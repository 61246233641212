import { selectStyle, ContainerColumnSelect } from "./style";
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";
import Select from "react-select";
import { useSelectAllFieldsColumn } from "../Hooks/useSelectAllFieldsColumn";

const SelectCategoryGroupBarChartSeries = ({
  columnsLibrary,
  handlerSelectCategory,
  dataInputs,
  data,
  setSelectedOptions,
  selectedOptions,
}) => {
  const { handlerSelectedColumn, handleSelectChange, allOptions } =
    useSelectAllFieldsColumn({
      data,
      columnsLibrary,
      setSelectedOptions,
      columnSelected: dataInputs.category?.name,
      handlerSelectColumn: handlerSelectCategory,
    });

  return (
    <ContainerColumnSelect>
      {columnsLibrary.map((columnLibrary) => {
        if (
          columnLibrary.name !== "localDate" &&
          columnLibrary.type !== "date" &&
          dataInputs.columnX.name !== columnLibrary.name
        ) {
          return (
            <>
              <div
                key={columnLibrary._id}
                className="card-type-chart"
                onClick={() => handlerSelectedColumn(columnLibrary.name)}
                style={{
                  backgroundColor:
                    dataInputs.category?.name === columnLibrary.name
                      ? ICON_GREEN_COLOR
                      : WHITE_COLOR,
                }}
              >
                <p>{columnLibrary.alias || columnLibrary.name} </p>
              </div>
              {dataInputs.category?.name === columnLibrary.name && (
                <Select
                  options={allOptions}
                  value={selectedOptions}
                  isMulti
                  onChange={handleSelectChange}
                  styles={selectStyle}
                />
              )}
            </>
          );
        }
      })}
    </ContainerColumnSelect>
  );
};

export default SelectCategoryGroupBarChartSeries;
