import { useEffect, useState } from "react";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
  RED_COLOR,
} from "utils/const";
import SelectLibrary from "./SelectLibrary";
// Hooks
import { useFetchEventLibraries } from "hooks/fetchLibraries";
import useColumnsWithUniqueValues from "hooks/dashboard/events/useColumnsWithUniqueValues2";
import useFindEvents from "hooks/events/useFindAllEvents";
// Components
import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary2";
import CustomButton from "components/Buttons/CustomButton";
import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";
import SelectColumnsLibrary from "pages/Dashboard/components/SelectColumnsLibrary";
// Services
import { createChart } from "services/Dashboard/kpiChart";
// Const
import { defaultFieldsEvents } from "utils/defaultColumns";
// Helpers
import HandlerClickColumn from "pages/Dashboard/helpers/handlerClickColumn";
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
import { config } from "config";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";

const EventFormKpi = ({ setOpenDialog }) => {
  const [columnsLibrary, setColumnsLibrary] = useState([]);
  const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columnsSelected, setColumnsSelected] = useState([]);
  const [columnValues, setColumnValues] = useState({});
  const [eventsFormat, setEventsFormat] = useState([]);
  const [dataInputs, setDataInputs] = useState({
    librarySelected: null,
    metric: null,
    typeOperation: "count",
    goal: null,
    alias: "",
    gaugeColor: null,
    backgroundColor: WHITE_COLOR,
    mainFontColor: BLACK_COLOR,
    mainFontSize: 30,
    secondFontColor: BLACK_COLOR,
    secondFontSize: 20,
    NoDecimals: 2,
  });
  const [showInputs, setShowInputs] = useState(false);
  const [columnsLibrarySelected, setColumnsLibrarySelected] = useState([]);

  const { mutate } = useSWRConfig();
  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);
  const updateDataInputs = (newState) => {
    setDataInputs((prevState) => ({ ...prevState, ...newState }));
  };

  const { data: libraries, error } = useFetchEventLibraries({});
  const { events } = useFindEvents();

  const handlerClickMetric = (column) => {
    updateDataInputs({ metric: column });
  };

  const handlerTypeOperation = (e) => {
    updateDataInputs({ typeOperation: e });
  };

  const handlerAlias = (e) => {
    updateDataInputs({ alias: e.target.value });
  };

  const handlerGaugeColor = (e) => {
    updateDataInputs({ gaugeColor: e.target.value });
  };

  const handlerGoal = (e) => {
    updateDataInputs({ goal: e.target.value });
  };

  const handlerBackgroundColor = (e) => {
    updateDataInputs({ backgroundColor: e.target.value });
  };

  const handlerMainFontColor = (e) => {
    updateDataInputs({ mainFontColor: e.target.value });
  };

  const handlerMainFontSize = (e) => {
    updateDataInputs({ mainFontSize: e.target.value });
  };

  const handlerSecondFontColor = (e) => {
    updateDataInputs({ secondFontColor: e.target.value });
  };

  const handlerSecondFontSize = (e) => {
    updateDataInputs({ secondFontSize: e.target.value });
  };

  const handlerClickColumn = HandlerClickColumn({
    setColumnsSelected,
    columnValues,
    setColumnValues,
  });

  const handlerNoDecimals = (e) => {
    updateDataInputs({ NoDecimals: e.target.value });
  };

  const { columnsWithValues, setColumnsWithValues } =
    useColumnsWithUniqueValues({
      events: eventsFormat,
      columnsSelected,
      handlerClickColumn,
      columnsLibrary: columnsLibrarySelected,
    });

  const handlerColumnValues = (columnName, selectedValues) => {
    setColumnValues({
      ...columnValues,
      [columnName]: selectedValues,
    });
  };

  const handlerLibrarySelected = (e) => {
    updateDataInputs({ librarySelected: e });
    const library = libraries.find((library) => library.id === e.value);
    const eventsByLibraryId = events.filter(
      (event) => event.pointTypeEvent.pointLibraryEventId === e.value
    );
    if (library) setShowInputs(true);
    const rows = createRowsEvents({
      events: eventsByLibraryId,
      library,
    });
    setEventsFormat(rows);
    updateDataInputs({ metric: null, alias: "" });
    setColumnsWithValues([]);
  };

  useEffect(() => {
    // find library by library select from libraries
    if (libraries && dataInputs.librarySelected) {
      const library = libraries.find(
        (library) => library.id === dataInputs.librarySelected.value
      );

      const columnsLibrary = library?.fields;

      setColumnsLibrarySelected(columnsLibrary);

      const columnsFiltered = columnsLibrary?.filter(
        (field) =>
          field.type !== "captureMedia" && field.type !== "notification"
      );
      if (columnsFiltered && columnsFiltered.length > 0) {
        setColumnsLibrary([...defaultFieldsEvents, ...columnsFiltered] || []);
        setColumnsTypeNumber(
          columnsFiltered?.filter(
            (field) =>
              field.type === "number" ||
              field.type === "operation" ||
              field.type === "range"
          )
        );
      } else {
        setColumnsLibrary([...defaultFieldsEvents] || []);
        setColumnsTypeNumber([]);
      }
    } else {
      setColumnsLibrary([]);
      setColumnsTypeNumber([]);
    }
  }, [libraries, dataInputs.librarySelected]);

  const handlerAddChart = async () => {
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const userId = localStorage.getItem("userId");
    const urlKpi = `${config.URL_BACKEND_MG}kpi-chart?adminCompanyId=${adminCompanyId}&from=events`;
    const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;

    const columnsValuesFilter = Object.entries(columnValues).map(
      ([name, values]) => ({
        name,
        values: values.map((item) => item.value),
      })
    );

    const chart = {
      from: "events",
      chartType: "kpiChart",
      metric: dataInputs?.metric?.name ? dataInputs.metric.name : null,
      alias: dataInputs.alias,
      libraryId: dataInputs.librarySelected.value,
      type: dataInputs.typeOperation,
      adminCompanyId: parseInt(adminCompanyId),
      columnsValuesFilter,
      goal: parseFloat(dataInputs.goal),
      chartStyle: {
        gaugeColor: dataInputs.gaugeColor || PRIMARY_COLOR,
        backgroundColor: dataInputs.backgroundColor,
        mainFontColor: dataInputs.mainFontColor,
        mainFontSize: parseInt(dataInputs.mainFontSize),
        secondFontColor: dataInputs.secondFontColor,
        secondFontSize: parseInt(dataInputs.secondFontSize),
        NoDecimals: parseInt(dataInputs.NoDecimals),
      },
    };

    setLoading(true);
    const { data } = await createChart(chart);
    if (data) {
      mutate(urlKpi);
      await addChartInAllViews({
        userId,
        type: "events",
        body: {
          newChart: {
            id: data._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <>
      {!error && libraries && (
        <SelectLibrary
          libraries={libraries}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={dataInputs.librarySelected}
        />
      )}
      {dataInputs.librarySelected && (
        <SelectTypeOperation
          handlerSelectTypeOperation={handlerTypeOperation}
        />
      )}
      {dataInputs.librarySelected &&
        columnsTypeNumber.length === 0 &&
        dataInputs.typeOperation === "sum" && (
          <div>
            <p style={{ color: RED_COLOR }}>
              The library {dataInputs.librarySelected.label} does not have
              numeric fields.
            </p>
          </div>
        )}
      {dataInputs.librarySelected &&
        columnsTypeNumber.length > 0 &&
        dataInputs.typeOperation === "sum" && (
          <SelectColumnLibrary
            columnsLibrary={columnsTypeNumber}
            handlerClickColumn={handlerClickMetric}
            columnSelected={dataInputs.metric?.name}
            title={"Select metric"}
          />
        )}
      {dataInputs.librarySelected && columnsLibrary && (
        <SelectColumnsLibrary
          columnsLibrary={columnsLibrary}
          handlerClickColumn={handlerClickColumn}
          columnsSelected={columnsSelected}
          columnsWithValues={columnsWithValues}
          handlerColumnValues={handlerColumnValues}
          columnValues={columnValues}
          title={"Set filters"}
        />
      )}
      {showInputs && (
        <section className="container-type-charts ">
          <div>
            <h2 className="subtitle">Goal</h2>
            <input
              className="alias"
              type="number"
              value={dataInputs.goal}
              onChange={handlerGoal}
            />
          </div>
          <div>
            <h2 className="subtitle">Alias</h2>
            <input
              className="alias"
              type="text"
              value={dataInputs.alias}
              onChange={(e) => {
                handlerAlias(e);
              }}
              required={true}
            />
          </div>
          <div>
            <h2 className="subtitle">Gauge color</h2>
            <input
              className="alias"
              type="color"
              value={dataInputs.gaugeColor || PRIMARY_COLOR}
              onChange={handlerGaugeColor}
            />
          </div>
          <>
            {/* Select background-color */}
            <div>
              <h2 className="subtitle">Background color</h2>
              <input
                className="alias"
                type="color"
                value={dataInputs.backgroundColor}
                onChange={handlerBackgroundColor}
              />
            </div>
            {/* Select main font color */}
            <div>
              <h2 className="subtitle">Main font color</h2>
              <input
                className="alias"
                type="color"
                value={dataInputs.mainFontColor}
                onChange={handlerMainFontColor}
              />
            </div>
            {/* Select main size font  */}
            <div>
              <h2 className="subtitle">Main font size (px)</h2>
              <input
                min={10}
                className="alias"
                type="number"
                value={dataInputs.mainFontSize}
                onChange={handlerMainFontSize}
              />
            </div>
            {/* Select second font color */}
            <div>
              <h2 className="subtitle">Second font color</h2>
              <input
                className="alias"
                type="color"
                value={dataInputs.secondFontColor}
                onChange={handlerSecondFontColor}
              />
            </div>
            {/* Select second size font  */}
            <div>
              <h2 className="subtitle">Second font size (px)</h2>
              <input
                className="alias"
                type="number"
                value={dataInputs.secondFontSize}
                min={10}
                onChange={handlerSecondFontSize}
              />
            </div>
            {/* Set NoDecimals */}
            <div>
              <h2 className="subtitle">No Decimals</h2>
              <input
                className="alias"
                type="number"
                value={dataInputs.NoDecimals}
                onChange={handlerNoDecimals}
              />
            </div>
          </>
        </section>
      )}
      {dataInputs.alias && (
        <CustomButton
          className="btn-add-chart"
          onClick={handlerAddChart}
          isLoad={loading}
          text={"Add new chart"}
          margin={"15px 0"}
        />
      )}
    </>
  );
};

export default EventFormKpi;
