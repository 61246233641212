import {
  CardContainer,
  CardImg,
  ResizeBottom,
  ResizeRight,
} from "./indexStyle";
import CloseIcon from "@mui/icons-material/Close";
import { GREY_TRANS } from "utils/const";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { updateOrderCard } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { config } from "config";
import handleAddGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridColumn";
import handleRemoveGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridColumn";
import handleRemoveGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridRow";
import handleAddGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridRow";
import { useEffect, useState } from "react";

const ImgChart = ({
  dataChart,
  currentStyle,
  template,
  index,
  setCurrentTemplate,
  responsiveWidth = null,
  responsiveHeight = null,
  restore = false,
  orderId,
  currentOrderName,
  currentOrderViews,
  setOpenDialogRestoreChart,
  setCurrentOrderViews,
  currentPositionId,
  handlerDeleteChartOnView,
  setDeleteChartDialog,
}) => {
  const role = localStorage.getItem("role");
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
  const { mutate } = useSWRConfig();
  const { setDragAndDroll } = useStoreDashboard();
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  const handleRestoreChart = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: [
          ...currentOrderViews,
          {
            id: dataChart._id,
            sizeChart: template.sizeChart,
          },
        ],
        _id: currentPositionId,
      },
      type: dataChart.from,
    });
    setCurrentOrderViews((current) => [
      ...current,
      {
        id: dataChart._id,
        sizeChart: template.sizeChart,
      },
    ]);
    mutate(urlOrders);
    setOpenDialogRestoreChart(false);
  };

  let path = "some/path";
  let url = new URL(path, dataChart.img);

  return (
    <CardContainer
      key={dataChart._id}
      width={
        template?.index
          ? currentStyle.width
          : responsiveWidth
          ? `${currentStyle.width - responsiveWidth}px`
          : `${currentStyle.width}px`
      }
      height={
        template?.index
          ? currentStyle.height
          : responsiveHeight
          ? `${currentStyle.height - responsiveHeight}px`
          : `${currentStyle.height}px`
      }
    >
      {role === "Administrador de compañía" &&
        !restore &&
        chartsIconsShowOnOff && (
          <button
            className="delete-card-button"
            onClick={() =>
              currentOrderName === "Design New View"
                ? setDeleteChartDialog(true)
                : handlerDeleteChartOnView()
            }
          >
            <span>
              <CloseIcon
                sx={{
                  color: GREY_TRANS,
                }}
              />
            </span>
          </button>
        )}

      {role === "Administrador de compañía" &&
        restore &&
        chartsIconsShowOnOff && (
          <button
            className="restore-card-button"
            onClick={() => handleRestoreChart()}
          >
            <RestartAltIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </button>
        )}

      {template && (
        <>
          <ResizeRight>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleAddGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleRemoveGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeRight>
          <ResizeBottom>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleRemoveGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleAddGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeBottom>
        </>
      )}

      <div
        className="chart-dashboard-element-array"
        style={{
          position: template ? "absolute" : "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          height: "100%",
          width: "100%",
          // backgroundImage: url,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img
          src={dataChart.img}
          width={"100%"}
          height={"100%"}
          alt=""
          crossOrigin="anonymous"
        />
      </div>
    </CardContainer>
  );
};

export default ImgChart;
