import NavBar from "components/NavBar";
import { CircularProgress, Grid, Container } from "@mui/material";
import BannerDecimetrix from "./BannerDecimetrix";
import {
  CustomContainer,
  ForgotButton,
  ForgotForm,
  ForgotLinkContainer,
  ForgotPasswordWrapper,
  Message,
} from "./ForgotPasswordElemts";
import { useState } from "react";
import axios from "axios";
import InputComponent from "../../../components/Forms/Input";
import { useNavigate } from "react-router-dom";
import { config } from "config.js";

export default function ForgotPasswordPage() {
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };

  const rememberedUser = JSON.parse(localStorage.getItem("rememberUser"));
  const token = localStorage.getItem("token");
  const [email, setEmail] = useState({
    field: rememberedUser ? rememberedUser.email : "",
    valid: null,
  });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formValidated, setFormValidated] = useState(null);
  const expressions = {
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };
  const navigate = useNavigate("");

  const reestablishUser = async (body) => {
    try {
      const data = await axios.post(
        `${config.URL_BACKEND_PG}api/v1/auth/forgotPassword`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const OnReestablish = async (event) => {
    event.preventDefault();
    if (email.valid === "true") {
      const body = {
        email: email.field,
      };
      setMessage(null);
      setLoading(true);
      const res = await reestablishUser(body);
      setLoading(false);

      if (res) {
        if (res.status === 401 || res.status === 404) {
          setFormValidated(false);
          setMessage("Incorrect user or password");
        } else {
          setFormValidated(true);
          setMessage(null);
        }
      } else {
        setFormValidated(false);
        setMessage("Incorrect user or password");
      }
    } else {
      setFormValidated(false);
      setMessage("please fill form correctly.");
    }
  };

  const handleSignIn = () => {
    navigate("/SignIn");
  };

  return (
    <div className="background-1">
      <NavBar configNavbar={configNavbar} />
      <Container maxWidth="xl">
        <ForgotPasswordWrapper>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <BannerDecimetrix />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <CustomContainer className="small">
                <ForgotForm
                  className="background-2 border-color-1 color1"
                  onSubmit={OnReestablish}
                >
                  <InputComponent
                    state={email}
                    changeState={setEmail}
                    type="email"
                    name="email"
                    label="E-mail address"
                    placeholder="your.email@gmail.com"
                    legendError="The email can only contain letters, numbers, periods, hyphens and underscore."
                    regularExpression={expressions.email}
                  />
                  <ForgotButton type="submit">
                    {loading ? <CircularProgress /> : "Reset Password"}
                  </ForgotButton>
                  <Message success={formValidated}>
                    {formValidated ? "Form sent successfully!" : message}
                  </Message>
                </ForgotForm>

                <ForgotLinkContainer onClick={handleSignIn}>
                  Sign In
                </ForgotLinkContainer>
              </CustomContainer>
            </Grid>
          </Grid>
        </ForgotPasswordWrapper>
      </Container>
    </div>
  );
}
