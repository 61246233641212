// React - Redux
import { useSelector } from "react-redux";
// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// custom style
import Typography from "@mui/material/Typography";
import { useStyles, CustomizedAccordion } from "./AccordionStyles";
import { PRIMARY_COLOR } from "utils/const";
// Components
import PointsDigitalTwin from "./Points";
import CustomButton from "components/Buttons/CustomButton";
// Hooks
import useViewFilterClusterMap from "hooks/useViewFilterClusterMap";

const AccordionDigitalTwin = ({ dataObjects }) => {
  const classes = useStyles();

  const { handleSetFilterPoints } = useViewFilterClusterMap({});

  const showDataObjects = useSelector(
    (state) => state.adminReducer.showDataObjects
  );

  const showDataComponents = useSelector(
    (state) => state.digitalTwinReducer.showDataComponents
  );

  const showEvents = useSelector(
    (state) => state.digitalTwinReducer.showEvents
  );

  return (
    <>
      <CustomizedAccordion
        className="background-1 border-color-1"
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
        style={{ borderRadius: "4px", boxShadow: "none", marginBottom: "1rem" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontFamily: document.body.style.fontFamily,
              color: document.body.style.color,
              fontWeight: "bold",
            }}
          >
            Digital Twin
          </Typography>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="color1" />}
          ></AccordionSummary>
        </div>
        <PointsDigitalTwin dataObjects={dataObjects} />
        {(showDataObjects.color !== PRIMARY_COLOR ||
          showDataComponents.color !== PRIMARY_COLOR ||
          showEvents.color !== PRIMARY_COLOR) && (
          <CustomButton
            text={"Rest Filters"}
            margin={0}
            onClick={handleSetFilterPoints}
          />
        )}
      </CustomizedAccordion>
    </>
  );
};

export default AccordionDigitalTwin;
