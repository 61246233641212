import { useState, useEffect, useCallback } from "react";
// Helpers
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/eventsLineChart";
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
import useSwr from "swr";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { config } from "config";

const useFindLineCharts = ({ openDialog, events, loading }) => {
  const [charts, setCharts] = useState(null);
  const [lineCharts, setLineCharts] = useState(null);
  const [eventsFormat, setEventsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlLineChart = `${config.URL_BACKEND_MG}line-chart?adminCompanyId=${adminCompanyId}&from=events`;
  const { data: dataLineChart, error: errorDataLineChart } =
    useSwr(urlLineChart);

  useEffect(() => {
    if (!errorDataLineChart && dataLineChart) setCharts(dataLineChart);
  }, [dataLineChart, errorDataLineChart, openDialog, reloadCharts]);

  useEffect(() => {
    const eventsFormat = createRowsEvents({ events });
    setEventsFormat(eventsFormat);
  }, [events]);

  const getDataBarChart = useCallback(async () => {
    if (!loading && eventsFormat) {
      const lineCountsvalues = countByValues({
        charts,
        events: eventsFormat,
      });

      const lineSumValues = sumValues({
        charts,
        events: eventsFormat,
      });

      setLineCharts([...lineCountsvalues, ...lineSumValues]);
    }
  }, [loading, eventsFormat, charts]);

  useEffect(() => {
    if (charts && events) {
      getDataBarChart();
    }
  }, [charts, events, getDataBarChart]);

  return { lineCharts };
};

export default useFindLineCharts;
