import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BLACK_COLOR } from "utils/const";

const DraggableList = ({ items, itemContent, handleOnDragEnd }) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="characters" direction="vertical">
        {(provided) => (
          <ul
            className="characters"
            style={{
              listStyle: "none",
              color: `${BLACK_COLOR}`
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items?.map((item, index) => {
              return (
                <Draggable
                  key={item.id || index}
                  draggableId={item?.id?.toString()}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {itemContent(item)}
                    </li>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
