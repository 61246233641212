import { config } from "config.js";

const URl_BASE_PG = config.URL_BACKEND_PG;

export const loginUser = async (body) => {
  try {
    const res = await fetch(`${URl_BASE_PG}api/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });
    const response = await res.json();
    return response;
  } catch (error) {
    console.error(error);
  }
};
