// custom style
import { BLACK_COLOR } from "utils/const";
import { Input, FormTextarea } from "./AccordionStyles";
import { useSelector } from "react-redux";

const AccordionDetailsPolygon = () => {
  const dataRegion = useSelector(
    (state) => state.adminReducer.dataObjectsRegion
  );

  return (
    <div style={{color:`${BLACK_COLOR}`}}>
      <div>
        <p className="geometry-type">
          <b>Region name:</b>
        </p>
        <Input type="text" value={dataRegion.properties.name} />
      </div>
      <div>
        <p class="geometry-type">
          <b>Library:</b>
        </p>
        <Input type="text" value={dataRegion.properties.regionlibrary} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>Start Date Operation:</b>
        </p>
        <Input type="text" value={dataRegion.properties.regionType} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>Contract:</b>
        </p>
        <Input type="text" value={dataRegion.properties.contract} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>Field:</b>
        </p>
        <Input type="text" value={dataRegion.properties.field} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>Base Line Period:</b>
        </p>
        <Input type="text" value={dataRegion.properties.baselinePeriod} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>State Facility:</b>
        </p>
        <Input type="text" value={dataRegion.properties.stateFacility} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>Start Date Operation:</b>
        </p>
        <Input type="text" value={dataRegion.properties.startDateOperation} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>Date Presentation Anh:</b>
        </p>
        <Input type="text" value={dataRegion.properties.datePresentationAnh} />
        <b></b>
      </div>
      <div>
        <p class="geometry-type">
          <b>Creted By:</b>
        </p>
        <Input type="text" value={dataRegion.properties.userName} />
        <b></b>
      </div>
      <div>
        <p>
          <b>Comments:</b>{" "}
          <FormTextarea type="text" value={dataRegion.properties.comments} />
        </p>
      </div>
    </div>
  );
};

export default AccordionDetailsPolygon;
