function videosIsLoaded(videosDetails, videoName) {
  return videosDetails.map((videoDetails) => {
    if (videoDetails.name === videoName) {
      return {
        ...videoDetails,
        videoIsLoad: true,
      };
    }
    return videoDetails;
  });
}

export default videosIsLoaded;
