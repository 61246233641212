// Styles
import Box from "@mui/material/Box";
// React
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
// Dependencies
import useSwr from "swr";
// Services
import { userVerification } from "services/users/userVerification";
// Const
import GenericTable from "../GenericTable/GenericTable";
import { FROM_TABLE } from "utils/constStrings";
// Config
import { config } from "config.js";
// Components
import Loading from "components/Lodings/LoadingV2";

export const keyValuesUsers = [
  "firstName",
  "secondName",
  "lastName",
  "email",
  "company",
  "role",
  "area",
  "activeSession",
  "confirmationToken",
  "actions",
  "noSessions",
  "userLogOut",
  "createdAt",
];

export const headersUsers = [
  "Name",
  "Second Name",
  "Last Name",
  "E-mail",
  "Company",
  "Role",
  "Area",
  "Session",
  "State",
  "Actions",
  "No Sessions",
  "User Log Out",
  "Created At",
];

const createRows = (operators) => {
  return Promise.all(
    operators.map((operator) => {
      return userVerification(operator.user.email).then((res) => {
        return {
          id: operator.userId,
          firstName: operator.firstName,
          secondName: operator.secondName,
          lastName: operator.firstLastName,
          email: operator.user.email,
          company: operator.company.name,
          role: operator.role?.role,
          area: operator.area,
          canDelete: operator.user.objects.length === 0,
          activeSession: res.activeSession ? "Online" : "Offline ",
          confirmationToken: operator.user.userVerification.confirmationToken
            ? "E-Mail pending of verification"
            : "Verified e-mail",
          createdAt: operator.createdAt.substring(0, 10),
          isEnable: res.isEnable,
          noSessions: res.noSessions,
        };
      });
    })
  );
};

export default function ListOperators() {
  const [rows, setRows] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [valuesNoSessions, setValuesNoSessions] = useState({});
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { data: adminCompanyData, error: adminCompanyDataError } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`
  );

  useEffect(() => {
    if (!adminCompanyDataError && adminCompanyData) {
      setIsLoad(true);
      createRows(adminCompanyData.operators).then((operatorsRows) => {
        operatorsRows.forEach(({ id, noSessions }) => {
          setValuesNoSessions((prevValues) => ({
            ...prevValues,
            [id]: noSessions,
          }));
        });
        setRows(operatorsRows?.length > 0 ? operatorsRows : []);
        setIsLoad(false);
      });
    }
  }, [adminCompanyData, adminCompanyDataError, adminCompanyId]);

  return (
    <Box sx={{ width: "100%" }}>
      {rows && (
        <GenericTable
          keyValues={keyValuesUsers}
          headers={headersUsers}
          rows={rows}
          setRows={setRows}
          valuesNoSessions={valuesNoSessions}
          setValuesNoSessions={setValuesNoSessions}
          state={true}
          configBar={true}
          from={FROM_TABLE.users}
        />
      )}
      {isLoad && <Loading />}
    </Box>
  );
}
