import styled from "styled-components";
import { WHITE_COLOR, WHITE_SECOND_COLOR } from "utils/const";

export const ContentButton = styled.div`
  margin-top: 2px;
`;

export const Wrapperbtn = styled("div")`
  position: relative;
  margin-left: 2rem;
  margin-top: 3.6rem;
  width: 100%;
`;

export const WrapperIcon = styled("div")`
  position: relative;
  margin-left: 1rem;
  margin-top: 5rem;
  width: 100%;
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: var(--background-primary_color);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 1px;
    margin-bottom: 6px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: ${WHITE_COLOR};
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Label = styled("label")`
  background: var(--background-primary_color);
  width: 50%;

  border-radius: 5px;
  color: ${WHITE_SECOND_COLOR};
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: var(--background-secondary_color);
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  width: 95%;
  margin: 0 auto;
  flex-direction: column;

  .select {
    display: flex;
    margin: 10px 0;
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-content: center;
  }

  .select p {
    width: 30%;
    align-self: center;
    font-size: 2rem;
  }

  .select .select-container {
    width: 70%;
  }

  .select-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin: 30px 0;
    width: 100%;
  }

  .select-fields .select-name-field {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    width: 48%;
  }

  .select-fields .select-file-field {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    width: 48%;
  }

  .select-name-field .table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
  }

  .select-file-field .table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
  }

  .table .table-header {
    padding: 30px 0;
    background-color: var(--background-primary_color);
    height: 40px;
    width: auto;
    word-wrap: break-word;
  }

  .table-header th {
    color: ${WHITE_COLOR};
    font-weight: 500;
    font-size: 2rem;
    margin: 0;
    width: auto;
    word-wrap: break-word;
  }

  .table .table-row {
    padding: 20px 0;
    margin: 20px 0;
    height: 30px;
    text-align: center;
    width: auto;
    word-wrap: break-word;
  }

  .select-title {
    font-size: 2rem;
  }

  .select-description {
    font-size: 1.8rem;
  }

  .selected {
    background-color: var(--background-secondary_color);
  }

  .table-row:hover {
    background-color: var(--background-trans_color);
    cursor: pointer;
  }

  .table .table-column {
    padding: 0 10px;
    font-size: 1.8rem;
  }

  #form-file-upload {
    height: 300px;
    width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload.drag-active {
    background-color: #9bc4cb;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  .drag-info p {
    font-size: 1.7rem;
  }
  .drag-info button p {
    color: var(--background-secondary_color);
  }

  p,
  h2 {
    color: ${document.body.style.color};
  }
`;

export const WrapperVideos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 2rem;
  width: 95%;
  margin: 0 auto;
`;

export const VideoContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 32%;
  min-width: 300px;
  height: auto;
  margin: 10px 0;
  padding: 0 10px 20px 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  .video-load {
    position: absolute;
    left: 10px;
    top: 5px;
  }
  .delete-button {
    position: absolute;
    right: 0;
    top: 5px;
  }

  .video-container {
    margin-top: 40px;
  }
  .video-details {
    margin: 10px 0;
  }

  p,
  h2 {
    color: ${document.body.style.color};
  }
`;
