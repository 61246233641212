import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";

const SelectComponentTypes = ({
  componentTypes,
  librarySelected,
  handlerClickComponentType,
  typeIdsSelected,
  typeBar,
}) => {
  return (
    <>
      <h2 className="subtitle">Select type</h2>
      <section className="container-type-charts ">
        {componentTypes
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((componentType) =>
            typeBar === "countByType"
              ? true
              : componentType.pointLibraryComponentId === librarySelected
          )
          .map((componentType) => (
            <div
              key={componentType.id}
              className="card-type-chart"
              onClick={() =>
                handlerClickComponentType(
                  componentType.id,
                  componentType.pointLibraryComponentId,
                  componentType
                )
              }
              style={{
                backgroundColor: typeIdsSelected.includes(componentType.id)
                  ? ICON_GREEN_COLOR
                  : WHITE_COLOR,
              }}
            >
              <p>{componentType.name} </p>
              <span>
                <img
                  width="30px"
                  src={
                    componentType.icon
                      ? componentType.icon
                      : "https://static.thenounproject.com/png/79163-200.png"
                  }
                  alt="icon component type"
                />
              </span>
            </div>
          ))}
      </section>
    </>
  );
};

export default SelectComponentTypes;
