import { useEffect, useState } from "react";
import {
  createTag,
  deleteTag,
  getAllTags,
} from "services/tags/fieldTagsService";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";

const ClearIndicator = ({ children, ...props }) => {
  const style = { cursor: "pointer" };
  return (
    <components.ClearIndicator {...props}>
      <span style={style}>
        <DeleteIcon />
      </span>
    </components.ClearIndicator>
  );
};

// option layout for menu list
const Option = ({ children, ...props }) => {
  const { handleDeleteTag } = props.selectProps;
  const { id } = props.data;
  return (
    <components.Option {...props}>
      <div className="tag-selectable">
        <div className="tag-selectable-label">{children}</div>
        <button
          type="button"
          value={id}
          onClick={handleDeleteTag}
          className="tag-selectable-button"
        >
          {props.data.value ? (
            <ClearIcon className="clear-icon" disable={true} />
          ) : (
            <></>
          )}
        </button>
      </div>
    </components.Option>
  );
};

// styles for each part of Select component
const styles = {
  container: (styles) => ({
    ...styles,
    width: "100%",
    padding: "5px",
  }),
  valueContainer: (styles) => {
    return {
      ...styles,
      width: "100%",
      display: "flex",
    };
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      color: "black",
      cursor: "pointer",
      width: "auto",
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxHeight: "150px",
      overflowX: "hidden",
      overflowY: "auto",
    };
  },
  option: (styles, { data }) => {
    return {
      ...styles,
      width: "700px",
      heigth: "10px",
      color: "black",
      cursor: "pointer",
      padding: "0px 10px",
    };
  },
};

function Tags({ field, handleFieldTags, info, update }) {
  const [tags, setTags] = useState(field.tags); // final array with all selected tagsin just strings
  const [existingTags, setExistingTags] = useState([]); // all tags by admin as they come from db
  const [selectTags, setSelectTags] = useState([]); // tags with format for Select component
  const [valueNoUpdate, setValueNoUpdate] = useState([]);

  const components = {
    animated: makeAnimated(),
    ClearIndicator,
    Option,
  };

  // setting the data
  useEffect(() => {
    getAllTags().then((tagsDb) => {
      setExistingTags(tagsDb);
      setSelectTags(
        tagsDb.map((t) => ({
          id: t.id,
          value: t.tags,
          label: t.tags,
        }))
      );
    });
  }, [tags]);

  const handleOnChangeSelect = (e) => {
    setTags(e.map((t) => t.label));
    handleFieldTags(e.map((t) => t.label));
    setValueNoUpdate(e);
  };

  const addTag = (tag) => {
    if (!tags.includes(tag) && !existingTags.find((it) => it.tags === tag)) {
      const newTags = [...tags, tag];
      setTags(newTags);
      handleFieldTags(tags);
    }
  };

  const handleCreateTag = (e) => {
    const newTag = e.trim().toLowerCase();
    if (newTag !== "" && !tags.includes(newTag)) {
      createTag(newTag);
      addTag(newTag);
    }
  };

  const handleDeleteTag = (e) => {
    const id = parseInt(e.currentTarget.value);
    const findTag = selectTags.find((item) => item.id === id);
    deleteTag(id);
    setSelectTags(selectTags.filter((it) => it.id !== id));
    setTags(tags.filter((it) => it !== findTag.tags));
    setValueNoUpdate(tags.filter((it) => it !== findTag.tags));
    handleFieldTags(tags);
    e.preventDefault();
  };

  const setDataUpdate = () => {
    return field.tags.forEach((it) => {
      const find = existingTags.find((tag) => tag.tags === it);
      if (find)
        return {
          id: find.id,
          value: find.tags,
          label: find.tags,
        };
    });
  };

  return (
    <div id="field-tags">
      <div>Field tags:{info}</div>
      <CreatableSelect
        isMulti
        closeMenuOnSelect={false}
        options={selectTags}
        components={components}
        onChange={handleOnChangeSelect}
        handleDeleteTag={handleDeleteTag}
        onCreateOption={handleCreateTag}
        styles={styles}
        placeholder="Type a new Tag and press enter to save..."
        className="field-tags-input"
        value={update ? setDataUpdate() : valueNoUpdate}
      />
    </div>
  );
}

export default Tags;
