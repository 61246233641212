import { getNumericValue } from "helpers/getNumericValue";

function filterObjectsByLibraryId(objects, libraryId) {
  return objects.filter((obj) => obj.typeElement?.pointLibraryId === libraryId);
}

function processDatasets(filteredObjects, chart, groupedLabels) {
  return chart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: null }));

    filteredObjects.forEach((obj) => {
      const typeElementName = obj.attributes.find(
        (attr) => attr.name === chart.categories.name
      )?.value;
      if (typeElementName !== dataset.donutStyle.label) return;

      const value = obj.attributes.find(
        (attr) => attr.name === chart.columnX.name
      )?.value;
      const yValue = obj.attributes.find(
        (attr) => attr.name === chart.columnY
      )?.value;

      const numericValue = getNumericValue(yValue);

      const index = dataByLabel.findIndex((item) => item.label === value);
      if (index !== -1) {
        if (
          dataByLabel[index].value === null ||
          isNaN(dataByLabel[index].value)
        ) {
          dataByLabel[index].value = numericValue;
        } else {
          dataByLabel[index].value += numericValue;
        }
      }
    });

    return {
      ...dataset,
      data: dataByLabel.map((item) => item.value),
    };
  });
}

// Función principal para procesar datos
export function sumValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    // const groupedLabels = getGroupedLabels(chart.columnX.values, chart.groupingVariable);
    const datasets = processDatasets(
      filteredObjects,
      chart,
      chart.columnX.values
    );

    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}

// Función para procesar datasets contando ocurrencias
function processCountDatasets(filteredObjects, timeSeriesChart, groupedLabels) {
  return timeSeriesChart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: 0 }));

    filteredObjects.forEach((obj) => {
      const typeElementName = obj.attributes.find(
        (attr) => attr.name === timeSeriesChart.categories.name
      )?.value;
      if (typeElementName !== dataset.donutStyle.label) return;

      const dataLabel = obj.attributes.find(
        (attr) => attr.name === timeSeriesChart.columnX.name
      )?.value;

      const index = dataByLabel.findIndex((item) => item.label === dataLabel);
      if (index !== -1) {
        dataByLabel[index].value += 1;
      }
    });

    return {
      ...dataset,
      data: dataByLabel.map((item) => item.value),
    };
  });
}

// Función principal para procesar datos con conteo
export function countByValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    const datasets = processCountDatasets(
      filteredObjects,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}
