import { getNumericValue } from "helpers/getNumericValue";

function filterEventsByLibraryId(events, libraryId) {
  return events.filter(
    (evt) => evt.pointTypeEvent.pointLibraryEventId === libraryId
  );
}

function filterEvents(events, filters) {
  return events.filter((obj) => {
    return filters.every((filter) => {
      return obj.attributes.some((attr) => {
        return attr.name === filter.name && filter.values.includes(attr.value);
      });
    });
  });
}

function processCountDatasets(filteredEvents, chart, groupedLabels) {
  const filteredByAttributes = filterEvents(
    filteredEvents,
    chart.columnsValuesFilter
  ); 
  
  const dataByLabel = groupedLabels.map((label) => ({ label, value: 0 }));
  filteredByAttributes.forEach((obj) => {
    const dataLabel = obj.attributes.find(
      (attr) => attr.name === chart.columnX.name
    )?.value;

    const index = dataByLabel.findIndex((item) => item.label === dataLabel);
    if (index !== -1) {
      dataByLabel[index].value += 1;
    }
  });

  return [
    {
      ...chart.donutStyle,
      data: dataByLabel.map((elm) => elm.value),
    },
  ];
}

export function countByValues({ events, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const filteredEvents = filterEventsByLibraryId(events, chart.libraryId);
    const datasets = processCountDatasets(
      filteredEvents,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}

function processDatasets(filteredEvents, chart, groupedLabels) {
  const filteredByAttributes = filterEvents(
    filteredEvents,
    chart.columnsValuesFilter
  ); 

  const dataByLabel = groupedLabels.map((label) => ({ label, value: null }));
  filteredByAttributes.forEach((obj) => {
    const value = obj.attributes.find(
      (attr) => attr.name === chart.columnX.name
    )?.value;

    const yValue = obj.attributes.find(
      (attr) => attr.name === chart.columnsY[0]
    )?.value;

    const numericValue = getNumericValue(yValue);

    const index = dataByLabel.findIndex((item) => item.label === value);
    if (index !== -1) {
      if (
        dataByLabel[index].value === null ||
        isNaN(dataByLabel[index].value)
      ) {
        dataByLabel[index].value = numericValue;
      } else {
        dataByLabel[index].value += numericValue;
      }
    }
  });

  return [
    {
      ...chart.donutStyle,
      data: dataByLabel.map((elm) => elm.value),
    },
  ];
}

export function sumValues({ events, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const filteredEvents = filterEventsByLibraryId(events, chart.libraryId);
    const datasets = processDatasets(
      filteredEvents,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}
