import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { HeadModal } from "./DialogsStyles";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { setShowOperationDetails } from "redux/actions";
import { AiFillCloseCircle } from "react-icons/ai";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";
import MinimizedDetail from "components/Float/MinimizedDetail";
import { useContext, useState } from "react";
import { TbArrowsMinimize } from "react-icons/tb";
import { useEffect } from "react";
import ObjectDetails from "components/ElementDetails/ObjectDetails/ObjectDetails";
import EventDetails from "components/ElementDetails/EventDetails/EventDetails";
import OperationDetails from "components/ElementDetails/OperationDetails/OperationDetails";
import {
  BACKGROUND_SECOND_DARK,
  BACKGROUND_GREY,
  PRIMARY_COLOR,
  WHITE_BACKGROUND,
  WHITE_COLOR,
} from "utils/const";
import { ThemeContext } from "App";
const OperationDialog = (props) => {
  const dispatch = useDispatch();
  const [seeIconMinimized, setSeeIconMinimized] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [params, setParams] = useState({});
  const { theme } = useContext(ThemeContext);

  const openDialogOne = useSelector(
    (state) => state.digitalTwinReducer.showOperationDetails
  );

  const paramsTwo = useSelector(
    (state) => state.digitalTwinReducer.operationDetails
  );

  useEffect(() => {
    if (props.currentId !== 0 || Object.keys(props.dataEvent).length !== 0) {
      if (props.currentId !== undefined && props.currentId !== 0) {
        const object = {
          content: <ObjectDetails objectId={props.currentId} />,
          title: "Objects Details",
        };
        setParams(object);
        setOpenDialog(true);
      } else if (
        props.dataEvent !== undefined &&
        Object.keys(props.dataEvent).length !== 0
      ) {
        const content = props.dataEvent.isDynamic ? (
          <EventDetails eventId={props.dataEvent.operationId} />
        ) : (
          <OperationDetails operationId={props.dataEvent.operationId} />
        );
        const title = props.dataEvent.isDynamic
          ? "Event Details"
          : "Operation Details";
        const operation = {
          content,
          title,
        };
        setParams(operation);
        setOpenDialog(true);
      } else {
        setParams(paramsTwo);
        setOpenDialog(openDialogOne);
      }
    } else {
      setParams(paramsTwo);
      setOpenDialog(openDialogOne);
    }
  }, [openDialog, paramsTwo, props.currentId, props.dataEvent, openDialogOne]);

  const { content, title } = params;

  const { style, minimized } = props;
  const open = openDialog;
  const theme2 = useTheme();
  const fullScreen = useMediaQuery(theme2.breakpoints.down("md"));

  const handleClose = () => {
    dispatch(setShowOperationDetails(false));
    setOpenDialog(false);
    localStorage.setItem("currentId", 0);
    localStorage.setItem("typeEvent", JSON.stringify({}));
    if (props.hasOwnProperty("handle") || props.hasOwnProperty("handleEvent")) {
      props.handle(0);
      props.handleEvent({});
    }
  };

  const handleMinized = () => {
    handleClose();
    setSeeIconMinimized(true);
  };

  useEffect(() => {
    openDialog && setSeeIconMinimized(false);
  }, [openDialog, paramsTwo, props.currentId, props.dataEvent]);

  return (
    <>
      {seeIconMinimized && <MinimizedDetail params={params} />}
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-paper": style,
            "& .MuiDialogContent-root": {
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              padding: "0px 10px 0px 10px",
            },
          }}
        >
          <HeadModal>
            <div className="title">
              <img
                src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
                alt="logo decimetrix"
                className="neurona"
              />
              <span className="titleHeadModal">{title}</span>
            </div>
            <div className="actions">
              {minimized && (
                <Button onClick={handleMinized}>
                  <TbArrowsMinimize
                    style={{ color: WHITE_COLOR, fontSize: 21 }}
                  />
                </Button>
              )}
              <Button onClick={handleClose}>
                <AiFillCloseCircle
                  style={{ color: WHITE_COLOR, fontSize: 21 }}
                />
              </Button>
            </div>
          </HeadModal>

          <DialogContent
            sx={
              {
                // backgroundColor: `${WHITE_BACKGROUND}`,
              }
            }
          >
            {content}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default OperationDialog;
