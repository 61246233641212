import DynamicEvent from "components/Forms/Events/DynamicEvent";
import { useState } from "react";
import SelectInput from "components/Forms/SelectInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FeatureDetails } from "components/Forms/SaveObject/indexStyle";
import { WraperTitleCardMarker } from "components/ElementDetails/ImageDetailsElements";
import { useFetchEventLibraries } from "hooks/fetchLibraries";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function CreateEvent({
  feature,
  objectId,
  closeModal,
  typeEvents,
  isGeographic = true,
}) {
  const [type, setType] = useState();
  const [currentEventType, setCurrentEventType] = useState();
  const [currentLibraryType, setCurrentLibraryType] = useState();

  const [libraryType, setLibraryType] = useState({
    name: "Library type",
    options: [],
    disableOptions: [],
    id: 0,
  });

  const [eventType, setEventType] = useState({
    name: "Type event",
    options: [],
    icons: [],
    id: 0,
  });
  const { data: typeEventLibraries, errorTypeEventLibraries } =
    useFetchEventLibraries({ id: null });

  useEffect(() => {
    const librarieIds = typeEvents.map(
      (event) => event.pointTypeEvent.pointLibraryEventId
    );

    var typeEventIcons = typeEvents.map((typeEvent) => {
      const library =
        !errorTypeEventLibraries &&
        typeEventLibraries?.find((library) => {
          return typeEvent.pointTypeEvent.pointLibraryEventId === library.id;
        });
      return {
        name: typeEvent.pointTypeEvent.name,
        icon: typeEvent.pointTypeEvent.icon,
        library: library?.name,
      };
    });

    const currentLibraryTypes = [];
    const currentDisableLibraries = [];
    const currentEventTypes = [];

    !errorTypeEventLibraries &&
      typeEventLibraries?.forEach((library) => {
        if (librarieIds?.includes(library.id)) {
          currentLibraryTypes.push(library.name);
          if (library.available === false) {
            currentDisableLibraries.push(library.name);
          }
        }
      });

    typeEvents.forEach((typeEvent) => {
      !errorTypeEventLibraries &&
        typeEventLibraries?.forEach((library, index) => {
          if (
            typeEventLibraries &&
            typeEvent.pointTypeEvent.pointLibraryEventId === library.id
          ) {
            if (
              library.name === currentLibraryTypes[0] &&
              !currentDisableLibraries.includes(library.name)
            ) {
              currentEventTypes.push(typeEvent.pointTypeEvent.name);
            }
          }
        });
    });

    setEventType((prev) => ({
      ...prev,
      options: currentEventTypes,
      icons: typeEventIcons,
    }));

    setLibraryType((prev) => ({
      ...prev,
      options: currentLibraryTypes,
      disableOptions: currentDisableLibraries,
    }));

    setCurrentEventType(currentEventTypes[0]);
    setCurrentLibraryType(currentLibraryTypes[0]);
    const currentType = typeEvents.find((elm) => {
      return elm.pointTypeEvent.name === currentEventTypes[0];
    });
    setType(currentType?.pointTypeEvent.id);
  }, [typeEvents, typeEventLibraries, errorTypeEventLibraries]);

  const { register, handleSubmit } = useForm();

  const handleSelectLibrary = (data) => {
    setCurrentLibraryType(data["Library type"]);
    const currentLibrary =
      !errorTypeEventLibraries &&
      typeEventLibraries?.find((event) => event.name === data["Library type"]);
    const currentLibraryOptions = [];
    const currentTypeEventIds = [];
    typeEvents.forEach((typeEvent) => {
      if (typeEvent.pointTypeEvent.pointLibraryEventId === currentLibrary.id) {
        currentLibraryOptions.push(typeEvent.pointTypeEvent.name);
        currentTypeEventIds.push(typeEvent.pointTypeEvent.id);
      }
    });
    setEventType({ ...eventType, options: currentLibraryOptions });
    setType(currentTypeEventIds[0]);
    setCurrentEventType(currentLibraryOptions[0]);
  };

  const handleSelectTypeEvent = (data) => {
    typeEvents.forEach((event) => {
      if (event.pointTypeEvent.name === data["Type event"]) {
        setType(event.pointTypeEvent.id);
        setCurrentEventType(data["Type event"]);
      }
    });
  };

  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {eventType?.icons.map((event) => {
            return (
              event.name === currentEventType &&
              event.library === currentLibraryType && (
                <img className="dynamicImg" src={event.icon} alt="Decimetrix" />
              )
            );
          })}
          <h2>Events</h2>
        </div>
      </WraperTitleCardMarker>
      <div onChange={handleSubmit(handleSelectLibrary)}>
        <SelectInput field={libraryType} register={register} />
      </div>
      <div onChange={handleSubmit(handleSelectTypeEvent)}>
        <SelectInput field={eventType} register={register} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <KeyboardArrowDownIcon style={{ margin: "2rem 0" }} />
      </div>
      <DynamicEvent
        closeModal={closeModal}
        objectId={objectId}
        feature={isGeographic ? feature : {}}
        pointTypeEventId={type}
      />
    </FeatureDetails>
  );
}
