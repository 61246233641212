import { Provider } from "react-redux";
import { render } from "react-dom";
import App from "./App";
import store from "./redux/store";
import { SWRConfig } from "swr";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Styles } from "./Styles";
import { SocketProvider } from "./context/SocketProvider";

render(
  <SocketProvider>
    <Provider store={store}>
      <Styles />
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          fetcher: (...args) => {
            const token = localStorage.getItem("token");

            return fetch(...args, {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }).then((response) => response.json());
          },
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </SWRConfig>
    </Provider>
  </SocketProvider>,
  document.getElementById("root")
);
