// Style
import {
  CardContainer,
  CardBar,
  ResizeRight,
  ResizeBottom,
} from "./indexStyle";
import CloseIcon from "@mui/icons-material/Close";
import { GREY_TRANS } from "utils/const";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// Services
import useStoreDashboard from "pages/Dashboard/Store";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { updateOrderCard } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { config } from "config";
import handleAddGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridColumn";
import handleRemoveGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridColumn";
import handleRemoveGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridRow";
import handleAddGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridRow";
import { createRgbaColor } from "pages/Dashboard/helpers/createRgbaColor";
import { fixedDecimalNumber } from "pages/Dashboard/helpers/fixedDecimalNumber";
import { useSetCurrentStyle } from "./Hooks/useSetCurrentStyle";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function isHexadecimal(color) {
  const hexPattern = /^#([A-Fa-f0-9]{3}){1,2}$|^#([A-Fa-f0-9]{6})$/;
  return hexPattern.test(color);
}
const BarChart = ({
  dataChart,
  currentStyle,
  template,
  setCurrentOrderViews,
  index,
  setCurrentTemplate,
  responsiveWidth = null,
  responsiveHeight = null,
  restore = false,
  orderId,
  currentOrderName,
  currentOrderViews,
  setOpenDialogRestoreChart,
  currentPositionId,
  handlerDeleteChartOnView,
  setDeleteChartDialog,
}) => {
  /**
   * labels: ["a", "b"]
   * datasets: [
   *  {
   *    label: "Dataset 1",
   *    data" [45, 3],
   *    backgroundColor: #fff;
   *  }
   * ]
   */

  const role = localStorage.getItem("role");
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
  const { mutate } = useSWRConfig();
  const { setDragAndDroll } = useStoreDashboard();
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  const handleRestoreChart = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: [
          ...currentOrderViews,
          {
            id: dataChart._id,
            sizeChart: template.sizeChart,
          },
        ],
        _id: currentPositionId,
      },
      type: dataChart.from,
    });
    setCurrentOrderViews((current) => [
      ...current,
      {
        id: dataChart._id,
        sizeChart: template.sizeChart,
      },
    ]);
    mutate(urlOrders);
    setOpenDialogRestoreChart(false);
  };

  const { currentStyleBarDataChart } = useSetCurrentStyle({ dataChart });

  return (
    <CardContainer
      key={dataChart._id}
      width={
        template?.index
          ? currentStyle.width
          : responsiveWidth
          ? `${currentStyle.width - responsiveWidth}px`
          : `${currentStyle.width}px`
      }
      height={
        template?.index
          ? currentStyle.height
          : responsiveHeight
          ? `${currentStyle.height - responsiveHeight}px`
          : `${currentStyle.height}px`
      }
    >
      {role === "Administrador de compañía" &&
        !restore &&
        chartsIconsShowOnOff && (
          <button
            className="delete-card-button"
            onClick={() =>
              currentOrderName === "Design New View"
                ? setDeleteChartDialog(true)
                : handlerDeleteChartOnView()
            }
          >
            <CloseIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </button>
        )}

      {role === "Administrador de compañía" &&
        restore &&
        chartsIconsShowOnOff && (
          <button
            className="restore-card-button"
            onClick={() => handleRestoreChart()}
          >
            <RestartAltIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </button>
        )}

      {template && (
        <>
          <ResizeRight>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleAddGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleRemoveGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeRight>
          <ResizeBottom>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleRemoveGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleAddGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeBottom>
        </>
      )}
      <CardBar position={template ? "absolute" : "relative"}>
        <Bar
          className="chart-dashboard-element-array"
          data={{
            labels: currentStyleBarDataChart.data.labels,
            datasets: currentStyleBarDataChart.data.datasets.map((item) => {
              if (
                item.hasOwnProperty("colorsByLabel") &&
                item.colorsByLabel.length > 0 &&
                item.type !== "line"
              ) {
                return {
                  ...item,
                  data: item.data.map((data) => {
                    if (typeof data === "object") {
                      return (data = data.value);
                    } else {
                      return data;
                    }
                  }),
                  backgroundColor: item.dataGradient.display
                    ? item.colorsByLabelGradient
                    : item.colorsByLabel,
                };
              } else {
                return item;
              }
            }),
          }}
          options={{
            responsive: true,
            indexAxis: currentStyleBarDataChart.currentHorizontalBar
              ? "y"
              : "x",
            layout: {
              padding: {
                left: 10,
                right: 20,
                top: 10,
                bottom: 10,
              },
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  if (context.dataset.constantValue) {
                    return context.dataset?.datalabels?.displayTotal;
                  }
                  return currentStyleBarDataChart.currentDisplayTotal;
                },
                align: function (context) {
                  if (context.dataset.constantValue) {
                    return context.dataset?.datalabels?.positionTotal;
                  }
                  return currentStyleBarDataChart.currentTotalPosition;
                },
                anchor: function (context) {
                  if (context.dataset.constantValue) {
                    return context.dataset?.datalabels?.positionTotal;
                  }
                  return currentStyleBarDataChart.currentTotalPosition;
                },
                formatter: (value, context) => {
                  const dataset = context.dataset;
                  const dataIndex = context.dataIndex;
                  const totalDataPoints = context.dataset.data.length;
                  if (
                    !currentStyleBarDataChart.currentDisplayShowCerosTotal &&
                    value === 0
                  ) {
                    return null;
                  }
                  if (dataset.constantValue !== null) {
                    if (dataIndex === totalDataPoints - 1) {
                      return dataset.constantValue === 0
                        ? null
                        : dataset.constantValue;
                    } else {
                      return null;
                    }
                  }
                  return fixedDecimalNumber({
                    number: value,
                    numDecimals:
                      currentStyleBarDataChart.currentTotalNumDecimals,
                  });
                },
                color: function (context) {
                  if (context.dataset.constantValue) {
                    return context.dataset?.datalabels?.colorTotal;
                  }
                  return currentStyleBarDataChart.currentTotalFontColor;
                },
                backgroundColor: function (context) {
                  if (context.dataset.constantValue) {
                    return context.dataset?.datalabels?.backgroundColorTotal;
                  }
                  return currentStyleBarDataChart.currentDisplayBackgroundTotal
                    ? createRgbaColor({
                        hexadecimalColor:
                          currentStyleBarDataChart?.currentTotalBackground,
                      })
                    : null;
                },
                borderWidth: 1,
                borderColor: function (context) {
                  if (context.dataset.constantValue) {
                    return context.dataset?.datalabels?.backgroundColorTotal;
                  }
                  return currentStyleBarDataChart.currentDisplayBackgroundTotal
                    ? currentStyleBarDataChart?.currentTotalBackground
                    : null;
                },
                borderRadius: 3,
                font: function (context) {
                  if (context.dataset.constantValue) {
                    return {
                      size: context.dataset?.datalabels?.fontSizeTotal,
                    };
                  }
                  return {
                    size: currentStyleBarDataChart.currentTotalFontSize,
                  };
                },
                offset: function (context) {
                  if (context.dataset.constantValue) {
                    return context.dataset?.datalabels?.offsetTotal;
                  }
                  return 2;
                },
              },
              title: {
                display: true,
                color: currentStyleBarDataChart.currentTitleColor,
                text: currentStyleBarDataChart.currentNameBarChart,
                font: {
                  size: currentStyleBarDataChart.currentTitleFontSize,
                  family: "Montserrat",
                },
                padding: {
                  top: 20,
                },
              },
              legend: {
                display: true,
                onClick: function (e, legendItem, legend) {
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
                  const meta = ci.getDatasetMeta(index);
                  meta.hidden =
                    meta.hidden === null
                      ? !ci.data.datasets[index].hidden
                      : null;
                  ci.update();
                },
                labels: {
                  generateLabels: function (chart) {
                    return currentStyleBarDataChart.data.datasets.map(
                      (dataset, i) => {
                        const meta = chart.getDatasetMeta(i);
                        return {
                          text: dataset.alias,
                          fillStyle: dataset.backgroundColor,
                          hidden: meta.hidden,
                          lineCap: dataset.borderCapStyle,
                          lineDash: dataset.borderDash,
                          lineDashOffset: dataset.borderDashOffset,
                          lineJoin: dataset.borderJoinStyle,
                          lineWidth: dataset.borderWidth,
                          strokeStyle: dataset.borderColor,
                          pointStyle: dataset.pointStyle,
                          datasetIndex: i,
                          fontStyle: meta.hidden ? "italic" : "normal",
                          fontColor: meta.hidden ? "#999" : "#666",
                        };
                      }
                    );
                  },
                },
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (context) {
                    return `${dataChart.type}: ${context.raw}`;
                  },
                },
                titleFont: {
                  size: currentStyleBarDataChart.currentTitleTooltipFontSize,
                },
                bodyFont: {
                  size: currentStyleBarDataChart.currentBodyTooltipFontSize,
                },
                mode: "nearest",
                intersect: true,
                titleAlign: "center",
                displayColors: false,
              },
            },
            animation: {
              duration: 1500,
              easing: "easeInOutQuart",
            },
            scales: {
              x: {
                grid: {
                  display: currentStyleBarDataChart.currentDisplayAxisX,
                  color: createRgbaColor({
                    hexadecimalColor:
                      currentStyleBarDataChart?.currentAxisXColor,
                  }),
                },
                ticks: {
                  font: {
                    size: currentStyleBarDataChart.currentLabelsXFontSize,
                  },
                  color: currentStyleBarDataChart.currentLabelsXColor,
                  padding: currentStyleBarDataChart.currentPaddingAxisX,
                },
                title: {
                  display: currentStyleBarDataChart.currentDisplayTitleX,
                  text: currentStyleBarDataChart.currentTitleAxisX,
                  font: {
                    size: currentStyleBarDataChart.currentTitleXFontSize,
                  },
                  color: currentStyleBarDataChart.currentTitleXColor,
                  padding: {
                    top: -currentStyleBarDataChart.currentPaddingAxisX,
                  },
                },
              },
              y: {
                beginAtZero: currentStyleBarDataChart.currentStatusBeginAtZero,
                min: currentStyleBarDataChart.currentMinValueAxis,
                max: currentStyleBarDataChart.currentMaxValueAxis,
                grid: {
                  display: currentStyleBarDataChart.currentDisplayAxisY,
                  color: createRgbaColor({
                    hexadecimalColor:
                      currentStyleBarDataChart?.currentAxisYColor,
                  }),
                },
                ticks: {
                  stepSize: currentStyleBarDataChart?.currentStepSizeY,
                  font: {
                    size: currentStyleBarDataChart.currentLabelsYFontSize,
                  },
                  color: currentStyleBarDataChart.currentLabelsYColor,
                  padding: currentStyleBarDataChart.currentPaddingAxisY,
                },
                title: {
                  display: currentStyleBarDataChart.currentDisplayTitleY,
                  text: currentStyleBarDataChart.currentTitleAxisY,
                  font: {
                    size: currentStyleBarDataChart.currentTitleYFontSize,
                  },
                  color: currentStyleBarDataChart.currentTitleYColor,
                  padding: {
                    bottom: -currentStyleBarDataChart.currentPaddingAxisY,
                  },
                },
              },
            },
          }}
        />
      </CardBar>
    </CardContainer>
  );
};

export default BarChart;
