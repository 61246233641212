import { useState, useEffect } from "react";

function updateColumnsWithValues({ columnsValues, columnsLibrary }) {
  return columnsValues.map((column) => {
    const matchingLibraryColumn = columnsLibrary.find(
      (libColumn) =>
        libColumn.name === column.columnName && libColumn.type === "select"
    );

    if (matchingLibraryColumn) {
      return {
        ...column,
        values: [
          ...new Set([...column.values, ...matchingLibraryColumn.options]),
        ],
      };
    }

    return column;
  });
}

const useColumnsWithUniqueValues = ({
  handlerClickColumn,
  events,
  columnsSelected,
  columnsLibrary,
}) => {
  const [columnsWithValues, setColumnsWithValues] = useState();


  useEffect(() => {
    const columnsWithValues = columnsSelected.map((columnName) => {
      const valuesEvents = events.map((item) => {
        const { attributes } = item;
        // if need to convert values to JSON
        const newAttributes = attributes.map((item) => {
          const newItem = { ...item };
          try {
            if (item.value && item.value[0] === "{") {
              const parsedValue = JSON.parse(item.value);
              if (parsedValue.hasOwnProperty("value")) {
                newItem.value = parsedValue.value;
              }
            }
          } catch (error) {
            return newItem;
          }
          return newItem;
        });
        //
        const attribute = newAttributes.find(
          (attr) => attr.name === columnName
        );
        return attribute;
      });
      const valuesEventsFiltered = valuesEvents.filter((item) => item?.value);
      const values = valuesEventsFiltered.map((item) => item?.value);
      const uniqueValues = [...new Set(values)];

      return {
        columnName: columnName,
        values: uniqueValues,
      };
    });

    const newColumnsWithValues = updateColumnsWithValues({
      columnsValues: columnsWithValues,
      columnsLibrary,
    });

    setColumnsWithValues(newColumnsWithValues);
  }, [handlerClickColumn, events, columnsSelected, columnsLibrary]);

  return { columnsWithValues, setColumnsWithValues };
};

export default useColumnsWithUniqueValues;
