import styled from "styled-components";
import {
  SECOND_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
  BLACK_GRAY_COLOR,
  BLACK_COLOR,
  ICON_GREEN_COLOR,
} from "utils/const";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 10px;

  .title {
    margin: 20px;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }

  .subtitle {
    margin: 20px 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
  }

  .subtitle-h3 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
  }

  .container-type-charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto;
    gap: 10px;

    .card-type-chart {
      display: flex;
      border: 1px solid ${BLACK_COLOR};
      border-radius: 5px;
      padding: 10px;
      gap: 10px;
      cursor: pointer;
      text-align: center;
      place-items: center;
      place-content: center;

      .icon {
        width: 40%;
      }
      .name {
        width: 60%;
        text-align: justify;
      }

      &:hover {
        background-color: ${SECOND_COLOR};
      }
    }
  }

  .alias {
    width: 100%;
    align-self: center;
    border: 1px solid ${BLACK_GRAY_COLOR};
    border-radius: 5px;
    padding: 10px;
  }

  .checkbox-container {
    display: flex;
    justify-content: center;
  }

  .preview_data_time_series {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .item {
      width: 5%;
    }

    .lineName {
      width: 50%;
    }

    .linePreview {
      width: 40%;
    }

    .delete {
      width: 5%;
    }
  }

  input[type="checkbox"] {
    --webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 50px;
    height: 39px;
    border: 1px solid ${BLACK_COLOR};
    border-radius: 5px;
    display: grid;
    place-content: center;
    text-align: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 40px;
    height: 30px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${ICON_GREEN_COLOR};
    border-radius: 4px;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  .btn-add-chart {
    width: 100%;
    margin: 20px 0;
    align-self: center;
    border: none;
    background-color: ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 10px;
    color: ${WHITE_COLOR};

    &:hover {
      background-color: ${SECOND_COLOR};
      cursor: pointer;
      color: ${BLACK_COLOR};
    }
  }
`;

export const ContentFilterItemLabel = styled("label")`
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
  color: ${(props) =>
    props.theme === "light" ? WHITE_COLOR : document.body.style.color};

  .input-start {
    margin-bottom: 10px;
  }
`;
