import { useState } from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import ListUser from "components/Tables/BasicTable/ListOperators";
import Layout from "components/Layout/AdminDecimetrix";
import { Title, UserContainer } from "./indexStyle";

const ListUsers = () => {
  return (
    <Layout>
      <UserContainer className="background-1">
        <Container
          style={{
            paddingTop: 80,
          }}
          maxWidth="xl"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <Title className="title">
                  <h1>Users</h1>
                </Title>
              </Grid>
            </Grid>
          </Box>
          <div>
            <ListUser />
          </div>
        </Container>
      </UserContainer>
    </Layout>
  );
};

export default ListUsers;
