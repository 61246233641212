import { useCallback } from "react";
const HandlerClickColumn = ({
  setColumnsSelected,
  columnValues,
  setColumnValues,
  multi = true,
}) => {
  const handlerClickColumn = useCallback(
    (name) => {
      setColumnsSelected((current) => {
        if (current.includes(name)) {
          const filteredColumnValues = Object.keys(columnValues).reduce(
            (result, columnName) => {
              if (columnName !== name) {
                result[columnName] = columnValues[columnName];
              }
              return result;
            },
            {}
          );
          setColumnValues(filteredColumnValues);
          return current.filter((item) => item !== name);
        }
        if (multi) return [...current, name];
        return [name];
      });
    },
    [columnValues, setColumnValues, setColumnsSelected, multi]
  );

  return handlerClickColumn;
};

export default HandlerClickColumn;
