import axios from "axios";
import { config } from "config.js";

export const saveCaptureMedia = async ({ lineObjectMgId, media }) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.patch(
    `${config.URL_BACKEND_MG}line/${lineObjectMgId}`,
    {
      $push: {
        captureMedia: {
          $each: media,
        },
      },
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};

export const updateCaptureMediaLines = async ({
  endPoint,
  mongoId,
  mediaId,
  media,
}) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png",
      "rotation": "tipo numero - posicion imagen"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.patch(
    `${config.URL_BACKEND_MG}${endPoint}/${mongoId}/media/${mediaId}`,
    media,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};

export const deleteCaptureMediaLines = async ({
  endPoint,
  mongoId,
  mediaId,
}) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png",
      "rotation": "tipo numero - posicion imagen"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${config.URL_BACKEND_MG}${endPoint}/${mongoId}/media/${mediaId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};
