import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";

const SelectEventTypes = ({
  eventTypes,
  librarySelected,
  handlerClickEventType,
  typeIdsSelected,
  typeBar,
}) => {
  return (
    <>
      <h2 className="subtitle">Select type</h2>
      <section className="container-type-charts ">
        {eventTypes
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((eventType) =>
            typeBar === "countByType"
              ? true
              : eventType.pointLibraryEventId === librarySelected
          )
          .map((eventType) => (
            <div
              key={eventType.id}
              className="card-type-chart"
              onClick={() =>
                handlerClickEventType(
                  eventType.id,
                  eventType.pointLibraryEventId,
                  eventType
                )
              }
              style={{
                backgroundColor: typeIdsSelected.includes(eventType.id)
                  ? ICON_GREEN_COLOR
                  : WHITE_COLOR,
              }}
            >
              <p>{eventType.name} </p>
              <span>
                <img
                  width="30px"
                  src={
                    eventType.icon
                      ? eventType.icon
                      : "https://static.thenounproject.com/png/79163-200.png"
                  }
                  alt="icon eventLibraries type"
                />
              </span>
            </div>
          ))}
      </section>
    </>
  );
};

export default SelectEventTypes;
