export function calculateOnlyOneOption(options) {
  let sameCero = 0;
  let differentCero = 0;

  options.forEach((elm) => {
    if (elm.uniqueValue === 0) {
      sameCero++;
    } else {
      differentCero++;
    }
  });

  return differentCero === 1;
}
