// React
import { useEffect, useState } from "react";
// Dependecies
import { useSWRConfig } from "swr";
// Components
import { ContainerOperations } from "./LineDetailsStyles";
import OpenDialog from "../../Dialogs/OpenDialog";
import TableLine from "./TableLine";
import LineMap from "components/Maps/LineMap";
import PhotoGallery from "../PhotoGalley/PhotoGallery";
import AddPicture from "./AddPicture/AddPicture";
// Hooks
import useMediaElement from "hooks/useMediaElement";
// Style
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";

import { InfoTitle } from "../ImageDetailsElements";

// Config
import { config } from "config.js";
import GetLinesArrays from "components/Forms/SaveLines/hooks/getLibraryArrays";
import GalleryByType from "./GalleryByType";
import DetailTypeIformation from "../DetailTypeInformation";
import { useContext } from "react";
import { ThemeContext } from "App";
import { WHITE_COLOR } from "utils/const";
const LineDetails = ({ lineId, deletedLine, setShowLineDetails }) => {
  // Constants
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { mutate } = useSWRConfig();
  const { theme } = useContext(ThemeContext);

  const [errorMessage, setErrorMessage] = useState(false);
  const [line, setLine] = useState({});
  const [lineLibrary, setLineLibrary] = useState(false);

  const { linesArray, errorLines, linesLibrariesArray } = GetLinesArrays({
    adminCompanyId,
    lineId,
  });

  // listen the lineId and set the line with all properties
  useEffect(() => {
    setLine(linesArray?.[0]);
    setLineLibrary(
      linesLibrariesArray?.find((elm) => {
        return linesArray?.[0].lineType.lineLibraryId === elm.id;
      })
    );
  }, [lineId, linesArray, linesLibrariesArray]);

  const { mediaObject: mediaLine, widthMap } = useMediaElement({
    element: line,
  });

  const handleUpdateData = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/lines?id=${line?.id}`);
  };

  return (
    <>
      {line && !errorLines && (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            position: "relative",
            width: "100vw",
          }}
        >
          <OpenDialog
            openDialog={errorMessage}
            setOpenDialog={setErrorMessage}
            content={{
              title: "Conexion lost",
              description: "Please check your internet conexion and try again.",
              disagree: "Continue",
            }}
          />
          <ContainerOperations>
            {/* --LINE DETAILS-- */}
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className={`info-event ${theme === "light" ? "bb" : "bb2"}`}>
                <DetailTypeIformation
                  typeName={line?.lineType?.type}
                  typeLibrary={lineLibrary?.name}
                  iconLibrary={lineLibrary?.icon}
                  type={"Line"}
                  dataLine={line}
                />
                <InfoTitle>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <section className="main-tables-container">
                      {/* Line Details */}
                      {Object.keys(line)?.length > 0 && (
                        <TableLine
                          line={line}
                          deletedLine={deletedLine}
                          deleteButton={true}
                          handleUpdateData={handleUpdateData}
                          setShowLineDetails={setShowLineDetails}
                        />
                      )}
                    </section>
                  </Grid>
                </InfoTitle>
              </div>
            </Grid>

            {/* --LINE GALLERY-- */}

            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div
                className={`photo-container-image ${
                  theme === "light" ? "bb" : "bb2"
                }`}
              >
                <h1
                  className="event-title"
                  style={{
                    color:
                      theme === "light"
                        ? "var(--background-primary_color)"
                        : WHITE_COLOR,
                  }}
                >
                  <span>GALLERY</span>
                </h1>
                <div
                  className="gallery"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 24px 0px 20px",
                  }}
                >
                  {line?.captureMedia?.length > 0 ? (
                    <>
                      <p
                        style={{
                          fontSize: "1.6rem",
                          color:
                            theme === "light"
                              ? "var(--background-primary_color)"
                              : WHITE_COLOR,
                        }}
                      >
                        <b>Total Pictures: </b>{" "}
                        {[...line?.captureMedia]?.length}
                      </p>
                      <br />
                      <PhotoGallery
                        photos={[...line?.captureMedia]}
                        ownId={line?.id}
                        mongoId={line?.geojsonLineId}
                        type={"lineObject"}
                        uploadImages={handleUpdateData}
                      />
                      <AddPicture line={line} uploadImages={handleUpdateData} />
                    </>
                  ) : (
                    <AddPicture line={line} uploadImages={handleUpdateData} />
                  )}
                </div>
              </div>
            </Grid>

            {/* GALLERIES BY TYPE  */}
            <Grid
              container
              spacing={3}
              justifyContent="flex-start"
              alignItems="center"
            >
              {mediaLine && line && (
                <GalleryByType line={line} mediaLine={mediaLine} />
              )}

              {/* -- MAP -- */}
              {widthMap && (
                <Grid item xs={12} md={12} lg={widthMap} xl={widthMap}>
                  <div
                    className={`content-map ${
                      theme === "light" ? "bb" : "bb2"
                    }`}
                  >
                    <h1
                      className="event-title"
                      style={{
                        color:
                          theme === "light"
                            ? "var(--background-primary_color)"
                            : WHITE_COLOR,
                      }}
                    >
                      <span>LOCATION</span>
                    </h1>
                    {line && <LineMap line={line} />}
                  </div>
                </Grid>
              )}
            </Grid>
          </ContainerOperations>
        </Box>
      )}
    </>
  );
};

export default LineDetails;
