import axios from "axios";
import { config } from "config.js";

export const createOrderMapView = async ({ body }) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${config.URL_BACKEND_MG}order-map-views`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );

    const order = await res.data;
    return order;
  } catch (error) {
    console.error(error);
    throw new Error("Error creating order map view");
  }
};
