import useStoreDashboard from "pages/Dashboard/Store";
import { useEffect } from "react";
import { RED_COLOR } from "utils/const";

const HandlerShowPrintGuidelines = ({ options, setOptions }) => {
  const { guidelines, orientationRows, landscape, portrait } = options;
  const { dashboardRef } = useStoreDashboard();

  // GRID
  const ulElementRef = document.getElementById("chart-dashboard-element-array");
  const ulStyles = window.getComputedStyle(ulElementRef);

  const totalGaps = (gap, rows) => gap * rows;
  const showOffLines = () => {
    // show off guidelines (false)
    const dottedDivs = document.getElementsByClassName("pdf-red-guidelines");
    Array.from(dottedDivs).map((item) => {
      item.remove();
      return item;
    });
  };

  useEffect(() => {
    if (!guidelines) {
      showOffLines();
    } else {
      if (landscape || portrait) showOffLines();
      // COLUMNS
      const gridColumns = ulStyles
        .getPropertyValue("grid-template-columns")
        .split(" ").length;
      const gridColumnGap = parseInt(
        ulStyles.getPropertyValue("grid-column-gap").match(/\d+/)
      );
      const columnSizes = ulStyles.getPropertyValue("grid-template-columns");
      const columnSize = parseInt(columnSizes.split(" ")?.[0].match(/\d+/));
      const sectionWidth =
        columnSize * gridColumns + totalGaps(gridColumnGap, gridColumns);
      // ROWS
      const gridRows = ulStyles
        .getPropertyValue("grid-template-rows")
        .split(" ").length;
      // Obtener el valor de gap
      const gridRowGap = parseInt(
        ulStyles.getPropertyValue("grid-row-gap").match(/\d+/)
      );
      // row height
      const rowSizes = ulStyles.getPropertyValue("grid-template-rows");
      const rowSize = parseInt(rowSizes.split(" ")?.[0].match(/\d+/));
      // display guidelines (true)
      const sectionHeight =
        rowSize * orientationRows + totalGaps(gridRowGap, orientationRows);

      const totalSections = (() => {
        const basic = gridRows / orientationRows;
        return basic > parseInt(basic) ? parseInt(basic) + 1 : parseInt(basic);
      })();

      let dottedDiv = null;
      let dottedDivFooter = null;
      let cellStart = 85;

      const dottedDivs = document.getElementsByClassName("pdf-red-guidelines");
      if (dottedDivs.length === 0) {
        const aux = [];
        for (let index = 0; index < totalSections; index++) {
          dottedDivFooter = document.createElement("div");
          dottedDivFooter.className = "pdf-red-guidelines-footer";
          dottedDivFooter.style.width = "100px";
          dottedDivFooter.style.height = "10px";
          dottedDivFooter.style.marginTop = `${sectionHeight - 50}px`;
          dottedDivFooter.style.marginLeft = "90%";
          dottedDivFooter.style.fontSize = "2rem";
          dottedDivFooter.style.color = RED_COLOR;
          dottedDivFooter.innerText = `page ${
            index >= 0 || index ? index + 1 : ""
          }`;

          dottedDiv = document.createElement("div");
          dottedDiv.className = "pdf-red-guidelines";
          dottedDiv.style.border = "3px dashed red";
          dottedDiv.style.width = `${sectionWidth}px`;
          dottedDiv.style.height = `${sectionHeight}px`;
          dottedDiv.style.position = "absolute";
          dottedDiv.style.marginTop = `${cellStart}px`;
          dottedDiv.style.display = "flex";
          dottedDiv.style.flexDirection = "row";
          dottedDiv.style.justifyContent = "flex-end";
          dottedDiv.appendChild(dottedDivFooter);

          cellStart += sectionHeight;
          aux.push(dottedDiv);
        }

        if (dashboardRef.current) {
          for (const elmnt of aux) {
            dashboardRef.current.appendChild(elmnt);
          }
        }
      }
    }
  }, [dashboardRef, guidelines, options, orientationRows]);
  return <></>;
};
export default HandlerShowPrintGuidelines;
