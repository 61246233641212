import axios from "axios";
import { config } from "config.js";

export const findLinesObjects = async ({ id, lineId, objectId }) => {
  const token = localStorage.getItem("token");
  const urlSearchParams = new URLSearchParams();
  if (id) urlSearchParams.append("id", id);
  if (objectId) urlSearchParams.append("objectId", objectId);
  if (lineId) urlSearchParams.append("lineId", lineId);
  const url = `${
    config.URL_BACKEND_PG
  }api/v1/lines-objects?${urlSearchParams.toString()}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const dataRaw = await res.data;
  const viewed = new Set();
  const data = dataRaw.filter((item) => {
    if (!viewed.has(item.objectId)) {
      viewed.add(item.objectId);
      return true;
    }
    return false;
  });
  return data;
};
