import findTypeObjectsTypeObjects from "services/typeObjectsTypeObjects/find.js";
import createTypeObjectsTypeObjects from "services/typeObjectsTypeObjects/create.js";
import deleteTypeObjectsTypeObjects from "services/typeObjectsTypeObjects/delete.js";

/**
 * Updates the type objects type objects.
 *
 * @param {Object} typeObjectTypeObject - The type object type object.
 * @param {Object} typeObjectsTypeObjects - The type objects type objects.
 * @param {function} setTypeObjectsTypeObjects - The function to set the type objects type objects.
 * @return {function} The update function.
 */
const UpdateTypeObjectsTypeObjects = ({
  typeObjectTypeObject,
  typeObjectsTypeObjects,
  setTypeObjectsTypeObjects,
}) => {
  const update = async () => {
    const { id } = typeObjectTypeObject;
    const lastObjectTypes = await findTypeObjectsTypeObjects({
      pointTypeObjectId: id,
    });
    const toDelete = [];
    const toCreate = [];

    lastObjectTypes.forEach(({ pointTypeObjectRelationId }) => {
      if (!typeObjectsTypeObjects[pointTypeObjectRelationId]) {
        const ids = lastObjectTypes
          .filter(
            (last) =>
              last.pointTypeObjectRelationId === pointTypeObjectRelationId
          )
          .map((last) => last.id);
        ids.filter((item, index) => ids.indexOf(item) === index);
        toDelete.push(...ids);
      }
    });

    Object.keys(typeObjectsTypeObjects).forEach((pointTypeObjectRelationId) => {
      if (
        !lastObjectTypes.find(
          (e) =>
            parseInt(e.pointTypeObjectRelationId) ===
            parseInt(pointTypeObjectRelationId)
        )
      )
        toCreate.push(pointTypeObjectRelationId);
    });

    await Promise.all(
      toCreate.map(async (pointTypeObjectRelationId) => {
        await createTypeObjectsTypeObjects({
          pointTypeObjectId: typeObjectTypeObject.id,
          pointTypeObjectRelationId: parseInt(pointTypeObjectRelationId),
        });
      })
    );

    await Promise.all(
      toDelete.map(async (id) => {
        await deleteTypeObjectsTypeObjects(id);
      })
    );

    setTypeObjectsTypeObjects({});
  };

  return update;
};

export default UpdateTypeObjectsTypeObjects;
