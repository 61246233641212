import { useState, useEffect } from "react";
import { config } from "config.js";
import useSwr from "swr";

const useFindObjects = () => {
  const [loading, setLoading] = useState(true);

  // use local storage
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // Fetch objects data
  const url = `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`;
  const { data, error } = useSwr(url);

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  const objects = data && !error ? data : [];

  return { objects: objects[0] , loading };
};

export default useFindObjects;
