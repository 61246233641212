import {
  SET_VIEW_MODE_COLLECTION,
  SET_SECTION_COLLECTION,
  SET_DATE_COLLECTION,
  RESET_STATES,
} from "./../actions/types";

const initialValues = {
  viewModeCollection: "List",
  sectionCollection: "map",
  dateCollection: {},
};

const ViewModeCollectionReducer = (state = initialValues, action) => {
  switch (action.type) {
    case SET_VIEW_MODE_COLLECTION:
      return {
        ...state,
        viewModeCollection: action.payload,
      };
    case SET_SECTION_COLLECTION:
      return {
        ...state,
        sectionCollection: action.payload,
      };
    case SET_DATE_COLLECTION:
      return {
        ...state,
        dateCollection: action.payload,
      };
    case RESET_STATES:
      return initialValues;
    default:
      return state;
  }
};

export default ViewModeCollectionReducer;
