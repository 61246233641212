import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LayersIcon from "@mui/icons-material/Layers";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { setUpdateRegion } from "redux/actions/index";
import { setTableDetailsForward } from "redux/actions/digitalTwin";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

import OpenDialog from "components/Dialogs/OpenDialog";
// Const
import {
  WHITE_SECOND_COLOR,
  SECOND_COLOR,
  GREY_TRANS,
  INFORMATION_COLOR,
} from "utils/const";

import { Checkbox } from "@mui/material";

// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// redux actions Admin
import {
  showRegionDetailsDrawer,
  SetUsers,
  SetShowUsers,
  setGeometryPolygon,
  setDataObjectsPolygon,
  setFeaturesDetailsDT,
} from "redux/actions/admin";

import AccordionDigitalTwin from "./../Accordions/AccordionDigitalTwin";
import AccordionObjectsPolygon from "./../Accordions/AccordionObjectsPolygon";
import AccordionDetailsPolygon from "./../Accordions/AccordionDetailsPolygon";
import AccordionBookmarksViews from "../Accordions/AccordionBookmarksViews";

import {
  useStyles,
  CustomizedTypography,
  CustomizeToggleButtonGroup,
  CustomizedAccordion,
  FilterTabStyles,
  ContentFilters,
  ContentFilterItem,
  ButtonInfo,
} from "./MapDrawerStyle";

import FilterByDate from "components/Accordions/FilterByDate";
import GenericFields from "components/Forms/SaveObject";
import AccordionSpecialFilters from "components/Accordions/AccordionSpecialFilters";

import { LoadCSVLines } from "components/Uploads/loadCSV/LoadCSVLines";
import { LoadCSVObjects } from "components/Uploads/loadCSV/LoadCSVObjects";

export default function MapDrawer({ drawState, dataObjects }) {
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const openRegionDetails = useSelector(
    (state) => state.adminReducer.showRegionDetailsDrawer
  );

  const role = localStorage.getItem("role");
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState("layers");
  const [showSaveObject, setShowSaveObject] = useState(false);

  const [messageCSVLines, setMessageCSVLines] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleAlignment = (event) => {
    setSection(event.currentTarget.value);
  };

  const featureDetails = useSelector(
    (state) => state.adminReducer.setFeaturesDetailsDT
  );

  const users = useSelector((state) => state.adminReducer.SetUsers);
  const showUsers = useSelector((state) => state.adminReducer.SetShowUsers);

  useEffect(() => {
    setShowSaveObject(() => {
      if (featureDetails.geometry.type === "Point") return true;
      return false;
    });
  }, [featureDetails]);

  const handleChangeUserFilter = (event, id) => {
    const { isShow } = users[id];
    users[id].isShow = !isShow;
    dispatch(SetUsers(users));
    dispatch(SetShowUsers(!showUsers));
  };

  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  const updateRegion = useSelector(
    (state) => state.digitalTwinReducer.updateRegion
  );

  useEffect(() => {
    if (drawState === true) {
      handleDrawerOpen();
    } else if (drawState === false) {
      handleDrawerClose();
    } else {
    }
  }, [role, drawState]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const closeRegionDetails = () => {
    dispatch(showRegionDetailsDrawer(false));
  };

  const closeRegionCreatingDetails = () => {
    dispatch(setGeometryPolygon(null));
    dispatch(setDataObjectsPolygon([]));
    dispatch(setUpdateRegion({ id: null, isUpdate: false, data: {} }));
  };

  const handleClickDrawer = () => {
    dispatch(setTableDetailsForward(9));
  };

  return (
    <div style={{ position: "relative" }} onClick={handleClickDrawer}>
      <Drawer
        className="background-2"
        sx={{
          width: 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: {
              xs: 240,
              sm: 320,
            },
            height: "calc(100vh - 90px)",
            borderRadius: "5px",
            boxSizing: "border-box",
            margin: "85px 7px",
            background: "var(--background-color3)",

            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              borderRadius: "5px",
            },
            "::-webkit-scrollbar-track-piece": {
              backgroundColor: WHITE_SECOND_COLOR,
              borderRadius: "5px",
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className="background-2" style={{ marginTop: "10px" }}>
          <div>
            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              <CustomizeToggleButtonGroup
                value={section}
                onChange={handleAlignment}
                size="large"
                exclusive
                fullWidth={true}
                aria-label="text view"
              >
                <ToggleButton
                  className="border-color-1"
                  sx={{
                    fontSize: "1.4rem",
                    fontFamily: document.body.style.fontFamily,
                    color: GREY_TRANS,
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: SECOND_COLOR,
                    },
                  }}
                  value="layers"
                >
                  <LayersIcon className="color1" />
                  <p> Workspace</p>
                </ToggleButton>
                <ToggleButton
                  className="border-color-1 "
                  sx={{
                    fontSize: "1.4rem",
                    fontFamily: document.body.style.fontFamily,
                    color: GREY_TRANS,
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: SECOND_COLOR,
                    },
                  }}
                  value="filters"
                >
                  <FilterAltIcon className="color1" />
                  <p>Filters</p>
                </ToggleButton>
              </CustomizeToggleButtonGroup>
            </List>
            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              {section === "layers" && (
                <div className="background-2">
                  <div>
                    {role === "Administrador de compañía" && (
                      <List
                        sx={{
                          height: "100%",
                          position: "bottom",
                        }}
                        subheader={<li />}
                      >
                        <CustomizeToggleButtonGroup
                          size="medium"
                          fullWidth={true}
                          aria-label="text view"
                          style={{ marginTop: "5px" }}
                        >
                          <CustomizedTypography
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontFamily: document.body.style.fontFamily,
                              color: document.body.style.color,
                            }}
                          >
                            CSV Objects
                          </CustomizedTypography>
                          <Button
                            className="color1 border-color-1"
                            style={{
                              borderRadius: "5px",
                            }}
                            sx={{
                              fontSize: "1.4rem",
                              fontFamily: document.body.style.fontFamily,
                              color: document.body.style.color,
                              width: {
                                xs: 120,
                                sm: 150,
                              },
                            }}
                            // onClick={handleLoadObjects}
                          >
                            <AddIcon className="color1" />
                            <LoadCSVObjects />
                          </Button>
                        </CustomizeToggleButtonGroup>
                        <CustomizeToggleButtonGroup
                          size="medium"
                          fullWidth={true}
                          aria-label="text view"
                          style={{ marginTop: "5px" }}
                        >
                          <CustomizedTypography
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontFamily: document.body.style.fontFamily,
                              color: document.body.style.color,
                            }}
                          >
                            CSV Lines
                            <ButtonInfo
                              onClick={() =>
                                setMessageCSVLines(!messageCSVLines)
                              }
                              transform={"translate(0%, -50px)"}
                            >
                              <ContactSupportIcon
                                sx={{
                                  fontSize: "1.6rem",
                                  color: `${INFORMATION_COLOR}`,
                                  ml: "-3px",
                                  mb: "15px",
                                }}
                              />
                              {messageCSVLines && (
                                <OpenDialog
                                  openDialog={messageCSVLines}
                                  setOpenDialog={setMessageCSVLines}
                                  content={{
                                    title: "Help CSV lines format",
                                    description: (
                                      <div>
                                        <p>
                                          Please refer to{" "}
                                          <a
                                            target="_blank"
                                            href="/library-configurations"
                                          >
                                            Library configurations.
                                          </a>
                                        </p>
                                        <p>
                                          Select a library you want by clicking
                                          the id in the table on the left.
                                        </p>
                                        <p>
                                          Click on "CSV Template" button for
                                          downloading empty file with lines CSV
                                          file headings.
                                        </p>
                                      </div>
                                    ),
                                    disagree: "Close",
                                  }}
                                />
                              )}
                            </ButtonInfo>
                          </CustomizedTypography>
                          <Button
                            className="color1 border-color-1"
                            style={{
                              borderRadius: "5px",
                            }}
                            sx={{
                              fontSize: "1.4rem",
                              fontFamily: document.body.style.fontFamily,
                              color: document.body.style.color,
                              width: {
                                xs: 120,
                                sm: 150,
                              },
                            }}
                            // onClick={handleLoadLines}
                          >
                            <AddIcon className="color1" />
                            <LoadCSVLines />
                          </Button>
                        </CustomizeToggleButtonGroup>
                      </List>
                    )}
                    <br />
                    <div
                      style={{
                        padding: "0px 12px",
                      }}
                    >
                      {dataObjects.length > 0 && (
                        <AccordionSpecialFilters dataObjects={dataObjects} />
                      )}
                      {dataObjects.length > 0 && (
                        <AccordionDigitalTwin dataObjects={dataObjects} />
                      )}
                      {/* card with the summary of the objects when creating the polygon */}
                      {geometryPolygon?.type === "Polygon" && (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                          >
                            <CloseIcon
                              onClick={closeRegionCreatingDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />
                            <Typography className="color1">
                              {updateRegion?.isUpdate
                                ? "Update Region"
                                : "New Region"}
                            </Typography>
                          </AccordionSummary>
                          <AccordionObjectsPolygon
                            dataObjects={dataObjectsPolygon}
                          />
                        </CustomizedAccordion>
                      )}
                      {/* Boorkmarks views */}
                      {
                        <CustomizedAccordion
                          className="background-1 border-color-1"
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                          style={{
                            borderRadius: "4px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1.5rem",
                                fontFamily: document.body.style.fontFamily,
                                color: document.body.style.color,
                                fontWeight: "bold",
                              }}
                            >
                              Map Views
                            </Typography>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon className="color1" />}
                            ></AccordionSummary>
                          </div>
                          <AccordionBookmarksViews />
                        </CustomizedAccordion>
                      }

                      {/* card with details of the region created  */}
                      {openRegionDetails &&
                      featureDetails.geometry.type == null ? (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                            // expandIcon={<ExpandMoreIcon />}
                          >
                            <CloseIcon
                              onClick={closeRegionDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "1.5rem",
                                color: document.body.style.color,
                              }}
                            >
                              Region Details
                            </Typography>
                            {/* <button>Edit Geometry</button> */}
                          </AccordionSummary>
                          <AccordionDetailsPolygon
                            dataObjects={dataObjectsPolygon}
                          />
                        </CustomizedAccordion>
                      ) : null}

                      <OpenDialog
                        openDialog={showSaveObject}
                        setOpenDialog={setShowSaveObject}
                        disagree={() => {
                          dispatch(
                            setFeaturesDetailsDT({
                              geometry: {
                                type: null,
                              },
                            })
                          );
                        }}
                        content={{
                          title: "Add new object",
                          description: <GenericFields />,
                        }}
                        maxWidth="1500px"
                        minWidth="300px"
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* ---section filters--- */}
              {section === "filters" && (
                <ContentFilters>
                  <CustomizedAccordion
                    className="background-1 color1 border-color-1 border-radius-1"
                    classes={{
                      content: classes.content,
                      expanded: classes.expanded,
                    }}
                    defaultExpanded={false}
                    style={{ marginBottom: "1rem" }}
                  >
                    <ContentFilterItem>
                      <Typography
                        sx={{
                          fontFamily: document.body.style.fontFamily,
                          color: document.body.style.color,
                        }}
                      >
                        Filter For User
                      </Typography>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="color1" />}
                      ></AccordionSummary>
                    </ContentFilterItem>
                    <AccordionSummary
                      style={{ color: document.body.style.color }}
                    >
                      <FilterTabStyles>
                        {Object.keys(users).map((key) => (
                          <div>
                            <Checkbox
                              defaultChecked={users[key].isShow}
                              onChange={(event) =>
                                handleChangeUserFilter(event, key)
                              }
                            />
                            <p>{users[key].operator}</p>
                          </div>
                        ))}
                        {/* {users.length !== 0 &&} */}
                      </FilterTabStyles>
                    </AccordionSummary>
                  </CustomizedAccordion>
                  <FilterByDate />
                  <OpenDialog
                    openDialog={showSaveObject}
                    setOpenDialog={setShowSaveObject}
                    disagree={() => {
                      dispatch(
                        setFeaturesDetailsDT({
                          geometry: {
                            type: null,
                          },
                        })
                      );
                    }}
                    content={{
                      title: "Add new object",
                      description: <GenericFields />,
                    }}
                    maxWidth="500px"
                  />
                </ContentFilters>
              )}
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
