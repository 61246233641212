import { useEffect, useState, useRef } from "react";

import "mathlive/fonts.css";
import "mathlive/static.css";

import {
  OperationsContainer,
  OperationsExpression,
  MathfieldContainer,
  MathfieldDisclaimer,
  KeyboardSpace,
} from "./OperationsElementsStyles";
import useSWR from "swr";
import { config } from "config";

import {
  getConstantLibrary,
  getLibraryIdFromOldOptions,
} from "helpers/expressions/expressionHelpers";
const keyboardAvailableFields = (fields) => {
  if (fields === undefined || fields.length === 0) return [];
  const campos = fields
    .filter((f) => f.type === "number" || f.type === "operation")
    .map((it) => {
      return {
        class: "ava",
        latex: it.name,
        width: 2,
        insert: it.name,
        datatype: it.type,
      };
    });
  return {
    label: "Available Fields",
    style: ".ava{ --keycap-font-size: 3rem; font-size: 2.5rem; }",
    rows: [campos],
  };
};

const keyboardGlobalConstants = (constants) => {
  if (constants === undefined || constants.length === 0)
    return { label: "Global constants", rows: [] };
  const campos = constants?.map((it) => {
    return {
      class: "ava",
      latex: it.name,
      width: 2,
      insert: it.name,
      datatype: "constant",
    };
  });
  return {
    label: "Global constants",
    style: ".ava{ --keycap-font-size: 3rem; font-size: 2.5rem; }",
    rows: [campos],
  };
};

function OperationsColumns({
  fields,
  setFields,
  setExpression,
  update,
  field,
  setField,
}) {
  const [operation, setOperation] = useState("");
  const [controlSelectLibrary, setControlSelectLibrary] = useState(false);
  const [valueSelect, setValueSelect] = useState(0);
  const [space, setSpace] = useState(false);

  const mathfieldRef = useRef(null);

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const idDecimetrix = localStorage.getItem("adminDecimetrixId");
  const urlParams = new URLSearchParams();
  if (adminCompanyId)
    urlParams.append("adminCompanyId", parseInt(adminCompanyId));
  if (idDecimetrix)
    urlParams.append("adminDecimetrixId", parseInt(idDecimetrix));

  const url = `${
    config.URL_BACKEND_PG
  }api/v1/constant-library?&${urlParams.toString()}`;
  const { data: constantLibrary, error: errorConstantLibrary } = useSWR(url);

  useEffect(() => {
    let mfe = mathfieldRef.current;
    mfe.value = operation;
    mfe.smartMode = true;
    mfe.defaultMode = "math";
    mfe.smartSuperscript = true;
    mfe.popoverPolicy = "auto";
    mfe.removeExtraneousParentheses = true;
    mfe.mathVirtualKeyboardPolicy = "auto";
    mfe.addEventListener("input", (evt) => {
      evt.preventDefault();
      if (mfe.getValue().length !== 0) {
        setControlSelectLibrary(true);
      } else {
        setControlSelectLibrary(false);
      }
      setOperation(mfe.getValue());
      setExpression(mfe.getValue());
    });
    mfe.addEventListener("focus", (evt) => {
      evt.preventDefault();
      setSpace(true);
      window.mathVirtualKeyboard.visible = true;
      window.mathVirtualKeyboard.layouts = [
        "numeric",
        keyboardAvailableFields(fields),
        !errorConstantLibrary && constantLibrary
          ? keyboardGlobalConstants(constantLibrary[0]?.globalConstants)
          : { label: "", rows: [] },
      ];
    });
    mfe.addEventListener("blur", () => {
      setSpace(false);
    });
    if (!update) {
      if (!errorConstantLibrary && constantLibrary !== undefined) {
        setField((current) => ({
          ...current,
          options:
            getConstantLibrary(
              constantLibrary,
              valueSelect
            )?.globalConstants?.map((it) => JSON.stringify(it)) || [],
        }));
      }
    }
  }, [constantLibrary, errorConstantLibrary]);

  useEffect(() => {
    if (update) {
      const mfe = mathfieldRef.current;
      const exprToUpdate = fields.find((f) => f.name === field.name);
      const expression = exprToUpdate?.operation;
      mfe.value = expression;

      setOperation(mfe.getValue());
      setExpression(mfe.getValue());

      if (mfe.getValue().length > 0 && field.options.length > 0) {
        if (!errorConstantLibrary && constantLibrary !== undefined) {
          const idLibOptions = getLibraryIdFromOldOptions({
            options: field.options,
          });
          setField((current) => ({
            ...current,
            options: getConstantLibrary(constantLibrary, idLibOptions)
              .globalConstants,
            operation: mfe.getValue(),
          }));
          setValueSelect(idLibOptions);
          setControlSelectLibrary(true);
        }
      } else {
        setField((current) => ({
          ...current,
          options: [],
          operation: mfe.getValue(),
        }));
        setControlSelectLibrary(false);
      }
    }
  }, [constantLibrary, errorConstantLibrary]);

  const handleChangeConstantLibrary = (e) => {
    const algo = constantLibrary?.find(
      (it) => it.id === parseInt(e.target.value)
    );
    if (algo) {
      let mfe = mathfieldRef.current;
      mfe.addEventListener("focus", (evt) => {
        evt.preventDefault();
        window.mathVirtualKeyboard.visible = true;
        window.mathVirtualKeyboard.layouts = [
          "numeric",
          keyboardAvailableFields(fields),
          keyboardGlobalConstants(algo.globalConstants),
        ];
      });
      setField((current) => ({
        ...current,
        options: algo.globalConstants?.map((it) => JSON.stringify(it)),
      }));
      setValueSelect(algo.id);
    }
  };

  return (
    <OperationsContainer id="operationcontainer">
      <OperationsExpression>
        {constantLibrary && !errorConstantLibrary && (
          <div className="type" id="gc-container">
            <label htmlFor="">Global constant library: </label>
            <select
              id="gc-select"
              name=""
              onChange={(evt) => handleChangeConstantLibrary(evt)}
              disabled={controlSelectLibrary}
              value={valueSelect}
            >
              {constantLibrary?.map((lib, index) => {
                return (
                  <option key={index} value={lib.id}>
                    {lib.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <label style={{ fontSize: "2rem", marginBottom: "15px" }}>
          Expression Builder:
        </label>
        <link rel="stylesheet" href="mathlive-fonts.css" />
        <MathfieldContainer>
          <math-field
            ref={mathfieldRef}
            id="formula"
            className="math-field ML__isExtendedWidth"
          />
        </MathfieldContainer>
        <MathfieldDisclaimer>
          <span style={{ fontSize: "2rem" }}>🚀</span>
          <div>
            <p>
              {" "}
              <strong> Note:</strong>{" "}
              <i>For this version only mathematical expressions are allowed.</i>
              {"\n "}
              <i>We are curretly working on text expressions.</i>{" "}
            </p>
          </div>
        </MathfieldDisclaimer>
      </OperationsExpression>
      {space && (
        <KeyboardSpace
          onScroll={() => ({ beahvior: "instant", block: "end" })}
        />
      )}
    </OperationsContainer>
  );
}

export default OperationsColumns;
