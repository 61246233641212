import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 40px;
  width: 100%;
  /* margin: 8rem auto; */
  margin-top: 8rem;

  aside {
    margin-top: 8rem;
    width: 30%;
  }

  main {
    margin-top: 4rem;
    width: 70%;
    align-items: flex-start;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    aside,
    main {
      width: 100%;
    }
  }
`;
