import OpenDialog from "components/Dialogs/OpenDialog";
import { Fragment, useEffect, useMemo, useState } from "react";
import React from "react";
import {
  FieldsContainer,
  FieldColumns,
  Field,
  FiledRelation,
  FiledSelectOptions,
  FieldOption,
  FiledOptions,
  IconOption,
  IconOption2,
} from "./FieldsStyle";
import DescriptionOpenDialog from "./DescriptionOpenDialog";
import {
  CSV_OBJECTS_TEMPLATE,
  CSV_EVENTS_TEMPLATE,
  CSV_COMPONENTS_TEMPLATE,
  CSV_LINES_TEMPLATE,
  TYPE_FIELDS,
} from "utils/constStrings";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import saveCSVTemplateFile from "utils/downloadCSVTemplate";
import ClickToolTip from "components/ToolTips/ClickToolTip";

//Icons
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DnsIcon from "@mui/icons-material/Dns";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import BookmarkIcon from "@mui/icons-material/Bookmark";

export default function Fields({ handleChange, form, typeFields, from }) {
  const fieldsForm = useMemo(
    () => form?.fields?.value || [],
    [form?.fields?.value]
  );

  const [field, setField] = useState({
    name: "",
    type: "string",
    required: true,
    circuit: false,
    options: [],
    optionsColors: [],
    possible: [],
    min: 0,
    max: 1,
    operation: "",
    alias: "",
    mobile: true,
    tags: [],
    onPDF: { state: false, where: "" },
  });

  const [fields, setFields] = useState([...fieldsForm]);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
  const [expandedFieldId, setExpandedFieldId] = useState(null);

  const setOpen = (open) => {
    if (!open) {
      setField({
        name: "",
        type: "string",
        required: true,
        circuit: false,
        options: [],
        optionsColors: [],
        possible: [],
        min: 0,
        max: 1,
        operation: "",
        alias: "",
        mobile: true,
        tags: [],
        onPDF: { state: false, where: "" },
      });
    }
    setDialog((current) => ({
      ...current,
      open,
      execute: handleExecute,
      update: false,
    }));
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setDialog((current) => ({
      ...current,
      open: true,
    }));
  };

  // format example
  // {
  //   "typeObjectName": "",
  //   "typeObjectId": "",
  //   "longitude": "",
  //   "latitude": "",
  //   "facname": "",
  //   "generalPhoto": "",
  //   "facilitylocation": "",
  //   "newdrawer": "",
  //   "dasdas": ""
  // }
  const handleDownloadCSVTemplate = (e) => {
    e.preventDefault();
    const auxFields = fields
      .filter((it) => it.type !== "captureMedia")
      .filter((it) => it.type !== "operation")
      .filter((it) => it.type !== "notification")
      .filter((it) => it.type !== "select");
    let csvFields = [];

    if (from === "Object") {
      const aux = {};
      for (let k in auxFields) aux[auxFields[k].name] = "";
      csvFields = [{ ...CSV_OBJECTS_TEMPLATE, ...aux }];
    }
    if (from === "Event") {
      const aux = {};
      for (let k in auxFields) aux[auxFields[k].name] = "";
      csvFields = [{ ...CSV_EVENTS_TEMPLATE, ...aux }];
    }
    if (from === "Component") {
      const aux = {};
      for (let k in auxFields) aux[auxFields[k].name] = "";
      csvFields = [{ ...CSV_COMPONENTS_TEMPLATE, ...aux }];
    }
    if (from === "Line") {
      const aux = {};
      for (let k in auxFields) aux[auxFields[k].name] = "";
      csvFields = [{ ...CSV_LINES_TEMPLATE, ...aux }];
    }

    saveCSVTemplateFile(csvFields, "csv");
  };

  const handleExecute = () => {
    if (dialog.update) {
      handleUpdateExecute();
      return;
    }
    const newField = { ...field };
    handleChange({
      target: {
        name: "fields",
        value: [...fields, { ...newField }],
      },
    });
    setDialog((current) => ({
      ...current,
      open: false,
    }));
    setOpen(false);
  };

  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      disagree: "Cancel",
    },
    execute: handleExecute,
    update: false,
  });

  const handleUpdateExecute = () => {
    handleChange({
      target: {
        name: "fields",
        value: fields?.map((f) => {
          if (f.name === field.name) {
            return field;
          }
          return f;
        }),
      },
    });
    setFields(
      fields?.map((f) => {
        if (f.name === field.name) {
          return field;
        }
        return f;
      })
    );
    setField({
      name: "",
      type: "string",
      required: true,
      circuit: false,
      options: [],
      optionsColors: [],
      possible: [],
      min: 0,
      max: 1,
      operation: "",
      alias: "",
      mobile: true,
      tags: [],
      onPDF: { state: false, where: "" },
    });
    setDialog((current) => ({
      ...current,
      open: false,
      execute: handleExecute,
      update: false,
    }));
    setOpen(false);
  };

  const handleChangeMobile = (e, index) => {
    const updatedFields = fields.map((f, i) =>
      i === index ? { ...f, mobile: e.target.checked } : f
    );
    setFields(updatedFields);
    handleChange({
      target: {
        name: "fields",
        value: updatedFields,
      },
    });
  };

  const handleChangeRequired = (e, index) => {
    const updatedFields = fields.map((f, i) =>
      i === index ? { ...f, required: e.target.checked } : f
    );
    setFields(updatedFields);
    handleChange({
      target: {
        name: "fields",
        value: updatedFields,
      },
    });
  };

  const handleDeleteField = (name) => {
    handleChange({
      target: {
        name: "fields",
        value: fields.filter((field) => field.name !== name),
      },
    });
    setFields(fields.filter((field) => field.name !== name));
  };

  const handleUpdateField = (name) => {
    const selected = fields.find((field) => field.name === name);
    setField(selected);
    setDialog((current) => ({
      ...current,
      open: true,
      execute: handleUpdateExecute,
      actions: {
        agree: "Update",
        disagree: "Cancel",
      },
      update: true,
    }));
  };

  const handleViewRelations = (name) => {
    const selected = fields.find((field) => field.name === name);
    setField(selected);
    setSelectedField(selected);
  };

  useEffect(() => {
    setFields([...fieldsForm]);
    setField({
      name: "",
      type: "string",
      required: true,
      circuit: false,
      options: [],
      optionsColors: [],
      possible: [],
      min: 0,
      max: 1,
      operation: "",
      alias: "",
      mobile: true,
      tags: [],
      onPDF: { state: false, where: "" },
    });
  }, [fieldsForm]);

  async function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const updatedItems = items.map((item, index) => ({
      ...item,
      indexPosition: index,
    }));

    setFields(updatedItems);
    handleChange({
      target: {
        name: "fields",
        value: updatedItems,
      },
    });
  }

  return (
    <FieldsContainer className="background-row-1 border-color-1">
      <OpenDialog
        openDialog={dialog?.open}
        setOpenDialog={setOpen}
        content={{
          title: "Add Field",
          description: (
            <DescriptionOpenDialog
              field={field}
              setField={setField}
              setDialog={setDialog}
              fields={fields}
              update={dialog.update}
              from={from}
            />
          ),
          ...dialog.actions,
        }}
        minWidth="680px"
        execute={handleExecute}
      />
      <div className="handling background-row-2 border-color-1">
        <h2 className="label">Fields</h2>
        {/* {TYPE_FIELDS.typeToLibrary === typeFields && (
          <button onClick={handleAddField}>
            <AddCircleIcon style={{ marginRight: "2px" }} />
            Add New Field
          </button>
        )} */}
        <div className="buttonsContainer">
          {TYPE_FIELDS.typeToLibrary === typeFields && (
            <button onClick={handleAddField}>
              <SimpleToolTip title="Add new dynamic field" placement="left">
                Add Field
              </SimpleToolTip>
            </button>
          )}
          {TYPE_FIELDS.typeToLibrary === typeFields && (
            <button onClick={handleDownloadCSVTemplate}>
              <SimpleToolTip
                title="Download library CSV template"
                placement="left"
              >
                <span>
                  CSV Template
                  <FileDownloadIcon />
                </span>
              </SimpleToolTip>
            </button>
          )}
        </div>
      </div>
      <hr className="hr-line" />

      <FieldColumns className="background-row-2 border-color-1">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="field">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 className="label" style={{ marginBottom: "2rem" }}>
                    General Field
                  </h2>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h2>Total Fields: </h2>
                    <h2
                      style={{
                        color: "white",
                        background: "var(--background-primary_color)",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "2px",
                        marginLeft: "0.5rem",
                        fontSize: "1.4rem",
                      }}
                    >
                      {fields.length}
                    </h2>
                  </div>
                </div>

                {fields
                  ?.filter((field) => field)

                  .map((field, index) => (
                    <Draggable
                      key={field.name}
                      draggableId={field.name}
                      index={index}
                    >
                      {(provided) => (
                        <div>
                          <Field
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // key={field.name}
                            className={
                              field.header
                                ? "field header"
                                : field.QAQC
                                ? "field qaqc"
                                : "field content fields"
                            }
                            style={{
                              outline:
                                selectedFieldIndex === index
                                  ? "5px solid var(--background-primary_color)"
                                  : "",
                              borderRadius:
                                selectedFieldIndex === index ? "5px" : "",
                              boxShadow:
                                selectedFieldIndex === index
                                  ? "0 0 25px 10px var(--background-primary_color)"
                                  : "",
                              outlineOffset:
                                selectedFieldIndex === index ? "7px" : "",
                              ...provided.draggableProps.style,
                            }}
                            onClick={() => setSelectedFieldIndex(index)}
                          >
                            <DragIndicatorIcon
                              style={{
                                fontSize: "2rem",
                                marginRight: "1rem",
                                zIndex: 1,
                              }}
                            />

                            <div
                              style={{
                                display: "flex",
                                paddingLeft: "1rem",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "5px",
                                  }}
                                >
                                  <IconOption2 className="item">
                                    <p>Item #{index + 1}</p>
                                  </IconOption2>
                                  {field?.type === "lineTag" ? (
                                    <IconOption className="line">
                                      <HorizontalRuleIcon className="coloricon" />
                                    </IconOption>
                                  ) : (
                                    <></>
                                  )}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: "1rem",
                                      gap: "inherit",
                                    }}
                                  ></div>

                                  {field?.type === "select" ? (
                                    <IconOption2
                                      className="select"
                                      onClick={(e) => {
                                        setExpandedFieldId(
                                          expandedFieldId === field.name
                                            ? null
                                            : field.name
                                        );
                                        setSelectedFieldIndex(index);
                                        handleViewRelations(field.name);
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="coloricon"
                                      />
                                      <KeyboardArrowDownIcon
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="coloricon"
                                      />
                                    </IconOption2>
                                  ) : (
                                    <IconOption
                                      className="select coloricon"
                                      style={{
                                        opacity: 0.2,
                                      }}
                                    >
                                      <DnsIcon className="coloricon" />
                                    </IconOption>
                                  )}

                                  <IconOption
                                    className="edit"
                                    onClick={() =>
                                      handleUpdateField(field.name)
                                    }
                                  >
                                    <EditIcon
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                      }}
                                      className="coloricon"
                                    />
                                  </IconOption>

                                  <IconOption
                                    className="close"
                                    onClick={() =>
                                      handleDeleteField(field.name)
                                    }
                                  >
                                    <CloseIcon
                                      style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        color: "#44598d",
                                      }}
                                    />
                                  </IconOption>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  margin: "1rem 0 1rem 0",
                                  background: "#eeeeee",
                                  border: "1px solid #bebebe",
                                  padding: "1rem",
                                  color: "black",
                                  borderRadius: "5px",
                                }}
                              >
                                <p>{field.alias ? field.alias : field.name}</p>
                              </div>

                              {expandedFieldId === field.name && (
                                <div style={{ marginTop: "1rem" }}>
                                  <p style={{ marginBottom: " 1rem" }}>
                                    Option Field
                                  </p>
                                  <FiledOptions>
                                    {selectedField?.type === "select" &&
                                    selectedField.options.length > 0 ? (
                                      selectedField.options.map(
                                        (option, optIndex) => (
                                          <Field
                                            key={optIndex}
                                            className="field"
                                            isRightColumn={true}
                                          >
                                            <p>{option}</p>
                                          </Field>
                                        )
                                      )
                                    ) : (
                                      <p className="empty">Empty Select</p>
                                    )}
                                  </FiledOptions>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginTop: "1rem",
                                  width: "100%",
                                }}
                              >
                                <ClickToolTip
                                  title={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <span>Name:</span> {field.name}
                                      </p>
                                      <p>
                                        <span>Type:</span> {field.type}
                                      </p>
                                      <p>
                                        <span>Required:</span>{" "}
                                        {field.required ? "Yes" : "No"}
                                      </p>
                                      <p>
                                        <span>Mobile:</span>{" "}
                                        {field.mobile ? "Yes" : "No"}
                                      </p>
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <span>
                                    <InfoIcon style={{ cursor: "pointer" }} />
                                  </span>
                                </ClickToolTip>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p style={{ fontWeight: "bold" }}>Type: </p>
                                  <p
                                    style={{
                                      marginLeft: "3px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    {field.type}
                                  </p>

                                  <div
                                    className="container"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      margin: "0 1rem 0 1rem",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`mobile-checkbox-${index}`}
                                      checked={field.mobile}
                                      onChange={(e) =>
                                        handleChangeMobile(e, index)
                                      }
                                    />
                                    <p style={{ marginLeft: "3px" }}>Mobile</p>
                                  </div>
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      margin: "0 1rem 0 1rem",
                                    }}
                                    class="container"
                                    id="input-checkbox-required"
                                  >
                                    <input
                                      type="checkbox"
                                      id={`required-checkbox-${index}`}
                                      checked={field.required}
                                      onChange={(e) =>
                                        handleChangeRequired(e, index)
                                      }
                                    />
                                    <p style={{ marginLeft: "3px" }}>
                                      Required
                                    </p>
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Field>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </FieldColumns>
    </FieldsContainer>
  );
}
