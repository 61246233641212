import {
  CardContainer,
  SectionContainer,
  BodyDescription,
  Title,
  Subtitle,
  Image,
  ImageDescription,
  ContainerPreviewChart,
} from "./indexStyle";
import { useEffect, useState } from "react";
import CustomButton from "components/Buttons/CustomButton";
import { useSWRConfig } from "swr";
import { config } from "config";
import uploadFileToS3 from "services/uploadFileToS3sdk";
import { updateChartText } from "services/Dashboard/createChart";

const PreviewTextChart = ({
  dataChart,
  setChartEditDialog,
  maxWidth,
  maxHeight,
}) => {
  const { mutate } = useSWRConfig();
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlKpi = `${
    config.URL_BACKEND_MG
  }charts-text?adminCompanyId=${adminCompanyId}&from=${
    dataChart.fromType ? dataChart.fromType : dataChart.from
  }`;
  const { sections: initialSection } = dataChart;
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState("");
  const [currentBorderColor, setCurrentBorderColor] = useState("");
  const [currentAllSections, setCurrentAllSections] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentStyle, setCurrentStyle] = useState({
    body: initialSection[0].body,
    image: initialSection[0].image,
    subtitle: initialSection[0].subtitle,
    title: initialSection[0].title,
    imageTitle: initialSection[0].imageTitle,
    style: {
      bodyBackgroundColor: initialSection[0].style.bodyBackgroundColor,
      bodyFontColor: initialSection[0].style.bodyFontColor,
      bodyFontSize: initialSection[0].style.bodyFontSize,
      imageFontColor: initialSection[0].style.imageFontColor,
      imageFontSize: initialSection[0].style.imageFontSize,
      imageWidth: initialSection[0].style.imageWidth,
      subtitleBackgroundColor: initialSection[0].style.subtitleBackgroundColor,
      subtitleFontColor: initialSection[0].style.subtitleFontColor,
      subtitleFontSize: initialSection[0].style.subtitleFontSize,
      titleBackgroundColor: initialSection[0].style.titleBackgroundColor,
      titleFontColor: initialSection[0].style.titleFontColor,
      titleFontSize: initialSection[0].style.titleFontSize,
    },
  });

  useEffect(() => {
    setCurrentBackgroundColor(dataChart.backgroundColor);
    setCurrentBorderColor(dataChart.borderColor);
    setCurrentAllSections(dataChart.sections);
  }, [dataChart]);

  useEffect(() => {
    setCurrentAllSections((current) =>
      current.map((elm, index) => {
        if (index === currentSection) {
          return {
            body: currentStyle.body,
            image: currentStyle.image,
            subtitle: currentStyle.subtitle,
            title: currentStyle.title,
            imageTitle: currentStyle.imageTitle,
            style: {
              bodyBackgroundColor: currentStyle.style.bodyBackgroundColor,
              bodyFontColor: currentStyle.style.bodyFontColor,
              bodyFontSize: currentStyle.style.bodyFontSize,
              imageFontColor: currentStyle.style.imageFontColor,
              imageFontSize: currentStyle.style.imageFontSize,
              imageWidth: currentStyle.style.imageWidth,
              subtitleBackgroundColor:
                currentStyle.style.subtitleBackgroundColor,
              subtitleFontColor: currentStyle.style.subtitleFontColor,
              subtitleFontSize: currentStyle.style.subtitleFontSize,
              titleBackgroundColor: currentStyle.style.titleBackgroundColor,
              titleFontColor: currentStyle.style.titleFontColor,
              titleFontSize: currentStyle.style.titleFontSize,
            },
          };
        } else {
          return elm;
        }
      })
    );
  }, [currentStyle]);

  const handleUpdateChart = async () => {
    const { data } = await updateChartText({
      id: dataChart._id,
      body: {
        ...dataChart,
        backgroundColor: currentBackgroundColor,
        borderColor: currentBorderColor,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        sections: currentAllSections,
      },
    });
    if (data) {
      mutate(urlKpi);
      setChartEditDialog(false);
    }
  };

  const handleSelectSection = (e) => {
    const selectedIndex = parseInt(e.target.value);
    setCurrentStyle({
      body: currentAllSections[selectedIndex].body,
      image: currentAllSections[selectedIndex].image,
      subtitle: currentAllSections[selectedIndex].subtitle,
      title: currentAllSections[selectedIndex].title,
      imageTitle: currentAllSections[selectedIndex].imageTitle,
      style: {
        bodyBackgroundColor:
          currentAllSections[selectedIndex].style.bodyBackgroundColor,
        bodyFontColor: currentAllSections[selectedIndex].style.bodyFontColor,
        bodyFontSize: currentAllSections[selectedIndex].style.bodyFontSize,
        imageFontColor: currentAllSections[selectedIndex].style.imageFontColor,
        imageFontSize: currentAllSections[selectedIndex].style.imageFontSize,
        imageWidth: currentAllSections[selectedIndex].style.imageWidth,
        subtitleBackgroundColor:
          currentAllSections[selectedIndex].style.subtitleBackgroundColor,
        subtitleFontColor:
          currentAllSections[selectedIndex].style.subtitleFontColor,
        subtitleFontSize:
          currentAllSections[selectedIndex].style.subtitleFontSize,
        titleBackgroundColor:
          currentAllSections[selectedIndex].style.titleBackgroundColor,
        titleFontColor: currentAllSections[selectedIndex].style.titleFontColor,
        titleFontSize: currentAllSections[selectedIndex].style.titleFontSize,
      },
    });
    setCurrentSection(selectedIndex);
  };

  const handleImageUpload = (e) => {
    const updatedChartData = [...currentAllSections];
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        const imageUrl = await uploadFileToS3(file);
        updatedChartData[currentSection].image = imageUrl;
        setCurrentAllSections(updatedChartData);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <ContainerPreviewChart widthChart={maxWidth} heightChart={maxHeight}>
      <div className="section-chart-button">
        <div className="preview-chart">
          <div className="chart">
            <CardContainer
              key={dataChart._id}
              backgroundColor={currentBackgroundColor}
              borderColor={currentBorderColor}
              width={maxHeight ? `${maxWidth}px` : null}
              height={maxHeight ? `${maxHeight}px` : null}
            >
              {/* Map sections */}
              {currentAllSections.map((section, index) => {
                const { style } = section;
                return (
                  <SectionContainer key={section._id}>
                    <Title
                      fontSize={style?.titleFontSize}
                      color={style?.titleFontColor}
                      backgroundColor={style?.titleBackgroundColor}
                      display={section.title ? "block" : "none"}
                    >
                      {currentAllSections[index].title}
                    </Title>
                    <Subtitle
                      fontSize={style?.subtitleFontSize}
                      color={style?.subtitleFontColor}
                      backgroundColor={style?.subtitleBackgroundColor}
                      display={section.subtitle ? "block" : "none"}
                    >
                      {currentAllSections[index].subtitle}
                    </Subtitle>
                    <BodyDescription
                      fontSize={style?.bodyFontSize}
                      color={style?.bodyFontColor}
                      backgroundColor={style?.bodyBackgroundColor}
                      display={section.body ? "block" : "none"}
                    >
                      {currentAllSections[index].body}
                    </BodyDescription>
                    {section.image && (
                      <Image
                        src={currentAllSections[index].image}
                        alt={section.imageTitle || "image card"}
                        display={section.image ? "block" : "none"}
                        width={style?.imageWidth}
                      />
                    )}
                    {/* Image description */}
                    <ImageDescription
                      fontSize={style?.imageFontSize}
                      color={style?.imageFontColor}
                      display={section.imageTitle ? "block" : "none"}
                    >
                      {currentAllSections[index].imageTitle}
                    </ImageDescription>
                  </SectionContainer>
                );
              })}
            </CardContainer>
          </div>
        </div>
        <div className="container-update">
          <CustomButton
            text={"Update Chart"}
            margin={0}
            onClick={handleUpdateChart}
          />
        </div>
      </div>
      <div className="editing-tools">
        <div className="groupe-styles">
          <div className="sections">
            <h3>Choose Section</h3>
            <div className="section-style">
              <p>Section</p>
              <select onChange={(e) => handleSelectSection(e)}>
                {currentAllSections.map((elm, index) => {
                  return (
                    <option key={index} value={index}>
                      {index + 1} Section
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="section-style">
              <p>Border Color</p>
              <input
                className="alias"
                type="color"
                value={currentBorderColor}
                onChange={(e) => setCurrentBorderColor(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Title</h3>
            <div className="section-style">
              <p>Title</p>
              <input
                className="input-title"
                type="text"
                placeholder="text"
                value={currentStyle.title}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    title: e.target.value,
                  }))
                }
              />
            </div>

            <div className="section-style">
              <p>Title Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyle.style.titleFontColor}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    style: {
                      ...current.style,
                      titleFontColor: e.target.value,
                    },
                  }))
                }
              />
            </div>

            <div className="section-style">
              <p>Background Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyle.style.titleBackgroundColor}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    style: {
                      ...current.style,
                      titleBackgroundColor: e.target.value,
                    },
                  }))
                }
              />
            </div>

            <div className="section-style">
              <p> Title Size</p>
              <div>
                <h4>{currentStyle.style.titleFontSize} px</h4>
                <input
                  value={currentStyle.style.titleFontSize}
                  type="range"
                  step={"1"}
                  min={"10"}
                  max={"60"}
                  onChange={(e) =>
                    setCurrentStyle((current) => ({
                      ...current,
                      style: {
                        ...current.style,
                        titleFontSize: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Subtitle</h3>
            <div className="section-style">
              <input
                className="input-title"
                type="text"
                placeholder="text"
                value={currentStyle.subtitle}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    subtitle: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Subtitle Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyle.style.subtitleFontColor}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    style: {
                      ...current.style,
                      subtitleFontColor: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Subtitle Size</p>
              <div>
                <h4>{currentStyle.style.subtitleFontSize}px</h4>
                <input
                  value={currentStyle.style.subtitleFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={60}
                  onChange={(e) =>
                    setCurrentStyle((current) => ({
                      ...current,
                      style: {
                        ...current.style,
                        subtitleFontSize: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Background Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyle.style.subtitleBackgroundColor}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    style: {
                      ...current.style,
                      subtitleBackgroundColor: e.target.value,
                    },
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Body</h3>
            <div className="section-style">
              <input
                className="input-title"
                type="text"
                placeholder="text"
                value={currentStyle.body}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    body: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Font Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyle.style.bodyFontColor}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    style: {
                      ...current.style,
                      bodyFontColor: e.target.value,
                    },
                  }))
                }
              />
            </div>

            <div className="section-style">
              <p>Body Size</p>
              <div>
                <h4>{currentStyle.style.bodyFontSize} px</h4>
                <input
                  value={currentStyle.style.bodyFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={60}
                  onChange={(e) =>
                    setCurrentStyle((current) => ({
                      ...current,
                      style: {
                        ...current.style,
                        bodyFontSize: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Background Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyle.style.bodyBackgroundColor}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    style: {
                      ...current.style,
                      bodyBackgroundColor: e.target.value,
                    },
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="groupe-styles">
          <div className="sections">
            <h3>Image</h3>
            <div className="section-style">
              <input
                className="input-title"
                type="text"
                placeholder="text"
                value={currentStyle.imageTitle}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    imageTitle: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>File</p>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
              />
            </div>
            <div className="section-style">
              <p>Title Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyle.style.imageFontColor}
                onChange={(e) =>
                  setCurrentStyle((current) => ({
                    ...current,
                    style: {
                      ...current.style,
                      imageFontColor: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Title Size</p>
              <div>
                <h4>{currentStyle.style.imageFontSize} px</h4>
                <input
                  value={currentStyle.style.imageFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={60}
                  onChange={(e) =>
                    setCurrentStyle((current) => ({
                      ...current,
                      style: {
                        ...current.style,
                        imageFontSize: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Background Color</p>
              <input
                className="alias"
                type="color"
                value={currentBackgroundColor}
                onChange={(e) => setCurrentBackgroundColor(e.target.value)}
              />
            </div>
            <div className="section-style">
              <p>Width</p>
              <div>
                <h4>{currentStyle.style.imageWidth} %</h4>
                <input
                  value={currentStyle.style.imageWidth}
                  type="range"
                  step={1}
                  min={1}
                  max={100}
                  onChange={(e) =>
                    setCurrentStyle((current) => ({
                      ...current,
                      style: {
                        ...current.style,
                        imageWidth: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerPreviewChart>
  );
};

export default PreviewTextChart;
