import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR } from "utils/const";

export const PopUpContainer = styled("div")`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  font-family: ${document.body.style.fontFamily};
  color: ${BLACK_COLOR};

  .title {
    font-size: 1.8;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    flex: 1;
  }

  .row {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1.5rem;
      font-weight: 600;
    }

    span {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 1rem;
    margin-top: 2rem;
  }

  .action-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .button-actions {
    background: ${PRIMARY_COLOR};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 1.5rem;
  }
`;
