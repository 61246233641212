// React
import { useEffect, useState } from "react";
// Dependencies
import useSwr from "swr";
import { useSWRConfig } from "swr";
// Components
import OpenDialog from "../../Dialogs/OpenDialog";
import TableEvent from "./TableEvent";
import EventMap from "components/Maps/EventMap";
import findObjects from "services/objects/findObjects";
import EventHistory from "./../EventHistory";
import PhotoGallery from "../PhotoGalley/PhotoGallery";
import AddPicture from "./AddPicture/AddPicture";
// Hooks
import useMediaElement from "hooks/useMediaElement";
import useCreateEventEvents from "hooks/useCreateEventEvents";
import useShowDetails from "hooks/useShowDetails";
// Style
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import { InfoTitle } from "../ImageDetailsElements";
import {
  ContainerOperations,
  CustomizedChevronLeftIcon,
} from "./EventDetailsElements";
// config
import { config } from "config.js";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  INFORMATION_COLOR,
  WHITE_COLOR,
} from "utils/const";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import findPointLibraryEvents from "services/findPointLibraryEvents";
import DetailTypeIformation from "../DetailTypeInformation";
import { useContext } from "react";
import { ThemeContext } from "App";
import EventHistoryNoLocation from "../EventHistoryNoLocation";

const getStyle = (theme) => ({
  color: theme === "light" ? document.body.style.color : WHITE_COLOR,
  background: theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
});

const EventDetails = ({ eventId, fromObject, fromComponent, fromEvent }) => {
  const { mutate } = useSWRConfig();
  const { theme } = useContext(ThemeContext);
  const colorStyle = getStyle(theme);

  const [errorMessage, setErrorMessage] = useState(false);

  const [objectGHG, setObjectGHG] = useState(false);
  const [eventLibrary, setEventLibrary] = useState([]);
  const [event, setEvent] = useState([]);

  const [eventCount, setEventCount] = useState([]);
  const [movePoint, setMovePoint] = useState(false);
  const { data: eventObject } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-events?id=${eventId}`
  );
  const { data: eventComponent } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${eventId}`
  );

  const { data: eventRelation } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${eventId}`
  );

  useEffect(() => {
    eventObject?.length > 0 && setEvent(eventObject);
    eventComponent?.length > 0 && setEvent(eventComponent);
    eventRelation?.length > 0 && setEvent(eventRelation);
  }, [eventObject, eventComponent, eventRelation, event, movePoint]);

  useEffect(() => {
    setEventCount([]);
    if (event[0]?.eventsCount?.length > 0) {
      setEventCount(event[0]?.eventsCount);
    }
  }, [event, movePoint]);

  const { mediaObject, widthMap } = useMediaElement({ element: event?.[0] });

  // Get object the current event
  useEffect(() => {
    const getObject = async () => {
      const objectId = eventObject[0]?.objectId;
      const data = await findObjects(objectId);
      setObjectGHG(data);
    };
    eventObject?.length > 0 && getObject();
  }, [eventObject, movePoint]);

  useEffect(() => {
    const getEventLibary = async () => {
      const dataLibrary = await findPointLibraryEvents(
        event[0]?.pointTypeEvent.pointLibraryEventId
      );
      setEventLibrary(dataLibrary);
    };
    event?.length > 0 && getEventLibary();
  }, [event]);

  const { handlerClickObjectId, handlerClickComponentId, handlerClickEventId } =
    useShowDetails();

  const returnToEvent = (id) => {
    setEvent([]);
    handlerClickEventId(id);
  };

  const uploadImages = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/point-events?id=${eventId}`);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${eventId}`
    );
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${eventId}`
    );
  };

  const { eventRows, seePointEventsTable, markedEvent } = useCreateEventEvents({
    eventId,
  });

  return (
    <>
      {event?.length > 0 && (
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {fromObject && eventObject?.length > 0 && (
            <IconButton
              onClick={() => handlerClickObjectId(objectGHG.id)}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          {fromComponent && eventComponent?.length > 0 && (
            <IconButton
              onClick={() =>
                handlerClickComponentId(eventComponent[0].pointComponentId)
              }
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          {fromEvent && eventRelation?.length > 0 && (
            <IconButton
              onClick={() =>
                returnToEvent(eventRelation[0].PointEventRelationWith[0].id)
              }
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          <OpenDialog
            openDialog={errorMessage}
            setOpenDialog={setErrorMessage}
            content={{
              title: "Conexion lost",
              description: "Please check your internet conexion and try again.",
              disagree: "Continue",
            }}
          />
          <ContainerOperations>
            {/* --EVENT TYPE-- */}
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className={`info-event ${theme === "light" ? "bb" : "bb2"}`}>
                <DetailTypeIformation
                  typeName={event[0]?.pointTypeEvent?.name}
                  typeLibrary={eventLibrary[0]?.name}
                  iconLibrary={eventLibrary[0]?.icon}
                  icon={event[0]?.pointTypeEvent?.icon}
                  type={"Event"}
                />
                <TableEvent
                  event={event[0]}
                  deleteButton={eventCount.length > 0 ? false : true}
                />
                <InfoTitle>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <section className="main-tables-container">
                      <div className="main-currency-table">
                        {eventCount?.length > 0 && (
                          <p
                            className="currency-table--title"
                            style={{
                              color:
                                theme === "light"
                                  ? "var(--background-primary_color)"
                                  : WHITE_COLOR,
                            }}
                          >
                            Events
                          </p>
                        )}
                        <div className="currency-table--container">
                          <table>
                            {eventCount?.length > 0 &&
                              eventCount
                                .filter(
                                  (it) =>
                                    it.quantity > 0 && it.quantity !== undefined
                                )
                                .map((it, index) => {
                                  return (
                                    <tr style={colorStyle}>
                                      <td className="table__bottom-left">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {it.typeName}
                                          <SimpleToolTip
                                            title={`Library: ${it.libName}`}
                                            placement="right"
                                          >
                                            <LocalLibraryIcon
                                              sx={{
                                                fontSize: "1.8rem",
                                                color: INFORMATION_COLOR,
                                              }}
                                            />
                                          </SimpleToolTip>
                                        </div>
                                      </td>
                                      <td className="table__bottom-right table__right">
                                        {it.quantity}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </table>
                        </div>
                        {eventCount?.length > 0 && (
                          <p
                            className="currency-table--title"
                            style={{
                              color:
                                theme === "light"
                                  ? "var(--background-primary_color)"
                                  : WHITE_COLOR,
                            }}
                          >
                            Non-Georeferenced Event
                          </p>
                        )}

                        <div className="currency-table--container">
                          <table>
                            {eventCount?.length > 0 &&
                              eventCount
                                .filter(
                                  (it) =>
                                    it.quantityNoGeographic > 0 &&
                                    it.quantityNoGeographic !== undefined
                                )
                                .map((it, index) => {
                                  return (
                                    <tr style={colorStyle}>
                                      <td className="table__bottom-left">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {it.typeName}
                                          <SimpleToolTip
                                            title={`Library: ${it.libName}`}
                                            placement="right"
                                          >
                                            <LocalLibraryIcon
                                              sx={{
                                                fontSize: "1.8rem",
                                                color: INFORMATION_COLOR,
                                              }}
                                            />
                                          </SimpleToolTip>
                                        </div>
                                      </td>
                                      <td className="table__bottom-right table__right">
                                        {it.quantityNoGeographic}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </table>
                        </div>
                      </div>
                    </section>
                  </Grid>
                </InfoTitle>
              </div>
            </Grid>

            {/* --EVENT GALLERY-- */}

            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div
                className={`photo-container-image ${
                  theme === "light" ? "bb" : "bb2"
                }`}
              >
                <h1
                  className="event-title"
                  style={{
                    color:
                      theme === "light"
                        ? "var(--background-primary_color)"
                        : WHITE_COLOR,
                  }}
                >
                  <span>GALLERY</span>
                </h1>
                <div
                  className="gallery"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 24px 0px 20px",
                  }}
                >
                  {event[0].eventFiles.length > 0 ||
                  event[0]?.media?.length > 0 ? (
                    <>
                      <p
                        style={{
                          fontSize: "1.6rem",
                          color:
                            theme === "light"
                              ? "var(--background-primary_color)"
                              : WHITE_COLOR,
                        }}
                      >
                        <b>Total Pictures: </b>{" "}
                        {[...event[0]?.eventFiles, ...event[0]?.media]?.length}
                      </p>
                      <br />
                      <PhotoGallery
                        photos={[...event[0]?.eventFiles, ...event[0]?.media]}
                        ownId={event[0]?.id}
                        mongoId={event[0]?.mongoId}
                        type={"event"}
                        uploadImages={uploadImages}
                      />
                      <AddPicture event={event[0]} />
                    </>
                  ) : (
                    <AddPicture event={event[0]} />
                  )}
                </div>
              </div>
            </Grid>

            {/* GALLERIES BY TYPE  */}
            <Grid
              container
              spacing={3}
              justifyContent="flex-start"
              alignItems="center"
            >
              {mediaObject &&
                mediaObject.map((mediaGroup) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      key={mediaGroup[0].alias}
                    >
                      <div
                        className={`photo-container ${
                          theme === "light" ? "bb" : "bb2"
                        }`}
                      >
                        <h1
                          className="event-title"
                          style={{
                            color:
                              theme === "light"
                                ? "var(--background-primary_color)"
                                : WHITE_COLOR,
                          }}
                        >
                          <span>{mediaGroup[0].alias}</span>
                        </h1>
                        {
                          <div
                            style={{
                              width: "85%",
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              padding: "0px 0px 20px 0px",
                            }}
                          >
                            {event && (
                              <PhotoGallery
                                photos={mediaGroup}
                                ownId={event[0]?.id}
                                state={false}
                                mongoId={event[0]?.mongoId}
                                type={"event"}
                                uploadImages={uploadImages}
                              />
                            )}
                          </div>
                        }
                      </div>
                    </Grid>
                  );
                })}

              {/* -- MAP -- */}
              {widthMap && event[0]?.isGeographic === true && (
                <Grid item xs={12} md={12} lg={widthMap} xl={widthMap}>
                  <div
                    className={`content-map ${
                      theme === "light" ? "bb" : "bb2"
                    }`}
                  >
                    <h1
                      className="event-title"
                      style={{
                        color:
                          theme === "light"
                            ? "var(--background-primary_color)"
                            : WHITE_COLOR,
                      }}
                    >
                      <span>LOCATION</span>
                    </h1>
                    {event && (
                      <EventMap
                        event={event[0]}
                        markedEvent={markedEvent}
                        setMovePoint={setMovePoint}
                      />
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
            {/* --EVENT HISTORY-- */}
            <EventHistory
              eventRows={eventRows}
              seePointEventsTable={seePointEventsTable}
            />

            {/**EVENT HISTORY NO LOCATION */}
            <EventHistoryNoLocation
              eventRows={eventRows}
              seePointEventsTable={seePointEventsTable}
            />
          </ContainerOperations>
        </Box>
      )}
    </>
  );
};

export default EventDetails;
