import useSwr from "swr";
import { config } from "config.js";

const FetchFieldVideosByUserId = (userId) => {
  const token = localStorage.getItem("token");

  const { data, error } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/field-video/userId/${userId}`,
    async (url) => {
      const response = await fetch(url, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    }
  );

  return { data, error };
};

export default FetchFieldVideosByUserId;
