import countEventsByObjectId from "services/countEventsByObjectId";
import { useEffect, useState } from "react";
const useCountEventsToOneObject = ({ objectId, setShowDeleteButton }) => {
  const [amountEvents, setAmountEvents] = useState();
  const [totalAmountEvents, setTotalAmoutEvents] = useState(0);
  const [totalAmountEventsNoGeog, setTotalAmountEventsNoGeog] = useState(0);

  useEffect(() => {
    countEventsByObjectId({ objectId })
      .then((response) => {
        setAmountEvents(response);
        const totalEvents = response?.dynamics?.reduce(
          (sum, item) => sum + item.quantity,
          0
        );
        setTotalAmoutEvents(totalEvents);
        if (response.dynamics?.length !== 0) setShowDeleteButton(false);

        // Count the total number of events without the "Geography"
        const eventsNoGeograpic = response?.dynamicsNoGeographic?.reduce(
          (sum, item) => sum + item.quantity,
          0
        );
        setTotalAmountEventsNoGeog(eventsNoGeograpic);

        if (response.dynamicsNoGeographic?.length !== 0)
          setShowDeleteButton(false);
      })
      .catch((err) => console.error(err));
  }, [objectId, setShowDeleteButton]);

  return { amountEvents, totalAmountEvents, totalAmountEventsNoGeog };
};

export default useCountEventsToOneObject;
