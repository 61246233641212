import { useState, useEffect, useCallback } from "react";
// Helpers
import { sumValues, countByValues } from "pages/Dashboard/helpers/objectsDonutChart";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { config } from "config";

const useFindDoughnutCharts = ({ openDialog, objects, loading }) => {
  const [charts, setCharts] = useState(null);
  const [donutCharts, setDonutCharts] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlBarChart = `${config.URL_BACKEND_MG}doughnut-chart?adminCompanyId=${adminCompanyId}&from=objects`;
  const { data: dataDonutChart, error: errorDataDonutChart } =
    useSwr(urlBarChart);

  useEffect(() => {
    if (!errorDataDonutChart && dataDonutChart) setCharts(dataDonutChart);
  }, [dataDonutChart, errorDataDonutChart, openDialog, reloadCharts]);

  useEffect(() => {
    const objectsFormat = createRowsObjects({ objects });
    setObjectsFormat(objectsFormat);
  }, [objects]);

  const getDataDonutChart = useCallback(async () => {
    if (!loading && objectsFormat) {
      const countCharts = countByValues({
        charts,
        objects: objectsFormat,
      });

      const sumCharts = sumValues({
        charts,
        objects: objectsFormat,
      });

      setDonutCharts([...countCharts, ...sumCharts]);
    }
  }, [loading, objectsFormat, charts]);

  useEffect(() => {
    if (charts && objects) {
      getDataDonutChart();
    }
  }, [charts, objects, getDataDonutChart]);

  return { doughnutCharts: donutCharts };
};

export default useFindDoughnutCharts;
