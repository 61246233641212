import { useEffect, useState } from "react";
import countComponentsByObjectId from "services/countComponentsByObjectId";

const useCountComponentsToOneObject = ({ objectId, setShowDeleteButton }) => {
  const [amountComponents, setAmountComponents] = useState();
  const [totalAmountComponents, setTotalAmountComponents] = useState(0);

  useEffect(() => {
    countComponentsByObjectId({ objectId })
      .then((response) => {
        setAmountComponents(response);
        const totalComponents = response?.reduce(
          (sum, item) => sum + item.quantity,
          0
        );
        setTotalAmountComponents(totalComponents);
        if (response.length > 0) {
          setShowDeleteButton(false);
        }
      })
      .catch((err) => console.error(err));
  }, [objectId, setShowDeleteButton]);

  return { amountComponents, totalAmountComponents };
};

export default useCountComponentsToOneObject;
