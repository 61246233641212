import { useCallback, useEffect, useState } from "react";
import { Layer, Source } from "react-map-gl";
import useGet from "hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeometryLine,
  setGeometryPolygon,
  setFeaturesDetailsDT,
  setObjectDeleteLineDraws,
} from "redux/actions/admin";

// import { PRIMARY_COLOR } from "utils/const";

import { useSWRConfig } from "swr";
import useSWR from "swr";
import deleteLine from "services/lines/deleteLine";

import { config } from "config.js";

import PopUp from "./popUp";
import {
  setCancelDrawing,
  setCurrentUpdateLineId,
  setDataLines,
  setShowLinePopUp,
} from "redux/actions/digitalTwin";

export default function Lines({ popUpLines, setPopUpLines, setLayerIds }) {
  // Constants
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // States
  const [linesFeatures, setLinesFeatures] = useState([]);
  const [popUp, setPopUp] = useState({});
  const [line, setLine] = useState({});
  const [lines, setLines] = useState([]);
  const [state, setState] = useState({});
  const [lineIdPg, setLineIdPg] = useState(0);
  const [showLineDetails, setShowLineDetails] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const updateGeometryLine = useSelector(
    (state) => state.adminReducer.updateGeometryLine
  );
  const showLinePopUp = useSelector(
    (state) => state.digitalTwinReducer.setShowLinePopUp
  );

  const objectDeleteLineDraws = useSelector(
    (state) => state.adminReducer.setObjectDeleteLineDraws
  );
  // Fetch data
  const { data: allLines, error: errorLines } = useSWR(
    `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
  );

  const [typesLine] = useGet(`api/v1/type-lines`);

  useEffect(() => {
    if (allLines === undefined) return;
    if ({ ...allLines }.hasOwnProperty("statusCode")) return;
    dispatch(setDataLines(allLines));
    setLines(allLines);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
    );
  }, [allLines, adminCompanyId, mutate, errorLines, dispatch]);

  // function that finds a line from line array by id, Feature id and mongo id
  const findLine = useCallback(
    (id) => {
      if (lines === undefined || id === undefined) return undefined;
      if (typeof id === "string") {
        const lookByGeoJson = lines?.find(
          (line) => line?.geojsonLineId === id || line?._id === id
        );
        const lookByLineId = lines?.find((line) => line?.id === id);
        const lookByLineFeatureId = lines?.find(
          (line) => line?.feature?.[0]?.id === id
        );

        if (lookByGeoJson) return lookByGeoJson;
        if (lookByLineId) return lookByLineId;
        if (lookByLineFeatureId) return lookByLineFeatureId;
      }
      return lines?.find((line) => line.id === id);
    },
    [lines]
  );

  useEffect(() => {
    if (lines !== undefined && Object.keys(popUpLines).length > 0) {
      const featureId = popUpLines.popUp.id;
      const searchingLine = findLine(featureId);
      if (searchingLine) setLineIdPg(searchingLine.id);
    }
  }, [lines, popUpLines, findLine]);

  // redux selectors
  const drawerControl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );
  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );

  useEffect(() => {
    if (!lines || lines.length <= 0) return null;
    setLayerIds((current) => [...current, "line-id"]);
  }, [lines, setLayerIds]);

  // listener for cleaning useStates
  useEffect(() => {
    if (geometryLine) return null;
    setLine({});
    setState(() => ({ type: "normal" }));
  }, [geometryLine]);

  useEffect(() => {
    const findCompleteLine = popUpLines.line && findLine(popUpLines.line.id);
    if (findCompleteLine) setLine(findCompleteLine);
  }, [popUpLines, findLine]);

  const handleDeleteLine = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);

  // arreglarlo! el deleteLine
  const deleteLineDraw = useCallback(async () => {
    const { geojsonLineId } = line;
    const completeLine = findLine(geojsonLineId);
    await deleteLine(completeLine.id);
    setPopUp(() => {});
    setState(() => ({ type: "normal" }));
    dispatch(setGeometryLine({}));
    dispatch(setGeometryPolygon({}));
    dispatch(
      setFeaturesDetailsDT({
        geometry: {
          type: null,
        },
      })
    );
    setPopUpLines({});
    setLinesFeatures((current) =>
      current.filter((line) => line?.properties?.lineIdPg !== completeLine.id)
    );
    drawerControl.set({
      type: "FeatureCollection",
      features: [],
    });
    setDeleteConfirmation(false);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
    );
  }, [
    line,
    dispatch,
    drawerControl,
    findLine,
    setPopUpLines,
    mutate,
    adminCompanyId,
  ]);

  //  Filter lineFeatures after deleting an object (delete lines related)
  useEffect(() => {
    if (objectDeleteLineDraws.state) {
      const lineIds = objectDeleteLineDraws.lineIds;
      setLinesFeatures((current) => {
        return current.filter(
          (line) =>
            line?.properties?.lineIdPg !==
            lineIds.find((it) => it === line?.properties?.lineIdPg)
        );
      });
      dispatch(
        setObjectDeleteLineDraws({
          state: false,
          lineIds: [],
        })
      );
    }
  }, [objectDeleteLineDraws, dispatch]);

  const handleUpdateLine = () => {
    const idUserUpdating = localStorage.getItem("userId");
    const { id } = line;
    dispatch(setCurrentUpdateLineId(lineIdPg));
    const completeLine = findLine(id);
    const { feature: features } = completeLine;
    const feature = features[0];
    drawerControl.set({
      type: "FeatureCollection",
      features: [
        {
          ...feature,
          properties: {
            ...line,
            idMongo: completeLine._id,
            userUpdatedBy: parseInt(idUserUpdating),
          },
        },
      ],
    });
    setLinesFeatures((current) => {
      return current.filter(
        (line) => line?.properties?.object?.id !== completeLine.feature[0].id
      );
    });
    drawerControl.changeMode("direct_select", {
      featureId: completeLine.feature[0].id,
    });
    dispatch(setShowLinePopUp({ id: lineIdPg, show: false, update: true }));
    setPopUp({});
    dispatch(setCancelDrawing(false));
  };

  useEffect(() => {
    setPopUp({ ...popUpLines?.popUp });
  }, [popUpLines?.popUp]);

  // set line properties
  useEffect(() => {
    delete popUpLines?.line?.id;
    setLine((current) => ({
      ...current,
      ...popUpLines.line,
    }));
  }, [popUpLines?.line]);

  useEffect(() => {
    if (line?.lineTypeId && typesLine) {
      const lineType = typesLine.find((type) => type.id === line.lineTypeId);
      if (lineType) {
        const lineLibraryName = lineType?.lineLibrary?.name;
        const { id, name } = lineType || {};
        setLine((currentLine) => ({
          ...currentLine,
          lineType: lineType.type,
          lineLibrary: lineLibraryName || "Unknown Library",
          lineLibraryId: id || null,
        }));
        setPopUpLines((currentPopUpLines) => ({
          ...currentPopUpLines,
          line: {
            ...currentPopUpLines.line,
            lineLibrary: lineLibraryName || true,
          },
        }));
      }
    }
  }, [line?.lineTypeId, typesLine]);

  useEffect(() => {
    const stop =
      !line.id || state.type !== "update" || linesFeatures.length <= 0;
    if (stop) return null;
    const existLineFeature = linesFeatures.findIndex(
      (lineFeature) => lineFeature.properties.object.id === line.id
    );
    if (!existLineFeature || existLineFeature < 0) return null;
    const newLinesFeatures = linesFeatures
      .slice(0, existLineFeature)
      .concat(linesFeatures.slice(existLineFeature + 1, linesFeatures.length));
    setLinesFeatures(newLinesFeatures);
    setPopUp(() => {});
    setState(() => ({ type: "normal" }));
  }, [line?.id, state.type, linesFeatures]);

  // filter features
  useEffect(() => {
    lines?.forEach((object) => {
      const featuresFilter = object?.feature.filter(
        (feature) => feature.geometry.type === "LineString"
      );
      const newsLinesFeatures = featuresFilter.map((feature) => {
        const type = typesLine?.filter(
          (type) => feature?.properties?.lineTypeId === type.id
        )[0];
        return {
          type: "Feature",
          properties: {
            cluster: false,
            object: feature,
            lineIdPg: object.id,
            ...type,
          },
          geometry: {
            type: "LineString",
            coordinates: feature.geometry.coordinates,
          },
        };
      });
      setLinesFeatures((current) => [...current, ...newsLinesFeatures]);
    });
  }, [lines, line?.id, state.type, typesLine, updateGeometryLine]);

  useEffect(() => {
    setLinesFeatures((current) => {
      if (Object.keys(updateGeometryLine).length > 0) {
        current.forEach((elm) => {
          if (
            elm.properties.lineIdPg === updateGeometryLine.properties.lineIdPg
          ) {
            elm.geometry.coordinates = updateGeometryLine.geometry.coordinates;
          }
        });
      }
      if (Object.keys(updateGeometryLine).length > 0) {
        return [
          ...current.filter(
            (it) => it.properties.object.id !== updateGeometryLine.properties.id
          ),
          // ...current,
        ];
      } else {
        return [...current];
      }
    });
  }, [allLines, updateGeometryLine]);

  const handleOpenLineDetails = () => {
    const lineFound = findLine(line.id);
    setLine(lineFound);
    setShowLineDetails(true);
  };

  useEffect(() => {
    if (showLinePopUp.show && Object.keys(showLinePopUp.event).length) {
      const { lat, lng } = showLinePopUp.event.lngLat;
      const line = findLine(showLinePopUp.id);
      if (line) {
        const { id, properties } = line;
        setPopUpLines((current) => ({
          popUp: {
            id,
            latitude: lat,
            longitude: lng,
          },
          line: {
            ...current.line,
            ...properties,
            id,
          },
        }));
      }
    }
  }, [showLinePopUp, setPopUpLines, findLine]);

  return (
    <div>
      <Source
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: linesFeatures,
        }}
      >
        <Layer
          key="line-id"
          id="line-id"
          type={"line"}
          paint={{
            "line-color": ["get", "color"],
            "line-width": ["get", "dasharrayWidth"],
          }}
          layout={{
            "line-cap": "round",
            "line-join": "round",
          }}
        />
      </Source>
      {popUp?.latitude && popUp?.longitude && showLinePopUp.show && (
        <PopUp
          {...{
            popUp,
            setPopUp,
            line,
            lineIdPg,
            handleUpdateLine,
            handleOpenLineDetails,
            handleDeleteLine,
            showLineDetails,
            setShowLineDetails,
            deleteConfirmation,
            setDeleteConfirmation,
            deletedLine: deleteLineDraw,
          }}
        />
      )}
    </div>
  );
}
