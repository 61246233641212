import Profile from "../../components/Profile/Profile";
import NavBar from "../../components/Admin/NavBar/NavBar";
import { ProfileStyle } from "./profileStyle";
import Layout from "../../components/Layout/AdminDecimetrix";

const Admin = () => {
  const userId = localStorage.getItem("userId");
  return (
    <>
      <Layout>
        <ProfileStyle className="background-1">
          <h1 className="title-profile">Profile</h1>
          <Profile userId={userId} />
        </ProfileStyle>
      </Layout>
    </>
  );
};

export default Admin;
