import { ContainerSelect } from "./style";

function SelectInput({ field, register }) {
  const { name, alias, required, options, possible, type, disableOptions } =
    field;
  return (
    <ContainerSelect>
      <label className="label" htmlFor={name}>
        {type === "currency" ? "Currency:" : alias || name}
        {required ? <span className="required">*</span> : null}
      </label>
      <select
        id={name}
        className="select"
        {...register(type === "currency" ? "Currency" : name)}
      >
        {type === "currency"
          ? possible?.map((option, index) => {
              return (
                <option key={index} value={option}>
                  {option}
                </option>
              );
            })
          : options?.map((option, index) => {
              return (
                <option
                  key={index}
                  value={option}
                  disabled={disableOptions?.includes(option) ? true : false}
                >
                  {option}
                </option>
              );
            })}
      </select>
    </ContainerSelect>
  );
}

export default SelectInput;
