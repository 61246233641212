import AllCharts from "./All/index";
import Loading from "components/Lodings/Loading";

const AllTypesCharts = ({
  openDialog,
  components,
  objects,
  events,
  loading,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  openDialogRestoreChart,
  setOpenDialogRestoreChart,
  orderId,
}) => {
  return (
    <div className="dashboard">
      {/* TODO: Corregir, si no hay datos algun elemento no muestra los demás datos*/}
      {/* {objects?.length > 0 && events?.length > 0 && components?.length > 0 && ( */}
      <AllCharts
        openDialog={openDialog}
        objects={objects}
        components={components}
        events={events}
        loading={loading}
        privateViews={privateViews}
        publicViews={publicViews}
        currentOrderName={currentOrderName}
        setCurrentOrderViews={setCurrentOrderViews}
        currentOrderViews={currentOrderViews}
        currentTemplate={currentTemplate}
        setCurrentTemplate={setCurrentTemplate}
        currentPositionId={currentPositionId}
        openDialogRestoreChart={openDialogRestoreChart}
        setOpenDialogRestoreChart={setOpenDialogRestoreChart}
        orderId={orderId}
      />
      {/* )} */}
      {loading && <Loading height={"500px"} text={"Loading Dashboard..."} />}
    </div>
  );
};

export default AllTypesCharts;
