import axios from 'axios';
import { config } from 'config.js';

const deleteObjectRelation = async (id) => {  // Cambiamos a un parámetro
  const token = localStorage.getItem('token');
  const url = `${config.URL_BACKEND_PG}api/v1/objects/relation-delete/${id}`;  // Incluimos el parámetro en la URL

  const response = await axios.delete(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return response.data;  // Retornamos la respuesta para usarla en el frontend
};

export default deleteObjectRelation;
