export function removePropertyExist(data, deleteData) {
  const newData = data.map((item) => ({ ...item }));
  let counter = 0;
  newData.forEach((item) => {
    for (const key in deleteData) {
      if (deleteData.relationId === "Objects") {
        if (/\/ O$/.test(item[key])) {
          item[key] = "";
        }
      }
      if (deleteData.relationId === "Events") {
        if (/\/ E$/.test(item[key])) {
          item[key] = "";
        }
      }
      if (deleteData.relationId === "Components") {
        if (/\/ C$/.test(item[key])) {
          item[key] = "";
        }
      }
      if (item.hasOwnProperty(key) && item[key] === deleteData[key]) {
        item[key] = "";
      }
      if (item.hasOwnProperty(key) && item[key] === "") {
        counter++;
        if (counter === newData.length) {
          newData.forEach((it) => {
            if (it.hasOwnProperty(key)) {
              delete it[key];
            }
          });
        }
      }
    }
  });

  return newData;
}
