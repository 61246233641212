// Styles
import CustomButton from "components/Buttons/CustomButton";
// Constants
import { DELETE_COLOR, HOVER_DELETE_COLOR, PRIMARY_COLOR } from "utils/const";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectCategoryGroupBarChartSeries from "./SelectCatergoryGroupBarChartSeries";
const AddGroupDonutChart = ({
  dataInputs,
  columnsLibrary,
  setDataSeries,
  dataSeries,
  handlerSelectCategory,
  data,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showCategories, setShowCategories] = useState(true);

  const handlerAddCategory = () => {
    const res = selectedOptions.map((elm) => {
      return {
        donutStyle: {
          label: elm.value,
          alias: elm.value,
          borderColor: "#ffffff",
          backgroundColorHexadecimal: dataInputs.columnX.backgroundColor,
          backgroundColor: dataInputs.columnX.backgroundColor,
        },
        columnsValuesFilter: [],
      };
    });
    setDataSeries(res);
    setShowCategories(false);
  };

  const handlerRemoveCategory = () => {
    setDataSeries([]);
    setSelectedOptions([]);
    setShowCategories(true);
    handlerSelectCategory(null);
  };

  return (
    <>
      {dataInputs.columnX && (
        <>
          <h2 className="subtitle">
            {dataInputs.librarySelected && dataSeries.length === 0
              ? "Sub Category"
              : "Selected Sub Categories"}
          </h2>
          <br />
          {dataSeries.length > 0 &&
            dataSeries.map((elm, ind) => {
              return (
                <div
                  className="preview_data_time_series"
                  style={{
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="item">
                    <h3>{ind + 1}.</h3>
                  </div>
                  <div className="lineName">
                    <h3>{elm.donutStyle.label}</h3>
                  </div>
                  <div className="delete">
                    <DeleteIcon
                      sx={{
                        fontSize: "20px",
                        color: DELETE_COLOR,
                        "&:hover": {
                          cursor: "pointer",
                          color: HOVER_DELETE_COLOR,
                        },
                      }}
                      onClick={() =>
                        setDataSeries(
                          dataSeries.filter(
                            (item) =>
                              item.donutStyle.label !== elm.donutStyle.label
                          )
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}

          {showCategories && dataInputs.librarySelected && columnsLibrary && (
            <SelectCategoryGroupBarChartSeries
              columnsLibrary={columnsLibrary}
              handlerSelectCategory={handlerSelectCategory}
              dataInputs={dataInputs}
              data={data}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
            />
          )}
          <br />
          <br />

          {dataInputs.librarySelected && dataSeries.length === 0 ? (
            <CustomButton
              text={"Add Sub Category"}
              onClick={handlerAddCategory}
              margin={0}
            />
          ) : (
            dataInputs.librarySelected && (
              <CustomButton
                text={"Remove Sub Category"}
                onClick={handlerRemoveCategory}
                primaryColor={DELETE_COLOR}
                secondaryColor={HOVER_DELETE_COLOR}
                margin={0}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default AddGroupDonutChart;
