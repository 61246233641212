import { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
// Components
import Layout from "components/Layout/AdminDecimetrix";
import ConfigTypeElements from "pages/ConfigAdmin/typeElemts";
import ConfigTypeLines from "pages/ConfigAdmin/typeElemts/typeLines/TypeLine";
import ConfigTypeRegions from "pages/ConfigAdmin/TypeRegions";

// Styles
import {
  TitleAdminConfig,
  ReturnSettingsPage,
  SelectContainerAdminConfig,
  ConfigObjectsContainer,
} from "./configAdminStyles";
import { ShowAllButton2 } from "pages/PlatformSetUp/Preferences/preferencesstyles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const typeObjects = [
  {
    id: 1,
    name: "Points",
  },
  {
    id: 2,
    name: "Lines",
  },
  {
    id: 3,
    name: "Polygons",
  },
];

const Objects = () => {
  const [object, setObjects] = useState({
    id: "1",
    name: "Points",
  });
  const navigate = useNavigate();

  const navigation = (path) => {
    navigate(path);
  };

  const handleLibrary = (e) => {
    setObjects((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: typeObjects.find((object) => object.id === parseInt(e.target.value))
        ?.name,
    }));
  };

  return (
    <Layout>
      <div className="background-1">
        {/* titulo */}

        <ConfigObjectsContainer>
          <ShowAllButton2
            style={{ position: "absolute" }}
            onClick={() => navigation("/config-admin")}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
          </ShowAllButton2>
          <TitleAdminConfig>
            <h1>Digital Twin Object Configuration - {object.name}</h1>
          </TitleAdminConfig>
          {/* flecha */}
          {/* <ReturnSettingsPage onClick={() => navigation("/config-admin")}>
            <AiOutlineLeft />
          </ReturnSettingsPage> */}
          {/* desplegable */}
          <SelectContainerAdminConfig style={{ flexDirection: "column" }}>
            <h3 className="color1">Select</h3>
            <div>
              <p>Type Object: </p>
              <select name="endPoint" id="endPoint" onChange={handleLibrary}>
                {typeObjects?.map((object) => (
                  <option key={object.id} value={object.id}>
                    {object.name}
                  </option>
                ))}
              </select>
            </div>
          </SelectContainerAdminConfig>
        </ConfigObjectsContainer>

        {object.id === "1" && <ConfigTypeElements />}
        {object.id === "2" && <ConfigTypeLines />}
        {object.id === "3" && <ConfigTypeRegions />}
      </div>
    </Layout>
  );
};

export default Objects;
