import { LineContainerForm } from "components/Forms/SaveLines/indexStyle";
/**
 *
 * @param {Object} color - The color of the line preview.
 * @param {Object} width - The width of the line preview.
 * @param {Object} separator - The space for separator of the line preview.
 * @param {Object} text - The trigger to display leyend on the line preview.
 * @return {JSX.Element} Returns the JSX element that displays the line preview.
 */
const ShowLinePreview = ({ color, width, separator, text = true }) => {
  return (
    <LineContainerForm separator={separator} width={width} color={color}>
      {text ? <span className="text-line-preview">Line preview:</span> : <></>}
      <div className="container">
        <span className="circle"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="circle"></span>
      </div>
    </LineContainerForm>
  );
};

export default ShowLinePreview;
