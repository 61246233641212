import { useEffect, useState, useCallback } from "react";
// Helpers
import { sum, count } from "pages/Dashboard/helpers/objectsKpis";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Services
// import { findCharts } from "services/Dashboard/findCharts";
// Components
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { config } from "config";

const useFindKpiCharts = ({ openDialog, objects, loading }) => {
  const [charts, setCharts] = useState(null);
  const [kpiCharts, setKpiCharts] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlKpi = `${config.URL_BACKEND_MG}kpi-chart?adminCompanyId=${adminCompanyId}&from=objects`;
  const { data: dataKpi, error: errorDataKpi } = useSwr(urlKpi);

  useEffect(() => {
    if (!errorDataKpi && dataKpi) setCharts(dataKpi);
  }, [dataKpi, errorDataKpi, openDialog, reloadCharts]);

  useEffect(() => {
    const objectsFormat = createRowsObjects({ objects });
    setObjectsFormat(objectsFormat);
  }, [objects]);

  const getDataKpiChartCount = useCallback(async () => {
    if (objectsFormat && !loading) {
      const kpisObjects = charts.filter((item) => item.type === "count");
      // Charts total objects
      const chartsCountObjects = count({
        kpiChart: kpisObjects,
        objects: objectsFormat,
      });
      // Set state once with all the calculated values
      setKpiCharts([...chartsCountObjects]);
    }
  }, [charts, setKpiCharts, loading, objectsFormat]);

  const getDataKpiChartSum = useCallback(async () => {
    if (objectsFormat && !loading) {
      // Kpis type sum
      const kpisTypeSum = charts.filter((item) => item.type === "sum");
      // Sum objects by library
      const chartSumByLibrary = sum({
        kpiChart: kpisTypeSum,
        objects: objectsFormat,
      });

      setKpiCharts((prev) => [...prev, ...chartSumByLibrary]);
    }
  }, [charts, setKpiCharts, loading, objectsFormat]);

  useEffect(() => {
    if (charts && objects) {
      getDataKpiChartCount();
      getDataKpiChartSum();
    }
  }, [charts, objects, getDataKpiChartCount, getDataKpiChartSum]);

  return { kpiCharts };
};

export default useFindKpiCharts;
