import axios from "axios";
import { config } from "config.js";

const createPointTypeObject = async ({ body, adminCompanyId }) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_PG}api/v1/type-elements?libraryId=${parseInt(
    body.libraryId
  )}&adminCompanyId=${adminCompanyId}`;
  const result = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default createPointTypeObject;
