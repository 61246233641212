// Styles
import CustomButton from "components/Buttons/CustomButton";
// Constants
import { DELETE_COLOR, HOVER_DELETE_COLOR, PRIMARY_COLOR } from "utils/const";
import { useEffect, useState } from "react";
import ShowLinePreview from "helpers/showLinePreview";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectCategoryLineTimeSerious from "./SelectCategoryLineTimeSerious";
import generateRandomHexadecimal from "helpers/generateRandomHexadecimal";
import SelectCategoryGroupBarChartSeries from "./SelectCatergoryGroupBarChartSeries";
const AddGroupBarChart = ({
  dataInputs,
  columnsLibrary,
  setDataSeries,
  dataSeries,
  handlerSelectCategory,
  data,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showCategories, setShowCategories] = useState(true);
  const [showAddButton, setShowAddButton] = useState(false);

  const handlerAddCategory = () => {
    const res = selectedOptions.map((elm) => {
      const colorRandom = generateRandomHexadecimal();
      return {
        barStyle: {
          label: elm.value,
          alias: elm.value,
          borderColor: colorRandom,
          backgroundColorHexadecimal: colorRandom,
          backgroundColor: colorRandom,
        },
        columnsValuesFilter: [],
      };
    });
    setDataSeries(res);
    setShowCategories(false);
  };

  const handlerRemoveCategory = () => {
    setDataSeries([]);
    setSelectedOptions([]);
    setShowCategories(true);
    handlerSelectCategory(null);
  };

  const handleShowAddButton = (options) => {
    if (options.length) return setShowAddButton(true);
    return setShowAddButton(false);
  };

  useEffect(() => {
    selectedOptions && handleShowAddButton(selectedOptions);
  }, [selectedOptions]);

  return (
    <>
      {dataInputs.columnX && (
        <>
          <h2 className="subtitle">
            {dataInputs.librarySelected && dataSeries.length === 0
              ? "X-Axis (Sub Category)"
              : `Sub Categories (${dataInputs.category.name})`}
          </h2>
          {dataSeries.length > 0 &&
            dataSeries.map((elm, ind) => {
              return (
                <div className="preview_data_time_series">
                  <div className="item">
                    <h3>{ind + 1}.</h3>
                  </div>
                  <div className="lineName">
                    <h3>{elm.barStyle.label}</h3>
                  </div>
                  <div className="linePreview">
                    <ShowLinePreview
                      text={false}
                      width={1}
                      color={elm.barStyle.borderColor || PRIMARY_COLOR}
                    />
                  </div>
                  <div className="delete">
                    <DeleteIcon
                      sx={{
                        fontSize: "20px",
                        color: DELETE_COLOR,
                        "&:hover": {
                          cursor: "pointer",
                          color: HOVER_DELETE_COLOR,
                        },
                      }}
                      onClick={() =>
                        setDataSeries(
                          dataSeries.filter(
                            (item) => item.barStyle.label !== elm.barStyle.label
                          )
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}

          {showCategories && dataInputs.librarySelected && columnsLibrary && (
            <SelectCategoryGroupBarChartSeries
              columnsLibrary={columnsLibrary}
              handlerSelectCategory={handlerSelectCategory}
              dataInputs={dataInputs}
              data={data}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
            />
          )}
          <br />
          <br />

          {showAddButton &&
            (dataInputs.librarySelected && dataSeries.length === 0 ? (
              <CustomButton
                text={"Add X-Axis (Sub Category)"}
                onClick={handlerAddCategory}
                margin={0}
              />
            ) : (
              dataInputs.librarySelected && (
                <CustomButton
                  text={"Remove X-Axis (Sub Category)"}
                  onClick={handlerRemoveCategory}
                  primaryColor={DELETE_COLOR}
                  secondaryColor={HOVER_DELETE_COLOR}
                  margin={0}
                />
              )
            ))}
        </>
      )}
    </>
  );
};

export default AddGroupBarChart;
