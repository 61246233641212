import { getNumericValue } from "helpers/getNumericValue";

function filterObjectsByLibraryId(objects, libraryId) {
  return objects.filter((obj) => obj.typeElement.pointLibraryId === libraryId);
}

function filterObjects(objects, filters) {
  return objects.filter((obj) => {
    return filters.every((filter) => {
      return obj.attributes.some((attr) => {
        return attr.name === filter.name && filter.values.includes(attr.value);
      });
    });
  });
}

function processCountDatasets(filteredObjects, chart, groupedLabels) {
  const filteredByAttributes = filterObjects(
    filteredObjects,
    chart.columnsValuesFilter
  ); 

  const dataByLabel = groupedLabels.map((label) => ({ label, value: 0 }));
  filteredByAttributes.forEach((obj) => {
    const dataLabel = obj.attributes.find(
      (attr) => attr.name === chart.columnX.name
    )?.value;

    const index = dataByLabel.findIndex((item) => item.label === dataLabel);
    if (index !== -1) {
      dataByLabel[index].value += 1;
    }
  });

  return [
    {
      ...chart.donutStyle,
      data: dataByLabel.map((elm) => elm.value),
    },
  ];
}

export function countByValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    const datasets = processCountDatasets(
      filteredObjects,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}

function processDatasets(filteredObjects, chart, groupedLabels) {
  const filteredByAttributes = filterObjects(
    filteredObjects,
    chart.columnsValuesFilter
  ); 
  const dataByLabel = groupedLabels.map((label) => ({ label, value: null }));
  
  filteredByAttributes.forEach((obj) => {
    const value = obj.attributes.find(
      (attr) => attr.name === chart.columnX.name
    )?.value;

    const yValue = obj.attributes.find(
      (attr) => attr.name === chart.columnsY[0]
    )?.value;

    const numericValue = getNumericValue(yValue);

    const index = dataByLabel.findIndex((item) => item.label === value);
    if (index !== -1) {
      if (
        dataByLabel[index].value === null ||
        isNaN(dataByLabel[index].value)
      ) {
        dataByLabel[index].value = numericValue;
      } else {
        dataByLabel[index].value += numericValue;
      }
    }
  });

  return [
    {
      ...chart.donutStyle,
      data: dataByLabel.map((elm) => elm.value),
    },
  ];
}

export function sumValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    const datasets = processDatasets(
      filteredObjects,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}
