import { Container, Panel } from "./styles";
import { AiFillSetting } from "react-icons/ai";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperationClusters,
  setObjectClusters,
  setComponentClusters,
  setRadiusElementsNear,
  setMZoomView,
} from "redux/actions";
import GenericDialog from "components/Dialogs/GenericDialog";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  WHITE_COLOR,
} from "utils/const";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { useContext } from "react";
import { ThemeContext } from "App";
import ObjectIcon from "images/ObjectIcon";
import ComponentIcon from "images/ComponentIcon";
import EventIcon from "images/EventIcon";
export default function FloatingSetting() {
  const { theme } = useContext(ThemeContext);

  const [seePanel, setSeePanel] = useState(false);
  const dispatch = useDispatch();

  const mZoomView = useSelector((state) => state.digitalTwinReducer.mZoomView);

  const operationClusters = useSelector(
   (state) => state.digitalTwinReducer.operationClusters                                   
  );
  const objectClusters = useSelector(
    (state) => state.digitalTwinReducer.objectClusters 
  );

  const componentClusters = useSelector(
    (state) => state.digitalTwinReducer.componentClusters
  );

  const radiusElementsNear = useSelector(
    (state) => state.digitalTwinReducer.radiusElementsNear
  );

  const handle50MZoomView = (e) => {
    let newValues;
    if (e.target.name === "icon") {
      newValues = {
        ...mZoomView,
        color: false,
        icon: true,
      };
    } else if (e.target.name === "color") {
      newValues = {
        ...mZoomView,
        color: true,
        icon: false,
      };
    } else {
      newValues = {
        ...mZoomView,
        zoom: e.target.value,
      };
    }

    localStorage.setItem("mZoomView", JSON.stringify(newValues));
    dispatch(setMZoomView(newValues));
  };
  const handleOperationClusters = (e) => {
    const newValues = {
      ...operationClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("operationCluster", JSON.stringify(newValues));
    dispatch(setOperationClusters(newValues));
  };

  const handleObjectClusters = (e) => {
    const newValues = {
      ...objectClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("objectClusters", JSON.stringify(newValues));
    dispatch(setObjectClusters(newValues));
  };

  const handleComponentClusters = (e) => {
    const newValues = {
      ...componentClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("componentClusters", JSON.stringify(newValues));
    dispatch(setComponentClusters(newValues));
  };

  const handleRadiusElementsNear = (e) => {
    const sanitizedValue = parseInt(e.target.value);
    dispatch(setRadiusElementsNear(sanitizedValue));
  };

  const handleSeePanel = (status) => {
    setSeePanel(() => status);
  };

  return (
    <>
      <Container className="background-2 border-color-1">
        {!seePanel && (
          <SimpleToolTip title="Map Settings" placement="left">
            <span
              className="icon-settings"
              onClick={() => handleSeePanel(true)}
            >
              <AiFillSetting />
            </span>
          </SimpleToolTip>
        )}
      </Container>

      <GenericDialog
        openDialog={seePanel}
        setOpenDialog={setSeePanel}
        style={{
          background:
            theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
        }}
        // style={{ background: `${WHITE_BACKGROUND}` }}
        content={{
          title: "Map Settings ",

          content: (
            <Panel
              style={{
                color:
                  theme === "light" ? document.body.style.color : WHITE_COLOR,
              }}
            >
              <div className="body">
                <section>
                  <p>
                    <b>Radius: </b>
                    Determines the maximum distance at which points are grouped
                    in a cluster.
                  </p>
                  <br />
                  <p>
                    <b>Max zoom: </b>
                    Sets the maximum zoom level on the map at which clusters are
                    ungrouped into individual points. The maximum zoom value is
                    23.
                  </p>
                </section>
                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>Object Clusters:</p>
                    <span className="color-cluster-object"></span>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        Radius:
                        <input
                          type="number"
                          name="radius"
                          value={objectClusters.radius}
                          onChange={handleObjectClusters}
                        />
                      </label>
                    </div>
                    <div className="body-item">
                      <label className="label">
                        Max Zoom:
                        <input
                          type="number"
                          name="maxZoom"
                          value={objectClusters.maxZoom}
                          onChange={handleObjectClusters}
                          onInput={(e) => {
                            if (e.target.value > 23) e.target.value = 23;
                          }}
                          min={1}
                          max={23}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>Event Clusters:</p>
                    <span className="color-cluster-event"></span>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        Radius:
                        <input
                          type="number"
                          name="radius"
                          value={operationClusters.radius}
                          onChange={handleOperationClusters}
                        />
                      </label>
                    </div>
                    <div className="body-item">
                      <label className="label">
                        Max Zoom:
                        <input
                          type="number"
                          name="maxZoom"
                          value={operationClusters.maxZoom}
                          onChange={handleOperationClusters}
                          onInput={(e) => {
                            if (e.target.value > 23) e.target.value = 23;
                          }}
                          min={1}
                          max={23}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>Component Clusters:</p>
                    <span className="color-cluster-component"></span>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        Radius:
                        <input
                          type="number"
                          name="radius"
                          value={componentClusters.radius}
                          onChange={handleComponentClusters}
                        />
                      </label>
                    </div>
                    <div className="body-item">
                      <label className="label">
                        Max Zoom:
                        <input
                          type="number"
                          name="maxZoom"
                          value={componentClusters.maxZoom}
                          onChange={handleComponentClusters}
                          onInput={(e) => {
                            if (e.target.value > 23) e.target.value = 23;
                          }}
                          min={1}
                          max={23}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>50m Zoom View</p>
                  </div>
                  <div className="body-item">
                    <label className="label">
                      Zoom:
                      <input
                        type="number"
                        name="zoom"
                        value={parseInt(mZoomView.zoom)}
                        onChange={handle50MZoomView}
                        onInput={(e) => {
                          if (e.target.value > 23) e.target.value = 23;
                        }}
                        min={1}
                        max={23}
                      />
                    </label>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        <div className="container-icons">
                          <div className="preview-point-icon">
                            <p> Object:</p>
                            <ObjectIcon width={40} height={40} />
                          </div>
                          <div className="preview-point-icon">
                            <p>Component:</p>

                            <ComponentIcon width={40} height={40} />
                          </div>
                          <div className="preview-point-icon">
                            <p>Event:</p>

                            <EventIcon width={40} height={40} />
                          </div>
                        </div>
                        <input
                          type="checkbox"
                          name="icon"
                          checked={mZoomView.icon}
                          onChange={handle50MZoomView}
                          min={1}
                          max={23}
                          style={{
                            transform: "scale(0.2)",
                          }}
                        />
                      </label>
                    </div>
                    <div className="body-item">
                      <label className="label">
                        <div className="container-icons">
                          <div className="preview-point-color">
                            <p> Object:</p>
                            <span className="color-cluster-object"></span>
                          </div>
                          <div className="preview-point-color">
                            <p>Component:</p>
                            <span className="color-cluster-component"></span>
                          </div>
                          <div className="preview-point-color">
                            <p>Event:</p>
                            <span className="color-cluster-event"></span>
                          </div>
                        </div>
                        <input
                          type="checkbox"
                          name="color"
                          checked={mZoomView.color}
                          onChange={handle50MZoomView}
                          style={{
                            transform: "scale(0.2)",
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>Radius Elements Near:</p>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        Radius (m):
                        <input
                          type="number"
                          name="radius"
                          value={radiusElementsNear}
                          onChange={handleRadiusElementsNear}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          ),
        }}
      />
    </>
  );
}
