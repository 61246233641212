export default function rangeFilterByDate({ rows, id, dateFilters }) {
  const sd = dateFilters.min !== "" ? dateFilters.min : undefined;
  const ed = dateFilters.max !== "" ? dateFilters.max : undefined;

  if (ed || sd) {
    return rows.filter((r) => {
      const cellDate = r[id] !== undefined && r[id].slice(0, 10);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}
