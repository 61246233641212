import { BLACK_COLOR } from "utils/const.jsx";
import { Loader, LoadingContainer } from "./LoadingStyle.jsx";

const LoadingStyle = ({ height, text }) => {
  return (
    <LoadingContainer height={height}>
      <Loader>
        <img
          className="logo"
          src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png"
          alt="ghg logo"
        />
      </Loader>
      <p style={{ color: `${BLACK_COLOR}` }}>{text || "Loading"}</p>
    </LoadingContainer>
  );
};

export default LoadingStyle;
