import { styled } from "@mui/material/styles";

import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import {
  PRIMARY_COLOR,
  BLACK_COLOR,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "../../utils/const";

export const useStyles = makeStyles(() => ({
  detailRoot: {
    padding: "0 16px",
  },
  expanded: {},
  root: {
    minHeight: 8 * 6,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    margin: "0px 0",
    "&$expanded": {
      margin: "0px 0",
      marginBottom: 0,
    },
  },
}));

export const ContentFilterItemLabel = styled("label")`
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: centrer;
  justify-content: space-between;

  .input-start {
    margin-bottom: 10px;
  }
`;

export const ContentFilterItem = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* color: ${BLACK_COLOR}; */

  .start {
    margin-right: 21px;
  }
`;

export const ContentFilters = styled("div")`
  padding: 0 10px;
  margin-top: 10px;
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;
  border-radius: 10px 10px 0 0;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: ${WHITE_COLOR};
  }
`;

export const CustomizedDiv = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${document.body.style.color};
  background-color: ${WHITE_SECOND_COLOR};

  p {
    margin-left: 10px;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const CustomizedAccordion = styled(Accordion)`
  margin-top: 0px;
  background-color: ${WHITE_SECOND_COLOR};
  border-bottom: 1px solid #b8b2b2;
  padding: 10px;
  box-shadow: none;
`;

export const CustomizedTypography = styled(Typography)`
  width: 49%;
  padding: 5%;
  font-size: 1.4rem;
  @media (max-width: 480px) {
    padding: 5%;
  }
`;

export const CustomizedTypographyLayer = styled(Typography)`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  @media (max-width: 480px) {
  }
`;

export const CustomTypography = styled(Typography)`
  padding: 0px;
  margin: 0px;
`;

export const CustomList = styled(List)`
  padding: 0px;
  margin: 0px;
`;

export const CustomizeToggleButtonGroup = styled(ToggleButtonGroup)`
  padding: 0 13px 5px;
  @media (max-width: 480px) {
    padding: 1%;
    margin-right: 2%;
  }
`;

export const CustomizedMenu = styled(Menu)``;

export const CustomizedButton = styled(Button)`
  margin-left: 20%;
  padding: 10px;
`;

export const CustomizedCard = styled(Card)`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const FilterTabStyles = styled("div")`
  width: 100%;

  div {
    display: flex;
    align-items: center;
    align-content: center;
  }
  p {
    font-size: 1.4rem;
  }

  .date {
    width: 100%;
    padding: 0 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;
