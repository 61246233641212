// React
import React, { useEffect, useState } from "react";
// Redux
import {
  setShowLines,
  setDrawLineMode,
  setNeuronSelectTool,
  setTmpDistanceFeatures,
  setObjecstsLinesIds,
} from "redux/actions/digitalTwin";
import {
  setGeometryLine,
  setFeaturesDetailsDT,
  setGeometryLineConfig,
} from "redux/actions/admin";
import { useSelector, useDispatch, useStore } from "react-redux";
// Style
import TimelineIcon from "@mui/icons-material/Timeline";
import RoomIcon from "@mui/icons-material/Room";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { LineToolsContainer } from "./LineToolsStyles";
import LineToolsModules from "./LineToolsModules";
import OpenDialog from "components/Dialogs/DialogInformation";
import LineRelations from "components/Forms/SaveLines/LineRelations";
import UpdateGeometryCoordinateLines from "../UpdateGeometryCoordinateLines";
import ParametrizedLines from "components/Forms/SaveLines";
import FreeLine from "components/Forms/SaveLines/FreeLine";
import { Layer, Popup, Source } from "react-map-gl";
import { fixDecimals } from "helpers/fixDecimals";
import CustomButton from "components/Buttons/CustomButton";
import { DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";
import DeleteIcon from "@mui/icons-material/Delete";

function LineTools() {
  const { ToolFreeline, ToolLineRelationObjects, ToolMeasureLineDistance } =
    LineToolsModules();
  const [openDialogRelationLines, setOpenDialogRelationLines] = useState(false);
  const [showSaveLineUpdate, setShowSaveLineUpdate] = useState(false);
  const [showSaveLine, setShowSaveLine] = useState(false);
  const [distancePopup, setDistancePopup] = useState({});

  const dispatch = useDispatch();

  const store = useStore();

  //selectors
  const { drawerControl } = useSelector((state) => state.digitalTwinReducer);
  const drawLineMode = useSelector(
    (state) => state.digitalTwinReducer.drawLineMode
  );
  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );
  const upadateGeometryLine = useSelector(
    (state) => state.adminReducer.updateGeometryLine
  );
  const { tmpDistanceFeatures } = useSelector(
    (state) => state.digitalTwinReducer
  );

  //use effect that monitors when to save the lines
  useEffect(() => {
    setShowSaveLine(() => {
      if (geometryLine?.geometry?.type === "LineString") {
        if (drawLineMode.mode === "freeline") {
          return true;
        }
        if (drawLineMode.mode === "linerelations") {
          return true;
        }
      }
      return false;
    });
  }, [geometryLine, drawLineMode]);

  useEffect(() => {
    setShowSaveLineUpdate(() => {
      if (upadateGeometryLine?.geometry?.type === "LineString") {
        return true;
      }
      return false;
    });
  }, [upadateGeometryLine]);

  useEffect(() => {
    if (tmpDistanceFeatures) {
      const ft = tmpDistanceFeatures.features.find(
        (it) => it.id === tmpDistanceFeatures.click.id
      );
      if (ft) {
        const { distance, units } = ft?.properties;
        setDistancePopup({
          distance:
            distance < 1
              ? fixDecimals(distance * 1000, 2)
              : fixDecimals(distance, 2),
          units: distance < 1 ? "Meters" : units,
        });
      }
    }
  }, [tmpDistanceFeatures]);

  const setLineMode = (e) => {
    const button = e.currentTarget.value;
    switch (button) {
      case "freeline":
        dispatch(
          setDrawLineMode({
            mode: "freeline",
            drawStatus: true,
            show: true,
            features: [],
          })
        );
        setNeuron(dispatch, true, 220, 62);
        ToolFreeline();
        break;

      case "linerelations":
        dispatch(
          setDrawLineMode({
            mode: "linerelations",
            drawStatus: true,
            show: true,
            features: [],
          })
        );
        setNeuron(dispatch, true, 250, 62);
        setOpenDialogRelationLines(true);
        // ToolLineRelationObjects();
        break;

      case "linedistance":
        dispatch(
          setDrawLineMode({
            mode: "linedistance",
            drawStatus: true,
            show: true,
            features: [],
          })
        );
        setNeuron(dispatch, true, 270, 62);
        ToolMeasureLineDistance();
        break;

      default:
        dispatch(
          setDrawLineMode({
            mode: "",
            drawStatus: false,
            show: false,
          })
        );
        setNeuron(dispatch, false, null, null);
        break;
    }
  };

  const handlerCloseRelationLinesForm = (data) => {
    drawerControl.changeMode("simple_select");
    setNeuron(dispatch, false, null, null);
    dispatch(setShowLines(true));
    dispatch(setGeometryLine(null));
    dispatch(
      setDrawLineMode({
        mode: "",
        drawStatus: false,
        show: false,
        features: [],
      })
    );
    setOpenDialogRelationLines(data);
  };
  const handlerCloseRelationLinesSaveForm = (data) => {
    drawerControl.changeMode("simple_select");
    setNeuron(dispatch, false, null, null);
    dispatch(setShowLines(true));
    dispatch(setGeometryLine(null));
    dispatch(
      setDrawLineMode({
        mode: "",
        drawStatus: false,
        show: false,
        features: [],
      })
    );
    setShowSaveLine(data);
  };

  // passing functionality to be executed on dialog button action
  const handleSetLineRelations = () => {
    ToolLineRelationObjects();
  };

  const handleClosePopup = () => {
    dispatch(
      setTmpDistanceFeatures({
        ...tmpDistanceFeatures,
        click: {},
      })
    );
  };

  // handler for deleting a distance line
  const handleDeleteDistanceLine = (featureId) => {
    const drawerControl = store.getState().digitalTwinReducer.drawerControl;
    const tmpDistanceFeatures =
      store.getState().digitalTwinReducer.tmpDistanceFeatures;
    if (tmpDistanceFeatures === undefined) return;
    const features = tmpDistanceFeatures.features;
    if (features.find((ft) => ft.id === featureId)) {
      tmpDistanceFeatures.features = features.filter(
        (it) => it.id !== featureId
      );
      dispatch(setTmpDistanceFeatures(tmpDistanceFeatures));
    }
    drawerControl.delete(featureId);
    handleClosePopup();
  };

  //buttons for each line tool
  return (
    <>
      {/* Dialog for creating FREELINE */}
      {geometryLine?.geometry?.type === "LineString" &&
        drawLineMode.mode === "freeline" && (
          <OpenDialog
            openDialog={showSaveLine}
            setOpenDialog={setShowSaveLine}
            disagree={() => {
              dispatch(setGeometryLine(null));
              dispatch(
                setFeaturesDetailsDT({
                  geometry: {
                    type: null,
                  },
                })
              );
              dispatch(
                setDrawLineMode({
                  mode: "",
                  show: false,
                  drawStatus: false,
                  features: [],
                })
              );
              drawerControl.changeMode("simple_select");
              setNeuron(dispatch, false, null, null);
            }}
            content={{
              title: "Save Free Line Draw",
              description: <FreeLine />,
            }}
            maxWidth="1500px"
            minWidth="300px"
          />
        )}
      {/* FIRST Dialog for LINE OBJECT RELATIONS  */}
      <OpenDialog
        openDialog={openDialogRelationLines}
        setOpenDialog={handlerCloseRelationLinesForm}
        content={{
          title: "Line Relations",
          description: (
            <LineRelations
              setOpendDialog={setOpenDialogRelationLines}
              handleSetLineRelations={handleSetLineRelations}
            />
          ),
        }}
        maxWidth="1500px"
        minWidth="300px"
      />
      {/* card with the summary when creating Lines related with objects */}
      {geometryLine?.geometry?.type === "LineString" &&
        drawLineMode.mode === "linerelations" && (
          <OpenDialog
            openDialog={showSaveLine}
            setOpenDialog={handlerCloseRelationLinesSaveForm}
            execute={() => {
              drawerControl.changeMode("simple_select");
              setNeuron(dispatch, false, null, null);
              dispatch(
                setDrawLineMode({
                  mode: "",
                  show: false,
                  drawStatus: false,
                  features: [],
                })
              );
              setShowLines(false);
              dispatch(setGeometryLine(null));
              dispatch(setGeometryLineConfig({}));
              dispatch(setObjecstsLinesIds([]));
            }}
            disagree={() => {
              dispatch(setGeometryLine(null));
              dispatch(
                setFeaturesDetailsDT({
                  geometry: {
                    type: null,
                  },
                })
              );
              dispatch(
                setDrawLineMode({
                  mode: "",
                  show: false,
                  drawStatus: false,
                  features: [],
                })
              );
              drawerControl.changeMode("simple_select");
              setNeuron(dispatch, false, null, null);
            }}
            content={{
              title: "Add new Line",
              description: <ParametrizedLines />,
            }}
            maxWidth="1500px"
            minWidth="300px"
          />
        )}

      {showSaveLineUpdate && (
        <UpdateGeometryCoordinateLines
          setShowSaveLine={setShowSaveLineUpdate}
        />
      )}

      {drawLineMode.show && !showSaveLine && (
        <LineToolsContainer>
          <SimpleToolTip title={"Free line"} placement="left">
            <button
              id={"freeline"}
              type="button"
              value={"freeline"}
              onClick={setLineMode}
              className="mapbox-gl-draw_ctrl-draw-btn button-first"
            >
              <TimelineIcon />
            </button>
          </SimpleToolTip>
          <SimpleToolTip title={"Object relation"} placement="left">
            <button
              id={"linerelations"}
              type="button"
              value={"linerelations"}
              onClick={setLineMode}
              className="mapbox-gl-draw_ctrl-draw-btn"
            >
              <RoomIcon />
            </button>
          </SimpleToolTip>
          <SimpleToolTip title={"Line distance"} placement="left">
            <button
              id={"linedistance"}
              type="button"
              value={"linedistance"}
              onClick={setLineMode}
              className="mapbox-gl-draw_ctrl-draw-btn button-last"
            >
              <SquareFootIcon />
            </button>
          </SimpleToolTip>
        </LineToolsContainer>
      )}
      {/** PopUp for line distance tool */}
      {tmpDistanceFeatures?.features?.length && (
        <>
          <Source type="geojson" data={tmpDistanceFeatures}>
            <Layer
              key="line-distance"
              id="line-distance"
              type={"line"}
              paint={{
                "line-color": "#ffff00",
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round",
              }}
            />
          </Source>
          {tmpDistanceFeatures &&
            tmpDistanceFeatures.click.lng &&
            tmpDistanceFeatures.click.lat && (
              <Popup
                longitude={tmpDistanceFeatures.click.lng}
                latitude={tmpDistanceFeatures.click.lat}
                anchor="bottom"
                onClose={handleClosePopup}
              >
                <div
                  style={{
                    width: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>
                    <h3>
                      Distance: {distancePopup.distance} {distancePopup.units}
                    </h3>
                  </label>{" "}
                  <CustomButton
                    text={<DeleteIcon />}
                    primaryColor={DELETE_COLOR}
                    secondaryColor={HOVER_DELETE_COLOR}
                    onClick={() =>
                      handleDeleteDistanceLine(tmpDistanceFeatures.click.id)
                    }
                    margin={0}
                  />
                </div>
              </Popup>
            )}
        </>
      )}
    </>
  );
}

const setNeuron = (dispatch, show, top, right) => {
  if (dispatch)
    dispatch(
      setNeuronSelectTool({
        show,
        top,
        right,
      })
    );
  return null;
};

export default LineTools;
