import styled from "styled-components";
import {
  PRIMARY_COLOR,
  OBJECT_CLUSTER_COLOR,
  EVENT_CLUSTER_COLOR,
  COMPONENT_CLUSTER_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  SECOND_COLOR,
} from "utils/const";

export const Container = styled.div`
  position: absolute;
  bottom: 6rem;
  right: 1rem;
  z-index: 7;
  /* background: #f1f1f1; */
  border-radius: 1rem;

  .icon-settings {
    font-size: 2.5rem;
    color: var(--background-primary_color);
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-85%, 20px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      font-size: 1.5rem;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;

export const Panel = styled.div`
  padding: 10px;

  .head {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    border-bottom: 1px solid ${PRIMARY_COLOR};
  }

  .head-left {
    cursor: pointer;
    color: red;
  }

  .body {
    margin-top: 1rem;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
  }

  .body-items {
    font-size: 1.5rem;
    /* color: ${BLACK_COLOR}; */
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .object-clusters-title {
    display: flex;
    align-items: center;
  }

  .color-cluster-object,
  .color-cluster-event,
  .color-cluster-component {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 10px;
    background-color: ${OBJECT_CLUSTER_COLOR};
    box-shadow: 0 0 3px ${WHITE_COLOR};
  }

  .color-cluster-event {
    background-color: ${EVENT_CLUSTER_COLOR};
  }

  .color-cluster-component {
    background-color: ${COMPONENT_CLUSTER_COLOR};
  }

  .container-icons {
    width: 140px;
  }

  .preview-point-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-width: 20px;
    justify-content: space-between;
    p {
      margin-right: 5px;
    }
  }

  .preview-point-color {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-width: 20px;
    justify-content: space-between;
    height: 46px;
    p {
      margin-right: 5px;
    }
  }

  .body-item {
    flex: 1;

    input {
      width: 40%;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid ${PRIMARY_COLOR};
      text-align: center;
      background-color: white;
      margin-left: 1rem;
      /* color: ${BLACK_COLOR}; */
    }

    label {
      display: flex;
      gap: 0.2rem;
      font-weight: 500;
      /* color: ${BLACK_COLOR}; */
    }
  }
`;

export const PanelSettingsContainer = styled.div`
  min-width: 1000px;
  height: 50vh;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const PanelSettingsCategories = styled.div`
  width: 20%;
  border: 1px solid black;
  border-radius: 5px;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 5px 10px 5px;
  }

  li {
    width: 100%;
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background-color: ${(props) => (props.option ? SECOND_COLOR : WHITE_COLOR)};
    color: black;
    &:hover {
      cursor: pointer;
      background-color: ${SECOND_COLOR};
    }
  }
`;

export const PanelSettingsConfigContainer = styled.div`
  width: 50vw;
  height: 50vh;
  overflow-y: scroll;
  border: 1px solid black;
  border-radius: 5px;
  padding-left: 10px;
`;
