import { useEffect, useState } from "react";

// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { config } from "config";
export const useFindImgChartsEvents = ({ openDialog }) => {
  const [charts, setCharts] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlImgChart = `${config.URL_BACKEND_MG}img-chart?adminCompanyId=${adminCompanyId}&from=events`;
  const { data: dataImgChart, error: errorDataImgChart } = useSwr(urlImgChart);
  
  useEffect(() => {
    if (!errorDataImgChart && dataImgChart) setCharts(dataImgChart);
  }, [dataImgChart, errorDataImgChart, openDialog, reloadCharts]);

  return { imgCharts: charts };
};
