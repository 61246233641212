import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// custom style
import { WrapperTypeObjectTitle } from "../../AccordionStyles";

//redux
import {
  showEventsByInspectionType,
  showOperationsByType,
  showEventsByType,
  setShowEventsByLibrary,
  setFilterEvents,
  setShowEvents,
} from "redux/actions/digitalTwin";
import { useDispatch, useSelector } from "react-redux";

//helpers
import deleteDuplicatePointsLibrary from "helpers/filters/deleteDuplicatePointsLibrary";
import getLibrariesTrue from "helpers/filters/getLibrariesTrue";

//utils
import {
  BACKGROUND_SECOND_GREY,
  BLACK_COLOR,
  PRIMARY_COLOR,
  SET_VISIBILITY_ICON,
  VISIBILITY_OFF_ICON,
} from "utils/const";

const FilterEvents = () => {
  const dispatch = useDispatch();

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );

  const showOperationByType = useSelector(
    (state) => state.digitalTwinReducer.showOperationsByType
  );

  const showAllEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );

  const showEventsByLibrary = useSelector(
    (state) => state.digitalTwinReducer.showEventsByLibrary
  );

  const showEvents = useSelector(
    (state) => state.digitalTwinReducer.showEvents
  );

  const handlerClickFilterComponentsByLibrary = (state, libraryId, color) => {
    const dataLibraryObject = showEventsByLibrary.map((elm) => {
      if (elm.libraryId === libraryId) {
        return { ...elm, state: state, color: color };
      } else {
        return elm;
      }
    });
    showAllEventsByType.forEach((elm) => {
      if (elm.libraryId === libraryId) {
        elm.state = state;
      }
    });
    const operationByTypeOff = Object.values(showOperationByType).every(
      (value) => value === false
    );
    const operationByTypeTrue = Object.values(showOperationByType).every(
      (value) => value === true
    );
    const objectLibraryOff = dataLibraryObject.filter((elm) => {
      return elm.state === true;
    });
    if (showEvents.state === false) {
      dispatch(
        setShowEvents({ state: state, color: `${SET_VISIBILITY_ICON}` })
      );
    }
    if (objectLibraryOff.length === 0 && operationByTypeOff) {
      dispatch(
        setShowEvents({ state: false, color: `${VISIBILITY_OFF_ICON}` })
      );
    }
    if (objectLibraryOff.length > 0) {
      dispatch(setShowEvents({ state: true, color: `${SET_VISIBILITY_ICON}` }));
    }
    if (
      objectLibraryOff.length === dataLibraryObject.length &&
      operationByTypeTrue
    ) {
      dispatch(setShowEvents({ state: true, color: `${PRIMARY_COLOR}` }));
    }
    dispatch(showEventsByType(showAllEventsByType));
    dispatch(setShowEventsByLibrary(dataLibraryObject));
    dispatch(setFilterEvents(true));
  };

  const handlerClickFilterComponentsByType = (typeId, libraryId, state) => {
    const currentLibrary = showEventsByLibrary.filter((elm) => {
      return elm.libraryId === libraryId;
    });
    showAllEventsByType.forEach((elm) => {
      if (elm.libraryId === libraryId && typeId === elm.id) {
        elm.state = state;
      }
    });
    dispatch(showEventsByType(showAllEventsByType));
    const currentObjectsType = showAllEventsByType.filter((elm) => {
      return elm.libraryId === libraryId;
    });
    const currentObjectsActivate = currentObjectsType.filter((elm) => {
      return elm.state === true;
    });
    if (showEvents.state === false) {
      dispatch(
        setShowEvents({ state: state, color: `${SET_VISIBILITY_ICON}` })
      );
    }
    if (currentObjectsActivate.length === 0) {
      const dataLibraryObject = showEventsByLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: false, color: `${VISIBILITY_OFF_ICON}` };
        } else {
          return elm;
        }
      });
      if (getLibrariesTrue(showEventsByLibrary)) {
        dispatch(
          setShowEvents({
            state: true,
            color: `${SET_VISIBILITY_ICON}`,
          })
        );
      }
      dispatch(setShowEventsByLibrary(dataLibraryObject));
      if (getLibrariesTrue(showEventsByLibrary) === false) {
        dispatch(
          setShowEvents({
            state: false,
            color: `${VISIBILITY_OFF_ICON}`,
          })
        );
      }
    }
    if (currentObjectsActivate.length > 0) {
      const dataLibraryObject = showEventsByLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: true, color: `${SET_VISIBILITY_ICON}` };
        } else {
          return elm;
        }
      });
      dispatch(setShowEventsByLibrary(dataLibraryObject));
      dispatch(setShowEvents({ state: true, color: `${SET_VISIBILITY_ICON}` }));
      if (
        getLibrariesTrue(showEventsByLibrary) === false &&
        currentLibrary.length === 1
      ) {
        dispatch(setShowEvents({ state: true, color: `${PRIMARY_COLOR}` }));
      }
    }
    if (currentObjectsActivate.length === currentLibrary.length) {
      const dataLibraryObject = showEventsByLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: true, color: `${PRIMARY_COLOR}` };
        } else {
          return elm;
        }
      });
      dispatch(setShowEventsByLibrary(dataLibraryObject));
    }
    if (
      currentObjectsActivate.length === currentLibrary.length &&
      getLibrariesTrue(showEventsByLibrary)
    ) {
      dispatch(setShowEvents({ state: true, color: `${PRIMARY_COLOR}` }));
    }
    dispatch(setFilterEvents(true));
  };

  const handlerClickFilterEvents = (state, color) => {
    if (state) {
      for (let key in showEventByInspectionType) {
        showEventByInspectionType[key] = state;
      }
      for (let key in showOperationByType) {
        showOperationByType[key] = state;
      }
      dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
      dispatch(showOperationsByType({ ...showOperationByType }));
    } else {
      for (let key in showEventByInspectionType) {
        showEventByInspectionType[key] = state;
      }
      for (let key in showOperationByType) {
        showOperationByType[key] = state;
      }

      dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
      dispatch(showOperationsByType({ ...showOperationByType }));
    }
    showAllEventsByType.forEach((elm) => {
      elm.state = state;
    });

    const dataLibraryObject = showEventsByLibrary.map((elm) => ({
      ...elm,
      state: state,
      color: color,
    }));
    dispatch(showEventsByType(showAllEventsByType));
    dispatch(setShowEventsByLibrary(dataLibraryObject));
    dispatch(setShowEvents({ state: state, color: color }));
    dispatch(setFilterEvents(true));
  };

  const currentLibrarys = deleteDuplicatePointsLibrary(
    showEventsByLibrary.length > 0 ? showEventsByLibrary : []
  );

  const currentLibrarysSort = currentLibrarys.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const showAllEventsByTypeSort = showAllEventsByType.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Accordion
      className="background-2"
      sx={{
        paddingLeft: "0px",
        border: "none",
        boxShadow: "none",
        background: `${BACKGROUND_SECOND_GREY}`,
      }}
      defaultExpanded={false}
    >
      <WrapperTypeObjectTitle>
        <div className="icon-title">
          {showEvents.state ? (
            <VisibilityIcon
              sx={{
                color: "var(--background-primary_color)",
                margin: "10px 10px 10px 15px",
                cursor: "pointer",
              }}
              onClick={() =>
                handlerClickFilterEvents(false, `${VISIBILITY_OFF_ICON}`)
              }
            />
          ) : (
            <VisibilityOffIcon
              sx={{
                color: showEvents.color,
                margin: "10px 15px",
                cursor: "pointer",
              }}
              onClick={() => handlerClickFilterEvents(true, `${PRIMARY_COLOR}`)}
            />
          )}
          <p>Eventos</p>
        </div>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="color1" />}
        ></AccordionSummary>
      </WrapperTypeObjectTitle>
      {currentLibrarys.length !== 0 &&
        currentLibrarysSort.map((elm) => {
          return (
            <AccordionDetails
              sx={{
                padding: "1px 1px 1px",
                marginLeft: "10px",
              }}
            >
              <Accordion
                className="background-2"
                sx={{
                  paddingLeft: "5px",
                  border: "none",
                  boxShadow: "none",
                  background: `${BACKGROUND_SECOND_GREY}`,
                }}
                defaultExpanded={false}
              >
                <WrapperTypeObjectTitle>
                  <div className="icon-title">
                    {elm.state ? (
                      <VisibilityIcon
                        sx={{
                          color: "var(--background-primary_color)",
                          margin: "10px 10px 10px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handlerClickFilterComponentsByLibrary(
                            false,
                            elm.libraryId,
                            `${VISIBILITY_OFF_ICON}`
                          )
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{
                          color: elm.color,
                          margin: "10px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handlerClickFilterComponentsByLibrary(
                            true,
                            elm.libraryId,
                            `${PRIMARY_COLOR}`
                          )
                        }
                      />
                    )}
                    <p>{elm.name}</p>
                  </div>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="color1" />}
                  ></AccordionSummary>
                </WrapperTypeObjectTitle>
                <AccordionDetails>
                  {showAllEventsByTypeSort.map((elm2) => {
                    return (
                      <WrapperTypeObjectTitle>
                        {elm.libraryId === elm2.libraryId ? (
                          <>
                            <div className="icon-title">
                              {elm2.state ? (
                                <VisibilityIcon
                                  sx={{
                                    color: "var(--background-primary_color)",
                                    margin: "10px 10px 10px 15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlerClickFilterComponentsByType(
                                      elm2.id,
                                      elm2.libraryId,
                                      false,
                                      `${VISIBILITY_OFF_ICON}`
                                    )
                                  }
                                />
                              ) : (
                                <VisibilityOffIcon
                                  sx={{
                                    color: `${VISIBILITY_OFF_ICON}`,
                                    margin: "10px 15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlerClickFilterComponentsByType(
                                      elm2.id,
                                      elm2.libraryId,
                                      true,
                                      `${PRIMARY_COLOR}`
                                    )
                                  }
                                />
                              )}
                              <p>{elm2.name}</p>
                            </div>
                            <div>
                              <img
                                src={elm2.icon}
                                width="30px"
                                height="30px"
                                alt="ico"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </WrapperTypeObjectTitle>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          );
        })}
    </Accordion>
  );
};

export default FilterEvents;
