import useSwr from "swr";
import { config } from "config.js";

const FetchComponents = (libraryId) => {
  let url = `${config.URL_BACKEND_PG}api/v1/point-type-components`;
  if (libraryId) {
    url = `${url}?libraryId=${libraryId}`;
  }
  const { data, error } = useSwr(url);

  return { data, error };
};

export default FetchComponents;
