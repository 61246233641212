import { create } from "zustand";

const useStoreDashboard = create((set) => ({
  reloadCharts: false,
  useDragAndDroll: false,
  newCreateChart: false,
  dashboardViewName: "",
  dashboardRef: null,
  chartsIconsShowOnOff: true,
  setNewCreateChart: () =>
    set((state) => ({ newCreateChart: !state.newCreateChart })),
  setReloadCharts: () =>
    set((state) => ({ reloadCharts: !state.reloadCharts })),
  setDragAndDroll: (payload) => set(() => ({ useDragAndDroll: payload })),
  setDashboardViewName: (payload) =>
    set(() => ({ dashboardViewName: payload })),
  setDashboardRef: (payload) => set(() => ({ dashboardRef: payload })),
  setChartsIconsShowOnOff: (payload) =>
    set(() => ({ chartsIconsShowOnOff: payload })),
}));

export default useStoreDashboard;
