import { Source, Layer } from "react-map-gl";

/**
 * Renders a component that displays lines joined on a map.
 *
 * @param {string} id - The ID of the component.
 * @param {Array} features - An array of features to display on the map.
 * @return {JSX.Element} A JSX element representing the lines joined component.
 */
const ShowLinesJoin = ({ id, features }) => {
  return (
    <Source
      id={id ? `source-${id}` : "source-id"}
      type="geojson"
      data={{ type: "FeatureCollection", features: features }}
    >
      <Layer
        id={id ? `layer-${id}` : "lines-id"}
        type="line"
        paint={{
          "line-color": ["get", "color"],
          "line-width": 3,
          "line-dasharray": [3, 1],
        }}
      />
    </Source>
  );
};

export default ShowLinesJoin;
