import Gauge from "./Gauge";
import CloseIcon from "@mui/icons-material/Close";
import { GREY_TRANS } from "utils/const";
import {
  CardContainer,
  CardKpiGauge,
  CardKpi,
  CountGoal,
  CountValue,
  Description,
  DeleteButton,
  ResizeRight,
  ResizeBottom,
  RestoreButton,
} from "./indexStyle";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { updateOrderCard } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { config } from "config";
import handleAddGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridColumn";
import handleRemoveGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridColumn";
import handleRemoveGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridRow";
import handleAddGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridRow";
import { useEffect, useState } from "react";

export const fixedNumber = (number, NoDecimals) => {
  if (number)
    return Number.isInteger(number) ? number : number.toFixed(NoDecimals);
  return null;
};

const KpiChart = ({
  allDataKpiCharts = [],
  dataKpi,
  borderColor,
  currentStyle,
  template,
  setCurrentOrderViews,
  index,
  setCurrentTemplate,
  responsiveWidth = null,
  responsiveHeight = null,
  currentPositionId,
  restore = false,
  orderId,
  currentOrderName,
  currentOrderViews,
  setOpenDialogRestoreChart,
  handlerDeleteChartOnView,
  setDeleteChartDialog,
}) => {
  const role = localStorage.getItem("role");
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
  const { mutate } = useSWRConfig();
  const { chartStyle } = dataKpi;
  const { setDragAndDroll } = useStoreDashboard();
  const [selectDynamicGoalValue, setSelectDynamicGoalValue] = useState(null);
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  useEffect(() => {
    const currentGoal = allDataKpiCharts.find((elm) => {
      return elm._id === dataKpi.dynamicGoalValueId;
    });

    if (currentGoal) {
      setSelectDynamicGoalValue(
        fixedNumber(currentGoal.count, currentGoal.chartStyle.NoDecimals)
      );
    } else {
      setSelectDynamicGoalValue(
        fixedNumber(dataKpi.goal, dataKpi.chartStyle.NoDecimals)
      );
    }
  }, [allDataKpiCharts]);

  const handleRestoreChart = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: [
          ...currentOrderViews,
          {
            id: dataKpi._id,
            sizeChart: template.sizeChart,
          },
        ],
        _id: currentPositionId,
      },
      type: dataKpi.from,
    });
    setCurrentOrderViews((current) => [
      ...current,
      {
        id: dataKpi._id,
        sizeChart: template.sizeChart,
      },
    ]);
    mutate(urlOrders);
    setOpenDialogRestoreChart(false);
  };

  return (
    <CardContainer
      key={dataKpi._id}
      width={
        template?.index
          ? currentStyle.width
          : responsiveWidth
          ? `${currentStyle.width - responsiveWidth}px`
          : `${currentStyle.width}px`
      }
      height={
        template?.index
          ? currentStyle.height
          : responsiveHeight
          ? `${currentStyle.height - responsiveHeight}px`
          : `${currentStyle.height}px`
      }
    >
      {role === "Administrador de compañía" &&
        !restore &&
        chartsIconsShowOnOff && (
          <DeleteButton
            right={3}
            backgroundColor={chartStyle?.backgroundColor}
            onClick={() =>
              currentOrderName === "Design New View"
                ? setDeleteChartDialog(true)
                : handlerDeleteChartOnView()
            }
          >
            {" "}
            <CloseIcon
              sx={{
                color: chartStyle?.secondFontColor || GREY_TRANS,
                fontSize: "22px",
              }}
            />
          </DeleteButton>
        )}

      {role === "Administrador de compañía" &&
        restore &&
        chartsIconsShowOnOff && (
          <RestoreButton
            className="restore-card-button"
            onClick={() => handleRestoreChart()}
          >
            <RestartAltIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </RestoreButton>
        )}

      {template && (
        <>
          <ResizeRight>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleAddGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleRemoveGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeRight>
          <ResizeBottom>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleRemoveGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleAddGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeBottom>
        </>
      )}

      {selectDynamicGoalValue ? (
        <CardKpiGauge
          className="chart-dashboard-element-array"
          borderColor={borderColor}
          backgroundColor={chartStyle?.backgroundColor}
        >
          <Gauge
            value={fixedNumber(
              dataKpi.count / selectDynamicGoalValue,
              chartStyle.NoDecimals
            )}
            color={chartStyle.gaugeColor}
            background={chartStyle?.backgroundColor}
            textColor={chartStyle?.secondFontColor}
            gaugeSize={chartStyle?.gaugeSize}
          />
          <div>
            <CountGoal
              fontSize={chartStyle?.mainFontSize}
              color={chartStyle.mainFontColor}
            >
              {fixedNumber(dataKpi.count, chartStyle.NoDecimals)}/
              {selectDynamicGoalValue}
            </CountGoal>
            <Description
              fontSize={chartStyle?.secondFontSize}
              color={chartStyle?.secondFontColor}
            >
              {dataKpi.alias}
            </Description>
          </div>
        </CardKpiGauge>
      ) : (
        <CardKpi
          className="chart-dashboard-element-array"
          borderColor={borderColor}
          backgroundColor={chartStyle?.backgroundColor}
        >
          <CountValue
            fontSize={chartStyle?.mainFontSize}
            color={chartStyle?.mainFontColor}
          >
            {fixedNumber(dataKpi.count, chartStyle.NoDecimals)}
          </CountValue>
          <Description
            fontSize={chartStyle?.secondFontSize}
            color={chartStyle?.secondFontColor}
          >
            {dataKpi.alias}
          </Description>
        </CardKpi>
      )}
    </CardContainer>
  );
};

export default KpiChart;
