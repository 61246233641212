import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { FiLogIn } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { BoxNav } from "../DigitalTwin/UserRoutesElements";
import {
  LogoBar,
  SideBarItems,
  VersionContent,
  iconStyle,
} from "./navDrawerData";
import { useContext, useEffect, useState } from "react";
import { BLACK_COLOR, BORDER_GREY, WHITE_COLOR } from "utils/const";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material";
import NeuronaIconD from "images/NeuronaIconD";
import useUserRole from "hooks/useUserRole";
import { ThemeContext } from "App";
import { useTheme } from "pages/PlatformSetUp/Preferences/ThemeContext";

const drawerWidth = 390;

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "2rem",
  "&:hover": {
    backgroundColor: "var(--background-trans_color)",
  },
}));

const Item = (props) => {
  const { theme } = useContext(ThemeContext);

  const { text, icon, styleListIcon, url, click } = props;
  const navigate = useNavigate();

  const navigation = () => {
    if (click) {
      click();
      return;
    }

    if (url && url.includes("https")) {
      window.open(url);
      return;
    }

    url && navigate(url);
  };

  return (
    <ListItem key={text} disablePadding>
      <StyledListItemButton onClick={(event) => navigation()}>
        <ListItemIcon sx={styleListIcon}>
          <>
            {icon}
            <ReactTooltip
              id={text}
              place="right"
              effect="solid"
              offset="{'left': -15}"
            >
              {text}
            </ReactTooltip>
          </>
        </ListItemIcon>
        <ListItemText
          primary={text}
          sx={{
            "& .css-1710b0l-MuiTypography-root": {
              fontFamily: document.body.style.fontFamily,
              color:
                theme === "light"
                  ? "var(--background-primary_color)"
                  : WHITE_COLOR,
            },
          }}
        />
      </StyledListItemButton>
    </ListItem>
  );
};

export default function PersistentDrawerLeft() {
  const { colorName } = useTheme();
  const { theme } = useContext(ThemeContext);
  const styleListIcon = {
    justifyContent: "center",
  };
  const role = localStorage.getItem("role");

  const [sidebarItems, setSidebarItems] = useState({});

  const { userRole } = useUserRole();

  useEffect(() => {
    if (userRole) {
      const items = SideBarItems[userRole] || {};
      setSidebarItems(items);
    }
  }, [userRole]);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.title = colorName;
  }, [colorName]);

  return (
    <BoxNav>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="end"
        sx={{ mr: 1, ...(open && {}) }}
      >
        <MenuIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton>
      <div>
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          position="fixed"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <VersionContent
            className={
              theme === "light" ? "whiteColo1" : "background-default-color"
            }
          >
            <h1
              style={{
                color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
              }}
            >
              Decimetrix<sup>®</sup>
            </h1>
            <h2
              style={{
                color: "var(--background-secondary_color)",
              }}
            >
              {colorName}
            </h2>
            {/* <p>Version xx</p> */}
            <LogoBar>
              <div>
                <NeuronaIconD width={220} height={220} />
              </div>
            </LogoBar>
          </VersionContent>

          <Divider style={{ backgroundColor: BORDER_GREY }} />

          <List
            style={{
              height: "100%",
            }}
            className={
              theme === "light" ? "whiteColo1" : "background-default-color"
            }
          >
            {/* Sign In only when no exis role */}
            {role === null && (
              <Item
                text={"Log In"}
                icon={
                  <FiLogIn
                    style={theme === "light" ? iconStyle : iconStyle}
                    data-tip
                  />
                }
                styleListIcon={styleListIcon}
                url={"/signIn"}
              />
            )}
            {/* // Home to any role */}
            <Item
              text={"Home"}
              icon={<HomeOutlinedIcon style={iconStyle} data-tip />}
              styleListIcon={styleListIcon}
              url={role ? "/hub" : "/"}
            />
            {/* Render items of sidebar depending on your role */}
            {Object.keys(sidebarItems).map((item, index) => {
              const { icon, url } = sidebarItems[item];
              return (
                <Item
                  text={item}
                  icon={icon}
                  styleListIcon={styleListIcon}
                  url={url}
                  key={index}
                />
              );
            })}
          </List>

          {/* About Us Item */}
          <List
            className={
              theme === "light" ? "whiteColo1" : "background-default-color"
            }
            style={{}}
          >
            <Item
              text={"About Us"}
              icon={<InfoOutlinedIcon style={iconStyle} data-tip />}
              styleListIcon={styleListIcon}
              url="https://www.linkedin.com/company/decimetrix/"
            />
          </List>
        </Drawer>
      </div>
    </BoxNav>
  );
}
