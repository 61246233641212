// Required modules
import NavBar from "components/Admin/NavBar/NavBar";
import GenericTable from "components/Tables/GenericTable/GenericTable.jsx";
import Form from "components/Forms/Form.jsx";
import MessagesDialog from "components/Dialogs/MessagesDialog.jsx";
// Config admin class
import ConfigAdmin from "../../ConfigAdmin.js";

// Hooks
import { useEffect, useState, useCallback } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableTypeLine,
  keyValuesTableTypeLine,
  initialValuesFormTypeLine,
} from "../../dataConfigAdmin.jsx";

// Styles
import {
  ConfigAdminContainer,
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  InformationShare,
  IconAdminConfigChangeColum,
  SelectContainerAdminConfig,
} from "../../configAdminStyles.jsx";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { ContainerForm } from "components/Forms/FormStyles";
import useGet from "hooks/useFetch.js";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import CheckListsObjectsElements from "./CheckListObjectElements.jsx";

import UpdateTypeLinesTypeObjects from "./UpdateTypeElementsTypeObjects.jsx";
import Fields from "pages/ConfigAdmin/typeElemts/Fields.jsx";
import { TYPE_FIELDS } from "utils/constStrings";
import findTypeLinesTypeObjects from "services/typeLinesTypeObjects/find.js";
import createTypeLinesTypeObjects from "services/typeLinesTypeObjects/create";
import ShowLinePreview from "helpers/showLinePreview.jsx";
// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <IoIosArrowDropupCircle className="icon" />
    </IconAdminConfigChangeColum>
  );
};

export default function TypeLine() {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [libraries] = useGet("api/v1/line-libraries");
  const [library, setLibrary] = useState({});
  //Alerts
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
  });

  const [typeLinesTypeObjects, setTypeLinesTypeObjects] = useState({});
  const [typeLineId, setTypeLineId] = useState(null);

  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormTypeLine);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [typeLineRow, setTypeLineRow] = useState([]);

  // Change in library select
  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: libraries.find((library) => library.id === parseInt(e.target.value))
        ?.name,
    }));
  };

  // Click  on type line into table
  const handleClickTypeLine = (id) => {
    if (id !== typeLineId) setTypeLinesTypeObjects({});
    setTypeLineId(id);
    configAdmin.handlerClickIntoTable(id);
  };

  const create = useCallback(
    async (typeLine) => {
      if (typeLinesTypeObjects) {
        await Promise.all(
          Object.keys(typeLinesTypeObjects).map(async (typeElementId) => {
            await createTypeLinesTypeObjects({
              typeLineId: typeLine.id,
              typeElementId: parseInt(typeElementId),
            });
          })
        );
      }
      setTypeLinesTypeObjects({});
      setTypeLineId(null);
    },
    [typeLinesTypeObjects]
  );

  const update = useCallback(
    async (typeLine) => {
      const updateObjectsObjects = UpdateTypeLinesTypeObjects({
        typeLine,
        typeLinesTypeObjects,
        setTypeLinesTypeObjects,
      });
      await updateObjectsObjects();
      setTypeLinesTypeObjects({});
      setTypeLineId(null);
    },
    [typeLinesTypeObjects, setTypeLinesTypeObjects]
  );

  const handleChangeCheck = (id) => {
    const element = typeLinesTypeObjects?.[id];
    if (element) {
      setTypeLinesTypeObjects((current) => {
        const { [id]: value, ...rest } = current;
        return rest;
      });
    } else {
      setTypeLinesTypeObjects((current) => ({
        ...current,
        [id]: true,
      }));
    }
  };

  useEffect(() => {
    if (!typeLineId) return;
    const getData = async () => {
      const data = await findTypeLinesTypeObjects({
        typeLineId,
      });
      data.forEach(({ typeElementId }) => {
        setTypeLinesTypeObjects((current) => ({
          ...current,
          [typeElementId]: true,
        }));
      });
    };
    getData();
  }, [typeLineId]);

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableTypeLine);
    configAdmin.setKeyValuesTable(keyValuesTableTypeLine);
    configAdmin.setSetRows(setTypeLineRow);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/type-lines`);
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, []);

  // Set callback update
  useEffect(() => {
    configAdmin && configAdmin?.setUpdateCallback(update);
  }, [configAdmin, update]);

  // Set callback create
  useEffect(() => {
    configAdmin && configAdmin?.setCreateCallback(create);
  }, [configAdmin, create]);

  // Set library default
  useEffect(() => {
    if (libraries) {
      setLibrary(() => libraries[0]);
    }
  }, [libraries]);

  // Set library in config admin
  useEffect(() => {
    if (library?.id?.length > 0 || library?.id) {
      configAdmin?.setEndpointFetch(
        `api/v1/type-lines/library/${parseInt(library?.id)}`
      );
    }
    configAdmin?.setLibraryId(library?.id);
    configAdmin?.fetchData();
  }, [configAdmin, library?.id, library]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin, library?.fields]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  //alerts
  useEffect(() => {
    const alert = configAdmin?.getAlertStatus();
    if (alert?.open) {
      setOpenAlert({
        open: alert.open,
        severity: alert.severity,
      });
    }
  }, [configAdmin?.getAlertStatus]);

  return (
    <>
      <div>
        {/* <NavBar linkLogo={"admin"} /> */}
        <OpenDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          execute={executeDialog}
          content={contentDialog}
        />
        <ConfigAdminContainer className="background-2 border-top-1">
          <RowAdminConfig>
            <SelectContainerAdminConfig
              className="color1"
              style={{ gridColumn: "1" }}
            >
              Existing Digital Twin Line(s)
              <select name="endPoint" id="endPoint" onChange={handleLibrary}>
                {libraries?.map((library) => (
                  <option key={library.id} value={library.id}>
                    {library.name}
                  </option>
                ))}
              </select>
            </SelectContainerAdminConfig>
            <ColumnAdminConfig style={{ gridColumn: "1" }} large={false}>
              {configAdmin && (
                <GenericTable
                  headers={configAdmin.getHeadersTable()}
                  rows={typeLineRow}
                  keyValues={configAdmin.getKeyValuesTable()}
                  handlerClick={handleClickTypeLine}
                  style={{
                    width: 50,
                  }}
                  state={true}
                  configBar={true}
                  from={"config"}
                />
              )}
            </ColumnAdminConfig>
            <ColumnAdminConfig style={{ gridColumn: "2" }}>
              <ContainerForm
                see={seeForm}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Form
                  form={form}
                  handleChange={handleChangeForm}
                  handleRows={handleClickTypeLine}
                  buttons={buttonsForm}
                  renderBeforeTitle={[
                    <ButtonSeeInitalView
                      click={() => configAdmin.toggleForm()}
                    />,
                  ]}
                  renderBeforeButtons={[
                    <ShowLinePreview
                      color={form?.color?.value}
                      width={form?.dasharrayWidth?.value}
                      separator={form?.dasharrayPixels?.value}
                    />,
                    <Fields
                      typeFields={TYPE_FIELDS.typeToPoints}
                      form={form}
                      handleChange={handleChangeForm}
                    />,
                    <CheckListsObjectsElements
                      typeLinesTypeObjects={typeLinesTypeObjects}
                      handleChangeCheck={handleChangeCheck}
                    />,
                  ]}
                  title={"Create a new Digital Twin Line"}
                />
              </ContainerForm>

              {!seeForm && (
                <>
                  <CreateNewRegionButton className="background-row-2 color1 border-color-1">
                    Create a new Digital Twin Line
                    <button onClick={() => configAdmin.toggleForm()}>
                      <span>
                        <IoMdAdd />
                      </span>
                      New Type Line {library?.name}
                    </button>
                  </CreateNewRegionButton>
                  <InformationShare className="background-row-2 border-color-1">
                    <div className="body color1">
                      To Edit Click on Existing Digital Twin Line ID
                    </div>
                  </InformationShare>
                </>
              )}
            </ColumnAdminConfig>
          </RowAdminConfig>
        </ConfigAdminContainer>
        {openAlert.open && (
          <MessagesDialog
            open={openAlert.open}
            severity={openAlert?.severity}
            message={configAdmin?.messageShow}
            handleClose={() => setOpenAlert(false)}
          />
        )}
      </div>
    </>
  );
}
