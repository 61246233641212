import { useState, useEffect, useCallback } from "react";

// Helpers

import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { config } from "config";
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/objectsGroupBarChart";

export const useGroupBarChart = ({ openDialog, objects, loading }) => {
  const [charts, setCharts] = useState(null);
  const [groupBarCharts, setGroupBarChart] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlBarChart = `${config.URL_BACKEND_MG}bar-chart-group?adminCompanyId=${adminCompanyId}&from=objects`;
  const { data: dataBarChart, error: errorDataBarChart } = useSwr(urlBarChart);

  useEffect(() => {
    if (!errorDataBarChart && dataBarChart) setCharts(dataBarChart);
  }, [dataBarChart, errorDataBarChart, openDialog, reloadCharts]);

  useEffect(() => {
    const objectsFormat = createRowsObjects({ objects });
    setObjectsFormat(objectsFormat);
  }, [objects]);

  const getDataGroupBarChart = useCallback(async () => {
    if (!loading && objectsFormat) {
      const barCountsvalues = countByValues({
        charts,
        objects: objectsFormat,
      });

      const barSumValues = sumValues({
        charts,
        objects: objectsFormat,
      });

      setGroupBarChart([...barCountsvalues, ...barSumValues]);
    }
  }, [loading, objectsFormat, charts]);

  useEffect(() => {
    if (charts && objects) {
      getDataGroupBarChart();
    }
  }, [charts, objects, getDataGroupBarChart]);

  return { groupBarCharts };
};
