import { updateChart } from "services/Dashboard/barChart";
import { useSWRConfig } from "swr";
import { config } from "config";
import { useEffect, useState } from "react";
import { PRIMARY_COLOR } from "utils/const";
import { getGradient } from "helpers/getGradient";

export const useSetCurrentStyle = ({ dataChart, setChartEditDialog }) => {
  const { mutate } = useSWRConfig();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlKpi = `${
    config.URL_BACKEND_MG
  }bar-chart?adminCompanyId=${adminCompanyId}&from=${
    dataChart.fromType ? dataChart.fromType : dataChart.from
  }`;
  const [currentStyleBarDataChart, setCurrentStyleBarDataChart] = useState({
    data: {
      labels: dataChart.labels,
      datasets: dataChart.datasets,
    },
    currentBarChartColor: "",
    borderWidthBar: 2,
    currentNameBarChart: "",
    currentTitleColor: "",
    currentTotalFontColor: "#EEEEEE",
    currentTotalBackground: "#EEEEEE",
    currentAxisXColor: "#EEEEEE",
    currentAxisYColor: "#EEEEEE",
    currentHorizontalBar: false,
    currentLabelsXColor: "",
    currentLabelsYColor: "",
    currentTitleXColor: "#EEEEEE",
    currentTitleYColor: "#EEEEEE",
    currentTotalPosition: "end",
    currentTotalFontSize: 15,
    currentTitleFontSize: 25,
    currentTitleTooltipFontSize: 15,
    currentBodyTooltipFontSize: 13,
    currentLabelsXFontSize: 15,
    currentLabelsYFontSize: 15,
    currentTitleXFontSize: 15,
    currentTitleYFontSize: 15,
    currentTitleAxisX: "",
    currentDisplayAxisX: false,
    currentTitleAxisY: "",
    currentDisplayAxisY: false,
    currentDisplayTotal: false,
    currentDisplayBackgroundTotal: false,
    currentDisplayTitleX: false,
    currentDisplayTitleY: false,
    currentStatusBeginAtZero: false,
    currentMinValueAxis: 0,
    currentMaxValueAxis: null,
    currentStepSizeY: 5,
    currentPaddingAxisY: 5,
    currentPaddingAxisX: 5,
    currentTotalNumDecimals: null,
    currentDisplayShowCerosTotal: true,
  });

  const [constantLine, setConstantLine] = useState({
    create: false,
    label: "",
    borderColor: PRIMARY_COLOR,
    borderColorHexadecimal: PRIMARY_COLOR,
    constantValue: 0,
    currentTotalNumDecimals: null,
  });

  useEffect(() => {
    setCurrentStyleBarDataChart((current) => ({
      ...current,
      data: {
        labels: dataChart.labels,
        datasets: dataChart.datasets
          .map((elm) => {
            let updatedElm = { ...elm };

            if (!updatedElm.hasOwnProperty("colorsByLabel")) {
              updatedElm = {
                ...updatedElm,
                data: elm.data,
                colorsByLabel: dataChart.labels.map(
                  (label) => (label = elm.backgroundColor)
                ),
                colorsHexadecimalByLabel: dataChart.labels.map(
                  (label) => (label = elm.backgroundColorHexadecimal)
                ),
              };
            }
            if (elm.dataGradient.display) {
              updatedElm = {
                ...updatedElm,
                backgroundColor: function (context) {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  if (!chartArea) {
                    return;
                  }
                  return getGradient({
                    ctx,
                    chartArea,
                    color: elm.backgroundColorHexadecimal,
                    colorStop: elm.dataGradient.colorStop,
                  });
                },
                colorsByLabelGradient: function (context) {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  if (!chartArea) {
                    return;
                  }

                  return updatedElm.colorsHexadecimalByLabel.map((item) => {
                    return getGradient({
                      ctx,
                      chartArea,
                      color: item,
                      colorStop: elm.dataGradient.colorStop,
                    });
                  });
                },
              };
            }
            return updatedElm;
          })
          .concat(dataChart.dataSeriesConstant),
      },
      currentNameBarChart: dataChart.alias,
      currentHorizontalBar: dataChart.horizontal,
      currentTitleColor: dataChart.chartStyle.colorTitle,
      currentTotalFontColor: dataChart.chartStyle.datalabels.colorTotal,
      currentTotalBackground:
        dataChart.chartStyle.datalabels.backgroundColorTotal,
      currentAxisXColor: dataChart.chartStyle.scales.x.axisColor,
      currentAxisYColor: dataChart.chartStyle.scales.y.axisColor,
      currentLabelsXColor: dataChart.chartStyle.scales.x.labelsColor,
      currentLabelsYColor: dataChart.chartStyle.scales.y.labelsColor,
      currentTitleXColor: dataChart.chartStyle.scales.x.titleAxis.colorTitle,
      currentTitleYColor: dataChart.chartStyle.scales.y.titleAxis.colorTitle,
      currentTotalPosition: dataChart.chartStyle.datalabels.positionTotal,
      currentTotalFontSize: dataChart.chartStyle.datalabels.fontSizeTotal,
      currentTitleFontSize: dataChart.chartStyle.fontSizeTitle,
      currentTitleTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeTitle,
      currentBodyTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeBody,
      currentLabelsXFontSize: dataChart.chartStyle.scales.x.labelsFontSize,
      currentLabelsYFontSize: dataChart.chartStyle.scales.y.labelsFontSize,
      currentTitleXFontSize:
        dataChart.chartStyle.scales.x.titleAxis.fontSizeTitle,
      currentTitleYFontSize:
        dataChart.chartStyle.scales.y.titleAxis.fontSizeTitle,
      currentTitleAxisX: dataChart.chartStyle.scales.x.titleAxis.text
        ? dataChart.chartStyle.scales.x.titleAxis.text
        : dataChart.columnX.name,
      currentDisplayAxisX: dataChart.chartStyle.scales.x.displayAxis,
      currentTitleAxisY: dataChart.chartStyle.scales.y.titleAxis.text
        ? dataChart.chartStyle.scales.y.titleAxis.text
        : dataChart.type === "count"
        ? "Count"
        : dataChart.columnsY[0],
      currentDisplayAxisY: dataChart.chartStyle.scales.y.displayAxis,
      currentDisplayTotal: dataChart.chartStyle.datalabels.displayTotal,
      currentDisplayBackgroundTotal:
        dataChart.chartStyle.datalabels.displayBackgroundTotal,
      currentDisplayTitleX:
        dataChart.chartStyle.scales.x.titleAxis.displayTitle,
      currentDisplayTitleY:
        dataChart.chartStyle.scales.y.titleAxis.displayTitle,
      currentStatusBeginAtZero: dataChart.chartStyle.scales.y.beginAtZero,
      currentMinValueAxis: dataChart.chartStyle.scales.y.minValueAxis,
      currentMaxValueAxis: dataChart.chartStyle.scales.y.maxValueAxis,
      currentStepSizeY: dataChart.chartStyle.scales.y.stepSizeY,
      currentPaddingAxisY: dataChart.chartStyle.scales.y.paddingAxis,
      currentPaddingAxisX: dataChart.chartStyle.scales.x.paddingAxis,
      currentTotalNumDecimals: dataChart.chartStyle.datalabels.numDecimals,
      currentDisplayShowCerosTotal: dataChart.chartStyle.datalabels.showCeros,
    }));
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...dataChart,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        alias: currentStyleBarDataChart.currentNameBarChart,
        horizontal: currentStyleBarDataChart.currentHorizontalBar,
        barStyle: currentStyleBarDataChart.data.datasets.find(
          (elm) =>
            !elm.hasOwnProperty("constantValue") || elm.constantValue === null
        ),
        chartStyle: {
          ...dataChart.chartStyle,
          colorTitle: currentStyleBarDataChart.currentTitleColor,
          fontSizeTitle: currentStyleBarDataChart.currentTitleFontSize,
          datalabels: {
            ...dataChart.chartStyle.datalabels,
            displayTotal: currentStyleBarDataChart.currentDisplayTotal,
            positionTotal: currentStyleBarDataChart.currentTotalPosition,
            colorTotal: currentStyleBarDataChart.currentTotalFontColor,
            displayBackgroundTotal:
              currentStyleBarDataChart.currentDisplayBackgroundTotal,
            backgroundColorTotal:
              currentStyleBarDataChart.currentTotalBackground,
            fontSizeTotal: currentStyleBarDataChart.currentTotalFontSize,
            showCeros: currentStyleBarDataChart.currentDisplayShowCerosTotal,
            numDecimals: currentStyleBarDataChart.currentTotalNumDecimals,
          },
          scales: {
            x: {
              ...dataChart.chartStyle.scales.x,
              titleAxis: {
                displayTitle: currentStyleBarDataChart.currentDisplayTitleX,
                text: currentStyleBarDataChart.currentTitleAxisX,
                colorTitle: currentStyleBarDataChart.currentTitleXColor,
                fontSizeTitle: currentStyleBarDataChart.currentTitleXFontSize,
              },
              displayAxis: currentStyleBarDataChart.currentDisplayAxisX,
              axisColor: currentStyleBarDataChart.currentAxisXColor,
              labelsFontSize: currentStyleBarDataChart.currentLabelsXFontSize,
              labelsColor: currentStyleBarDataChart.currentLabelsXColor,
              paddingAxis: currentStyleBarDataChart.currentPaddingAxisX,
            },
            y: {
              ...dataChart.chartStyle.scales.y,
              titleAxis: {
                displayTitle: currentStyleBarDataChart.currentDisplayTitleY,
                text: currentStyleBarDataChart.currentTitleAxisY,
                colorTitle: currentStyleBarDataChart.currentTitleYColor,
                fontSizeTitle: currentStyleBarDataChart.currentTitleYFontSize,
              },
              displayAxis: currentStyleBarDataChart.currentDisplayAxisY,
              axisColor: currentStyleBarDataChart.currentAxisYColor,
              labelsFontSize: currentStyleBarDataChart.currentLabelsYFontSize,
              labelsColor: currentStyleBarDataChart.currentLabelsYColor,
              beginAtZero: currentStyleBarDataChart.currentStatusBeginAtZero,
              minValueAxis: currentStyleBarDataChart.currentMinValueAxis,
              maxValueAxis: currentStyleBarDataChart.currentMaxValueAxis,
              stepSizeY: currentStyleBarDataChart.currentStepSizeY,
              paddingAxis: currentStyleBarDataChart.currentPaddingAxisY,
            },
          },
          tooltip: {
            ...dataChart.chartStyle.tooltip,
            fontSizeTitle: currentStyleBarDataChart.currentTitleTooltipFontSize,
            fontSizeBody: currentStyleBarDataChart.currentBodyTooltipFontSize,
          },
        },
        dataSeriesConstant: currentStyleBarDataChart.data.datasets
          .filter((elm) => elm.type === "line")
          .map((elm) => {
            const { _id, ...rest } = elm;
            return rest;
          }),
      },
    });
    if (data) {
      mutate(urlKpi);
      setChartEditDialog(false);
    }
  };

  const handleCreateConstantLine = () => {
    const newGoalLine = {
      _id: constantLine.label,
      label: constantLine.label,
      alias: constantLine.label,
      type: "line",
      constantValue: constantLine.constantValue,
      data: new Array(
        currentStyleBarDataChart.data.datasets[0].data.length
      ).fill(constantLine.constantValue),
      borderColor: constantLine.borderColorHexadecimal,
      borderWidth: 2,
      borderDash: [5, 5],
      borderDashOffset: 1,
      borderCapStyle: "butt",
      borderJoinStyle: "miter",
      backgroundColorHexadecimal: constantLine.borderColorHexadecimal,
      backgroundColor: constantLine.borderColor,
      fill: false,
      pointBackgroundColor: constantLine.borderColorHexadecimal,
      pointBorderColor: "#0E4D45",
      pointBorderWidth: 0,
      pointRadius: 0,
      pointStyle: "circle",
      pointHitRadius: 0,
      pointHoverBackgroundColor: constantLine.borderColorHexadecimal,
      pointHoverBorderColor: constantLine.borderColorHexadecimal,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 0,
      cubicInterpolationMode: "default",
      tension: 0,
      stepped: false,
      spanGaps: false,
      showLine: true,
      hidden: false,
      datalabels: {
        displayTotal: false,
        positionTotal: "end",
        colorTotal: "#EEEEEE",
        displayBackgroundTotal: false,
        backgroundColorTotal: "rgba(255, 255, 255, 0)",
        fontSizeTotal: 10,
        offsetTotal: 3,
      },
      clip: true,
    };
    setCurrentStyleBarDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: [...current.data.datasets, newGoalLine],
      },
    }));
    setConstantLine({
      create: false,
      label: "",
      borderColor: PRIMARY_COLOR,
      borderColorHexadecimal: PRIMARY_COLOR,
      constantValue: 0,
    });
  };

  const handleDeleteConstantLine = ({ id }) => {
    setCurrentStyleBarDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: current.data.datasets.filter((elm) => elm._id !== id),
      },
    }));
  };

  return {
    currentStyleBarDataChart,
    setCurrentStyleBarDataChart,
    handleUpdateChart,
    handleCreateConstantLine,
    handleDeleteConstantLine,
    constantLine,
    setConstantLine,
  };
};
