import styled from "styled-components";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  OBJECT_CLUSTER_COLOR,
  ICON_GREEN_COLOR,
  RED_COLOR,
  WHITE_COLOR,
  PRIMARY_COLOR,
  WHITE_SECOND_COLOR,
  BLACK_COLOR,
} from "utils/const";
import neuronDecimetrix from "images/neuron_mag.png";

export const NamePlateMarker = styled.button`
  position: relative;
  border: none;
  background: none;
  cursor: ${(props) => (props.drawInMap ? "none" : "pointer")};

  img {
    width: 40px;
    filter: ${(props) => (props.filter ? "none" : "grayscale(100%)")};
    z-index: 2;
  }

  img:hover {
    opacity: ${(props) => (props.drawInMap ? "0" : "1")};
  }

  .selected {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.flyTo ? "block" : "none")};
  }

  .qaqc {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${ICON_GREEN_COLOR};
    border-radius: 50%;
    z-index: -1;
    display: ${(props) => (props.qaqc ? "block" : "none")};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      opacity: 0.6;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 0.6rem;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: white;
  }
`;

export const ButtonFilter = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 19.3rem;

  right: 10px;
  z-index: 10;
  background-color: ${WHITE_SECOND_COLOR};
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 5px;
`;

export const NueronTools = styled("div")`
  position: absolute;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  z-index: 100;
  width: 50px;
  height: 50px;
  background-image: url(${neuronDecimetrix});
  background-size: cover;
  display: ${(props) => (props.display ? "block" : "none")};
`;

export const ButtonInformation = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 399px;
  right: 9px;
  z-index: 8;
  background-color: ${WHITE_SECOND_COLOR};
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px 4px;

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;

export const ButtonExtend = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 440px;
  right: 9px;
  background-color: ${WHITE_SECOND_COLOR};
  color: black;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px 4px;
  z-index: 8;

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;

export const ButtonCopyCoordinates = styled("button")`
  position: absolute;
  top: 88px;
  right: 44px;
  width: 33px;
  height: 33px;
  z-index: 2;
  background: ${WHITE_COLOR};
  background-clip: padding-box;
  display: inline-block;
  cursor: pointer;
  padding: 7px 5px 6px 6px;
  border-radius: 5px;
  border: solid 2px;
  border-color: #00000021;

  p {
    position: absolute;
    left: 2rem;
    top: -0.5rem;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 50%;
    font-size: 10px;
    background-color: ${RED_COLOR};
    color: ${WHITE_COLOR};
    z-index: 999;
  }

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }

  &:hover {
    background-color: #fcf7f7;
  }
`;

export const ButtonMovePoint = styled("button")`
  position: absolute;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  width: 33px;
  height: 33px;
  z-index: 3;
  background: ${WHITE_COLOR};
  background-clip: padding-box;
  display: inline-block;
  cursor: pointer;
  padding: 7px 5px 6px 6px;
  border-radius: 5px;
  border: solid 2px;
  border-color: #00000021;

  p {
    position: absolute;
    left: 2rem;
    top: -0.5rem;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 50%;
    font-size: 10px;
    background-color: ${RED_COLOR};
    color: ${WHITE_COLOR};
    z-index: 999;
  }

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }

  &:hover {
    background-color: #fcf7f7;
  }
`;

export const ButtonSaveCoordinatesLine = styled("button")`
  position: absolute;
  top: 229px;
  right: 44px;
  width: 33px;
  height: 33px;
  z-index: 3;
  background: ${WHITE_COLOR};
  background-clip: padding-box;
  display: inline-block;
  cursor: pointer;
  padding: 7px 5px 6px 6px;
  border-radius: 5px;
  border: solid 2px;
  border-color: #00000021;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    position: absolute;
    left: 2rem;
    top: -0.5rem;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 50%;
    font-size: 10px;
    background-color: ${RED_COLOR};
    color: ${WHITE_COLOR};
    z-index: 999;
  }

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }

  &:hover {
    background-color: #fcf7f7;
  }
`;

export const CustomizeToggleButtonGroup = styled(ToggleButtonGroup)`
  @media (max-width: 480px) {
    padding: 1%;
    margin-right: 2%;
  }
`;

export const ButtonVisibilityOffTable = styled("button")`
  margin-left: 1px;
  margin-top: 5px;
  border: none;
  font-size: 2.3rem;
  background: transparent;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ButtonVisibilityOnTable = styled("button")`
  position: absolute;
  bottom: 1.5rem;
  right: 50%;
  z-index: 7;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px #28b2d06e;
  color: ${WHITE_COLOR};
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  animation: jumpAnimation 3s ease forwards;

  @keyframes jumpAnimation {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-10px);
    }
  }

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(7%, -30px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
      font-size: 10px;
      width: max-content;
    }
  }

  &:hover .tooltip {
    display: block;
  }

  &:hover {
    background-color: #fcf7f7;
  }
`;
