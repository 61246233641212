import * as turf from "@turf/turf";

/**
 * The function calculates the length of a line represented by the `coordinates` using the Turf.js library, and the length is calculated based on the specified `units`. If `units` is not provided, the default unit is set to "kilometers". The function then returns the calculated distance. If any errors occur during the calculation, it logs the error to the console and returns 0.0 as the distance.
 *
 * @function
 * @name calculareLineLength
 * @param {Object} coordinates - coordinates : [[lng,lat],[lng,lat], ...]
 * @param {Object} units - units: <"degrees", "radians", "miles", or "kilometers">
 * @returns {number} - distance or 0.0
 */
const calculareLineLength = ({ coordinates, units }) => {
  try {
    if (!coordinates || coordinates.length < 2) return 0.0;
    if (!units) units = "kilometers";
    const line = turf.lineString(coordinates);
    const distance = turf.length(line, units);
    return distance;
  } catch (error) {
    console.error("🚩calculateLineLength", error);
    return 0.0;
  }
};

export default calculareLineLength;
