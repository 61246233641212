import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import email from "lotties/email-sent.json";
import NavBar from "components/NavBar";
import Container from "@mui/material/Container";
import Loading from "components/Lodings/LoadingV2";
import { config } from "config.js";

import {
  Confirmation,
  TitleConfirmation,
  Pconfirmation,
  GoButton,
} from "./RecoveryElements";

const EmailConfirmation = () => {
  const [value, setValue] = useState(false);

  const { token, id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.patch(
          `${
            config.URL_BACKEND_PG
          }api/v1/user-verification/email-confirmation/${parseInt(id)}`,
          { confirmationToken: token }
        );
        if (response.status === 201) setValue(true);
        // if (response.status === 401) setValue(true);
      } catch (error) {
        setValue(true);
      }
    };
    fetchData();
  }, [id, token]);

  const emailLottie = {
    loop: true,
    autoplay: true,
    animationData: email,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };

  return (
    <>
      <NavBar configNavbar={configNavbar} />
      <Container
        style={{
          paddingTop: 150,
        }}
      >
        <div>
          {value === false ? (
            <div>
              <Loading />
            </div>
          ) : (
            <>
              <Confirmation>
                <Lottie options={emailLottie} height={350} width={350} />
                <TitleConfirmation>E-mail confirmed!</TitleConfirmation>
                <Pconfirmation>
                  Your account has been successfully registered. To complete the
                  process please log in and explore the Green Dragon.
                </Pconfirmation>
                <GoButton to="/signIn">Go to log in</GoButton>
              </Confirmation>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default EmailConfirmation;
