import hexToRGB from "./hexToRGB";
export const getGradient = ({ ctx, chartArea, color, colorStop }) => {
  const [top = 0.3, medTop = 0.5, medBottom = 0.7, bottom = 1] = colorStop;
  const { r, g, b } = hexToRGB(color);

  // Crear un nuevo gradiente en cada llamada
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.bottom,
    0,
    chartArea.top
  );
  gradient.addColorStop(top, `rgba(${r}, ${g}, ${b}, ${0.2})`);
  gradient.addColorStop(medTop, `rgba(${r}, ${g}, ${b}, ${0.3})`);
  gradient.addColorStop(medBottom, `rgba(${r}, ${g}, ${b}, ${0.5})`);
  gradient.addColorStop(bottom, `rgba(${r}, ${g}, ${b})`);

  return gradient;
};
