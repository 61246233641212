import axios from "axios";
import { config } from "config.js";

const findUserSessions = async ({ token, userId }) => {
  try {
    const res = await axios.get(
      `${config.URL_BACKEND_PG}api/v1/sessions?userId=${userId}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Error find sessions");
  }
};

export default findUserSessions;

export const findUserSessionByToken = async () => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${config.URL_BACKEND_PG}api/v1/sessions?token=${token}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return data[0];
  } catch (error) {
    console.error(error);
    throw new Error("Error finding user session by token");
  }
};

export const findRevoquedTokens = async ({ id, token, userId }) => {
  const urlSearchParams = new URLSearchParams();
  if (id) urlSearchParams.append("id", id);
  if (token) urlSearchParams.append("token", token);
  if (userId) urlSearchParams.append("userId", userId);
  const url = `${
    config.URL_BACKEND_PG
  }api/v1/revoqued-tokens?${urlSearchParams.toString()}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return data;
};
