import axios from "axios";
import { config } from "config.js";

const saveRegionApiMg = async (regionGeoJson) => {
  const token = localStorage.getItem("token");

  const res = await axios.post(
    `${config.URL_BACKEND_MG}region`,
    regionGeoJson,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default saveRegionApiMg;
