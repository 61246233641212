import { useEffect, useRef } from "react";
import { GaugeContainer } from "./GaugeStyle";

const Gauge = ({ value, color, background, textColor, gaugeSize }) => {
  const gaugeRef = useRef(null);

  useEffect(() => {
    const setGaugeValue = (gauge, value) => {
      if (value < 0) {
        return;
      }

      gauge.querySelector(".gauge__fill").style.transform = `rotate(${
        value > 1 ? 0.5 : value / 2
      }turn)`;
      gauge.querySelector(".gauge__cover").textContent = `${Math.round(
        value * 100
      )}%`;
    };

    setGaugeValue(gaugeRef.current, value);
  }, [value]);

  return (
    <GaugeContainer
      ref={gaugeRef}
      color={color}
      textColor={textColor}
      background={background}
      gaugeSize={gaugeSize}
    >
      <div className="gauge__body">
        <div className="gauge__fill"></div>
        <div className="gauge__cover"></div>
      </div>
    </GaugeContainer>
  );
};

export default Gauge;
