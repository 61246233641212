import styled from "styled-components";
import {
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
  ICON_GREEN_COLOR,
  BLACK_COLOR,
} from "utils/const";

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;

  width: 100%;
  text-align: center;

  .title {
    margin: 20px 0;
  }
  .title h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.6rem;
  }

  .table-container {
    /* margin: 5px 0; */
  }

  .kpis-section {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .kpis-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px 20px;
    border-radius: 7px;
    margin: 15px 0;
  }

  .kpis-container h3 {
    font-size: 2rem;
    font-weight: 500;
    margin: 0 5px;
  }

  .kpis-container h3 span {
    font-weight: 700;
  }

  .button-upload-videos {
    background-color: var(--background-primary_color);
    padding: 10px 10px;
    color: ${WHITE_COLOR};
    border-radius: 10px;
    font-size: 1.8rem;
    border: none;
    width: 200px;
    cursor: pointer;
  }
  .button-upload-videos:hover {
    background-color: var(--background-secondary_color);
  }
`;

export const ContainerSelectFields = styled.div`
  .select {
    display: flex;
    margin: 10px 0;
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-content: center;
  }

  .select p {
    width: 30%;
    align-self: center;
    font-size: 2rem;
  }

  .select .select-container {
    width: 70%;
  }

  .select-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin: 30px 0;
    width: 100%;
  }

  .select-fields .select-file-field {
    width: 100%;
  }

  .select-name-field .table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
  }

  .select-file-field .table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
  }

  .table .table-header {
    padding: 30px 0;
    background-color: var(--background-primary_color);
    height: 40px;
    width: auto;
    word-wrap: break-word;
  }

  .table-header th {
    color: ${WHITE_COLOR};
    font-weight: 500;
    font-size: 2rem;
    margin: 0;
    width: auto;
    word-wrap: break-word;
  }

  .table .table-row {
    padding: 20px 0;
    margin: 20px 0;
    height: 30px;
    text-align: center;
    width: auto;
    word-wrap: break-word;
  }

  .select-title {
    font-size: 2rem;
  }

  .select-description {
    font-size: 1.8rem;
  }

  .selected {
    background-color: var(--background-secondary_color);
  }

  .table-row:hover {
    background-color: var(--background-trans_color);
    cursor: pointer;
  }

  .table .table-column {
    padding: 0 10px;
    font-size: 1.8rem;
  }
`;
