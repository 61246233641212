import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Barsicons = () => {
  return (
    <>
      <svg
        id="Layer_10"
        data-name="Layer 10"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`
      .cls-1b {
        stroke: #8484ce;
      }

      .cls-1b, .cls-2b {
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }

      .cls-2b {
        stroke: #585872;
      }

      .cls-3b {
        fill: var(--background-secondary_color);
      }

      .cls-3b, .cls-4b {
        stroke-width: 0px;
      }

      .cls-4b {
        fill: var(--background-primary_color);
        
      }
      `}
          </style>
        </defs>
        <circle class="cls-4b" cx="65.14" cy="216.86" r="21.16" />
        <line class="cls-1b" x1="65.14" y1="238.02" x2="65.14" y2="258.15" />
        <line class="cls-1b" x1="80.17" y1="201.97" x2="130.58" y2="170.78" />
        <line class="cls-1b" x1="86.3" y1="216.86" x2="125.46" y2="216.86" />
        <line class="cls-1b" x1="80.17" y1="231.75" x2="130.58" y2="265.52" />
        <line class="cls-1b" x1="65.14" y1="195.7" x2="65.14" y2="178.15" />
        <circle class="cls-3b" cx="146.62" cy="279.31" r="21.16" />
        <circle class="cls-3b" cx="146.62" cy="156.99" r="21.16" />
        <circle class="cls-4b" cx="146.62" cy="216.86" r="21.16" />
        <circle class="cls-4b" cx="65.14" cy="156.99" r="21.16" />
        <circle class="cls-4b" cx="65.14" cy="279.31" r="21.16" />
        <line class="cls-2b" x1="206.11" y1="173.07" x2="400.17" y2="173.07" />
        <rect
          class="cls-3b"
          x="212.3"
          y="152.43"
          width="129.55"
          height="14.97"
          rx="7.48"
          ry="7.48"
        />
        <line class="cls-2b" x1="206.11" y1="235.04" x2="400.17" y2="235.04" />
        <rect
          class="cls-3b"
          x="212.3"
          y="214.4"
          width="129.55"
          height="14.97"
          rx="7.48"
          ry="7.48"
        />
        <line class="cls-2b" x1="206.11" y1="297.01" x2="400.17" y2="297.01" />
        <rect
          class="cls-3b"
          x="212.3"
          y="276.36"
          width="129.55"
          height="14.97"
          rx="7.48"
          ry="7.48"
        />
      </svg>
    </>
  );
};

export default Barsicons;
