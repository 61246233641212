import { updateChart } from "services/Dashboard/groupDoughnutChart";
import { useSWRConfig } from "swr";
import { config } from "config";
import { useEffect, useState } from "react";

export const useSetCurrentStyle = ({ dataChart, setChartEditDialog }) => {
  const { mutate } = useSWRConfig();
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlKpi = `${
    config.URL_BACKEND_MG
  }group-doughnut-chart?adminCompanyId=${adminCompanyId}&from=${
    dataChart.fromType ? dataChart.fromType : dataChart.from
  }`;
  const [currentStyleLineDataChart, setCurrentStyleLineDataChart] =
    useState(dataChart);

  useEffect(() => {
    setCurrentStyleLineDataChart({
      ...dataChart,
      datasets: dataChart.datasets.map((elm) => {
        let updatedElm = {
          ...elm.donutStyle,
          data: elm.data,
        };
        return updatedElm;
      }),
    });
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...currentStyleLineDataChart,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        datasets: currentStyleLineDataChart.datasets.map((elm) => {
          return {
            columnsValuesFilter: [],
            donutStyle: elm,
          };
        }),
      },
    });
    if (data) {
      mutate(urlKpi);
      setChartEditDialog(false);
    }
  };

  return {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
  };
};
