import { useEffect, useState } from "react";
import { SelectContainerAdminConfig } from "../configAdminStyles";
import { ConfigObjectsContainer } from "pages/Dashboard/Config/ConfigObjects/indexStyle";
import useSwr from "swr";
import { config } from "config";
import SelectObjectTypes from "pages/Dashboard/Config/ConfigObjects/SelectObjectTypes";
import CustomButton from "components/Buttons/CustomButton";
import SelectLibrary from "pages/Dashboard/components/SelectLibrary";
import SelectComponentTypes from "pages/Dashboard/Config/ConfigComponents/SelectComponentTypes";
import SelectEventTypes from "pages/Dashboard/Config/ConfigEvents/SelectEventTypes";
import { SelectLineObjectTypes } from "pages/Dashboard/Config/ConfigObjects/SelectLineObjectTypes";

export const DuplicateLibrary = ({
  adminCompanyId,
  adminsInfo,
  pointObject = false,
  lineObject = false,
  pointComponent,
  pointEvent,
  configAdmin,
  handleChangeForm,
  setDialog,
  setTypeElementsSelect,
}) => {
  const [currentAdminCompany, setCurrentAdminCompany] = useState(0);
  const [dataLibrary, setDataLibrary] = useState([]);
  const [allDataAdmin, setAllDataAdmin] = useState([]);

  const [librarySelected, setLibrarySelected] = useState(0);
  const [typeElements, setTypeElements] = useState([]);
  const [typeIdsSelected, setTypeIdsSelected] = useState([]);
  const [librarySelectedEvent, setLibrarySelectedEvent] = useState(null);

  const urlObjectLibraries =
    pointObject && currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/point-libraries?adminCompanyId=${currentAdminCompany}`
      : null;

  const urlTypeObjects =
    pointObject &&
    librarySelected !== 0 &&
    currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/type-elements?libraryId=${librarySelected.id}&adminCompanyId=${currentAdminCompany}`
      : null;

  const urlComponentLibraries =
    pointComponent && currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/point-library-components?adminCompanyId=${currentAdminCompany}`
      : null;

  const urlTypeComponents =
    pointComponent &&
    librarySelected !== 0 &&
    currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/point-type-components?libraryId=${librarySelected.id}&adminCompanyId=${currentAdminCompany}`
      : null;

  const urlEventLibraries =
    pointEvent && currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/point-library-events?adminCompanyId=${currentAdminCompany}`
      : null;

  const urlTypeEvents =
    pointEvent &&
    librarySelected !== 0 &&
    currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/point-type-events?libraryId=${librarySelected.id}&adminCompanyId=${currentAdminCompany}`
      : null;

  const urlLineObjectsLibraries =
    lineObject && currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/line-libraries?adminCompanyId=${currentAdminCompany}`
      : null;

  const urlTypeLineObjects =
    lineObject &&
    librarySelected !== 0 &&
    currentAdminCompany !== "defaultLibrary"
      ? `${config.URL_BACKEND_PG}api/v1/type-lines/library/${librarySelected.id}?adminCompanyId=${currentAdminCompany}`
      : null;

  // Points
  const {
    data: currentAdminCompanyObjectLibraries,
    error: currentAdminCompanyObjectLibrariesError,
  } = useSwr(urlObjectLibraries);

  const {
    data: currentAdminCompanyTypeElementsObject,
    error: currentAdminCompanyTypeElementsErrorObject,
  } = useSwr(urlTypeObjects);

  const {
    data: currentAdminCompanyComponentLibraries,
    error: currentAdminCompanyComponentLibrariesError,
  } = useSwr(urlComponentLibraries);

  const {
    data: currentAdminCompanyTypeElementsComponent,
    error: currentAdminCompanyObjectTypeElementsErrorComponent,
  } = useSwr(urlTypeComponents);

  const {
    data: currentAdminCompanyEventLibraries,
    error: currentAdminCompanyEventLibrariesError,
  } = useSwr(urlEventLibraries);

  const {
    data: currentAdminCompanyTypeElementsEvent,
    error: currentAdminCompanyObjectTypeElementsErrorEvent,
  } = useSwr(urlTypeEvents);

  // Lines
  const {
    data: currentAdminCompanyLineObjectLibraries,
    error: currentAdminCompanyLineObjectLibrariesError,
  } = useSwr(urlLineObjectsLibraries);

  const {
    data: currentAdminCompanyTypeLineObject,
    error: currentAdminCompanyTypeLineErrorObject,
  } = useSwr(urlTypeLineObjects);

  useEffect(() => {
    const currentInfoAdmin = adminsInfo.filter((elm) => {
      if (
        parseInt(elm.id) !== parseInt(adminCompanyId) &&
        elm.id !== "defaultLibrary"
      ) {
        return elm;
      }
    });
    setCurrentAdminCompany(currentInfoAdmin[0]?.id);
    setAllDataAdmin(currentInfoAdmin);
  }, [adminsInfo, adminCompanyId]);

  useEffect(() => {
    if (pointObject) {
      !currentAdminCompanyObjectLibrariesError &&
        currentAdminCompanyObjectLibraries?.length > 0 &&
        currentAdminCompanyObjectLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
    if (pointComponent) {
      !currentAdminCompanyComponentLibrariesError &&
        currentAdminCompanyComponentLibraries?.length > 0 &&
        currentAdminCompanyComponentLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
    if (pointEvent) {
      !currentAdminCompanyEventLibrariesError &&
        currentAdminCompanyEventLibraries?.length > 0 &&
        currentAdminCompanyEventLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
    if (lineObject) {
      !currentAdminCompanyLineObjectLibrariesError &&
        currentAdminCompanyLineObjectLibraries?.length > 0 &&
        currentAdminCompanyLineObjectLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
  }, [
    currentAdminCompanyObjectLibrariesError,
    currentAdminCompanyComponentLibrariesError,
    currentAdminCompanyEventLibrariesError,
    currentAdminCompanyLineObjectLibrariesError,
    currentAdminCompanyObjectLibraries,
    currentAdminCompanyComponentLibraries,
    currentAdminCompanyEventLibraries,
    currentAdminCompanyLineObjectLibraries,
    currentAdminCompany,
    pointObject,
    pointComponent,
    pointEvent,
  ]);

  useEffect(() => {
    if (pointObject) {
      !currentAdminCompanyTypeElementsErrorObject &&
        currentAdminCompanyTypeElementsObject?.length > 0 &&
        setTypeElements(currentAdminCompanyTypeElementsObject);
    }
    if (pointComponent) {
      !currentAdminCompanyObjectTypeElementsErrorComponent &&
        currentAdminCompanyTypeElementsComponent?.length > 0 &&
        setTypeElements(currentAdminCompanyTypeElementsComponent);
    }
    if (pointEvent) {
      !currentAdminCompanyObjectTypeElementsErrorEvent &&
        currentAdminCompanyTypeElementsEvent?.length > 0 &&
        setTypeElements(currentAdminCompanyTypeElementsEvent);
    }
    if (lineObject) {
      !currentAdminCompanyTypeLineErrorObject &&
        currentAdminCompanyTypeLineObject?.length > 0 &&
        setTypeElements(currentAdminCompanyTypeLineObject);
    }
  }, [
    currentAdminCompanyTypeElementsErrorObject,
    currentAdminCompanyObjectTypeElementsErrorComponent,
    currentAdminCompanyObjectTypeElementsErrorEvent,
    currentAdminCompanyTypeLineErrorObject,
    currentAdminCompanyTypeElementsObject,
    currentAdminCompanyTypeElementsComponent,
    currentAdminCompanyTypeElementsEvent,
    currentAdminCompanyTypeLineObject,
    currentAdminCompany,
    librarySelected,
    pointObject,
    pointComponent,
    pointEvent,
  ]);

  const handleAdminCompany = (e) => {
    setDataLibrary([]);
    setTypeElements([]);
    setTypeIdsSelected([]);
    setTypeElementsSelect([]);
    setCurrentAdminCompany(e.target.value);
  };

  const handlerLibrarySelected = (e) => {
    setLibrarySelectedEvent(e);
    setTypeIdsSelected([]);
    setTypeElementsSelect([]);
    const library = dataLibrary.find((elm) => elm.id === e.value);
    setLibrarySelected(library);
  };

  const handlerClickElementType = async (id, libraryId, typeElement) => {
    setTypeIdsSelected((current) => {
      if (current.includes(id)) {
        return current.filter((item) => item !== id);
      }
      return [...current, id];
    });

    setTypeElementsSelect((current) => {
      if (current.some((item) => item.name === typeElement.name)) {
        return current.filter((item) => item.name !== typeElement.name);
      }
      return [
        ...current,
        {
          name: typeElement.name,
          urlIconColor: typeElement.urlIconColor || typeElement.icon,
        },
      ];
    });
  };

  const handlerClickLineType = async (id, libraryId, typeLine) => {
    setTypeIdsSelected((current) => {
      if (current.includes(id)) {
        return current.filter((item) => item !== id);
      }
      return [...current, id];
    });

    setTypeElementsSelect((current) => {
      if (current.some((item) => item.type === typeLine.type)) {
        return current.filter((item) => item.type !== typeLine.type);
      }
      return [
        ...current,
        {
          type: typeLine.type,
          description: typeLine.description,
          color: typeLine.color,
          dasharrayWidth: typeLine.dasharrayWidth,
          dasharrayPixels: typeLine.dasharrayPixels,
          fields: typeLine.fields,
        },
      ];
    });
  };

  const handlerDuplicateLibrary = () => {
    configAdmin.toggleForm();
    handleChangeForm({
      target: { name: "name", value: librarySelected.name, validate: "true" },
    });
    handleChangeForm({
      target: { name: "icon", value: librarySelected.icon, validate: "true" },
    });
    handleChangeForm({
      target: {
        name: "fields",
        value: librarySelected.fields,
        validate: "true",
      },
    });
    handleChangeForm({
      target: {
        name: "description",
        value: librarySelected.description,
        validate: "true",
      },
    });
    setDialog((current) => ({
      ...current,
      open: false,
    }));
  };

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        minHeight: "300px",
      }}
    >
      <SelectContainerAdminConfig
        style={{
          padding: "0px",
          marginRight: "0px",
        }}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: 500,
            marginRight: "25px",
          }}
        >
          Select from which admin:{" "}
        </p>
        <div>
          <select
            style={{
              zIndex: 999,
            }}
            name="endPoint"
            id="endPoint"
            onChange={handleAdminCompany}
          >
            {allDataAdmin?.map((admin) => {
              return (
                <option
                  style={{
                    zIndex: 999,
                    position: "absolute",
                  }}
                  key={admin.id}
                  value={admin.id}
                >
                  {`${admin.firstName} ${admin.firstLastName}`}
                </option>
              );
            })}
          </select>
        </div>
      </SelectContainerAdminConfig>
      <ConfigObjectsContainer
        style={{
          height: "auto",
          marginBottom: "20px",
        }}
        fontSize={"20px"}
      >
        <SelectLibrary
          libraries={dataLibrary}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={librarySelectedEvent}
        />
        {typeElements.length > 0 && pointObject && (
          <SelectObjectTypes
            objectTypes={typeElements}
            librarySelected={librarySelected.id}
            handlerClickObjectType={handlerClickElementType}
            typeIdsSelected={typeIdsSelected}
          />
        )}

        {typeElements.length > 0 && lineObject && (
          <SelectLineObjectTypes
            lineObjectTypes={typeElements}
            linelibrarySelected={librarySelected.id}
            handlerClickLineObjectType={handlerClickLineType}
            typeIdsSelected={typeIdsSelected}
          />
        )}

        {typeElements.length > 0 && pointComponent && (
          <SelectComponentTypes
            componentTypes={typeElements}
            librarySelected={librarySelected.id}
            handlerClickComponentType={handlerClickElementType}
            typeIdsSelected={typeIdsSelected}
          />
        )}

        {typeElements.length > 0 && pointEvent && (
          <SelectEventTypes
            eventTypes={typeElements}
            librarySelected={librarySelected.id}
            handlerClickEventType={handlerClickElementType}
            typeIdsSelected={typeIdsSelected}
          />
        )}
      </ConfigObjectsContainer>
      <div
        style={{
          border: "solid 1px",
        }}
      >
        <CustomButton
          onClick={() => handlerDuplicateLibrary()}
          text={"Duplicate Library"}
          margin={0}
        />
      </div>
    </div>
  );
};
