import { useMemo, useState, useEffect } from "react";
// Hooks
import useSwr from "swr";
// Config
import { config } from "config.js";

const useFindComponents = () => {
  const [loading, setLoading] = useState(true);

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlComponents = `${config.URL_BACKEND_PG}api/v1/point-components?adminCompanyId=${adminCompanyId}`;
  const { data, error } = useSwr(urlComponents);

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  const components = useMemo(() => {
    return data && !error ? data : [];
  }, [data, error]);

  return { components, loading };
};

export default useFindComponents;
