import { useState } from "react";
import { ButtonRoute } from "../ButtonElements";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Img,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  SubTitle,
  BtnWrap,
  ImgWrap,
  ArrowForward,
  ArrowRight,
} from "./InfoElements";
import { useNavigate } from "react-router-dom";
import React from "react";
import { WHITE_BACKGROUND } from "utils/const";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  lightText,
  topLine,
  headLine,
  darkText,
  description,
  buttonLabel,
  primary,
  dark,
  img,
  colorWave,
}) => {
  const wave = () => {
    return (
      <div style={{ height: "100px", overflow: "hidden" }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M0.00,49.98 C179.73,247.22 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: "none", fill: `${colorWave}` }}
          ></path>
        </svg>
      </div>
    );
  };

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    const loginStatus = localStorage.getItem("loginStatus");
    const role = localStorage.getItem("role");
    if (loginStatus) {
      switch (role) {
        case "COMPANY ADMIN":
          navigate("/admin");
          break;
        case "OPERATOR":
          navigate("/hub");
          break;
        default:
          navigate("/");
          break;
      }
    } else {
      navigate("/signIn");
    }
  };

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine
                  dangerouslySetInnerHTML={{ __html: topLine }}
                ></TopLine>

                <Heading lightText={lightText}>{headLine}</Heading>
                <SubTitle
                  darkText={darkText}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></SubTitle>
                <BtnWrap>
                  <ButtonRoute
                    onClick={handleClick}
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                  >
                    {buttonLabel} {hover ? <ArrowForward /> : <ArrowRight />}
                  </ButtonRoute>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                {/* Verificar si la prop 'img' es un componente o una ruta de imagen */}
                {React.isValidElement(img) ? (
                  // Si es un componente, renderizar directamente
                  img
                ) : (
                  // Si es una ruta de imagen, renderizar la etiqueta 'img'
                  <Img
                    width="50px"
                    src={img}
                    alt="column"
                    className="image-size"
                  ></Img>
                )}
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
        {wave()}
      </InfoContainer>
    </>
  );
};

export default InfoSection;
