import { PRIMARY_COLOR } from "utils/const";

const MaterialSymbolsTextSnippetOutline = ({
  color = PRIMARY_COLOR,
  size = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M5 19h14V9.825L14.175 5H5zm0 2q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h10l6 6v10q0 .825-.587 1.413T19 21zm2-4h10v-2H7zm0-4h10v-2H7zm0-4h7V7H7zM5 19V5z"
      />
    </svg>
  );
};

export default MaterialSymbolsTextSnippetOutline;
