import { useState } from "react";
// Components
import NavBar from "components/NavBar";
import Terms from "../ShowData";
import LinksTerms from "./LinksTerms";
// Styles
import { Container } from "../indexStyles";
// Data
import { dataSocrates } from "../TermsData/termsSocrates";

const Index = () => {
  const configNavbar = {
    sidebar: true,
    typeLinks: "scroll",
    linkLogo: "/",
    menuElements: {},
    button: {
      link: "signIn",
      text: "Log In",
    },
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavBar toggle={toggle} configNavbar={configNavbar} />
      <Container className="background-1">
        <LinksTerms />
        <Terms data={dataSocrates} />
      </Container>
    </>
  );
};

export default Index;
