/**
 * Checks if the CSV data has all the required headers according to the provided list.
 *
 * This function splits the input CSV string into rows, extracts the header row,
 * and then checks if every header in the `strictHeaders` array exists in the
 * extracted header row. It returns `true` if all headers are present, otherwise
 * it returns `false`.
 *
 * @function checkCSVHeaders
 * @category Utility Functions
 * @since 1.0.0
 * @example
 * // Example usage:
 * const isValid = checkCSVHeaders("id,name,value\r\n1,javier,88", ["id", "name"]);
 * console.log(isValid); // Output: true
 *
 * @param {string} [data=""] - The CSV data to be checked. This parameter can be omitted or left empty, in which case the function will not perform any checks.
 * @param {any[]} [strictHeaders=[]] - An array of strings representing the exact headers that must be present in the CSV data. This parameter can also be omitted or left empty, in which case the function will not perform any header checks.
 * @returns {boolean} - Returns `true` if all headers specified in `strictHeaders` are found in the CSV data, otherwise returns `false`.
 *
 * @throws Will throw an error if both `data` and `strictHeaders` are provided but are empty or invalid.
 */
function checkCSVHeaders(data = "", strictHeaders = []) {
  if (!data || !strictHeaders || data === "" || !strictHeaders.length)
    return false;
  const rowArrays = data.split(/\r\n|\n/); // Splits the CSV data into individual rows
  const rowHeaders = rowArrays?.[0].split(","); // Extracts the headers from the first row
  const check = strictHeaders.every((prop) => {
    // Checks if each header in `strictHeaders` is present in the extracted headers
    if (!rowHeaders.find((it) => it === prop)) return false;
    return true;
  });
  return check; // Returns true if all headers are present, otherwise false
}

export default checkCSVHeaders;
