
// Styles
import { ConfigAdminContainer } from "../configAdminStyles.jsx";
import ObjectLinesLibraries from "./ObjectsLinesLibraries.jsx";

export default function LibraryLine({
  adminCompanyId,
  adminsInfo,
  setOpenDialogConfig,
  setContentDialogConfig,
}) {
  return (
    <div>
      <ConfigAdminContainer className="background-2 border-top-1">
        <ObjectLinesLibraries
          adminCompanyId={adminCompanyId}
          adminsInfo={adminsInfo}
          setOpenDialogConfig={setOpenDialogConfig}
          setContentDialogConfig={setContentDialogConfig}
        />
      </ConfigAdminContainer>
    </div>
  );
}
