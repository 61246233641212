import styled from "styled-components";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  min-width: 800px;
  max-height: 100%;
  min-height: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: ${(props) => props.background};
  @media screen and (max-width: 1300px) {
    & {
      width: auto;
    }
  }
`;

export const PageHeader = styled.section`
  width: 100%;
  min-height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};

  .page-header-title {
    min-width: 500px;
    min-height: 80px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h1 {
    font-size: 25px;
  }
  img {
    width: 60px;
    margin-left: 20px;
  }
  .page-header-title-id {
    margin-left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
  }
  .page-header-id {
    min-width: 500px;
    min-height: 80px;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .page-header-id-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-height: 85%;
  height: auto;
  display: flex;
  gap: 5px;
`;

export const PageDrawer = styled.div`
  min-width: 100px;
  max-width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};

  span {
    width: auto;
    height: auto;
    position: absolute;
    margin-top: 0;
    transform: rotate(-90deg);
    object-fit: fill;
  }

  span > h1 {
    font-size: 2.1rem;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    writing-mode: vertical-tb;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: flex-wrap;
  justify-content: flex-end;
  background-color: white;
`;

export const DetailInspection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;

  .details-img-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .images-container {
    display: flex;
    align-items: stretch;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: fill;
  }
  .details-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-height: 800px;
    padding-left: 20px;
    font-size: 20px;
  }
`;

export const ResultInspection = styled.div`
  width: 20%;
  min-width: 200px;
  min-height: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-beetwen;
  align-items: center;
  position: relative;

  .upper-container {
    height: 100%;
  }
  p {
    font-size: 25px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .result-container {
    width: 100%;
    height: auto;
    min-height: 150px;
    border: 2px solid ${(props) => props.borderColor};
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .lower-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
      height: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      position: relative;
    }
  }
`;

export const SectionContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

// export const DownloadButton = styled.button`
//   width: 20vw;
//   height: auto;
//   font-size: 2.25rem;
//   margin-top: 20px;
//   padding: 5px 20px;
//   border: none;
//   border-radius: 15px;
//   color: white;
//   background-color: ${PRIMARY_COLOR};
//   &:hover {
//     background-color: ${SECOND_COLOR};
//     color: white;
//     cursor: pointer;
//   }
// `;
