import React from "react";
const ComponentIcon = ({ width, height }) => {
  return (
    <div style={{ marginBottom: "2px" }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 296.000000 295.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,295.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            fill="#e44040"
            d="M1120 2424 c-195 -113 -426 -247 -512 -297 l-158 -91 0 -561 0 -560
507 -295 c279 -162 514 -294 523 -294 9 0 244 132 523 294 l507 295 0 560 0
561 -157 91 c-734 426 -868 503 -873 503 -3 0 -165 -93 -360 -206z m828 -314
l342 -195 -3 -449 -2 -450 -380 -218 c-209 -119 -392 -222 -406 -228 -25 -11
-58 6 -433 220 l-406 232 2 446 3 446 405 232 405 232 66 -37 c36 -20 220
-124 407 -231z"
          />
          <path
            fill="#000000"
            d="M1060 1638 c-64 -33 -85 -67 -85 -138 0 -63 16 -95 67 -133 54 -40
157 -31 198 18 11 13 11 18 -2 31 -14 14 -19 14 -46 0 -65 -33 -132 -12 -152
49 -15 44 -4 80 32 110 34 29 75 32 119 9 28 -14 32 -14 50 2 23 21 17 31 -35
55 -54 25 -92 24 -146 -3z"
          />
          <path
            fill="#000000"
            d="M1320 1495 l0 -155 30 0 30 0 1 113 0 112 46 -98 c59 -125 68 -125
128 3 25 52 45 91 45 85 1 -5 2 -55 3 -110 l2 -100 33 -3 32 -3 0 155 0 156
-46 0 -45 0 -44 -92 -43 -93 -43 92 -44 93 -42 0 -43 0 0 -155z"
          />
          <path
            fill="#000000"
            d="M1750 1495 l0 -155 30 0 c29 0 30 2 30 45 l0 45 38 0 c56 0 110 18
127 43 17 25 20 99 5 128 -19 34 -64 49 -150 49 l-80 0 0 -155z m172 83 c12
-20 9 -64 -6 -77 -7 -6 -34 -14 -60 -17 l-46 -7 0 63 0 63 52 -6 c28 -4 55
-12 60 -19z"
          />
        </g>
      </svg>
    </div>
  );
};

export default ComponentIcon;
