// React - Redux
import React from "react";
import { useSelector } from "react-redux";
// Redux
import { setNeuronSelectTool } from "redux/actions/digitalTwin";
// Style
import { ButtonCopyCoordinates } from "./userRoutes";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { Typography } from "@mui/material";

function CaptureCoordinates({
  onCopyCoordinates,
  setCopyCoordinates,
  copyCoordinates,
  copyLocation,
  setCopyLocation,
}) {
  const drawerControl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );
  const handleCopyCoordinates = () => {
    onCopyCoordinates({
      setCopyCoordinates,
      copyCoordinates,
      copyLocation,
      setCopyLocation,
      drawerControl,
      setNeuronSelectTool,
    });
  };

  return (
<SimpleToolTip
  title={
    Object.keys(copyLocation).length === 0 ? "Copy Coordinates" : (
      <>
        <Typography style={{fontSize: 12}}>
          Longitude: {copyLocation.lng}
        </Typography>
        <Typography style={{fontSize: 12}}>
          Latitude: {copyLocation.lat}
        </Typography>
      </>
    )
  }
  placement="left"
>
  <ButtonCopyCoordinates onClick={() => handleCopyCoordinates()}>
    {copyLocation.hasOwnProperty("lng") &&
      copyLocation.hasOwnProperty("lat") &&
      copyLocation.lng !== "" &&
      copyLocation.lat !== "" && <p>✓</p>}
    <ShareLocationIcon
      style={{
        color: "#282828",
        fontSize: "1.6rem",
      }}
    />
  </ButtonCopyCoordinates>
</SimpleToolTip>

  );
}

export default CaptureCoordinates;
