// Style
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import TollIcon from "@mui/icons-material/Toll";
import { BLACK_COLOR, DELETE_COLOR, VISIBILITY_OFF_ICON } from "utils/const";
import {
  Change,
  DateFilterContentStyle,
  getSelectStyles,
} from "../../../GenericTableStyle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// React
import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
// Helpers
import { removePropertyExist } from "./removePropertyExist";
import { calculateOnlyOneOption } from "./calculateOnlyOneOption";
import { setDuplicateObjects } from "./setDuplicateObjects";
import { isDate } from "helpers/isDate";
// Components
import DebouncedInput from "./GenericInputFilter";
import DatePicker from "components/Accordions/DatePicker";

function GenericFilterTable({
  column,
  table,
  setValue,
  setFilterCheckBox,
  filterCheckBox,
  setDateFilters,
  dateFilters,
  rows,
}) {
  const [changeRange, setChangeRange] = useState(false);
  const [dataChecked, setDataChecked] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [currentObject, setCurrentObject] = useState({});
  const [allOptions, setAllOptions] = useState([]);
  const [useCheck, setUseCheck] = useState(true);

  const selectStyles = getSelectStyles(
    table.getPrePaginationRowModel().rows.length
  );

  const firstValue = table
    ?.getPreFilteredRowModel()
    ?.flatRows[0]?.getValue(column.id);

  var columnFilterValue = column.getFilterValue();
  if (setValue) {
    columnFilterValue = "";
  }

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column, firstValue]
  );

  const sortedUniqueValuesFilterSelect = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues()).sort(),
    [rows, dataChecked, openSelect, useCheck]
  );

  const sortedUniqueValuesFilterSelect3 = useMemo(() => {
    if (typeof firstValue === "number") return [];
    return Array.from(column.getFacetedUniqueValues()).sort();
  }, [table.getPrePaginationRowModel().rows.length, rows]);

  useEffect(() => {
    const options = sortedUniqueValuesFilterSelect
      .map((elm) => {
        return {
          value: elm[0],
          label: elm[0],
          uniqueValue: elm[1],
        };
      })
      .filter((it) => it !== undefined);

    if (column.id === "relationId") {
      const relationComponentsLength = table
        .getPreFilteredRowModel()
        .flatRows.map((elm) => elm.getValue("relationId"))
        .filter((item) => /\/ C$/.test(item));
      const relationEventsLength = table
        .getPreFilteredRowModel()
        .flatRows.map((elm) => elm.getValue("relationId"))
        .filter((item) => /\/ E$/.test(item));
      const relationObjectsLength = table
        .getPreFilteredRowModel()
        .flatRows.map((elm) => elm.getValue("relationId"))
        .filter((item) => /\/ O$/.test(item));
      const relations = [
        {
          value: "Objects",
          label: "Objects",
          uniqueValue: relationObjectsLength.length,
        },
        {
          value: "Components",
          label: "Components",
          uniqueValue: relationComponentsLength.length,
        },
        {
          value: "Events",
          label: "Events",
          uniqueValue: relationEventsLength.length,
        },
      ];
      const res = relations.concat(options);
      return setAllOptions(res);
    }

    if (filterCheckBox.length > 0) {
      return allOptions.forEach((elm) => {
        options.forEach((elm2) => {
          if (elm.value === elm2.value) {
            elm.uniqueValue = elm2.uniqueValue;
          }
        });
      });
    }

    return setAllOptions(options);
  }, [
    column,
    table,
    sortedUniqueValuesFilterSelect,
    dataChecked,
    openSelect,
    useCheck,
  ]);

  useEffect(() => {
    if (
      allOptions.length > 0 &&
      !dataChecked.find((it) => Object.keys(it).includes(column.id)) &&
      Object.keys(currentObject).length === 0
    ) {
      const res = allOptions.map((elm) => {
        let updatedElm = {};

        if (column.id === "relationId") {
          const relationComponentsLength = table
            .getPreFilteredRowModel()
            .flatRows.map((elm) => elm.getValue("relationId"))
            .filter((component) => /\/ C$/.test(component));
          const relationEventsLength = table
            .getPreFilteredRowModel()
            .flatRows.map((elm) => elm.getValue("relationId"))
            .filter((event) => /\/ E$/.test(event));
          const relationObjectsLength = table
            .getPreFilteredRowModel()
            .flatRows.map((elm) => elm.getValue("relationId"))
            .filter((objet) => /\/ O$/.test(objet));
          if (elm.value === "Objects") {
            updatedElm = {
              ...elm,
              uniqueValue: relationObjectsLength.length,
            };
          }
          if (elm.value === "Components") {
            updatedElm = {
              ...elm,
              uniqueValue: relationComponentsLength.length,
            };
          }
          if (elm.value === "Events") {
            updatedElm = {
              ...elm,
              uniqueValue: relationEventsLength.length,
            };
          }
        }

        sortedUniqueValuesFilterSelect3?.forEach((item) => {
          if (
            elm.value !== "Objects" &&
            elm.value !== "Components" &&
            elm.value !== "Events" &&
            elm.value === item[0]
          ) {
            updatedElm = {
              ...elm,
              uniqueValue: item[1],
            };
          }
        });
        if (
          elm.value !== "Objects" &&
          elm.value !== "Components" &&
          elm.value !== "Events" &&
          Object.keys(updatedElm).length === 0
        )
          return { ...elm, uniqueValue: 0 };
        return updatedElm;
      });
      setAllOptions(res);
    }
  }, [table.getPrePaginationRowModel().rows.length, rows]);

  useEffect(() => {
    if (
      filterCheckBox.length >= 2 &&
      !filterCheckBox.find((it) => Object.keys(it).includes(column.id))
    ) {
      const res = allOptions.map((elm) => {
        let updatedElm = {};

        if (column.id === "relationId") {
          const relationComponentsLength = table
            .getPreFilteredRowModel()
            .flatRows.map((elm) => elm.getValue("relationId"))
            .filter((component) => /\/ C$/.test(component));
          const relationEventsLength = table
            .getPreFilteredRowModel()
            .flatRows.map((elm) => elm.getValue("relationId"))
            .filter((event) => /\/ E$/.test(event));
          const relationObjectsLength = table
            .getPreFilteredRowModel()
            .flatRows.map((elm) => elm.getValue("relationId"))
            .filter((objet) => /\/ O$/.test(objet));
          if (elm.value === "Objects") {
            updatedElm = {
              ...elm,
              uniqueValue: relationObjectsLength.length,
            };
          }
          if (elm.value === "Components") {
            updatedElm = {
              ...elm,
              uniqueValue: relationComponentsLength.length,
            };
          }
          if (elm.value === "Events") {
            updatedElm = {
              ...elm,
              uniqueValue: relationEventsLength.length,
            };
          }
        }
        sortedUniqueValuesFilterSelect3?.forEach((item) => {
          if (
            elm.value !== "Objects" &&
            elm.value !== "Components" &&
            elm.value !== "Events" &&
            elm.value === item[0]
          ) {
            updatedElm = {
              ...elm,
              uniqueValue: item[1],
            };
          }
        });
        if (
          elm.value !== "Objects" &&
          elm.value !== "Components" &&
          elm.value !== "Events" &&
          Object.keys(updatedElm).length === 0
        )
          return { ...elm, uniqueValue: 0 };
        return updatedElm;
      });
      setAllOptions(res);
    }
  }, [table.getPrePaginationRowModel().rows.length]);

  useEffect(() => {
    if (Object.keys(currentObject).length > 0) {
      const newData = removePropertyExist(filterCheckBox, currentObject);
      setFilterCheckBox(newData.filter((it) => Object.keys(it).length !== 0));
    }
  }, [currentObject]);

  useEffect(() => {
    if (filterCheckBox.length === 0) {
      setDataChecked([]);
      setCurrentObject([]);
    }
  }, [filterCheckBox]);

  const isChecked = (column, value) => {
    if (dataChecked.length > 0) {
      const match = dataChecked.find(
        (it) => JSON.stringify(it) === JSON.stringify({ [column]: value })
      );
      if (
        match &&
        JSON.stringify(match) === JSON.stringify({ [column]: value })
      )
        return true;
      return false;
    }
  };

  const check = (column, value) => {
    setUseCheck(!useCheck);
    const exist = dataChecked.find(
      (it) => JSON.stringify(it) === JSON.stringify({ [column]: value })
    );

    const checkObjects = dataChecked.find(
      (it) => JSON.stringify(it) === JSON.stringify({ relationId: "Objects" })
    );
    const checkEvents = dataChecked.find(
      (it) => JSON.stringify(it) === JSON.stringify({ relationId: "Events" })
    );
    const checkComponents = dataChecked.find(
      (it) =>
        JSON.stringify(it) === JSON.stringify({ relationId: "Components" })
    );

    if (
      column === "relationId" &&
      (checkObjects || checkEvents || checkComponents)
    ) {
      if (checkObjects && value === "Objects") {
        const setRelationObjects = dataChecked.filter((elm) => {
          return (
            /\/ O$/.test(elm.relationId) === false &&
            elm.relationId !== "Objects"
          );
        });
        setCurrentObject({ relationId: "Objects" });
        return setDataChecked(setRelationObjects);
      }
      if (checkEvents && value === "Events") {
        const setRelationEvents = dataChecked.filter((elm) => {
          return (
            /\/ E$/.test(elm.relationId) === false &&
            elm.relationId !== "Events"
          );
        });
        setCurrentObject({ relationId: "Events" });
        return setDataChecked(setRelationEvents);
      }
      if (checkComponents && value === "Components") {
        const setRelationComponents = dataChecked.filter((elm) => {
          return (
            /\/ C$/.test(elm.relationId) === false &&
            elm.relationId !== "Components"
          );
        });
        setCurrentObject({ relationId: "Components" });
        return setDataChecked(setRelationComponents);
      }
    }

    if (exist) {
      const filteredPrueba = dataChecked.filter(
        (item) => JSON.stringify(item) !== JSON.stringify({ [column]: value })
      );
      setDataChecked(filteredPrueba);
      setCurrentObject({ [column]: value });
    } else {
      if (column === "relationId") {
        if (value === "Objects") {
          const relationObjects = allOptions.filter((item) =>
            /\/ O$/.test(item.value)
          );
          relationObjects.forEach((elm) => {
            return setDataChecked((prev) => [
              ...prev,
              { relationId: elm.value },
            ]);
          });
        }
        if (value === "Components") {
          const relationComponents = allOptions.filter((item) =>
            /\/ C$/.test(item.value)
          );
          relationComponents.forEach((elm) => {
            return setDataChecked((prev) => [
              ...prev,
              { relationId: elm.value },
            ]);
          });
        }
        if (value === "Events") {
          const relationEvents = allOptions.filter((item) =>
            /\/ E$/.test(item.value)
          );
          relationEvents.forEach((elm) => {
            return setDataChecked((prev) => [
              ...prev,
              { relationId: elm.value },
            ]);
          });
        }
        return setDataChecked((prev) => [...prev, { [column]: value }]);
      }
      return setDataChecked((prev) => [...prev, { [column]: value }]);
    }
  };

  const addNewFilter = useCallback(
    (column, valueColumn) => {
      const newArray = [];
      if (filterCheckBox.length > 0) {
        filterCheckBox.forEach((elm) => {
          if (
            column === "relationId" &&
            (valueColumn === "Objects" ||
              valueColumn === "Components" ||
              valueColumn === "Events")
          ) {
            let newObject = {};
            if (valueColumn === "Objects") {
              const relationObjects = allOptions.filter((item) =>
                /\/ O$/.test(item.value)
              );
              relationObjects.forEach((obj) => {
                if (obj.value !== "Objects") {
                  if (elm.hasOwnProperty("relationId")) {
                    newArray.push(elm);
                  }
                  newObject = {
                    ...elm,
                    relationId: obj.value,
                  };
                  return newArray.push(newObject);
                }
              });
            }
            if (valueColumn === "Components") {
              const relationComponents = allOptions.filter((item) =>
                /\/ C$/.test(item.value)
              );
              relationComponents.forEach((component) => {
                if (component.value !== "Components") {
                  if (elm.hasOwnProperty("relationId")) {
                    newArray.push(elm);
                  }
                  newObject = {
                    ...elm,
                    relationId: component.value,
                  };
                  return newArray.push(newObject);
                }
              });
            }
            if (valueColumn === "Events") {
              const relationEvents = allOptions.filter((item) =>
                /\/ E$/.test(item.value)
              );
              relationEvents.forEach((event) => {
                if (event.value !== "Events") {
                  if (elm.hasOwnProperty("relationId")) {
                    newArray.push(elm);
                  }
                  newObject = {
                    ...elm,
                    relationId: event.value,
                  };
                  return newArray.push(newObject);
                }
              });
            }
          }

          if (
            valueColumn !== "Objects" &&
            valueColumn !== "Components" &&
            valueColumn !== "Events"
          ) {
            const entries = Object.entries(elm);
            for (const [key, value] of entries) {
              let newObject = {};
              if (key !== column) {
                newObject = {
                  ...elm,
                  [column]: valueColumn,
                };
              }
              if (key === column && value !== valueColumn) {
                newArray.push(elm);
                const res = {
                  ...elm,
                  [column]: valueColumn,
                };
                newObject = res;
                newArray.push(newObject);
                return null;
              }
              if (key === column && value === valueColumn) {
                newObject[key] = value;
              }
              newArray.push(newObject);
            }
          }
        });
      } else {
        if (
          column === "relationId" &&
          (valueColumn === "Objects" ||
            valueColumn === "Components" ||
            valueColumn === "Events")
        ) {
          if (valueColumn === "Objects") {
            const relationObjects = allOptions.filter((item) =>
              /\/ O$/.test(item.value)
            );

            relationObjects.forEach((obj) => {
              if (obj.value !== "Objects") {
                newArray.push({
                  relationId: obj.value,
                });
              }
            });
          }
          if (valueColumn === "Components") {
            const relationComponents = allOptions.filter((item) =>
              /\/ C$/.test(item.value)
            );
            relationComponents.forEach((component) => {
              if (component.value !== "Components") {
                newArray.push({
                  relationId: component.value,
                });
              }
            });
          }
          if (valueColumn === "Events") {
            const relationEvents = allOptions.filter((item) =>
              /\/ E$/.test(item.value)
            );
            relationEvents.forEach((event) => {
              if (event.value !== "Events") {
                newArray.push({
                  relationId: event.value,
                });
              }
            });
          }
        }
        newArray.push({ [column]: valueColumn });
        setFilterCheckBox(newArray);
      }
      setFilterCheckBox(setDuplicateObjects(newArray));
    },
    [column, filterCheckBox, setFilterCheckBox, allOptions]
  );

  const dateValue =
    column.id === "dateUTC" || column.id === "localDate"
      ? sortedUniqueValues?.map((elm) => {
          return elm?.substring(0, 10);
        })
      : [];
  const sortedUniqueDateValues = [...new Set(dateValue)];
  const [onlyOneOption, setOnlyOneOption] = useState(false);

  useEffect(() => {
    const newOnlyOneOption = calculateOnlyOneOption(allOptions);
    setOnlyOneOption(newOnlyOneOption);
  }, [allOptions]);

  const handleChangeMinDate = (date) => {
    if (dateFilters?.columnId !== "" && dateFilters?.columnId !== column.id) {
      setDateFilters({
        columnId: column.id,
        min: date,
        max: "",
      });
    } else {
      setDateFilters((prev) => ({
        ...prev,
        columnId: column.id,
        min: date,
      }));
    }
  };

  const handleChangeMaxDate = (date) => {
    if (dateFilters?.columnId !== "" && dateFilters?.columnId !== column.id) {
      setDateFilters({
        columnId: column.id,
        min: "",
        max: date,
      });
    } else {
      setDateFilters((prev) => ({
        ...prev,
        columnId: column.id,
        max: date,
      }));
    }
  };

  if (typeof firstValue === "number") {
    if (changeRange === false) {
      return (
        <div>
          <Change onClick={() => setChangeRange(!changeRange)}>
            <FiberSmartRecordIcon
              style={{ mb: "-5px", mr: "5px", color: `${BLACK_COLOR}` }}
            />
            <div className="container-tooltip">
              <span className="tooltip">Range Mode</span>
            </div>
          </Change>
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) => column.setFilterValue((old) => [value, value])}
            placeholder={`Num (${column.getFacetedUniqueValues().size})`}
            className="tableCell2"
          />
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <Change onClick={() => setChangeRange(!changeRange)}>
              <TollIcon sx={{ mb: "-5px", mr: "5px" }} />
              <div className="container-tooltip">
                <span className="tooltip">Unique Value</span>
              </div>
            </Change>
          </div>
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[0] ?? ""}
            onChange={(value) =>
              column.setFilterValue((old) => [value, old?.[1]])
            }
            placeholder={`Min ${
              column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ""
            }`}
            className="tableCell2"
          />
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) =>
              column.setFilterValue((old) => [old?.[0], value])
            }
            placeholder={`Max ${
              column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ""
            }`}
            className="tableCell2"
          />
        </div>
      );
    }
  }

  if (isDate(firstValue)) {
    return (
      <DateFilterContentStyle>
        <div className="contentButtons">
          <button
            className="resetButton"
            onClick={() => setDateFilters({ columnId: "", min: "", max: "" })}
          >
            <RestartAltIcon
              sx={{
                fontSize: "2rem",
                color: `${BLACK_COLOR}`,
                "&:hover": {
                  transition: "0.6s",
                  color: `${DELETE_COLOR}`,
                },
              }}
            />
          </button>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              startdate={
                dateFilters?.columnId === column.id ? dateFilters.min : ""
              }
              enddate={
                dateFilters?.columnId === column.id ? dateFilters.max : ""
              }
              value={dateFilters?.columnId === column.id ? dateFilters.min : ""}
              changefunction={handleChangeMinDate}
              from={"genericTable"}
              active={
                dateFilters?.columnId === column.id && dateFilters.min !== ""
                  ? true
                  : false
              }
            />
            <HorizontalRuleIcon sx={{ margin: 0 }} />
            <DatePicker
              startdate={
                dateFilters?.columnId === column.id ? dateFilters.min : ""
              }
              enddate={
                dateFilters?.columnId === column.id ? dateFilters.max : ""
              }
              value={dateFilters?.columnId === column.id ? dateFilters.max : ""}
              changefunction={handleChangeMaxDate}
              from={"genericTable"}
              active={
                dateFilters?.columnId === column.id && dateFilters.max !== ""
                  ? true
                  : false
              }
            />
          </LocalizationProvider>
        </div>
      </DateFilterContentStyle>
    );
  }

  if (typeof firstValue === "string" && !isNaN(firstValue) === false) {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <Select
          styles={selectStyles}
          menuPlacement="bottom"
          options={[...allOptions]}
          isMulti
          closeMenuOnSelect={false}
          menuIsOpen={openSelect}
          onMenuClose={() => setOpenSelect(false)}
          onMenuOpen={() => setOpenSelect(true)}
          placeholder={`Options (${column.getFacetedUniqueValues().size})`}
          components={{
            Option: ({ label, data }) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    margin: "2px",
                  }}
                >
                  {onlyOneOption === false && (
                    <input
                      style={{
                        margin: "0px",
                        textAlign: "left",
                      }}
                      type="checkbox"
                      onChange={() => {
                        check(column.id, data.value);
                        addNewFilter(column.id, data.value);
                      }}
                      checked={isChecked(column.id, data.value)}
                      disabled={data.uniqueValue === 0 ? true : false}
                    />
                  )}
                  <div
                    style={{
                      marginRight: "1px",
                      marginLeft: "3px",
                      marginBottom: "2px",
                      color: `${
                        data.uniqueValue === 0
                          ? VISIBILITY_OFF_ICON
                          : BLACK_COLOR
                      }`,
                    }}
                  >
                    {" "}
                    {`(${data.uniqueValue})`}
                  </div>
                  <p
                    style={{
                      color: `${
                        data.uniqueValue === 0
                          ? VISIBILITY_OFF_ICON
                          : BLACK_COLOR
                      }`,
                      marginTop: "1px",
                      marginLeft: "3px",
                    }}
                  >
                    {label}
                  </p>
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  }
  if (typeof firstValue === "string") {
    return (
      <>
        <datalist id={column.id + "list"}>
          {column.id === "localDate" && sortedUniqueDateValues.length !== 0
            ? sortedUniqueDateValues
                .slice(0, 5000)
                .map((value) => <option value={value} key={value} />)
            : sortedUniqueValues
                .slice(0, 5000)
                .map((value) => <option value={value} key={value} />)}
        </datalist>
        <DebouncedInput
          type="text"
          value={columnFilterValue ?? ""}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`Search (${
            column.id === "localDate"
              ? sortedUniqueDateValues.length
              : column.getFacetedUniqueValues().size
          })`}
          className="tableCell"
          list={column.id + "list"}
        />
      </>
    );
  } else {
    return <></>;
  }
}

export default GenericFilterTable;
