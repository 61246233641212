import axios from "axios";
import { config } from "config.js";

/**
 * Fetches all object relations based on the given query parameters.
 *
 * @param {Object} query - Query parameters to filter object relations.
 * @returns {Promise<Object[]>} A list of object relations with details of the related objects.
 */
const findObjectRelation = async (query) => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_PG}api/v1/objects/relations`;
  // Convert query object to URL query string
  const queryString = new URLSearchParams(query).toString();

  const result = await axios.get(`${url}?${queryString}`, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return result.data;
};

export default findObjectRelation;
