import { useEffect, useState } from "react";
import { createLineString } from "helpers/createLineString";
import { EVENT_CLUSTER_COLOR } from "utils/const";

/**
 * Generates a lines event relation for a given event and parent event.
 *
 * @param {Object} options - The options for generating the lines event relation.
 * @param {Object} options.event - The event object.
 * @param {Object[]} options.parentEvent - The parent event array.
 * @return {Object} The event lines and parent event lines.
 */
const useCreateLinesEventEventsRelation = ({ event, parentEvent }) => {
  const [eventLines, setEventLines] = useState([]);
  const [parentEventLines, setParentEventLines] = useState([]);

  useEffect(() => {
    setEventLines([]);
  }, [event, setEventLines]);

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  useEffect(() => {
    const createLineFeature = () => {
      const eventLines = event.PointEventRelation.filter(
        (point) => point.adminCompanyId === parseInt(adminCompanyId) && point.isGeographic === true
      ).map((pointEvent) => {
        const firstLocation = [
          event.locationOperation.longitude,
          event.locationOperation.latitude,
        ];
        const secondLocation = [
          pointEvent.locationOperation.longitude,
          pointEvent.locationOperation.latitude,
        ];
        const lineString = createLineString(
          [firstLocation, secondLocation],
          EVENT_CLUSTER_COLOR
        );
        return lineString;
      });
      setEventLines((prev) => prev.concat(eventLines));
    };

    event.PointEventRelation.length > 0 && createLineFeature();
  }, [event, setEventLines, adminCompanyId]);

  useEffect(() => {
    const createMarkerLine = () => {
      const markerLines = parentEvent.map((marker) => {
        const firstLocation = [
          event.locationOperation.longitude,
          event.locationOperation.latitude,
        ];
        const secondLocation = [marker.longitude, marker.latitude];
        const lineString = createLineString(
          [firstLocation, secondLocation],
          EVENT_CLUSTER_COLOR
        );
        return lineString;
      });
      setParentEventLines(markerLines);
    };

    parentEvent?.length > 0 && createMarkerLine();
  }, [parentEvent, event]);

  return { eventLines, parentEventLines };
};

export default useCreateLinesEventEventsRelation;
