import useSwr from "swr";
import { config } from "config.js";

const useFindTypeComponets = ({ id }) => {
  const urlData = `${config.URL_BACKEND_PG}api/v1/point-type-components?libraryId=${id}`;
  const { data, error: errorTypeComponents } = useSwr(urlData);

  const typeComponents = data?.map((item) => {
    const { fields, ...rest } = item;
    return rest;
  });

  return {
    typeComponents,
    errorTypeComponents,
  };
};

export default useFindTypeComponets;
