import {
  ContainerForm,
  VideoContent,
  WrapperVideos,
} from "./dragAndDropStyles";
import { useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AiFillCloseCircle } from "react-icons/ai";
import DialogContent from "@mui/material/DialogContent";
// import TextFileSVG from "images/text-files.svg";
import CustomButton from "components/Buttons/CustomButton";
import SelectFields from "./SelectFields";
import syncVideoWithEvent from "./syncVideoWithEvent";
import setVideos from "./setVideos";
import findEvents from "./findEvents";
import {
  BACKGROUND_THIRD_DARK,
  BORDER_DARK,
  BORDER_GREY,
  WHITE_COLOR,
} from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import TextFiles from "images/TextFiles";

const DragAndDropContent = ({ userId }) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [videosDetails, setVideosDetails] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [uploadAllVideos, setUploadAllVideos] = useState(false);
  //
  const [typeEventId, setTypeEventId] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [fieldFile, setFieldFile] = useState("");
  const [loadSyncVideos, setLoadySyncVideos] = useState(false);
  const [loadUploadVideos, setLoadUploadVideos] = useState(false);
  const { theme } = useContext(ThemeContext);

  const [isSyncVideos, setIsSyncVideos] = useState(false);

  const inputRef = useRef(null);

  // triggers when file is dropped
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSyncVideos(false);
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = e.dataTransfer.files;
      setVideos({ files, setVideoFiles, setVideosDetails });
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is selected with click
  const handleChange = async function (e) {
    e.preventDefault();
    setIsSyncVideos(false);
    if (e.target.files && e.target.files[0]) {
      const files = e.target.files;
      setVideos({ files, setVideoFiles, setVideosDetails });
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const deleteUploadVideo = async (video, videos) => {
    const filterVideos = videos.filter((item) => item.name !== video.name);
    setVideosDetails(filterVideos);
    const filterVideoFiles = videoFiles.filter(
      (item) => item.name !== video.name
    );
    setVideoFiles(filterVideoFiles);
    setIsSyncVideos(false);
  };

  const clearVideos = (e) => {
    setVideosDetails([]);
    setVideoFiles([]);
    setIsSyncVideos(false);
  };

  const uploadVideos = async ({ isUpload }) => {
    if (isUpload) {
      setLoadUploadVideos(true);
      setUploadAllVideos(true);
    }
    if (!isUpload) setLoadySyncVideos(true);
    const { eventsWithoutVideo } = await findEvents({
      fieldFile,
      typeEventId,
    });
    await syncVideoWithEvent({
      userId,
      videos: videoFiles,
      eventsWithoutVideo,
      isUpload,
      fieldName,
      fieldFile,
      videosDetails,
      setVideosDetails,
      setIsSyncVideos,
    });
    if (isUpload) setLoadUploadVideos(false);
    if (!isUpload) setLoadySyncVideos(false);
  };

  return (
    <div>
      {" "}
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <ContainerForm>
              <SelectFields
                fieldName={fieldName}
                setFieldName={setFieldName}
                fieldFile={fieldFile}
                setFieldFile={setFieldFile}
                typeEventId={typeEventId}
                setTypeEventId={setTypeEventId}
              />
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                  accept="*"
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <div className="drag-info">
                    <p className="color1">Drag and drop your file here or</p>
                    <button className="upload-button" onClick={onButtonClick}>
                      <p className="color1">Upload a file</p>
                    </button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </ContainerForm>
          </Grid>
        </Grid>
        {videosDetails && (
          <>
            {videosDetails.length > 0 && fieldName && fieldFile && (
              <Grid
                container
                style={{
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <CustomButton
                      text="Clear Videos"
                      onClick={clearVideos}
                    ></CustomButton>
                    <CustomButton
                      text="Sync Videos"
                      onClick={() => uploadVideos({ isUpload: false })}
                      isLoad={loadSyncVideos}
                    ></CustomButton>
                    <CustomButton
                      text="Upload Videos"
                      onClick={() => uploadVideos({ isUpload: true })}
                      isLoad={loadUploadVideos}
                    ></CustomButton>
                  </Box>
                </Grid>
              </Grid>
            )}
            <WrapperVideos>
              {videosDetails.map((video) => (
                <VideoContent
                  key={video?.name}
                  className="background-1 border-color-1"
                  style={{
                    background:
                      theme === "light" ? WHITE_COLOR : BACKGROUND_THIRD_DARK,
                    border: `1px solid ${
                      theme === "light" ? BORDER_GREY : BORDER_DARK
                    }`,
                  }}
                >
                  <div>
                    {uploadAllVideos && (
                      <h1 className="video-load">
                        {video.videoIsLoad ? (
                          "Uploaded ✅"
                        ) : (
                          <>
                            <CircularProgress
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#067444",
                                marginRight: "5px",
                              }}
                            />
                          </>
                        )}
                      </h1>
                    )}
                    <h1> </h1>
                    <Button
                      className="delete-button"
                      onClick={() => deleteUploadVideo(video, videosDetails)}
                    >
                      <AiFillCloseCircle
                        style={{
                          color: "var(--background-secondary_color)",
                          fontSize: 21,
                        }}
                      />
                    </Button>{" "}
                  </div>
                  <div className="video-container">
                    <TextFiles />
                  </div>
                  <div className="video-details color1">
                    <p>
                      <b>Name: </b> {video.name}
                    </p>
                    <p>
                      <b>Size: </b>
                      {(video.size / 1000000).toFixed(3)} MB
                    </p>
                    <p>
                      <b>type: </b>
                      {video.type}
                    </p>

                    {isSyncVideos && (
                      <p>
                        <b>Is match:</b>{" "}
                        <span>{video.isMatch ? "Yes ✅" : "No ❌"}</span>
                      </p>
                    )}
                  </div>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  ></Box>
                </VideoContent>
              ))}
            </WrapperVideos>
          </>
        )}
      </DialogContent>
    </div>
  );
};
export default DragAndDropContent;
