import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  ServicesContainer,
  ServicesCard,
  ServicesIcon,
  ServicesH1,
  ServicesH2,
  ServicesP,
  HubWrapper,
  CardContainer,
  ArrowContainer,
  LeftArrow,
  RightArrow,
} from "./ServicesElements";
import AugmentedRealityImg from "images/AugmentedRealityImg";
import MobileImg from "images/MobileImg";
import BaseLineImg from "images/BaseLineImg";
import CloudImg from "images/CloudImg";
import SoftwareImg from "images/SoftwareImg";
import NetZeroImg from "images/NetZeroImg";
import FactoryImg from "images/FactoryImg";
import FootPrintImg from "images/FootPrintImg";
import HardwareImg from "images/HardwareImg";
import LeftArrowIcon from "images/LeftArrowIcon";
import RigthArrowIcon from "images/RigthArrowIcon";

const Services = () => {
  const navigate = useNavigate();

  const cardContainerRef = useRef(null);

  const handleLeftArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: -350, behavior: "smooth" });
  };

  const handleRightArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: 350, behavior: "smooth" });
  };

  const onClickLogin = () => {
    navigate("/signIn");
  };
  return (
    <div>
      <ServicesContainer className="background-3" id="productAndServices">
        <ServicesH1 className="colorwhite">Product & Services</ServicesH1>
        <CardContainer>
          <ArrowContainer onClick={handleLeftArrowClick}>
            <LeftArrowIcon />
          </ArrowContainer>
          <HubWrapper ref={cardContainerRef}>
            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="1"
            >
              {/* <ServicesIcon src={Icon1} /> */}
              <AugmentedRealityImg />
              <ServicesH2>
                Decimetrix<sup>®</sup> Augmented Reality
              </ServicesH2>
              <ServicesP>Training per user using our XR technology.</ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="2"
            >
              {/* <ServicesIcon src={Icon2} /> */}
              <MobileImg />
              <ServicesH2>
                Decimetrix<sup>®</sup> Digital Inventory App
              </ServicesH2>
              <ServicesP>
                Digital inventory of Facilities, Wells, and Systems.
              </ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="3"
            >
              {/* <ServicesIcon src={Icon3} /> */}
              <BaseLineImg />
              <ServicesH2>
                {" "}
                Decimetrix<sup>®</sup> Emissions Baseline{" "}
              </ServicesH2>
              <ServicesP>
                Emissions baseline creation for Wells & Facilities.
              </ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="4"
            >
              {/* <ServicesIcon src={Icon4} /> */}
              <CloudImg />
              <ServicesH2>Information & Data Management</ServicesH2>
              <ServicesP>
                On cloud or On-Premise service using elastic storage.
              </ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="5"
            >
              {/* <ServicesIcon src={Icon5} /> */}
              <SoftwareImg />
              <ServicesH2>Software as a Service (SaaS).</ServicesH2>
              <ServicesP>
                Customized software developed for our clients.
              </ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="6"
            >
              {/* <ServicesIcon src={Icon6} /> */}
              <HardwareImg />
              <ServicesH2> Hardware as a Sale or Service. </ServicesH2>
              <ServicesP>Detection and quantification of emissions.</ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="7"
            >
              {/* <ServicesIcon src={Icon7} /> */}
              <FootPrintImg />
              <ServicesH2>
                Decimetrix<sup>®</sup> Consulting{" "}
              </ServicesH2>
              <ServicesP>
                Consulting for the road to NET ZERO and carbon neutrality.
              </ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="8"
            >
              {/* <ServicesIcon src={Icon8} /> */}
              <FactoryImg />
              <ServicesH2>Field Repair & Validation</ServicesH2>
              <ServicesP>
                Repair & validation of emissions found for assets in the field.
              </ServicesP>
            </ServicesCard>

            <ServicesCard
              className="color1 background-card-color border-color-1"
              onClick={onClickLogin}
              id="9"
            >
              {/* <ServicesIcon src={Icon9} /> */}
              <NetZeroImg />
              <ServicesH2> Carbon Footprint Management</ServicesH2>
              <ServicesP>
                Management & estimation of carbon footprint levels.
              </ServicesP>
            </ServicesCard>
          </HubWrapper>
          <ArrowContainer onClick={handleRightArrowClick}>
            <RigthArrowIcon />
          </ArrowContainer>
        </CardContainer>
      </ServicesContainer>
    </div>
  );
};

export default Services;
