import { PRIMARY_COLOR } from "utils/const";

const CarbonChartLineStacked = ({ color = PRIMARY_COLOR, size = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <g fill="none">
        <path d="M0 0h48v48H0z" />
        <path
          fill={color}
          d="M6 41a1 1 0 0 0 1 1h34v-2H8v-.638l9.331-11.198l11.353 3.785a1 1 0 0 0 1.084-.309l10-12l-1.536-1.28l-9.563 11.476l-11.353-3.785a1 1 0 0 0-1.084.309L8 36.238v-6.851l9.289-10.218l10.37 3.77a1 1 0 0 0 1.11-.299l10-12l-1.537-1.28l-9.55 11.46l-10.34-3.76a1 1 0 0 0-1.082.267L8 26.413V7H6z"
        />
      </g>
    </svg>
  );
};

export default CarbonChartLineStacked;
