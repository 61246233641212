import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContainer,
  HubContainer,
  HubWrapper,
} from "pages/AdminCompany/Hub/HubStyles";
import FetchOperator from "hooks/fetchOperator";
import { roles } from "utils/roles";
import InfoHubOper from "./infoHubOperator";

function ModulesOper({ hideCards, userRole }) {
  const navigate = useNavigate();
  const operatorId = localStorage.getItem("operatorId");
  const { data: customer } = FetchOperator(operatorId);

  return (
    <HubContainer className="background-2 border-top-1">
      <CardContainer>
        {!hideCards && (
          <HubWrapper style={{ justifyContent: "center" }}>
            <InfoHubOper
              navigate={navigate}
              customer={customer}
              roles={roles}
            />
          </HubWrapper>
        )}
      </CardContainer>
    </HubContainer>
  );
}

export default ModulesOper;
