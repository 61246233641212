import { useState } from "react";
import { useSWRConfig } from "swr";
import { useDispatch } from "react-redux";
import { setShowOperationDetails } from "redux/actions";

import CustomizedButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
import deleteEvent from "services/deleteEvent";
import { config } from "config.js";

const DeleteButton = ({ eventId }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this event?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const handleClick = () => setOpenDialog(true);

  const handlerDeleteEvent = async () => {
    const { res } = await deleteEvent(eventId);
    if (res.status === 200) {
      dispatch(setShowOperationDetails(false));
      const urlOperations = `${config.URL_BACKEND_PG}api/v1/admin-company/events/${adminCompanyId}`;
      const urlAnemometers = `${config.URL_BACKEND_PG}api/v1/anemometer?adminCompanyId=${adminCompanyId}`;
      mutate(urlOperations);
      mutate(urlAnemometers);
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
      setContentDialog({
        title: "",
        description: "❌ Error to delete event, try again.",
        disagree: "Cancel",
      });
    }
  };

  return (
    <>
      <CustomizedButton
        text={"Delete event"}
        primaryColor={"#d83939"}
        secondaryColor={"#eb4123"}
        onClick={handleClick}
      />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={handlerDeleteEvent}
        content={contentDialog}
      />
    </>
  );
};

export default DeleteButton;
