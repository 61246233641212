import { WraperTitleCardMarker } from "components/ElementDetails/ImageDetailsElements";
import { Popup } from "react-map-gl";
import CustomButton from "components/Buttons/CustomButton";
import { setDatabaseRelationObject, setPopUpRelationObject } from "redux/actions/digitalTwin";
import { useDispatch, useSelector } from "react-redux";
import { PopUpmap } from "components/DigitalTwin/MapElements";
import { BLACK_COLOR, PRIMARY_COLOR } from "utils/const";

const PopUpObjects = ({
  handleSave,
  handleFinish,
  handleCancel,
  ObjectClick,
  setOpenPopUp,
}) => {
  const dispatch = useDispatch();

  const objetSelected = useSelector(
    (state) => state.digitalTwinReducer.objectRelationObject.objectId
  );

  // Información para el pop-up
  const infoObjectToRelate = {
    objectId: ObjectClick.id,
    objectName: ObjectClick.typeElement.name,
    userFirstName: ObjectClick.user?.adminCompany?.firstName,
    userLastName: ObjectClick.user?.adminCompany?.firstLastName,
    latitude: ObjectClick.location.latitude,
    longitude: ObjectClick.location.longitude,
  };

  const handleSavePopUp = async () => {
    await handleSave();
    setOpenPopUp(false);
  };

  const handleFinishPopUp = () => {
    handleFinish();
    setOpenPopUp(false);
    dispatch(setPopUpRelationObject({ showPopUp: false }));
    dispatch(setDatabaseRelationObject([]));
  };

  const handleClose = () => {
    setOpenPopUp(false);
    dispatch(setPopUpRelationObject({ showPopUp: false }));
  };

  return (
    <>
      {objetSelected.id !== ObjectClick.id && (
        <Popup
          longitude={infoObjectToRelate.longitude}
          latitude={infoObjectToRelate.latitude}
          anchor="bottom"
          onClose={handleClose}
          closeButton={true}
        >
          <div>
            <WraperTitleCardMarker style={{ color: BLACK_COLOR }}>
              <h3 className="title">Object Information</h3>
            </WraperTitleCardMarker>
            <hr />
            <PopUpmap>
              <p className="specification">
                <span className="label" style={{ color: PRIMARY_COLOR }}>
                  Object Id:
                </span>
                <span className="sub" style={{ color: BLACK_COLOR }}>
                  {infoObjectToRelate.objectId}
                </span>
              </p>

              <p className="specification">
                <span className="label" style={{ color: PRIMARY_COLOR }}>
                  Object Name:
                </span>
                <span className="sub" style={{ color: BLACK_COLOR }}>
                  {infoObjectToRelate.objectName}
                </span>
              </p>
              <hr />

              <p className="specification">
                <span className="label" style={{ color: PRIMARY_COLOR }}>
                  Created by:
                </span>
                <span className="sub" style={{ color: BLACK_COLOR }}>
                  {infoObjectToRelate.userFirstName}
                </span>{" "}
                <span style={{ color: BLACK_COLOR }}>
                  {infoObjectToRelate.userLastName}
                </span>
              </p>

              <div className="actions">
                <button className="button" onClick={handleSavePopUp}>
                  Save
                </button>

                <button className="button" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </PopUpmap>
            <br />

            <CustomButton
              text="Save and Quit"
              onClick={handleFinishPopUp}
              margin={0}
            />
          </div>
        </Popup>
      )}
    </>
  );
};

export default PopUpObjects;
