import { getMonthAbbreviation } from "helpers/getMonthAbbreviation";
import { getNumericValue } from "helpers/getNumericValue";

// Función para filtrar objetos por libraryId
function filterObjectsByLibraryId(objects, libraryId) {
  return objects.filter((obj) => obj.typeElement?.pointLibraryId === libraryId);
}

// Función para obtener y ordenar fechas únicas
function getSortedUniqueDates(filteredEvents, columnX, timeVariable) {
  const dates = [
    ...new Set(
      filteredEvents.map(
        (evt) =>
          evt.attributes
            .find((attr) => attr.name === columnX)
            ?.value.split(" ")[0]
      )
    ),
  ].filter((date) => date !== "" && date !== " ");

  return dates.sort((a, b) => {
    const [yearA, monthA, dayA] = a.split("-").map(Number);
    const [yearB, monthB, dayB] = b.split("-").map(Number);

    if (timeVariable === "year") {
      return yearA - yearB;
    } else if (timeVariable === "month") {
      return monthA - monthB;
    } else if (timeVariable === "day") {
      if (yearA !== yearB) return yearA - yearB;
      if (monthA !== monthB) return monthA - monthB;
      return dayA - dayB;
    }
    return 0;
  });
}

// Función para agrupar fechas según timeVariable
function groupDate(date, timeVariable) {
  if (date !== " " || date !== "") {
    const [year, month, day] = date.split("-");
    switch (timeVariable) {
      case "year":
        return year;
      case "month":
        return getMonthAbbreviation(month);
      default:
        return date;
    }
  }
}

// Función para agrupar labels
function getGroupedLabels(labels, timeVariable) {
  return [...new Set(labels.map((label) => groupDate(label, timeVariable)))];
}

// Función para procesar datasets
function processDatasets(filteredObjects, timeSeriesChart, groupedLabels) {
  return timeSeriesChart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: NaN }));

    filteredObjects.forEach((obj) => {
      const typeElementName = obj.attributes.find(
        (attr) => attr.name === timeSeriesChart.categories
      )?.value;
      if (typeElementName !== dataset.lineStyle?.label) return;

      const date = obj.attributes
        .find(
          (attr) =>
            attr.name === timeSeriesChart.columnX &&
            (attr.value !== " " || attr.value !== "")
        )
        ?.value.split(" ")[0];

      const groupedDate = groupDate(date, timeSeriesChart.timeVariable);
      const value = obj.attributes.find(
        (attr) => attr.name === timeSeriesChart.columnsY
      )?.value;

      const numericValue = getNumericValue(value);

      const index = dataByLabel.findIndex((item) => item.label === groupedDate);
      if (index !== -1) {
        if (isNaN(dataByLabel[index].value)) {
          dataByLabel[index].value = numericValue;
        } else {
          dataByLabel[index].value += numericValue;
        }
      }
    });

    if (dataset.lineStyle.constantValue) {
      return {
        ...dataset,
        data: new Array(dataByLabel.length).fill(
          dataset.lineStyle.constantValue
        ),
      };
    } else {
      return {
        ...dataset,
        data: dataByLabel.map((item) => item.value),
      };
    }
  });
}

// Función principal para procesar datos
export function sumValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    const labels = getSortedUniqueDates(
      filteredObjects,
      chart.columnX,
      chart.timeVariable
    );
    const groupedLabels = getGroupedLabels(labels, chart.timeVariable);
    const datasets = processDatasets(filteredObjects, chart, groupedLabels);

    return {
      ...chart,
      labels: groupedLabels,
      datasets,
    };
  });
}

// Función para procesar datasets contando ocurrencias
function processCountDatasets(filteredObjects, timeSeriesChart, groupedLabels) {
  return timeSeriesChart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: null }));

    filteredObjects.forEach((obj) => {
      const typeElementName = obj.attributes.find(
        (attr) => attr.name === timeSeriesChart.categories
      )?.value;
      if (typeElementName !== dataset.lineStyle?.label) return;

      const date = obj.attributes
        .find(
          (attr) =>
            attr.name === timeSeriesChart.columnX &&
            (attr.value !== " " || attr.value !== "")
        )
        ?.value.split(" ")[0];

      const groupedDate = groupDate(date, timeSeriesChart.timeVariable);

      const index = dataByLabel.findIndex((item) => item.label === groupedDate);
      if (index !== -1) {
        dataByLabel[index].value += 1;
      }

      if (index !== -1) {
        if (dataByLabel[index].value === null) {
          dataByLabel[index].value = null;
        } else {
          dataByLabel[index].value += 1;
        }
      }
    });

    if (dataset.lineStyle.constantValue) {
      return {
        ...dataset,
        data: new Array(dataByLabel.length).fill(
          dataset.lineStyle.constantValue
        ),
      };
    } else {
      return {
        ...dataset,
        data: dataByLabel.map((item) => item.value),
      };
    }
  });
}

// Función principal para procesar datos con conteo
export function countByValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    const labels = getSortedUniqueDates(
      filteredObjects,
      chart.columnX,
      chart.timeVariable
    );
    const groupedLabels = getGroupedLabels(labels, chart.timeVariable);
    const datasets = processCountDatasets(
      filteredObjects,
      chart,
      groupedLabels
    );
    return {
      ...chart,
      labels: groupedLabels,
      datasets,
    };
  });
}
