import React, { useRef, useState } from "react";
import useGet from "hooks/useFetch";
import { useNavigate } from "react-router-dom";
import Layout from "components/Layout/AdminDecimetrix";
import DescriptionHub from "pages/AdminCompany/Hub/DescriptionHub";
import CustomTabs from "pages/AdminCompany/Hub/CustomTabs";
import {
  HubH2,
  HubP,
  CardContainer,
  HubCard,
  HubContainer,
  HubWrapper,
} from "pages/AdminCompany/Hub/HubStyles";
import contentCardsTools from "./contentCardsTools";

function ContencCards({ hideCards }) {
  const cardContainerRef = useRef(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - cardContainerRef.current.offsetLeft);
    setScrollLeft(cardContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const [adminCompany, errorAdminCompany] = useGet(
    `api/v1/admin-company/${adminCompanyId}`
  );

  return (
    <>
      <Layout>
        <DescriptionHub adminCompany={adminCompany} />
        <CustomTabs value={value} onChange={handleChange} />
        <HubContainer className="background-2 border-top-1">
          <CardContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {!hideCards && (
              <>
                <HubWrapper
                  ref={cardContainerRef}
                  style={{ justifyContent: "center" }}
                >
                  {contentCardsTools.map((item) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <HubCard
                        className="background-card-color"
                        key={item.id}
                        onClick={() => navigate(item.toNavigate)}
                      >
                        <HubH2>{item.title}</HubH2>
                        {item.image}
                        <HubP>{item.description}</HubP>
                      </HubCard>
                    </div>
                  ))}
                </HubWrapper>
              </>
            )}
          </CardContainer>
        </HubContainer>
      </Layout>
    </>
  );
}

export default ContencCards;
