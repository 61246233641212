import { useState, useEffect, useCallback } from "react";

// Helpers
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/eventsTimeSeriesChart";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { config } from "config";

export const useFindTimeSeriesChartEvents = ({
  openDialog,
  events,
  loading,
}) => {
  const [charts, setCharts] = useState(null);
  const [timeSeriesCharts, setTimeSeriesChart] = useState(null);
  const [eventsFormat, setEventsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlTimeSeriesChart = `${config.URL_BACKEND_MG}time-series-chart?adminCompanyId=${adminCompanyId}&from=events`;
  const { data: dataTimeSeriesChart, error: errorDataTimeSeriesChart } =
    useSwr(urlTimeSeriesChart);

  useEffect(() => {
    if (!errorDataTimeSeriesChart && dataTimeSeriesChart)
      setCharts(dataTimeSeriesChart);
  }, [dataTimeSeriesChart, errorDataTimeSeriesChart, openDialog, reloadCharts]);

  useEffect(() => {
    const eventsFormat = createRowsEvents({ events });
    setEventsFormat(eventsFormat);
  }, [events]);

  const getDataTimeSeriesChart = useCallback(async () => {
    if (!loading && eventsFormat) {
      const lineCountsvalues = countByValues({
        charts,
        events: eventsFormat,
      });

      const timeSeriesSumValues = sumValues({
        charts,
        events: eventsFormat,
      });

      setTimeSeriesChart([...lineCountsvalues, ...timeSeriesSumValues]);
    }
  }, [loading, eventsFormat, charts]);

  useEffect(() => {
    if (charts && events) {
      getDataTimeSeriesChart();
    }
  }, [charts, events, getDataTimeSeriesChart]);

  return { timeSeriesCharts };
};
