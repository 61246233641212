// React
import { useCallback, useEffect, useState } from "react";
// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFlyTo, setRowNum } from "redux/actions/digitalTwin";
// Components
import GenericTable from "components/Tables/GenericTable/GenericTable";
// Dependecies
import moment from "moment-timezone";
// Styles
import { SearchPosition } from "./styles";
// Hooks
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import useShowDetails from "hooks/useShowDetails";
// Const
import { keyValuesObjects, headersObjects } from "./keyAndHeadersTables";
import { FROM_TABLE } from "utils/constStrings";
import { replaceDotsWithUnderscores } from "helpers/removeAccents";

/**
 * Generate the function comment for the given function body.
 *
 * @param {Object} props - An object containing the function's props.
 * @param {string} props.pointLibrary - The point library.
 * @param {function} props.setRows - The function to set the rows.
 * @param {string} props.attributte - The attribute.
 * @param {function} props.setLabels - The function to set the labels.
 * @param {string} props.label - The label.
 * @param {function} props.setTableVisibility - The function to set the table visibility.
 * @param {boolean} props.tableVisibility - The table visibility.
 * @param {function} props.handleClose - The function to handle closing.
 * @param {string} props.typeOfTable - The type of table.
 * @param {string} props.typeOfLibrary - The type of library.
 * @param {function} props.handleDrawerClose - The function to handle drawer closing.
 * @param {function} props.handleDrawerOpen - The function to handle drawer opening.
 * @param {boolean} [props.state=false] - The state.
 * @return {JSX.Element} The JSX element.
 */
export default function TableObjects({
  pointLibrary,
  setRows: setRowsMain,
  attributte,
  setLabels,
  label,
  setTableVisibility,
  tableVisibility,
  handleClose,
  typeOfTable,
  typeOfLibrary,
  handleDrawerClose,
  handleDrawerOpen,
  state = false,
  orderColumns,
  dataChecked,
  setDataChecked,
}) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon,
    shallowEqual
  );

  const dataEventPolygon = useSelector(
    (state) => state.adminReducer.dataEventPolygon,
    shallowEqual
  );
  const dataComponentPolygon = useSelector(
    (state) => state.adminReducer.dataComponentPolygon,
    shallowEqual
  );

  const rowNumber = useSelector((state) => state.digitalTwinReducer.rowNum);

  const map = useSelector((state) => state.digitalTwinReducer.map);
  const flyToRedux = useSelector((state) => state.digitalTwinReducer.flyTo);

  const { data: dataLibrary, error: errorLibrary } = useFetchObjectLibraries({
    id: pointLibrary,
  });

  useEffect(() => {
    setRows([]);
  }, [pointLibrary]);

  const { handlerClickObjectId } = useShowDetails();

  const handlerClickTable = (id) => {
    handlerClickObjectId(id);
    localStorage.setItem("currentId", id);
  };

  const removeLineBreak = (text) => {
    if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
    if (text && isNaN(text) && typeof text !== "object") {
      return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
    }
    return text;
  };

  const markPoint = useCallback(
    (id, lat, lng) => {
      if (state) {
        const data = {
          id,
          lat,
          lng,
        };
        const dataMarketPoint = JSON.stringify(data);
        localStorage.setItem("dataMarketPoint", dataMarketPoint);
        setTimeout(() => {
          const setData = {
            id: "",
            lat: "",
            lng: "",
          };
          const setDataMarketPoint = JSON.stringify(setData);
          localStorage.setItem("dataMarketPoint", setDataMarketPoint);
        }, 7000);
      } else {
        dispatch(
          setFlyTo({
            ...flyToRedux,
            object: {
              id,
              latitude: parseFloat(lat),
              longitude: parseFloat(lng),
            },
          })
        );
        setTableVisibility("none");
        setTimeout(() => {
          dispatch(
            setFlyTo({
              ...flyToRedux,
              object: {
                id: "",
                latitude: "",
                longitude: "",
              },
            })
          );
        }, 5000);
      }
    },
    [dispatch, flyToRedux, state, setTableVisibility]
  );

  const flyTo = useCallback(
    (e, id) => {
      const { value } = e.target;
      const [lng, lat] = value.split(",");
      if (state) {
        const coordinates = [lng, lat];
        const parseCoordinates = JSON.stringify(coordinates);
        localStorage.setItem("coordinates", parseCoordinates);
        markPoint(id, lat, lng);
      } else {
        map.flyTo({
          center: [parseFloat(lng), parseFloat(lat)],
          duration: 2000,
          zoom: 24,
        });
        markPoint(id, lat, lng);
        setTableVisibility("none");
      }
    },
    [map, markPoint, state, setTableVisibility]
  );

  const createRows = useCallback(
    (data) => {
      const newDataArray = data?.map((dataItem) => {
        let attributesCopy = [...dataItem.attributes];

        const res = attributesCopy.map((newData) => {
          const elm = dataLibrary?.[0]?.fields?.find((item) => {
            return (
              item?.name === newData?.name ||
              item?.alias === newData?.name ||
              item?.name === newData?.alias ||
              item?.alias === newData?.alias
              );
            });
          if( elm && elm.QAQC && !elm.alias.includes("✅")){
            return elm.alias = elm.alias + "✅"
          }
          if (elm) {
            if (elm.type !== "captureMedia" && elm.type !== "notification") {
              if (elm.type === "number" || elm.type === "operation") {
                if (newData.value && newData.value[0] === "{") {
                  const valueParse = JSON.parse(newData.value);
                  if (valueParse.value === "") {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: NaN,
                      _id: newData._id,
                    };
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: valueParse.value,
                      _id: newData._id,
                    };
                  }
                } else {
                  if (newData.value && !isNaN(newData.value)) {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: parseFloat(newData.value),
                      _id: newData._id,
                    };
                  }
                }
              } else {
                if (elm.type === "date" && !newData.value === !NaN) {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: "0000-00-00",
                    _id: newData._id,
                  };
                } else {
                  if (!newData.value === !NaN || newData.value === " ") {
                    if (elm.type === "date") {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "0000-00-00",
                        _id: newData._id,
                      };
                    } else {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "Undefined data",
                        _id: newData._id,
                      };
                    }
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: newData.value,
                      _id: newData._id,
                    };
                  }
                }
              }
            }
          }
          return {
            ...newData,
            alias: newData.name,
          };
        });

        const allFields = dataLibrary?.[0]?.fields
          ?.map((elm) => {
            if (elm.type !== "captureMedia" && elm.type !== "notification") {
              return {
                name: elm.name,
                alias: elm.alias,
                type: elm.type,
              };
            }
          })
          .filter((it) => it !== undefined);

        const matchMissingAttributes = allFields?.filter((elm) => {
          return res.every((item) => {
            return item.name !== elm.name;
          });
        });

        if (matchMissingAttributes?.length > 0) {
          matchMissingAttributes.forEach((elm) => {
            if (elm.type === "date") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "0000-00-00",
              });
            } else if (elm.type === "number" || elm.type === "operation") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: NaN,
              });
            } else {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "Undefined data",
              });
            }
          });
        }

        return { ...dataItem, attributes: res };
      });

      const rows = newDataArray.map((item) => {
        const convertedTime = moment
          .tz(item.date, "UTC")
          .utcOffset(item.timeZoneOffset || "-05:00")
          .tz(item.timeZone || "America/Bogota");
        // const date = new Date(item.date);
        const { location, id } = item;
        const { latitude, longitude } = location;

        const totalComponents = dataComponentPolygon?.filter(
          (component) => component.objectId === item.id
        )?.length;

        const totalEvents = dataEventPolygon?.filter(
          (event) => event.objectId === item.id
        )?.length;

        const row = {
          id: removeLineBreak(item.id),
          flyTo: (
            <button
              className="button"
              value={`${longitude},${latitude}`}
              onClick={(e) => flyTo(e, id)}
            >
              Fly
            </button>
          ),
          markPoint: (
            <button
              className="button"
              onClick={() => markPoint(id, latitude, longitude)}
            >
              Flash
            </button>
          ),
          typeElementName: removeLineBreak(item.typeElement.name),
          localDate: removeLineBreak(
            convertedTime.format("YYYY-MM-DD HH:mm:ss z").substring(0, 19)
          ),
          dateUTC: removeLineBreak(item.date.substring(0, 10)),
          userName: removeLineBreak(
            (item.user &&
              item.user.adminCompany &&
              item.user.adminCompany.firstName +
                " " +
                item.user.adminCompany.firstLastName) ||
              (item.user &&
                item.user.operator &&
                item.user.operator.firstName +
                  " " +
                  item.user.operator.firstLastName) ||
              (item.user &&
                item.user.adminDecimetrix &&
                item.user.adminDecimetrix.firstName +
                  " " +
                  item.user.adminDecimetrix.firstLastName)
          ),
          typeElementId: removeLineBreak(item.typeElementId),
          latitude: removeLineBreak(item.location.latitude),
          longitude: removeLineBreak(item.location.longitude),
          totalComponents,
          totalEvents,
        };

        if (item.attributes.length !== 0) {
          item.attributes.forEach((object) => {
            try {
              if (typeof JSON.parse(object.value) === "object") {
                const value = JSON.parse(object.value);
                row[replaceDotsWithUnderscores(object.alias)] = parseFloat(
                  value.value
                );
              } else {
                return (row[replaceDotsWithUnderscores(object.alias)] = !isNaN(
                  object.value
                )
                  ? parseFloat(object.value)
                  : object.value);
              }
            } catch (error) {
              return (row[replaceDotsWithUnderscores(object.alias)] = !isNaN(
                object.value
              )
                ? parseFloat(object.value)
                : object.value);
            }
          });
        }

        for (const key in row) {
          if (attributte === "") return row;
          if (!row[key] || typeof row[key] === "object") continue;
          if (label !== "all" && key !== label) continue;

          const value = isNaN(row[key]) ? row[key] : row[key].toString();
          try {
            if (value.toUpperCase().includes(attributte.toUpperCase())) {
              return row;
            }
          } catch (error) {
            console.error(attributte);
          }
        }
        return null;
      });
      const rowsFilter = rows.filter((item) => item !== null);

      return rowsFilter;
    },
    [
      attributte,
      label,
      flyTo,
      markPoint,
      dataLibrary,
      dataComponentPolygon,
      dataEventPolygon,
      errorLibrary,
      rowNumber,
    ]
  );

  const updateLabels = useCallback(() => {
    const labels = {};
    for (const key in keyValuesObjects) {
      labels[keyValuesObjects[key]] = headersObjects[key];
    }
    setLabels(labels);
  }, [setLabels]);

  useEffect(() => {
    const headers = [];
    headersObjects.forEach((item, index) => {
      const header = {
        label: item,
        key: keyValuesObjects[index],
      };
      headers.push(header);
    });
  }, []);

  useEffect(() => {
    if (!pointLibrary) return;
    const newDataObject = dataObjectsPolygon.filter((data) => {
      return data?.typeElement?.pointLibraryId === pointLibrary;
    });

    const rows = createRows(newDataObject);
    setRows(() => [...rows]);
    setRowsMain(() => [...rows]);
    updateLabels();
  }, [
    dataObjectsPolygon,
    pointLibrary,
    setRowsMain,
    updateLabels,
    dataLibrary,
    rowNumber,
  ]);

  useEffect(() => {
    dispatch(setRowNum(rows.length));
  }, [rows.length, dispatch]);
  return (
    <div className="body">
      {rows.length > 0 && (
        <SearchPosition>
          <GenericTable
            headers={headersObjects}
            rows={rows}
            orderColumns={orderColumns}
            rowsDynamic={rows}
            typeOfTable={typeOfTable}
            typeOfLibrary={typeOfLibrary}
            keyValues={keyValuesObjects}
            handlerClick={handlerClickTable}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
            handleClose={handleClose}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            style={{
              small: true,
              maxHeight: state ? 100 : 50,
              width: 55,
            }}
            digitalTwin={true}
            state={state}
            pointLibraryId={pointLibrary}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
            from={FROM_TABLE.digitalTwin}
          />
        </SearchPosition>
      )}
    </div>
  );
}
