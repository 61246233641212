import AccordionRender from "./AccordionRenderTypesEvents";
import useGet from "hooks/useFetch.js";

/**
 * Renders a list of checklists components based on the provided data.
 *
 * @param {Object} typeEventsTypeEvents - The type events object.
 * @param {Function} handleChangeCheckEvent - The event handler for changing the check event.
 * @return {JSX.Element} The rendered checklists components.
 */
const CheckListsComponents = ({
  typeEventsTypeEvents,
  handleChangeCheckEvent,
  seeForm,
}) => {
  const [pointLibraryEvents] = useGet("api/v1/point-library-events");
  const [pointTypeEvents] = useGet("api/v1/point-type-events");

  const res =
    pointTypeEvents &&
    !pointTypeEvents.error &&
    pointTypeEvents.map((elm) => {
      return elm.pointLibraryEventId;
    });

  const idTypes =
    res &&
    !res.error &&
    res.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
      }}
    >
      {pointLibraryEvents &&
        !pointLibraryEvents.error &&
        pointLibraryEvents
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((library, key) =>
            idTypes && !idTypes.error && idTypes.includes(library.id) ? (
              <AccordionRender
                handleChangeCheckEvent={handleChangeCheckEvent}
                typeEventsTypeEvents={typeEventsTypeEvents}
                key={key}
                library={library}
                seeForm={seeForm}
              />
            ) : (
              <></>
            )
          )}
    </div>
  );
};

export default CheckListsComponents;
