import { useEffect } from "react";
import Container from "@mui/material/Container";
import Loading from "components/Lodings/LoadingV2";

import AdminTabs from "./AdminTabs";

import useGet from "hooks/useFetch";
import Layout from "components/Layout/AdminDecimetrix";
import DescriptionHub from "./DescriptionHub";

const Admin = () => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [adminCompany, errorAdminCompany] = useGet(
    `api/v1/admin-company/${adminCompanyId}`
  );

  useEffect(() => {
    const persist = localStorage.getItem("persist:root");
    if (persist !== null) {
      localStorage.removeItem("persist:root");
    }
  }, []);

  return (
    <Layout>
      <Container
        className="background-1"
        maxWidth="false"
        style={{
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        {!errorAdminCompany && adminCompany ? (
          <>
            <DescriptionHub adminCompany={adminCompany} />
            <AdminTabs />
          </>
        ) : (
          <Loading />
        )}
      </Container>
    </Layout>
  );
};

export default Admin;
