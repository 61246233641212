import axios from 'axios';
import { config } from 'config.js';


/**
 * Creates a new date filter.
 * 
 * @param {Object} body - The request body.
 *  @property {string} date - The date to filter.
 * @returns {Promise<Object>} The response data from the API.
 */

const saveDate = async (body) => {

  const token = localStorage.getItem('token');
  let url = `${config.URL_BACKEND_PG}api/v1/date-filter`;
  const result = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
  const data = await result.data;
  return data;
}

export default saveDate;