import axios from "axios";

async function createOrUpdateSettingMap(newSettings) {
  try {
    const token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_URL_BACKEND}api/v1/setting-map`;
    const result = await axios.post(url, newSettings, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.error("🚩🚩Error on Create or Update new setting: ", error);
    return { error };
  }
}

export default createOrUpdateSettingMap;
