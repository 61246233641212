import React from "react";

const EventIcon = ({ width, height }) => {
  return (
    <div style={{ marginBottom: "2px" }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 296.000000 296.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,296.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            fill="#e44040"
            d="M1360 2634 c-494 -63 -871 -392 -1001 -874 -21 -75 -24 -108 -24
-280 0 -172 3 -205 23 -278 121 -446 453 -764 892 -858 103 -22 344 -25 435
-5 174 37 298 88 432 177 241 160 405 393 484 684 21 75 24 108 24 280 0 183
-2 201 -28 294 -118 424 -449 743 -866 837 -84 19 -299 32 -371 23z m278 -260
c173 -35 328 -120 455 -248 38 -39 73 -71 78 -71 4 0 11 -4 16 -9 5 -5 2 -6
-6 -1 -9 5 -12 3 -9 -6 3 -8 10 -13 16 -12 5 1 9 -5 8 -15 0 -9 4 -16 10 -15
7 1 11 -5 10 -15 0 -9 5 -17 13 -17 8 0 16 -7 18 -15 4 -13 3 -13 -6 0 -20 28
-11 0 18 -58 15 -31 34 -74 40 -95 8 -22 17 -34 23 -31 7 4 8 2 4 -4 -4 -6 0
-45 9 -86 19 -97 19 -276 -1 -374 -53 -264 -237 -502 -479 -624 -107 -54 -212
-80 -346 -85 -77 -4 -153 -1 -195 6 -266 47 -504 223 -632 468 -72 138 -102
262 -102 417 0 259 85 465 267 649 129 130 296 217 471 247 82 13 239 11 320
-6z m719 -736 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m10 -80
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"
          />
          <path
            fill="#000000"
            d="M984 1657 c-2 -7 -3 -89 -2 -182 l3 -170 133 -3 132 -3 0 35 0 35
-97 3 -98 3 0 35 1 35 84 5 85 5 0 30 0 30 -87 3 -88 3 0 39 0 40 95 0 c92 0
95 1 101 24 3 14 3 30 -1 35 -10 17 -254 15 -261 -2z"
          />
          <path
            fill="#000000"
            d="M1290 1658 c0 -7 31 -89 68 -183 l68 -170 39 0 39 0 67 170 c38 94
68 176 69 183 0 7 -13 12 -34 12 l-34 0 -52 -131 c-29 -72 -56 -127 -60 -122
-3 4 -28 63 -55 131 l-48 122 -34 0 c-20 0 -33 -5 -33 -12z"
          />
          <path
            fill="#000000"
            d="M1670 1635 l0 -35 50 0 49 0 3 -147 3 -148 35 0 35 0 3 148 3 147 49
0 50 0 0 35 0 35 -140 0 -140 0 0 -35z"
          />
        </g>
      </svg>
    </div>
  );
};

export default EventIcon;
