import { getNumericValue } from "helpers/getNumericValue";
import hexToRGB from "helpers/hexToRGB";
function getAllUniqueValues(filteredEvents, columnXName) {
  const uniqueValues = new Set();
  filteredEvents.forEach((evnt) => {
    evnt.attributes.forEach((attr) => {
      if (attr.name === columnXName) {
        uniqueValues.add(attr.value);
      }
    });
  });
  return Array.from(uniqueValues);
}

function filterEventsByLibraryId(events, libraryId) {
  return events.filter(
    (evnt) => evnt.pointTypeEvent.pointLibraryEventId === libraryId
  );
}

function filterComponents(events, filters) {
  return events.filter((evnt) => {
    return filters.every((filter) => {
      return evnt.attributes.some((attr) => {
        return attr.name === filter.name && filter.values.includes(attr.value);
      });
    });
  });
}

function transformToChartFormat(result) {
  const entries = Object.entries(result);
  entries.sort((a, b) => b[1] - a[1]);

  const labels = entries.map((entry) => entry[0]);
  const data = entries.map((entry) => entry[1]);

  return { labels, data };
}

export function count({ events, barCharts }) {
  return barCharts.map((barChart) => {
    const filteredByLibraryId = filterEventsByLibraryId(
      events,
      barChart.libraryId
    );
    const filteredByAttributes = filterComponents(
      filteredByLibraryId,
      barChart.columnsValuesFilter
    );
    const columnXName = barChart.columnX.name;
    const columnXValues = barChart.columnX.values;
    const result = columnXValues.reduce((acc, value) => {
      acc[value] = 0;
      return acc;
    }, {});

    filteredByAttributes.forEach((evnt) => {
      evnt.attributes.forEach((attr) => {
        if (attr.name === columnXName) {
          if (columnXValues.length === 0) {
            if (!result[attr.value]) result[attr.value] = 0;
            result[attr.value]++;
          } else if (columnXValues.includes(attr.value)) {
            result[attr.value]++;
          }
        }
      });
    });

    const data = transformToChartFormat(result);
    const dataSeriesConstant = barChart.dataSeriesConstant.map((elm) => {
      return {
        ...elm,
        data: new Array(data.data.length).fill(elm.constantValue),
      };
    });

    if (barChart.barStyle.colorsByLabel.length > 0) {
      return {
        ...barChart,
        labels: data.labels,
        datasets: [
          {
            _id: "bar_data",
            data: data.data.map((item, index) => {
              return {
                value: item,
                background: barChart.barStyle.colorsByLabel[index],
              };
            }),
            ...barChart.barStyle,
          },
        ],
        dataSeriesConstant,
      };
    } else {
      return {
        ...barChart,
        labels: data.labels,
        datasets: [
          {
            _id: "bar_data",
            data: data.data,
            ...barChart.barStyle,
          },
        ],
        dataSeriesConstant,
      };
    }
  });
}

export function sum({ events, barCharts }) {
  return barCharts.map((barChart) => {
    const filteredByLibraryId = filterEventsByLibraryId(
      events,
      barChart.libraryId
    );

    const filteredByAttributes = filterComponents(
      filteredByLibraryId,
      barChart.columnsValuesFilter
    );

    const columnXName = barChart.columnX.name;
    let columnXValues = barChart.columnX.values;
    const columnsY = barChart.columnsY;

    if (columnXValues.length === 0) {
      columnXValues = getAllUniqueValues(filteredByAttributes, columnXName);
    }

    const result = columnXValues.reduce((acc, value) => {
      acc[value] = 0;
      return acc;
    }, {});

    filteredByAttributes.forEach((evnt) => {
      let xValue = null;
      let yValue = 0;

      evnt.attributes.forEach((attr) => {
        if (attr.name === columnXName && columnXValues.includes(attr.value)) {
          xValue = attr.value;
        }
        if (columnsY.includes(attr.name)) {
          yValue = getNumericValue(attr.value);
        }
      });

      if (xValue !== null) {
        result[xValue] += yValue;
      }
    });

    const data = transformToChartFormat(result);
    const dataSeriesConstant = barChart.dataSeriesConstant.map((elm) => {
      return {
        ...elm,
        data: new Array(data.data.length).fill(elm.constantValue),
      };
    });

    if (barChart.barStyle.colorsByLabel.length > 0) {
      return {
        ...barChart,
        labels: data.labels,
        datasets: [
          {
            _id: "bar_data",
            data: data.data.map((item, index) => {
              return {
                value: item,
                background: barChart.barStyle.colorsByLabel[index],
              };
            }),
            ...barChart.barStyle,
          },
        ],
        dataSeriesConstant,
      };
    } else {
      return {
        ...barChart,
        labels: data.labels,
        datasets: [
          {
            _id: "bar_data",
            data: data.data,
            ...barChart.barStyle,
          },
        ],
        dataSeriesConstant,
      };
    }
  });
}
