const handleRemoveGridRow = ({
  index,
  setDragAndDroll,
  setCurrentTemplate,
}) => {
  setDragAndDroll(true);
  setCurrentTemplate((current) => ({
    ...current,
    chartsPosition: current.chartsPosition.map((elm) => {
      if (elm.index === index) {
        return {
          ...elm,
          gridRowStart:
            elm.gridRowStart[5] > 1
              ? `span ${parseInt(elm.gridRowStart.split(" ")[1]) - 1}`
              : elm.gridRowStart,
        };
      }
      return elm;
    }),
  }));
};

export default handleRemoveGridRow;
