import React from "react";
import { PRIMARY_COLOR } from "utils/const";

const HardwareImg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="200"
        height="200"
        viewBox="0 0 888 552.12922"
      >
        <title>drone_surveillance</title>
        <path
          d="M479.90234,562.18555a3.86767,3.86767,0,0,0-3.86279,3.86328V720.36328a3.86767,3.86767,0,0,0,3.86279,3.86328h26.1958a3.86768,3.86768,0,0,0,3.8628-3.86328V566.04883a3.86768,3.86768,0,0,0-3.8628-3.86328Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <path
          d="M697.74121,562.18555a3.86809,3.86809,0,0,0-3.86328,3.86328V720.36328a3.86809,3.86809,0,0,0,3.86328,3.86328h26.19531a3.86809,3.86809,0,0,0,3.86328-3.86328V566.04883a3.86809,3.86809,0,0,0-3.86328-3.86328Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <polygon
          points="664.127 265.647 463.273 250.14 623.513 277.462 661.912 270.816 664.127 265.647"
          fill="#3f3d56"
        />
        <polygon
          points="624.99 275.031 663.389 268.385 663.766 267.505 665.604 267.647 663.389 272.816 624.99 279.462 464.75 250.14 512.237 255.806 624.99 275.031"
          fill={PRIMARY_COLOR}
        />
        <polygon
          points="209.251 265.647 8.396 250.14 168.637 277.462 207.036 270.816 209.251 265.647"
          fill="#3f3d56"
        />
        <polygon
          points="166.422 275.031 204.82 268.385 205.198 267.505 207.036 267.647 204.82 272.816 166.422 279.462 6.181 250.14 53.668 255.806 166.422 275.031"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M854.09532,520.07184l-16.984.73844-155.5958,65.6469L672.61,593.91541l-28.23038,23.62994H555.76731l-26.68708-23.62994-10.1461-8.9868-149.25261-63.3799-13.29184-1.47687,44.30614-21.41463h.23631c10.0427-9.53323,97.01568-16.984,202.8335-16.984s192.79079,7.45079,202.8335,16.984h.97474Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <path
          d="M399.47889,499.75191a8.27564,8.27564,0,0,0,.47852-2.75769V461.92146a8.30885,8.30885,0,0,0-8.30884-8.30883h-3.37366a19.93774,19.93774,0,1,0-28.32556,0h-3.37359a8.30881,8.30881,0,0,0-8.30884,8.30883v35.07276a8.26212,8.26212,0,0,0,1.08465,4.09521c-2.50775.4397-5.01556.917-7.52331,1.46857a6.877,6.877,0,0,0-5.37634,6.72424v8.33295a6.88724,6.88724,0,0,0,6.8872,6.88727H421.372V503.82625A177.61335,177.61335,0,0,0,399.47889,499.75191Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <path
          d="M861.27235,502.558c-2.50775-.55157-5.0155-1.02893-7.52325-1.46857a8.26212,8.26212,0,0,0,1.08465-4.09521V461.92146a8.30881,8.30881,0,0,0-8.30884-8.30883h-3.37359a19.93778,19.93778,0,1,0-28.32563,0H811.4521a8.30881,8.30881,0,0,0-8.30884,8.30883v35.07276a8.27564,8.27564,0,0,0,.47852,2.75769,177.61335,177.61335,0,0,0-21.89313,4.07434v20.67621h78.03284a6.88724,6.88724,0,0,0,6.8872-6.88727v-8.33295A6.877,6.877,0,0,0,861.27235,502.558Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <polygon
          points="681.85 265.647 882.704 250.14 722.464 277.462 684.065 270.816 681.85 265.647"
          fill="#3f3d56"
        />
        <path
          d="M615.6533,610.89943H584.4936a8.78851,8.78851,0,0,0-8.78853,8.78852v6.79128a8.78369,8.78369,0,0,0,5.68218,8.213,8.58909,8.58909,0,0,0-1.99,5.50911v1.94786a8.61086,8.61086,0,0,0,3.69218,7.06972v25.92439h33.968V649.21894a8.61088,8.61088,0,0,0,3.69218-7.06972v-1.94786a8.58909,8.58909,0,0,0-1.99-5.50911,8.78369,8.78369,0,0,0,5.68218-8.213V619.688A8.78851,8.78851,0,0,0,615.6533,610.89943Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <path
          d="M568.40771,671.47461a5.239,5.239,0,0,0-5.23291,5.23242v41.917a5.23917,5.23917,0,0,0,5.23291,5.2334h63.33155a5.23907,5.23907,0,0,0,5.23242-5.2334V676.707a5.23887,5.23887,0,0,0-5.23242-5.23242Z"
          transform="translate(-156 -173.93539)"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M672.61,593.91541l-28.23038,23.62994H555.76731l-26.68708-23.62994h26.53938l1.15935-5.782a7.50328,7.50328,0,0,1,7.35484-6.033h74.09461a7.50326,7.50326,0,0,1,7.35483,6.033l1.15935,5.782Z"
          transform="translate(-156 -173.93539)"
          fill={PRIMARY_COLOR}
        />
        <circle cx="444.07345" cy="523.73023" r="20.6762" fill="#3f3d56" />
        <circle cx="444.07345" cy="523.73023" r="11.81497" fill="#e6e6e6" />
        <path
          d="M607.21506,688.26905a11.79947,11.79947,0,0,1-16.538,16.53825,11.80444,11.80444,0,1,0,16.538-16.53825Z"
          transform="translate(-156 -173.93539)"
          fill="#fff"
        />
        <rect
          x="180.8211"
          y="337.43041"
          width="530.19637"
          height="3.38212"
          fill="#e6e6e6"
        />
        <polygon
          points="723.941 275.031 685.542 268.385 685.165 267.505 683.327 267.647 685.542 272.816 723.941 279.462 884.181 250.14 836.694 255.806 723.941 275.031"
          fill={PRIMARY_COLOR}
        />
        <polygon
          points="226.973 265.647 427.828 250.14 267.587 277.462 229.189 270.816 226.973 265.647"
          fill="#3f3d56"
        />
        <polygon
          points="269.803 275.031 231.404 268.385 231.027 267.505 229.189 267.647 231.404 272.816 269.803 279.462 430.043 250.14 382.556 255.806 269.803 275.031"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="415.64368"
          y="414.44175"
          width="59.07485"
          height="11.81497"
          fill="#e6e6e6"
        />
        <path
          d="M411.21655,173.93539H232.14569a23.99169,23.99169,0,0,0-23.9646,23.96455v95.07091a23.9917,23.9917,0,0,0,23.9646,23.96454H411.21655a23.9917,23.9917,0,0,0,23.96454-23.96454V197.89994a23.99169,23.99169,0,0,0-23.96454-23.96455Z"
          transform="translate(-156 -173.93539)"
          fill="#e6e6e6"
        />
        <path
          d="M232.14546,193.43538a4.46908,4.46908,0,0,0-4.46436,4.46436V292.971a4.46908,4.46908,0,0,0,4.46436,4.46436H411.21675a4.46908,4.46908,0,0,0,4.46435-4.46436V197.89974a4.46908,4.46908,0,0,0-4.46435-4.46436Z"
          transform="translate(-156 -173.93539)"
          fill="#fff"
        />
        <path
          d="M318.05769,287.4132q-.09163,0-.18408-.00512a3.229,3.229,0,0,1-3.0025-2.693l-10.36384-62.18336-9.15078,36.60313a3.22909,3.22909,0,0,1-3.13268,2.44593h-12.9162a3.229,3.229,0,1,1,0-6.4581H289.7027l12.30464-49.21887a3.229,3.229,0,0,1,6.31777.25227l10.56143,63.36824,8.98109-31.434a3.229,3.229,0,0,1,6.16809-.134l5.72218,17.16635h10.58882a3.22905,3.22905,0,0,1,0,6.4581H337.43051a3.229,3.229,0,0,1-3.0633-2.20795l-3.1575-9.47223L321.161,285.07123A3.22925,3.22925,0,0,1,318.05769,287.4132Z"
          transform="translate(-156 -173.93539)"
          fill={PRIMARY_COLOR}
        />
        <circle cx="201.78353" cy="84.46667" r="9.50011" fill="#3f3d56" />
        <path
          d="M689.21655,173.93539H510.14563a23.99169,23.99169,0,0,0-23.96454,23.96455v95.07091a23.9917,23.9917,0,0,0,23.96454,23.96454H689.21655a23.99168,23.99168,0,0,0,23.96454-23.96454V197.89994a23.99167,23.99167,0,0,0-23.96454-23.96455Z"
          transform="translate(-156 -173.93539)"
          fill="#e6e6e6"
        />
        <path
          d="M510.14546,193.43538a4.46908,4.46908,0,0,0-4.46436,4.46436V292.971a4.46908,4.46908,0,0,0,4.46436,4.46436H689.21675a4.46908,4.46908,0,0,0,4.46435-4.46436V197.89974a4.46908,4.46908,0,0,0-4.46435-4.46436Z"
          transform="translate(-156 -173.93539)"
          fill="#fff"
        />
        <circle cx="443.6811" cy="52.99712" r="23.62069" fill={PRIMARY_COLOR} />
        <path
          d="M443.68107,80.55459h.00006a31.49422,31.49422,0,0,1,31.49422,31.49422v1.57474a0,0,0,0,1,0,0H412.18685a0,0,0,0,1,0,0v-1.57474A31.49422,31.49422,0,0,1,443.68107,80.55459Z"
          fill={PRIMARY_COLOR}
        />
        <circle cx="395.65237" cy="71.89367" r="16.53448" fill="#3f3d56" />
        <path
          d="M567.8456,269.96044a22.041,22.041,0,0,0-36.6645,16.49619v1.10231h32.28161v-1.57476A31.3396,31.3396,0,0,1,567.8456,269.96044Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <circle cx="491.70984" cy="71.89367" r="16.53448" fill="#3f3d56" />
        <path
          d="M631.5166,269.96044a22.041,22.041,0,0,1,36.6645,16.49619v1.10231H635.89949v-1.57476A31.3395,31.3395,0,0,0,631.5166,269.96044Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <path
          d="M967.21655,173.93539H788.14569a23.99168,23.99168,0,0,0-23.9646,23.96455v95.07091a23.99169,23.99169,0,0,0,23.9646,23.96454H967.21655a23.99168,23.99168,0,0,0,23.96454-23.96454V197.89994a23.99167,23.99167,0,0,0-23.96454-23.96455Z"
          transform="translate(-156 -173.93539)"
          fill="#e6e6e6"
        />
        <path
          d="M788.14546,193.43538a4.46908,4.46908,0,0,0-4.46436,4.46436V292.971a4.46908,4.46908,0,0,0,4.46436,4.46436H967.21675a4.46908,4.46908,0,0,0,4.46435-4.46436V197.89974a4.46908,4.46908,0,0,0-4.46435-4.46436Z"
          transform="translate(-156 -173.93539)"
          fill="#fff"
        />
        <path
          d="M874.683,289.39891a2.93082,2.93082,0,0,1-2.93091-2.9309V263.02079a2.93091,2.93091,0,0,1,5.86181,0V286.468A2.93082,2.93082,0,0,1,874.683,289.39891Z"
          transform="translate(-156 -173.93539)"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M884.94113,277.6753h-20.5164a2.9309,2.9309,0,1,1,0-5.8618h20.5164a2.9309,2.9309,0,1,1,0,5.8618Z"
          transform="translate(-156 -173.93539)"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M914.25016,221.98816a2.93088,2.93088,0,0,1-2.93091-2.9309V207.33365H899.59564a2.9309,2.9309,0,1,1,0-5.8618h14.65452a2.93088,2.93088,0,0,1,2.9309,2.9309v14.65451A2.93088,2.93088,0,0,1,914.25016,221.98816Z"
          transform="translate(-156 -173.93539)"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M896.53272,225.051a2.93095,2.93095,0,0,1-2.07242-5.00341l17.71744-17.71734a2.9309,2.9309,0,0,1,4.14483,4.145L898.60514,224.1926A2.92205,2.92205,0,0,1,896.53272,225.051Z"
          transform="translate(-156 -173.93539)"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M874.683,263.02079a27.84357,27.84357,0,1,1,27.84357-27.84357A27.87514,27.87514,0,0,1,874.683,263.02079Zm0-49.82533a21.98177,21.98177,0,1,0,21.98176,21.98176A22.00674,22.00674,0,0,0,874.683,213.19546Z"
          transform="translate(-156 -173.93539)"
          fill="#3f3d56"
        />
        <rect y="549.8885" width="888" height="2.24072" fill="#3f3d56" />
      </svg>
    </>
  );
};

export default HardwareImg;
