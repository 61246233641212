import React from "react";
import styled, { keyframes } from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const moveArrow = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
`;
const StyledRightArrowIcon = styled.div`
  svg {
    width: 70px;
    height: 70px;

    .cls-1rai {
      fill: var(--background-secondary_color);
      stroke-width: 0px;
    }
  }

  &:hover {
    svg {
      .cls-1rai {
        /* fill: ${SECOND_COLOR}; */
      }
    }
  }

  &:active svg {
    animation: ${moveArrow} 0.4s ease;
  }
`;

const RigthArrowIcon = () => {
  return (
    <StyledRightArrowIcon>
      <svg
        id="Capa_54"
        data-name="Capa 54"
        width="70"
        height="70"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <rect
          class="cls-1rai"
          x="129.8"
          y="146.61"
          width="176.35"
          height="57.76"
          rx="24"
          ry="24"
          transform="translate(191.63 -103.1) rotate(45.76)"
        />
        <rect
          class="cls-1rai"
          x="128.68"
          y="230.46"
          width="176.35"
          height="57.76"
          rx="24"
          ry="24"
          transform="translate(-119.44 224.83) rotate(-44.24)"
        />
      </svg>
    </StyledRightArrowIcon>
  );
};

export default RigthArrowIcon;
