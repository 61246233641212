// React
import { useState } from "react";
// Components
import FilterTableDigitalTwin from "./Filter/FilterTableDigitalTwin";
import GenericFilterTable from "./Filter/GenericFilterTable";
// Helpers
import createDefaultColumns from "../createDefaultColumns";
// Dependencies
import { useDrag, useDrop } from "react-dnd";
import { flexRender } from "@tanstack/react-table";
// Styles
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import { FROM_TABLE } from "utils/constStrings";
import { BLACK_COLOR } from "utils/const";
import qaqc from "images/qaqc.png";

const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  const data = [...columnOrder];
  data.splice(
    data.indexOf(targetColumnId),
    0,
    data.splice(data.indexOf(draggedColumnId), 1)[0]
  );
  return [...data];
};

const DraggableColumnHeader = ({
  header,
  table,
  customWidth,
  columnFilter,
  setValue,
  setFilterCheckBox,
  filterCheckBox,
  setDateFilters,
  dateFilters,
  useFilter,
  columnResizeMode,
  headers,
  keyValues,
  rowsDynamic,
  setUseOrderColumns,
  rows,
  typeOfLibrary,
  dataChecked,
  setDataChecked,
  from,
  selectedFiltersByColumnOrder,
  currentNameviewColumnOrder,
}) => {
  const [dragging, setDragging] = useState(false);

  const { allKeyValues } = createDefaultColumns(
    headers,
    keyValues,
    rowsDynamic
  );

  const handleMouseDown = () => {
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };
  const cursorStyle = dragging ? { cursor: "grabbing" } : { cursor: "grab" };

  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder || allKeyValues
      );
      setColumnOrder(newColumnOrder);
      setUseOrderColumns(true);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: "column",
  });

  return (
    <th
      className="background-table-color"
      ref={dropRef}
      colSpan={header.colSpan}
      style={{
        opacity: isDragging ? 0.5 : 1,
        width: customWidth,
      }}
    >
      {header.isPlaceholder ? null : (
        <>
          <div
            {...{
              className: header.column.getCanSort()
                ? "cursor-pointer select-none"
                : "",
              onClick: header.column.getToggleSortingHandler(),
            }}
            ref={previewRef}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {header.getContext().column.id.includes("✅")
              ? header.column.id.slice(
                  0,
                  header.getContext().column.id.length - 1
                )
              : flexRender(header.column.columnDef.header, header.getContext())}
            {header.getContext().column.id.includes("✅") && (
              <img src={qaqc} style={{ width: "30px" }} />
            )}
            {flexRender(header.column.columnDef.header, header.getContext()) !==
              null && (
              <button
                sty
                ref={dragRef}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  border: "none",
                  color: `${BLACK_COLOR}`,
                  backgroundColor: "transparent",
                  margin: 0,
                  ...cursorStyle,
                }}
              >
                <ControlCameraIcon sx={{ fontSize: "1.5rem" }} />
              </button>
            )}
            {{
              asc: <ExpandLessIcon />,
              desc: <ExpandMoreIcon />,
            }[header.column.getIsSorted()] ?? null}
          </div>
          {header.column.getCanFilter() && useFilter && (
            <div style={{ marginTop: "5px" }}>
              {from === FROM_TABLE.digitalTwin ? (
                <FilterTableDigitalTwin
                  column={columnFilter}
                  table={table}
                  setValue={setValue}
                  setFilterCheckBox={setFilterCheckBox}
                  filterCheckBox={filterCheckBox}
                  setDateFilters={setDateFilters}
                  dateFilters={dateFilters}
                  rows={rows}
                  typeOfLibrary={typeOfLibrary}
                  dataChecked={dataChecked}
                  setDataChecked={setDataChecked}
                  selectedFiltersByColumnOrder={selectedFiltersByColumnOrder}
                  currentNameviewColumnOrder={currentNameviewColumnOrder}
                />
              ) : (
                <GenericFilterTable
                  column={columnFilter}
                  table={table}
                  setValue={setValue}
                  setFilterCheckBox={setFilterCheckBox}
                  filterCheckBox={filterCheckBox}
                  setDateFilters={setDateFilters}
                  dateFilters={dateFilters}
                  rows={rows}
                  useFilter={useFilter}
                />
              )}
            </div>
          )}
        </>
      )}

      <div
        {...{
          onMouseDown: header.getResizeHandler(),
          onTouchStart: header.getResizeHandler(),
          className: `resizer ${
            header.column.getIsResizing() ? "isResizing" : ""
          }`,
          style: {
            transform:
              columnResizeMode === "onEnd" && header.column.getIsResizing()
                ? `translateX(${
                    table.getState().columnSizingInfo.deltaOffset
                  }px)`
                : "",
          },
        }}
      />
    </th>
  );
};

export default DraggableColumnHeader;
