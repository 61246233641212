import useSwr from "swr";
import { config } from "config.js";

const FetchEvents = (libraryId) => {
  let url = `${config.URL_BACKEND_PG}api/v1/point-type-events`;
  if (libraryId) {
    url = `${url}?libraryId=${libraryId}`;
  }

  const { data, error } = useSwr(url);

  return { data, error };
};

export default FetchEvents;
