import styled from "styled-components";

export const ContainerDashboard = styled.div`
  padding: 0px;
  background: ${({ backgrounColor }) =>
    backgrounColor === "green"
      ? "radial-gradient(circle at bottom center, #2C3E50 10%, #27AE60 30%, #16A085 50%, #2ECC71 80%, #3498db 100%);"
      : backgrounColor === "blue"
      ? "radial-gradient(circle at bottom center, #2E3192 10%, #1BFFFF 30%, #4A90E2 50%, #0A3D62 80%, #1C1C3C 100%)"
      : backgrounColor === "orange"
      ? "radial-gradient(circle at bottom center,#FF9A8B 10%,#FF6A88 30%,#FF99AC 50%,#FFC3A0 80%,#FFD1BA 100%)"
      : backgrounColor === "dark"
      ? "radial-gradient(circle at bottom center, #000000 10%, #1a1a1a 20%, #3a3a3a 30%, #5a5a5a 40%, #7a7a7a 50%, #999999 60%, #7a7a7a 70%, #5a5a5a 80%, #3a3a3a 90%, #1a1a1a 100%);"
      : backgrounColor};

  .setting {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0 10px;
  }

  .dashboard {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 100vh;
    margin-top: -80px;
    margin-left: 65px;

    .title {
      margin-bottom: 10px;
      font-size: 50px;
      font-weight: bold;
      text-align: center;
    }
  }
`;
