import axios from "axios";
import { config } from "config.js";

const updateRegionApiMg = async (regionGeoJson, newIdUpdate) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(
      `${config.URL_BACKEND_MG}region/${newIdUpdate}`,
      regionGeoJson,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = res.data;

    return { data, res };
  } catch (error) {
    console.error("Error in updateRegionApiMg:", error);
    throw error;
  }
};

export default updateRegionApiMg;
