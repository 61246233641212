import { getNumericValue } from "helpers/getNumericValue";

function filterObjectsByLibraryId(objects, libraryId) {
  return objects.filter((obj) => obj.typeElement?.pointLibraryId === libraryId);
}

function groupValue(value, groupingVariable) {
  if (!groupingVariable) return value;
  return value;
}

function processDatasets(filteredObjects, chart, groupedLabels) {
  return chart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: null }));

    filteredObjects.forEach((obj) => {
      const typeElementName = obj.attributes.find(
        (attr) => attr.name === chart.categories.name
      )?.value;
      if (typeElementName !== dataset.barStyle.label) return;

      const value = obj.attributes.find(
        (attr) => attr.name === chart.columnX.name
      )?.value;
      const groupedValue = groupValue(value, chart.groupingVariable);
      const yValue = obj.attributes.find(
        (attr) => attr.name === chart.columnsY
      )?.value;

      const numericValue = getNumericValue(yValue);

      const index = dataByLabel.findIndex(
        (item) => item.label === groupedValue
      );
      if (index !== -1) {
        if (
          dataByLabel[index].value === null ||
          isNaN(dataByLabel[index].value)
        ) {
          dataByLabel[index].value = numericValue;
        } else {
          dataByLabel[index].value += numericValue;
        }
      }
    });

    if (dataset.barStyle.colorsByLabel.length > 0) {
      if (dataset.barStyle.constantValue) {
        return {
          ...dataset,
          data: dataByLabel.map((item, index) => {
            return {
              value: dataset.barStyle.constantValue,
              background: dataset.barStyle.colorsByLabel[index],
            };
          }),
        };
      } else {
        return {
          ...dataset,
          data: dataByLabel.map((item, index) => {
            return {
              value: item.value,
              background: dataset.barStyle.colorsByLabel[index],
            };
          }),
        };
      }
    } else {
      if (dataset.barStyle.constantValue) {
        return {
          ...dataset,
          data: new Array(dataByLabel.length).fill(dataset.barStyle.constantValue),
        };
      } else {
        return {
          ...dataset,
          data: dataByLabel.map((item) => item.value),
        };
      }
    }
  });
}

// Función principal para procesar datos
export function sumValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    // const groupedLabels = getGroupedLabels(chart.columnX.values, chart.groupingVariable);
    const datasets = processDatasets(
      filteredObjects,
      chart,
      chart.columnX.values
    );

    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}

// Función para procesar datasets contando ocurrencias
function processCountDatasets(filteredObjects, timeSeriesChart, groupedLabels) {
  return timeSeriesChart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: 0 }));

    filteredObjects.forEach((obj) => {
      const typeElementName = obj.attributes.find(
        (attr) => attr.name === timeSeriesChart.categories.name
      )?.value;
      if (typeElementName !== dataset.barStyle.label) return;

      const dataLabel = obj.attributes.find(
        (attr) => attr.name === timeSeriesChart.columnX.name
      )?.value;

      const index = dataByLabel.findIndex((item) => item.label === dataLabel);
      if (index !== -1) {
        dataByLabel[index].value += 1;
      }
    });

    if (dataset.barStyle.constantValue) {
      return {
        ...dataset,
        data: new Array(dataByLabel.length).fill(dataset.barStyle.constantValue),
      };
    } else {
      return {
        ...dataset,
        data: dataByLabel.map((item) => item.value),
      };
    }
  });
}

// Función principal para procesar datos con conteo
export function countByValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const filteredObjects = filterObjectsByLibraryId(objects, chart.libraryId);
    // const groupedLabels = getGroupedLabels(chart.columnX.values, chart.timeVariable);
    const datasets = processCountDatasets(
      filteredObjects,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}
