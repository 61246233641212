export const defaultFieldsObjects = [
  {
    name: "typeElementId",
    alias: "Element ID",
    type: "number",
  },
  {
    name: "typeElementName",
    alias: "Element name",
    type: "string",
  },
  {
    name: "localDate",
    alias: "Local date",
    type: "string",
  },
  {
    name: "userName",
    alias: "User",
    type: "string",
  },
  {
    name: "latitude",
    alias: "Latitude",
    type: "number",
  },
  {
    name: "longitude",
    alias: "Longitude",
    type: "number",
  },
];

export const defaultFieldsEvents = [
  {
    name: "typeOfEvent",
    alias: "Type of Event",
    type: "string",
  },
  {
    name: "relationId",
    alias: "Relation ID",
    type: "string",
  },
  {
    name: "relationTypeName",
    alias: "Relation Type Name",
    type: "string",
  },
  {
    name: "totalEvents",
    alias: "Total Events",
    type: "number",
  },
  {
    name: "localDate",
    alias: "Local Date",
    type: "string",
  },
  {
    name: "userName",
    alias: "User",
    type: "string",
  },
  {
    name: "latitude",
    alias: "Latitude",
    type: "number",
  },
  {
    name: "longitude",
    alias: "Longitude",
    type: "number",
  },
];

export const defaultFieldsComponents = [
  {
    name: "typeOfComponent",
    alias: "Type of Component",
    type: "string",
  },
  {
    name: "objectId",
    alias: "Object ID",
    type: "string",
  },
  {
    name: "typeOfElement",
    alias: "Type of Element",
    type: "string",
  },
  {
    name: "localDate",
    alias: "Local Date",
    type: "string",
  },
  {
    name: "userName",
    alias: "User",
    type: "string",
  },
  {
    name: "latitude",
    alias: "Latitude",
    type: "number",
  },
  {
    name: "longitude",
    alias: "Longitude",
    type: "number",
  },
];

/**
 * The `getReservedWords` function does not take any parameters and it returns an array that contains the elements from the `defaultFieldsObjects`, `defaultFieldsEvents`, and `defaultFieldsComponents` arrays.
 *
 * @function
 * @name getReservedWords
 * @kind variable
 * @returns {{ name: string; alias: string; type: string; }[]}
 */
const getReservedWords = () => {
  return [
    ...defaultFieldsObjects,
    ...defaultFieldsEvents,
    ...defaultFieldsComponents,
  ];
};

export default getReservedWords;
