import axios from "axios";
import { config } from "config.js";

export const createOrder = async ({ body, from }) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_MG}order-cards-dashboard?type=${from}`;
  const res = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};
// Update with patch

export const updateOrderCard = async ({ id, body, type }) => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_MG}order-cards-dashboard/${id}?type=${type}`;
  const res = await axios.patch(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export const updateTemplate = async ({ id, body, type }) => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_MG}order-cards-dashboard/template/${id}?type=${type}`;
  const res = await axios.patch(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export const addChartInAllViews = async ({ userId, body, type }) => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_MG}order-cards-dashboard/add-chart/${userId}?type=${type}`;
  const res = await axios.patch(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export const deleteChartId = async ({ userId, body, type, fromType }) => {
  const token = localStorage.getItem("token");
  const url =
    type === "allTypes"
      ? `${config.URL_BACKEND_MG}order-cards-dashboard/delete-chart/${userId}?type=${type}&fromType=${fromType}`
      : `${config.URL_BACKEND_MG}order-cards-dashboard/delete-chart/${userId}?type=${type}`;
  const res = await axios.delete(url, {
    data: body,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export const deleteOrderView = async ({ id, viewId, type }) => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_MG}order-cards-dashboard/${id}?type=${type}&viewId=${viewId}`;
  const res = await axios.delete(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};
