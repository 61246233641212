import axios from "axios";
import { config } from "../../config";

/**
 *
 * @param {*} id line id for deleting line if exists
 * @returns Object that contains APIpg response and status of request
 */
const deleteLine = async (id) => {
  try {
    const token = localStorage.getItem("token");

    // delete line through pg
    const responsePg = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/lines?lineId=${id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const pgResponse = await responsePg.data;
    return {
      ...pgResponse,
    };
  } catch (error) {
    return { error };
  }
};

export default deleteLine;