import axios from "axios";
import { config } from "config.js";
const URL_BASE_PG = config.URL_BACKEND_PG;

const token = localStorage.getItem("token");

export const userVerification = async (email) => {
  try {
    const res = await fetch(
      `${URL_BASE_PG}api/v1/user-verification?email=${email}`,
      {
        method: "GET",
      }
    );
    const response = await res.json();

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserVerification = async ({ userId, changes }) => {
  try {
    const res = await axios.patch(
      `${URL_BASE_PG}api/v1/user-verification/${userId}`,
      changes
    );
    const data = await res.data;
    return { res, data };
  } catch (error) {
    console.error(error);
    throw new Error("Error updating user verification", error);
  }
};

export const updateUserVerificationStatus = async ({ userId, status }) => {
  try {
    const res = await axios.patch(
      `${config.URL_BACKEND_PG}api/v1/user-verification/status/${userId}`,
      {
        isEnable: status,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { res, data };
  } catch (error) {
    console.error(error);
    throw new Error("Error updating user verification status", error);
  }
};
