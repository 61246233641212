import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/Forms/GenericInput";
import CustomButton from "components/Buttons/CustomButton";
import MessagesDialog from "components/Dialogs/MessagesDialog";
import Select from "react-select/creatable";
import { useSWRConfig } from "swr";
import { config } from "config.js";
import { roles } from "utils/roles";
import { createOrder } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";
import { ContainerSelect, SelectViewStyles } from "./style";

function FormAddView({
  from,
  currentOrderViews,
  setDialogAddView,
  setCurrentOrderName,
  setCurrentOrderViews,
  setOpenAlertCreated,
  setCurrentPositionId,
  setResetDefaultView,
  currentTemplate,
  allViews,
}) {
  const { mutate } = useSWRConfig();
  const { setDragAndDroll } = useStoreDashboard();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;

  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const uniqueSectionNames = [
    ...new Set(allViews.map((view) => view?.section?.name)),
  ];

  const options = uniqueSectionNames.map((sectionName) => ({
    value: sectionName,
    label: <ContainerSelect>{sectionName}</ContainerSelect>,
  }));

  const handleInputChange = (newValue) => {
    if (getValues("selectField") === null) {
      setValue("selectField", newValue);
    }
  };

  const onSubmit = async (data) => {
    const typeView = data["Public"] ? "public" : "private";

    if (currentOrderViews.length > 0) {
      setLoading(true);
      const response = await createOrder({
        from,
        body: {
          adminCompanyId: role === roles.companyAdmin ? adminCompanyId : null,
          userId,
          order: {
            name: data["Name View"],
            position: currentOrderViews,
            typeView,
            template: {
              name: currentTemplate ? currentTemplate.name : null,
              gridTemplateColumns: currentTemplate
                ? currentTemplate.gridTemplateColumns
                : null,
              chartsPosition: currentTemplate
                ? currentTemplate.chartsPosition
                : null,
              sizeChart: currentTemplate ? currentTemplate.sizeChart : null,
            },
            section: {
              name: data.selectField,
            },
          },
        },
      });

      if (typeof response.data === "string") {
        const text = (
          <div>
            The name of the {data["Public"] ? "public" : "private"} view{" "}
            <b>{data["Name View"]}</b> already exists,
          </div>
        );
        setOpenAlert({
          open: true,
          severity: "error",
          message: text,
        });
      } else {
        setOpenAlertCreated({
          open: true,
          severity: "success",
          message: "It has been successfully created",
        });
        setCurrentPositionId(
          response.data.orders[from].find(
            (elm) => elm.name === data["Name View"]
          )._id
        );
        setResetDefaultView(false);
        setDragAndDroll(false);
        setDialogAddView(false);
        setCurrentOrderViews(currentOrderViews);
        setCurrentOrderName(data["Name View"]);
        mutate(urlOrders);
      }
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "1.5rem",
        width: "100%",
      }}
    >
      {openAlert.open && (
        <MessagesDialog
          open={openAlert.open}
          severity={openAlert.severity}
          message={openAlert.message}
          handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <TextInput
            field={{
              name: "Name View",
              type: "text",
              required: true,
            }}
            register={register}
            errors={errors}
          />

          <Select
            {...register("selectField")}
            options={options}
            isClearable
            isSearchable
            onChange={(selectedOption) => {
              setValue("selectField", selectedOption?.value);
            }}
            onInputChange={handleInputChange}
            placeholder="Choose or create a section"
            styles={SelectViewStyles}
          />
          {role === roles.companyAdmin && (
            <TextInput
              field={{
                name: "Public",
                type: "checkbox",
                required: false,
              }}
              register={register}
              errors={errors}
            />
          )}
        </>
        <div
          style={{
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <CustomButton
            type="submit"
            isLoad={loading}
            text="Create View"
            margin={0}
          />
        </div>
      </form>
    </div>
  );
}

export default FormAddView;
