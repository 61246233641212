import { useEffect, useState, useCallback } from "react";
// Services
import { findChartsText } from "services/Dashboard/findCharts";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { config } from "config";
const useFindTextCharts = ({ openDialog }) => {
  const [charts, setCharts] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlLineChart = `${config.URL_BACKEND_MG}charts-text?adminCompanyId=${adminCompanyId}&from=components`;
  const { data: dataTextChart, error: errorDataTextChart } = useSwr(urlLineChart);
  
  useEffect(() => {
    if (!errorDataTextChart && dataTextChart) setCharts(dataTextChart);
  }, [dataTextChart, errorDataTextChart, openDialog, reloadCharts]);

  return { textCharts: charts };
};

export default useFindTextCharts;
