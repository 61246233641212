import axios from "axios";
import { config } from "config.js";

export const getAllTags = async () => {
  try {
    const token = localStorage.getItem("token");
    const adminCompanyId = localStorage.getItem("adminCompanyId");

    const urlSearchParams = new URLSearchParams();
    if (adminCompanyId)
      urlSearchParams.append("adminCompanyId", adminCompanyId);

    let url = `${
      config.URL_BACKEND_PG
    }api/v1/tags?${urlSearchParams.toString()}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    if (error) return [];
  }
};

export const createTag = async (tag) => {
  try {
    if (tag.trim() === "") return false;
    const token = localStorage.getItem("token");
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const body = {
      tag: tag.trim(),
      adminCompanyId: parseInt(adminCompanyId),
    };

    let url = `${config.URL_BACKEND_PG}api/v1/tags`;
    const res = await axios.post(url, body, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    if (error) return [];
  }
};

export const deleteTag = async (tagId) => {
  try {
    const token = localStorage.getItem("token");
    const adminCompanyId = localStorage.getItem("adminCompanyId");

    const urlSearchParams = new URLSearchParams();
    if (adminCompanyId)
      urlSearchParams.append("adminCompanyId", adminCompanyId);
    if (tagId) urlSearchParams.append("id", tagId);

    let url = `${
      config.URL_BACKEND_PG
    }api/v1/tags?${urlSearchParams.toString()}`;
    const res = await axios.delete(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    if (error) return [];
  }
};
