import React, { useState } from "react";
import {
  ColorCircle,
  ColorGrid,
  ColorRainBow,
  ColorSquare,
  ColorSquare2,
  ContainerExample,
  ContentCardDetail,
  DefaultGrid,
  Titleh1,
  ToolTipExample,
} from "./preferencesstyles";
import {
  BLACK_COLOR,
  BORDER_GREY,
  INFORMATION_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
} from "utils/const";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { useTooltip } from "components/ToolTips/TooltipContext";
//icons
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import AssistantIcon from "@mui/icons-material/Assistant";
import PreferenceToolTip from "components/ToolTips/PreferenceToolTip";
import FlakyIcon from "@mui/icons-material/Flaky";
import CancelIcon from "@mui/icons-material/Cancel";

//color-react

import { ChromePicker } from "react-color";
import ToggleToolTip from "./ToggleToolTip";
import AnimatedGif from "./AnimatedGif";

function TooltipConfig({ title }) {
  const {
    globalTooltipActive,
    handleTooltipColor,
    handleTooltipFont,
    handleTooltipSize,
    handleGlobalTooltip,
    handleAllColor,
  } = useTooltip();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayColorPickerFont, setDisplayColorPickerFont] = useState(false);

  const [selectedColor, setSelectedColor] = useState("#3f51b5");
  const [selectedFontColor, setSelectedFontColor] = useState(WHITE_COLOR);

  const handleColorChange = (color) => {
    handleTooltipColor(color);
  };
  const handleFontChange = (fontColor) => {
    handleTooltipFont(fontColor);
  };
  const handleFontSizeChange = (fontSize) => {
    handleTooltipSize(fontSize);
  };

  const handleDefault = (color, fontColor) => {
    handleAllColor(color, fontColor);
  };

  const handleColorChangeColor = (color) => {
    setSelectedColor(color);
    handleTooltipColor(color);
  };

  const handleColorSquareClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorSquareClickFont = () => {
    setDisplayColorPickerFont(!displayColorPickerFont);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    setDisplayColorPickerFont(false);
  };

  const handleFontSquareClickFont = (color) => {
    setSelectedFontColor(color);
    handleTooltipFont(color);
  };
  return (
    <>
      <Titleh1>{title}</Titleh1>
      <ContentCardDetail className="background-2 border-color-1">
        <div className="contentitle">
          <div className="contentitle2">
            <h2>
              <AssistantIcon style={{ marginRight: "1rem" }} />
              Select Default ToolTip Color
            </h2>
            <p>Edit Default ToolTip Color</p>
            <DefaultGrid>
              <ContainerExample
                onClick={() => {
                  // handleColorChange(INFORMATION_COLOR);
                  // handleFontChange(WHITE_COLOR);
                  handleDefault(INFORMATION_COLOR, WHITE_COLOR);
                }}
              >
                <PreferenceToolTip
                  title="Default color"
                  placement="top"
                  backgroundColor={INFORMATION_COLOR}
                  color={WHITE_COLOR}
                  open
                >
                  <ColorCircle />
                </PreferenceToolTip>
              </ContainerExample>

              <ContainerExample
                onClick={() => {
                  handleDefault(PRIMARY_COLOR, WHITE_COLOR);
                }}
              >
                <PreferenceToolTip
                  title="Green color 1"
                  placement="top"
                  backgroundColor={PRIMARY_COLOR}
                  color={WHITE_COLOR}
                  open
                >
                  <ColorCircle />
                </PreferenceToolTip>
              </ContainerExample>
              <ContainerExample
                onClick={() => {
                  handleDefault(SECOND_COLOR, WHITE_COLOR);
                }}
              >
                <PreferenceToolTip
                  title="Green color 2"
                  placement="top"
                  backgroundColor={SECOND_COLOR}
                  color={WHITE_COLOR}
                  open
                >
                  <ColorCircle />
                </PreferenceToolTip>
              </ContainerExample>
              <ContainerExample
                style={{ background: "#202020" }}
                onClick={() => {
                  handleDefault(WHITE_COLOR, BLACK_COLOR);
                }}
              >
                <PreferenceToolTip
                  title="Dark mode"
                  placement="top"
                  backgroundColor={WHITE_COLOR}
                  color={BLACK_COLOR}
                  open
                >
                  <ColorCircle />
                </PreferenceToolTip>
              </ContainerExample>
            </DefaultGrid>
          </div>
          <div className="optionsContainer">
            <div className="contentitle2">
              <h2>
                <FormatColorFillIcon style={{ marginRight: "1rem" }} />
                Custom Background & Font Color
              </h2>
              <div>
                <p>Background Color</p>
              </div>

              <ColorGrid>
                <PreferenceToolTip
                  title="Default"
                  placement="top"
                  backgroundColor="#3f51b5"
                  color={WHITE_COLOR}
                >
                  <ColorSquare
                    style={{ backgroundColor: "#3f51b5" }}
                    onClick={() => handleColorChange("#3f51b5")}
                  />
                </PreferenceToolTip>

                <PreferenceToolTip
                  title="Gold"
                  placement="top"
                  backgroundColor="#f7a21b"
                  color={WHITE_COLOR}
                >
                  <ColorSquare
                    style={{ backgroundColor: "#f7a21b" }}
                    onClick={() => handleColorChange("#f7a21b")}
                  />
                </PreferenceToolTip>

                <PreferenceToolTip
                  title="Orange"
                  placement="top"
                  backgroundColor="#e45635"
                >
                  <ColorSquare
                    style={{ backgroundColor: "#e45635" }}
                    onClick={() => handleColorChange("#e45635")}
                  />
                </PreferenceToolTip>
                <PreferenceToolTip
                  title="Strawberry"
                  placement="top"
                  backgroundColor="#d60257"
                >
                  <ColorSquare
                    style={{ backgroundColor: "#d60257" }}
                    onClick={() => handleColorChange("#d60257")}
                  />
                </PreferenceToolTip>
                <PreferenceToolTip
                  title="Blue marine"
                  placement="top"
                  backgroundColor="#00a0b0"
                >
                  <ColorSquare
                    style={{ backgroundColor: "#00a0b0" }}
                    onClick={() => handleColorChange("#00a0b0")}
                  />
                </PreferenceToolTip>
                <PreferenceToolTip
                  title="Pick your color"
                  placement="top"
                  backgroundColor="#7a7a7a"
                >
                  <ColorRainBow onClick={handleColorSquareClick} />
                </PreferenceToolTip>
                {displayColorPicker && (
                  <div
                    className="background-row-1 border-color-1"
                    style={{
                      padding: "1rem",
                      borderRadius: "5px",
                      position: "absolute",
                      zIndex: 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>Custom Color Selector: </h2>
                      <CancelIcon
                        className="iconButton color3"
                        onClick={handleClose}
                      />
                    </div>

                    <ChromePicker
                      color={selectedColor}
                      onChange={(color) => handleColorChangeColor(color.hex)}
                      // Disable transparent option
                      disableAlpha={true}
                      styles={{
                        default: {
                          picker: {
                            width: "300px",
                            height: "auto",
                            boxShadow: "none",
                          },
                        },
                      }}
                    />
                  </div>
                )}
              </ColorGrid>
              <p>Font Color</p>
              <ColorGrid>
                <PreferenceToolTip
                  title="Black"
                  placement="top"
                  backgroundColor={BLACK_COLOR}
                >
                  <ColorSquare
                    style={{ backgroundColor: BLACK_COLOR }}
                    onClick={() => handleFontChange(BLACK_COLOR)}
                  />
                </PreferenceToolTip>
                <PreferenceToolTip
                  title="White"
                  placement="top"
                  backgroundColor="#7a7a7a"
                >
                  <ColorSquare
                    style={{
                      backgroundColor: WHITE_COLOR,
                      border: `solid 1px ${BORDER_GREY}`,
                    }}
                    onClick={() => handleFontChange(WHITE_COLOR)}
                  />
                </PreferenceToolTip>

                <PreferenceToolTip
                  title="Pick your color"
                  placement="top"
                  backgroundColor="#7a7a7a"
                >
                  <ColorRainBow onClick={handleColorSquareClickFont} />
                </PreferenceToolTip>
                {displayColorPickerFont && (
                  <div
                    className="background-row-1 border-color-1"
                    style={{
                      padding: "1rem",
                      borderRadius: "5px",
                      position: "absolute",
                      zIndex: 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>Custom Color Selector: </h2>
                      <CancelIcon
                        className="iconButton color3"
                        onClick={handleClose}
                      />
                    </div>

                    <ChromePicker
                      color={selectedFontColor}
                      onChange={(color) => handleFontSquareClickFont(color.hex)}
                      // Disable transparent option
                      disableAlpha={true}
                      styles={{
                        default: {
                          picker: {
                            width: "300px",
                            height: "auto",
                            boxShadow: "none",
                          },
                        },
                      }}
                    />
                  </div>
                )}
              </ColorGrid>
              <h2>
                {" "}
                <FormatSizeIcon style={{ marginRight: "1rem" }} />
                Change Font Size
              </h2>

              <p>
                Personalize the font size to match your preferences and needs.
              </p>
              <ColorGrid>
                <ColorSquare2 onClick={() => handleFontSizeChange(16)}>
                  Small
                </ColorSquare2>
                <ColorSquare2 onClick={() => handleFontSizeChange(20)}>
                  Medium
                </ColorSquare2>
                <ColorSquare2 onClick={() => handleFontSizeChange(30)}>
                  Large
                </ColorSquare2>
              </ColorGrid>
              <h2>
                {" "}
                <FlakyIcon style={{ marginRight: "1rem" }} />
                Activate / Deactivate Tooltip
              </h2>
              <p>
                This option allows you to enable or disable tooltips throughout
                the app.
              </p>
              <ColorGrid>
                <ToggleToolTip
                  checked={globalTooltipActive}
                  onChange={handleGlobalTooltip}
                  name="globalTooltipActive"
                />
              </ColorGrid>
            </div>
            {/* Preview Container */}
            <div className="previewContainer border-color-1 background-row-1">
              <div>
                <p>Preview</p>
                <ToolTipExample>
                  <SimpleToolTip
                    title="ToolTip"
                    placement="top"
                    open
                    withTransition={false}
                  >
                    <ColorCircle />
                  </SimpleToolTip>
                </ToolTipExample>
              </div>
              <div>
                <p>On the app</p>
                <ToolTipExample
                  onClick={() => {
                    handleColorChange(INFORMATION_COLOR);
                    handleFontChange(WHITE_COLOR);
                  }}
                >
                  <AnimatedGif />
                </ToolTipExample>
              </div>
            </div>
          </div>
        </div>
      </ContentCardDetail>
    </>
  );
}

export default TooltipConfig;
