export const roles = {
  client: "Cliente",
  itSupport: "IT Support",
  director: "Director de proyecto",
  auditor: "Auditor",
  operario: "Operario",
  teamField: "Equipo de campo",
  teamOffice: "Equipo de oficina",
  companyAdmin: "Administrador de compañía",
  decimetrixAdmin: "Administrador de decimetrix",
};
