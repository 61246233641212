import styled from "styled-components";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  WHITE_COLOR,
} from "utils/const";

export const Wrapperbtn = styled("div")`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 10px;
  margin: 0 auto;
`;

export const TrStyle = styled.tr`
  color: ${(props) =>
    props.theme === "light" ? document.body.style.color : WHITE_COLOR};

  background: ${(props) =>
    props.theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK};
`;
