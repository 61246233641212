import PDFCreator from "./hooks/PDFCreator";
import {
  MainContainer,
  PageContainer,
  SectionContent,
} from "./PDFCreatorStyle";
import TemplateEventLeak from "./templates/TemplateEventLeak";

/**
 *
 * @param {Array<Object>} data - Array of objects
 * @returns
 */
const ReportContainer = ({ data = [], event = false }) => {
  const pages = [];
  if (data.length === 1 && event) {
    pages.push(TemplateEventLeak({ dataDb: data[0] }));
  }
  if (data.length > 1 && event) {
    data.forEach((dt) => {
      pages.push(TemplateEventLeak({ dataDb: dt }));
    });
  }
  /* add conditions for other reports (objs-cmps-lines)*/

  return (
    <MainContainer style={{ width: "100%" }}>
      <PageContainer>
        <SectionContent>
          {/*  data = [ {Object} ] */}
          <PDFCreator pages={pages} />
        </SectionContent>
        <SectionContent>
          <PDFCreator pages={pages} downloadButton={true} />
        </SectionContent>
      </PageContainer>
    </MainContainer>
  );
};

export default ReportContainer;
