export function getNumericValue(value) {
  // Intenta analizar el valor como JSON
  try {
    const parsedValue = JSON.parse(value);
    if (
      typeof parsedValue === "object" &&
      parsedValue.hasOwnProperty("value")
    ) {
      return parseFloat(parsedValue.value);
    }
  } catch (error) {
    // Si hay un error, asumimos que no es JSON y seguimos adelante
  }
  // Si no se pudo analizar como JSON o no es un objeto con una propiedad 'value', verificamos si es un número
  if (isNaN(value)) {
    return NaN;
  } else {
    return parseFloat(value);
  }
}
