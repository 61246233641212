import useSwr from "swr";
import { config } from "config.js";

/**
 * Fetches constant names from a specified URL using the useSwr hook.
 * Returns the fetched data and any error that occurred during the fetch.
 * @param {id} AdminCompanyId for getting specific constants names
 * @returns {Object} An object containing the fetched data and any error.
 *                   - data: An array of the constant names strings.
 *                   - error: Any error that occurred during the fetch request.
 *
 * @example
 * const { data, error } = useGetConstantNames();
 * // Use the fetched data and handle any error
 * ["constantName","constantName","constantName"]
 */
const useGetConstantNames = ({ id, idDecimetrix }) => {
  // if (parseInt(id) < 0) return { data: []} ;
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("adminCompanyId", id);
  if (idDecimetrix) urlParams.append("adminDecimetrixId", idDecimetrix);

  const url = `${
    config.URL_BACKEND_PG
  }api/v1/global-constants?getNames=true&${urlParams.toString()}`;

  //const url = `${config.URL_BACKEND_PG}api/v1/global-constants?getNames=true&adminCompanyId=${adminid}`;
  const { data, error } = useSwr(url);
  return { data, error };
};

export default useGetConstantNames;
