import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import { styled } from "@mui/material/styles";

const CustomTabs = ({ value, onChange }) => {
  const StyledTabs = styled(Tabs)({
    display: "flex",
  });

  return (
    <StyledTabs
      className="background-1"
      value={value}
      onChange={onChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: "var(--background-secondary_color)",
          height: "6px",
          borderRadius: "6px",
        },
      }}
    >
      <Tab
        label="Available Modules"
        style={{
          color: "var(--background-primary_color)",
          fontFamily: document.body.style.fontFamily,
        }}
        sx={{
          marginLeft: "115px",
          fontSize: "20px",
          "@media (max-width: 768px)": {
            marginLeft: "50px",
            fontSize: "17px",
          },
        }}
      />
      <Tab
        className="module-color"
        label="Access"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
      <Tab
        className="module-color"
        label="Get More"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
    </StyledTabs>
  );
};

export default CustomTabs;
