import styled from "styled-components";
import { WHITE_COLOR } from "utils/const";

export const LineToolsContainer = styled.div`
  position: absolute;
  top: 230px;
  right: 44px;
  width: 33px;
  height: auto;
  z-index: 3;
  background-clip: padding-box;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 29px;
    height: 29px;
    background: ${WHITE_COLOR};
    border-color: #00000021;
  }

  &:hover .tooltip {
    display: block;
  }
  button:hover {
    background-color: #efefef;
  }

  .button-first {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .button-last {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;
