import { findPointEvents } from "services/findEvents";

// Filtro los puntos que no tengan guardado el archivo seleccionado
const findEvents = async ({ fieldFile, typeEventId }) => {
  const pointEvents = await findPointEvents({
    pointTypeEventId: typeEventId,
  });
  const eventsWithoutVideo = pointEvents?.filter((item) => {
    const attribute = item.attributes.some(
      (attribute) => attribute.name === fieldFile.name
    );
    return !attribute;
  });
  return { pointEvents, eventsWithoutVideo };
};

export default findEvents;
