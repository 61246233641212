import { useState, useEffect, useCallback } from "react";

// Helpers
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";

// Hooks
import useFindObjects from "hooks/objects/useFindObjects";

// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { config } from "config";
import { countByValues, sumValues } from "pages/Dashboard/helpers/componentsTimeSeriesChart";

export const useFindTimeSeriesChartComponents = ({
  openDialog,
  components,
  loading,
}) => {
  const [charts, setCharts] = useState(null);
  const [timeSeriesCharts, setTimeSeriesChart] = useState(null);
  const [componentsFormat, setComponentsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlTimeSeriesChart = `${config.URL_BACKEND_MG}time-series-chart?adminCompanyId=${adminCompanyId}&from=components`;
  const { data: dataTimeSeriesChart, error: errorDataTimeSeriesChart } =
    useSwr(urlTimeSeriesChart);
    const { objects } = useFindObjects();

  useEffect(() => {
    if (!errorDataTimeSeriesChart && dataTimeSeriesChart)
      setCharts(dataTimeSeriesChart);
  }, [dataTimeSeriesChart, errorDataTimeSeriesChart, openDialog, reloadCharts]);

  useEffect(() => {
    const componentsFormat = createRowsComponents({ components, objects });

    setComponentsFormat(componentsFormat);
  }, [components]);

  const getDataTimeSeriesChart = useCallback(async () => {
    if (!loading && componentsFormat) {
      const lineCountsvalues = countByValues({
        charts,
        components: componentsFormat,
      });

      const timeSeriesSumValues = sumValues({
        charts,
        components: componentsFormat,
      });

      setTimeSeriesChart([...lineCountsvalues, ...timeSeriesSumValues]);
    }
  }, [loading, componentsFormat, charts]);

  useEffect(() => {
    if (charts && components) {
      getDataTimeSeriesChart();
    }
  }, [charts, components, getDataTimeSeriesChart]);

  return { timeSeriesCharts };
};
