import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const ConfigIcon = () => {
  return (
    <div style={{ marginBottom: "2px" }}>
      <svg
        id="Layer_24"
        data-name="Layer 24"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`.cls-1setticon {
        fill: var(--background-primary_color);
        
      }

      .cls-1setticon, .cls-2setticon {
        stroke-width: 0px;
      }

      .cls-2setticon {
        fill: var(--background-secondary_color);
      }`}
          </style>
        </defs>
        <path
          class="cls-1setticon"
          d="M329.6,161.71l-33.92-8.92c-2.12-7.77-5.01-15.22-8.6-22.27l18.42-31.93c1.67-2.9,1.19-6.56-1.18-8.93l-24.81-24.81c-2.38-2.38-6.06-2.85-8.96-1.16l-30.3,17.68c-6.87-3.93-14.18-7.18-21.82-9.67l-9.55-35.6c-.87-3.23-3.8-5.48-7.15-5.48h-35.09c-3.36,0-6.3,2.27-7.16,5.52l-8.92,33.92c-7.77,2.12-15.22,5.01-22.27,8.6l-31.93-18.42c-2.9-1.67-6.56-1.19-8.93,1.18l-24.81,24.81c-2.38,2.38-2.85,6.06-1.16,8.96l17.68,30.3c-3.93,6.87-7.18,14.18-9.67,21.82l-35.6,9.55c-3.23.87-5.48,3.8-5.48,7.15v35.09c0,3.36,2.27,6.3,5.52,7.16l33.92,8.92c2.12,7.77,5.01,15.22,8.6,22.27l-18.42,31.93c-1.67,2.9-1.19,6.56,1.18,8.93l24.81,24.81c2.38,2.38,6.06,2.85,8.96,1.16l30.3-17.68c6.87,3.93,14.18,7.18,21.82,9.67l9.55,35.6c.87,3.23,3.8,5.48,7.15,5.48h35.09c3.36,0,6.3-2.27,7.16-5.52l8.92-33.92c7.77-2.12,15.22-5.01,22.27-8.6l31.93,18.42c2.9,1.67,6.56,1.19,8.93-1.18l24.81-24.81c2.38-2.38,2.85-6.06,1.16-8.96l-17.68-30.3c3.93-6.87,7.18-14.18,9.67-21.82l35.6-9.55c3.23-.87,5.48-3.8,5.48-7.15v-35.09c0-3.36-2.27-6.3-5.52-7.16ZM181.76,245.42c-33.93,0-61.44-27.51-61.44-61.44s27.51-61.44,61.44-61.44,61.44,27.51,61.44,61.44-27.51,61.44-61.44,61.44Z"
        />
        <path
          class="cls-2setticon"
          d="M386.75,304.93c-9.95-7.7-16.3-12.71-20.68-15.1.44-5.65.32-11.33-.34-16.96.23-.13.46-.28.68-.45l21.11-16.62c1.91-1.51,2.58-4.11,1.62-6.35l-10.05-23.46c-.96-2.25-3.32-3.56-5.74-3.2-13.15,1.69-21.38,2.64-26.05,4.17-1.83-2.43-3.79-4.75-5.85-6.98-.81.68-1.76,1.21-2.83,1.5l-35.6,9.55c-.92,2.83-1.95,5.61-3.08,8.34,29.57,14.77,38.43,63.83-1.46,81.73-25.65,10.47-46.85-1.86-56.48-20.34-6.39,3.08-13.1,5.61-20.07,7.51l-6.76,25.7c3.22,4.16,6.77,8.05,10.67,11.58-.02.11-.04.21-.06.32l-3.18,26.68c-.29,2.42,1.08,4.73,3.35,5.63l23.7,9.48c2.27.91,4.87.17,6.32-1.79,7.29-9.42,12.17-15.62,14.7-19.97,5.78.62,11.8.61,18.03-.1l16.41,20.85c1.51,1.91,4.11,2.58,6.35,1.62l23.46-10.05c2.25-.96,3.56-3.32,3.2-5.74-1.56-12.2-2.5-20.17-3.85-24.98,5-3.78,9.37-7.91,13.14-12.3,1.28.15,26.01,3.1,26.01,3.1,2.42.29,4.73-1.08,5.63-3.35l9.48-23.7c.91-2.27.17-4.87-1.79-6.32Z"
        />
      </svg>{" "}
    </div>
  );
};

export default ConfigIcon;
