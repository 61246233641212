import styled from "styled-components";
import { WHITE_SECOND_COLOR, BLACK_GRAY_COLOR, BORDER_GREY } from "utils/const";

export const GaugeContainer = styled.div`
  width: 100%;
  max-width: ${({gaugeSize}) => gaugeSize}px;
  font-size: ${({gaugeSize}) => gaugeSize * 2.2 / 110}rem;
  font-weight: bold;
  color: ${(props) => props?.textColor || BLACK_GRAY_COLOR};

  .gauge__body {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: ${BORDER_GREY};
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow: hidden;
  }

  .gauge__fill {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: 100%;
    background: ${(props) => props.color};
    transform-origin: center top;
    transform: rotate(0.25turn);
    transition: transform 0.3s ease-out;
  }

  .gauge__cover {
    width: 75%;
    height: 150%;
    background: ${(props) => props?.background || WHITE_SECOND_COLOR};
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);

    /* Text */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    box-sizing: border-box;
  }
`;
