import axios from "axios";
import { config } from "config.js";

const findTypeComponentsTypeElements = async (queries) => {
  const { typeElementId, pointTypeComponentId } = queries;
  const token = localStorage.getItem("token");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  let url = `${config.URL_BACKEND_PG}api/v1/type-components-type-elements?`;
  if (adminCompanyId) {
    url = `${config.URL_BACKEND_PG}api/v1/type-components-type-elements?adminCompanyId=${adminCompanyId}`;
  }

  if (typeElementId) {
    url = url + `&typeElementId=${typeElementId}`;
  }

  if (pointTypeComponentId) {
    url = url + `&pointTypeComponentId=${pointTypeComponentId}`;
  }
  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  const data = await result.data;
  return data;
};

export default findTypeComponentsTypeElements;
