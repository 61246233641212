import axios from "axios";
import { config } from "config.js";
// Find events locations ids within a polygon by admin company Id
const findEventsLocationsIds = async (adminCompanyId, polygon) => {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_OCR_API}filterEventsPolygon?adminCompanyId=${adminCompanyId}`,
    polygon
  );
  const data = await res.data;
  return { data, res };
};

// find events within a polygon
const findEventsPolygon = async (adminCompanyId, polygon) => {
  const token = localStorage.getItem("token");
  const { data: eventLocationsIds } = await findEventsLocationsIds(
    adminCompanyId,
    polygon
  );
  const dataEvents = [];
  return await Promise.all(
    eventLocationsIds.map(async (object) => {
      let locationId = object;
      const res = await axios.get(
        `${config.URL_BACKEND_PG}api/v1/operation/location/${locationId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const data = await res.data;
      dataEvents.push(data);
      return { data, res };
    })
  ).then(() => dataEvents);
};

export default findEventsPolygon;
