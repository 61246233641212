// Styles
import { ContainerColumnSelect } from "./style";
// Constants
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";

const SelectTimePeriod = ({ handlerTimePeriod, timeSelect }) => {
  return (
    <>
      <h2 className="subtitle">Select Time Period</h2>
      <ContainerColumnSelect>
        {[
          { alias: "Year", name: "year" },
          { alias: "Month", name: "month" },
          { alias: "Day", name: "day" },
        ].map((elm) => (
          <div
            key={elm._id}
            className="card-type-chart"
            onClick={() => handlerTimePeriod(elm.name)}
            style={{
              backgroundColor:
                timeSelect === elm.name ? ICON_GREEN_COLOR : WHITE_COLOR,
            }}
          >
            <p>{elm.alias}</p>
          </div>
        ))}
      </ContainerColumnSelect>
    </>
  );
};

export default SelectTimePeriod;
