import styled from "styled-components";

export const Currency = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 100px 0;
  }
`;

export const StepSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  b {
    font-size: 2rem;
    font-weight: 800;
  }
`;
