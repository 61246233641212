import React from "react";
import { Page, Text, View, StyleSheet, Link, Image } from "@react-pdf/renderer";
import { isValidJSON } from "helpers/expressions/expressionHelpers";
import DigitalTransformation8 from "images/Digital-Transformation-8.png";
// import dDecimetrix from "images/d-decimetrix.png";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import NeuronaIconD from "images/NeuronaIconD";
import { isUrl } from "helpers/isUrl";

// Create Document Component
const TemplateEventLeak = ({ dataDb = {} }) => {
  let data = {
    eventId: null,
    mainColor: "#19213c",
    mainTextColor: "#ffffff",
    secondColor: "#104862",
    secondTextColor: "#ffffff",
    separatorColor: "#20d0d0",
    textColor: "#000000",
    createdAt: "",
    title: "",
    drawer: "",
    media: [],
    description: [],
    righttop: [],
    video: [],
  };

  // check info from db and replace it on data object to show
  if (dataDb !== undefined && Object.keys(dataDb).length) {
    data = { ...data, ...dataDb };

    dataDb?.eventValues?.forEach((it) => {
      if (it.where === "righttop") {
        data.righttop = data.righttop.concat(it);
      }
    });
    dataDb?.objectValues?.forEach((it) => {
      if (it.where === "righttop") {
        data.righttop = data.righttop.concat(it);
      }
    });
    dataDb?.eventPValues?.forEach((it) => {
      if (it.where === "righttop") {
        data.righttop = data.righttop.concat(it);
      }
    });
    dataDb?.componentValues?.forEach((it) => {
      if (it.where === "righttop") {
        data.righttop = data.righttop.concat(it);
      }
    });

    if (
      dataDb.componentId === undefined &&
      dataDb.eventMedia !== undefined &&
      dataDb.eventMedia.length
    ) {
      data.media = data.media.concat(dataDb.eventMedia[0]);
    }
    if (dataDb.objectMedia !== undefined && dataDb.objectMedia.length) {
      data.media = data.media.concat(dataDb.objectMedia[0]);
    }
    if (dataDb.eventPMedia !== undefined && dataDb.eventPMedia.length) {
      data.media = data.media.concat(dataDb.eventPMedia[0]);
    }
    if (dataDb.componentMedia !== undefined && dataDb.componentMedia.length) {
      data.media = data.media.concat(dataDb.componentMedia[0]);
    }
  }
  //function to return description items to iterate
  const dataDetails = (data = {}) => {
    const {
      objectId,
      componentId,
      componentTypeName,
      objectTypeName,
      eventId,
      video,
    } = data;

    return [
      {
        show: eventId ? true : false,
        name: "ID del evento:",
        value: eventId ?? 0,
      },
      {
        show: componentTypeName ? true : false,
        name: "Componente dónde se detectó la emisión:",
        value: `${componentTypeName} - ${objectTypeName}` ?? "",
      },
      {
        show: componentId ? true : false,
        name: "ID Green Dragon Componente donde se detectó la emision:",
        value: componentId ?? 0,
      },
      {
        show: objectTypeName ? true : false,
        name: "Tipo de Equipo:",
        value: objectTypeName ?? "",
      },
      {
        show: objectId ? true : false,
        name: "ID Green Dragon Equipo",
        value: objectId ?? 0,
      },
      {
        show: video.length ? true : false,
        name: "Enlace Video:",
        value: video.length ? video[0].value : "",
      },
    ];
  };

  const getResultInspection = (data = {}) => {
    const rightTop = data?.righttop;
    return rightTop?.map((item) => {
      if (isValidJSON(item.value)) {
        item.value = JSON.parse(item.value).value;
      }
      return (
        <View
          style={{
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "center",
          }}
        >
          <Text>{item.value}</Text>{" "}
          <Text style={{ fontWeight: "bold" }}>{item.alias} </Text>
        </View>
      );
    });
  };

  return (
    <Page
      size="A4"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        padding: "5px",
      }}
    >
      <View style={styles.PageContainer}>
        <View style={styles.PageHeader}>
          <View style={styles.PageHeaderTitle}>
            {/* <Image
              src={dDecimetrix}
              // src="https://greendragon-development.s3.us-east-2.amazonaws.com/default/d-decimetrix.png"
              // src={{
              //   uri: "https://greendragon-development.s3.us-east-2.amazonaws.com/default/d-decimetrix.png",
              //   method: "GET",
              //   headers: {
              //     data: "image/png",
              //     "Content-type": "image/png",
              //     "Cache-Control": "no-cache",
              //   },
              //   body: "",
              // }}
              style={{
                width: "60px",
                marginLeft: "20px",
                marginRight: "10px",
              }}
            /> */}
            <View
              style={{
                fontSize: "18px",
              }}
            >
              <Text>{data?.number}</Text>
              <Text>{`${data?.title} - ${
                data?.componentTypeName ? data?.componentTypeName : ""
              }`}</Text>
            </View>
          </View>
          <View style={styles.PageHeaderIdentication}>
            <LocalOfferOutlinedIcon sx={{ fontSize: "40px" }} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <Text>ID EVENTO</Text>
              <Text>{data.eventId}</Text>
            </View>
          </View>
        </View>

        <View style={styles.ContentContainer}>
          {/* LATERAL LEFT */}
          <View style={styles.PageDrawer}>
            <View
              style={{
                display: "flex",
                transform: "rotate(-90deg)",
                minWidth: "250px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Text style={styles.PageDrawerText}>
                {data.drawer ? data.drawer : "Ubicación"}
              </Text>
            </View>
          </View>

          <View style={styles.PageContent}>
            {/* CENTER */}
            <View style={styles.PageContentCenter}>
              <View style={styles.PageContentCenterMedia}>
                <View style={styles.PageContentCenterMediaImgContainer}>
                  {data.media.length &&
                    data.media.map((it) => (
                      <Image
                        src={it.url}
                        style={{
                          minWidth: "100px",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    ))}
                </View>
              </View>

              <View style={styles.PageContentCenterDescription}>
                <Text
                  style={{
                    fontSize: "12px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontWeight: "900",
                  }}
                >
                  Detalles de la inspección:
                </Text>
                <View>
                  {dataDetails(data)
                    .filter((it) => it.show)
                    .map((item) => (
                      <Text>
                        <NeuronaIconD width={20} height={20} /> {item.name}{" "}
                        {isUrl(item.value) ? (
                          <Link
                            src={item.value}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Enlace
                          </Link>
                        ) : (
                          item.value
                        )}
                      </Text>
                    ))}
                </View>
              </View>
            </View>

            {/* LATERAL RIGHT */}
            <View style={styles.PageContentRight}>
              <View style={styles.PageContentRightResults}>
                <Text
                  style={{
                    fontSize: "12px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontWeight: "900",
                  }}
                >
                  Resultado detección
                </Text>
                <View style={styles.PageContentRightResultsBox1}>
                  {getResultInspection(data)?.map((item) => {
                    return item;
                  })}
                </View>
                <Text
                  style={{
                    fontSize: "12px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontWeight: "900",
                  }}
                >
                  Fecha detección
                </Text>
                <View style={styles.PageContentRightResultsBox2}>
                  <Text>{new Date(data.createdAt).toDateString()}</Text>
                </View>
              </View>
              <View style={styles.PageContentRightImgFooter}>
                <Image
                  src={DigitalTransformation8}
                  alt="decimetrix digital transformation logo"
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

// Create styles
const styles = StyleSheet.create({
  PageContainer: {
    width: "100%",
    height: "69%",
    flexDirection: "column",
    backgroundColor: "#20d0d0",
    fontSize: "10px",
    gap: "2px",
  },
  PageHeader: {
    width: "100%",
    minHeight: "10%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#ffffff",
    backgroundColor: "#19213c",
  },
  PageHeaderTitle: {
    width: "70%",
    minHeight: "80px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#ffffff",
    marginLeft: "20px",
  },
  PageHeaderIdentication: {
    width: "30%",
    minHeight: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
  },
  ContentContainer: {
    width: "100%",
    maxHeight: "85%",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    gap: "2px",
  },
  PageDrawer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    color: "#ffffff",
    backgroundColor: "#104862",
  },
  PageDrawerText: {
    objectFit: "fill",
    fontSize: "15px",
    fontWeight: "bold",
  },
  PageContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: "#ffffff",
  },
  PageContentCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  PageContentCenterMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  PageContentCenterMediaImgContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  PageContentCenterDescription: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  PageContentRight: {
    minWidth: "100px",
    width: "30%",
  },
  PageContentRightResults: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    fontSize: "15px",
    paddingTop: "20px",
  },
  PageContentRightResultsBox1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "150px",
    fontSize: "12px",
    height: "30%",
    border: "2px solid #20d0d0",
  },
  PageContentRightResultsBox2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "70px",
    height: "100px",
    fontSize: "12px",
    border: "2px solid #20d0d0",
  },
  PageContentRightImgFooter: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-end",
    width: "100%",
    height: "200px",
    marginTop: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingBottom: "10px",
  },
});

export default TemplateEventLeak;
