import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR } from "utils/const";

export const OperationsContainer = styled.div`
  width: 80%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OperationsExpression = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  #gc-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      display: flex;
      align-items: flex-start;
    }
  }
  #gc-select {
    width: 100%;
    padding: 0 5px;
    padding-left: 20px;
    font-size: 1.4rem;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
  }
`;

export const MathfieldContainer = styled.div`
  min-width: 400px;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  #formula {
    text-align: center;
    min-width: 400px;
    width: 60%;
    min-height: 100px;
    --keyboard-zindex: 3000;
    --caret-color: ${BLACK_COLOR};
    --selection-background-color: rgba(49, 166, 55, 0.5);
    --placeholder-color: rgba(49, 166, 55, 0.5);
    --keyboard-toolbar-font-size: 5rem;
    font-size: 40px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    position: relative;
  }

  #formula::part(virtual-keyboard-toggle):hover::after {
    display: none;
  }

  #formula::part(container) {
    position: relative;
    font-size: 3rem;
    padding-left: 20px;
    width: 95%;
    display: flex;
  }
  math-field:focus-within {
    outline: 2px solid #31a6377f;
    border-radius: 3px;
    width: 100%;
  }
`;

export const MathfieldDisclaimer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const KeyboardSpace = styled.div`
  min-width: 500px;
  width: 100%;
  @media only screen and (min-width: 400px) {
    height: 150px;
  }
  @media only screen and (min-width: 1000px) {
    height: 300px;
  }
`;
