import { config } from "config.js";
import axios from "axios";

const findComponents = async ({ id }) => {
  try {
    const token = localStorage.getItem("token");

    const urlSearchParams = new URLSearchParams();

    if (id) urlSearchParams.append("id", id);
    const url = `${
      config.URL_BACKEND_PG
    }api/v1/point-components?${urlSearchParams.toString()}`;

    const res = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    return await res.data;
  } catch (error) {
    throw new Error("Error find components");
  }
};

export default findComponents;
