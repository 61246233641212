import { BLACK_COLOR } from "utils/const";
import ReportIcon from "images/ReportIcon";
import CompanyManagerIcon from "images/CompanyManager";
import ConfigIcon from "images/ConfigIcon";

export const infoHubAdminDeci = [
  {
    title: "COMPANY MANAGERS",
    navigate: "/admin-companies",
    icon: <CompanyManagerIcon />,
    description: "Add a company manager",
    color: BLACK_COLOR,
  },
  {
    title: "CONFIGURATION",
    navigate: "/config-admin",
    icon: <ConfigIcon />,
    description: "Configure app settings, customization",
    color: BLACK_COLOR,
  },
  {
    title: "REPORTS",
    navigate: "#",
    icon: <ReportIcon />,
    description: "View reports",
    color: BLACK_COLOR,
  },
];
