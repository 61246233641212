import React, { createContext, useContext, useEffect, useState } from "react";
import { config } from "config";
import useSwr from "swr";
import { useSWRConfig } from "swr";

import { updateBackendPreferences } from "services/updateBackendPreferences";

const TooltipContext = createContext();

export const useTooltip = () => useContext(TooltipContext);

export const TooltipProvider = ({ children }) => {
  const [tooltipColor, setTooltipColor] = useState("#3f51b5");
  const [tooltipFont, setTooltipFont] = useState("#ffffff");
  const [tooltipSize, setTooltipSize] = useState(16);
  const [configPreferences, setConfigPreferences] = useState([]);
  const [globalTooltipActive, setGlobalTooltipActive] = useState(true);
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { mutate } = useSWRConfig();

  //Para todos los usuarios de un Id adminCompany en particular
  const url = `${config.URL_BACKEND_MG}config-preferences?adminCompanyId=${adminCompanyId}`;

  const { data: confgData, error: errorConfigData } = useSwr(url);

  useEffect(() => {
    if (!errorConfigData && confgData && confgData.length > 0) {
      setConfigPreferences(confgData);
    }
  }, [errorConfigData, confgData]);

  useEffect(() => {
    if (configPreferences.length > 0) {
      setTooltipColor(configPreferences[0].tooltip.backGround);
      setTooltipFont(configPreferences[0].tooltip.tooltipFont);
      setTooltipSize(configPreferences[0].tooltip.fontSize);
      setGlobalTooltipActive(configPreferences[0].tooltip.globalActive);
    }
  }, [configPreferences]);

  const handleTooltipColor = async (color) => {
    try {
      setTooltipColor(color);
      await updateBackendPreferences({
        id: configPreferences[0]._id,
        body: {
          ...configPreferences[0],
          tooltip: { ...configPreferences[0].tooltip, backGround: color },
        },
      });

      mutate(url);
    } catch (error) {
      console.error("Failed to update tooltip color:", error);
    }
  };
  const handleTooltipFont = async (fontColor) => {
    try {
      setTooltipFont(fontColor);
      await updateBackendPreferences({
        id: configPreferences[0]._id,
        body: {
          ...configPreferences[0],
          tooltip: { ...configPreferences[0].tooltip, tooltipFont: fontColor },
        },
      });

      mutate(url);
    } catch (error) {
      console.error("Failed to update tooltip font color:", error);
    }
  };

  const handleAllColor = async (color, fontColor) => {
    try {
      setTooltipColor(color);
      await updateBackendPreferences({
        id: configPreferences[0]._id,
        body: {
          ...configPreferences[0],
          tooltip: {
            ...configPreferences[0].tooltip,
            backGround: color,
            tooltipFont: fontColor,
          },
        },
      });
      mutate(url);
    } catch (error) {
      console.error("Failed to update tooltip color:", error);
    }
  };

  const handleTooltipSize = async (size) => {
    try {
      await updateBackendPreferences({
        id: configPreferences[0]._id,
        body: {
          ...configPreferences[0],
          tooltip: { ...configPreferences[0].tooltip, fontSize: size },
        },
      });
      mutate(url);
      setTooltipSize(size);
    } catch (error) {
      console.error("Failed to update tooltip size:", error);
    }
  };

  const handleGlobalTooltip = async () => {
    try {
      await updateBackendPreferences({
        id: configPreferences[0]._id,
        body: {
          ...configPreferences[0],
          tooltip: {
            ...configPreferences[0].tooltip,
            globalActive: !globalTooltipActive,
          },
        },
      });
      setGlobalTooltipActive((prevActive) => !prevActive);
      mutate(url);
    } catch (error) {
      console.error("Failed to toggle global tooltip active state:", error);
    }
  };

  return (
    <TooltipContext.Provider
      value={{
        globalTooltipActive,
        handleTooltipColor,
        handleTooltipFont,
        handleTooltipSize,
        handleGlobalTooltip,
        handleAllColor,
        tooltipColor,
        tooltipFont,
        tooltipSize,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
};
