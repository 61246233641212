import { useCallback, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AddField, Button } from "../configAdminStyles";
import OperationsColumns from "./OperationsElements";
import { ButtonInfo } from "../../../components/ElementDetails/ObjectDetails/ObjectDetailsElements";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import OpenDialog from "components/Dialogs/OpenDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tags from "./fieldTags";
// Consts
import fieldTypes, {
  fieldExpression,
  enableTypeFiles,
  enableTypeCurrency,
} from "utils/fieldTypes";
import useGetConstantNames from "hooks/fetchConstantsNames";
import { BLACK_COLOR, INFORMATION_COLOR } from "utils/const";
import ClickToolTip from "components/ToolTips/ClickToolTip";
import { TYPE_POINTS } from "utils/constStrings";
import getReservedWords from "utils/defaultColumns";
import { ConteinerLine, LineConteiner } from "./FieldsStyle";

const reservedWords = getReservedWords();

export default function DescriptionOpenDialog({
  field,
  setField,
  setDialog,
  fields,
  update,
  from,
}) {
  const [tag, setTag] = useState("");
  const [possible, setPossible] = useState("select");
  const [step, setStep] = useState(0);
  const [errorName, setErrorName] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [typeField, setTypeField] = useState("string");
  const [inputValue, setInputValue] = useState("");
  const [expression, setExpression] = useState("");
  const [existHeader, setExistHeader] = useState(false);
  const [existQAQC, setExistQAQC] = useState(false);

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");

  const { data: constantNames, error: errorConstantNames } =
    useGetConstantNames({
      id: adminCompanyId,
      idDecimetrix: adminDecimetrixId,
    });

  const handleChangeType = useCallback(
    (e) => {
      setTypeField(e.target.value);
      setField((current) => ({
        ...current,
        type: e.target.value,
        options: [],
        possible: [],
        min: 0,
        max: 1,
        operation: "",
      }));
    },
    [setTypeField, setField]
  );
  const handleChangeAlias = (e) => {
    setField((current) => ({
      ...current,
      alias: e.target.value,
    }));
  };

  const handleChangeName = (e) => {
    setInputValue(e.target.value);
    setField((current) => ({
      ...current,
      name: e.target.value,
    }));
  };

  const handleChangeRequired = (e) => {
    setField((current) => ({
      ...current,
      required: e.target.checked,
    }));
  };

  const handleChangeMobile = (e) => {
    setField((current) => ({
      ...current,
      mobile: e.target.checked,
    }));
  };

  const handleChangeOnPDF = (e) => {
    setField((current) => ({
      ...current,
      onPDF: { where: "description", state: e.target.checked },
    }));
  };

  const handleChangePDFSelect = (e) => {
    // switching onPDF props when othe selected the same
    const fieldsCopy = fields;
    for (let f in fieldsCopy) {
      if (fieldsCopy[f]?.onPDF?.where === e.target.value) {
        const fIndex = fields.indexOf(fieldsCopy[f]);
        if (
          fIndex &&
          (e.target.value === "righttop" || e.target.value === "description")
        )
          break;
        const aux = fields[fIndex];
        aux.onPDF.where = "";
        aux.onPDF.state = false;
        fields[fIndex] = aux;
      }
    }
    //change where of onPDF attribute of field
    setField((current) => ({
      ...current,
      onPDF: { ...current.onPDF, where: e.target.value },
    }));
  };

  const pdfSelectOptions = [
    { cmp: <option value="title">Title</option> },
    { cmp: <option value="drawer">Drawer</option> },
    { cmp: <option value="media">Media</option> },
    {
      cmp: (
        <option value="description" selected>
          Description
        </option>
      ),
    },
    { cmp: <option value="righttop">Right top</option> },
  ];

  const handleTag = (e) => {
    setTag(e.target.value);
  };

  const addTag = () => {
    setField((current) => ({
      ...current,
      options: [...current.options, tag],
    }));
    setTag("");
  };

  const handleDeleteTag = (tag) => {
    setField((current) => ({
      ...current,
      options: current.options.filter((option) => option !== tag),
    }));
  };

  const handleMin = (e) => {
    let value = parseInt(e.target.value);
    if (value > field.max) {
      value = field.max - 1;
    }
    setField((current) => ({
      ...current,
      min: value,
    }));
  };

  const handlemax = (e) => {
    let value = parseInt(e.target.value);
    if (value < field.min) {
      value = field.min + 1;
    }
    setField((current) => ({
      ...current,
      max: value,
    }));
  };

  const handleStep = (e) => {
    setStep(e.target.value);
    setField((current) => ({
      ...current,
      options: [e.target.value],
    }));
  };

  const handleNotification = (e) => {
    setField((current) => ({
      ...current,
      notification: e.target.checked,
    }));
  };

  const handleHeader = useCallback(
    (e) => {
      setField((current) => ({
        ...current,
        header: e.target.checked,
      }));
    },
    [setField]
  );

  const handleQAQC = useCallback(
    (e) => {
      setField((current) => ({
        ...current,
        QAQC: e.target.checked,
      }));
    },
    [setField]
  );

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    setField((current) => ({
      ...current,
      possible: [...current.possible, value],
    }));
    setPossible("select");
  };

  const handleDeletePosible = (value) => {
    setField((current) => ({
      ...current,
      possible: current.possible.filter((posible) => posible !== value),
    }));
  };

  useEffect(() => {
    const header = fields.find((field) => field.header === true);
    if (header) {
      setExistHeader(header);
    } else {
      setExistHeader({ header: false });
    }
  }, [handleHeader, fields, handleChangeType]);

  useEffect(() => {
    const QAQC = fields.find((field) => field.QAQC === true);
    if (QAQC) {
      setExistQAQC(QAQC);
    } else {
      setExistQAQC({ QAQC: false });
    }
  }, [handleQAQC, fields, handleChangeType]);

  useEffect(() => {
    setField((current) => ({
      ...current,
      operation: expression,
    }));
  }, [expression, setField]);

  //validates invalid characters in name
  useEffect(() => {
    const expression = /^[a-zA-Z0-9]+$/;
    if (inputValue !== "" && !expression.test(inputValue)) {
      setInvalidName(true);
    } else {
      setInvalidName(false);
    }
  }, [inputValue, typeField]);

  //validates non-repeated name in the fields
  useEffect(() => {
    const existField = fields.find((field_) => field_.name === field.name);
    const existConstant = constantNames?.find((name) => name === field.name);
    const existReservedWord = reservedWords?.find(
      (it) => it.name === field.name
    );
    if (
      !update &&
      (field.name === "" ||
        field.alias === "" ||
        existField ||
        invalidName ||
        existConstant ||
        existReservedWord)
    ) {
      setDialog((current) => {
        const { agree, ...actions } = current.actions;
        return {
          ...current,
          actions: { ...actions },
        };
      });
      if (existField || existConstant || existReservedWord) {
        setErrorName(true);
        return;
      }
      setErrorName(false);
      return;
    }
    setErrorName(false);
    setDialog((current) => ({
      ...current,
      actions: {
        ...current.actions,
        agree: update ? "Update" : "Add",
      },
    }));
  }, [
    field.name,
    field.alias,
    setDialog,
    fields,
    update,
    typeField,
    invalidName,
    inputValue,
    constantNames,
    errorConstantNames,
  ]);
  const messageAttributeName =
    "The Field Attribute name will be used in the Database. No spaces nor special characters are allowed. If want to see a name with special characters or spaces please use the Alias field. (Required)";
  const messageDisplayName =
    "This field is the one that the end users will see in the Digital Twin. Special Charachters are allowed. (Required)";
  const messageFieldTags = "The list of tags for each field of this library";
  const WarningSpan = ({ content }) => (
    <span
      style={{
        maginBottom: "10px",
        position: "fixed",
        marginTop: "60px",
        width: "100%",
        textAlign: "center",
      }}
    >
      {content}
    </span>
  );

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedOptions = Array.from(field.options);
    const [movedOption] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, movedOption);

    setField((current) => ({
      ...current,
      options: reorderedOptions,
    }));
  };

  const handleFieldTags = (tags) => {
    setField((current) => ({
      ...current,
      tags,
    }));
  };

  return (
    <AddField>
      <div className="required">
        <>
          <div className="name" style={{ display: "flex", width: "70%" }}>
            <div>
              Attribute Name: <span className="color-required">*</span>
              <InfoField
                title={"Atribute Name"}
                message={messageAttributeName}
              />{" "}
            </div>
            <div className="input">
              <input
                className="input-field"
                type="text"
                value={field.name}
                onChange={handleChangeName}
                disabled={update}
              />
              {errorName && <WarningSpan content="This name already exist" />}
              {invalidName && (
                <WarningSpan content="Invaid characters for name field" />
              )}
            </div>
          </div>
          <div className="name" style={{ display: "flex", width: "70%" }}>
            <div htmlFor="">
              Display Name: <span className="color-required">*</span>
              <InfoField title={"Display Name"} message={messageDisplayName} />
            </div>
            <div className="input">
              <input
                className="input-field"
                type="text"
                value={field.alias}
                onChange={handleChangeAlias}
              />
            </div>
          </div>
        </>
        <div className="type">
          <label htmlFor="">Attribute Type: </label>
          <select
            className="input-field select"
            name=""
            onChange={handleChangeType}
            value={field.type}
          >
            {Object.entries(fieldTypes).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
            {fields.filter((elm) => elm.type === fieldExpression.typeCondition)
              .length > 0 && (
              <option value={fieldExpression.value}>
                {fieldExpression.text}
              </option>
            )}
          </select>
        </div>

        <div id="checkbox-container">
          <div
            className="required2 input-checkbox"
            id="input-checkbox-required"
          >
            <label>Mobile?</label>
            <input
              className="input-field"
              type="checkbox"
              checked={field.mobile}
              onChange={handleChangeMobile}
            />
          </div>
          <div
            className="required2 input-checkbox"
            id="input-checkbox-required"
          >
            <label>Required?</label>
            <input
              className="input-field"
              type="checkbox"
              checked={field.required}
              onChange={handleChangeRequired}
            />
          </div>

          <div
            className="required2 input-checkbox"
            id="input-checkbox-required"
          >
            <label>Report?</label>
            <input
              className="input-field"
              type="checkbox"
              checked={field.onPDF.state}
              onChange={handleChangeOnPDF}
            />
            {field.onPDF.state && (
              <select
                className="input-select"
                name="select-pdf-sel"
                onChange={handleChangePDFSelect}
                value={field?.onPDF.where}
              >
                {pdfSelectOptions.map((it) => it.cmp)}{" "}
              </select>
            )}
          </div>
        </div>
        <Tags
          field={field}
          handleFieldTags={handleFieldTags}
          info={<InfoField title={"Field tag"} message={messageFieldTags} />}
          update={update}
        />
        {field.type === "operation" && (
          <OperationsColumns
            fields={fields}
            setFields={setField}
            setExpression={setExpression}
            update={update}
            field={field}
            setField={setField}
          />
        )}
      </div>
      {field.type === "lineTag" && (
        <>
          <div className="required">
            <div className="name" style={{ display: "flex", width: "70%" }}>
              <div htmlFor="">
                Line Preview <ButtonInfo style={{ color: "white" }} />{" "}
                <ClickToolTip title="This line..." placement="top">
                  <ContactSupportIcon
                    sx={{
                      fontSize: "1.6rem",
                      color: `${INFORMATION_COLOR}`,
                      ml: "-7px",
                      mb: "15px",
                    }}
                  />
                </ClickToolTip>
              </div>
              <ConteinerLine>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <LineConteiner>
                    <div className="line">
                      {" "}
                      <div className="titlecontent">
                        <p className="title">{field.alias}</p>
                      </div>
                    </div>
                  </LineConteiner>
                </div>
              </ConteinerLine>
            </div>
          </div>
        </>
      )}
      {field.type === "file" && (
        <div>
          <div className="label">
            <label htmlFor="">Possible: </label>
            <select value={possible} onChange={handleChangeSelect}>
              <option value="select" disabled>
                Select
              </option>
              {Object.entries(enableTypeFiles).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div className="tags">
            {field.possible.map((option, index) => (
              <div className="tag">
                <span
                  style={{
                    color: BLACK_COLOR,
                  }}
                  key={index}
                >
                  {option}
                </span>
                <IoMdClose
                  onClick={() => handleDeletePosible(option)}
                  style={{ cursor: "pointer" }}
                  color="red"
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {field.type === "currency" && (
        <div>
          <div className="label">
            <label htmlFor="">Possible: </label>
            <select value={possible} onChange={handleChangeSelect}>
              <option value="select" disabled>
                Select
              </option>
              {Object.entries(enableTypeCurrency).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div className="tags">
            {field.possible.map((option, index) => (
              <div className="tag">
                <span key={index}>{option}</span>
                <IoMdClose
                  onClick={() => handleDeletePosible(option)}
                  style={{ cursor: "pointer" }}
                  color="red"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {field.type === "select" && (
        <div>
          <div className="label">
            <label htmlFor="">Options: </label>
            <ButtonInfo style={{ color: "white" }}>
              <ClickToolTip
                title="The purple frame marks the first position"
                placement="top"
              >
                <ContactSupportIcon
                  sx={{
                    fontSize: "1.6rem",
                    color: `${INFORMATION_COLOR}`,
                    ml: "-7px",
                    mb: "15px",
                  }}
                />
              </ClickToolTip>
            </ButtonInfo>
            <input type="text" value={tag} onChange={handleTag} />
            <Button onClick={addTag}>Add</Button>
          </div>
          <div className="tags" style={{ display: "flex" }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="tags" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {field.options.map((option, index) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className={`tag ${
                                index === 0 ? "first-tag" : ""
                              }`}
                            >
                              <span>{option}</span>
                              <IoMdClose
                                onClick={() => handleDeleteTag(option)}
                                style={{ cursor: "pointer" }}
                                color="red"
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>{" "}
            </DragDropContext>
          </div>
        </div>
      )}

      {field.type === "range" && (
        <div className="label">
          <label htmlFor="">Max:</label>
          <input type="number" onChange={handlemax} value={field.max} />
          <label htmlFor="">Min: </label>
          <input type="number" onChange={handleMin} value={field.min} />
          <label htmlFor="">Step: </label>
          <input type="number" onChange={handleStep} value={step} />
        </div>
      )}

      {field.type === "notification" && (
        <div className="notification">
          <label htmlFor="notification">
            Do you want to have notifications?
          </label>
          <div className="required2" id="input-checkbox" width="50%">
            <input
              id="notification"
              type="checkbox"
              checked={field.notification}
              onChange={handleNotification}
            />
          </div>
        </div>
      )}

      {((field.type === "string" && !existHeader?.header) ||
        existHeader?.name === field?.name) && (
        <div className="notification">
          <label htmlFor="string-header">
            Do you want to use this field as the header/label??
          </label>
          <div className="required2" id="input-checkbox" width="50%">
            <input
              id="string-header"
              type="checkbox"
              checked={field.header}
              onChange={handleHeader}
            />
          </div>
        </div>
      )}

      {((field.type === "check" && !existQAQC?.QAQC) ||
        existQAQC?.name === field?.name) &&
        (from === TYPE_POINTS.object || from === TYPE_POINTS.component) && (
          <div className="notification">
            <label htmlFor="string-header">
              Do you want to use this field as the QAQC?
            </label>
            <div
              className="required2 input-checkbox"
              id="input-checkbox-mobile"
            >
              <input
                id="string-header"
                type="checkbox"
                checked={field.QAQC}
                onChange={handleQAQC}
              />
            </div>
          </div>
        )}
    </AddField>
  );
}

const InfoField = ({ title, message }) => {
  const [dialog, setDialog] = useState({
    open: false,
    actions: { disagree: "Close" },
  });
  const handleShow = () => {
    setDialog((current) => ({ ...current, open: true }));
  };
  return (
    <ButtonInfo>
      {
        <ContactSupportIcon
          sx={{
            fontSize: "1.8rem",
            color: INFORMATION_COLOR,
          }}
          onClick={handleShow}
        />
      }
      <OpenDialog
        openDialog={dialog.open}
        setOpenDialog={setDialog}
        content={{
          title,
          description: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <div>
                <p>{message}</p>
              </div>
            </div>
          ),
          ...dialog.actions,
        }}
      />
    </ButtonInfo>
  );
};
