import axios from "axios";
import { config } from "config.js";
const deleteGlobalConstant = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/global-constants/${id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    return { error };
  }
};

export default deleteGlobalConstant;
