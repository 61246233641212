import { CardDonutPreview, ContainerPreviewChart } from "./indexStyle";
import { useState } from "react";
import CustomButton from "components/Buttons/CustomButton";
import { Doughnut } from "react-chartjs-2";
import { useSetCurrentStyle } from "./Hooks/useSetCurrentStyle";
import { createRgbaColor } from "pages/Dashboard/helpers/createRgbaColor";
import { getGradient } from "helpers/getGradient";
import { fixedDecimalNumber } from "pages/Dashboard/helpers/fixedDecimalNumber";
import ClickToolTip from "components/ToolTips/ClickToolTip";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { INFORMATION_COLOR } from "utils/const";

const PreviewDonutChart = ({
  dataChart,
  setChartEditDialog,
  maxHeight,
  maxWidth,
}) => {
  const {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
  } = useSetCurrentStyle({ dataChart, setChartEditDialog });

  return (
    currentStyleLineDataChart && (
      <ContainerPreviewChart heightChart={maxHeight} widthChart={maxWidth}>
        <div className="section-chart-button">
          <div className="preview-chart">
            <div className="chart">
              <CardDonutPreview>
                <Doughnut
                  data={{
                    labels: currentStyleLineDataChart.labels,
                    datasets: currentStyleLineDataChart.datasets.map((item) => {
                      if (
                        item.hasOwnProperty("colorsByLabel") &&
                        item.colorsByLabel.length > 0
                      ) {
                        return {
                          ...item,
                          data: item.data.map((data) => {
                            if (typeof data === "object") {
                              return (data = data?.value);
                            } else {
                              return data;
                            }
                          }),
                          backgroundColor: item.dataGradient.display
                            ? item.colorsByLabelGradient
                            : item.colorsByLabel,
                        };
                      } else {
                        return item;
                      }
                    }),
                  }}
                  options={{
                    responsive: true,
                    aspectRatio: 2,
                    layout: {
                      padding: {
                        left: 10,
                        right: 20,
                        top: 10,
                        bottom: 10,
                      },
                    },
                    plugins: {
                      datalabels: {
                        formatter: (value, ctx) => {
                          const datasets = ctx.chart.data.datasets;
                          const total = datasets.reduce((total, dataset) => {
                            return (
                              total +
                              dataset.data.reduce(
                                (datasetTotal, datapoint) =>
                                  datasetTotal + datapoint,
                                0
                              )
                            );
                          }, 0);

                          const percentage = ((value / total) * 100).toFixed(1);

                          if (
                            currentStyleLineDataChart.chartStyle.datalabels
                              .showPercentage
                          ) {
                            return [
                              `${fixedDecimalNumber({
                                number: value,
                                numDecimals:
                                  ctx.dataset?.datalabels?.numDecimals,
                              })}; ${percentage}%`,
                            ];
                          } else {
                            return fixedDecimalNumber({
                              number: value,
                              numDecimals: ctx.dataset?.datalabels?.numDecimals,
                            });
                          }
                        },

                        color: function (context) {
                          return context.dataset?.datalabels?.colorTotal;
                        },
                        anchor: function (context) {
                          return context.dataset?.datalabels?.positionTotal;
                        },
                        align: function (context) {
                          return context.dataset?.datalabels?.positionTotal;
                        },
                        offset: function (context) {
                          return context.dataset?.datalabels?.offsetTotal;
                        },
                        font: function (context) {
                          return {
                            size: context.dataset?.datalabels?.fontSizeTotal,
                          };
                        },
                        backgroundColor: function (context) {
                          if (
                            context.dataset?.datalabels?.displayTotal &&
                            context.dataset?.datalabels?.displayBackgroundTotal
                          ) {
                            return context.dataset?.datalabels
                              ?.backgroundColorTotal;
                          }
                        },
                        borderWidth: 1,
                        borderColor: function (context) {
                          if (
                            context.dataset?.datalabels?.displayTotal &&
                            context.dataset?.datalabels?.displayBackgroundTotal
                          ) {
                            return context.dataset?.datalabels
                              ?.backgroundColorTotal;
                          }
                        },
                        borderRadius: 3,
                        display: (context) => {
                          if (context.dataset?.datalabels?.displayTotal) {
                            const dataset = context.dataset;
                            const value = dataset.data[context.dataIndex];
                            const total = dataset.data.reduce(
                              (acc, data) => acc + data,
                              0
                            );
                            const percentage = (value / total) * 100;
                            return percentage > 1;
                          } else {
                            return false;
                          }
                        },
                      },
                      title: {
                        display: true,
                        color: currentStyleLineDataChart.chartStyle.colorTitle,
                        text: currentStyleLineDataChart.alias,
                        font: {
                          size: currentStyleLineDataChart.chartStyle
                            .fontSizeTitle,
                          family: "Montserrat",
                        },
                        padding: {
                          top: 20,
                        },
                      },
                      subtitle: {
                        display:
                          currentStyleLineDataChart.type === "sum"
                            ? true
                            : false,
                        text:
                          currentStyleLineDataChart?.columnsY.length > 0
                            ? `Sum ${currentStyleLineDataChart.columnsY[0]}`
                            : "",
                        font: {
                          size: 15,
                          family: "Montserrat",
                        },
                      },
                      legend: {
                        display: true,
                        onClick: function (e, legendItem, legend) {
                          const ci = legend.chart;

                          if (legendItem.isFromStyleLineDataChart) {
                            const meta = ci.getDatasetMeta(
                              legendItem.datasetIndex
                            );
                            meta.hidden =
                              meta.hidden === null
                                ? !ci.data.datasets[legendItem.datasetIndex]
                                    .hidden
                                : null;
                          } else {
                            const dataIndex = legendItem.index;
                            ci.data.datasets.forEach((dataset, i) => {
                              const dataMeta = ci.getDatasetMeta(i);
                              dataMeta.data[dataIndex].hidden =
                                !dataMeta.data[dataIndex].hidden;
                            });
                          }

                          ci.update();
                        },

                        labels: {
                          generateLabels: function (chart) {
                            let data = chart.data.datasets;
                            const labelsFromChartData = chart.data.labels.map(
                              (label, index) => {
                                let dataset = data.find(
                                  (ds) => ds.data[index] !== undefined
                                );

                                return {
                                  text: label,
                                  fillStyle: dataset.backgroundColor[index],
                                  hidden:
                                    chart.getDatasetMeta(0).data[index].hidden,
                                  index: index,
                                  isFromStyleLineDataChart: false,
                                };
                              }
                            );

                            // const labelsFromStyleLineDataChart =
                            //   currentStyleLineDataChart?.datasets.map(
                            //     (dataset, i) => {
                            //       const meta = chart.getDatasetMeta(i);
                            //       return {
                            //         text: dataset.alias,
                            //         // fillStyle: dataset.backgroundColor,
                            //         fillStyle: dataset.borderColor,
                            //         hidden: meta.hidden,
                            //         lineCap: dataset.borderCapStyle,
                            //         lineDash: dataset.borderDash,
                            //         lineDashOffset: dataset.borderDashOffset,
                            //         lineJoin: dataset.borderJoinStyle,
                            //         lineWidth: dataset.borderWidth,
                            //         strokeStyle: dataset.borderColor,
                            //         pointStyle: dataset.pointStyle,
                            //         datasetIndex: i,
                            //         fontStyle: meta.hidden
                            //           ? "italic"
                            //           : "normal",
                            //         fontColor: meta.hidden ? "#999" : "#666",
                            //         isFromStyleLineDataChart: true,
                            //       };
                            //     }
                            //   );

                            return [
                              ...labelsFromChartData,
                              // ...labelsFromStyleLineDataChart,
                            ];
                          },
                        },
                      },
                      afterDraw: (chart) => {
                        const ctx = chart.ctx;
                        const centerX =
                          (chart.chartArea.left + chart.chartArea.right) / 2;
                        const centerY =
                          (chart.chartArea.top + chart.chartArea.bottom) / 2;

                        // Sumar todos los valores de todos los datasets
                        const total = chart.data.datasets.reduce(
                          (totalSum, dataset) => {
                            return (
                              totalSum +
                              dataset.data.reduce(
                                (sum, value) => sum + value,
                                0
                              )
                            );
                          },
                          0
                        );

                        ctx.save();
                        ctx.font = "bold 16px Arial";
                        ctx.fillStyle = "black";
                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        ctx.fillText(`Total: ${total}`, centerX, centerY);
                        ctx.restore();
                      },

                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (context) {
                            const label2 =
                              currentStyleLineDataChart?.columnsY.length > 0
                                ? `Total ${currentStyleLineDataChart?.columnsY[0]}`
                                : ""; // Ejemplo de un segundo label con información adicional
                            const label1 = `${context.dataset.alias} : ${context.raw}`;

                            if (currentStyleLineDataChart.type === "sum") {
                              return [label2, label1];
                            } else {
                              return label1;
                            }
                          },
                        },
                        titleFont: {
                          size: currentStyleLineDataChart.chartStyle.tooltip
                            .fontSizeTitle,
                        },
                        bodyFont: {
                          size: currentStyleLineDataChart.chartStyle.tooltip
                            .fontSizeBody,
                        },
                        mode: "nearest",
                        intersect: true,
                        titleAlign: "center",
                        displayColors: false,
                      },
                    },
                    animation: {
                      duration: 1500,
                      easing: "easeInOutQuart",
                    },
                  }}
                />
              </CardDonutPreview>
            </div>
          </div>
          <div className="container-update">
            <CustomButton
              text={"Update Chart"}
              margin={0}
              onClick={handleUpdateChart}
            />
          </div>
        </div>
        <div className="editing-tools">
          <div className="groupe-styles">
            <div className="sections">
              <h3>Title</h3>
              <div className="section-style">
                <input
                  placeholder="Title Name"
                  className="input-title"
                  type="text"
                  value={currentStyleLineDataChart.alias}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      alias: e.target.value,
                    }))
                  }
                />
              </div>
              <br />
              <div className="section-style">
                <p>Title Color</p>
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.chartStyle.colorTitle}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      chartStyle: {
                        ...current.chartStyle,
                        colorTitle: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="section-style">
                <p>Title Size</p>
                <div>
                  <h4>
                    {currentStyleLineDataChart.chartStyle.fontSizeTitle} px
                  </h4>
                  <input
                    value={currentStyleLineDataChart.chartStyle.fontSizeTitle}
                    type="range"
                    step={1}
                    min={10}
                    max={60}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        chartStyle: {
                          ...current.chartStyle,
                          fontSizeTitle: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
              <div className="divider">
                <span className="divider-title">Colors By Main Category</span>
              </div>

              {currentStyleLineDataChart.columnX.backgroundColorHexadecimal.map(
                (elm, index) => {
                  return (
                    <div className="section-style">
                      <p>{currentStyleLineDataChart.columnX.values[index]}</p>
                      <input
                        className="alias"
                        type="color"
                        value={elm}
                        onChange={(e) => {
                          setCurrentStyleLineDataChart((current) => {
                            const newColorsByLabel = [
                              ...current.columnX.backgroundColor,
                            ];
                            const newColorsHexadecimalByLabel = [
                              ...current.columnX.backgroundColorHexadecimal,
                            ];
                            newColorsByLabel[index] = createRgbaColor({
                              hexadecimalColor: e.target.value,
                            });

                            newColorsHexadecimalByLabel[index] = e.target.value;
                            return {
                              ...current,
                              columnX: {
                                ...current.columnX,
                                backgroundColor: newColorsByLabel,
                                backgroundColorHexadecimal:
                                  newColorsHexadecimalByLabel,
                                colorsByLabelGradient: function (context) {
                                  const chart = context.chart;
                                  const { ctx, chartArea } = chart;
                                  if (!chartArea) {
                                    return;
                                  }
                                  return newColorsHexadecimalByLabel.map(
                                    (color) => {
                                      return getGradient({
                                        ctx,
                                        chartArea,
                                        color: color,
                                        colorStop: [1, 0.5, 0.5, 1],
                                      });
                                    }
                                  );
                                },
                              },
                              datasets: current.datasets.map((elm) => {
                                return {
                                  ...elm,
                                  backgroundColor: newColorsHexadecimalByLabel,
                                  backgroundColorHexadecimal:
                                    newColorsHexadecimalByLabel,
                                };
                              }),
                            };
                          });
                        }}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>

          <div className="groupe-styles">
            <div className="sections">
              <h3>Data Series</h3>
              <div className="section-style">
                <p>Series Name</p>
                <br />
                <br />
                <input
                  placeholder="Series Name"
                  className="input-title"
                  type="text"
                  value={currentStyleLineDataChart.datasets[0].alias}
                  onChange={(e) => {
                    setCurrentStyleLineDataChart((current) => {
                      const newDatasets = current.datasets.map((elm) => {
                        return {
                          ...elm,
                          alias: e.target.value,
                        };
                      });
                      return {
                        ...current,
                        datasets: newDatasets,
                      };
                    });
                  }}
                />
              </div>
              <br />
              <div className="divider">
                <span className="divider-title">Donut Options</span>
              </div>
              <div className="section-style">
                <p>Show Series</p>
                <input
                  className="alias"
                  checked={!currentStyleLineDataChart.datasets[0].hidden}
                  type="checkbox"
                  onChange={(e) => {
                    setCurrentStyleLineDataChart((current) => {
                      const newDatasets = current.datasets.map((elm) => {
                        return {
                          ...elm,
                          hidden: !currentStyleLineDataChart.datasets[0].hidden,
                        };
                      });
                      return {
                        ...current,
                        datasets: newDatasets,
                      };
                    });
                  }}
                />
              </div>
              <div className="section-style">
                <p>Border Color</p>
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.datasets[0].borderColor}
                  onChange={(e) => {
                    setCurrentStyleLineDataChart((current) => {
                      const newDatasets = current.datasets.map((elm) => {
                        return {
                          ...elm,
                          borderColor: e.target.value,
                        };
                      });
                      return {
                        ...current,
                        datasets: newDatasets,
                      };
                    });
                  }}
                />
              </div>
              <div className="section-style">
                <p>Line Border Width</p>
                <div>
                  <h4>
                    {currentStyleLineDataChart.datasets[0].borderWidth} px
                  </h4>
                  <input
                    value={currentStyleLineDataChart.datasets[0].borderWidth}
                    type="range"
                    step={1}
                    min={1}
                    max={15}
                    onChange={(e) => {
                      setCurrentStyleLineDataChart((current) => {
                        const newDatasets = current.datasets.map((elm) => {
                          return {
                            ...elm,
                            borderWidth: e.target.value,
                          };
                        });
                        return {
                          ...current,
                          datasets: newDatasets,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div className="section-style">
                <p>Cutout</p>
                <div>
                  <h4>{currentStyleLineDataChart.datasets[0].cutout}</h4>
                  <input
                    value={parseFloat(
                      currentStyleLineDataChart.datasets[0].cutout
                    )}
                    type="range"
                    step={1}
                    min={0}
                    max={100}
                    onChange={(e) => {
                      setCurrentStyleLineDataChart((current) => {
                        const newDatasets = current.datasets.map((elm) => {
                          return {
                            ...elm,
                            cutout: `${e.target.value}%`,
                          };
                        });
                        return {
                          ...current,
                          datasets: newDatasets,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              {/* 
                      {currentStyleLineDataChart.datasets[0].dataGradient.display && (
                        <>
                          <div className="section-style">
                            <p>Level Top</p>
                            <div>
                              <h4>{currentStyleLineDataChart.datasets[0].dataGradient.colorStop[0]} px</h4>
                              <input
                                value={currentStyleLineDataChart.datasets[0].dataGradient.colorStop[0]}
                                type="range"
                                step={0.1}
                                min={0}
                                max={1}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          const newColorStop = [
                                            ...elm.dataGradient.colorStop,
                                          ];
                                          newColorStop[0] = parseFloat(
                                            e.target.value
                                          );
                                          return {
                                            ...elm,
                                            dataGradient: {
                                              ...elm.dataGradient,
                                              colorStop: newColorStop,
                                            },
                                            backgroundColor: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }
                                              return getGradient({
                                                ctx,
                                                chartArea,
                                                color:
                                                  currentStyleLineDataChart.datasets[0].backgroundColorHexadecimal,
                                                colorStop:
                                                  currentStyleLineDataChart.datasets[0].dataGradient
                                                    .colorStop,
                                              });
                                            },
                                            colorsByLabelGradient: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }

                                              return elm.colorsHexadecimalByLabel.map(
                                                (color) => {
                                                  return getGradient({
                                                    ctx,
                                                    chartArea,
                                                    color: color,
                                                    colorStop: newColorStop,
                                                  });
                                                }
                                              );
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="section-style">
                            <p>Level Med Top</p>
                            <div>
                              <h4>{currentStyleLineDataChart.datasets[0].dataGradient.colorStop[1]} px</h4>
                              <input
                                value={currentStyleLineDataChart.datasets[0].dataGradient.colorStop[1]}
                                type="range"
                                step={0.1}
                                min={0}
                                max={1}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          const newColorStop = [
                                            ...elm.dataGradient.colorStop,
                                          ];
                                          newColorStop[1] = parseFloat(
                                            e.target.value
                                          );
                                          return {
                                            ...elm,
                                            dataGradient: {
                                              ...elm.dataGradient,
                                              colorStop: newColorStop,
                                            },
                                            backgroundColor: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }
                                              return getGradient({
                                                ctx,
                                                chartArea,
                                                color:
                                                  currentStyleLineDataChart.datasets[0].backgroundColorHexadecimal,
                                                colorStop:
                                                  currentStyleLineDataChart.datasets[0].dataGradient
                                                    .colorStop,
                                              });
                                            },

                                            colorsByLabelGradient: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }

                                              return elm.colorsHexadecimalByLabel.map(
                                                (color) => {
                                                  return getGradient({
                                                    ctx,
                                                    chartArea,
                                                    color: color,
                                                    colorStop: newColorStop,
                                                  });
                                                }
                                              );
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="section-style">
                            <p>Level med Bottom</p>
                            <div>
                              <h4>{currentStyleLineDataChart.datasets[0].dataGradient.colorStop[2]} px</h4>
                              <input
                                value={currentStyleLineDataChart.datasets[0].dataGradient.colorStop[2]}
                                type="range"
                                step={0.1}
                                min={0}
                                max={1}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.datasets.map((elm) => {
                                        const newColorStop = [
                                          ...elm.dataGradient.colorStop,
                                        ];
                                        newColorStop[2] = parseFloat(
                                          e.target.value
                                        );
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            dataGradient: {
                                              ...elm.dataGradient,
                                              colorStop: newColorStop,
                                            },
                                            backgroundColor: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }
                                              return getGradient({
                                                ctx,
                                                chartArea,
                                                color:
                                                  currentStyleLineDataChart.datasets[0].backgroundColorHexadecimal,
                                                colorStop:
                                                  currentStyleLineDataChart.datasets[0].dataGradient
                                                    .colorStop,
                                              });
                                            },

                                            colorsByLabelGradient: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }

                                              return elm.colorsHexadecimalByLabel.map(
                                                (color) => {
                                                  return getGradient({
                                                    ctx,
                                                    chartArea,
                                                    color: color,
                                                    colorStop: newColorStop,
                                                  });
                                                }
                                              );
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="section-style">
                            <p>Level Bottom</p>
                            <div>
                              <h4>{currentStyleLineDataChart.datasets[0].dataGradient.colorStop[3]} px</h4>
                              <input
                                value={currentStyleLineDataChart.datasets[0].dataGradient.colorStop[3]}
                                type="range"
                                step={0.1}
                                min={0}
                                max={1}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.datasets.map((elm) => {
                                        const newColorStop = [
                                          ...elm.dataGradient.colorStop,
                                        ];
                                        newColorStop[3] = parseFloat(
                                          e.target.value
                                        );
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            dataGradient: {
                                              ...elm.dataGradient,
                                              colorStop: newColorStop,
                                            },
                                            backgroundColor: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }
                                              return getGradient({
                                                ctx,
                                                chartArea,
                                                color:
                                                  currentStyleLineDataChart.datasets[0].backgroundColorHexadecimal,
                                                colorStop:
                                                  currentStyleLineDataChart.datasets[0].dataGradient
                                                    .colorStop,
                                              });
                                            },

                                            colorsByLabelGradient: function (
                                              context
                                            ) {
                                              const chart = context.chart;
                                              const { ctx, chartArea } = chart;
                                              if (!chartArea) {
                                                return;
                                              }

                                              return elm.colorsHexadecimalByLabel.map(
                                                (color) => {
                                                  return getGradient({
                                                    ctx,
                                                    chartArea,
                                                    color: color,
                                                    colorStop: newColorStop,
                                                  });
                                                }
                                              );
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )} */}
              <br />
            </div>
          </div>

          <div className="groupe-styles">
            <div className="sections">
              <h3>Total</h3>
              <div className="section-color-conditional">
                <p>View Total</p>
                <div>
                  <input
                    checked={
                      currentStyleLineDataChart?.chartStyle?.datalabels
                        ?.displayTotal
                    }
                    type="checkbox"
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,

                        chartStyle: {
                          ...current.chartStyle,
                          datalabels: {
                            ...current.chartStyle.datalabels,
                            displayTotal:
                              !currentStyleLineDataChart.chartStyle.datalabels
                                .displayTotal,
                            displayBackgroundTotal: false,
                            backgroundColorTotal: "rgba(255, 255, 255, 0)",
                            colorTotal: currentStyleLineDataChart.chartStyle
                              .datalabels.displayTotal
                              ? "#EEEEEE"
                              : "#000000",
                          },
                        },

                        datasets: current.datasets.map((elm) => {
                          return {
                            ...elm,
                            datalabels: {
                              ...elm.datalabels,
                              displayTotal: !elm.datalabels.displayTotal,
                              colorTotal: elm.datalabels.displayTotal
                                ? "#EEEEEE"
                                : "#000000",
                              displayBackgroundTotal: false,
                              backgroundColorTotal: "rgba(255, 255, 255, 0)",
                            },
                          };
                        }),
                      }))
                    }
                  />
                  <input
                    className="alias"
                    type="color"
                    value={currentStyleLineDataChart?.currentTotalFontColor}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        chartStyle: {
                          ...current.chartStyle,
                          datalabels: {
                            ...current.chartStyle.datalabels,
                            displayTotal: true,
                            colorTotal: e.target.value,
                          },
                        },
                        datasets: current.datasets.map((elm) => {
                          return {
                            ...elm,
                            datalabels: {
                              ...elm.datalabels,
                              displayTotal: true,
                              colorTotal: e.target.value,
                            },
                          };
                        }),
                      }))
                    }
                  />
                </div>
              </div>
              <div className="section-style">
                <p>Show Percentage</p>
                <input
                  className="alias"
                  checked={
                    currentStyleLineDataChart?.chartStyle?.datalabels
                      ?.showPercentage
                  }
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      chartStyle: {
                        ...current.chartStyle,
                        datalabels: {
                          ...current.chartStyle.datalabels,
                          showPercentage:
                            !currentStyleLineDataChart.chartStyle.datalabels
                              .showPercentage,
                        },
                      },
                    }))
                  }
                />
              </div>

              <div className="section-style">
                <p>Total Size</p>
                <div>
                  <h4>
                    {
                      currentStyleLineDataChart?.chartStyle?.datalabels
                        ?.fontSizeTotal
                    }{" "}
                    px
                  </h4>
                  <input
                    value={
                      currentStyleLineDataChart?.chartStyle?.datalabels
                        ?.fontSizeTotal
                    }
                    type="range"
                    step={1}
                    min={10}
                    max={50}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        chartStyle: {
                          ...current.chartStyle,
                          datalabels: {
                            ...current.chartStyle.datalabels,
                            fontSizeTotal: e.target.value,
                          },
                        },
                        datasets: current.datasets.map((elm) => {
                          return {
                            ...elm,
                            datalabels: {
                              ...elm.datalabels,
                              fontSizeTotal: e.target.value,
                            },
                          };
                        }),
                      }))
                    }
                  />
                </div>
              </div>
              <div className="section-style">
                <p>Position Total</p>
                <select
                  value={
                    currentStyleLineDataChart?.chartStyle?.datalabels
                      ?.positionTotal
                  }
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      chartStyle: {
                        ...current.chartStyle,
                        datalabels: {
                          ...current.chartStyle.datalabels,
                          positionTotal: e.target.value,
                        },
                      },
                      datasets: current.datasets.map((elm) => {
                        return {
                          ...elm,
                          datalabels: {
                            ...elm.datalabels,
                            positionTotal: e.target.value,
                          },
                        };
                      }),
                    }))
                  }
                >
                  <option value="end">Top</option>
                  <option value="center">Center</option>
                  <option value="start">Down</option>
                </select>
              </div>

              <div className="section-style">
                <p>Offset</p>
                <div>
                  <h4>
                    {
                      currentStyleLineDataChart?.datasets[0]?.datalabels
                        ?.offsetTotal
                    }{" "}
                    px
                  </h4>
                  <input
                    value={
                      currentStyleLineDataChart?.datasets[0]?.datalabels
                        ?.offsetTotal
                    }
                    type="range"
                    step={1}
                    min={-100}
                    max={100}
                    onChange={(e) => {
                      setCurrentStyleLineDataChart((current) => {
                        const newDatasets = current.datasets.map((elm) => {
                          return {
                            ...elm,
                            datalabels: {
                              ...elm.datalabels,
                              offsetTotal: e.target.value,
                            },
                          };
                        });
                        return {
                          ...current,
                          datasets: newDatasets,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div className="section-color-conditional">
                <p>Total Background</p>
                <div>
                  <input
                    checked={
                      currentStyleLineDataChart?.chartStyle?.datalabels
                        ?.displayBackgroundTotal
                    }
                    type="checkbox"
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        chartStyle: {
                          ...current.chartStyle,
                          datalabels: {
                            ...current.chartStyle.datalabels,
                            displayBackgroundTotal:
                              !current.chartStyle.datalabels
                                .displayBackgroundTotal,
                            backgroundColorTotal: current.chartStyle.datalabels
                              .backgroundColorTotal
                              ? "rgba(255, 255, 255, 0)"
                              : "#2df137",
                          },
                        },
                        datasets: current.datasets.map((elm) => {
                          return {
                            ...elm,
                            datalabels: {
                              ...elm.datalabels,
                              displayBackgroundTotal:
                                !elm.datalabels.displayBackgroundTotal,
                              backgroundColorTotal: elm.datalabels
                                .displayBackgroundTotal
                                ? "rgba(255, 255, 255, 0)"
                                : "#2df137",
                            },
                          };
                        }),
                      }))
                    }
                  />
                  <input
                    className="alias"
                    type="color"
                    value={currentStyleLineDataChart?.currentTotalBackground}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        chartStyle: {
                          ...current.chartStyle,
                          datalabels: {
                            ...current.chartStyle.datalabels,
                            displayBackgroundTotal: true,
                            backgroundColorTotal: e.target.value,
                          },
                        },
                        datasets: current.datasets.map((elm) => {
                          return {
                            ...elm,
                            datalabels: {
                              ...elm.datalabels,
                              displayBackgroundTotal: true,
                              backgroundColorTotal: e.target.value,
                            },
                          };
                        }),
                      }))
                    }
                  />
                </div>
              </div>
              <div className="section-style">
                <p>Num Decimals</p>
                <input
                  value={
                    currentStyleLineDataChart?.chartStyle?.datalabels
                      ?.numDecimals
                  }
                  type="number"
                  placeholder={"Number"}
                  min={0}
                  max={100}
                  onChange={(e) => {
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      chartStyle: {
                        ...current.chartStyle,
                        datalabels: {
                          ...current.chartStyle.datalabels,
                          numDecimals: parseFloat(e.target.value),
                        },
                      },
                      datasets: current.datasets.map((elm) => {
                        return {
                          ...elm,
                          datalabels: {
                            ...elm.datalabels,
                            numDecimals: parseFloat(e.target.value),
                          },
                        };
                      }),
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="groupe-styles">
            <div className="sections">
              <h3>Tooltip</h3>
              <div className="section-style">
                <p>Title Size</p>
                <div>
                  <h4>
                    {
                      currentStyleLineDataChart?.chartStyle?.tooltip
                        ?.fontSizeTitle
                    }{" "}
                    px
                  </h4>
                  <input
                    value={
                      currentStyleLineDataChart?.chartStyle?.tooltip
                        ?.fontSizeTitle
                    }
                    type="range"
                    step={1}
                    min={10}
                    max={40}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        chartStyle: {
                          ...current.chartStyle,
                          tooltip: {
                            ...current.chartStyle.tooltip,
                            fontSizeTitle: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                </div>
              </div>
              <div className="section-style">
                <p>Body Size</p>
                <div>
                  <h4>
                    {
                      currentStyleLineDataChart?.chartStyle?.tooltip
                        ?.fontSizeBody
                    }{" "}
                    px
                  </h4>
                  <input
                    value={
                      currentStyleLineDataChart?.chartStyle?.tooltip
                        ?.fontSizeBody
                    }
                    type="range"
                    step={1}
                    min={10}
                    max={40}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        chartStyle: {
                          ...current.chartStyle,
                          tooltip: {
                            ...current.chartStyle.tooltip,
                            fontSizeBody: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerPreviewChart>
    )
  );
};

export default PreviewDonutChart;
