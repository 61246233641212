import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { CustomizeToggleButtonGroup } from "../DigitalTwin/userRoutes";
import ListObjects from "../DigitalTwin/ListObjects";
import ListLines from "../DigitalTwin/Lines/ListLines";
import ListRegions from "../DigitalTwin/ListRegions";
import { ItemList } from "../DigitalTwin/UserRoutesElements";
import ListComponents from "components/DigitalTwin/ListComponents";
import ListEvents from "components/DigitalTwin/ListEvents";

import { Title } from "./ContentDialogStyle";
import { SECOND_COLOR } from "utils/const";
import { StyleColor2 } from "Styles";
import { useContext } from "react";
import { ThemeContext } from "App";

export const ContentInformation = ({ section, handleTabInformation }) => {
  const { theme } = useContext(ThemeContext);
  return {
    title: "Information",
    description: (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <CustomizeToggleButtonGroup
            value={section}
            onChange={handleTabInformation}
            padding={4}
            sx={{
              "& .css-1wqhd87-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  color: SECOND_COLOR,
                },
              "& .css-p5gg5x-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                { color: SECOND_COLOR },
              "& .css-h9rskr-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  color: SECOND_COLOR,
                },
              "& .css-f0womv-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  color: SECOND_COLOR,
                },
            }}
          >
            <ToggleButton
              sx={{
                color: StyleColor2(theme),
                fontSize: "1.4rem",
                fontFamily: document.body.style.fontFamily,
              }}
              value="points"
            >
              <p>Points</p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1.4rem",
                fontFamily: document.body.style.fontFamily,
                color: StyleColor2(theme),
              }}
              value="lines"
            >
              <p>Lines</p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1.4rem",
                fontFamily: document.body.style.fontFamily,
                color: StyleColor2(theme),
                "& .css-p5gg5x-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                  { color: "red" },
              }}
              value="polygons"
            >
              <p>Polygons</p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1.4rem",
                fontFamily: document.body.style.fontFamily,
                color: StyleColor2(theme),
                "& .css-p5gg5x-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                  { color: "red" },
              }}
              value="clusters"
            >
              <p>Clusters</p>
            </ToggleButton>
          </CustomizeToggleButtonGroup>
          <br />
          {section === "points" && (
            <>
              <Title style={{ color: StyleColor2(theme) }}>Objects</Title>
              <ListObjects />
              <Title style={{ color: StyleColor2(theme) }}>Components</Title>
              <ListComponents />
              <Title style={{ color: StyleColor2(theme) }}>Events</Title>
              <ListEvents />
            </>
          )}
          {section === "lines" && <ListLines />}
          {section === "polygons" && <ListRegions />}
          {section === "clusters" && (
            <Container style={{ color: StyleColor2(theme) }}>
              <ItemList>
                <span className="cluster-csv"></span>
                <p>File CSV</p>
              </ItemList>
              <ItemList>
                <span className="cluster-objects"></span>
                <p>Objects</p>
              </ItemList>
              <ItemList>
                <span className="cluster-components"></span>
                <p>Components</p>
              </ItemList>
              <ItemList>
                <span className="cluster-events"></span>
                <p>Events</p>
              </ItemList>
            </Container>
          )}
        </Grid>
      </>
    ),
  };
};
