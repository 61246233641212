import { useState } from "react";
import { useSWRConfig } from "swr";
import { useDispatch } from "react-redux";
import { setShowOperationDetails } from "redux/actions";
// Config
import { config } from "config.js";
// Components
import CustomizedButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
// Services
import deleteComponent from "services/components/deleteComponent";
// Colors
import { DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteButton = ({ pointComponentId }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this component?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const handleClick = () => setOpenDialog(true);

  const handlerDeleteComponent = async () => {
    const { res } = await deleteComponent(pointComponentId);
    if (res.status === 200) {
      setOpenDialog(false);
      dispatch(setShowOperationDetails(false));
      const adminCompanyId = localStorage.getItem("adminCompanyId");
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-components?adminCompanyId=${adminCompanyId}`
      );
    } else {
      setOpenDialog(true);
      setContentDialog({
        title: "",
        description: "❌ Error to delete component, try again.",
        disagree: "Cancel",
      });
    }
  };
  const shouldShowTooltip = true;

  return (
    <div>
      <CustomizedButton
        text={<DeleteIcon />}
        primaryColor={DELETE_COLOR}
        secondaryColor={HOVER_DELETE_COLOR}
        onClick={handleClick}
        margin={"10px 0"}
        showTooltip={shouldShowTooltip}
        tooltipTitle="Delete"
      />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={handlerDeleteComponent}
        content={contentDialog}
      />
    </div>
  );
};

export default DeleteButton;
