import useGet from "hooks/useFetch.js";
import AccordionRender from "./AccordionRender";

const CheckListsObjectsElements = ({
  typeLinesTypeObjects,
  handleChangeCheck,
}) => {
  const [libraries] = useGet("api/v1/point-libraries");
  const [allTypes] = useGet("api/v1/type-elements");

  const res =
    allTypes &&
    !allTypes.error &&
    allTypes.map((elm) => {
      return elm.pointLibraryId;
    });

  const idTypes =
    res &&
    !res.error &&
    res.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
      }}
    >
      {libraries &&
        !libraries.error &&
        libraries.map((library, key) =>
          idTypes && !idTypes.error && idTypes.includes(library.id) ? (
            <AccordionRender
              handleChangeCheck={handleChangeCheck}
              typeLinesTypeObjects={typeLinesTypeObjects}
              key={key}
              library={library}
            />
          ) : (
            <></>
          )
        )}
    </div>
  );
};

export default CheckListsObjectsElements;
