import { useState, useEffect } from "react";
// Hooks
import useFindKpiCharts from "pages/Dashboard/Hooks/components/useFindKpiCharts";
import useFindBarCharts from "pages/Dashboard/Hooks/components/useFindBarCharts";
import useFindTextCharts from "pages/Dashboard/Hooks/components/useFindTextCharts";
import useFindLineCharts from "pages/Dashboard/Hooks/components/useFindLineCharts";
import useFindDoughnutCharts from "pages/Dashboard/Hooks/components/useFindDoughnutCharts";
import { useFindTimeSeriesChartComponents } from "pages/Dashboard/Hooks/components/useFindTimeSeriesCharts";
// Components
import DropDragGrid from "pages/Dashboard/DragAndDrop/DragAndDrollGrid";
import { useFindGroupBarChartComponent } from "pages/Dashboard/Hooks/components/useFindGroupBarChart";
import { useFindTimeSeriesBarChartComponents } from "pages/Dashboard/Hooks/components/useFindTimeSeriesBarCharts";
import { useFindImgChartsComponents } from "pages/Dashboard/Hooks/components/useFindImgCharts";
import { useFindGroupDoughnutChartsComponents } from "pages/Dashboard/Hooks/components/useFindGroupDoughnutCharts";
const ComponentsCharts = ({
  openDialog,
  components,
  loading,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  orderId,
}) => {
  const [dataChart, setDataChart] = useState([]);
  const { kpiCharts } = useFindKpiCharts({ openDialog, components, loading });
  const { barCharts } = useFindBarCharts({ openDialog, components, loading });
  const { textCharts } = useFindTextCharts({ openDialog, components, loading });
  const { lineCharts } = useFindLineCharts({ openDialog, components, loading });

  const { doughnutCharts } = useFindDoughnutCharts({
    openDialog,
    components,
    loading,
  });
  const { groupDoughnutCharts } = useFindGroupDoughnutChartsComponents({
    openDialog,
    components,
    loading,
  });
  const { timeSeriesCharts } = useFindTimeSeriesChartComponents({
    openDialog,
    components,
    loading,
  });

  const { groupBarCharts } = useFindGroupBarChartComponent({
    openDialog,
    components,
    loading,
  });

  const { timeSeriesBarCharts } = useFindTimeSeriesBarChartComponents({
    openDialog,
    components,
    loading,
  });

  const { imgCharts } = useFindImgChartsComponents({
    openDialog,
  });

  useEffect(() => {
    if (
      kpiCharts &&
      barCharts &&
      textCharts &&
      lineCharts &&
      doughnutCharts &&
      groupDoughnutCharts &&
      timeSeriesCharts &&
      groupBarCharts &&
      timeSeriesBarCharts &&
      imgCharts
    ) {
      setDataChart([
        ...kpiCharts,
        ...barCharts,
        ...textCharts,
        ...lineCharts,
        ...doughnutCharts,
        ...groupDoughnutCharts,
        ...timeSeriesCharts,
        ...groupBarCharts,
        ...timeSeriesBarCharts,
        ...imgCharts,
      ]);
    }
  }, [
    kpiCharts,
    barCharts,
    textCharts,
    lineCharts,
    doughnutCharts,
    groupDoughnutCharts,
    timeSeriesCharts,
    currentOrderName,
    currentTemplate,
    groupBarCharts,
    timeSeriesBarCharts,
    imgCharts,
  ]);

  return (
    <>
      {dataChart && components && (
        <DropDragGrid
          dataChart={dataChart}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          currentPositionId={currentPositionId}
          orderId={orderId}
        />
      )}
    </>
  );
};

export default ComponentsCharts;
