import { useState } from "react";
import { useSWRConfig } from "swr";
import { useDispatch } from "react-redux";
import { setShowOperationDetails } from "redux/actions";

import CustomizedButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
import deleteEvent from "services/deleteNewEvent";
import DeleteIcon from "@mui/icons-material/Delete";

// Config
import { config } from "config.js";

const DeleteButton = ({ pointEventId }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this event?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const handleClick = () => setOpenDialog(true);

  const handlerDeleteEvent = async () => {
    const { res } = await deleteEvent(pointEventId);
    if (res.status === 200) {
      dispatch(setShowOperationDetails(false));
      // TODO: hacer el mutate dependiendo de la relación del evento y no los 3 mutates a la vez.
      const urlObjectEvents = `${config.URL_BACKEND_PG}api/v1/point-events?adminCompanyId=${adminCompanyId}`;
      const urlComponentEvents = `${config.URL_BACKEND_PG}api/v1/point-events/components?adminCompanyId=${adminCompanyId}`;
      const urlEventsRelation = `${config.URL_BACKEND_PG}api/v1/point-events/relation?adminCompanyId=${adminCompanyId}`;
      mutate(urlObjectEvents);
      mutate(urlComponentEvents);
      mutate(urlEventsRelation);
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
      setContentDialog({
        title: "",
        description: "❌ Error to delete event, try again.",
        disagree: "Cancel",
      });
    }
  };
  const shouldShowTooltip = true;

  return (
    <div>
      <CustomizedButton
        text={<DeleteIcon />}
        primaryColor={"#d83939"}
        secondaryColor={"#eb4123"}
        onClick={handleClick}
        margin={"10px 0"}
        showTooltip={shouldShowTooltip}
        tooltipTitle="Delete"
      />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={handlerDeleteEvent}
        content={contentDialog}
      />
    </div>
  );
};

export default DeleteButton;
