// Styles
import { INFORMATION_COLOR } from "utils/const";
import { ContainerSearchTable, SearchTable } from "../../GenericTableStyle";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
// Components
import SelectVisivilityColumns from "../SelectVisivilityColumns";
import ClickToolTip from "components/ToolTips/ClickToolTip";
export const InputSearchTable = ({
  state,
  handleGlobalFilterChange,
  table,
  useFilter,
  setUseFilter,
  buttonFilters,
  handlerSetValue,
}) => {
  return (
    <ContainerSearchTable className="background-row-1 color1 border-color-1">
      <SearchTable margin={state ? 0 : 20} style={{ alignItems: "center" }}>
        <div className="space"></div>
        <div className="space">
          <input
            type="text"
            onChange={handleGlobalFilterChange}
            className="input"
            placeholder="Search all columns..."
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <SelectVisivilityColumns
            table={table}
            style={{ alignItems: "center" }}
          />
          <p style={{ fontSize: "16px", paddingTop: "0px" }}>
            Check to view filters
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "1rem",
          }}
        >
          {useFilter || !buttonFilters ? (
            <>
              <IconButton style={{ background: "transparent" }}>
                <button
                  onClick={() => handlerSetValue()}
                  style={{ background: "transparent" }}
                >
                  <RestartAltIcon style={{ fontSize: "3rem" }} />
                </button>
              </IconButton>
              <ClickToolTip title="Reset Filters" placement="top">
                <ContactSupportIcon
                  sx={{
                    fontSize: "1.6rem",
                    color: `${INFORMATION_COLOR}`,
                    ml: "-5px",
                    mb: "17px",
                    cursor: "pointer",
                  }}
                />
              </ClickToolTip>
            </>
          ) : (
            <></>
          )}
          {useFilter ? (
            <IconButton style={{ background: "transparent" }}>
              <button
                onClick={() => setUseFilter(!useFilter)}
                style={{ background: "transparent" }}
              >
                <FilterAltOffIcon style={{ fontSize: "3rem" }} />
              </button>
            </IconButton>
          ) : (
            <IconButton style={{ background: "transparent" }}>
              <button
                onClick={() => setUseFilter(!useFilter)}
                style={{ background: "transparent" }}
              >
                <FilterAltIcon
                  onClick={() => setUseFilter(!useFilter)}
                  style={{ fontSize: "3rem" }}
                />
              </button>
            </IconButton>
          )}
          <ClickToolTip title="Decimetrix view filters tools" placement="top">
            <ContactSupportIcon
              sx={{
                fontSize: "1.6rem",
                color: `${INFORMATION_COLOR}`,
                ml: "-5px",
                mb: "17px",
                cursor: "pointer",
              }}
            />
          </ClickToolTip>
        </div>
      </SearchTable>
    </ContainerSearchTable>
  );
};
