import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const CompanyManagerIcon = () => {
  return (
    <>
      <svg
        id="Capa_52"
        data-name="Capa 52"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {` .cls-1acm {
        fill: ${PRIMARY_COLOR};
      }
      .cls-1acmplus {
        fill: ${SECOND_COLOR}
      }
      .cls-1acm, .cls-2acm, .cls-3acm, .cls-4acm {
        stroke-width: 0px;
      }

      .cls-5 {
        stroke: #c3c3c3;
        stroke-miterlimit: 10;
        stroke-width: 6px;
      }

      .cls-5, .cls-2acm {
        fill: #f4f4f4;
      }

      .cls-3acm {
        fill: #6d6d6d;
      }

      .cls-4acm {
        fill: ${SECOND_COLOR};
      }`}
          </style>
        </defs>
        <path
          class="cls-4acm"
          d="M322.85,69.1v273.75h34.83c17.07,0,30.91-13.84,30.91-30.91v-179.89c0-9.97-4.81-19.33-12.92-25.13l-52.82-37.82Z"
        />
        <path
          class="cls-4acm"
          d="M280.23,27.22l-180.33,41.52c-8.45,1.95-14.44,9.47-14.44,18.14v237.35c0,10.28,8.33,18.62,18.62,18.62h198.95V45.37c0-11.97-11.13-20.83-22.79-18.14ZM172.07,237.29c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM172.07,189.93c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM172.07,141.2c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM220.59,237.29c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM220.59,189.93c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM220.59,141.2c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM271.2,237.29c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM271.2,189.93c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65ZM271.2,141.2c0,5.19-4.2,9.39-9.39,9.39h-15.65c-5.19,0-9.39-4.2-9.39-9.39v-15.65c0-5.19,4.2-9.39,9.39-9.39h15.65c5.19,0,9.39,4.2,9.39,9.39v15.65Z"
        />
        <circle class="cls-1acm" cx="131.21" cy="301.45" r="95.48" />
        <ellipse
          class="cls-2acm"
          cx="130.53"
          cy="292.72"
          rx="36.67"
          ry="37.39"
        />
        <g>
          <path
            class="cls-2acm"
            d="M143.19,338.51c23.71,3.16,8.6,49.54-12.16,49.54-20.75,0-36.84-46.6-13.16-49.6,0,0,12.73-2.08,25.32.05Z"
          />
          <g>
            <path
              class="cls-4acm"
              d="M135.71,340.37l-4.67,4.67c-.25.25-.66.25-.91,0l-4.67-4.67c-.4-.4-.12-1.09.45-1.09h9.34c.57,0,.86.69.45,1.09Z"
            />
            <polygon
              class="cls-4acm"
              points="118.39 386.24 130.57 339.17 142.8 386.24 118.39 386.24"
            />
          </g>
          <path
            class="cls-3acm"
            d="M143.19,338.51l-12.65,47.91-12.67-47.97c-24.29,3.58-43.67,17.4-46.34,37.52,0,0,0,0,0,0,18.98,15.01,37.72,21.95,59,21.95s43.03-8.41,59.12-20.97c0,0,0,0,0,0-2.67-20.13-22.15-34.67-46.47-38.45Z"
          />
        </g>
        <circle class="cls-5" cx="228.64" cy="356.84" r="50.14" />
        <rect
          class="cls-1acmplus"
          x="218"
          y="326.23"
          width="21.29"
          height="61.2"
          rx="6.01"
          ry="6.01"
        />
        <rect
          class="cls-1acmplus"
          x="218.49"
          y="326.23"
          width="20.32"
          height="61.2"
          rx="6.01"
          ry="6.01"
          transform="translate(-128.19 585.48) rotate(-90)"
        />
      </svg>
    </>
  );
};

export default CompanyManagerIcon;
