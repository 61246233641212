import {
  CustomizedAccordion,
  FilterTabStyles,
  ContentFilterItem,
  useStyles,
  ContentFilterItemLabel,
} from "./AccordionStyles";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { addDays, addMonths, format, parse } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setFilterObjectsByData } from "redux/actions";
import DatePicker from "./DatePicker";
import { RED_COLOR, WHITE_COLOR } from "utils/const";
import dayjs from "dayjs";
import CustomButton from "components/Buttons/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//save dates in database
import { AddNewViewContainer } from "./AccordionBookmarksViewsStyle";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import BookmarksViews from "./BookmarksViews";
import { List } from "@mui/material";
import AccordionFilterDate from "./AccordionFilterDate";
import findFilterDate from "services/DatesFilter/findFilterDate";
import BookmarksFilterDate from "./BookmarksFilterDate";

export default function FilterByDate() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );
  const infoDatBase = useSelector(
    (state) => state.digitalTwinReducer.infoFilterDate
  ); //////////////////
  const objectDates = useSelector((state) => state.adminReducer.dataObjects);

  const today = format(new Date(), "yyyy-MM-dd");
  const [localEndDate, setLocalEndDate] = useState("");
  const [localStartDate, setLocalStartDate] = useState(startDate);
  //Open modal for save date
  const [openModal, setOpenModal] = useState(false);

  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const rol = localStorage.getItem("role");
  const handleChangeStartDate = (date) => {
    setLocalStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setLocalEndDate(date);
  };

  const handleRestartDates = () => {
    objectDates.sort(
      (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
    );
    setLocalEndDate(today);
    setLocalStartDate(() =>
      dayjs(objectDates?.at(0)?.date).format("YYYY-MM-DD")
    );

    dispatch(
      setFilterObjectsByData({
        endDate: today,
        startDate: dayjs(objectDates?.at(0)?.date).format("YYYY-MM-DD"),
      })
    );
  };

  const updateDates = async () => {
    try {
      const data = await findFilterDate({ userId: userId });
      return data;
    } catch (error) {
      console.error("Error fetching related objects:", error);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (endDate && localEndDate === "") {
      const endDateParsed = parse(endDate, "yyyy-MM-dd", new Date());
      const currentEndDate = addDays(endDateParsed, -1);
      setLocalEndDate(() => format(currentEndDate, "yyyy-MM-dd"));
    } else if (localEndDate === "") {
      setLocalEndDate(() => today);
    }
  }, [endDate, localEndDate, today]);

  useEffect(() => {
    if (!localStartDate) {
      const parseLocalToday = parse(today, "yyyy-MM-dd", new Date());
      const currentStartDate = addMonths(parseLocalToday, -1);
      setLocalStartDate(() => format(currentStartDate, "yyyy-MM-dd"));
    }
  }, [today, localStartDate]);

  useEffect(() => {
    if (localEndDate === "") return;
    const globalEndData = addDays(
      parse(localEndDate, "yyyy-MM-dd", new Date()),
      1
    );
    dispatch(
      setFilterObjectsByData({
        endDate: format(globalEndData, "yyyy-MM-dd"),
        startDate: localStartDate,
      })
    );
  }, [localEndDate, localStartDate, dispatch]);

  return (
    <>
      <CustomizedAccordion
        className="background-1 color1 border-color-1 border-radius-1"
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
        style={{ boxShadow: "none" }}
      >
        <ContentFilterItem>
          <Typography
            sx={{
              fontFamily: document.body.style.fontFamily,
              color: document.body.style.color,
            }}
          >
            Filter For Date
          </Typography>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="color1" />}
          ></AccordionSummary>
        </ContentFilterItem>
        <AccordionSummary>
          <FilterTabStyles>
            <ContentFilterItem className="date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ContentFilterItemLabel className="color1">
                  Start Date:
                  <DatePicker
                    startdate={localStartDate}
                    enddate={localEndDate}
                    value={localStartDate}
                    changefunction={handleChangeStartDate}
                  />
                </ContentFilterItemLabel>

                <ContentFilterItemLabel className="color1">
                  End Date:
                  <DatePicker
                    startdate={localStartDate}
                    enddate={localEndDate}
                    value={localEndDate}
                    changefunction={handleChangeEndDate}
                  />
                </ContentFilterItemLabel>
              </LocalizationProvider>

              <ContentFilterItemLabel>
                <CustomButton
                  primaryColor={RED_COLOR}
                  text={"Show all"}
                  onClick={handleRestartDates}
                />
              </ContentFilterItemLabel>
              <List
                sx={{
                  height: "100%",
                  position: "bottom",
                }}
                subheader={<li />}
              >
                <AddNewViewContainer>
                  <button className="button-add" onClick={handleOpenModal}>
                    <SimpleToolTip title="Add this Date" placement="right">
                      <AddIcon sx={{ fontSize: "3rem", color: WHITE_COLOR }} />
                    </SimpleToolTip>
                  </button>
                </AddNewViewContainer>
              </List>
              {openModal && (
                <AccordionFilterDate
                  handleCloseModal={handleCloseModal}
                  localStartDate={localStartDate}
                  localEndDate={localEndDate}
                  updateDates={updateDates}
                />
              )}
            </ContentFilterItem>
          </FilterTabStyles>
        </AccordionSummary>
      </CustomizedAccordion>
      <div>
        <BookmarksFilterDate
          setLocalStartDate={setLocalStartDate}
          setLocalEndDate={setLocalEndDate}
          updateDates={updateDates}
          handleRestartDates={handleRestartDates}
        />
      </div>
    </>
  );
}
