import React from "react";
import { LastConteiner, UserH2Flex } from "./HubStyles";
import { BLACK_COLOR } from "utils/const";

const LastLoginInfo = ({
  adminCompany,
  adminDecimetrix,
  operatorDecimetrix,
}) => {
  const formatDate = (string) => {
    if (!string) return "";
    const date = new Date(string);
    const utcMinusFiveTime = date.toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    return utcMinusFiveTime;
  };

  const toLowerCaseIfExist = (string) => {
    return string ? string.toLowerCase() : "";
  };

  const lastLogin =
    adminDecimetrix?.user?.userVerification?.lastLogin ||
    adminCompany?.user?.userVerification?.lastLogin ||
    operatorDecimetrix?.operator?.user?.userVerification?.lastLogin;

  const roleName = toLowerCaseIfExist(
    operatorDecimetrix?.operator?.role?.role ||
      adminDecimetrix?.user?.userType?.role ||
      adminCompany?.user?.userType?.role
  );

  return (
    <LastConteiner className="background-1">
      <UserH2Flex className="background-1">
        <b>Last login:&nbsp;</b>{" "}
        {lastLogin ? <span>{formatDate(lastLogin)}</span> : null}
      </UserH2Flex>
      <UserH2Flex className="background-1">
        <b>Role:&nbsp;</b> {roleName ? <span>{roleName}</span> : null}
      </UserH2Flex>
    </LastConteiner>
  );
};

export default LastLoginInfo;
