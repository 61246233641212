import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR } from "utils/const";

export const DrawerMapStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 15px;
`;

export const Title = styled.h1`
  margin: 2rem;
`;

export const ButtonMapStyle = styled.button`
  width: 175px;
  height: 60px;
  border-radius: 4px;
  border: none;
  /* background-color: (${PRIMARY_COLOR}, 0.5); */
  background-color: rgba(0, 0, 0, 0.08);
  color: black;
  font-size: 2rem;
  /* font-weight: normal; */
  padding: 10px;
  margin: 3px;
  text-align: center;
  display: flex;
  margin-top: 10px;
  align-items: center;

  &:hover {
    background-color: rgba(14, 77, 69, 0.7);
    cursor: pointer;
  }
`;

export const ImgButton = styled.img`
  width: 50px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
`;
