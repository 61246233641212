import AllObjectCharts from "./ObjectCharts/index";
import Loading from "components/Lodings/Loading";

const ObjectsCharts = ({
  openDialog,
  objects,
  loading,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  orderId,
}) => {
  return (
    <div className="dashboard ">
      {objects?.length > 0 && (
        <AllObjectCharts
          openDialog={openDialog}
          objects={objects}
          loading={loading}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          currentPositionId={currentPositionId}
          orderId={orderId}
        />
      )}
      {loading && <Loading height={"500px"} text={"Loading Dashboard..."} />}
    </div>
  );
};

export default ObjectsCharts;
