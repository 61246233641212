import styled from "styled-components";
import { PRIMARY_COLOR, WHITE_COLOR } from "utils/const";

export const SelectContainer = styled.div`
  width: 100%;

  .label {
    margin: 20px 0;
    padding: 10px 0;
    font-size: 1.6rem;
    font-weight: 600;
    color: #5c5757;
    cursor: pointer;
  }

  .select {
    border-radius: 5px;
    width: 100%;
    margin: 10px 0;
    background-color: var(--background-primary_color);
    border-color: var(--background-primary_color);
    color: ${WHITE_COLOR};
    padding: 6px 15px;
  }
`;
