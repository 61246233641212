// React
import { useEffect, useState } from "react";

import ObjectRelations from "components/Forms/SaveObject/ObjectRelations";
// React - Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setDataObjectDTImages,
  setOperationDetails,
  setShowOperationDetails,
} from "redux/actions";
import {
  setFeaturesDetailsDT,
  setObjectDeleteLineDraws,
} from "redux/actions/admin";
// Components
import CustomButton from "components/Buttons/CustomButton";
import GenericDialog from "components/Dialogs/GenericDialog";
import ContentDialogConvertObject from "components/ElementDetails/contentDialogs/ContentDialogConvertObject";
import UploadMediaObject from "components/ElementDetails/ObjectDetails/UploadMediaObject";
import EventDetails from "../EventDetails/EventDetails";
import ComponentDetails from "../ComponentDetails/Index";
import TableObject from "./TableObject";
import OpenDialog from "components/Dialogs/OpenDialog";
import PhotoGallery from "./../PhotoGalley/PhotoGallery";
import MapView from "components/Maps/ObjectMap";
import QuantityComponents from "./QuantityComponents";
import QuantityEvents from "./QuantityEvents";
import ComponentHistory from "./ComponentHistory";
import EventHistory from "../EventHistory";
import GalleryByType from "./GalleryByType";
import Formulario from "components/Forms/SaveObject/DynamicFields";
// Config
import { config } from "config.js";
// Hooks
import FetchComponentsByObjectId from "hooks/fetchComponentsByObjectId";
import useCreateEventsObjets from "hooks/useCreateEventsObject";
import useMediaElement from "hooks/useMediaElement";
import useCreateComponentObjects from "hooks/useCreateComponentObjects";
import CreateEvent from "components/Maps/ObjectMap/CreateEvent";

import {
  getPolygon,
  getPolygonData,
} from "hooks/elementDetails/useNearObjects";
// Services
import findPointLibraries from "services/findPointLibrary";
import updateObjectPg from "services/updateObjectPg";
// Dependencies
import { useSWRConfig } from "swr";
import axios from "axios";
import jwt_decode from "jwt-decode";
import useSwr from "swr";
// Style
import { IconButton } from "@mui/material";
import { CustomizedChevronLeftIcon } from "../EventDetails/EventDetailsElements";
import { grey } from "@mui/material/colors";
import { ContainerOperations } from "../OperationDetails/OperationDetailsElements";
import Grid from "@material-ui/core/Grid";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import RelationObjectIcon from "@mui/icons-material/Hub";
import DeleteIcon from "@mui/icons-material/Delete";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { ContentButtonGallery, Wrapperbtn } from "./ObjectDetailsElements";
import { InfoTitle, Label } from "../ImageDetailsElements";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";

// config - const
import {
  DELETE_COLOR,
  BLACK_COLOR,
  HOVER_DELETE_COLOR,
  WHITE_BACKGROUND,
  WHITE_COLOR,
} from "utils/const";
import { roles } from "utils/roles";
import DetailTypeIformation from "../DetailTypeInformation";
import { useContext } from "react";
import { ThemeContext } from "App";
import EventHistoryNoLocation from "../EventHistoryNoLocation";

const ObjectDetails = ({
  objectId,
  fromRelation,
  currentRelation,
  isComponent,
  isEvent,
  from = null,
}) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);

  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );

  const rotationValue = useSelector(
    (state) => state.digitalTwinReducer.rotationValue
  );

  const objects = useSelector((state) => state.digitalTwinReducer.objects);

  const radiusElementsNear = useSelector(
    (state) => state.digitalTwinReducer.radiusElementsNear
  );

  const [openDialog, setOpenDialog] = useState(false);
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");

  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this element?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const [photosFile, setPhotosFile] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(true);
  const token = localStorage.getItem("token");
  const [openDialogChange, setOpenDialogChange] = useState(false);
  const [typeElementId, setTypeElementId] = useState("1");
  const [equipmentName, setEquipmentName] = useState("");
  const [pointLibrary, setPointLibrary] = useState(null);
  const [initialLocation, setInitialLocation] = useState({});
  const [polygon, setPolygon] = useState({});
  const [objectRadiusIds, setObjectRadiusIds] = useState([]);
  const [currentObjectId, setCurrentObjectId] = useState(objectId);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDialogObjects, setOpenDialogObjects] = useState(false);

  const [movePoint, setMovePoint] = useState(false);
  //Crate non-geographic events
  const [showCreateEventForm, setShowCreateEventForm] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [selectedTypeEvents, setSelectedTypeEvents] = useState([]);
  const { eventRows, dataDynamicEvents, seePointEventsTable, markedEvent } =
    useCreateEventsObjets({ objectId });

  // Component Table ...
  const {
    componentRows,
    dataDynamicComponent,
    seePointComponentTable,
    markedComponent,
  } = useCreateComponentObjects({ objectId });

  const handleConvertObject = () => {
    setOpenDialogChange(true);
  };
  const { mutate } = useSWRConfig();

  const setTypeElement = (e) => {
    setTypeElementId(e.target.value);
  };

  const setTypeEquipmentName = (e) => {
    setEquipmentName(e.target.value);
  };

  // Components Data
  const { data: dataComponents } = FetchComponentsByObjectId(objectId);

  const urlData = `${config.URL_BACKEND_PG}api/v1/objects/${
    from === "object" ? objectId : currentObjectId
  }`;
  const { data: object, error: errorObject } = useSwr(urlData);

  const { mediaObject, widthMap } = useMediaElement({ element: object });

  if (rotationValue?.length > 0 && object?.objectFiles?.length !== 0) {
    const changesMap = new Map(
      rotationValue.map((change) => [change.id, change])
    );
    for (let i = 0; i < object?.objectFiles.length; i++) {
      const fileId = object?.objectFiles[i]?.id;
      const change = changesMap.get(fileId);
      if (change && change.type === "object") {
        object.objectFiles[i].rotation = change.rotation;
      }
    }
  }

  const decodeToken = jwt_decode(token);
  const urlUser = `${config.URL_BACKEND_PG}api/v1/users/${decodeToken.sub}`;
  const { data: user } = useSwr(urlUser);
  const shouldShowTooltip = true;

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setDataObjectDTImages([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setOpenModal(true);
  };

  const uploadImages = () => {
    mutate(urlData);
  };

  useEffect(() => {
    if (object?.objectFiles.length === 0 && object?.media?.length === 0) {
      updateObjectPg({ haveMedia: false }, object.id).then((res) => res);
    }
  }, [object, movePoint]);

  useEffect(() => {
    if (dataDynamicEvents?.length > 0) setShowDeleteButton(false);
    if (dataDynamicComponent?.length > 0) setShowDeleteButton(false);
    if (dataComponents?.length > 0) setShowDeleteButton(false);
    if (object?.PointObjectRelation?.length > 0) setShowDeleteButton(false);

    const fetchDataPointLibrary = async () => {
      if (object?.regionsObjects?.length > 0) setShowDeleteButton(false);
      const { data, res } = await findPointLibraries(
        object.typeElement.pointLibraryId
      );
      if (res.status === 200) setPointLibrary(data[0]);
    };
    object && fetchDataPointLibrary();
  }, [
    object,
    errorObject,
    dataDynamicEvents,
    dataDynamicComponent,
    dataComponents,
    movePoint,
  ]);

  const handleDelete = () => {
    setContentDialog({
      ...contentDialog,
      title: !showDeleteButton ? `Delete object warning` : "",
      description: (
        <p>
          Are you sure you want to delete this element?
          <p>
            {!showDeleteButton
              ? `This action will delete all relations of the object with Id: ${object.id}`
              : ""}
          </p>
        </p>
      ),
    });
    setOpenDialog(true);
  };

  const deleteElement = async () => {
    setContentDialog({ ...contentDialog, agree: <CircularProgress /> });
    const { data: linesObjects } = await axios.get(
      `${config.URL_BACKEND_PG}api/v1/lines-objects?objectId=${object.id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const lineIds = !linesObjects.length
      ? []
      : linesObjects.map((item) => item.lineId);
    const result = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/objects/${object.id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    if (result) {
      mutate(
        `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`
      );
      mutate(
        `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
      );
      dispatch(
        setObjectDeleteLineDraws({
          state: true,
          lineIds,
        })
      );
    }
    setContentDialog({ ...contentDialog, agree: "Accept" });
    setOpenDialog(false);
    dispatch(setShowOperationDetails(false));
  };

  const saveObject = async (body) => {
    try {
      await updateObjectPg(body, objectId);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`
      );
      setContentDialog({ ...contentDialog, agree: "yes" });
    } catch (error) {
      console.error(error);
    }
  };

  const convertElement = async () => {
    let body = {
      typeElementId: Number(typeElementId),
      fieldEquipmentName: equipmentName,
      lastUpdate: new Date(),
      userUpdateId: userId,
    };

    await saveObject(body);
    dispatch(
      setFeaturesDetailsDT({
        geometry: {
          type: null,
        },
      })
    );
    setOpenDialogChange(false);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/object-ghg/count/object/${currentObjectId}`
    );
  };

  const handleCurrentObjectId = (index) => {
    const nextId = objectRadiusIds[index];
    setCurrentObjectId(() => nextId);
  };

  useEffect(() => {
    if (object && !initialLocation.latitude) {
      setInitialLocation(object.location);
    }
  }, [object, initialLocation, movePoint]);

  useEffect(() => {
    setPolygon(() => ({
      ...getPolygon({ initialLocation, radius: radiusElementsNear })?.geometry,
    }));
  }, [initialLocation, radiusElementsNear, movePoint]);

  useEffect(() => {
    if (!polygon.type) return;
    (async () => {
      const objectsInPolygon = await getPolygonData({
        polygon,
        elementType: "object",
        data: objects,
      });
      const objectssIds = objectsInPolygon.map((object) => object.id);
      setObjectRadiusIds([...objectssIds]);
    })(polygon);
  }, [polygon, objects, movePoint]);

  const returnToEvent = () => {
    if (isEvent) {
      const event = {
        content: <EventDetails eventId={currentRelation.id} />,
        title: "Event Details",
      };
      dispatch(setOperationDetails(event));
    }
    if (isComponent) {
      const component = {
        content: <ComponentDetails componentId={currentRelation.id} />,
        title: "Component Details",
      };
      dispatch(setOperationDetails(component));
    }
    dispatch(setShowOperationDetails(true));
  };

  const handleUpdateData = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/objects/${object.id}`);
  };

  // Find type events to type elements
  const elementTypeId = object?.typeElementId;
  const urlTypeEvents = `${config.URL_BACKEND_PG}api/v1/type-events-type-elements?typeElementId=${elementTypeId}&adminCompanyId=${adminCompanyId}`;
  const { data: dataTypeEvents } = useSwr(urlTypeEvents);

  const handleOpenCreateEventForm = (objectId, typeEvents) => {
    setSelectedObjectId(objectId);
    setSelectedTypeEvents(typeEvents);
    setShowCreateEventForm(true);
  };

  return (
    <>
      {fromRelation && (
        <IconButton
          onClick={returnToEvent}
          style={{
            fontSize: 30,
            cursor: "pointer",
            position: "sticky",
            left: "0px",
            top: "0px",
          }}
        >
          <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
        </IconButton>
      )}
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={deleteElement}
        content={contentDialog}
      />
      <GenericDialog
        openDialog={openDialogChange}
        setOpenDialog={setOpenDialogChange}
        execute={convertElement}
        content={{
          title: "Convertir Objetos",
          description: "Convierte un tipo de elemento a otro tipo de elemento",
          agree: "Guardar",
          content: (
            <ContentDialogConvertObject
              handleConvertObject={handleConvertObject}
              typeElementId={typeElementId}
              setTypeElement={setTypeElement}
              setTypeElementId={setTypeElementId}
              setTypeEquipmentName={setTypeEquipmentName}
            />
          ),
        }}
        style={{
          margin: 0,
          padding: 0,
          width: "50%",
          // height: "450px",
          maxWidth: "none",
          background: `${WHITE_BACKGROUND}`,
        }}
      />
      <OpenDialog
        openDialog={openUpdateDialog}
        setOpenDialog={setOpenUpdateDialog}
        content={{
          title: "Update Object",
          description: (
            <Formulario
              dataObject={object}
              update={true}
              setOpenDialog={setOpenUpdateDialog}
              handleUpdateData={handleUpdateData}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />

      <OpenDialog
        openDialog={openDialogObjects}
        setOpenDialog={setOpenDialogObjects}
        content={{
          title: "Object Relations",
          description: (
            <ObjectRelations
              objectId={object?.typeElement?.id}
              setOpenDialogParent={() => {
                dispatch(setShowOperationDetails(false));
              }}
              setOpenDialog={setOpenDialogObjects}
              object={object}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />

      <OpenDialog
        openDialog={showCreateEventForm}
        setOpenDialog={setShowCreateEventForm}
        content={{
          title: "Create Non-Georeferenced Event",
          description: (
            <CreateEvent
              isGeographic={false}
              objectId={selectedObjectId}
              closeModal={setShowCreateEventForm}
              typeEvents={selectedTypeEvents}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />

      {object && (
        <ContainerOperations seeArrow={objectRadiusIds?.length > 1}>
          <Grid key={object.id} item xs={12} md={12} lg={12} xl={12}>
            <div
              // className="current-object"
              style={{
                color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
              }}
              className={`current-object ${theme === "light" ? "bb" : "bb2"}`}
            >
              {objectRadiusIds?.map((id, index) => {
                const before = (
                  <span
                    className={index === 0 && "disabled"}
                    onClick={() => handleCurrentObjectId(index - 1)}
                  >
                    <AiOutlineLeft />
                  </span>
                );
                const after = (
                  <span
                    className={
                      index === objectRadiusIds?.length - 1 && "disabled"
                    }
                  >
                    <AiOutlineRight
                      onClick={() => handleCurrentObjectId(index + 1)}
                    />
                  </span>
                );
                const render = (
                  <p>{index + 1 + "/" + objectRadiusIds.length}</p>
                );
                if (id !== currentObjectId) return null;
                if (objectRadiusIds?.length <= 1) return render;
                return (
                  <>
                    {before} {render} {after}
                  </>
                );
              })}
            </div>
          </Grid>
          {/* --OBJECT DETAILS */}
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <div
              className={`info-operation ${theme === "light" ? "bb" : "bb2"}`}
            >
              <DetailTypeIformation
                typeName={object?.typeElement?.name} //Obtener el id del objeto: object?.typeElement?.id
                typeLibrary={pointLibrary?.name}
                iconLibrary={pointLibrary?.icon}
                icon={object?.typeElement?.urlIconColor}
                type={"Object"}
              />
              <InfoTitle>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <section className="main-tables-container">
                    {/* Object Details */}
                    <TableObject object={object} />
                    {/* Object buttons */}
                    {(user?.operator?.role?.role === roles.teamOffice ||
                      user?.operator?.role?.role === roles.teamField ||
                      decodeToken?.role === roles.companyAdmin ||
                      decodeToken?.sub === object?.userId) && (
                      <Wrapperbtn>
                        <CustomButton
                          onClick={handleConvertObject}
                          text={<ChangeCircleIcon />}
                          margin={"10px 0"}
                          primaryColor={"#3f51b5"}
                          secondaryColor={"#1084f0"}
                          showTooltip={shouldShowTooltip}
                          tooltipTitle="Convert"
                        ></CustomButton>

                        <CustomButton
                          text={<EditIcon />}
                          onClick={() => setOpenUpdateDialog(true)}
                          margin={"10px 0"}
                          primaryColor={"#ff600a"}
                          secondaryColor={"#f0712c"}
                          showTooltip={shouldShowTooltip}
                          tooltipTitle="Edit"
                        />

                        <CustomButton
                          text={<RelationObjectIcon />}
                          onClick={() => setOpenDialogObjects(true)}
                          primaryColor={"#15830b"}
                          secondaryColor={"#18910d"}
                          margin={"10px 0"}
                          showTooltip={shouldShowTooltip}
                          tooltipTitle="Relationship"
                        />
                        <CustomButton
                          text={<DynamicFormIcon />}
                          onClick={() =>
                            handleOpenCreateEventForm(
                              object?.id,
                              dataTypeEvents
                            )
                          }
                          margin={"10px 0"}
                          showTooltip={shouldShowTooltip}
                          tooltipTitle="Add Non-Georeferenced Event"
                        />

                        {/* {showDeleteButton && ( */}
                        <CustomButton
                          onClick={handleDelete}
                          text={<DeleteIcon />}
                          primaryColor={DELETE_COLOR}
                          secondaryColor={HOVER_DELETE_COLOR}
                          margin={"10px 0"}
                          showTooltip={shouldShowTooltip}
                          tooltipTitle="Delete"
                        ></CustomButton>
                        {/* )} */}
                      </Wrapperbtn>
                    )}
                    {/* Quantity of components  */}
                    <QuantityComponents
                      objectId={objectId}
                      setShowDeleteButton={setShowDeleteButton}
                    />
                    {/* Quantity of events */}
                    <QuantityEvents
                      objectId={objectId}
                      setShowDeleteButton={setShowDeleteButton}
                    />
                  </section>
                </Grid>
              </InfoTitle>
            </div>
          </Grid>

          {/* -- GALLERY PHOTOS-- */}
          <Grid item xs={12} md={12} lg={8} xl={8}>
            <div
              className={`photo-container-image ${
                theme === "light" ? "bb" : "bb2"
              }`}
            >
              <h1
                className="operation-title"
                style={{
                  color:
                    theme === "light"
                      ? "var(--background-primary_color)"
                      : WHITE_COLOR,
                }}
              >
                <span>GALLERY</span>
              </h1>
              <div
                className="gallery"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "0px 24px 0px 20px",
                }}
              >
                {(object?.objectFiles?.length > 0 ||
                  object?.media?.length > 0) && (
                  <>
                    <br />
                    <PhotoGallery
                      photos={[
                        ...object?.objectFiles?.filter(
                          (photo) => photo.typeObjectFileId !== 5
                        ),
                        ...object?.media,
                      ]}
                      ownId={object?.id}
                      type={"object"}
                      mongoId={object?.mongoId}
                      uploadImages={uploadImages}
                    />
                  </>
                )}
                <ContentButtonGallery>
                  {/* <Wrapperbtn> */}
                  <Label htmlFor="files">
                    <AddAPhotoIcon />
                    <p>Add Picture</p>
                  </Label>
                  <input
                    id="files"
                    style={{ visibility: "hidden" }}
                    accept="image/*"
                    type="file"
                    name="file"
                    onChange={uploadFile}
                  />
                  {/* </Wrapperbtn> */}
                </ContentButtonGallery>
                <UploadMediaObject
                  object={object}
                  photosFile={photosFile}
                  setPhotosFile={setPhotosFile}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              </div>
            </div>
          </Grid>

          {/* GALLERIES BY TYPE  */}
          <Grid
            container
            spacing={3}
            justifyContent="flex-start"
            alignItems="center"
          >
            {mediaObject && (
              <GalleryByType object={object} mediaObject={mediaObject} />
            )}

            {/* --MAP- */}
            {widthMap && object && (
              <Grid item xs={12} md={12} lg={widthMap} xl={widthMap}>
                <div
                  className={`content-map ${theme === "light" ? "bb" : "bb2"}`}
                >
                  <h1
                    className="operation-title"
                    style={{
                      color:
                        theme === "light"
                          ? "var(--background-primary_color)"
                          : WHITE_COLOR,
                    }}
                  >
                    <span>OBJECT LOCATION</span>
                  </h1>
                  <MapView
                    typeObjectId={object?.typeElement?.pointLibraryId}
                    object={object}
                    location={object?.location}
                    urlIcon={object?.typeElement?.urlIconColor}
                    markedEvent={markedEvent || markedComponent}
                    typeElementId={object?.typeElementId}
                    setMovePoint={setMovePoint}
                    setCurrentObjectId={setCurrentObjectId}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          {/* COMPONENT HISTORY */}
          <ComponentHistory
            componentRows={componentRows}
            seePointComponentTable={seePointComponentTable}
          />
          {/* --EVENT HISTORY-- */}
          <EventHistory
            eventRows={eventRows}
            seePointEventsTable={seePointEventsTable}
          />

          {/**EVENT HISTORY NO LOCATION */}
          <EventHistoryNoLocation
            eventRows={eventRows}
            seePointEventsTable={seePointEventsTable}
          />
        </ContainerOperations>
      )}
    </>
  );
};

export default ObjectDetails;
