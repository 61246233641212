// Styles
// import { useEffect, useState } from "react";
import { selectStyle } from "./style";
// Components
import Select from "react-select";

const SelectLibrary = ({
  libraries,
  handlerLibrarySelected,
  isMulti = false,
  librarySelected,
  closeMenuOnSelect = true,
}) => {
  
  return (
    <>
      <h2 className="subtitle">Select Library</h2>
      <section className="container-type-charts ">
        <Select
          options={libraries
            .map((library) => {
              return {
                value: library.id,
                label: library.name,
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={(selectedOption) => {
            handlerLibrarySelected(selectedOption);
          }}
          styles={selectStyle}
          placeholder="Select library"
          isMulti={isMulti}
          value={librarySelected}
          closeMenuOnSelect={closeMenuOnSelect}
        />
      </section>
    </>
  );
};

export default SelectLibrary;
