import { useCallback } from "react";
import { useStore, useDispatch } from "react-redux";
import {
  setDrawLineMode,
  setObjecstsLinesIds,
  setRelationPointLines,
  setShowLinePopUp,
  setShowLines,
  setTmpDistanceFeatures,
} from "redux/actions/digitalTwin";
import { waitSeconds } from "helpers/waitSeconds";
import uniqueObjsArr from "helpers/uniqueObjsArr";
import calculareLineLength from "helpers/calculateLineLength";
import { setGeometryLine } from "redux/actions/admin";

function LineToolsModules() {
  const dispatch = useDispatch();
  const store = useStore();

  const ToolFreeline = () => {
    const { map, drawerControl, drawLineMode } =
      store.getState().digitalTwinReducer;
    if (map && drawerControl && drawLineMode.mode === "freeline") {
      drawerControl.changeMode("draw_line_string");
    }
    return null;
  };

  const ToolLineRelationObjects = () => {
    const map = store.getState().digitalTwinReducer.map;
    const drawerControl = store.getState().digitalTwinReducer.drawerControl;
    const drawLineMode = store.getState().digitalTwinReducer.drawLineMode;
    dispatch(setShowLines(true));
    if (map && drawerControl && drawLineMode.mode === "linerelations") {
      drawerControl.changeMode("draw_line_string");
      map.on("click", (e) => {
        clickMapLineRelations({ controlGl: drawerControl, map });
        map.once("click", (e) => {
          if (drawLineMode.features.length === 2) {
            map.off("click", () => {
              drawerControl.changeMode("simple_select");
              dispatch(setGeometryLine({}));
              dispatch(
                setDrawLineMode({
                  mode: "",
                  drawStatus: false,
                  show: false,
                  features: [],
                })
              );
            });
          }
        });
      });
    }
    return null;
  };

  const ToolMeasureLineDistance = () => {
    const map = store.getState().digitalTwinReducer.map;
    const drawerControl = store.getState().digitalTwinReducer.drawerControl;
    const drawLineMode = store.getState().digitalTwinReducer.drawLineMode;
    drawerControl.changeMode("draw_line_string");
    if (map && drawerControl && drawLineMode.mode === "linedistance") {
      map.on("click", (evt) => {
        map.once("dblclick", (e) => {
          drawerControl.changeMode("simple_select");
          clickMapDistance({ controlGl: drawerControl, map, event: e });
          map.off("click", () => console.log("event click off"));
          map.off("dblclick", () => console.log("event dblclick off"));
        });
      });
    }
    return null;
  };

  const clickMapDistance = useCallback(
    ({ controlGl, map, event }) => {
      const units = { units: "kilometers" };
      const gL = store.getState().adminReducer.setGeometryLine;
      const { tmpDistanceFeatures, drawLineMode } =
        store.getState().digitalTwinReducer;
      if (
        gL?.geometry?.type === "LineString" &&
        drawLineMode.mode === "linedistance"
      ) {
        const distance = calculareLineLength({
          coordinates: gL?.geometry?.coordinates,
          units,
        });
        gL.properties = { distance, ...units, id: gL.id };
        const uniques = uniqueObjsArr([...tmpDistanceFeatures.features, gL]);
        dispatch(
          setTmpDistanceFeatures({
            ...tmpDistanceFeatures,
            features: uniques,
          })
        );
        map.on("mouseenter", "line-distance", () => {
          map.getCanvas().style.cursor = "grab";
        });
        map.on("mouseleave", "line-distance", () => {
          map.getCanvas().style.cursor = "";
        });
        controlGl.trash();
      }
      return {};
    },
    [store, dispatch]
  );

  const cleanTools = () => {
    const { map, drawerControl, drawLineMode } =
      store.getState().digitalTwinReducer;
    //changemode to simple select if no line tool is selected
    return drawLineMode.mode === "select" || drawLineMode.mode === ""
      ? map.off("click", (e) => {
          drawerControl.changeMode("simple_select");
        })
      : null;
  };

  const selectLineForPopUp = ({ mapa, control, e }) => {
    const mode = control.getMode();
    const { drawLineMode } = store.getState().digitalTwinReducer;
    if (drawLineMode.mode === "linerelations") return;
    if (mode === "simple_select") {
      const bbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];
      const selectedFeatures = mapa.queryRenderedFeatures(bbox, {
        layers: ["line-id"],
      });
      const selectedFeatures2 = mapa.queryRenderedFeatures(bbox, {
        layers: ["line-distance"],
      });
      if (selectedFeatures.length > 0) {
        const typeFeature = JSON.parse(selectedFeatures[0].properties.object);
        const lineType = typeFeature.geometry.type === "LineString";
        const { lineIdPg } = selectedFeatures?.[0].properties;
        if (lineType && lineIdPg !== undefined) {
          dispatch(setShowLinePopUp({ id: lineIdPg, show: true, event: e }));
        }
      } else if (selectedFeatures2.length > 0) {
        const { tmpDistanceFeatures } = store.getState().digitalTwinReducer;
        dispatch(
          setTmpDistanceFeatures({
            ...tmpDistanceFeatures,
            click: {
              lng: e.lngLat.lng,
              lat: e.lngLat.lat,
              id: selectedFeatures2[0].properties.id,
            },
          })
        );
      } else {
        dispatch(setShowLinePopUp({ id: 0, show: false, event: {} }));
      }
    }
  };

  const findClosePoint = useCallback(
    (coordinate, radius, id) => {
      let closestPoint = null;
      let closestDistance = Infinity;
      const { filteredObjects: points } = store.getState().digitalTwinReducer;
      const [x, y] = coordinate;
      if (id) return points.find((it) => it.properties.object.id === id);
      points.filter((point) => {
        const [px, py] = point.geometry.coordinates;
        const fx = Math.sqrt((px - x) ** 2 + (py - y) ** 2);
        const r = fx < radius;
        if (r && fx < closestDistance) {
          closestDistance = fx;
          closestPoint = point;
        }
        return r;
      });
      return closestPoint;
    },
    [store]
  );

  /**
   * The `clickMapLineRelations` function defined using `useCallback` is responsible for handling the logic related to creating and managing line relations beetwen two objects on a map.
   * @function
   * @name clickMapLineRelations
   * @kind variable
   * @memberof LineToolsModules
   * @param {{ controlGl: any map: any }} { controlGl, map }
   * @returns {<void>}
   */
  const clickMapLineRelations = useCallback(
    async ({ controlGl, map }) => {
      const { type } = store.getState().digitalTwinReducer.drawAnyThing;
      const { relationPointsLines, objectsLinesIds, drawLineMode } =
        store.getState().digitalTwinReducer;
      let mode = controlGl.getMode();
      const zoom = map.getZoom();
      const maxZoom = 23;
      const radius = 0.00001;
      const radiusZoom = radius * (maxZoom - zoom);
      if (mode !== "draw_line_string" && type !== "point") {
        return;
      }
      const { features } = controlGl.getAll();
      const lastFeature = features[features.length - 1];
      const { geometry } = lastFeature;
      const { coordinates } = geometry;
      const lenCoordinates = coordinates.length;
      const point = coordinates[lenCoordinates - 2];
      const id = drawLineMode?.object?.id;
      let closePoint = id ? findClosePoint(point, radiusZoom, id) : undefined;
      await waitSeconds(0.3);
      if (
        mode === "draw_line_string" &&
        type === "line_string" &&
        drawLineMode.mode === "linerelations"
      ) {
        if (closePoint) {
          dispatch(
            setDrawLineMode({
              ...drawLineMode,
              features:
                drawLineMode.features.length === 2
                  ? [...drawLineMode.features]
                  : [...drawLineMode.features, closePoint],
            })
          );
        }

        if (closePoint === null && relationPointsLines.length === 0) {
          controlGl.changeMode("simple_select");
          await waitSeconds(0.3);
          controlGl.trash();
          controlGl.changeMode("draw_line_string");
        }
        if (closePoint && relationPointsLines.length === 0) {
          dispatch(setRelationPointLines([lastFeature]));
          dispatch(setObjecstsLinesIds([closePoint.properties.object.id]));
        }
        if (
          relationPointsLines.length === 1 &&
          closePoint &&
          relationPointsLines[0].id === lastFeature.id
        ) {
          const index = lastFeature.geometry.coordinates.length - 1;
          const index2 = lastFeature.geometry.coordinates.length - 2;
          lastFeature.geometry.coordinates[index] =
            closePoint.geometry.coordinates;
          lastFeature.geometry.coordinates[index2] =
            closePoint.geometry.coordinates;

          const aux = {
            type: "FeatureCollection",
            features: [{ ...lastFeature }],
          };
          controlGl.set(aux);
          controlGl.changeMode("simple_select");
          dispatch(setRelationPointLines([]));
          dispatch(
            setObjecstsLinesIds([
              ...objectsLinesIds,
              closePoint.properties.object.id,
            ])
          );
          return;
        }
      }
      if (closePoint) {
        const [lon, lat] = closePoint.geometry.coordinates;
        coordinates[coordinates.length - 2] = [lon, lat];
        controlGl.set({
          type: "FeatureCollection",
          features: [{ ...lastFeature }],
        });
      }
      const { object } = drawLineMode;
      if (object !== undefined) {
        const { drawLineMode } = store.getState().digitalTwinReducer;
        const paActualizar = drawLineMode;
        delete paActualizar.object;
        dispatch(setDrawLineMode(paActualizar));
      }
    },
    [findClosePoint, store, dispatch]
  );

  return {
    ToolFreeline,
    ToolLineRelationObjects,
    cleanTools,
    selectLineForPopUp,
    ToolMeasureLineDistance,
  };
}

export default LineToolsModules;
