import React from "react";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";

const CustomToolTipButton = ({ title, placement, translateY, isActive }) => {
  return (
    <SimpleToolTip title={title} placement={placement}>
      <div
        style={{
          width: 0,
          height: 0,
          borderBottom: "20px solid transparent",
          borderRight: "12px solid transparent",
          position: "fixed",
          pointerEvents: "auto",
          zIndex: 1000,
          left: "calc(100% - 38px)",
          transform: `translateY(${translateY}px)`,
        }}
      />
    </SimpleToolTip>
  );
};

export default CustomToolTipButton;
