/**
 * Redondea un número a un número específico de decimales.
 *
 * @param {number|string} num - El número que se redondeará.
 * @param {number} [numDecimals=0] - El número de decimales al que se redondeará el número. Por defecto, es 0.
 * @returns {number} - El número redondeado con la cantidad especificada de decimales.
 *
 * @example
 * // Redondea el número 3.14159 a 2 decimales.
 * const resultado = fixDecimals(3.14159, 2);
 * // resultado = 3.14
 *
 * @example
 * // Redondea la cadena '123.456' a 1 decimal.
 * const resultado = fixDecimals('123.456', 1);
 * // resultado = 123.4
 *
 * @example
 * // Devuelve 0.0 si no se proporcionan argumentos.
 * const resultado = fixDecimals();
 * // resultado = 0.0
 */
export function fixDecimals(num = 0, numDecimals = 0) {
  try {
    if (typeof num === "number") num = `${num}`;
    if (typeof num === "string") {
      const decimals = num.split(".");
      const fixed =
        decimals[1].length < numDecimals
          ? decimals[1]
          : decimals[1].substring(0, numDecimals);
      return Number(decimals[0].concat(`.${fixed}`));
    }
    return 0.0;
  } catch (error) {
    return 0.0;
  }
}