import { RED_COLOR } from "utils/const";

export const StyleDrawControl = [
  {
    id: "gl-draw-polygon",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#3eff4e",
      "line-dasharray": [0.7, 2],
      "line-width": 3,
      "line-opacity": 0.7,
    },
  },
  {
    id: "gl-draw-fill-polygon",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#12d49a",
      "fill-opacity": 0.4,
    },
  },
  // change fill color polygon when is selected
  {
    id: "gl-draw-polygon-fill-color",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
    paint: {
      "fill-color": "#33aa76",
      "fill-opacity": 0.5,
    },
  },
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#3bddb7",
      "line-width": 2,
    },
  },
  // change color line when is selected
  {
    id: "gl-draw-line-color",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#dd28a1",
      "line-width": 3,
      "line-dasharray": [1, 3],
    },
  },
  {
    id: "gl-draw-point",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#5f00f8",
    },
  },
  // change color point when is selected
  {
    id: "gl-draw-point-selected",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "active", "true"]],
    paint: {
      "circle-radius": 7,
      "circle-color": RED_COLOR,
    },
  },
];
