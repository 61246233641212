import React from "react";
import { SECOND_COLOR } from "utils/const";

const ObjectIcon = ({ width, height }) => {
  return (
    <div style={{ marginBottom: "2px" }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 293.000000 294.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,294.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            fill="#e44040"
            d="M1315 2919 c-187 -25 -407 -118 -562 -237 -242 -188 -399 -457 -444
-762 -14 -102 -7 -309 15 -400 47 -199 142 -387 269 -534 56 -64 185 -172 247
-207 28 -16 76 -70 157 -177 65 -86 125 -167 134 -181 9 -15 64 -89 122 -165
59 -76 107 -141 107 -145 0 -3 -60 -5 -132 -4 -73 2 -145 0 -161 -3 -33 -7
-46 -44 -27 -80 l13 -24 410 0 c379 0 411 1 423 18 7 9 14 29 14 44 0 38 -43
49 -187 48 -65 -1 -121 -1 -125 -1 -4 1 47 73 115 160 67 88 181 237 252 331
76 100 145 180 165 193 178 110 350 324 440 550 120 300 101 655 -51 947 -167
321 -455 540 -811 616 -90 19 -289 26 -383 13z m337 -243 c95 -21 151 -41 247
-92 232 -121 408 -345 472 -599 32 -125 32 -312 1 -439 -65 -260 -254 -502
-482 -616 -155 -78 -294 -106 -479 -97 -242 11 -448 106 -612 282 -226 241
-308 531 -243 847 72 351 375 647 735 717 71 14 287 12 361 -3z"
          />
          <path
            fill="#000000"
            d="M1116 1930 c-70 -21 -110 -88 -104 -172 7 -96 65 -151 162 -151 87 0
141 40 161 120 35 137 -81 244 -219 203z m141 -87 c41 -50 23 -141 -33 -168
-60 -28 -109 -13 -139 45 -28 55 -12 119 36 147 39 22 109 10 136 -24z"
          />
          <path
            fill="#000000"
            d="M1412 1773 l3 -158 85 -3 c101 -3 123 1 150 30 27 29 27 91 1 117
-18 18 -19 22 -5 50 17 35 11 72 -18 98 -16 14 -41 19 -119 21 l-100 4 3 -159z
m176 73 c6 -27 -24 -46 -70 -46 -37 0 -38 1 -38 36 l0 35 52 -3 c43 -2 54 -7
56 -22z m11 -110 c14 -9 14 -53 1 -61 -6 -4 -35 -9 -65 -12 l-55 -6 0 41 c0
23 5 42 13 45 17 6 93 1 106 -7z"
          />
          <path
            fill="#000000"
            d="M1750 1905 c0 -23 3 -25 50 -25 l50 0 0 -100 c0 -90 -2 -100 -20
-110 -16 -9 -28 -7 -53 5 -29 15 -33 15 -44 -2 -25 -33 -16 -43 59 -66 27 -9
86 12 103 36 12 17 15 51 15 154 l0 133 -80 0 c-79 0 -80 0 -80 -25z"
          />
        </g>
      </svg>
    </div>
  );
};

export default ObjectIcon;
