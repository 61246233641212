import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

export const LinksContainer = styled.aside`
  .item-links {
    list-style: none;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    margin-right: 4rem;
    padding: 1rem;
    border-radius: 5px;
    color: ${PRIMARY_COLOR};
  }

  .item-links:hover {
    cursor: pointer;
    color: ${SECOND_COLOR};
  }
`;
