import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function BasicDatePicker({
  label,
  value,
  setValue,
  maxDate,
  minDate,
  setUseFilterCalendar,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          sx={{
            width: "100px",
          }}
          label={label}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            setUseFilterCalendar(true);
          }}
          maxDate={maxDate}
          minDate={minDate}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
