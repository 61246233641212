import { useState, useEffect } from "react";
import findUser from "services/findUser";

const useUserRole = () => {
  const role = localStorage.getItem("role");
  const [userRole, setUserRole] = useState(role);
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    if (userId) {
      findUser(userId).then(({ data }) => {
        const { operator } = data;
        let userRole = role;
        if (operator) {
          userRole = operator?.role?.role;
          setUserRole(userRole);
        }
      });
    } else {
      setUserRole(null);
    }
  }, [userId, role]);
  return { userRole };
};

export default useUserRole;
