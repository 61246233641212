import { BiVideoRecording, BiCollection } from "react-icons/bi";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "utils/const";
//ICONS
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { BiBrain } from "react-icons/bi";
import styled from "styled-components";

const userId = localStorage.getItem("userId");

export const ListItemTextStyle = {
  color: PRIMARY_COLOR,
};

export const iconStyle = {
  color: "var(--background-primary_color)",
  fontSize: "2.5rem",
};

export const iconStyle2 = {
  color: WHITE_COLOR,
  fontSize: "2.5rem",
};

export const iconStyle3 = {
  color: document.body.style.color,
  fontSize: "2.5rem",
};

export const VersionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 6rem 2rem;
  position: relative;

  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2.2rem;
    color: ${PRIMARY_COLOR};
  }

  p {
    text-align: right;
    margin-right: 2rem;
    padding: 3rem;
  }

  sup {
    font-size: 2.3rem;
  }
`;

export const LogoBar = styled.div`
  display: flex;
  background-size: cover;
  width: 220px;
  height: 220px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 40%;
  div {
    margin-bottom: 2rem;
    z-index: -1;
  }
`;

const dashboard = (url, name) => ({
  icon: <InsertChartOutlinedIcon style={iconStyle} data-tip />,
  url,
});

const addUser = (url, name) => ({
  icon: (
    <ManageAccountsOutlinedIcon style={iconStyle} sx={iconStyle} data-tip />
  ),
  url,
});

const digitalTwin = (url, name) => ({
  icon: <ChangeCircleOutlinedIcon style={iconStyle} data-tip />,
  url,
});

const socrates = (url, name) => ({
  icon: <BiBrain style={iconStyle} data-tip />,
  url,
});

const settings = (url, name) => ({
  icon: <SettingsOutlinedIcon style={iconStyle} data-tip />,
  url,
});

const video = (url, name) => ({
  icon: <BiVideoRecording style={iconStyle} data-tip />,
  url,
});

const Collection = (url, name) => ({
  icon: <BiCollection style={iconStyle} data-tip />,
  url,
});

export const SideBarItems = {
  "Administrador de decimetrix": {
    "Company managers": addUser("/admin-companies", "Company managers"),
    Settings: settings("/config-admin", "Settings"),
  },
  "Administrador de compañía": {
    "KPIs & Dashboards": dashboard("/dashboard-admin", "Dashboard"),
    "Digital twin": digitalTwin("/digital-twin-admin", "Digital twin"),
    Users: addUser("/operators", "Operators"),
    Settings: settings("/config-admin", "Settings"),
    "AI Socrates": socrates("/chatbot", "Socrates"),
  },
  "IT Support": {
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    Users: addUser("/operators-user", "Operators"),
  },
  "Equipo de campo": {
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Video manager": video(`/upload-videos/${userId}`, "Video manager"),
  },
  "Equipo de oficina": {
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Video manager": video(`/field-video-manager`, "Video manager"),
  },
  "Director de proyecto": {
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
  },
};
