/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { Container } from "./StyleContentDialog";
import CustomSelect from "components/Buttons/CustomSelect";
import CustomButton from "components/Buttons/CustomButton";
import useFindTypeComponets from "hooks/useFindTypeComponents";
import updatePointComponent from "services/updatePointComponent";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import { config } from "config.js";

const ContentConvertComponent = ({ pointComponent, setOpenDialog }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [select, setSelect] = useState();
  const [icon, setIcon] = useState("");

  const { mutate } = useSWRConfig();

  const pointLibraryComponentId =
    pointComponent.pointTypeComponent.pointLibraryComponentId;

  const { typeComponents, errorTypeComponents } = useFindTypeComponets({
    id: pointLibraryComponentId,
  });

  useEffect(() => {
    if (icon === "" || icon === undefined) {
      setIcon(typeComponents?.[0].icon);
      setSelect(typeComponents?.[0].id);
    }
  }, [typeComponents]);

  useEffect(() => {
    const typeComponent = typeComponents?.find(
      (typeComponent) => typeComponent.id === parseInt(select)
    );
    setIcon(typeComponent?.icon);
  }, [select]);

  const convertComponent = async () => {
    let body = {
      pointTypeComponentId: Number(select),
      updatedAt: new Date(),
    };
    setIsLoad(true);
    await updatePointComponent({ id: pointComponent.id, body: body });
    setIsLoad(false);
    setOpenDialog(false);
    const urlDataComponent = `${config.URL_BACKEND_PG}api/v1/point-components?id=${pointComponent?.id}`;
    mutate(urlDataComponent);
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const urlComponents = `${config.URL_BACKEND_PG}api/v1/point-components?adminCompanyId=${adminCompanyId}`;
    mutate(urlComponents);
  };

  return (
    <>
      {!errorTypeComponents && (
        <Container>
          <h2 className="title">
            <span>Library: </span>
            {typeComponents?.[0]?.pointLibraryComponent?.name}
          </h2>
          <CustomSelect
            options={typeComponents
              ?.slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
            label={"Component type"}
            select={select}
            setSelect={setSelect}
          />
          {icon && (
            <figure className="icon">
              <img src={icon} alt="icon of component" />
            </figure>
          )}
          <CustomButton
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECOND_COLOR}
            text={"CONVERT"}
            onClick={convertComponent}
            isLoad={isLoad}
            margin={0}
          />
        </Container>
      )}
    </>
  );
};

export default ContentConvertComponent;
