import { AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./styles";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import { useState } from "react";
import { useEffect } from "react";
import findOperations from "services/findOperations";
import findObjects from "services/objects/findObjects";

export default function MinimizedDetail({ params }) {
  const { objectId, operationId } = params?.content?.props;
  const [geo, setGeo] = useState({});
  const map = useSelector((state) => state.digitalTwinReducer.map);

  const dispatch = useDispatch();
  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );

  const handleOpenDialog = () => {
    if (!drawAnyThing) {
      map.flyTo({
        center: [geo.longitude, geo.latitude],
        duration: 2000,
      });
      dispatch(setOperationDetails(params));
      dispatch(setShowOperationDetails(true));
    }
  };

  useEffect(() => {
    let location = {};
    if (!operationId && !objectId) return;
    const getGeo = async () => {
      if (operationId) {
        const data = await findOperations(operationId);
        location = data?.locationOperation;
      }
      if (objectId) {
        const data = await findObjects(objectId);
        location = data?.location;
      }
      setGeo({
        longitude: parseFloat(location?.longitude),
        latitude: parseFloat(location?.latitude),
      });
    };
    getGeo();
  }, [operationId, objectId]);

  return (
    <Container>
      <span className="icon-table" onClick={handleOpenDialog}>
        <AiFillEye />
      </span>
    </Container>
  );
}
