import axios from "axios";
import { config } from "config.js";

const uploadFileComponent = async (body) => {
  try {
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/component-files`,
      body
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default uploadFileComponent;

/**
 * Saves the captured media to a point component.
 *
 * @param {Object} params - The parameters for saving the captured media.
 * @param {string} params.pointComponentMgId - The ID of the point component.
 * @param {Array} params.media - The captured media to be saved.
 * @param {string} params.media.type - The type of the media.
 * @param {string} params.media.alias - The alias of the media.
 * @param {string} params.media.url - The URL of the media.
 * @return {Object} - The saved data and the response object.
 */
export const saveCaptureMedia = async ({ pointComponentMgId, media }) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.patch(
    `${config.URL_BACKEND_MG}point-components/${pointComponentMgId}`,
    {
      $push: {
        captureMedia: {
          $each: media,
        },
      },
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};
