const handleAddGridRow = ({ index, setDragAndDroll, setCurrentTemplate }) => {
  setDragAndDroll(true);
  setCurrentTemplate((current) => {
    const chartsPosition = current.chartsPosition;
    const found = chartsPosition.some((elm) => elm.index === index);

    if (found) {
      return {
        ...current,
        chartsPosition: chartsPosition.map((elm) =>
          elm.index === index
            ? {
                ...elm,
                gridRowStart: `span ${
                  parseInt(elm.gridRowStart.split(" ")[1]) + 1
                }`,
              }
            : elm
        ),
      };
    } else {
      return {
        ...current,
        chartsPosition: [
          ...chartsPosition,
          {
            index: index,
            gridRowStart: "span 2",
            gridColumnStart: "span 1",
          },
        ],
      };
    }
  });
};

export default handleAddGridRow;
