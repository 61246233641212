import ComponentsImg from "images/ComponentsImg";
import ProductIterationImg from "images/ProductIterationImg";
import EventsImg from "images/EventsImg";
import LibrariesImg from "images/LibrariesImg";

const contentCards = [
  {
    id: 1,
    title: "OBJECTS",
    image: <ProductIterationImg />,
    description: "Add or edit your objects",
    toNavigate: "/object-configurations",
  },
  {
    id: 2,
    title: "COMPONENTS",
    image: <ComponentsImg />,
    description: "Add or edit your components",
    toNavigate: "/component-configurations",
  },
  {
    id: 3,
    title: "EVENTS",
    image: <EventsImg />,
    description: "Add or edit your events",
    toNavigate: "/config-actions-events",
  },
  {
    id: 4,
    title: "LIBRARIES",
    image: <LibrariesImg />,
    description: "Add or edit your libraries",
    toNavigate: "/library-configurations",
  },
];

export default contentCards;
