import axios from "axios";
import { config } from "config.js";

/**
 * Fetches all filters based on the given query parameters.
 *
 * @param {Object} query - Query parameters to filter dates
 * @returns {Promise<Object[]>}
 *
 */

const findFilterDate = async (query) => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_PG}api/v1/date-filter`;
  // Convert query object to URL query string
  const queryString = new URLSearchParams(query).toString();
  const result = await axios.get(`${url}?${queryString}`, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return result.data;
};

export default findFilterDate;
