import updateRotationPhoto from "services/updateRotationPhoto";
import { updateCaptureMedia } from "services/uploadFilePointEvent";
import { updateCaptureMediaLines } from "services/lines/uploadFileLines";

export default function saveRotationImage(
  files,
  uploadImages,
  setCurrentIndex,
  photos,
  item,
  mongoId
) {
  files.forEach(async (file, index) => {
    if (item.id === file.id && item.type === "object") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      uploadImages();
      await updateRotationPhoto(`object-files/${file.id}`, {
        rotation: file.rotation,
      });
    }
    if (item.id === file.id && item.type === "pointObjectMg") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      uploadImages();
      const photo = photos.find((photo) => {
        return photo._id === file.id;
      });

      // await updateObjectPg(
      //   { lastUpdate: new Date(), userUpdateId: dataObject.userId },
      //   dataObject.id
      // );

      await updateCaptureMedia({
        endPoint: "point-object/object-file/",
        pointTypeMgId: mongoId,
        mediaId: file.id,
        media: {
          ...photo,
          rotation: file.rotation,
        },
      });
    }
    if (item.id === file.id && item.type === "operation") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      uploadImages();
      await updateRotationPhoto(`operation-photo/${file.id}`, {
        rotation: file.rotation,
      });
    }
    if (item.id === file.id && item.type === "pointEventPg") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      await updateRotationPhoto(`event-files/${file.id}`, {
        rotation: file.rotation,
      });
    }
    if (item.id === file.id && item.type === "pointComponentPg") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      await updateRotationPhoto(`component-files/${file.id}`, {
        rotation: file.rotation,
      });
    }
    if (item.id === file.id && item.type === "pointEventMg") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      const photo = photos.find((photo) => photo._id === file.id);
      await updateCaptureMedia({
        endPoint: "point-event/event-file/",
        pointTypeMgId: mongoId,
        mediaId: file.id,
        media: {
          ...photo,
          rotation: file.rotation,
        },
      });
    }
    if (item.id === file.id && item.type === "pointComponentMg") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      const photo = photos.find((photo) => photo._id === file.id);
      await updateCaptureMedia({
        endPoint: "point-components/component-file/",
        pointTypeMgId: mongoId,
        mediaId: file.id,
        media: {
          ...photo,
          rotation: file.rotation,
        },
      });
    }
    if (item.id === file.id && item.type === "line") {
      setCurrentIndex({
        type: file.type,
        index: index,
      });
      const photo = photos.find((photo) => photo._id === file.id);
      await updateCaptureMediaLines({
        endPoint: "line",
        mongoId,
        mediaId: file.id,
        media: {
          ...photo,
          rotation: file.rotation,
        },
      });
    }
  });
}
