import CustomTabs from "pages/AdminCompany/Hub/CustomTabs";
import React from "react";
import ModulesAdminDeci from "./ModulesAdminDeci";

const AdminTabsDeci = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <CustomTabs value={value} onChange={handleChange} />

      {value === 0 && <ModulesAdminDeci />}
      {value === 1 && <ModulesAdminDeci hideCards={true} />}
      {value === 2 && <ModulesAdminDeci hideCards={true} />}
    </div>
  );
};

export default AdminTabsDeci;
