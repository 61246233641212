import React from "react";
import {
  ContainerIntro,
  ContainerTitle,
  HubH1,
  HubD,
  LogoCont,
} from "./HubStyles";
import LastLoginInfo from "./LastLoginInfo";
import NeuronaIconD from "images/NeuronaIconD";

const DescriptionHub = ({
  adminCompany,
  adminDecimetrix,
  operatorDecimetrix,
}) => {
  return (
    <>
      <ContainerIntro className="background-1">
        <LogoCont className="miniLogo">
          <NeuronaIconD width={60} height={60} />
        </LogoCont>
        <ContainerTitle>
          <HubH1>
            Decimetrix<sup style={{ fontSize: 25 }}>®</sup> Green Dragon,
            accelerate your journey to NET-ZERO.
          </HubH1>
          <HubD>
            Create and get value from your digital Inventory, Inspect and
            integrate your efforts around sustainability, detect, quantify and
            report your progress towards NET ZERO, all in one tool for your
            enterprise. Collaborate like never before to meet your goals.
          </HubD>
        </ContainerTitle>
        <LogoCont className="maxLogo">
          <NeuronaIconD width={200} height={200} />
        </LogoCont>
      </ContainerIntro>
      <LastLoginInfo
        adminCompany={adminCompany}
        adminDecimetrix={adminDecimetrix}
        operatorDecimetrix={operatorDecimetrix}
      />
    </>
  );
};

export default DescriptionHub;
