import { useState, useEffect, useCallback } from "react";
// Helpers
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/objectsLineChart";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { config } from "config";

const useFindLineCharts = ({ openDialog, objects, loading }) => {
  const [charts, setCharts] = useState(null);
  const [lineCharts, setLineCharts] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlLineChart = `${config.URL_BACKEND_MG}line-chart?adminCompanyId=${adminCompanyId}&from=objects`;
  const { data: dataLineChart, error: errorDataLineChart } =
    useSwr(urlLineChart);

  useEffect(() => {
    if (!errorDataLineChart && dataLineChart) setCharts(dataLineChart);
  }, [dataLineChart, errorDataLineChart, openDialog, reloadCharts]);

  useEffect(() => {
    const objectsFormat = createRowsObjects({ objects });
    setObjectsFormat(objectsFormat);
  }, [objects]);

  const getDataBarChart = useCallback(async () => {
    if (!loading && objectsFormat) {
      const lineCountsvalues = countByValues({
        charts,
        objects: objectsFormat,
      });

      const lineSumValues = sumValues({
        charts,
        objects: objectsFormat,
      });

      setLineCharts([...lineCountsvalues, ...lineSumValues]);
    }
  }, [loading, objectsFormat, charts]);

  useEffect(() => {
    if (charts && objects) {
      getDataBarChart();
    }
  }, [charts, objects, getDataBarChart]);

  return { lineCharts };
};

export default useFindLineCharts;
