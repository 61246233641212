import { parse, parseISO, isBefore, isAfter } from "date-fns";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useFilterComponentsByDateAndUser = ({
  components,
  users,
  componentsMap,
}) => {
  const [filterComponents, setFilterComponents] = useState([...components]);
  const [filterComponentsMap, setFilterComponentsMap] = useState([]);

  const dateFilterObject = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );

  const { endDate: endDateString, startDate: startDateString } =
    dateFilterObject;

  const endDate = useMemo(
    () => parse(endDateString, "yyyy-MM-dd", new Date()),
    [endDateString]
  );
  const startDate = useMemo(
    () => parse(startDateString, "yyyy-MM-dd", new Date()),
    [startDateString]
  );

  const filterComponentByDateAndUser = useCallback(() => {
    if (components.length > 0) {
      const newComponents = components.filter((component) => {
        const { userId } = component;
        const dateComponent = parseISO(
          component.date,
          "yyyy-MM-dd",
          new Date()
        );
        const isInRange =
          !isBefore(dateComponent, startDate) &&
          !isAfter(dateComponent, endDate);
        const isShowUSer = users && users[userId]?.isShow;
        const isShow = isInRange && isShowUSer;
        return isShow;
      });
      setFilterComponents(newComponents);
    }
  }, [components, startDate, endDate, users]);

  const filterComponentsMapByDateAndUser = useCallback(() => {
    if (componentsMap.length > 0) {
      const newComponentsMap = componentsMap.filter((component) => {
        const userId = component.properties.component.userId;
        const dateComponent = parseISO(
          component.properties.component.date,
          "yyyy-MM-dd",
          new Date()
        );
        const isInRange =
          !isBefore(dateComponent, startDate) &&
          !isAfter(dateComponent, endDate);
        const isShowUSer = users && users[userId]?.isShow;
        const isShow = isInRange && isShowUSer;
        return isShow;
      });
      setFilterComponentsMap(newComponentsMap);
    }
  }, [componentsMap, startDate, endDate, users]);

  useEffect(() => {
    filterComponentByDateAndUser();
    filterComponentsMapByDateAndUser();
  }, [filterComponentByDateAndUser, filterComponentsMapByDateAndUser]);

  return {
    filterComponents,
    filterComponentsMap,
  };
};

export default useFilterComponentsByDateAndUser;
