/**
 * randomColorRGBA returns a random RGBa color, when param 'pair' is set to true returns an array with two colors, [basecolor, transparentcolor]
 * when called without arguments, returns a string random rgba color with random transparency.
 * @function
 * @name randomColorRGBA
 * @kind function
 * @param {boolean} { pair } - if pair set to true returns [baseColor, baseColor+Transparency]
 * @param {double} { opacity } - if opacity param set to number beetween 0 - 1, transparency will be set by param.
 * @returns {string | string[]}
 * @example
 * - randomColorRGBA() → 'rgba(75, 192, 192, 0.2)'
 * - randomColorRGBA({pair: true}) → ['rgba(75, 192, 192, 1)', 'rgba(75, 192, 192, 0.2)']
 * - randomColorRGBA({pair: true, opacity: 0.7}) → ['rgba(75, 192, 192, 1)', 'rgba(75, 192, 192, 0.7)']
 */
function randomColorRGBA({ pair = false, opacity = 0 }) {
  try {
    const color = () => {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);
      return { red, green, blue };
    };
    const alpha = Math.random().toFixed(1);
    const cPair = color();
    if (pair) {
      return [
        `rgba(${cPair.red}, ${cPair.green}, ${cPair.blue}, 1)`,
        `rgba(${cPair.red}, ${cPair.green}, ${cPair.blue}, ${
          opacity === 0 ? alpha : opacity
        })`,
      ];
    }
    return `rgba(${cPair.red}, ${cPair.green}, ${cPair.blue}, ${
      opacity === 0 ? alpha : opacity
    })`;
  } catch (error) {
    console.error("Error generating color RGBA:", error.message);
    return null;
  }
}

export default randomColorRGBA;
