import axios from "axios";
import { config } from "config.js";

const URl_BASE_PG = config.URL_BACKEND_PG;

export const createLoginRegister = async ({ id = -1, token }) => {
  try {
    if (id < 0) return;
    const request = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/login-history`,
      {
        userId: id,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const res = await request.data;
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getLoginHistory = async ({ id = -1, count = false, token }) => {
  try {
    if (id < 0) return;
    const urlParams = new URLSearchParams();
    urlParams.append("userId", id);
    if (count) urlParams.append("count", count);
    const res = await fetch(
      `${URl_BASE_PG}api/v1/login-history?${urlParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const response = await res.json();
    return response;
  } catch (error) {
    console.error(error);
  }
};
