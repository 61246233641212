import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "utils/const";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE_COLOR};
  border-radius: 5px;
  /* padding: 10px 20px; */
  width: 100%;
  min-width: 320px;
  color: #2c2c2c;

  b {
    display: flex;
    justify-content: center;
    font-size: medium;
  }

  h1 {
    font-size: 3rem;
  }

  .location {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: ${WHITE_COLOR};
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .wrapper-inputs {
    display: flex;
    width: 100%;
    gap: 0 30px;
    justify-content: space-between;
    align-items: center;
  }

  .wrapper-check-update {
    display: grid;
    grid-template-columns: 1fr 6fr;
  }

  .wrapper-inputs div {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
  }

  button[type="submit"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    /* margin: 20px 0; */
    background-color: var(--background-primary_color);
    color: ${WHITE_COLOR};

    &:hover {
      cursor: pointer;
      background-color: var(--background-secondary_color);
    }
  }

  @media (max-width: 780px) {
    .wrapper-inputs {
      display: flex;
      flex-direction: column;
    }
    .wrapper-inputs div {
      align-self: center;
      width: 100%;
    }
  }
`;

export const StepSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  b {
    font-size: 2rem;
    font-weight: 800;
  }
`;
