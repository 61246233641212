import axios from "axios";
import { config } from "config.js";

const deleteViewTableColumns = async ({
  nameView,
  typeOfLibrary,
  typeOfTable,
}) => {
  try {
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${config.URL_BACKEND_MG}order-columns/${userId}?adminCompanyId=${adminCompanyId}&name=${nameView}&typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default deleteViewTableColumns;
