import { useNavigate } from "react-router-dom";
import {
  SideBarContainer,
  SidebarWrapper,
  Icon,
  CloseIcon,
  SideBtnWrap,
  SidebarRoute,
  SidebarMenu,
  SidebarLink,
} from "./SidebarElements";
import ThemeToggleButton from "components/Buttons/ThemeToggleButton";

const SideBar = ({ toggle, isOpen }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    const loginStatus = localStorage.getItem("loginStatus");
    if (loginStatus === "true") {
      navigate(`/hub`);
    } else {
      navigate("/signIn");
    }
  };
  return (
    <>
      <SideBarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink onClick={toggle} to="greenDragon">
              Green Dragon
            </SidebarLink>
            <SidebarLink onClick={toggle} to="discover">
              Discover
            </SidebarLink>
            <SidebarLink onClick={toggle} to="value">
              Value
            </SidebarLink>
            {/* <SidebarLink onClick={toggle} to="ProductAndServices">
              Product and services
            </SidebarLink>
            <SidebarLink onClick={toggle} to="value">
              Connect
            </SidebarLink> */}
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarRoute onClick={handleClick}>Log In</SidebarRoute>
          </SideBtnWrap>
        </SidebarWrapper>
      </SideBarContainer>
    </>
  );
};

export default SideBar;
