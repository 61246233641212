import useSupercluster from "use-supercluster";
import { useSelector, useDispatch } from "react-redux";
import {
  setObjectsDigitalTwin,
  setFilteredObjectsDigitalTwin,
} from "redux/actions";
import { setDataObjectsPolygon } from "redux/actions/admin";
import { useEffect } from "react";

// Hooks
import useFilteredObjects from "hooks/useFilteredObjects";
import POINT_TYPE from "./const";
import { useStore } from "react-redux";
import useFilteredObjectsMap from "hooks/useFilteredObjectsMap";
import ObjectsLayer from "./ObjectsLayer";

/**
 * Renders object clusters on the map.
 *
 * @param {Object} objects - array of objects to render
 * @param {Object} bounds - bounds of the map
 * @param {Function} onSelectPoint - function to handle point selection
 * @param {Object} viewState - current view state of the map
 * @param {Function} setViewState - function to update the view state
 * @return {JSX.Element} - JSX object representing the object clusters on the map
 */
const ObjectsClusters = ({
  objects,
  objectsMap,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
  setLayerIds,
  mapRef,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const state = store.getState();

  const dataObjectsPolygon = state.adminReducer.dataObjectsPolygon;

  const objectClusters = useSelector(
    (state) => state.digitalTwinReducer.objectClusters
  );

  const showMarkersByLibrary = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );
  const showMarkerByElementWithMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );
  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );

  const dataOriginalObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataOriginalObjectsPolygon
  );
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const dataTable = useSelector(
    (state) => state.adminReducer.objectsFilterTable
  );

  // data to geoJSON
  const objectsFilter = useFilteredObjects(objects);
  const objectsFilterMap = useFilteredObjectsMap(objectsMap);
  const originalData = useFilteredObjects(dataOriginalObjectsPolygon);

  const objectToPoint = (object) => {
    return {
      type: "Feature",
      properties: {
        cluster: false,
        object: {
          ...object,
          type: POINT_TYPE.object,
          id: object.id,
          icon: object.typeElement.urlIconColor,
          location: {
            latitude: object.location.latitude,
            longitude: object.location.longitude,
          },
          label: object?.label,
          haveMedia: object.haveMedia,
          QAQC: object.QAQC,
        },
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(object.location.longitude),
          parseFloat(object.location.latitude),
        ],
      },
    };
  };

  useEffect(() => {
    if (!objects || objects.length <= 0) return null;
    setLayerIds((current) => [...current, "point-object-id"]);
  }, [objects, setLayerIds]);

  const filteredObjects =
    dataTable !== undefined && dataTable.length !== 0
      ? dataTable.map((object) => objectToPoint(object))
      : objectsFilterMap;

  useEffect(() => {
    dispatch(setObjectsDigitalTwin(objectsFilterMap));
    if (
      dataObjectsPolygon.length !== objectsFilter.length &&
      geometryPolygon?.type !== "Polygon"
    ) {
      dispatch(setDataObjectsPolygon(objectsFilter));
    }
  }, [dispatch, objectsFilterMap, objectsFilter, objects]);

  useEffect(() => {
    dispatch(setFilteredObjectsDigitalTwin(filteredObjects));
  }, [dispatch, filteredObjects, objects]);

  useEffect(() => {
    if (geometryPolygon?.type === "Polygon" && drawAnyThing) {
      dispatch(setDataObjectsPolygon(originalData));
    }
  }, [
    originalData,
    objectsFilter,
    geometryPolygon,
    showMarkersByLibrary,
    showMarkersByTypeElement,
    showMarkerByElementWithMedia,
    dispatch,
    objects,
  ]);

  const { clusters } = useSupercluster({
    points: filteredObjects,
    bounds,
    zoom: viewState.zoom,
    options: {
      radius: objectClusters.radius || 0,
      maxZoom: objectClusters.maxZoom || 0,
    },
  });

  return (
    <ObjectsLayer
      clusters={clusters}
      onSelectPoint={onSelectPoint}
      setViewState={setViewState}
      viewState={viewState}
      objectsLength={filteredObjects.length}
      mapRef={mapRef}
    />
  );
};

export default ObjectsClusters;
