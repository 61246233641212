import { useState, useEffect, useRef } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./SortableItem";
import Grid from "./Grid";
import useStoreDashboard from "../Store";
import { getWrapperTemplate } from "../helpers/getWrapperTemplate";
import OpenDialog from "components/Dialogs/OpenDialog";
import RestoreCharts from "../components/RestoreCharts";

const DropDragGrid = ({
  dataChart,
  allDataKpiCharts,
  currentOrderName,
  currentOrderViews,
  setCurrentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  openDialogRestoreChart,
  setOpenDialogRestoreChart,
  orderId,
}) => {
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState([]);
  const { setDragAndDroll } = useStoreDashboard();

  useEffect(() => {
    if (currentOrderName === "Design New View") {
      const updatedItems = dataChart.map((elm, index) => ({
        ...elm,
        sizeChart: {
          width: getWrapperTemplate({
            template: currentTemplate,
            index,
            size: true,
          }).width,
          height: getWrapperTemplate({
            template: currentTemplate,
            index,
            size: true,
          }).height,
        },
      }));
      setItems(updatedItems);
      const updatedCurrentOrderViews = dataChart.map((elm, index) => ({
        id: elm._id,
        sizeChart: {
          width: getWrapperTemplate({
            template: currentTemplate,
            index,
            size: true,
          }).width,
          height: getWrapperTemplate({
            template: currentTemplate,
            index,
            size: true,
          }).height,
        },
      }));
      setCurrentOrderViews(updatedCurrentOrderViews);
    }
  }, [currentOrderName, currentTemplate, dataChart]);

  useEffect(() => {
    if (currentOrderName !== "Design New View") {
      const newOrder = [];
      currentOrderViews?.forEach((item, index) => {
        dataChart.forEach((elm) => {
          if (elm._id === item.id) {
            newOrder.push({
              ...elm,
              sizeChart: {
                width: currentTemplate
                  ? getWrapperTemplate({
                      template: currentTemplate,
                      index,
                      size: true,
                    })?.width
                  : item.sizeChart.width,
                height: currentTemplate
                  ? getWrapperTemplate({
                      template: currentTemplate,
                      index,
                      size: true,
                    })?.height
                  : item.sizeChart.height,
              },
            });
          }
        });
      });

      setItems(newOrder);
    }
  }, [currentOrderName, currentTemplate, currentOrderViews, dataChart]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event) => {
    setActiveId(null);
    setDragAndDroll(true);
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over.id);

        const newOrder = arrayMove(items, oldIndex, newIndex);
        const data = newOrder.map((elm, index) => {
          if (currentTemplate) {
            return {
              ...elm,
              sizeChart: {
                width: getWrapperTemplate({
                  template: currentTemplate,
                  index,
                  size: true,
                }).width,
                height: getWrapperTemplate({
                  template: currentTemplate,
                  index,
                  size: true,
                }).height,
              },
            };
          }
          return elm;
        });
        return data;
      });
      setCurrentOrderViews((order) => {
        const oldIndex = order.findIndex((item) => item.id === active.id);
        const newIndex = order.findIndex((item) => item.id === over.id);

        const movedItem = order[oldIndex];
        const newOrder = [...order];
        newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, movedItem);
        const data = newOrder.map((elm, index) => {
          if (currentTemplate) {
            return {
              ...elm,
              sizeChart: {
                width: getWrapperTemplate({
                  template: currentTemplate,
                  index,
                  size: true,
                }).width,
                height: getWrapperTemplate({
                  template: currentTemplate,
                  index,
                  size: true,
                }).height,
              },
            };
          }
          return elm;
        });
        return data;
      });
    }
  };

  const dashboardChartsRef = useRef();
  const { setDashboardRef } = useStoreDashboard();
  useEffect(() => {
    dashboardChartsRef !== null && setDashboardRef(dashboardChartsRef);
  }, [dashboardChartsRef, setDashboardRef]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        boxSizing: "border-box",
        padding: "20px",
      }}
      ref={dashboardChartsRef}
    >
      <OpenDialog
        openDialog={openDialogRestoreChart}
        setOpenDialog={setOpenDialogRestoreChart}
        content={{
          title: "Restore Chart in View",
          description: (
            <RestoreCharts
              dataChart={dataChart}
              currentOrderViews={currentOrderViews}
              setCurrentOrderViews={setCurrentOrderViews}
              currentPositionId={currentPositionId}
              orderId={orderId}
              currentOrderName={currentOrderName}
              template={currentTemplate}
              setOpenDialogRestoreChart={setOpenDialogRestoreChart}
            />
          ),
        }}
        minWidth="70%"
        maxWidth="70%"
        height={"90%"}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        {/* como funciona el grid?  */}
        <Grid template={currentTemplate} minWidth={330} gap={"15px"}>
          <SortableContext
            items={items.map((item) => item._id)}
            strategy={rectSortingStrategy}
          >
            {items.map((item, index) => (
              <SortableItem
                allDataKpiCharts={allDataKpiCharts}
                key={item._id}
                item={item}
                setCurrentOrderViews={setCurrentOrderViews}
                currentOrderName={currentOrderName}
                currentOrderViews={currentOrderViews}
                dragActive={activeId}
                index={index}
                template={currentTemplate}
                currentTemplate={currentTemplate}
                setCurrentTemplate={setCurrentTemplate}
                currentPositionId={currentPositionId}
              />
            ))}
            <DragOverlay>
              {activeId ? (
                <div
                  style={{
                    width: `${
                      currentTemplate ? currentTemplate.sizeChart.width : "100%"
                    }px`,
                    height: `${
                      currentTemplate
                        ? currentTemplate.sizeChart.height
                        : "100%"
                    }px`,
                  }}
                >
                  <SortableItem
                    allDataKpiCharts={allDataKpiCharts}
                    item={items.find((item) => item._id === activeId)}
                    index={items.findIndex((item) => item._id === activeId)}
                    template={currentTemplate}
                    setCurrentOrderViews={setCurrentOrderViews}
                    dragActive={activeId}
                    widthOnMove={`${
                      currentTemplate ? currentTemplate.sizeChart.width : "100%"
                    }px`}
                    heightOnMove={`${
                      currentTemplate
                        ? currentTemplate.sizeChart.height
                        : "100%"
                    }px`}
                  />
                </div>
              ) : null}
            </DragOverlay>
          </SortableContext>
        </Grid>
      </DndContext>
    </div>
  );
};

export default DropDragGrid;
