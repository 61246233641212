const createDefaultColumns = (headers, keyValues, rows) => {
  const allValues = [];
  rows?.forEach((elm) => {
    for (let key in elm) {
      if (keyValues.includes(key) === false) {
        allValues.push(key);
      }
    }
  });
  const uniqueArray = [...new Set(allValues)];
  const allHeaders = headers.concat(uniqueArray);
  const allKeyValues = keyValues.concat(uniqueArray);
  const columnsFormat = [
    {
      header: null,
      footer: (props) => props.column.id,
      id: ` `,
      columns: allHeaders.map((clum, index) => {
        return {
          accessorKey: `${allKeyValues[index]}`,
          cell: (info) => info.getValue(),
          header: () => <span>{clum}</span>,
          footer: (props) => props.column.id,
        };
      }),
    },
  ];
  return {columnsFormat,allKeyValues};
};

export default createDefaultColumns;
