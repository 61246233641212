import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// Components
import DynamicFields from "components/Forms/SaveObject/DynamicFields";
import SelectInput from "components/Forms/SelectInput";
// Style
import { FeatureDetails } from "components/Forms/SaveObject/indexStyle";
import { WraperTitleCardMarker } from "components/ElementDetails/ImageDetailsElements";
// Hooks
import { useFetchObjectLibraries } from "hooks/fetchLibraries";

const CreateObject = ({ feature, objectId, closeModal, typeObjects }) => {
  const [type, setType] = useState();
  const [currentObjectType, setCurrentObjectType] = useState("");
  const [currentLibraryType, setCurrentLibraryType] = useState("");
  const [libraryType, setLibraryType] = useState({
    name: "Library type",
    options: [],
    disableOptions: [],
    id: 0,
  });
  const [objectType, setObjectType] = useState({
    name: "Object type",
    options: [],
    icons: [],
    id: 0,
  });

  const { data: typeObjectLibraries, errorTypeObjectLibraries } =
    useFetchObjectLibraries({ id: null });

  useEffect(() => {
    const librarieIds = typeObjects.map(
      (object) => object.PointTypeObjectRelation.pointLibraryId
    );

    var typeIcons = typeObjects.map((elm) => {
      const library =
        !errorTypeObjectLibraries &&
        typeObjectLibraries?.find((library) => {
          return elm.PointTypeObjectRelation.pointLibraryId === library.id;
        });
      return {
        name: elm.PointTypeObjectRelation.name,
        icon: elm.PointTypeObjectRelation.urlIconColor,
        library: library?.name,
      };
    });

    const currentLibraryTypes = [];
    const currentDisableLibraries = [];
    const currentObjectTypes = [];

    !errorTypeObjectLibraries &&
      typeObjectLibraries?.forEach((library) => {
        if (librarieIds?.includes(library.id)) {
          currentLibraryTypes.push(library.name);
          if (library.available === false) {
            currentDisableLibraries.push(library.name);
          }
        }
      });

    typeObjects.forEach((object) => {
      !errorTypeObjectLibraries &&
        typeObjectLibraries?.forEach((library, index) => {
          if (
            !errorTypeObjectLibraries &&
            typeObjectLibraries &&
            object.PointTypeObjectRelation.pointLibraryId === library.id
          ) {
            if (
              library.name === currentLibraryTypes[0] &&
              !currentDisableLibraries.includes(library.name)
            ) {
              currentObjectTypes.push(object.PointTypeObjectRelation.name);
            }
          }
        });
    });
    setObjectType((prev) => ({
      ...prev,
      options: currentObjectTypes,
      icons: typeIcons,
    }));

    setLibraryType((prev) => ({
      ...prev,
      options: currentLibraryTypes,
      disableOptions: currentDisableLibraries,
    }));

    setCurrentObjectType(currentObjectTypes[0]);
    setCurrentLibraryType(currentLibraryTypes[0]);
    const currentType = typeObjects.find((elm) => {
      return elm.PointTypeObjectRelation.name === currentObjectTypes[0];
    });
    setType(currentType?.PointTypeObjectRelation.id);
  }, [typeObjects, typeObjectLibraries, errorTypeObjectLibraries]);

  const { register, handleSubmit } = useForm();

  const handleSelectLibrary = (data) => {
    setCurrentLibraryType(data["Library type"]);
    const currentLibrary =
      !errorTypeObjectLibraries &&
      typeObjectLibraries?.find(
        (objectLibrary) => objectLibrary.name === data["Library type"]
      );
    const currentLibraryOptions = [];
    const currentTypeEventIds = [];
    typeObjects.forEach((elm) => {
      if (elm.PointTypeObjectRelation.pointLibraryId === currentLibrary.id) {
        currentLibraryOptions.push(elm.PointTypeObjectRelation.name);
        currentTypeEventIds.push(elm.PointTypeObjectRelation.id);
      }
    });
    setObjectType({ ...objectType, options: currentLibraryOptions });
    setType(currentTypeEventIds[0]);
    setCurrentObjectType(currentLibraryOptions[0]);
  };

  const handleSelectObjectType = (data) => {
    typeObjects.forEach((object) => {
      if (object.PointTypeObjectRelation.name === data["Object type"]) {
        setType(object.PointTypeObjectRelation.id);
        setCurrentObjectType(data["Object type"]);
      }
    });
  };

  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {objectType?.icons.map((object) => {
            return (
              object.name === currentObjectType &&
              object.library === currentLibraryType && (
                <img
                  key={object.icon}
                  className="dynamicImg"
                  src={object.icon}
                  alt={`Icon of object ${object.icon}`}
                />
              )
            );
          })}
          <h2>Objects</h2>
        </div>
      </WraperTitleCardMarker>
      <div onChange={handleSubmit(handleSelectLibrary)}>
        <SelectInput field={libraryType} register={register} />
      </div>
      <div onChange={handleSubmit(handleSelectObjectType)}>
        <SelectInput field={objectType} register={register} />
      </div>
      {type && (
        <DynamicFields
          closeModal={closeModal}
          objectId={objectId}
          feature={feature}
          typeObjectRelationId={type}
        />
      )}
    </FeatureDetails>
  );
};

export default CreateObject;
