// React
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// Components
import DynamicFields from "components/Forms/SaveComponent/DynamicFields";
import SelectInput from "components/Forms/SelectInput";
// Style
import { FeatureDetails } from "components/Forms/SaveObject/indexStyle";
import { WraperTitleCardMarker } from "components/ElementDetails/ImageDetailsElements";
// Hooks
import { useFetchComponentsLibraries } from "hooks/fetchLibraries";
import { WHITE_BACKGROUND } from "utils/const";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CreateComponent = ({ feature, objectId, closeModal, typeComponents }) => {
  const [type, setType] = useState();
  const [currentComponentType, setCurrentComponentType] = useState("");
  const [currentLibraryType, setCurrentLibraryType] = useState("");

  const [libraryType, setLibraryType] = useState({
    name: "Library type",
    options: [],
    disableOptions: [],
    id: 0,
  });
  const [componentType, setComponentType] = useState({
    name: "Type component",
    options: [],
    icons: [],
    id: 0,
  });

  const { data: typeComponentLibraries, errorTypeComponentLibraries } =
    useFetchComponentsLibraries({ id: null });

  useEffect(() => {
    const librarieIds = typeComponents?.map(
      (component) => component.pointTypeComponent.pointLibraryComponentId
    );

    var typeIcons = typeComponents?.map((elm) => {
      const library =
        !errorTypeComponentLibraries &&
        typeComponentLibraries?.find((library) => {
          return elm.pointTypeComponent.pointLibraryComponentId === library.id;
        });
      return {
        name: elm.pointTypeComponent.name,
        icon: elm.pointTypeComponent.icon,
        library: library?.name,
      };
    });

    const currentLibraryTypes = [];
    const currentDisableLibraries = [];
    const currentComponentTypes = [];

    !errorTypeComponentLibraries &&
      typeComponentLibraries?.forEach((library) => {
        if (librarieIds?.includes(library.id)) {
          currentLibraryTypes.push(library.name);
          if (library.available === false) {
            currentDisableLibraries.push(library.name);
          }
        }
      });

    typeComponents?.forEach((component) => {
      !errorTypeComponentLibraries &&
        typeComponentLibraries?.forEach((library, index) => {
          if (
            !errorTypeComponentLibraries &&
            typeComponentLibraries &&
            component.pointTypeComponent.pointLibraryComponentId === library.id
          ) {
            if (
              library.name === currentLibraryTypes[0] &&
              !currentDisableLibraries.includes(library.name)
            ) {
              currentComponentTypes.push(component.pointTypeComponent.name);
            }
          }
        });
    });
    setComponentType((prev) => ({
      ...prev,
      options: currentComponentTypes,
      icons: typeIcons,
    }));

    setLibraryType((prev) => ({
      ...prev,
      options: currentLibraryTypes,
      disableOptions: currentDisableLibraries,
    }));

    setCurrentComponentType(currentComponentTypes[0]);
    setCurrentLibraryType(currentLibraryTypes[0]);
    const currentType = typeComponents?.find((elm) => {
      return elm.pointTypeComponent.name === currentComponentTypes[0];
    });
    setType(currentType?.pointTypeComponent.id);
  }, [typeComponents, typeComponentLibraries, errorTypeComponentLibraries]);

  const { register, handleSubmit } = useForm();

  const handleSelectLibrary = (data) => {
    setCurrentLibraryType(data["Library type"]);
    const currentLibrary =
      !errorTypeComponentLibraries &&
      typeComponentLibraries?.find(
        (event) => event.name === data["Library type"]
      );
    const currentLibraryOptions = [];
    const currentTypeEventIds = [];
    typeComponents?.forEach((elm) => {
      if (
        elm.pointTypeComponent.pointLibraryComponentId === currentLibrary.id
      ) {
        currentLibraryOptions.push(elm.pointTypeComponent.name);
        currentTypeEventIds.push(elm.pointTypeComponent.id);
      }
    });
    setComponentType({ ...componentType, options: currentLibraryOptions });
    setType(currentTypeEventIds[0]);
    setCurrentComponentType(currentLibraryOptions[0]);
  };

  const handleSelectTypeEvent = (data) => {
    typeComponents.forEach((event) => {
      if (event.pointTypeComponent.name === data["Type component"]) {
        setType(event.pointTypeComponent.id);
        setCurrentComponentType(data["Type component"]);
      }
    });
  };

  return (
    <FeatureDetails style={{ background: `${WHITE_BACKGROUND}` }}>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {componentType?.icons.map((component) => {
            return (
              component.name === currentComponentType &&
              component.library === currentLibraryType && (
                <img
                  key={component.icon}
                  className="dynamicImg"
                  src={component.icon}
                  alt={`Icon of component ${component.icon}`}
                />
              )
            );
          })}
          <h2>Components</h2>
        </div>
      </WraperTitleCardMarker>
      <div onChange={handleSubmit(handleSelectLibrary)}>
        <SelectInput field={libraryType} register={register} />
      </div>
      <div onChange={handleSubmit(handleSelectTypeEvent)}>
        <SelectInput field={componentType} register={register} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <KeyboardArrowDownIcon style={{ margin: "2rem 0" }} />
      </div>
      {type && (
        <DynamicFields
          closeModal={closeModal}
          objectId={objectId}
          feature={feature}
          pointTypeComponentId={type}
        />
      )}
    </FeatureDetails>
  );
};

export default CreateComponent;
