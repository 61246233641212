import axios from "axios";
import { config } from "config.js";
import { findUserSessionByToken } from "services/sessions/find";
import deleteUserSession from "services/sessions/delete";

const createUserSession = async ({ token }) => {
  try {
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/sessions`,
      {
        token: token,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Error posting user session");
  }
};

export default createUserSession;

export const createRevoquedToken = async () => {
  try {
    const token = localStorage.getItem("token");
    const currentSession = await findUserSessionByToken();
    const idCurrentSession = currentSession.id;
    const resDeleteSession = await deleteUserSession({ id: idCurrentSession });
    if (resDeleteSession) {
      const res = await axios.post(
        `${config.URL_BACKEND_PG}api/v1/revoqued-tokens`,
        {
          token: currentSession.token,
        },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const data = await res.data;
      return data;
    } else {
      throw new Error("Error deleting user session");
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error posting revoqued token");
  }
};

export const moveTokenToRevoquedToken = async ({ token }) => {
  try {
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/revoqued-tokens`,
      {
        token: token,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Error move current token to revoqued tokens");
  }
};
