import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { config } from "config.js";
import {
  FormContainer,
  WrapperForm,
  Form,
  ContainerButtonCenter,
  ContainerButtonBottom,
  ContainerButtonRegister,
  ContainerButtonBack,
  Button,
  MessageError,
  MessageSuccess,
} from "components/Forms/FormStyles";

import InputComponent from "components/Forms/Input";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor2 } from "Styles";

const steps = [
  "User details",
  "Company and Legal Resp.",
  "Responsible details",
  "Oil Engineer details",
];

const FormSignUp = ({ handleClose }) => {
  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });
  const { theme } = useContext(ThemeContext);
  const [company, setCompany] = useState({ field: "", valid: null });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [area, setArea] = useState({ field: "", valid: null });
  const [project, setProject] = useState({ field: "", valid: null });
  const [FormValidated, setFormValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const token = localStorage.getItem("token");
  const [messageError, setMessageError] = useState("");
  const [step, setStep] = useState(1);
  const [nameCompany, setNameCompany] = useState({ field: "", valid: null });
  const [nitCompany, setNitCompany] = useState({ field: "", valid: null });
  const [typeCompany, setTypeCompany] = useState({ field: "", valid: null });
  const [composicionUtCompany, setComposicionUtCompany] = useState({
    field: "",
    valid: null,
  });
  const [firstNameCompany, setFirstNameCompany] = useState({
    field: "",
    valid: null,
  });
  const [secondNameCompany, setSecondNameCompany] = useState({
    field: "",
    valid: null,
  });
  const [lastNameCompany, setLastNameCompany] = useState({
    field: "",
    valid: null,
  });
  const [roleCompany, setRoleCompany] = useState({
    field: "",
    valid: null,
  });
  const [emailCompany, setEmailCompany] = useState({
    field: "",
    valid: null,
  });
  const [phoneCompany, setPhoneCompany] = useState({
    field: "",
    valid: null,
  });
  // Data responsible ldar
  const [firstNameResponsible, setFirstNameResponsible] = useState({
    field: "",
    valid: null,
  });
  const [secondNameResponsible, setSecondNameResponsible] = useState({
    field: "",
    valid: null,
  });
  const [lastNameResponsible, setLastNameResponsible] = useState({
    field: "",
    valid: null,
  });
  const [roleResponsible, setRoleResponsible] = useState({
    field: "",
    valid: null,
  });
  const [emailResponsible, setEmailResponsible] = useState({
    field: "",
    valid: null,
  });
  const [phoneResponsible, setPhoneResponsible] = useState({
    field: "",
    valid: null,
  });
  // Data oil engineer
  const [firstNameOilEngineer, setFirstNameOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [secondNameOilEngineer, setSecondNameOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [lastNameOilEngineer, setLastNameOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [roleOilEngineer, setRoleOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [emailOilEngineer, setEmailOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [phoneOilEngineer, setPhoneOilEngineer] = useState({
    field: "",
    valid: null,
  });

  const expressions = {
    firstName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    firstLastName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    secondName: /^[a-zA-ZÀ-ÿ]{0,40}$/, // letters only
    company: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    area: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    project: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    password: /^.{8,60}$/, // 8 a 60 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    nameCompany: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    nitCompany: /^[0-9]+$/,
    typeCompany: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    composicionUtCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    firstNameCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    secondNameCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    lastNameCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    roleCompany: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    emailCompany: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    phoneCompany: /^[0-9]+$/,
    firstNameResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    secondNameResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    lastNameResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    roleResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    emailResponsible: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    phoneResponsible: /^[0-9]+$/,
  };

  const saveUser = async (body) => {
    try {
      const res = await fetch(`${config.URL_BACKEND_PG}api/v1/admin-company`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify(body),
      });
      await res.json();
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //check the request fields filled

    //array to store the names of the fields that are not filled

    const missingFields = [];

    //check if the fields are filled
    if (!firstName.field) {
      missingFields.push("First Name");
    }
    if (!firstLastName.field) {
      missingFields.push("Last Name");
    }
    if (!company.field) {
      missingFields.push("Company");
    }

    if (!email.field) {
      missingFields.push("Email");
    }

    if (!area.field) {
      missingFields.push("Area");
    }
    if (!project.field) {
      missingFields.push("Project");
    }

    //required fields for company
    if (!nameCompany.field) {
      missingFields.push("Company Name");
    }

    if (!nitCompany.field) {
      missingFields.push("Nit");
    }

    if (!typeCompany.field) {
      missingFields.push("Company Type");
    }

    if (!composicionUtCompany.field) {
      missingFields.push("Composition");
    }

    if (!firstNameCompany.field) {
      missingFields.push("First Name Legal Resp.");
    }

    if (!lastNameCompany.field) {
      missingFields.push("Last Name Legal Resp.");
    }

    if (!roleCompany.field) {
      missingFields.push("Role Legal Resp.");
    }

    if (!emailCompany.field) {
      missingFields.push("Email Legal Resp.");
    }

    if (!phoneCompany.field) {
      missingFields.push("Phone Legal Resp.");
    }

    //required fields for responsible ldar

    if (!firstNameResponsible.field) {
      missingFields.push("First Name Responsible.");
    }
    if (!lastNameResponsible.field) {
      missingFields.push("Last Name Responsible.");
    }

    if (!roleResponsible.field) {
      missingFields.push("Role Responsible.");
    }

    if (!emailResponsible.field) {
      missingFields.push("Email Responsible.");
    }

    if (!phoneResponsible.field) {
      missingFields.push("Phone Responsible.");
    }

    //required fields for oil engineer

    if (!firstNameOilEngineer.field) {
      missingFields.push("First Name Oil Engineer");
    }
    if (!lastNameOilEngineer.field) {
      missingFields.push("Last Name Oil Engineer");
    }

    if (!roleOilEngineer.field) {
      missingFields.push("Role Oil Engineer");
    }

    if (!emailOilEngineer.field) {
      missingFields.push("Email Oil Engineer");
    }

    if (!phoneOilEngineer.field) {
      missingFields.push("Phone Oil Engineer");
    }

    //build the message with the missing fields

    let errorMessage = "";
    if (missingFields.length > 0) {
      errorMessage = `THE FOLLOWING FIELDS ARE REQUIRED: ${missingFields.join(
        " - "
      )}`;
    }

    //update messageError
    setMessageError(errorMessage);

    if (errorMessage) {
      setFormValidated(false);
      setLoading(false);
      return;
    }

    if (
      firstName.valid === "true" &&
      firstLastName.valid === "true" &&
      company.valid === "true" &&
      email.valid === "true" &&
      area.valid === "true" &&
      project.valid === "true"
    ) {
      const body = {
        firstName: firstName.field,
        secondName: secondName.field,
        firstLastName: firstLastName.field,
        area: area.field,
        project: project.field,
        adminDecimetrixId: adminDecimetrixId,
        user: {
          email: email.field,
        },
        company: {
          name: nameCompany.field,
          nit: nitCompany.field,
          type: typeCompany.field,
          composicionUt: composicionUtCompany.field,
          legalRepresentative: {
            firstName: firstNameCompany.field,
            secondName: secondNameCompany.field,
            lastName: lastNameCompany.field,
            role: roleCompany.field,
            email: emailCompany.field,
            phone: phoneCompany.field,
          },
          responsibleLdar: {
            firstName: firstNameResponsible.field,
            secondName: secondNameResponsible.field,
            lastName: lastNameResponsible.field,
            role: roleResponsible.field,
            email: emailResponsible.field,
            phone: phoneResponsible.field,
          },
          oilEngineer: {
            firstName: firstNameOilEngineer.field,
            secondName: secondNameOilEngineer.field,
            lastName: lastNameOilEngineer.field,
            role: roleOilEngineer.field,
            email: emailOilEngineer.field,
            phone: phoneOilEngineer.field,
          },
        },
      };

      const res = await saveUser(body);
      setLoading(false);
      if (res.status === 409) {
        setFormValidated(false);
        setMessageError("This email is already registered");
      } else if (res.status === 201) {
        handleClose();
        setFormValidated(true);
        setMessageError(null);
        setFirstName({ field: "", valid: null });
        setSecondName({ field: "", valid: null });
        setFirstLastName({ field: "", valid: null });
        setEmail({ field: "", valid: null });
        setArea({ field: "", valid: null });
        setProject({ field: "", valid: null });

        setNameCompany({ field: "", valid: null });
        setNitCompany({ field: "", valid: null });
        setTypeCompany({ field: "", valid: null });
        setComposicionUtCompany({ field: "", valid: null });

        setFirstNameCompany({ field: "", valid: null });
        setSecondNameCompany({ field: "", valid: null });
        setLastNameCompany({ field: "", valid: null });
        setRoleCompany({ field: "", valid: null });

        setEmailCompany({ field: "", valid: null });
        setPhoneCompany({ field: "", valid: null });
        setFirstNameResponsible({ field: "", valid: null });
        setSecondNameResponsible({ field: "", valid: null });

        setLastNameResponsible({ field: "", valid: null });
        setRoleResponsible({ field: "", valid: null });
        setEmailResponsible({ field: "", valid: null });
        setPhoneResponsible({ field: "", valid: null });

        setFirstNameOilEngineer({ field: "", valid: null });
        setSecondNameOilEngineer({ field: "", valid: null });
        setLastNameOilEngineer({ field: "", valid: null });
        setRoleOilEngineer({ field: "", valid: null });
        setEmailOilEngineer({ field: "", valid: null });
        setPhoneOilEngineer({ field: "", valid: null });
      } else {
        setFormValidated(false);
        setMessageError("Filld all the fields correctly.");
      }
    } else {
      setFormValidated(false);

      setMessageError("Filld all the fields correctly.2"); ///
    }
    setLoading(false);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };
  //que las box sean responsive
  return (
    <>
      <br></br>
      <Box sx={{ width: "100%" }}>
        <Stepper
          style={{ color: "red" }}
          activeStep={step - 1}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step style={{ color: "red" }} key={label}>
              <StepLabel
                sx={{
                  "& .css-1u6qyme-MuiStepLabel-label.Mui-active": {
                    color: StyleColor2(theme),
                  },
                  "& .css-1vyamtt-MuiStepLabel-labelContainer": {
                    color: StyleColor2(theme),
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormContainer>
        <WrapperForm style={{ marginTop: "1rem" }}>
          <Form action="" onSubmit={onSubmit}>
            {step === 1 ? (
              <>
                <>
                  <InputComponent
                    state={firstName}
                    changeState={setFirstName}
                    type="text"
                    label="First Name *"
                    name="firstName"
                    legendError="The name can only contain letters."
                    regularExpression={expressions.firstName}
                  />
                  <InputComponent
                    state={secondName}
                    changeState={setSecondName}
                    type="text"
                    label="Second Name"
                    name="secondName"
                    legendError="The name can only contain letters."
                    regularExpression={expressions.secondName}
                  />
                  <InputComponent
                    state={firstLastName}
                    changeState={setFirstLastName}
                    type="text"
                    label="Last Name *"
                    name="firstLastName"
                    legendError="The last name can only contain letters."
                    regularExpression={expressions.firstLastName}
                  />
                  <InputComponent
                    state={company}
                    changeState={setCompany}
                    type="text"
                    label="Company *"
                    name="company"
                    legendError="The company can only contain letters, numbers, periods, hyphens and underscores."
                    regularExpression={expressions.company}
                  />
                  <InputComponent
                    state={email}
                    changeState={setEmail}
                    type="email"
                    label="E-mail *"
                    placeholder="email@email.com"
                    name="email"
                    legendError="Email can only contain letters, numbers, periods, hyphens, and underscores."
                    regularExpression={expressions.email}
                  />
                  <InputComponent
                    state={area}
                    changeState={setArea}
                    type="text"
                    label="Area *"
                    placeholder=""
                    name="area"
                    legendError="The area can only contain letters."
                    regularExpression={expressions.area}
                  />
                  <InputComponent
                    state={project}
                    changeState={setProject}
                    type="project"
                    label="Project *"
                    placeholder=""
                    name="project"
                    legendError="The project can only contain letters."
                    regularExpression={expressions.project}
                  />
                </>
              </>
            ) : step === 2 ? (
              <>
                <InputComponent
                  state={nameCompany}
                  changeState={setNameCompany}
                  type="text"
                  label="Company Name *"
                  name="nameCompany"
                  legendError="The company name can only contain letters."
                  regularExpression={expressions.nameCompany}
                />
                <InputComponent
                  state={nitCompany}
                  changeState={setNitCompany}
                  type="text"
                  label="Nit *"
                  name="nitCompany"
                  legendError="The nit can only contain numbers."
                  regularExpression={expressions.nitCompany}
                />
                <InputComponent
                  state={typeCompany}
                  changeState={setTypeCompany}
                  type="text"
                  label="Company Type *"
                  name="typeCompany"
                  legendError="The company type can only contain letters."
                  regularExpression={expressions.typeCompany}
                />
                <InputComponent
                  state={composicionUtCompany}
                  changeState={setComposicionUtCompany}
                  type="text"
                  label="Composition *"
                  name="composicionUtCompany"
                  legendError="The composition can only contain letters."
                  regularExpression={expressions.composicionUtCompany}
                />
                <InputComponent
                  state={firstNameCompany}
                  changeState={setFirstNameCompany}
                  type="text"
                  label="First Name Legal Rep *"
                  name="firstNameCompany"
                  legendError="The first name can only contain letters."
                  regularExpression={expressions.firstNameCompany}
                />
                <InputComponent
                  state={secondNameCompany}
                  changeState={setSecondNameCompany}
                  type="text"
                  label="Second Name Legal Rep"
                  name="secondNameCompany"
                  legendError="The second name can only contain letters."
                  regularExpression={expressions.secondNameCompany}
                />
                <InputComponent
                  state={lastNameCompany}
                  changeState={setLastNameCompany}
                  type="text"
                  label="Last Name Legal Rep *"
                  name="lastNameCompany"
                  legendError="The last name can only contain letters."
                  regularExpression={expressions.lastNameCompany}
                />
                <InputComponent
                  state={roleCompany}
                  changeState={setRoleCompany}
                  type="text"
                  label="Role Legal Rep *"
                  name="roleCompany"
                  legendError="The role can only contain letters."
                  regularExpression={expressions.roleCompany}
                />
                <InputComponent
                  state={emailCompany}
                  changeState={setEmailCompany}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="emailCompany"
                  legendError="The email can only contain letters, numbers, periods, hyphens and underscores."
                  regularExpression={expressions.emailCompany}
                />
                <InputComponent
                  state={phoneCompany}
                  changeState={setPhoneCompany}
                  type="tel"
                  label="Phone *"
                  placeholder=""
                  name="phoneCompany"
                  legendError="The phone can only contain numbers."
                  regularExpression={expressions.phoneCompany}
                />
              </>
            ) : step === 3 ? (
              <>
                <InputComponent
                  state={firstNameResponsible}
                  changeState={setFirstNameResponsible}
                  type="text"
                  label="First Name *"
                  name="firstNameResponsible"
                  legendError="The first name can only contain letters."
                  regularExpression={expressions.firstNameResponsible}
                />
                <InputComponent
                  state={secondNameResponsible}
                  changeState={setSecondNameResponsible}
                  type="text"
                  label="Second Name "
                  name="secondNameResponsible"
                  legendError="The second name can only contain letters."
                  regularExpression={expressions.secondNameResponsible}
                />
                <InputComponent
                  state={lastNameResponsible}
                  changeState={setLastNameResponsible}
                  type="text"
                  label="Last Name *"
                  name="lastNameResponsible"
                  legendError="The last name can only contain letters."
                  regularExpression={expressions.lastNameResponsible}
                />
                <InputComponent
                  state={roleResponsible}
                  changeState={setRoleResponsible}
                  type="text"
                  label="Role *"
                  name="roleResponsible"
                  legendError="The role can only contain letters."
                  regularExpression={expressions.roleResponsible}
                />
                <InputComponent
                  state={emailResponsible}
                  changeState={setEmailResponsible}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="emailResponsible"
                  legendError="The email can only contain letters, numbers, periods, hyphens and underscores.."
                  regularExpression={expressions.emailResponsible}
                />
                <InputComponent
                  state={phoneResponsible}
                  changeState={setPhoneResponsible}
                  type="tel"
                  label="Phone *"
                  name="phoneResponsible"
                  legendError="The phone can only contain numbers."
                  regularExpression={expressions.phoneResponsible}
                />
              </>
            ) : step === 4 ? (
              <>
                <InputComponent
                  state={firstNameOilEngineer}
                  changeState={setFirstNameOilEngineer}
                  type="text"
                  label="First Name *"
                  name="firstNameOilEngineer"
                  legendError="The first name can only contain letters."
                  regularExpression={expressions.firstNameResponsible}
                />
                <InputComponent
                  state={secondNameOilEngineer}
                  changeState={setSecondNameOilEngineer}
                  type="text"
                  label="Second Name "
                  name="secondNameOilEngineer"
                  legendError="The second name can only contain letters."
                  regularExpression={expressions.secondNameOilEngineer}
                />
                <InputComponent
                  state={lastNameOilEngineer}
                  changeState={setLastNameOilEngineer}
                  type="text"
                  label="Last Name *"
                  name="lastNameOilEngineer"
                  legendError="The last name can only contain letters."
                  regularExpression={expressions.lastNameResponsible}
                />
                <InputComponent
                  state={roleOilEngineer}
                  changeState={setRoleOilEngineer}
                  type="text"
                  label="Role *"
                  name="roleOilEngineer"
                  legendError="The role can only contain letters."
                  regularExpression={expressions.roleResponsible}
                />
                <InputComponent
                  state={emailOilEngineer}
                  changeState={setEmailOilEngineer}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="emailOilEngineer"
                  legendError="The email can only contain letters, numbers, periods, hyphens and underscores.."
                  regularExpression={expressions.emailResponsible}
                />
                <InputComponent
                  state={phoneOilEngineer}
                  changeState={setPhoneOilEngineer}
                  type="tel"
                  label="Phone *"
                  name="phoneOilEngineer"
                  legendError="The phone can only contain numbers."
                  regularExpression={expressions.phoneResponsible}
                />
              </>
            ) : null}
          </Form>
          {step === 4 && (
            <>
              <ContainerButtonBack>
                <Button onClick={prevStep}>Back</Button>
              </ContainerButtonBack>
              <ContainerButtonRegister>
                <Button type="submit" onClick={onSubmit}>
                  Register
                </Button>
                {loading && (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}
              </ContainerButtonRegister>
            </>
          )}
          {step === 3 && (
            <>
              <ContainerButtonBack>
                <Button onClick={prevStep}>Back</Button>
              </ContainerButtonBack>
              <ContainerButtonRegister>
                <Button onClick={nextStep}>Continue</Button>
              </ContainerButtonRegister>
            </>
          )}
          {step === 2 ? (
            <>
              <ContainerButtonCenter>
                <Button onClick={prevStep}>Back</Button>
              </ContainerButtonCenter>
              <ContainerButtonBottom>
                <Button onClick={nextStep}>Continue</Button>
              </ContainerButtonBottom>
            </>
          ) : step === 1 ? (
            <>
              <ContainerButtonBottom>
                <Button onClick={nextStep}>Continue</Button>
              </ContainerButtonBottom>
            </>
          ) : null}
          {FormValidated ? (
            <MessageSuccess>
              <p>User saved successfully</p>
            </MessageSuccess>
          ) : messageError !== "" ? (
            <MessageError>
              <p>{messageError}</p>
            </MessageError>
          ) : null}
        </WrapperForm>
      </FormContainer>
    </>
  );
};

export default FormSignUp;
