import Layout from "components/Layout/AdminDecimetrix";
import FlareEffiency from "components/FlareEfficiency";
import { Container, PrincipalConteiner } from "./FlareEfficiencyStyle";

const index = () => {
  return (
    <PrincipalConteiner>
      <Layout>
        <Container className="background-2">
          <section style={{ marginBottom: "20px" }} className="logo-decimetrix">
            <img
              src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/img_footer.png"
              alt=""
            />
          </section>
          <FlareEffiency />
        </Container>
      </Layout>
    </PrincipalConteiner>
  );
};

export default index;
