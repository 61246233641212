import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_PARAMS,
  RESET_STATES,
} from "../actions/types";

const userLocalStorage = { token: localStorage.getItem("token") };

const initialState = {
  setCompanyId: localStorage.adminCompanyId,
  user: !userLocalStorage.token ? {} : userLocalStorage,
  userParams: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_CURRENT_USER_PARAMS:
      return {
        ...state,
        userParams: action.payload,
      };
    case RESET_STATES:
      return {
        ...initialState,
        user: {},
      };
    default:
      return state;
  }
};

export default userReducer;
