import { useEffect, useState } from "react";
// Styles
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
// Hooks
import useFetchPointTypeEvent from "hooks/useFetchPointTypeEvents";
// Dependecies
import { useSWRConfig } from "swr";
// Config
import { config } from "config.js";
import { SearchPointType } from "../FieldsStyle";
import { normalizeString } from "helpers/normalizeString";

/**
 * Renders an accordion component with a list of types and events.
 *
 * @param {Object} library - The library object containing name and id.
 * @param {Object} typeEventsTypeEvents - The object containing type events.
 * @param {Function} handleChangeCheckEvent - The function to handle check event changes.
 * @return {JSX.Element} The rendered accordion component.
 */
const AccordionRenderTypesEvents = ({
  library,
  typeEventsTypeEvents,
  handleChangeCheckEvent,
  seeForm,
}) => {
  const { mutate } = useSWRConfig();
  const { name, id } = library;
  const { data: types } = useFetchPointTypeEvent({
    libraryId: parseInt(id),
  });
  const [check, setCheck] = useState(false);
  const [organizeTypes, setOrganizeTypes] = useState([]);
  const [valueInput, setValueInput] = useState("");

  useEffect(() => {
    if (!types || types.error) return;
    setOrganizeTypes(types.sort((a, b) => a.name.localeCompare(b.name)));
  }, [types]);

  useEffect(() => {
    mutate(`${config.URL_BACKEND_PG}api/v1/point-type-events?libraryId=${id}`);
  }, [seeForm]);

  useEffect(() => {
    const filterData =
      types &&
      !types.error &&
      types.length > 0 &&
      types
        .filter((elm) => {
          const normalizedInput = normalizeString(valueInput);
          const normalizedName = normalizeString(elm.name);

          return normalizedName.includes(normalizedInput);
        })
        .sort((a, b) => a.name.localeCompare(b.name));

    setOrganizeTypes(filterData);
  }, [valueInput]);

  useEffect(() => {
    if (!types || types.error) return;
    types.every((type) => {
      const element = typeEventsTypeEvents?.[type?.id];
      if (element) return setCheck(true);
      return setCheck(false);
    });
  }, [types, typeEventsTypeEvents]);

  const handleChangeCheckLibrary = () => {
    const thisCheck = check;
    if (thisCheck) {
      organizeTypes.forEach((type) => {
        const element = typeEventsTypeEvents?.[type?.id];
        if (element) {
          handleChangeCheckEvent(type.id);
        }
      });
      return;
    }
    organizeTypes.forEach((type) => {
      const element = typeEventsTypeEvents?.[type?.id];
      if (!element) {
        handleChangeCheckEvent(type.id);
      }
    });
  };

  const handleSerch = (e) => {
    setValueInput(e.target.value);
  };

  return (
    <Accordion className="background-row-1 color1 border-color-1 no-shadow">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="color1" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <FormControlLabel
            sx={{
              "& .css-119cnn6-MuiTypography-root": {
                fontFamily: document.body.style.fontFamily,
                fontWeight: "bold",
                color: document.body.style.color,
              },
            }}
            control={
              <Checkbox checked={check} onChange={handleChangeCheckLibrary} />
            }
            label={name}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ mt: "-20px" }}>
        {types && !types.error && types.length >= 5 && (
          <SearchPointType
            type="text"
            onChange={handleSerch}
            value={valueInput}
            placeholder="Search point type..."
          />
        )}
        {types && !types.error && types.length < 5 && <hr />}
        <FormGroup>
          {organizeTypes &&
            !organizeTypes.error &&
            organizeTypes.length > 0 &&
            organizeTypes.map((type, key) => {
              const element = typeEventsTypeEvents?.[type?.id];
              let check = false;
              if (element) check = true;
              return (
                <FormControlLabel
                  sx={{
                    "& .css-119cnn6-MuiTypography-root": {
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                    },
                  }}
                  key={key}
                  control={
                    <Checkbox
                      checked={check}
                      onChange={() => handleChangeCheckEvent(type.id)}
                    />
                  }
                  label={type.name}
                />
              );
            })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionRenderTypesEvents;
