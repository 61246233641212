import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilterObject } from "redux/actions/admin";

export default function useFilteredObjects(objects) {
  const dispatch = useDispatch();

  const showMarkerByElementWithMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );

  const showTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );

  const stateFilterObject = useSelector(
    (state) => state.adminReducer.stateFilterObject
  );

  const filterObjects = showTypeElement?.filter((elm) => {
    return elm.state === true;
  });

  useEffect(() => {
    dispatch(setFilterObject(false));
  }, [
    stateFilterObject,
    objects,
    dispatch,
    showMarkerByElementWithMedia,
    showTypeElement,
  ]);

  const filteredObjects = objects?.filter((object) => {
    const show =
      (showMarkerByElementWithMedia && !object.haveMedia) ||
      !showMarkerByElementWithMedia;

    return filterObjects?.some((componentFilter) => {
      return (
        object.typeElement.id === componentFilter.id &&
        object.typeElement.pointLibraryId === componentFilter.libraryId &&
        componentFilter.state === true &&
        show
      );
    });
  });

  return filteredObjects;
}
