import useStoreDashboard from "pages/Dashboard/Store";
import {
  ButtonContainer,
  OptionsContainer,
  PreviewContainer,
  PrintOptionsContainer,
  Input,
} from "./pdfPrintDashboardSettingsStyles";
import { useEffect, useState } from "react";
import { PRIMARY_COLOR, WHITE_SECOND_COLOR } from "utils/const";
// import useGetTemplates from "./hooks/getTemplates";
import PDFCreator from "pages/Dashboard/pdfCreator/hooks/PDFCreator";
import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  Text,
} from "@react-pdf/renderer";
import { CircularProgress } from "@mui/material";
import html2canvas from "html2canvas";
import OpenDialog from "components/Dialogs/OpenDialog";
import { waitSeconds } from "helpers/waitSeconds";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import NoteIcon from "@mui/icons-material/Note";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PrintIcon from "@mui/icons-material/Print";

function PDFPrintDashboardSettings({
  setOpenModalPrintPDF,
  currentOrderViews,
  currentTemplate,
}) {
  const [pdfDocument, setPdfDocument] = useState(null);
  const [dashboardImages, setDashboardImages] = useState([]);
  const [pages, setPages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileName, setFileName] = useState(
    `dashboard-report_${new Date().toDateString().toString()}`
  );
  const [options, setOptions] = useState({
    landscape: true,
    portrait: false,
    orientationRows: 7,
  });

  const { dashboardRef, setChartsIconsShowOnOff } = useStoreDashboard();

  const totalGaps = (gap, rows) => gap * rows;
  // GRID
  const ulElementRef = document.getElementById("chart-dashboard-element-array");
  const ulStyles = window.getComputedStyle(ulElementRef);

  const handleChangeOption = (opcn) => {
    if (!opcn || !opcn.length) return;
    if (opcn === "landscape") {
      setOptions((current) => {
        return {
          ...current,
          portrait: options.portrait && !options.landscape ? false : true,
          orientationRows: 7,
          [opcn]: !current[opcn],
        };
      });
      return;
    }
    if (opcn === "portrait") {
      setOptions((current) => {
        return {
          ...current,
          landscape: options.landscape && !options.portrait ? false : true,
          orientationRows: 10,
          [opcn]: !current[opcn],
        };
      });
      return;
    }
    setOptions((current) => {
      return {
        ...current,
        [opcn]: !current[opcn],
      };
    });
  };

  // COLUMNS
  const gridColumns = ulStyles
    .getPropertyValue("grid-template-columns")
    .split(" ").length;
  const gridColumnGap = parseInt(
    ulStyles.getPropertyValue("grid-column-gap").match(/\d+/)
  );
  const columnSizes = ulStyles.getPropertyValue("grid-template-columns");
  const columnSize = parseInt(columnSizes.split(" ")?.[0].match(/\d+/));
  const sectionWidth =
    columnSize * gridColumns + totalGaps(gridColumnGap, gridColumns);
  // ROWS
  const gridRows = ulStyles
    .getPropertyValue("grid-template-rows")
    .split(" ").length;
  // Obtener el valor de gap
  const gridRowGap = parseInt(
    ulStyles.getPropertyValue("grid-row-gap").match(/\d+/)
  );
  // row height
  const rowSizes = ulStyles.getPropertyValue("grid-template-rows");
  const rowSize = parseInt(rowSizes.split(" ")?.[0].match(/\d+/));
  // display guidelines (true)
  const sectionHeight =
    rowSize * options.orientationRows +
    totalGaps(gridRowGap, options.orientationRows);

  const totalSections = (() => {
    const basic = gridRows / options.orientationRows;
    return basic > parseInt(basic) ? parseInt(basic) + 1 : parseInt(basic);
  })();

  // return array of Pages needed to build pdf Document
  useEffect(() => {
    const getPages = (imgs) => {
      return imgs.map((img) => {
        return (
          <Page
            orientation={options.portrait ? "portrait" : "landscape"}
            style={{
              width: "100%",
              height: "100%",
              padding: "10px 10px",
            }}
            wrap={false}
          >
            <Image
              src={img}
              style={{
                maxHeight: sectionHeight,
              }}
            />
          </Page>
        );
      });
    };
    dashboardImages.length && setPages(getPages(dashboardImages));
  }, [dashboardImages, options.portrait, sectionHeight]);

  // use effect for setting up download button
  useEffect(() => {
    const getDocument = (pages) => {
      const document = !pages ? (
        <Document author="Decimetrix®️">
          <Page>
            <Text>No Content</Text>
          </Page>
        </Document>
      ) : (
        // Generates de document with all the pages
        <Document author="Decimetrix®️">{pages?.map((page) => page)}</Document>
      );
      return document;
    };
    pages && setPdfDocument(getDocument(pages));
  }, [pages]);

  // Use effect for getting images from all the charts on dashboard
  // useEffect(() => {
  // get all charts event without the Canva element (text, kpi, img)
  // const chartElements = Array.from(
  //   dashboardRef?.current?.getElementsByClassName(
  //     "chart-dashboard-element-array"
  //   )
  // );
  // // function to parse every element with or within a canvas to image
  // (async () => {
  //   const chartsImages = Promise.all(
  //     chartElements.map((item) => {
  //       if (item.nodeName === "CANVAS") {
  //         return Promise.resolve(item.toDataURL("image/jpeg", 1.0));
  //       }
  //       if (item.firstChild && item.firstChild.nodeName === "IMG") {
  //         return Promise.resolve(item.firstChild.src);
  //       }
  //       return html2canvas(item).then((canvas) => {
  //         const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //         return imgData;
  //       });
  //     })
  //   );
  //   const base64Images = await chartsImages;
  //   base64Images.length && setChartImages(base64Images);
  // })();

  //
  // }, [dashboardRef, options, dashboardImages]);

  // useEffect for generating array of base64 string Images
  useEffect(() => {
    const generate = async () => {
      let nextYpoint = 20;
      const aux = [];
      setDashboardImages([]);
      for (let i = 0; i < totalSections; i++) {
        await html2canvas(ulElementRef, {
          scrollY: 0,
          height: sectionHeight,
          width: sectionWidth,
          x: 0,
          y: nextYpoint,
          allowTaint: true,
          useCORS: true,
          logging: true,
        }).then((canvas) => {
          // Convertir el canvas a una imagen
          const imgData = canvas.toDataURL("image/jpeg", 1.0);
          aux.push(imgData);
        });
        // mover el siguiente tramo vertical
        nextYpoint += sectionHeight;
      }
      setDashboardImages(aux);
    };

    dashboardRef.current && generate();
  }, [dashboardRef, sectionHeight, sectionWidth, totalSections, ulElementRef]);

  return (
    <PrintOptionsContainer>
      <PreviewContainer>
        {/* PDF preview */}
        <div className="pdf-preview-container">
          <PDFCreator pages={pages} downloadButton={false} />
        </div>
      </PreviewContainer>

      <OptionsContainer>
        {/* page config options  */}
        <div className="options-section">
          <span>
            <h3>Options</h3>
          </span>

          <div className="option-div">
            <div className="option-content"
              style={{
                borderBottom: "1px solid black"
              }}>
              <span>Paper type</span>
              <div>
                A4
              </div>
            </div>
            <SimpleToolTip title={"Landscape format"} placement={"right"}>
              <div className="option-content">
                <div className="option-name">
                  <NoteIcon
                    sx={{
                      fontSize: "1.25",
                    }}
                  />
                  Landscape
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="landscapecheckbox"
                    id="landscapecheckbox"
                    value={options.landscape}
                    checked={options.landscape}
                    onChange={() => handleChangeOption("landscape")}
                  />
                </div>
              </div>
            </SimpleToolTip>

            <SimpleToolTip title={"Portrait format"} placement={"right"}>
              <div className="option-content">
                <div className="option-name">
                  <InsertDriveFileIcon
                    sx={{
                      fontSize: "1.25",
                    }}
                  />
                  Portrait
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="portraitcheckbox"
                    id="portraitcheckbox"
                    value={options.portrait}
                    checked={options.portrait}
                    onChange={() => handleChangeOption("portrait")}
                  />
                </div>
              </div>
            </SimpleToolTip>
          </div>
        </div>

        {/* button container */}
        <ButtonContainer>
          <div
            className="report-download"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <span>
              <PrintIcon
                sx={{
                  fontSize: "1.25",
                }}
              />
              Print report
            </span>
            {openDialog && (
              <OpenDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                minWidth="500px"
                content={{
                  title: "Report name",
                  description: (
                    <div
                      className="filename-report"
                      style={{
                        width: "500px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <h3>name</h3>
                        <Input
                          type="text"
                          name="reportname"
                          value={fileName}
                          onChange={(e) => {
                            setFileName(e.target.value);
                          }}
                        />
                      </span>
                      <PDFDownloadLink
                        document={pdfDocument}
                        fileName={fileName}
                        style={{
                          backgroundColor: PRIMARY_COLOR,
                          minWidth: "200px",
                          height: "auto",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          textAlign: "center",
                          textDecoration: "none",
                          color: WHITE_SECOND_COLOR,
                        }}
                        onClick={async () => {
                          await waitSeconds(1);
                          setChartsIconsShowOnOff(true);
                          setOpenDialog(false);
                          setOpenModalPrintPDF(false);
                        }}
                      >
                        {({ blob, url, loading, error }) => {
                          return loading ? (
                            <CircularProgress
                              style={{
                                width: "20px",
                                height: "20px",
                                color: WHITE_SECOND_COLOR,
                                marginRight: "5px",
                              }}
                            />
                          ) : (
                            "Print"
                          );
                        }}
                      </PDFDownloadLink>
                    </div>
                  ),
                }}
              />
            )}
          </div>
        </ButtonContainer>
      </OptionsContainer>
    </PrintOptionsContainer>
  );
}
export default PDFPrintDashboardSettings;
