export function getMonthAbbreviation(monthNumber) {
  // Asegurarse de que el input sea una cadena de dos dígitos
  const paddedMonth = monthNumber?.toString().padStart(2, "0");

  const monthAbbreviations = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  return monthAbbreviations[paddedMonth] || "Invalid month";
}
