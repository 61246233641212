import { useMemo, useState, useEffect } from "react";
import useSwr from "swr";
import { config } from "config.js";

const useFindAllEvents = () => {
  const [loading, setLoading] = useState(true);

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // Fetch events objects
  const urlEvents = `${config.URL_BACKEND_PG}api/v1/point-events?adminCompanyId=${adminCompanyId}`;
  const { data: allDataEvents, error: errorEvents } = useSwr(urlEvents);

  const eventsObjects = allDataEvents && !errorEvents ? allDataEvents : null;

  // Fetch component events
  const urlComponentEvents = `${config.URL_BACKEND_PG}api/v1/point-events/components?adminCompanyId=${adminCompanyId}`;
  const { data: dataComponentEvents, error: errorComponentEvents } =
    useSwr(urlComponentEvents);
  const eventsComponents =
    dataComponentEvents && !errorComponentEvents ? dataComponentEvents : null;

  // Fetch events events
  const urlEventsRelation = `${config.URL_BACKEND_PG}api/v1/point-events/relation?adminCompanyId=${adminCompanyId}`;
  const { data: dataEventsRelation, error: errorEventsRelation } =
    useSwr(urlEventsRelation);
  const eventsEvents =
    dataEventsRelation && !errorEventsRelation ? dataEventsRelation : null;

  const events = useMemo(() => {
    if (eventsObjects && eventsComponents && eventsEvents)
      return [...eventsObjects, ...eventsComponents, ...eventsEvents];
    return null;
  }, [eventsObjects, eventsComponents, eventsEvents]);

  // Utiliza useEffect para cambiar el estado de carga una vez que los datos se han cargado
  useEffect(() => {
    if (eventsObjects && eventsComponents && eventsEvents) {
      setLoading(false);
    }
  }, [eventsObjects, eventsComponents, eventsEvents]);

  return { events, loading };
};

export default useFindAllEvents;
