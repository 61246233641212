import { getNumericValue } from "helpers/getNumericValue";

function filterComponentsByLibraryId(components, libraryId) {
  return components.filter(
    (cmp) => cmp.pointTypeComponent.pointLibraryComponentId === libraryId
  );
}

function processDatasets(filteredComponents, chart, groupedLabels) {
  return chart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: null }));

    filteredComponents.forEach((cmp) => {
      const typeComponent = cmp.attributes.find(
        (attr) => attr.name === chart.categories.name
      )?.value;
      if (typeComponent !== dataset.donutStyle.label) return;

      const value = cmp.attributes.find(
        (attr) => attr.name === chart.columnX.name
      )?.value;
      const yValue = cmp.attributes.find(
        (attr) => attr.name === chart.columnY
      )?.value;

      const numericValue = getNumericValue(yValue);

      const index = dataByLabel.findIndex((item) => item.label === value);
      if (index !== -1) {
        if (
          dataByLabel[index].value === null ||
          isNaN(dataByLabel[index].value)
        ) {
          dataByLabel[index].value = numericValue;
        } else {
          dataByLabel[index].value += numericValue;
        }
      }
    });

    return {
      ...dataset,
      data: dataByLabel.map((item) => item.value),
    };
  });
}

// Función principal para procesar datos
export function sumValues({ components, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const filteredComponents = filterComponentsByLibraryId(
      components,
      chart.libraryId
    );
    const datasets = processDatasets(
      filteredComponents,
      chart,
      chart.columnX.values
    );

    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}

// Función para procesar datasets contando ocurrencias
function processCountDatasets(
  filteredComponents,
  timeSeriesChart,
  groupedLabels
) {
  return timeSeriesChart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: 0 }));

    filteredComponents.forEach((cmp) => {
      const typeComponent = cmp.attributes.find(
        (attr) => attr.name === timeSeriesChart.categories.name
      )?.value;
      if (typeComponent !== dataset.donutStyle.label) return;

      const dataLabel = cmp.attributes.find(
        (attr) => attr.name === timeSeriesChart.columnX.name
      )?.value;

      const index = dataByLabel.findIndex((item) => item.label === dataLabel);
      if (index !== -1) {
        dataByLabel[index].value += 1;
      }
    });

    return {
      ...dataset,
      data: dataByLabel.map((item) => item.value),
    };
  });
}

// Función principal para procesar datos con conteo
export function countByValues({ components, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const filteredComponents = filterComponentsByLibraryId(
      components,
      chart.libraryId
    );
    const datasets = processCountDatasets(
      filteredComponents,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}
