import React, { useState } from "react";
import { IoIosHelpCircle } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  ContainerBanner,
  ContainerBlock,
  ContainerElement,
  DecimButton,
  ModalContainer,
  ModalContent,
  Overlay,
} from "./ModalHelpButtonStyle"; // Asegúrate de ajustar la importación de tus estilos
import { INFORMATION_COLOR, WHITE_COLOR } from "utils/const";

const CustomModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <IoIosHelpCircle
        onClick={openModal}
        style={{
          cursor: "pointer",
          color: INFORMATION_COLOR,
          fontSize: "4.5rem",
        }}
      />
      {isModalOpen && (
        <ModalContainer>
          <Overlay onClick={closeModal} />
          <ModalContent className="animate__animated animate__fadeIn">
            <div>
              <ContainerBanner>
                <h2 className="colorwhite">Help/Support</h2>
                <AiFillCloseCircle
                  onClick={closeModal}
                  style={{
                    cursor: "pointer",
                    color: WHITE_COLOR,
                    fontSize: "2.5rem",
                  }}
                />
              </ContainerBanner>
              <ContainerElement className="background-1">
                <h1>Let's get this done!</h1>
                <ContainerBlock className="background-2 border-color-1">
                  <p>
                    Decimetrix® has created a series of videos to guide and
                    support our Users on how to use the platform. In addition,
                    We have a series of videos using Harvard's Case Study
                    approach with several useful questions and how to answers.
                    Click on Tutorials to get guided help!
                  </p>
                  <DecimButton style={{ width: "50%", marginLeft: "3rem" }}>
                    Tutorials
                  </DecimButton>
                </ContainerBlock>
                <ContainerBlock className="background-2 border-color-1">
                  <p>
                    If for any reason you are unable to complete the task,
                    process, run into a problem that we are not covering in the
                    videos, or have any additional question(s), feel free to
                    reach out, Click on Contac Us and send us an email or send
                    us a message to our support line!
                  </p>
                  <DecimButton style={{ width: "50%", marginLeft: "3rem" }}>
                    Contact Us
                  </DecimButton>
                </ContainerBlock>
                <DecimButton onClick={closeModal} style={{ marginTop: "2rem" }}>
                  Done
                </DecimButton>
              </ContainerElement>
            </div>
          </ModalContent>
        </ModalContainer>
      )}
    </div>
  );
};

export default CustomModal;
