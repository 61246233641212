import useSupercluster from "use-supercluster";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import OperationDetails from "components/ElementDetails/OperationDetails/OperationDetails";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import {
  setFilterEvents,
  setFilteredOperations,
  setOperations,
} from "redux/actions/digitalTwin";

import EventDetails from "components/ElementDetails/EventDetails/EventDetails";
import { setDataEventPolygon } from "redux/actions/admin";

import MarkerElement from "components/Clusters/MarkerElement";
import MarkerCluster from "components/Clusters/MarkerCluster";
import { useStore } from "react-redux";

/**
 * Renders the map component with operation clusters and events.
 *
 * @param {Object} operations - an array of operations
 * @param {Object} events - an array of events
 * @param {Object} bounds - the boundaries of the map
 * @param {Function} onSelectPoint - callback function for selecting a point
 * @param {Object} viewState - the current view state of the map
 * @param {Function} setViewState - callback function for setting the view state of the map
 * @return {JSX.Element} the rendered map component
 */
const OperationsClusters = ({
  events,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const state = store.getState();
  const dataFilteredOperations = state.digitalTwinReducer.filteredOperations;

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );
  const operationClusters = useSelector(
    (state) => state.digitalTwinReducer.operationClusters
  );

  const dataEventFilterTable = useSelector(
    (state) => state.adminReducer.dataEventFilterTable
  );

  const showEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );

  const stateFilterEvents = useSelector(
    (state) => state.digitalTwinReducer.stateFilterEvents
  );

  useEffect(() => {
    dispatch(setFilterEvents(false));
  }, [stateFilterEvents, dispatch]);

  const filterEvents = showEventsByType?.filter((elm) => {
    return elm.state === true;
  });

  const allEventsTypes = events?.filter((event) => {
    return filterEvents?.some((eventFilter) => {
      return (
        event.pointTypeEvent.id === eventFilter.id &&
        event.pointTypeEvent.pointLibraryEventId === eventFilter.libraryId &&
        eventFilter.state === true
      );
    });
  });

  let idInspectionTypes = [];
  for (let key in showEventByInspectionType) {
    if (showEventByInspectionType[key] === true) {
      idInspectionTypes.push(parseInt(key));
    }
  }

  const eventToPoint = (event, type) => ({
    type: "Feature",
    properties: {
      cluster: false,
      event: {
        ...event,
        id: event.id,
        type: type,
        icon:
          type === "operation"
            ? event.typeOperation.urlIcon
            : event.pointTypeEvent.icon,
        location: {
          latitude: event.locationOperation.latitude,
          longitude: event.locationOperation.longitude,
        },
        inspectionFlir: type === "operation" ? event.inspectionFlir : undefined,
        label: event?.label,
      },
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(event.locationOperation.longitude),
        parseFloat(event.locationOperation.latitude),
      ],
    },
  });

  const filteredEvents = allEventsTypes?.map((event) =>
    eventToPoint(event, "event")
  );

  const eventsPoints = events.map((event) => eventToPoint(event, "event"));

  useEffect(() => {
    dispatch(setOperations(eventsPoints));
    dispatch(setDataEventPolygon(allEventsTypes));
  }, [dispatch, eventsPoints, allEventsTypes]);

  useEffect(() => {
    if (dataFilteredOperations.length !== filteredEvents.length) {
      dispatch(setFilteredOperations(filteredEvents));
    }
  }, [dispatch, filteredEvents]);

  const pointEvents = useMemo(() => [...filteredEvents], [filteredEvents]);

  const { clusters } = useSupercluster({
    points:
      dataEventFilterTable.length !== 0 ? dataEventFilterTable : pointEvents,
    bounds,
    zoom: viewState.zoom,
    options: {
      radius: operationClusters.radius || 0,
      // maxZoom: operationClusters.maxZoom || 0,
      maxZoom: operationClusters.maxZoom || 0,
    },
  });

  const handleClickEvent = useCallback(
    (eventId) => {
      const event = {
        content: <EventDetails eventId={eventId} />,
        title: "Event Details",
      };
      dispatch(setOperationDetails(event));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );

  const renderMarkerOperation = useMemo(
    () => (event) => {
      return (
        <MarkerElement
          zoom={viewState.zoom}
          element={event}
          handleClick={() => handleClickEvent(event.id)}
        />
      );
    },
    [handleClickEvent, viewState.zoom]
  );

  const MarkersCluster = useMemo(
    () =>
      clusters.length > 0 &&
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const dataCluster = {
          id: cluster.id,
          location: {
            latitude,
            longitude,
          },
          pointCount,
          elementsCount: pointEvents.length,
          type: "operation",
        };
        if (isCluster) {
          return (
            <MarkerCluster
              cluster={dataCluster}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        }
        return renderMarkerOperation(cluster.properties.event);
      }),
    [
      clusters,
      renderMarkerOperation,
      onSelectPoint,
      pointEvents,
      setViewState,
      viewState,
    ]
  );

  return <>{MarkersCluster}</>;
};

export default OperationsClusters;
