import styled, { css } from "styled-components";
import { Link as LinkR } from "react-router-dom";
import {
  PRIMARY_COLOR,
  EVENT_CLUSTER_COLOR,
  OBJECT_CLUSTER_COLOR,
  CSV_CLUSTER_COLOR,
  COMPONENT_CLUSTER_COLOR,
  BLACK_COLOR,
  WHITE_SECOND_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const MapCardContainer = styled.div`
  /* height: 100%; */
  height: 200hv;

  width: 100%;

  display: flex;
  flex-direction: column;
  padding-top: 90px;
  align-items: center;
  background-color: ${WHITE_SECOND_COLOR};
  /* margin-left: 10px; */
  /* margin-right: 80px; */
`;

export const NavInfo = styled.div`
  margin-left: ${(props) => (props.tipo ? "300px" : "0px")};

  display: flex;

  @media (max-width: 480px) {
    margin-left: ${(props) => (props.tipo ? "215px" : "0px")};
  }
`;

export const MapWrapper = styled.div`
  /* max-width: 1000px; */
  /* height: 50%; */
  /* padding:10px; */

  width: 90%;
  display: grid;
  background-color: ${BLACK_COLOR};

  /* grid-auto-rows: 40rem; */
  grid-template-columns: 350px minmax(10px, 5000px);
  grid-template-rows: 40rem;
  /* grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr) ); */

  /* grid-template-columns: minmax(1fr, 1fr) minmax(60rem, 1fr) ; */
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const BoxNav = styled.div`
  /* max-width: 1000px; */
  /* height: 50%; */
  /* padding:10px; */
`;

export const MapH1 = styled.h1`
  font-size: 2.5rem;
  color: #010606;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ItemList = styled.div`
  margin: 10px;
  display: flex;
  p {
    margin-top: 5px;
  }
  .linesColor {
    color: ${(props) => props.color};
  }

  .line {
    width: 30px;
    height: 3px;
    background: ${(props) => props.color};
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-right: 5px;
  }
  .lineRegion {
    width: 50px;
    height: 3px;
    background: ${(props) => props.color};
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 5px;
  }
  .background {
    width: 100px;
    height: 3px;
    background: ${(props) => props.background};
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 5px;
  }
  img {
    margin-right: 5px;
  }
  .cluster-csv,
  .cluster-objects,
  .cluster-components,
  .cluster-events {
    padding: 15px;
    color: ${WHITE_COLOR};
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    cursor: pointer;
    margin: 0 10px;
  }
  .cluster-csv {
    background: ${CSV_CLUSTER_COLOR};
  }
  .cluster-events {
    background: ${EVENT_CLUSTER_COLOR};
  }
  .cluster-components {
    background: ${COMPONENT_CLUSTER_COLOR};
  }
`;

export const MapCard = styled.div`
  position: absolute;
  width: 100%;
  top: 80px;
  bottom: 0;
  position: fixed;
`;

export const Pentagono = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  background: ${(props) =>
    props.background ||
    'url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png"), #FFF'};
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  opacity: ${(props) => props.opacity || "1"};
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  ${(props) =>
    props.small &&
    css`
      position: relative;
      width: 20px;
      height: 20px;
      z-index: 11;
    `}

  ${(props) =>
    props.into &&
    css`
      position: absolute;
      width: 20px;
      height: 20px;
      z-index: 12;
    `}
`;

export const WrapperButtons = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
`;
export const StyledP = styled.p`
  margin-left: 10px;
`;

export const ButtonCard = styled.button`
  width: 70px;
  height: 40px;
  border-radius: 40px;
  border: none;
  background-color: rgb(14, 77, 69);
  color: ${WHITE_COLOR};
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    opacity: 0.8;
    z-index: 10;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    font-size: 1.4rem;
  }
`;

export const MarkerCard = styled.div`
  background-color: ${WHITE_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  max-height: 540px;
  max-width: 320px;
  /* padding: 30px; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const LayersCard = styled.div`
  align-content: stretch;
  overflow: hidden;
  object-fit: cover;
  object-fit: fill;

  background-color: ${WHITE_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;

  /* border-radius: 10px; */
  /* max-height: 840px; */
  /* height: 90%; */
  max-width: 320px;
  max-height: 320px;
  padding: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const WrapperImages = styled.div`
  display: flex;
  margin: 20px;
  overflow-x: auto;
`;

export const ScrollChild = styled.img`
  width: 90%;
  height: 150px;
  margin-right: 10px;
  border-radius: 10px;
`;

export const NavbarLogoR = styled(LinkR)`
  color: ${WHITE_COLOR};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const Img = styled.img`
  width: 160px;
  height: auto;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const NavBtnLink = styled.button`
  border-radius: 50px;
  background: ${BLACK_COLOR};
  padding: 10px 22px;
  color: ${WHITE_COLOR};
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${BLACK_COLOR};
    background: ${WHITE_COLOR};
  }
`;

export const Wrapperbtn = styled("div")`
  display: flex;
  justify-content: right;
  text-align: right;
  /* flex-direction: left;
  text-align:left; */
  margin: 3px;
`;

export const Label = styled("label")`
  background: ${WHITE_SECOND_COLOR};
  width: 43%;
  border: 2px solid ${PRIMARY_COLOR};
  border-radius: 10px;
  color: #1d1d1d;
  padding: 0.2rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.4rem;

  p {
    margin-left: 0.4 rem;
  }

  &:hover {
    background: ${PRIMARY_COLOR};
    color: ${WHITE_SECOND_COLOR};
  }
`;
