import moment from "moment-timezone";

const removeLineBreak = (text) => {
  if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
  if (text && isNaN(text) && typeof text !== "object") {
    return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
  }
  return text;
};

export const createRowsObjects = ({ objects, library = null }) => {
  let newDataArray = objects;
  if (library) {
    newDataArray = objects?.map((dataItem) => {
      let attributesCopy = [...dataItem.attributes];

      const res = attributesCopy.map((newData) => {
        const elm = library?.fields?.find((item) => {
          return (
            item?.name === newData?.name ||
            item?.alias === newData?.name ||
            item?.name === newData?.alias ||
            item?.alias === newData?.alias
          );
        });
        if (elm) {
          if (elm.type !== "captureMedia" && elm.type !== "notification") {
            if (elm.type === "number" || elm.type === "operation") {
              if (newData.value && newData.value[0] === "{") {
                const valueParse = JSON.parse(newData.value);
                if (valueParse.value === "") {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: NaN,
                    _id: newData._id,
                  };
                } else {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: valueParse.value,
                    _id: newData._id,
                  };
                }
              } else {
                if (newData.value && !isNaN(newData.value)) {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: parseFloat(newData.value),
                    _id: newData._id,
                  };
                }
              }
            } else {
              if (elm.type === "date" && !newData.value === !NaN) {
                return {
                  name: elm.name,
                  alias: elm.alias,
                  value: "0000-00-00",
                  _id: newData._id,
                };
              } else {
                if (!newData.value === !NaN || newData.value === " ") {
                  if (elm.type === "date") {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: "0000-00-00",
                      _id: newData._id,
                    };
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: "Undefined data",
                      _id: newData._id,
                    };
                  }
                } else {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: newData.value,
                    _id: newData._id,
                  };
                }
              }
            }
          }
        }
        return {
          ...newData,
          alias: newData.name,
        };
      });

      const allFields = library?.fields
        ?.map((elm) => {
          if (elm.type !== "captureMedia" && elm.type !== "notification") {
            return {
              name: elm.name,
              alias: elm.alias,
              type: elm.type,
            };
          }
          return null;
        })
        .filter((it) => it !== undefined && it !== null);

      const matchMissingAttributes = allFields?.filter((elm) => {
        return res.every((item) => {
          return item.name !== elm.name;
        });
      });

      if (matchMissingAttributes?.length > 0) {
        matchMissingAttributes.forEach((elm) => {
          if (elm.type === "date") {
            res.push({
              name: elm.name,
              alias: elm.alias,
              value: "0000-00-00",
            });
          } else if (elm.type === "number" || elm.type === "operation") {
            res.push({
              name: elm.name,
              alias: elm.alias,
              value: NaN,
            });
          } else {
            res.push({
              name: elm.name,
              alias: elm.alias,
              value: "Undefined data",
            });
          }
        });
      }

      return { ...dataItem, attributes: res };
    });
  }

  const rows = newDataArray.map((item) => {
    const convertedTime = moment
      .tz(item.date, "UTC")
      .utcOffset(item.timeZoneOffset || "-05:00")
      .tz(item.timeZone || "America/Bogota");
    // const date = new Date(item.date);
    const { location, id } = item;
    const { latitude, longitude } = location;

    const row = [
      {
        name: "id",
        value: removeLineBreak(id),
        alias: "ID",
      },
      {
        name: "typeElementName",
        value: removeLineBreak(item.typeElement.name),
        alias: "Type of Element",
      },
      {
        name: "localDate",
        value: removeLineBreak(
          convertedTime.format("YYYY-MM-DD HH:mm:ss z").substring(0, 19)
        ),
        alias: "Local Date",
      },
      {
        name: "userName",
        alias: "User",
        value: removeLineBreak(
          (item.user &&
            item.user.adminCompany &&
            item.user.adminCompany.firstName +
              " " +
              item.user.adminCompany.firstLastName) ||
            (item.user &&
              item.user.operator &&
              item.user.operator.firstName +
                " " +
                item.user.operator.firstLastName) ||
            (item.user &&
              item.user.adminDecimetrix &&
              item.user.adminDecimetrix.firstName +
                " " +
                item.user.adminDecimetrix.firstLastName)
        ),
      },
      {
        name: "typeElementId",
        alias: "Element ID",
        value: removeLineBreak(item.typeElementId),
      },
      {
        name: "latitude",
        alias: "Latitude",
        value: removeLineBreak(latitude),
      },
      {
        name: "longitude",
        alias: "Longitude",
        value: removeLineBreak(longitude),
      },
      ...item.attributes,
    ];

    return {
      ...item,
      attributes: row,
    };
  });
  const rowsFilter = rows.filter((item) => item !== null);

  return rowsFilter;
};
