import { useContext } from "react";
import { InputProfile, Label, WrapperInput, LegendError } from "./FormStyles";
import { ThemeContext } from "App";
import { WHITE_COLOR } from "utils/const";

const InputComponent = ({
  state,
  changeState,
  type,
  label,
  placeholder,
  name,
  legendError,
  regularExpression,
  func,
  value,
  disabled,
}) => {
  const onChange = (e) => {
    changeState({ ...state, field: e.target.value });
  };
  const { theme } = useContext(ThemeContext);

  const validation = () => {
    if (regularExpression) {
      if (regularExpression.test(state.field)) {
        changeState({ ...state, valid: "true" });
      } else {
        changeState({ ...state, valid: "false" });
      }
    }

    if (func) {
      func();
    }
  };

  return (
    <>
      <WrapperInput>
        <Label
          style={{
            color: theme === "light" ? document.body.style.color : WHITE_COLOR,
          }}
          htmlFor={name}
          valid={state.valid}
        >
          {label}
        </Label>
        <InputProfile
          type={type}
          placeholder={placeholder}
          id={name}
          value={value}
          onChange={onChange}
          onKeyUp={validation}
          onBlur={validation}
          valid={state.valid}
          disabled={disabled}
        />

        <LegendError valid={state.valid}>{legendError}</LegendError>
      </WrapperInput>
    </>
  );
};

export default InputComponent;
