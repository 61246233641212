// Constants
import ShowLinePreview from "helpers/showLinePreview";
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";

export const SelectLineObjectTypes = ({
  lineObjectTypes,
  linelibrarySelected,
  handlerClickLineObjectType,
  typeIdsSelected,
  typeBar,
}) => {
  return (
    <>
      <h2 className="subtitle">Select type</h2>
      <section className="container-type-charts ">
        {lineObjectTypes
          .sort((a, b) => a.type.localeCompare(b.type))
          .filter((lineType) =>
            typeBar === "countByType"
              ? true
              : lineType.lineLibraryId === linelibrarySelected
          )
          .map((lineType) => (
            <div
              key={lineType.id}
              className="card-type-chart"
              onClick={() =>
                handlerClickLineObjectType(
                  lineType.id,
                  lineType.lineLibraryId,
                  lineType
                )
              }
              style={{
                backgroundColor: typeIdsSelected.includes(lineType.id)
                  ? ICON_GREEN_COLOR
                  : WHITE_COLOR,
              }}
            >
              <p>{lineType.type} </p>
              <span>
                <ShowLinePreview
                  color={lineType.color}
                  width={lineType.dasharrayWidth}
                  separator={lineType.dasharrayPixels}
                  text={false}
                />
              </span>
            </div>
          ))}
      </section>
    </>
  );
};
