import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const Settingsicon = () => {
  return (
    <div style={{ marginBottom: "2px" }}>
      <svg
        id="Capa_58"
        data-name="Capa 58"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`.cls-1setticon {
               fill: var(--background-primary_color);

      }

      .cls-1setticon, .cls-2setticon, .cls-3setticon {
        stroke-width: 0px;
      }

      .cls-2setticon {
        fill: #fff;
      }

      .cls-3setticon {
        fill: var(--background-secondary_color);
      }`}
          </style>
        </defs>
        <path
          class="cls-3setticon"
          d="M246.69,321.47c0-45.07,36.54-81.61,81.61-81.61,18.34,0,35.27,6.05,48.9,16.27,3.07-12.59,4.71-25.73,4.71-39.26,0-91.16-73.9-165.05-165.05-165.05S51.81,125.7,51.81,216.86s73.9,165.05,165.05,165.05c17.19,0,33.77-2.63,49.35-7.51-12.16-14.25-19.52-32.73-19.52-52.94Z"
        />
        <rect
          class="cls-2setticon"
          x="105.42"
          y="203.79"
          width="222.88"
          height="26.14"
          rx="13.07"
          ry="13.07"
        />
        <path
          class="cls-2setticon"
          d="M118.49,251.94c-7.22,0-13.07,5.85-13.07,13.07s5.85,13.07,13.07,13.07h140.7c6.67-10.6,15.7-19.57,26.37-26.14H118.49Z"
        />
        <circle class="cls-2setticon" cx="124.91" cy="216.86" r="25.34" />
        <circle class="cls-2setticon" cx="266.01" cy="168.71" r="25.34" />
        <circle class="cls-2setticon" cx="220.8" cy="265.01" r="25.34" />
        <rect
          class="cls-2setticon"
          x="105.42"
          y="155.64"
          width="222.88"
          height="26.14"
          rx="13.07"
          ry="13.07"
        />
        <circle class="cls-1setticon" cx="328.3" cy="321.47" r="71.09" />
        <path
          class="cls-2setticon"
          d="M351.8,333.31c-.61,0-1.22.06-1.81.14-4.62.06-16.31-1.75-13.47-22.05.47-1.83.74-3.74.74-5.71,0-12.68-10.28-22.96-22.96-22.96s-22.96,10.28-22.96,22.96,10.28,22.96,22.96,22.96c2.19,0,4.31-.33,6.31-.9,5.42-.86,18.83-1.06,17.75,18.38,0,0,.01,0,.02,0,0,.21-.03.42-.03.64,0,7.43,6.02,13.44,13.44,13.44s13.44-6.02,13.44-13.44-6.02-13.44-13.44-13.44Z"
        />
      </svg>
    </div>
  );
};

export default Settingsicon;
