import styled from "styled-components";

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;
`;
export const Title = styled("div")`
  font-size: 1.4rem;
  margin: 20px 0;
`;
