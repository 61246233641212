import axios from "axios";
import { config } from "config.js";

export const uploadCSVObjects = async (body) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${config.URL_BACKEND_PG}api/v1/csv/objects`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};
