import styled from "styled-components";

export const CnstInfoTable = styled.table`
  width: 50%;

  th {
    text-align: left;
    border-down: 1px solid black;
  }
`;
