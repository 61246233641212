import { useEffect, useState } from "react";
import DropDragGrid from "pages/Dashboard/DragAndDrop/DragAndDrollGrid";
import useFindKpiCharts from "pages/Dashboard/Hooks/Objects/useFindKpiCharts";
import useFindTextCharts from "pages/Dashboard/Hooks/Objects/useFindTextCharts";
import useFindLineCharts from "pages/Dashboard/Hooks/Objects/useFindLineCharts";
import useFindBarCharts from "pages/Dashboard/Hooks/Objects/useFindBarCharts";
import useFindDoughnutCharts from "pages/Dashboard/Hooks/Objects/useFindDoughnutCharts";
import { useFindTimeSeriesChartObjects } from "pages/Dashboard/Hooks/Objects/useFindTimeSeriesCharts";
import { useFindTimeSeriesBarChartObjects } from "pages/Dashboard/Hooks/Objects/useFindTimeSeriesBarCharts";
import { useFindImgChartsObjects } from "pages/Dashboard/Hooks/Objects/useFindImgCharts";
import { useFindGroupDoughnutChartsObjects } from "pages/Dashboard/Hooks/Objects/useFindGroupDoughnutCharts";
const ObjectCharts = ({
  openDialog,
  objects,
  loading,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  orderId,
}) => {
  const [dataChart, setDataChart] = useState([]);

  const { kpiCharts } = useFindKpiCharts({
    openDialog,
    objects,
    loading,
  });

  const { textCharts } = useFindTextCharts({ openDialog });

  const { lineCharts } = useFindLineCharts({
    openDialog,
    objects,
    loading,
  });

  const { barCharts } = useFindBarCharts({
    openDialog,
    objects,
    loading,
  });

  const { doughnutCharts } = useFindDoughnutCharts({
    openDialog,
    objects,
    loading,
  });

  const { groupDoughnutCharts } = useFindGroupDoughnutChartsObjects({
    openDialog,
    objects,
    loading,
  });

  const { timeSeriesCharts } = useFindTimeSeriesChartObjects({
    openDialog,
    objects,
    loading,
  });

  const { timeSeriesBarCharts } = useFindTimeSeriesBarChartObjects({
    openDialog,
    objects,
    loading,
  });

  const { imgCharts } = useFindImgChartsObjects({
    openDialog,
  });

  useEffect(() => {
    if (
      kpiCharts &&
      textCharts &&
      lineCharts &&
      barCharts &&
      doughnutCharts &&
      groupDoughnutCharts &&
      timeSeriesCharts &&
      timeSeriesBarCharts &&
      imgCharts
    ) {
      setDataChart([
        ...kpiCharts,
        ...textCharts,
        ...lineCharts,
        ...barCharts,
        ...doughnutCharts,
        ...groupDoughnutCharts,
        ...timeSeriesCharts,
        ...timeSeriesBarCharts,
        ...imgCharts,
      ]);
    }
  }, [
    kpiCharts,
    textCharts,
    lineCharts,
    barCharts,
    doughnutCharts,
    groupDoughnutCharts,
    timeSeriesCharts,
    timeSeriesBarCharts,
    currentOrderName,
    currentTemplate,
    imgCharts,
  ]);

  return (
    <>
      {dataChart && objects && (
        <DropDragGrid
          dataChart={dataChart}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          currentPositionId={currentPositionId}
          orderId={orderId}
        />
      )}
    </>
  );
};

export default ObjectCharts;
