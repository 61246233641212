import styled from "styled-components";
import {
  WHITE_BACKGROUND,
  BACKGROUND_GREY,
  BORDER_GREY,
  PRIMARY_COLOR,
  SECOND_COLOR,
  SELECTION_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const Content = styled.div`
  height: 100vh;
  background-color: ${BACKGROUND_GREY};
`;

export const InformationSection = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  border: 1px solid ${BORDER_GREY};
  background-color: ${WHITE_BACKGROUND};
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 15%;
  background-position-x: 70%;
  h1 {
    margin: 0;
    font-size: 3rem;
  }
`;
export const GlobalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 20rem);
  height: auto;
  padding: 5rem 0;
`;
export const ConfigurationSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;
  min-width: 50%;
  height: 600px;
  margin: 30px;
`;

export const ConfigLibrary = styled.div`
  width: 49%;
  height: 100%;
  background-color: ${BACKGROUND_GREY};
`;

export const ConfigConstant = styled.div`
  width: 49%;
  height: 100%;
  background-color: ${BACKGROUND_GREY};
`;

export const ContentButton = styled.div`
  width: 100%;
  height: 20%;
  padding: 10px;
  border: solid 1px ${BORDER_GREY};
  border-radius: 5px;
  background-color: ${WHITE_BACKGROUND};
  text-align: center;

  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const Columns = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: 20px;
  align-items: center;
  border-radius: 5px 5px 0 0;
  background-color: var(--background-primary_color);
`;

export const Space = styled.div`
  margin-top: 14px;
`;

export const Column = styled.p`
  width: ${(props) => props.width}%;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: ${(props) => props.textAling};
  align-items: center;
  color: ${WHITE_COLOR};
`;

export const ContentData = styled.div`
  width: 100%;
  height: 80%;
  border: solid 1px ${BORDER_GREY};
  margin-top: 14px;
  border-radius: 6px;
  background-color: ${WHITE_BACKGROUND};
`;

export const InformationShare = styled.div`
  background-image: url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png");
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  padding: 21px;
  border-radius: 5px;

  .body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--background-secondary_color);
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 450px;
  padding: 10px;
`;

export const Title = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: 800;
`;

export const ContentMoveTags = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;
export const MoveTag = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  margin: 10px;
  padding: 2px;
  border: 1px solid #fefefe;
  border-radius: 10px;
  background-color: ${({ currentLibraryId, item }) =>
    currentLibraryId === item.id ? SELECTION_COLOR : BACKGROUND_GREY};
  text-align: ${(props) => props.textAling};
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
  }
`;

export const ContentDataTag = styled.p`
  width: ${(props) => props.width}%;
  background-color: ${WHITE_COLOR};
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.8rem;
  font-weight: 500;
`;
