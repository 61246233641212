import { useNavigate } from "react-router-dom";
import { LinksTermsContainer } from "./LinksTermsStyle";

const LinksTerms = () => {
  const navigate = useNavigate();

  const navigation = (url) => navigate(url);

  return (
    <LinksTermsContainer>
      <ul>
        <li
          className="item-links background-row-1 border-color-1 color1"
          onClick={() => navigation("/terms-and-conditions-cloud-web")}
        >
          Decimetrix® Green Dragon cloud enterprise-level web platform Terms and
          Conditions
        </li>
        <li
          className="item-links background-row-1 border-color-1 color1"
          onClick={() => navigation("/terms-and-conditions-mobile")}
        >
          Decimetrix® Reality Capture Mobile Solution Terms and Conditions
        </li>
        <li
          className="item-links background-row-1 border-color-1 color1"
          onClick={() => navigation("/terms-and-conditions-socrates")}
        >
          Decimetrix® AI Socrates Software Platform Terms and Conditions
        </li>
      </ul>
    </LinksTermsContainer>
  );
};

export default LinksTerms;
