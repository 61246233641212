import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { INFORMATION_COLOR } from "utils/const";
import { useTooltip } from "./TooltipContext";

const PreferenceToolTip = ({
  title,
  children,
  placement,
  open,
  backgroundColor,
  color,
}) => {
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundColor,
      color: color,
      boxShadow: `0 1px 5px ${backgroundColor}`,
      fontSize: 16,
      cursor: "pointer",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor,
    },
  }));

  return (
    <StyledTooltip
      title={title}
      arrow
      placement={placement}
      open={open}
      TransitionProps={{ timeout: 0 }}
    >
      {children}
    </StyledTooltip>
  );
};

export default PreferenceToolTip;
