const fieldTypes = {
  string: "String",
  number: "Number",
  check: "Check",
  select: "Select",
  lineTag: "Line Tag",
  date: "Date",
  file: "File",
  email: "Email",
  range: "Range",
  url: "Url",
  delegate: "Delegate",
  currency: "Currency",
  captureMedia: "Capture media",
  notification: "Notification",
};

export default fieldTypes;

export const fieldExpression = {
  value: "operation",
  text: "Expression",
  typeCondition: "number",
};

export const enableTypeFiles = {
  jpg: "JPG",
  png: "PNG",
  pdf: "PDF",
  doc: "DOC",
  docx: "DOCX",
  xsls: "XSLX",
  xls: "XLS",
  xlsx: "XLSX",
  csv: "CSV",
  ppt: "PPT",
  dwg: "DWG",
  dwf: "DWF",
  avi: "AVI",
  mp3: "MP3",
  dat: "DAT",
  seq: "SEQ",
  dwt: "DWT",
  dxf: "DXF",
  rvt: "RVT",
  csq: "CSQ",
  mp4: "MP4",
  txt: "TXT",
};

export const enableTypeCurrency = {
  COP: "COP",
  USD: "USD",
  MXN: "MXN",
  AED: "AED",
  BHD: "BHD",
  BRL: "BRL",
  CAD: "CAD",
  CHF: "CHF",
  CNY: "CNY",
  GBP: "GBP",
  GIP: "GIP",
  INR: "INR",
  JOD: "JOD",
  KWD: "KWD",
  KYD: "KYD",
  OMR: "OMR",
  SAR: "SAR",
};
