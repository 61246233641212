import axios from "axios";
import { config } from "config.js";

/**
 * Creates a new object relation.
 *
 * @param {Object} body - The request body.
 *   @property {number} existObjectId - The ID of the existing object.
 *   @property {number} newObjectId - The ID of the new object.
 * @returns {Promise<Object>} The response data from the API.
 */
const createObjectRelation = async (body) => {
  /**
   * body: {
   *  existObjectId: int
   *  newObjectId: int
   * }
   */
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_PG}api/v1/objects/relation`;
  const result = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default createObjectRelation;
