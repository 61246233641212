import axios from "axios";
import { config } from "config.js";

const deleteFilterDate = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/date-filter/${id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error("🚩🚩🚩Error deleting date service", error);
  }
};

export default deleteFilterDate;
