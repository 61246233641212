import CustomButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
import TextInput from "components/Forms/GenericInput";
import { useState } from "react";
import { Form } from "./AccordionBookmarksViewsStyle";
import { useSelector } from "react-redux";
import { useSWRConfig } from "swr";
import { useForm } from "react-hook-form";
import { config } from "config.js";
import saveDate from "services/DatesFilter/saveDate";
import { useDispatch } from "react-redux";

const fieldInputs = [
  {
    name: "Name of the Date",
    type: "string",
    required: true,
  },
  {
    name: "Public",
    type: "checkbox",
    required: false,
  },
];

function AccordionFilterDate({
  handleCloseModal,
  localStartDate,
  localEndDate,
  updateDates,
}) {
  const { mutate } = useSWRConfig();
  const [openModalSave, setOpenModalSave] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  //Save info of the dates saved
  const [datesSaved, setDatesSaved] = useState([]);

  ///Info for database
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const role = localStorage.getItem("role");
  ////

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoad(true);
    const newDate = {
      userId: userId,
      name: data["Name of the Date"],
      initialDate: localStartDate,
      finalDate: localEndDate,
      adminCompanyId: adminCompanyId,
      visibility: data?.Public || false,
    };

    try {
      await saveDate(newDate);
      setIsLoad(false);
      await updateDates(); //Update the dates
      setDatesSaved(newDate);
      mutate(`${config.apiUrl}/datesFilter`);
      cleanField();

      //Close after saved
      handleCloseModal();
    } catch {
      console.error("Error saving date");
    }
  };

  const cleanField = () => {
    document.getElementById("Name of the Date").value = "";
  };

  return (
    <>
      {/**Agregar un label para agregar el nombre de la fecha y un checkbox */}
      <div>
        <OpenDialog
          openDialog={openModalSave}
          setOpenDialog={handleCloseModal}
          execute={() => {}}
          content={{
            title: "Save Filter",
            description: (
              <>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {fieldInputs.map((field) => {
                    if (
                      field.type === "checkbox" &&
                      role === "Administrador de compañía"
                    )
                      return (
                        <TextInput
                          field={field}
                          register={register}
                          errors={errors}
                        />
                      );

                    if (field.type === "string")
                      return (
                        <TextInput
                          field={field}
                          register={register}
                          errors={errors}
                        />
                      );
                    return null;
                  })}

                  <CustomButton isLoad={isLoad} text={"Save"} type={"submit"} />
                </Form>
              </>
            ),
          }}
          minWidth={"300px"}
        />
      </div>
    </>
  );
}

export default AccordionFilterDate;
