import CircularProgress from "@mui/material/CircularProgress";
import { BLACK_COLOR, PRIMARY_COLOR } from "utils/const";
import { LoadingContainer } from "./LoadingStyle";

export default function CircularIndeterminate() {
  return (
    <LoadingContainer>
      <CircularProgress
        style={{
          width: "150px",
          height: "150px",
          color: "var(--background-primary_color)",
        }}
      />
      <p className="color1">Loading</p>
    </LoadingContainer>
  );
}
