// Components
import {
  Change,
  FooterConteiner,
  NavigateContainer,
} from "../../GenericTableStyle";
// Styles
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
export const FooterTools = ({ table }) => {
  return (
    <FooterConteiner className="background-row-1 color1">
      <div
        style={{
          margin: "auto 10px",
          fontSize: "12px",
        }}
      >
        {table.getPrePaginationRowModel().rows.length} Rows |
      </div>
      <span
        style={{
          margin: "auto 0px",
          fontSize: "12px",
          marginLeft: "0",
        }}
        className="flex items-center gap-1"
      >
        <strong style={{ marginRight: "1rem" }}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
        | Go to page:
        <input
          style={{ marginLeft: "1rem" }}
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
          className="border p-1 rounded w-16"
        />
      </span>

      <select
        style={{
          margin: "auto",
          marginLeft: "0",
          background: "none",
          paddingLeft: "5px",
          backgroundColor: "white",
          border: "solid 1px #9D8F8F",
          borderRadius: "2px",
          width: "auto",
          height: "20px",
        }}
        value={
          table.getState().pagination.pageSize === 1000000
            ? "All"
            : table.getState().pagination.pageSize
        }
        onChange={(e) => {
          table.setPageSize(
            Number(e.target.value === "All" ? 1000000 : e.target.value)
          );
        }}
      >
        {[5, 10, 15, 20, 1000000].map((pageSize) => (
          <option
            key={pageSize}
            value={pageSize === 1000000 ? "All" : pageSize}
          >
            Show {pageSize === 1000000 ? "All" : pageSize}
          </option>
        ))}
      </select>
      <div
        className="flex items-center gap-2"
        style={{
          margin: "1rem",
          display: "flex",
          width: "30%",
          height: "fit-content",
          justifyContent: "end",
        }}
      >
        <NavigateContainer style={{ display: "flex", flexDirection: "row" }}>
          <Change
            className={`border rounded p-1 changeElement ${
              !table.getCanPreviousPage() ? "disabled" : "activate"
            }`}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <FirstPageIcon className="arrowPage" />
          </Change>
          <Change
            className={`border rounded p-1 changeElement ${
              !table.getCanPreviousPage() ? "disabled" : "activate"
            }`}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <NavigateBeforeIcon className="arrowPage" />
          </Change>
          <Change
            className={`border rounded p-1 changeElement ${
              !table.getCanNextPage() ? "disabled" : "activate"
            }`}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <NavigateNextIcon className="arrowPage" />
          </Change>
          <Change
            className={`border rounded p-1 changeElement ${
              !table.getCanNextPage() ? "disabled" : "activate"
            }`}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <LastPageIcon className="arrowPage" />
          </Change>
        </NavigateContainer>
      </div>
    </FooterConteiner>
  );
};
