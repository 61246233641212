const handleAddGridColumn = ({
  index,
  setDragAndDroll,
  setCurrentTemplate,
}) => {
  setDragAndDroll(true);
  setCurrentTemplate((current) => {
    const chartsPosition = current.chartsPosition;
    const found = chartsPosition.some((elm) => elm.index === index);

    if (found) {
      return {
        ...current,
        chartsPosition: chartsPosition.map((elm) =>
          elm.index === index
            ? {
                ...elm,
                gridColumnStart:
                  elm.gridColumnStart[5] <
                  parseInt(
                    current.gridTemplateColumns[24] +
                      current.gridTemplateColumns[25]
                  )
                    ? `span ${parseInt(elm.gridColumnStart.split(" ")[1]) + 1}`
                    : elm.gridColumnStart,
              }
            : elm
        ),
      };
    } else {
      return {
        ...current,
        chartsPosition: [
          ...chartsPosition,
          {
            index: index,
            gridRowStart: "span 1",
            gridColumnStart: "span 2",
          },
        ],
      };
    }
  });
};

export default handleAddGridColumn