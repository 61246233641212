// Style
import { ConfigEventsContainer } from "./indexStyle";
// Hooks
import useFindAllEvents from "hooks/events/useFindAllEvents";

// Components
import Loading from "components/Lodings/Loading";
import FormText from "pages/Dashboard/components/FormText";
import FormLineChart from "pages/Dashboard/components/FormLineChartEvents";
import EventFormKpi from "pages/Dashboard/components/EventFormKpi";
import EventFormBar from "pages/Dashboard/components/EventFormBar";
import EventFormDoughnut from "pages/Dashboard/components/EventFormDoughnut";
import FormTimeChartEvents from "pages/Dashboard/components/FormTimeChartEvents";
import FormGroupBarChartEvents from "pages/Dashboard/components/FormGroupBarChartEvents";
import FormTimeSeriesBarChartEvents from "pages/Dashboard/components/FormTimeSeriesBarChartEvents";
import FormImgChart from "pages/Dashboard/components/FormImgChart";
import EventFormGroupDoughnut from "../../components/EventFormGroupDoughnut";

const ConfigEvents = ({ chartType, setOpenDialog }) => {
  // Find envets data
  const { events, loading } = useFindAllEvents();

  return (
    <ConfigEventsContainer>
      {events?.length > 0 && (
        <section>
          <div className="conatiner-add-chart">
            <h1 className="title">Chart settings</h1>
            {/* Kpi chart */}
            {chartType === "kpiChart" && (
              <EventFormKpi setOpenDialog={setOpenDialog} />
            )}
            {/* Bar chart */}
            {chartType === "barChart" && (
              <EventFormBar setOpenDialog={setOpenDialog} />
            )}
            {/* Doughnut chart */}
            {chartType === "doughnutChart" && (
              <EventFormDoughnut setOpenDialog={setOpenDialog} />
            )}
            {chartType === "groupDoughnutChart" && (
              <EventFormGroupDoughnut setOpenDialog={setOpenDialog} />
            )}
            {/* Line Chart */}
            {chartType === "lineChart" && (
              <FormLineChart setOpenDialog={setOpenDialog} />
            )}
            {/* Time Series Chart */}
            {chartType === "timeChart" && (
              <FormTimeChartEvents setOpenDialog={setOpenDialog} />
            )}
            {/* Group Bar Chart */}
            {chartType === "groupBarChart" && (
              <FormGroupBarChartEvents setOpenDialog={setOpenDialog} />
            )}
            {/* Text chart */}
            {chartType === "textChart" && (
              <FormText from={"events"} setOpenDialog={setOpenDialog} />
            )}
            {/* Time Series Bar Chart */}
            {chartType === "timeSeriesBarChart" && (
              <FormTimeSeriesBarChartEvents setOpenDialog={setOpenDialog} />
            )}
            {chartType === "imgChart" && (
              <FormImgChart from={"events"} setOpenDialog={setOpenDialog} />
            )}
          </div>
        </section>
      )}
      {loading && <Loading height={"auto"} text={"Loading..."} />}
    </ConfigEventsContainer>
  );
};

export default ConfigEvents;
