const setVideos = ({ files, setVideosDetails, setVideoFiles }) => {
  Object.values(files).forEach((video) => {
    // if (video.name.includes(".seq")) {
    setVideoFiles((prev) => {
      if (prev?.length > 0) {
        const findVideo = prev?.find((item) => item.name === video.name);
        if (findVideo) return prev;
        return prev.concat(video);
      }
      return prev.concat(video);
    });
    const reader = new FileReader();
    reader.readAsDataURL(video);
    reader.onload = () => {
      setVideosDetails((prev) => {
        const bodyVideo = [
          {
            data: reader.result,
            name: video.name,
            size: video.size,
            type: video.type,
            updatedDate: video.lastModifiedDate,
            videoIsLoad: null,
          },
        ];
        if (prev?.length > 0) {
          const findVideo = prev?.find((item) => item.name === video.name);
          if (findVideo) return prev;
          return prev.concat(bodyVideo);
        }
        return prev.concat(bodyVideo);
      });
    };
    // }
  });
};

export default setVideos;
