import React from "react";
import styled, { keyframes } from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const moveArrow = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
`;

const StyledLeftArrowIcon = styled.div`
  width: 70px;
  height: 70px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    .cls-1lai {
      fill: var(--background-secondary_color);
      stroke-width: 0px;
    }
  }

  &:hover svg .cls-1lai {
    /* fill: ${PRIMARY_COLOR}; */
  }

  &:active svg {
    animation: ${moveArrow} 0.4s ease;
  }
`;

const LeftArrowIcon = () => {
  return (
    <StyledLeftArrowIcon>
      <svg
        id="Capa_54"
        data-name="Capa 54"
        viewBox="0 0 433.72 433.72"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className="cls-1lai"
          x="127.57"
          y="146.61"
          width="176.35"
          height="57.76"
          rx="24"
          ry="24"
          transform="translate(491.99 143.35) rotate(134.24)"
        />
        <rect
          className="cls-1lai"
          x="128.68"
          y="230.46"
          width="176.35"
          height="57.76"
          rx="24"
          ry="24"
          transform="translate(191.3 596.43) rotate(-135.76)"
        />
      </svg>
    </StyledLeftArrowIcon>
  );
};

export default LeftArrowIcon;
