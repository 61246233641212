import { LinearProgress, Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CustomButton from "components/Buttons/CustomButton";
import { DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";
import { deleteObjectsByIds } from "services/objects/bulkDelete";
import { deleteEventsByIds } from "services/Events/bulkDelete";
import { deleteComponentsByIds } from "services/components/bulkDelete";
import { useFilteredData } from "../Hooks/useFilteredData";
import { useSocket } from "hooks/useSocket";

const TYPE_ELEMENT = {
  OBJECT: "objects",
  COMPONENT: "components",
  EVENT: "events",
};

export const BulkDelete = ({ setOpenBulkDelete, typeOfTable }) => {
  const socket = useSocket();
  const [ids, setIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteWithRelations, setDeleteWithRelations] = useState(false);

  const [deleteProgress, setDeleteProgress] = useState();
  const [deleteBatchComplete, setDeleteBatch] = useState();
  const [bulkDeleteComplete, setBulkDelete] = useState();

  const { itemsFiltered } = useFilteredData({ typeOfTable });

  useEffect(() => {
    setIds(itemsFiltered.map((item) => item.id));
  }, [itemsFiltered]);

  useEffect(() => {
    const handleSocketEvent = (event, setDataFlag) => {
      if (socket) {
        socket.on(event, (data) => {
          setDataFlag(data);
        });
        return () => {
          socket.off(event);
        };
      }
    };

    const cleanupSocketListeners = [
      handleSocketEvent("deleteProgress", setDeleteProgress),
      handleSocketEvent("deleteBatchComplete", setDeleteBatch),
      handleSocketEvent("bulkDeleteComplete", setBulkDelete),
    ];

    return () => {
      cleanupSocketListeners.forEach((cleanup) => cleanup());
    };
  }, [socket]);

  // handle file u

  const handleDeleteData = async () => {
    setIsLoading(true);
    try {
      const deleteActions = {
        [TYPE_ELEMENT.OBJECT]: deleteObjectsByIds,
        [TYPE_ELEMENT.COMPONENT]: deleteComponentsByIds,
        [TYPE_ELEMENT.EVENT]: deleteEventsByIds,
      };

      const deleteFunction = deleteActions[typeOfTable];
      if (deleteFunction) {
        if (typeOfTable === TYPE_ELEMENT.OBJECT){
          await deleteFunction({ ids, relations: deleteWithRelations });
          return;
        }
        await deleteFunction(ids);
      } else {
        console.error(`Tipo de tabla no reconocido: ${typeOfTable}`);
      }
    } catch (error) {
      console.error("Error al eliminar datos:", error);
    } finally {
      setIsLoading(false);
      setOpenBulkDelete(false);
    }
  };

  const ProgressBar = ({ value }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress color="success" variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography>{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const DeleteProgress = ({
    deleteProgress,
    deleteBatchComplete,
    bulkDeleteComplete,
  }) => {
    if (!deleteProgress && !deleteBatchComplete && !bulkDeleteComplete) {
      return null;
    }

    let progress = 0;
    let statusText = "";

    if (deleteProgress) {
      progress = (deleteProgress.deleted / deleteProgress.total) * 100;
      statusText = `Deleting: ${deleteProgress.deleted} / ${deleteProgress.total}`;
    } else if (deleteBatchComplete) {
      progress =
        (deleteBatchComplete.deleted / deleteBatchComplete.total) * 100;
      statusText = `Batch complete: ${deleteBatchComplete.deleted} deleted, ${deleteBatchComplete.errors} errors`;
    } else if (bulkDeleteComplete) {
      progress = 100;
      statusText = `Deletion complete: ${bulkDeleteComplete.deleted} deleted, ${bulkDeleteComplete.errors} errors`;
    }

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <ProgressBar value={progress} />
        <Typography variant="body2" sx={{ mt: 1 }}>
          {statusText}
        </Typography>
      </Box>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <section>
        <p
          style={{
            fontSize: "2rem",
          }}
        >
          Are you sure you want to delete all filtered items?
        </p>
        <section>
          {typeOfTable === TYPE_ELEMENT.OBJECT && (
            <div
              style={{
                marginTop: "1rem",
                paddingLeft: "1rem",
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <input
                name={"delete-relations"}
                type="checkbox"
                onChange={() => setDeleteWithRelations(!deleteWithRelations)}
                value={deleteWithRelations}
              />
              <label for="delete-relations">Delete all relations?</label>
            </div>
          )}
        </section>
        <DeleteProgress
          deleteProgress={deleteProgress}
          deleteBatchComplete={deleteBatchComplete}
          bulkDeleteComplete={bulkDeleteComplete}
        />
      </section>
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CustomButton
          text={"Delete filtered data"}
          onClick={handleDeleteData}
          primaryColor={DELETE_COLOR}
          secondaryColor={HOVER_DELETE_COLOR}
          margin={"0"}
          isLoad={isLoading}
        />
        <CustomButton
          text={"Cancel"}
          onClick={() => setOpenBulkDelete(false)}
          margin={"0 10"}
        />
      </div>
    </div>
  );
};
