import {
  SHOW_DRAWER_USER_ROUTES,
  TYPE_JOB,
  SHOW_DRAWER_DIGITAL_TWIN_ADMIN,
  SET_COORDINATES_USER_JOB,
  SET_FEATURES_DETAILS,
  SET_FEATURES_DETAILS_DT,
  SET_LOCATIONSID_IN_POLYGON,
  SET_GEOMETRY_POLYGON,
  SET_FEATURES_POLYGON,
  SET_DATA_OBJECTS,
  SET_DATA_REGION,
  SET_ORIGINAL_DATA_OBJECTS_POLYGON,
  SET_DATA_OBJECTS_POLYGON,
  SHOW_MARKER_BY_TYPE_OBJECT_ID,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID,
  SHOW_DATA_OBJECTS,
  SET_FILTER_OBJECT,
  SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD,
  SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON,
  SHOW_REGION_DETAILS_DRAWER,
  SET_FILTER_USERS,
  SET_SHOW_FILTER_USERS,
  SET_SHOW_FILTER_ALL_USERS,
  SET_GEOMETRY_LINE,
  SET_GEOMETRY_LINE_CONFIG,
  SHOW_LINE_DETAILS_DRAWER,
  SET_DATA_EVENT_POLYGON,
  OBJECTS_FILTER_TABLE,
  SET_DATA_EVENTS_FILTER_TABLE,
  SET_DATA_COMPONENTS_POLYGON,
  COMPONENTS_FILTER_TABLE,
  SET_UPDATE_GEOMETRY_LINE,
  SET_OBJECT_DELETE_LINE_DRAWS,
  RESET_STATES,
} from "./../actions/types";

const initialValues = {
  dataEventFilterTable: [],
  objectsFilterTable: [],
  componentsFilterTable: [],
  SetShowAllUsers: false,
  SetShowUsers: false,
  SetUsers: {},
  setGeometryPolygon: {},
  setFeaturesPolygon: [],
  setLocationsIdPolygon: [],
  dataEventPolygon: [],
  dataComponentPolygon: [],
  setGeometryLine: {},
  updateGeometryLine: {},
  setGeometryLineConfig: {},
  showDrawerUserRoutes: true,
  typeJob: null,
  showDrawerDigitalTwinAdmin: false,
  setCoordinatesUserRoute: {
    lat: null,
    lng: null,
  },
  setFeaturesDetails: {
    geometry: {
      type: null,
    },
  },
  setFeaturesDetailsDT: {
    geometry: {
      type: null,
    },
  },
  dataObjects: [],
  dataObjectsRegion: {},
  dataOriginalObjectsPolygon: [],
  dataObjectsPolygon: [],
  markerByTypeObject: [],
  markerByTypeElement: [],
  showDataObjects: {},
  stateFilterObject: false,
  markerByTypeObjectLoad: {},
  markerByTypeElementLoad: {},
  markerByTypeObjectPolygon: {},
  markerByTypeElementPolygon: {},
  showRegionDetailsDrawer: false,
  showLineDetailsDrawer: true,
  setObjectDeleteLineDraws: {
    state: false,
    lineIds: [],
  },
};

const adminReducer = (state = initialValues, action) => {
  switch (action.type) {
    case SET_OBJECT_DELETE_LINE_DRAWS:
      return {
        ...state,
        setObjectDeleteLineDraws: action.payload,
      };
    case SET_UPDATE_GEOMETRY_LINE:
      return {
        ...state,
        updateGeometryLine: action.payload,
      };
    case COMPONENTS_FILTER_TABLE:
      return {
        ...state,
        componentsFilterTable: action.payload,
      };
    case SET_DATA_EVENTS_FILTER_TABLE:
      return {
        ...state,
        dataEventFilterTable: action.payload,
      };
    case OBJECTS_FILTER_TABLE:
      return {
        ...state,
        objectsFilterTable: action.payload,
      };
    case SET_DATA_EVENT_POLYGON:
      return {
        ...state,
        dataEventPolygon: action.payload,
      };
    case SET_DATA_COMPONENTS_POLYGON:
      return {
        ...state,
        dataComponentPolygon: action.payload,
      };
    case SET_GEOMETRY_POLYGON:
      return {
        ...state,
        setGeometryPolygon: action.payload,
      };
    case SET_FEATURES_POLYGON:
      return {
        ...state,
        setFeaturesPolygon: action.payload,
      };
    case SET_LOCATIONSID_IN_POLYGON:
      return {
        ...state,
        setLocationsIdPolygon: action.payload,
      };
    case SET_GEOMETRY_LINE:
      return {
        ...state,
        setGeometryLine: action.payload,
      };
    case SET_GEOMETRY_LINE_CONFIG:
      return {
        ...state,
        setGeometryLineConfig: action.payload,
      };
    case SHOW_DRAWER_USER_ROUTES:
      return {
        ...state,
        showDrawerUserRoutes: action.payload,
      };
    case TYPE_JOB:
      return {
        ...state,
        typeJob: action.payload,
      };
    case SHOW_DRAWER_DIGITAL_TWIN_ADMIN:
      return {
        ...state,
        showDrawerDigitalTwinAdmin: action.payload,
      };
    case SET_COORDINATES_USER_JOB:
      return {
        ...state,
        setCoordinatesUserRoute: action.payload,
      };
    case SET_FEATURES_DETAILS:
      return {
        ...state,
        setFeaturesDetails: action.payload,
      };
    case SET_FEATURES_DETAILS_DT:
      return {
        ...state,
        setFeaturesDetailsDT: action.payload,
      };
    case SET_DATA_OBJECTS:
      return {
        ...state,
        dataObjects: action.payload,
      };
    case SET_ORIGINAL_DATA_OBJECTS_POLYGON:
      return {
        ...state,
        dataOriginalObjectsPolygon: action.payload,
      };
    case SET_DATA_OBJECTS_POLYGON:
      return {
        ...state,
        dataObjectsPolygon: action.payload,
      };
    case SET_DATA_REGION:
      return {
        ...state,
        dataObjectsRegion: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_OBJECT_ID:
      return {
        ...state,
        markerByTypeObject: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_ELEMENT_ID:
      return {
        ...state,
        markerByTypeElement: action.payload,
      };
    case SHOW_DATA_OBJECTS:
      return {
        ...state,
        showDataObjects: action.payload,
      };
    case SET_FILTER_OBJECT:
      return {
        ...state,
        stateFilterObject: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD:
      return {
        ...state,
        markerByTypeObjectLoad: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD:
      return {
        ...state,
        markerByTypeElementLoad: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON:
      return {
        ...state,
        markerByTypeObjectPolygon: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON:
      return {
        ...state,
        markerByTypeElementPolygon: action.payload,
      };
    case SHOW_REGION_DETAILS_DRAWER:
      return {
        ...state,
        showRegionDetailsDrawer: action.payload,
      };
    case SHOW_LINE_DETAILS_DRAWER:
      return {
        ...state,
        showLineDetailsDrawer: action.payload,
      };
    case SET_FILTER_USERS:
      return {
        ...state,
        SetUsers: action.payload,
      };
    case SET_SHOW_FILTER_USERS:
      return {
        ...state,
        SetShowUsers: action.payload,
      };
    case SET_SHOW_FILTER_ALL_USERS:
      return {
        ...state,
        SetShowAllUsers: action.payload,
      };
    case RESET_STATES:
      return initialValues;
    default:
      return state;
  }
};

export default adminReducer;
