import { circle, point, polygon, booleanPointInPolygon } from "@turf/turf";

// agnostico del elemento
export const getPolygon = ({ initialLocation, radius }) => {
  const responseOnError = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [],
    },
  };
  try {
    if (initialLocation.latitude && initialLocation.longitude) {
      const center = [initialLocation.longitude, initialLocation.latitude];
      const options = {
        steps: 10,
        units: "meters",
        properties: { foo: "bar" },
      };
      const polygon = circle(center, radius, options);
      return polygon;
    }
  } catch (error) {
    console.log("Error getting polygon", error);
    return responseOnError;
  }
};

// Agnostico del elemento
export const getPolygonData = async ({ polygon, elementType, data }) => {
  const responseOnError = [];
  let elements = [];
  try {
    switch (elementType) {
      case "object":
        elements = await filterObjects(data);
        break;
      case "component":
        elements = filterComponents(data);
        break;
      case "event":
        elements = filterEvents(data);
        break;
      default:
        break;
    }
    const elementsFiltered = await pointsWithinPolygon({
      dataPolygon: polygon,
      data: elements,
    });
    return elementsFiltered;
  } catch (error) {
    console.log("Error getting polygon data", error);
    return responseOnError;
  }
};

//filter just the object from other data
const filterObjects = async (data) => {
  if (!data.length || data === undefined) return [];
  const res = await data.map((obj) => {
    return {
      id: obj.properties.object.id,
      location: obj.properties.object.location,
    };
  });
  return res;
};
const filterComponents = (data) => {
  if (!data.length || data === undefined) return [];
  return data.map((obj) => {
    return {
      id: obj.properties.object.id,
      location: obj.properties.object.location,
    };
  });
};
const filterEvents = (data) => {
  if (!data.length || data === undefined) return [];
  return data.map((obj) => {
    return {
      id: obj.properties.object.id,
      location: obj.properties.object.location,
    };
  });
};

//filter only coordinates within the polygon
const pointsWithinPolygon = async ({ dataPolygon, data }) => {
  const poly = polygon(dataPolygon.coordinates);
  const res = await data
    .map((pnt) => {
      const pt = point([pnt.location.longitude, pnt.location.latitude]);
      const isInPolygon = booleanPointInPolygon(pt, poly);
      if (isInPolygon) return pnt;
      return null;
    })
    .filter((it) => it !== null);
  return res;
};
