import { useDispatch, useSelector } from "react-redux";
import {
  setFilterComponent,
  setNeuronSelectTool,
  setShowDataComponents,
  setShowDataComponentsByType,
  setShowDataComponentsLibrary,
  setShowEvents,
  setShowEventsByLibrary,
  setStateComponentFilterTable,
  setStateEventFilterTable,
  setStateFilterButtonTable,
  setStateObjectFilterTable,
  showEventsByInspectionType,
  showEventsByType,
  showOperationsByType,
} from "redux/actions/digitalTwin";
import {
  setFiltersEventsToFalse,
  setFiltersEventsToTrue,
  setFiltersFalse,
  setFiltersTrue,
} from "../components/Tables/GenericTable/helpers/setGlobalFilterPoints";
import { useEffect, useState } from "react";
import {
  setComponentsFilterTable,
  setDataEventFilterTable,
  setObjectsFilterTable,
  setShowDataObjects,
  showMarkerByElementWithMedia,
  showMarkerseByTypeElement,
  showMarkerseByTypeObject,
} from "redux/actions/admin";
import handleExtendView from "../components/Tables/GenericTable/helpers/handleExtendView";
import { PRIMARY_COLOR, VISIBILITY_OFF_ICON } from "utils/const";
import findTypeLinesTypeObjects from "services/typeLinesTypeObjects/find";

export default function useViewFilterClusterMap({
  setTableVisibility,
  setButtonFilters,
  typeOfTable,
  typeOfLibrary,
  idFilters,
  handleDrawerClose,
  handleDrawerOpen,
  lineTypeId,
}) {
  const dispatch = useDispatch();

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );
  const showOperationByType = useSelector(
    (state) => state.digitalTwinReducer.showOperationsByType
  );
  const showAllEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );
  const showEventsByLibrary = useSelector(
    (state) => state.digitalTwinReducer.showEventsByLibrary
  );

  const showMarkersByLibrary = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );

  const showDataComponentsByType = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsByType
  );
  const showDataComponentsLibrary = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsLibrary
  );

  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  const filterOperations = useSelector(
    (state) => state.digitalTwinReducer.filteredOperations
  );
  const dataComponentsPolygon = useSelector(
    (state) => state.adminReducer.dataComponentPolygon
  );
  const allObjects = useSelector((state) => state.adminReducer.dataObjects);

  const currentMap = useSelector((state) => state.digitalTwinReducer.map);

  const [eventByInspectionType, setEventByInspectionType] = useState([]);
  const [operationByType, setOperationByType] = useState([]);
  const [allEventByType, setAllEventsByType] = useState([]);
  const [eventsByLibrary, setAllEventsByLibrary] = useState([]);

  const [markersByLibrary, setMarkersByLibrary] = useState([]);
  const [markersByTypeElement, setMarkersByTypeElement] = useState([]);

  const [dataComponentsByType, setDataComponentsByType] = useState([]);
  const [dataComponentsLibrary, setDataComponentsLibrary] = useState([]);

  useEffect(() => {
    setEventByInspectionType(showEventByInspectionType);
    setOperationByType(showOperationByType);
    setAllEventsByType(showAllEventsByType);
    setAllEventsByLibrary(showEventsByLibrary);

    setMarkersByLibrary(showMarkersByLibrary);
    setMarkersByTypeElement(showMarkersByTypeElement);

    setDataComponentsByType(showDataComponentsByType);
    setDataComponentsLibrary(showDataComponentsLibrary);
  }, [
    showEventByInspectionType,
    showOperationByType,
    showAllEventsByType,
    showEventsByLibrary,
    showMarkersByLibrary,
    showMarkersByTypeElement,
    showDataComponentsByType,
    showDataComponentsLibrary,
  ]);

  const viewInMap = () => {
    setTableVisibility("none");
    setButtonFilters(false);
    dispatch(setStateFilterButtonTable(true));
    if (typeOfTable === "objects") {
      const env1 = setFiltersEventsToFalse(eventByInspectionType);
      const env2 = setFiltersEventsToFalse(operationByType);
      const env3 = setFiltersFalse(allEventByType);
      const env4 = setFiltersFalse(eventsByLibrary);
      const componentsByType = setFiltersFalse(dataComponentsByType);
      const componentsByLibrary = setFiltersFalse(dataComponentsLibrary);
      const currentDataTable = [];
      dataObjectsPolygon.forEach((elm) => {
        idFilters.forEach((elm2) => {
          if (elm.id === elm2) {
            currentDataTable.push(elm);
          }
        });
      });
      dispatch(
        setShowEvents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(setObjectsFilterTable(currentDataTable));
      dispatch(setStateObjectFilterTable(true));
      handleExtendView({ array: currentDataTable, map: currentMap });
      handleDrawerClose();
    }
    if (typeOfTable === "events") {
      const currentDataEventsTable = [];
      const currentDataOperationTable = [];
      const objects = setFiltersFalse(markersByLibrary);
      const obj = setFiltersFalse(markersByTypeElement);
      const componentsByType = setFiltersFalse(dataComponentsByType);
      const componentsByLibrary = setFiltersFalse(dataComponentsLibrary);
      filterOperations.forEach((elm) => {
        idFilters.forEach((elm2) => {
          if (elm.properties.event.id === elm2) {
            if (
              elm.properties.event.typeOperation?.name === "Inspección flir" ||
              elm.properties.event.typeOperation?.name === "Leak" ||
              elm.properties.event.typeOperation?.name === "Sketch" ||
              elm.properties.event.typeOperation?.name === "Reparación"
            ) {
              return currentDataOperationTable.push(elm);
            } else if (elm.properties.event.hasOwnProperty("pointTypeEvent")) {
              return currentDataEventsTable.push(elm);
            }
          }
        });
      });
      const currentDataTable =
        typeOfLibrary === "Inspection Flir" ||
        typeOfLibrary === "Leak" ||
        typeOfLibrary === "Sketch" ||
        typeOfLibrary === "Repair"
          ? currentDataOperationTable
          : currentDataEventsTable;
      dispatch(setDataEventFilterTable(currentDataTable));
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(
        setShowDataObjects({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(showMarkerByElementWithMedia(true));
      handleExtendView({ array: currentDataTable, map: currentMap });
      dispatch(setStateEventFilterTable(true));
      handleDrawerClose();
    }
    if (typeOfTable === "components") {
      const env1 = setFiltersEventsToFalse(eventByInspectionType);
      const env2 = setFiltersEventsToFalse(operationByType);
      const env3 = setFiltersFalse(allEventByType);
      const env4 = setFiltersFalse(eventsByLibrary);
      const objects = setFiltersFalse(markersByLibrary);
      const obj = setFiltersFalse(markersByTypeElement);

      const currentDataTable = [];
      dataComponentsPolygon.forEach((elm) => {
        idFilters.forEach((elm2) => {
          if (elm.id === elm2) {
            currentDataTable.push(elm);
          }
        });
      });
      dispatch(
        setShowEvents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(
        setShowDataObjects({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(setComponentsFilterTable(currentDataTable));
      dispatch(setFilterComponent(true));
      dispatch(setStateComponentFilterTable(true));
      handleExtendView({ array: currentDataTable, map: currentMap });
      handleDrawerClose();
    }
  };

  const setViewInMap = () => {
    setButtonFilters(true);
    setTableVisibility("inline");
    dispatch(setStateFilterButtonTable(false));
    if (typeOfTable === "objects") {
      dispatch(setObjectsFilterTable([]));
      const env1 = setFiltersEventsToTrue(eventByInspectionType);
      const env2 = setFiltersEventsToTrue(operationByType);
      const env3 = setFiltersTrue(allEventByType);
      const env4 = setFiltersTrue(eventsByLibrary);
      const componentsByType = setFiltersTrue(dataComponentsByType);
      const componentsByLibrary = setFiltersTrue(dataComponentsLibrary);
      dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
      handleExtendView({ array: dataObjectsPolygon, map: currentMap });
      dispatch(setStateObjectFilterTable(false));
      handleDrawerOpen();
    }

    if (typeOfTable === "events") {
      dispatch(setDataEventFilterTable([]));
      const objects = setFiltersTrue(markersByLibrary);
      const obj = setFiltersTrue(markersByTypeElement);
      const componentsByType = setFiltersTrue(dataComponentsByType);
      const componentsByLibrary = setFiltersTrue(dataComponentsLibrary);
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
      dispatch(showMarkerByElementWithMedia(false));
      handleExtendView({ array: filterOperations, map: currentMap });
      dispatch(setStateEventFilterTable(false));
      handleDrawerOpen();
    }

    if (typeOfTable === "components") {
      dispatch(setComponentsFilterTable([]));
      const env1 = setFiltersEventsToTrue(eventByInspectionType);
      const env2 = setFiltersEventsToTrue(operationByType);
      const env3 = setFiltersTrue(allEventByType);
      const env4 = setFiltersTrue(eventsByLibrary);
      const objects = setFiltersTrue(markersByLibrary);
      const obj = setFiltersTrue(markersByTypeElement);
      dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
      dispatch(setFilterComponent(true));
      dispatch(setStateComponentFilterTable(false));
      handleExtendView({ array: dataComponentsPolygon, map: currentMap });
      handleDrawerOpen();
    }
  };

  const handleFilterPointsCreateLine = async () => {
    const typeElementAllow = await findTypeLinesTypeObjects({
      typeLineId: lineTypeId,
    });

    const dataFilter = typeElementAllow.filter((elm) => {
      const result = allObjects.some((item) => {
        return elm.typeElementId === item.typeElementId;
      });
      return result;
    });

    const env1 = setFiltersEventsToFalse(eventByInspectionType);
    const env2 = setFiltersEventsToFalse(operationByType);
    const env3 = setFiltersFalse(allEventByType);
    const env4 = setFiltersFalse(eventsByLibrary);
    const componentsByType = setFiltersFalse(dataComponentsByType);
    const componentsByLibrary = setFiltersFalse(dataComponentsLibrary);
    const currentDataTable = [];
    allObjects.forEach((elm) => {
      dataFilter.forEach((elm2) => {
        if (elm.typeElementId === elm2.typeElementId) {
          currentDataTable.push(elm);
        }
      });
    });
    dispatch(setShowEvents({ color: `${VISIBILITY_OFF_ICON}`, state: false }));
    dispatch(showEventsByInspectionType(env1));
    dispatch(showOperationsByType(env2));
    dispatch(showEventsByType(env3));
    dispatch(setShowEventsByLibrary(env4));
    dispatch(setShowDataComponentsByType(componentsByType));
    dispatch(setShowDataComponentsLibrary(componentsByLibrary));
    dispatch(
      setShowDataComponents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
    );
    dispatch(setObjectsFilterTable(currentDataTable));
    dispatch(setStateObjectFilterTable(true));
  };

  const handleSetFilterPoints = () => {
    dispatch(setObjectsFilterTable([]));
    const env1 = setFiltersEventsToTrue(eventByInspectionType);
    const env2 = setFiltersEventsToTrue(operationByType);
    const env3 = setFiltersTrue(allEventByType);
    const env4 = setFiltersTrue(eventsByLibrary);
    const objects = setFiltersTrue(markersByLibrary);
    const obj = setFiltersTrue(markersByTypeElement);
    const componentsByType = setFiltersTrue(dataComponentsByType);
    const componentsByLibrary = setFiltersTrue(dataComponentsLibrary);
    dispatch(showMarkerseByTypeObject(objects));
    dispatch(showMarkerseByTypeElement(obj));
    dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
    dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
    dispatch(showEventsByInspectionType(env1));
    dispatch(showOperationsByType(env2));
    dispatch(showEventsByType(env3));
    dispatch(setShowEventsByLibrary(env4));
    dispatch(setShowDataComponentsByType(componentsByType));
    dispatch(setShowDataComponentsLibrary(componentsByLibrary));
    dispatch(setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true }));
    dispatch(setStateObjectFilterTable(false));
    dispatch(
      setNeuronSelectTool({
        show: false,
        top: null,
        right: null,
      })
    );
  };

  return {
    setViewInMap,
    viewInMap,
    handleFilterPointsCreateLine,
    handleSetFilterPoints,
  };
}
