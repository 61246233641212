/**
 * The `uniqueObjsArr` function takes an array `arr` as input and two optional parameters `encoder` and `decoder`, which default to `JSON.stringify` and `JSON.parse` respectively.
 * Returns an array of unique values using Set and a default encoder / decoder  ( JSON )
 * @function
 * @name uniqueObjsArr
 * @param {Array} arr - Array of values <any>
 * @param {Object} encoder - default JSON.stringify
 * @param {Object} decoder - default JSON.parse
 * @returns {Array} - Retuns [] || [...]
 *
 * @example
 * const arr = [{id:1},{id:1},{id:2}]
 * uniqueObjsArr(arr) → [{id:1},{id:2}]
 */
const uniqueObjsArr = (arr, encoder = JSON.stringify, decoder = JSON.parse) =>
  [...new Set(arr.map((item) => encoder(item)))].map((item) => decoder(item));

export default uniqueObjsArr;
