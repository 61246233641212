import Typography from "@mui/material/Typography";
// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// custom style
import { useStyles, CustomizedAccordion } from "../AccordionStyles";

// redux
import { useSelector } from "react-redux";

import FilterEvents from "./DataPointsFilter/FilterEvents";
import FilterComponents from "./DataPointsFilter/FilterComponents";
import FilterObjects from "./DataPointsFilter/FilterObjects";

const PointsDigitalTwin = ({ dataObjects }) => {
  const classes = useStyles();

  const stateObjectFilterTable = useSelector(
    (state) => state.digitalTwinReducer.stateObjectFilterTable
  );
  const stateEventFilterTable = useSelector(
    (state) => state.digitalTwinReducer.stateEventFilterTable
  );
  const stateComponentFilterTable = useSelector(
    (state) => state.digitalTwinReducer.stateComponentFilterTable
  );

  return (
    <>
      <CustomizedAccordion
        className="background-2 border-color-1"
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        sx={{
          paddingLeft: "0px",
          border: "none",
          boxShadow: "none",
        }}
        defaultExpanded={false}
        style={{ borderRadius: "4px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            className="colo1"
            style={{
              fontSize: "1.5rem",
              fontFamily: document.body.style.fontFamily,
              color: document.body.style.color,
              fontWeight: "bold",
            }}
          >
            <p
              style={{
                marginLeft: "2rem",
              }}
            >
              Points
            </p>
          </Typography>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="color1" />}
          ></AccordionSummary>
        </div>
        {stateObjectFilterTable ? <></> : <FilterObjects />}
        {stateComponentFilterTable ? <></> : <FilterComponents />}
        {stateEventFilterTable ? <></> : <FilterEvents />}
      </CustomizedAccordion>
    </>
  );
};

export default PointsDigitalTwin;
