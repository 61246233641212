import axios from "axios";
import { config } from "config.js";

const uploadFieldVideo = async (body) => {
  try {
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/field-video`,
      body
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default uploadFieldVideo;
