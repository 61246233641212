import {
  LineContainerForm,
  TextLine,
} from "components/Forms/SaveLines/LineRelationsStyles";
import { DetailTypeTitle } from "./ImageDetailsElements";
import {
  useFetchComponentsLibraries,
  useFetchEventLibraries,
  useFetchObjectLibraries,
} from "hooks/fetchLibraries";
import { useContext } from "react";
import { ThemeContext } from "App";
import { BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR } from "utils/const";

export default function DetailTypeIformation({
  type,
  typeName,
  typeLibrary,
  iconType,
  dataLine,
  icon,
  iconLibrary,
}) {
  const Line = (props) => {
    const { color, width, separator } = props;

    return (
      <LineContainerForm separator={separator} width={width} color={color}>
        <TextLine>Line preview:</TextLine>
        <div className="container">
          <span className="circle"></span>
          <span className="line"></span>
          <span className="separator"></span>
          <span className="line"></span>
          <span className="separator"></span>
          <span className="line"></span>
          <span className="circle"></span>
        </div>
      </LineContainerForm>
    );
  };
  const { theme } = useContext(ThemeContext);
  return (
    <DetailTypeTitle>
      <div className="detail-container">
        <div className="type-container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
              }}
            >
              Library: <span>{typeLibrary} </span>
            </h1>

            {type === "Line" ||
            type === "Component" ||
            type === "Event" ||
            type === "Object" ? (
              iconLibrary !== undefined ? (
                <img
                  width="30"
                  src={iconLibrary}
                  alt=""
                  className="dynamicImg"
                />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="type-container">
          <h1
            style={{
              color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
            }}
          >
            Type: <span>{typeName}</span>
          </h1>
          {iconType !== undefined ? (
            <img width="30" src={iconType} alt="" className="dynamicImg" />
          ) : (
            <></>
          )}
          {icon !== undefined && (
            <img
              style={{ width: "50px" }}
              src={icon}
              alt=""
              className="dynamicImg"
            />
          )}
        </div>

        {type === "Line" && (
          <div>
            <Line
              color={dataLine?.lineType?.color || dataLine?.color}
              width={dataLine?.lineType?.dasharrayWidth || dataLine?.width}
              separator={
                dataLine?.lineType?.dasharrayPixels || dataLine?.separator
              }
            />
          </div>
        )}
      </div>
    </DetailTypeTitle>
  );
}
