import { useDispatch } from "react-redux";
import {
  setDataRelationObject,
  setDatabaseRelationObject,
  setInfoObjectRelation,
  setModalRelationObject,
  setObjectRelationObject,
  setSeeObjectChildRelation,
} from "redux/actions/digitalTwin";
import { InfoRelation } from "./indexStyle";
import CustomButton from "components/Buttons/CustomButton";
import { useSelector } from "react-redux";
import useViewFilterClusterMap from "hooks/useViewFilterClusterMap.jsx";
import { useEffect, useState } from "react";
import { WraperTitleCardMarker } from "components/ElementDetails/ImageDetailsElements.jsx";
import findObjectRelation from "services/objects/findObjectRelation";
import deleteObjectRelation from "services/objects/deleteObjectRelation";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DialogRelatedObjects = ({ openInfo, setOpenInfo }) => {
  const dispatch = useDispatch();

  //Reset all objects/components and events
  const { handleSetFilterPoints } = useViewFilterClusterMap({});
  //State for save info of the objects
  const [dataBaseInfo, setDataBaseInfo] = useState([]);
  //Activate dialog with information
  const infoRelationObject = useSelector(
    (state) => state.digitalTwinReducer.infoRelationObject
  );

  //Info dialog

  const dataRelationObject = useSelector(
    (state) => state.digitalTwinReducer.dataRelationObject
  );
  //Info belongo object selected
  const ObjetSelected = useSelector(
    (state) => state.digitalTwinReducer.objectRelationObject.objectId
  );

  const handleQuitInfo = () => {
    setOpenInfo(false);
    dispatch(setInfoObjectRelation({ showInfoObject: false }));

    dispatch(setModalRelationObject({ show: false }));
    dispatch(setDataRelationObject([]));
    dispatch(setObjectRelationObject({ objectId: "" }));
    dispatch(setDatabaseRelationObject([])); ///////////////
    handleSetFilterPoints();
    //handleQuitPopUp();
  };

  useEffect(() => {
    if (infoRelationObject.showInfoObject) {
      setOpenInfo(true);
    } else {
      setOpenInfo(false);
    }
  }, [infoRelationObject.showInfoObject]);

  //Get the relationships
  const fetchRelations = async () => {
    try {
      // Call to service to get the relationships
      const data = await findObjectRelation({
        point_object_relation_id: ObjetSelected.id,
      });
      setDataBaseInfo(data);
    } catch (error) {
      console.error("Error fetching related objects:", error);
    }
  };

  useEffect(() => {
    fetchRelations();
  }, [ObjetSelected.id, dataRelationObject, dispatch]);

  //Delete relationship
  const handleDeleteRelation = async (objectSave) => {
    try {
      // Call to service to delete the relationship
      await deleteObjectRelation(objectSave);
      // Update the list of relationships
      fetchRelations();
    } catch (error) {
      console.error("Error deleting relationship:", error);
    }
  };

  //see object son

  const handleSeeObjectSon = (objectId) => {
    dispatch(
      setSeeObjectChildRelation({
        showChild: true,
        objectLook: objectId[0],
      })
    );

    //Reset states after 6 seconds
    setTimeout(() => {
      dispatch(setSeeObjectChildRelation({ showChild: false, objectLook: "" }));
    }, 6000);
  };

  //Set the object relation
  useEffect(() => {
    if (dataBaseInfo.length > 0 && infoRelationObject.showInfoObject) {
      dispatch(setDatabaseRelationObject(dataBaseInfo));
    } else {
      dispatch(setDatabaseRelationObject([]));
    }
  }, [dataBaseInfo, dispatch, infoRelationObject.showInfoObject]);

  return (
    <>
      {openInfo && (
        <InfoRelation className="background-2 border-color-1">
          <div>
            <WraperTitleCardMarker className="color1">
              <h3 className="title">Relationship between objects</h3>
            </WraperTitleCardMarker>
            <hr />
            <span>
              Click on the object you want to relate and press the "save" button
              to continue saving more objects or "save and quit" to save and
              finish.
            </span>
            <hr />

            {dataBaseInfo.length > 0 && (
              <div>
                <span>
                  Objects related to the object{" "}
                  <strong>{ObjetSelected.typeElement.name} </strong>with Id{" "}
                  {ObjetSelected.id}:
                </span>

                <ul className="spanList">
                  {dataBaseInfo.map((object, index) => (
                    <li key={index}>
                      <span>
                        {object.objectSave.typeElement.name} with Id{" "}
                        {object.objectSave.id}
                      </span>
                      <div className="itemContainer">
                        <div className="textContent">
                          {object.pointObject.map(
                            (objectDataBase) => objectDataBase.typeElement.name
                          )}{" "}
                          with Id{" "}
                          {object.pointObject.map(
                            (objectDataBase) => objectDataBase.id
                          )}
                        </div>
                        {/**Button */}
                        <div className="iconContainer">
                          <DeleteIcon
                            onClick={() =>
                              handleDeleteRelation(object.relationId)
                            }
                            sx={{
                              cursor: "pointer",
                              color: "red",
                              "&:hover": {
                                color: "#000000",
                              },
                            }}
                          />
                          {/**Button to see object*/}
                          <VisibilityIcon
                            onClick={() =>
                              handleSeeObjectSon(
                                object.pointObject.map(
                                  (objectDataBase) => objectDataBase.id
                                )
                              )
                            }
                            sx={{
                              cursor: "pointer",
                              color: "#0E4D45",
                              "&:hover": {
                                color: "#31A636", // Cambia el color en hover para mejor interacción
                              },
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <span className="spanNumberObjects">
                  <hr />
                  {""}
                </span>
              </div>
            )}
          </div>

          <CustomButton
            type={"button"}
            onClick={handleQuitInfo}
            text={"Quit"}
            margin={0}
          />
        </InfoRelation>
      )}
    </>
  );
};

export default DialogRelatedObjects;
