import styled, { css } from "styled-components";
import {
  WHITE_COLOR,
  BORDER_GREY,
  GRAY_COLOR,
  PRIMARY_COLOR,
  WHITE_SECOND_COLOR,
  SECOND_COLOR,
  BACKGROUND_SECOND_GREY,
  BACKGROUD_GREY_PREVIEW,
} from "utils/const";

export const PrintOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: ${WHITE_COLOR};
  border-radius: 10px;
  width: 100%;
  max-height: 75vh;
  .section-chart-button {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 68%;
    margin-right: 10px;
    max-height: 75vh;
  }

  .preview-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${BACKGROUD_GREY_PREVIEW};
    width: 100%;
    height: 95%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
  }

  .chart {
    width: 100%;
  }

  .container-update {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${WHITE_COLOR};
    width: 100%;
    height: 5%;
    margin-right: 10px;
    border-radius: 10px;
  }

  .editing-tools {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 32%;
    background: ${BACKGROUD_GREY_PREVIEW};
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
  }

  .groupe-styles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: ${WHITE_COLOR};
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .sections {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 5px;
      color: ${WHITE_COLOR};
      background: ${PRIMARY_COLOR};
      margin-bottom: 15px;
      height: 40px;
    }
  }

  .section-style {
    display: flex;
    flex-wrap: wrap;
    margin: 2px;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    .alias {
      width: 100%;
      align-self: center;
      padding: 10px;
    }
    p {
      font-size: 15px;
    }

    h4 {
      color: ${GRAY_COLOR};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: end;
    }

    .input-title {
      width: 100%;
      height: 43px;
      padding: 5px 5px 0px 5px;
      margin: 0px 5px 0px 5px;
      border: solid 3px #b7b7b7;
      border-radius: 5px;
      background: ${WHITE_COLOR};
      text-align: center;
      font-weight: 900;
    }

    .input-title:focus {
      outline: none;
    }

    input[type="number"] {
      width: 38%;
      height: 18px;
      margin: 10px;
      text-align: center;
      border: none;
      background-color: ${BACKGROUND_SECOND_GREY};
      font-weight: 600;
    }

    input[type="checkbox"] {
      width: 45%;
      height: 20px;
    }

    input[type="range"] {
      margin: 10px;
      width: 59%;
    }

    input[type="color"] {
      border: none;
      width: 45%;
      height: 37px;
      background-color: ${WHITE_COLOR};
      cursor: pointer;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: 2px solid ${BACKGROUND_SECOND_GREY};
      border-radius: 5px;
    }

    select {
      border: none;
      width: 40%;
      margin: 7px;
      height: 25px;
      border-radius: 5px;
      text-align: center;
      color: ${WHITE_COLOR};
      background-color: ${PRIMARY_COLOR};
      cursor: pointer;
    }
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 68%;
  margin-right: 10px;
  max-height: 78vh;
  height: 100%;
  .pdf-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${BACKGROUD_GREY_PREVIEW};
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 32%;
  background: ${BACKGROUD_GREY_PREVIEW};
  border-radius: 10px;
  padding: 20px;
  overflow: auto;

  .options-section {
    width: 100%;
    min-height: max-content;
    border-radius: 10px;
    background-color: ${WHITE_COLOR};
    padding-bottom: 10px;
  }

  .option-div {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
  }

  .option-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .option-name {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }

  span {
    position: sticky;
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 5px;
      color: ${WHITE_COLOR};
      background: ${PRIMARY_COLOR};
      margin-bottom: 15px;
      height: 40px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .report-download {
    min-width: 200px;
    width: 100%;
    height: auto;
    padding: 10px 0px;
    border-radius: 5px;
    text-decoration: none;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_SECOND_COLOR};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .report-download:hover {
    min-width: 200px;
    width: 100%;
    height: auto;
    padding: 10px 20px;
    background-color: ${SECOND_COLOR};
  }
`;

export const Input = styled.input`
  min-width: 300px;
  width: 100%;
  background: #f1f1f1;
  border-radius: 10px;
  height: 40px;
  padding: 10px 40px 10px 20px;
  /* border-bottom: 1px solid #2e2e2e; */
  display: flex;
  font-size: 1.5rem;

  &.little {
    transform: scale(0.5);
    margin-top: 0;
  }

  &:focus {
    border: 3px solid ${BORDER_GREY};
    outline: none;
    /* box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4); */
  }

  ${(props) =>
    props.valid === "true" &&
    css`
      border: 3px solid transparent;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${BORDER_GREY} !important;
    `}
`;
