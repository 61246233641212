// React
import { useState } from "react";
// Dependecies
import { useSWRConfig } from "swr";
// Services
import updateObjectPg from "services/updateObjectPg";
import updatePointEvent from "services/updatePointEvent";
import updatePointComponent from "services/updatePointComponent";
// confing - const
import { config } from "config.js";
import { urlKeys } from "utils/urlKeys";

export default function useUpdateLocationPoint({ type, point }) {
  const { mutate } = useSWRConfig();
  const [updateLongitude, setUpdateLongitude] = useState(false);
  const [updateLatitude, setUpdateLatitude] = useState(false);
  const [updateLocation, setUpdateLocation] = useState({
    longitude:
      type === "object"
        ? parseFloat(point.location.longitude)
        : type === "event"
        ? point.locationOperation.longitude || 0
        : type === "component"
        ? point?.locationComponent?.longitude || 0
        : 0,
    latitude:
      type === "object"
        ? parseFloat(point.location.latitude)
        : type === "event"
        ? point.locationOperation.latitude || 0
        : type === "component"
        ? point?.locationComponent?.latitude || 0
        : 0,
  });

  let handleUpdateLocation;
  let handleCancelUpdate;

  if (type === "object") {
    handleUpdateLocation = ({
      coordinate,
      oldLocation,
      marks = [],
      eventsComponents = [],
      componentsObject = [],
      id,
    }) => {
      if (coordinate && coordinate === "longitude") setUpdateLongitude(false);
      if (coordinate && coordinate === "latitude") setUpdateLatitude(false);
      updateObjectPg(
        {
          location: {
            longitude: parseFloat(updateLocation.longitude),
            latitude: parseFloat(updateLocation.latitude),
          },
        },
        point.id
      );
      if (marks.length > 0) {
        marks.forEach(async (event) => {
          const newLongitude =
            event.locationOperation.longitude -
            oldLocation.longitude +
            updateLocation.longitude;
          const newLatitude =
            event.locationOperation.latitude -
            oldLocation.latitude +
            updateLocation.latitude;
          await updatePointEvent({
            id: event.id,
            body: {
              locationOperation: {
                longitude: newLongitude,
                latitude: newLatitude,
              },
            },
          });
          mutate(`${config.URL_BACKEND_PG}api/v1/point-events?id=${event.id}`);
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${event.id}`
          );
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${event.id}`
          );
          mutate(urlKeys.eventsEvents);
          mutate(urlKeys.eventsComponents);
          mutate(urlKeys.eventsObjects);
        });
      }
      if (componentsObject.length > 0) {
        componentsObject.forEach(async (component) => {
          const newLongitude =
            component.locationComponent.longitude -
            oldLocation.longitude +
            updateLocation.longitude;
          const newLatitude =
            component.locationComponent.latitude -
            oldLocation.latitude +
            updateLocation.latitude;
          await updatePointComponent({
            id: component?.id,
            body: {
              locationComponent: {
                longitude: newLongitude,
                latitude: newLatitude,
              },
            },
          });
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-components?id=${component.id}`
          );
          mutate(urlKeys.components);
        });
      }
      if (eventsComponents.length > 0) {
        eventsComponents.forEach(async (operation) => {
          const newLongitude =
            operation.locationOperation.longitude -
            oldLocation.longitude +
            updateLocation.longitude;
          const newLatitude =
            operation.locationOperation.latitude -
            oldLocation.latitude +
            updateLocation.latitude;
          await updatePointEvent({
            id: operation.id,
            body: {
              locationOperation: {
                longitude: newLongitude,
                latitude: newLatitude,
              },
            },
          });

          if (operation.PointEventRelation.length > 0) {
            operation.PointEventRelation.forEach(async (relation) => {
              if (operation.id === relation.PointEventRelations.pointEventId) {
                const newLongitude =
                  relation.locationOperation.longitude -
                  oldLocation.longitude +
                  updateLocation.longitude;
                const newLatitude =
                  relation.locationOperation.latitude -
                  oldLocation.latitude +
                  updateLocation.latitude;
                await updatePointEvent({
                  id: relation.id,
                  body: {
                    locationOperation: {
                      longitude: newLongitude,
                      latitude: newLatitude,
                    },
                  },
                });
                mutate(
                  `${config.URL_BACKEND_PG}api/v1/point-events?id=${relation.id}`
                );
                mutate(
                  `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${relation.id}`
                );
                mutate(
                  `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${relation.id}`
                );
                mutate(urlKeys.eventsEvents);
                mutate(urlKeys.eventsComponents);
                mutate(urlKeys.eventsObjects);
              }
            });
          }

          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events?id=${operation.id}`
          );
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${operation.id}`
          );
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${operation.id}`
          );
          mutate(urlKeys.eventsEvents);
          mutate(urlKeys.eventsComponents);
          mutate(urlKeys.eventsObjects);
        });
      }
      mutate(`${config.URL_BACKEND_PG}api/v1/objects/${id}`);
      mutate(urlKeys.objects);
    };

    handleCancelUpdate = ({ coordinate }) => {
      if (coordinate === "longitude") {
        setUpdateLocation((prev) => ({
          ...prev,
          longitude: parseFloat(point.location.longitude),
        }));
        setUpdateLongitude(false);
      }
      if (coordinate === "latitude") {
        setUpdateLocation((prev) => ({
          ...prev,
          latitude: parseFloat(point.location.latitude),
        }));
        setUpdateLatitude(false);
      }
    };
  }

  if (type === "event") {
    handleUpdateLocation = ({
      coordinate,
      oldLocation,
      pointsEventRelations = [],
      id
    }) => {
      if (coordinate && coordinate === "longitude") setUpdateLongitude(false);
      if (coordinate && coordinate === "latitude") setUpdateLatitude(false);
      updatePointEvent({
        id: point.id,
        body: {
          locationOperation: {
            longitude: parseFloat(updateLocation.longitude),
            latitude: parseFloat(updateLocation.latitude),
          },
        },
      });

      if (pointsEventRelations.length > 0) {
        pointsEventRelations.forEach(async (operation) => {
          const newLongitude =
            operation.locationOperation.longitude -
            oldLocation.longitude +
            updateLocation.longitude;
          const newLatitude =
            operation.locationOperation.latitude -
            oldLocation.latitude +
            updateLocation.latitude;
          await updatePointEvent({
            id: operation.id,
            body: {
              locationOperation: {
                longitude: newLongitude,
                latitude: newLatitude,
              },
            },
          });
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events?id=${operation.id}`
          );
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${operation.id}`
          );
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${operation.id}`
          );
          mutate(urlKeys.eventsEvents);
          mutate(urlKeys.eventsComponents);
          mutate(urlKeys.eventsObjects);
        });
      }

      mutate(`${config.URL_BACKEND_PG}api/v1/point-events?id=${id}`);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${id}`
      );
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${id}`
      );
      mutate(urlKeys.eventsEvents);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsObjects);
    };

    handleCancelUpdate = ({ coordinate }) => {
      if (coordinate === "longitude") {
        setUpdateLocation((prev) => ({
          ...prev,
          longitude: parseFloat(point.locationOperation.longitude),
        }));
        setUpdateLongitude(false);
      }
      if (coordinate === "latitude") {
        setUpdateLocation((prev) => ({
          ...prev,
          latitude: parseFloat(point.locationOperation.latitude),
        }));
        setUpdateLatitude(false);
      }
    };
  }

  if (type === "component") {
    handleUpdateLocation = ({
      coordinate,
      oldLocation,
      pointsEventRelations = [],
      id
    }) => {
      if (coordinate && coordinate === "longitude") setUpdateLongitude(false);
      if (coordinate && coordinate === "latitude") setUpdateLatitude(false);
      updatePointComponent({
        id: point?.id,
        body: {
          locationComponent: {
            longitude: parseFloat(updateLocation.longitude),
            latitude: parseFloat(updateLocation.latitude),
          },
        },
      });
      if (pointsEventRelations.length > 0) {
        pointsEventRelations.forEach(async (operation) => {
          const newLongitude =
            operation.locationOperation.longitude -
            oldLocation.longitude +
            updateLocation.longitude;
          const newLatitude =
            operation.locationOperation.latitude -
            oldLocation.latitude +
            updateLocation.latitude;
          await updatePointEvent({
            id: operation.id,
            body: {
              locationOperation: {
                longitude: newLongitude,
                latitude: newLatitude,
              },
            },
          });

          if (operation.PointEventRelation.length > 0) {
            operation.PointEventRelation.forEach(async (relation) => {
              if (operation.id === relation.PointEventRelations.pointEventId) {
                const newLongitude =
                  relation.locationOperation.longitude -
                  oldLocation.longitude +
                  updateLocation.longitude;
                const newLatitude =
                  relation.locationOperation.latitude -
                  oldLocation.latitude +
                  updateLocation.latitude;
                await updatePointEvent({
                  id: relation.id,
                  body: {
                    locationOperation: {
                      longitude: newLongitude,
                      latitude: newLatitude,
                    },
                  },
                });
                mutate(
                  `${config.URL_BACKEND_PG}api/v1/point-events?id=${relation.id}`
                );
                mutate(
                  `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${relation.id}`
                );
                mutate(
                  `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${relation.id}`
                );
                mutate(urlKeys.eventsEvents);
                mutate(urlKeys.eventsComponents);
                mutate(urlKeys.eventsObjects);
              }
            });
          }
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events?id=${operation.id}`
          );
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${operation.id}`
          );
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${operation.id}`
          );
          mutate(urlKeys.eventsEvents);
          mutate(urlKeys.eventsComponents);
          mutate(urlKeys.eventsObjects);
        });
      }
      mutate(
        `${config.URL_BACKEND_PG}api/v1/type-events-type-components?adminCompanyId=${config.ADMIN_COMPANY_ID}&pointTypeComponentId=${id}`
      );
      mutate(`${config.URL_BACKEND_PG}api/v1/point-components?id=${id}`);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/components?pointComponentId=${id}`
      );
      mutate(urlKeys.components);
    };

    handleCancelUpdate = ({ coordinate }) => {
      if (coordinate === "longitude") {
        setUpdateLocation((prev) => ({
          ...prev,
          longitude: parseFloat(point?.locationComponent?.longitude),
        }));
        setUpdateLongitude(false);
      }
      if (coordinate === "latitude") {
        setUpdateLocation((prev) => ({
          ...prev,
          latitude: parseFloat(point?.locationComponent?.latitude),
        }));
        setUpdateLatitude(false);
      }
    };
  }

  return {
    data: { updateLongitude, updateLatitude, updateLocation },
    setData: { setUpdateLongitude, setUpdateLatitude, setUpdateLocation },
    handleUpdateLocation,
    handleCancelUpdate,
  };
}
