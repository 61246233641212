import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Chartsicon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="200"
        height="200"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`
            .cls-1chart {
              stroke: #d8d8d8;
              stroke-width: 3px;
            }
      
            .cls-1chart, .cls-2chart {
              fill: none;
              stroke-linecap: round;
              stroke-miterlimit: 10;
            }
      
            .cls-2chart {
              stroke: #4c4c4c;
              stroke-width: 9px;
            }
      
            .cls-3chart {
        fill: var(--background-primary_color);

            }
      
            .cls-3chart, .cls-4chart, .cls-5chart {
              stroke-width: 0px;
            }
      
            .cls-4chart {
        fill: var(--background-secondary_color);

            }
      
            .cls-5chart {
              fill: #d8d8d8;
            }
          `}
          </style>
        </defs>
        <g>
          <path
            class="cls-1chart"
            d="m84.82,277.5c27.29-59.69,62.82-87.41,91.43-101.63,38.77-19.26,66.74-14.65,104.2-37.88,36.79-22.81,56.3-56.07,67.22-82.45"
          />
          <polygon
            class="cls-5chart"
            points="355.01 69.48 352.18 70.5 347.17 56.58 333.66 62.6 332.44 59.85 348.9 52.53 355.01 69.48"
          />
        </g>
        <path
          class="cls-4chart"
          d="m100.47,256.98h12.51c2.96,0,5.36,2.4,5.36,5.36v70.72h-23.23v-70.72c0-2.96,2.4-5.36,5.36-5.36Z"
        />
        <path
          class="cls-3chart"
          d="m141.11,182.88h18.32c2.96,0,5.36,2.4,5.36,5.36v144.82h-29.03v-144.82c0-2.96,2.4-5.36,5.36-5.36Z"
        />
        <path
          class="cls-4chart"
          d="m187.55,207.57h12.51c2.96,0,5.36,2.4,5.36,5.36v120.13h-23.23v-120.13c0-2.96,2.4-5.36,5.36-5.36Z"
        />
        <path
          class="cls-3chart"
          d="m228.55,134.88h18.32c2.96,0,5.36,2.4,5.36,5.36v192.82h-29.03v-192.82c0-2.96,2.4-5.36,5.36-5.36Z"
        />
        <path
          class="cls-4chart"
          d="m274.36,156.82h12.51c2.96,0,5.36,2.4,5.36,5.36v170.62h-23.23v-170.62c0-2.96,2.4-5.36,5.36-5.36Z"
        />
        <path
          class="cls-3chart"
          d="m315.36,86.82h18.32c2.96,0,5.36,2.4,5.36,5.36v240.61h-29.03V92.18c0-2.96,2.4-5.36,5.36-5.36Z"
        />
        <line
          class="cls-2chart"
          x1="71.5"
          y1="332.79"
          x2="362.21"
          y2="332.79"
        />{" "}
      </svg>
    </>
  );
};

export default Chartsicon;
