// Style
import { ConfigComponentsContainer } from "./indexStyle";
// Hooks
import useFindComponents from "hooks/components/useFindComponents";

// Components
import Loading from "components/Lodings/Loading";
import FormText from "pages/Dashboard/components/FormText";
import FormLineChart from "pages/Dashboard/components/FormLineChartComponents";
import ComponentFormKpi from "pages/Dashboard/components/ComponentFormKpi";
import ComponentFormBar from "pages/Dashboard/components/ComponentFormBar";
import ComponentFormDoughnut from "pages/Dashboard/components/ComponentFormDoughnut";
import FormTimeChartComponents from "pages/Dashboard/components/FormTimeChartComponents";
import FormGroupBarChartCompoents from "pages/Dashboard/components/FormGroupBarChartComponents";
import FormTimeSeriesBarChartComponents from "pages/Dashboard/components/FormTimeSeriesBarChartComponents";
import FormImgChart from "pages/Dashboard/components/FormImgChart";
import ComponentFormGroupDoughnut from "pages/Dashboard/components/ComponentFormGroupDoughnut";

const ConfigComponents = ({ chartType, setOpenDialog }) => {
  // Fetch components data
  const { components, loading } = useFindComponents();

  return (
    <ConfigComponentsContainer>
      {components?.length > 0 && (
        <section>
          <div className="conatiner-add-chart">
            <h1 className="title">Chart settings</h1>
            {/* Kpi chart */}
            {chartType === "kpiChart" && (
              <ComponentFormKpi setOpenDialog={setOpenDialog} />
            )}
            {/* Bar chart */}
            {chartType === "barChart" && (
              <ComponentFormBar setOpenDialog={setOpenDialog} />
            )}
            {/* Doughnut chart */}
            {chartType === "doughnutChart" && (
              <ComponentFormDoughnut setOpenDialog={setOpenDialog} />
            )}
            {chartType === "groupDoughnutChart" && (
              <ComponentFormGroupDoughnut setOpenDialog={setOpenDialog} />
            )}
            {/* Line Chart */}
            {chartType === "lineChart" && (
              <FormLineChart setOpenDialog={setOpenDialog} />
            )}
            {/* Time Series Chart */}
            {chartType === "timeChart" && (
              <FormTimeChartComponents setOpenDialog={setOpenDialog} />
            )}
            {/* Group Bar Chart */}
            {chartType === "groupBarChart" && (
              <FormGroupBarChartCompoents setOpenDialog={setOpenDialog} />
            )}
            {/* Text chart */}
            {chartType === "textChart" && (
              <FormText from={"components"} setOpenDialog={setOpenDialog} />
            )}
            {/* Time Series Bar Chart */}
            {chartType === "timeSeriesBarChart" && (
              <FormTimeSeriesBarChartComponents setOpenDialog={setOpenDialog} />
            )}
            {chartType === "imgChart" && (
              <FormImgChart from={"components"} setOpenDialog={setOpenDialog} />
            )}
          </div>
        </section>
      )}
      {loading && <Loading height={"auto"} text={"loading..."} />}
    </ConfigComponentsContainer>
  );
};

export default ConfigComponents;
