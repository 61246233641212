import { getNumericValue } from "helpers/getNumericValue";

function filterEventsByLibraryId(events, libraryId) {
  return events.filter(
    (evt) => evt.pointTypeEvent.pointLibraryEventId === libraryId
  );
}

function processDatasets(filteredEvents, chart, groupedLabels) {
  return chart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: null }));

    filteredEvents.forEach((evt) => {
      const typeElementName = evt.attributes.find(
        (attr) => attr.name === chart.categories.name
      )?.value;
      if (typeElementName !== dataset.donutStyle.label) return;

      const value = evt.attributes.find(
        (attr) => attr.name === chart.columnX.name
      )?.value;
      const yValue = evt.attributes.find(
        (attr) => attr.name === chart.columnY
      )?.value;

      const numericValue = getNumericValue(yValue);

      const index = dataByLabel.findIndex((item) => item.label === value);
      if (index !== -1) {
        if (
          dataByLabel[index].value === null ||
          isNaN(dataByLabel[index].value)
        ) {
          dataByLabel[index].value = numericValue;
        } else {
          dataByLabel[index].value += numericValue;
        }
      }
    });

    return {
      ...dataset,
      data: dataByLabel.map((item) => item.value),
    };
  });
}

// Función principal para procesar datos
export function sumValues({ events, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const filteredEvents = filterEventsByLibraryId(events, chart.libraryId);
    const datasets = processDatasets(
      filteredEvents,
      chart,
      chart.columnX.values
    );

    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}

// Función para procesar datasets contando ocurrencias
function processCountDatasets(filteredEvents, timeSeriesChart, groupedLabels) {
  return timeSeriesChart.datasets.map((dataset) => {
    const dataByLabel = groupedLabels.map((label) => ({ label, value: 0 }));

    filteredEvents.forEach((evt) => {
      const typeElementName = evt.attributes.find(
        (attr) => attr.name === timeSeriesChart.categories.name
      )?.value;
      if (typeElementName !== dataset.donutStyle.label) return;

      const dataLabel = evt.attributes.find(
        (attr) => attr.name === timeSeriesChart.columnX.name
      )?.value;

      const index = dataByLabel.findIndex((item) => item.label === dataLabel);
      if (index !== -1) {
        dataByLabel[index].value += 1;
      }
    });

    return {
      ...dataset,
      data: dataByLabel.map((item) => item.value),
    };
  });
}

// Función principal para procesar datos con conteo
export function countByValues({ events, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const filteredEvents = filterEventsByLibraryId(events, chart.libraryId);
    const datasets = processCountDatasets(
      filteredEvents,
      chart,
      chart.columnX.values
    );
    return {
      ...chart,
      labels: chart.columnX.values,
      datasets,
    };
  });
}
