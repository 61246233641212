import axios from "axios";
import { config } from "config";

export const uploadCSVLines = async (body) => {
  const token = localStorage.getItem("token");
  try {
    let data = null;
    const url = `${config.URL_BACKEND_PG}api/v1/csv/lines`;
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
      })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        console.error("Error uploading CSV file", error);
      });
    return data;
  } catch (error) {
    console.error("Error uploading CSV line file", error);
  }
};
