import styled from "styled-components";
import {
  PRIMARY_COLOR,
  PRIMARY_COLOR_TRANS,
  SECOND_COLOR,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "utils/const";

export const Title = styled("div")`
  font-size: 1.4rem;
  background-color: ${WHITE_SECOND_COLOR};
  margin: 20px 0;
`;

export const ButtonStyle = styled("div")`
  /* background-color: ${WHITE_SECOND_COLOR}; */
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  width: 100%;

  button {
    background-color: var(--background-primary_color);
    width: 100%;
    color: ${WHITE_COLOR};
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: var(--background-secondary_color);
    }
  }
`;

export const ButtonTableStyle = styled("button")`
  width: fit-content;
  border: none;
  background-color: #9bc4cb;
  margin: 0;
  cursor: pointer;

  &:active {
    border: solid 2px ${WHITE_COLOR};
  }
  &:hover {
    color: ${SECOND_COLOR};
  }
  .container-tooltip {
    position: relative;
  }

  .tooltip {
    display: block;
    position: absolute;
    border-radius: 5px;
    padding: 7px;
    transform: translate(-29%, 0px);
    border: solid 2px ${PRIMARY_COLOR};
    background-color: ${PRIMARY_COLOR_TRANS};
    color: ${WHITE_COLOR};
    text-align: center;
    z-index: 999;
    font-size: 14px;
    visibility: hidden;
  }

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.5s;
  }
`;
