import { FaBars } from "react-icons/fa";
import logoWhite from "images/Digital-Transformation.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetStatesIndex } from "redux/actions/index";
import NavBarDrawer from "./navDrawer";
import { useState } from "react";
import { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import {
  NavbarContainer,
  NavbarLogoS,
  NavbarLogoR,
  Img,
  MobileIcon,
  NavInfo,
  NavMenu,
  NavItem,
  NavLinkS,
  NavLinkR,
  NavBtn,
  NavBtnLink,
} from "./NavBarElements";
import { PRIMARY_COLOR } from "utils/const";
import { resetStatesAdmin } from "redux/actions/admin";
import { resetStatesDigitalTwin } from "redux/actions/digitalTwin";
// Services
import { updateUserVerification } from "services/users/userVerification";
import { createRevoquedToken } from "services/sessions/create";
import ThemeToggleButton from "components/Buttons/ThemeToggleButton";

const NavBar = ({ toggle, configNavbar }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { sidebar, typeLinks, linkLogo, menuElements, button } = configNavbar;
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");

  useEffect(() => {}, [open]);

  const selectLinkLogo = (typeLink) => {
    switch (typeLink) {
      case "scroll":
        return (
          <NavbarLogoS to={`${linkLogo}`}>
            <Img src={logoWhite} />
          </NavbarLogoS>
        );
      case "router":
        return (
          <NavbarLogoR to={`/${linkLogo}`}>
            <Img src={logoWhite} />
          </NavbarLogoR>
        );
      default:
        return null;
    }
  };

  const selectMenu = (typeLink, key) => {
    switch (typeLink) {
      case "scroll":
        return (
          <NavItem key={key}>
            <NavLinkS to={`${key}`}>{menuElements[key]}</NavLinkS>
          </NavItem>
        );
      case "router":
        return (
          <NavItem key={key}>
            <NavLinkR to={`/${key}`}>{menuElements[key]}</NavLinkR>
          </NavItem>
        );

      default:
        return null;
    }
  };

  const renderMenu = () => {
    return Object.keys(menuElements).map((key) => {
      return selectMenu(typeLinks, key);
    });
  };

  const handlerClick = async (event) => {
    const actionButton = event.target.id;
    const linkButton = event.target.name;
    switch (actionButton) {
      case "Log In":
        const loginStatus = localStorage.getItem("loginStatus");
        const role = localStorage.getItem("role");
        switch (role) {
          case "Customer":
            if (loginStatus === "true") {
              navigate("/hub");
            } else {
              navigate("/signIn");
            }
            break;
          case "Admin":
            if (loginStatus === "true") {
              navigate("/admin");
            } else {
              navigate("/signIn");
            }
            break;
          default:
            navigate("/signIn");
            break;
        }
        break;
      case "Log Out":
        await updateUserVerification({
          userId,
          changes: { activeSession: false },
        });
        await createRevoquedToken();
        localStorage.setItem("loginStatus", false);
        localStorage.clear();
        dispatch(resetStatesIndex());
        dispatch(resetStatesAdmin());
        dispatch(resetStatesDigitalTwin());
        navigate(`/${linkButton}`);
        break;
      case "Back":
        navigate(`/${linkButton}`);
        break;
      case "Home":
        navigate(`/${linkButton}`);
        break;
      default:
        break;
    }
  };
  const handleDrawerOpen = (event) => {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: PRIMARY_COLOR,
          height: "80px",
          justifyContent: "center",
        }}
      >
        <Toolbar
          style={{
            paddingRight: 40,
          }}
        >
          <NavbarContainer>
            <NavInfo>
              <NavBarDrawer onClick={handleDrawerOpen}></NavBarDrawer>
              {selectLinkLogo(typeLinks)}
              {sidebar ? (
                <MobileIcon onClick={toggle}>
                  <FaBars />
                </MobileIcon>
              ) : null}
            </NavInfo>
            <NavInfo>
              <ThemeToggleButton />
              <NavMenu sidebar={sidebar}>{renderMenu()}</NavMenu>
              <NavBtn sidebar={sidebar}>
                <NavBtnLink
                  id={button.text}
                  name={button.link}
                  onClick={handlerClick}
                >
                  {button.text}
                </NavBtnLink>
              </NavBtn>
            </NavInfo>
          </NavbarContainer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
