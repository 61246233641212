import React, { useEffect, useState } from "react";
import {
  ColorGrid,
  ColorRainBow,
  ColorSquare,
  ContainerExample,
  ContentCardDetail,
  DefaultGrid,
  Titleh1,
  ToolTipExample,
} from "./preferencesstyles";

//icons
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import PreferenceToolTip from "components/ToolTips/PreferenceToolTip";
import { WHITE_COLOR } from "utils/const";
import { ChromePicker } from "react-color";
import CancelIcon from "@mui/icons-material/Cancel";
import { fontFamilies } from "Styles";
import useSWR from "swr";
import { config } from "config";
import { useFont } from "./FontData";

function FontConfig({ title }) {
  const { handleFontFamily, handleFontColor } = useFont();

  const [displayColorPickerFont, setDisplayColorPickerFont] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedFont, setSelectedFont] = useState("'Montserrat', 'Noto Sans'");
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const url = `${config.URL_BACKEND_MG}config-preferences?adminCompanyId=${adminCompanyId}`;
  const { data: confgData, error: errorConfigData } = useSWR(url);

  useEffect(() => {
    if (!errorConfigData && confgData && confgData.length > 0) {
      setSelectedColor(confgData[0].font.fontColor);
      setSelectedFont(confgData[0].font.fontType);
    }
  }, [errorConfigData, confgData]);

  const handleColorSquareClickFont = () => {
    setDisplayColorPickerFont(!displayColorPickerFont);
  };

  const handleClose = () => {
    setDisplayColorPickerFont(false);
  };

  return (
    <>
      <Titleh1>{title}</Titleh1>
      <ContentCardDetail className="background-2 border-color-1">
        <div className="contentitle">
          <div className="contentitle2">
            <h2>
              <SortByAlphaIcon style={{ marginRight: "1rem" }} />
              Select Default Font
            </h2>
            <p>Edit Default Font Color</p>
            <DefaultGrid>
              <ContainerExample
                type={"Noto"}
                currentFont={selectedFont}
                onClick={() => {
                  handleFontFamily(fontFamilies.default);
                  setSelectedFont(fontFamilies.default);
                }}
              >
                <div className="Noto">AaBbCc</div>
                <span
                  className="Noto fontsize"
                  style={{ color: "var(--background-secondary_color)" }}
                >
                  (Montserrat)
                </span>
              </ContainerExample>
              <ContainerExample
                type={"Courier"}
                currentFont={selectedFont}
                onClick={() => {
                  handleFontFamily(fontFamilies.courier);
                  setSelectedFont(fontFamilies.courier);
                }}
              >
                <div className="Courier">AaBbCc</div>
                <span
                  className="Courier fontsize"
                  style={{ color: "var(--background-secondary_color)" }}
                >
                  (Courier)
                </span>
              </ContainerExample>
              <ContainerExample
                type={"Verdana"}
                currentFont={selectedFont}
                onClick={() => {
                  handleFontFamily(fontFamilies.verdana);
                  setSelectedFont(fontFamilies.verdana);
                }}
              >
                <div className="Verdana">AaBbCc</div>
                <span
                  className="Verdana fontsize"
                  style={{ color: "var(--background-secondary_color)" }}
                >
                  (Verdana)
                </span>
              </ContainerExample>
              <ContainerExample
                type={"Gill"}
                currentFont={selectedFont}
                onClick={() => {
                  handleFontFamily(fontFamilies.gill);
                  setSelectedFont(fontFamilies.gill);
                }}
              >
                <div className="Gill">AaBbCc</div>
                <span
                  className="Gill fontsize"
                  style={{ color: "var(--background-secondary_color)" }}
                >
                  (Gill)
                </span>
              </ContainerExample>
            </DefaultGrid>
            <div className="optionsContainer">
              <div className="centerContainer">
                <div className="contentitle2">
                  <h2>
                    <WaterDropIcon style={{ marginRight: "1rem" }} />
                    Custom Font Color
                  </h2>
                  <div>
                    <p>
                      Font Color<span>*</span>
                    </p>
                  </div>
                  <ColorGrid>
                    <PreferenceToolTip
                      title="Black"
                      placement="top"
                      backgroundColor="#000000"
                      color={WHITE_COLOR}
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#000000" }}
                        onClick={() => handleFontColor("#000000")}
                      />
                    </PreferenceToolTip>
                    <PreferenceToolTip
                      title="Grey"
                      placement="top"
                      backgroundColor="#525252"
                      color={WHITE_COLOR}
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#525252" }}
                        onClick={() => handleFontColor("#525252")}
                      />
                    </PreferenceToolTip>
                    <PreferenceToolTip
                      title="Gold"
                      placement="top"
                      backgroundColor="#f7a21b"
                      color={WHITE_COLOR}
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#f7a21b" }}
                        onClick={() => handleFontColor("#f7a21b")}
                      />
                    </PreferenceToolTip>

                    <PreferenceToolTip
                      title="Orange"
                      placement="top"
                      backgroundColor="#e45635"
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#e45635" }}
                        onClick={() => handleFontColor("#e45635")}
                      />
                    </PreferenceToolTip>
                    <PreferenceToolTip
                      title="Purple"
                      placement="top"
                      backgroundColor="#831e46"
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#831e46" }}
                        onClick={() => handleFontColor("#831e46")}
                      />
                    </PreferenceToolTip>
                    <PreferenceToolTip
                      title="Strawberry"
                      placement="top"
                      backgroundColor="#d60257"
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#d60257" }}
                        onClick={() => handleFontColor("#d60257")}
                      />
                    </PreferenceToolTip>
                    <PreferenceToolTip
                      title="Deep blue"
                      placement="top"
                      backgroundColor="#1a6b72"
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#1a6b72" }}
                        onClick={() => handleFontColor("#1a6b72")}
                      />
                    </PreferenceToolTip>
                    <PreferenceToolTip
                      title="Blue marine"
                      placement="top"
                      backgroundColor="#00a0b0"
                    >
                      <ColorSquare
                        style={{ backgroundColor: "#00a0b0" }}
                        onClick={() => handleFontColor("#00a0b0")}
                      />
                    </PreferenceToolTip>
                    <PreferenceToolTip
                      title="Pick your color"
                      placement="top"
                      backgroundColor="#7a7a7a"
                    >
                      <ColorRainBow onClick={handleColorSquareClickFont} />
                    </PreferenceToolTip>
                    {displayColorPickerFont && (
                      <div
                        className="background-row-1 border-color-1"
                        style={{
                          padding: "1rem",
                          borderRadius: "5px",
                          position: "absolute",
                          zIndex: 2,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <h2>Custom Color Selector: </h2>
                          <CancelIcon
                            className="iconButton color3"
                            onClick={handleClose}
                          />
                        </div>

                        <ChromePicker
                          color={selectedColor}
                          onChange={(color) => handleFontColor(color.hex)}
                          // onChange={handleFontColor}
                          // onChangeComplete={handleColorChangeComplete}

                          // Disable transparent option
                          disableAlpha={true}
                          styles={{
                            default: {
                              picker: {
                                width: "300px",
                                height: "auto",
                                boxShadow: "none",
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </ColorGrid>
                  <div style={{ opacity: 0.6, margin: "2rem 0rem" }}>
                    <p>
                      <strong>Warning:</strong> The font color will only be{" "}
                      applied in Light Mode.
                      <br />
                      The color change will not be visible in Dark Mode.
                      <br />{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="gridPreviewContainer border-color-1 background-row-1">
                <div>
                  <p>Title 1</p>
                  <ToolTipExample>
                    <h1>Preview</h1>
                  </ToolTipExample>
                </div>
                <div>
                  <p>Title 2</p>
                  <ToolTipExample>
                    <h2>Preview</h2>
                  </ToolTipExample>
                </div>
                <div>
                  <p>paragraph</p>
                  <ToolTipExample>
                    <p>preview</p>
                  </ToolTipExample>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentCardDetail>
    </>
  );
}

export default FontConfig;
