import {
  COMPONENT_CLUSTER_COLOR,
  EVENT_CLUSTER_COLOR,
  OBJECT_CLUSTER_COLOR,
} from "utils/const";

const relationTypeColor = ({
  relationId,
  handlerClickObjectId,
  handlerClickComponentId,
  handlerClickEventId,
}) => {
  const lastChar = relationId?.slice(-1);
  const thirdLastChar = relationId?.slice(-3)[0];
  const id = parseInt(relationId?.match(/\d+/)?.[0]);

  const mapping = {
    O: {
      color: OBJECT_CLUSTER_COLOR,
      handlerClick: () => handlerClickObjectId(id),
    },
    C: {
      color: COMPONENT_CLUSTER_COLOR,
      handlerClick: () => handlerClickComponentId(id),
    },
    E: {
      color: EVENT_CLUSTER_COLOR,
      handlerClick: () => handlerClickEventId(id),
    },
  };

  if (mapping[lastChar] && thirdLastChar === "/") {
    return (
      <p
        onClick={mapping[lastChar].handlerClick}
        style={{ color: mapping[lastChar].color, cursor: "Pointer" }}
      >
        {relationId}
      </p>
    );
  }

  return relationId;
};

export default relationTypeColor;
