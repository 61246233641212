import { useEffect, useMemo, useState } from "react";
// Style
import { GeneralButton } from "components/ElementDetails/listEventTableStyle.jsx";
// Config
import { config } from "config.js";
// Hooks
import useSwr from "swr";

function useCreateEventEvents({ eventId }) {
  const [rows, setRows] = useState([]);
  const [markedEvent, setMarkedEvent] = useState("");
  const seePointEventsTable = useMemo(() => rows?.length > 0, [rows]);

  const urlEvents = `${config.URL_BACKEND_PG}api/v1/point-events/all?id=${eventId}`;
  const { data: events, error: errorEvents } = useSwr(urlEvents);

  const dataDynamicEvents = useMemo(() => {
    return events && !errorEvents ? events : null;
  }, [events, errorEvents]);
  const handleMark = (id) => {
    setMarkedEvent(id);
  };

  useEffect(() => {
    if (markedEvent !== "") {
      const timer = setTimeout(() => {
        setMarkedEvent("");
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [markedEvent]);

  useEffect(() => {
    if (dataDynamicEvents) {
      const rows = dataDynamicEvents?.PointEventRelation?.filter(
        (point) =>
          point.adminCompanyId ===
          parseInt(localStorage.getItem("adminCompanyId"))
      )?.map((item) => {
        return {
          id: item.id,
          name: item.pointTypeEvent.name,
          date: item.date,
          longitude: item.locationOperation.longitude,
          latitude: item.locationOperation.latitude,
          createdAt: item.createdAt,
          markPoint: (
            <GeneralButton onClick={() => handleMark(item.id)}>
              Flash
            </GeneralButton>
          ),
        };
      });
      setRows(rows);
    }
  }, [dataDynamicEvents]);

  return {
    eventRows: rows,
    dataDynamicEvents,
    seePointEventsTable,
    markedEvent,
    setMarkedEvent,
  };
}

export default useCreateEventEvents;
