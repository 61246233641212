import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  setDataRelationObject,
  setInfoObjectRelation,
  setObjectRelationObject,
  setPopUpRelationObject,
} from "redux/actions/digitalTwin.jsx";
import createObjectRelation from "services/objects/createObjectRelaction.js";
import { setModalRelationObject } from "redux/actions/digitalTwin.jsx";
import { useSelector } from "react-redux";
import PopUpObjects from "components/Forms/SaveObject/PopUpObjects.jsx";
import useViewFilterClusterMap from "hooks/useViewFilterClusterMap.jsx";


const CreateRelationObjects = ({
  openPopUp,
  setOpenPopUp,
  pointObjectRelationId,
  pointObjectId,
  ObjectClick,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //All objects in the map
  const allObjects = useSelector((state) => state.adminReducer.dataObjects);

  const popUpRelationObject = useSelector(
    (state) => state.digitalTwinReducer.popUpRelationObject
  );

  const [relationInfo, setRelationInfo] = useState([]);

  //Reset filterss
  const { handleSetFilterPoints } = useViewFilterClusterMap({});


  const handleSave = async () => {
    try {
      // Call to service to create the relationship
      await createObjectRelation({
        existObjectId: pointObjectRelationId,
        newObjectId: pointObjectId,
      });

      // Save info that belong to the objects
      setRelationInfo((prevInfo) => [...prevInfo, { objectSave: ObjectClick }]);
    } catch (error) {
      console.error("Error creating relationship:", error);
    } finally {
      setLoading(false);
      dispatch(setPopUpRelationObject({ showPopUp: false }));
      //dispatch(setDataRelationObject(relationInfo));
    }
  };

  //finish popup and relation object
  const handleFinishPopUp = () => {
    //handleClosePopUp();
    handleSave();
    //Close Dialog of info
    dispatch(setInfoObjectRelation({ showInfoObject: false }));
    //Reset map
    handleSetFilterPoints();
    //Close instance
    dispatch(setModalRelationObject({ show: false }));

    dispatch(setObjectRelationObject({ objectId: "" }));

    //
  };

  //Show info of the map

  // useEffect(() => {
  //   setOpenDialog(relationObjectModal.show);
  // }, [relationObjectModal.show, dispatch, //setOpenDialog, openDialog]);

  //useEffect para controlar el estado del modal
  useEffect(() => {
    if (popUpRelationObject.showPopUp) {
      setOpenPopUp(true);
    }
  }, [
    popUpRelationObject,
    setOpenPopUp,
    openPopUp,
    allObjects,
    dispatch,
    ObjectClick,
  ]);

  //useEffect to save the info of the objects

  useEffect(() => {
    if (relationInfo.length > 0) {
      dispatch(setDataRelationObject(relationInfo));
    }
  }, [relationInfo, dispatch]);



  return (
    <>
      <div>
        {/* Modal to confirm the relationship */}
        {openPopUp && (
          <>
            <PopUpObjects
              handleSave={handleSave}
              handleFinish={handleFinishPopUp}
              ObjectClick={ObjectClick}
              setOpenPopUp={setOpenPopUp}
            />
          </>
        )}
      </div>
      {/**Show the info of the objects */}
    </>
  );
};

export default CreateRelationObjects;
