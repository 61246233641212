import axios from "axios";
import { config } from "config";
const deleteUserById = async (id) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${config.URL_BACKEND_PG}api/v1/operators/${id}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteUserById;
