import axios from "axios";
import { config } from "config.js";

const getEventPDFInfo = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(
      `${config.URL_BACKEND_PG}api/v1/pdfmodule/event/${id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      },
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default getEventPDFInfo;
