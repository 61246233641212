import styled from "styled-components";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  RED_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const ContainerLoad = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Container = styled.div`
  position: absolute;
  bottom: 10rem;
  right: 1rem;
  z-index: 7;
  border-radius: 1rem;

  .dynamic-num {
    position: absolute;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 50%;
    font-size: 11px;
    background-color: ${RED_COLOR};
    color: ${WHITE_COLOR};
  }

  .icon-table {
    font-size: 2.5rem;
    color: var(--background-primary_color);
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: -12rem;
      transform: translate(-50%, 20px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      font-size: 1.5rem;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;
