import React from "react";
import * as turf from "@turf/turf";
import { useEffect, useCallback} from "react";

function CenterMapFromFeatures({ mapref, coordinates }) {
  /** Extended view handler */
  const handleExtendView = useCallback(() => {

    /* if there is no objects then a default extend view of -1 zoom  */
    const { lng, lat } = mapref?.current.getCenter();
    const zoom = mapref?.current.getZoom();
    const defaultExtended = Math.round(zoom - 1);
    mapref?.current?.flyTo({
      center: [lng, lat],
      duration: 1000,
      zoom: defaultExtended,
    });
    const featuresCollection = turf.points(coordinates);
    const envelopeFeatures = turf.envelope(featuresCollection);

    mapref?.current?.getMap().fitBounds(envelopeFeatures.bbox, {
      padding: 50,
    });
  }, [coordinates, mapref]);

  useEffect(() => {
    mapref.current && handleExtendView();
  }, [mapref, handleExtendView]);

  return <></>;
}

export default CenterMapFromFeatures;
