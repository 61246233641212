import styled from "styled-components";
import { MdArrowForward, MdArrowRight } from "react-icons/md";
import {
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "utils/const";
import { Link } from "react-router-dom";

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: ${WHITE_SECOND_COLOR};
`;

export const Confirmation = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  justify-items: center;
  align-items: center;
`;

export const TitleConfirmation = styled.h1`
  margin: 1%;
  font-size: 3rem;
`;

export const Pconfirmation = styled.p`
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 1%;
  color: gray;
  text-align: center;
  font-size: 1.5rem;
`;

export const GoButton = styled(Link)`
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  font-size: 1.6rem;
  margin-top: 40px;
  height: 50px;
  padding: 10px;
  border: none;
  border-radius: 15px;
  width: 50%;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${SECOND_COLOR};
  }
`;

export const LoginForm = styled.form`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginTitle = styled.p`
  text-align: center;
  font-size: 2.5rem;
  color: ${SECOND_COLOR};
  margin: 80px 0;
`;

export const PStyled = styled.p`
  padding: 1rem;
  font-size: 1.4rem;
  color: black;
  justify-content: right;
  align-items: right;
  text-align: right;
  cursor: pointer;
`;

export const LoginInput = styled.input`
  width: 100%;
  min-height: 40px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #18213a;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 2rem;
`;

export const ArrowRight = styled(MdArrowRight)`
  margin-right: 8px;
  font-size: 2rem;
`;

export const ImgWrap = styled.div`
  max-width: 500px;
  height: 100%;
  display: grid;
  height: 500px;
  justify-content: center;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SocialIconLink = styled.a`
  color: #222729;
  font-size: 2.4rem;
`;

export const Divider = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LineDivider = styled.hr`
  height: 2px;
  border: none;
  background-color: rgb(225, 225, 225);
  flex: 1 1 0%;
`;

export const TextDivider = styled.div`
  background-color: rgb(255, 255, 255);
  color: rgb(97, 97, 97);
  font-size: 1.4rem;
  line-height: 18px;
  padding: 0px 10px;
`;
export const RestartLink = styled.button`
  background-color: rgb(255, 255, 255);
  color: rgb(97, 97, 97);
  font-size: 1.4rem;
  line-height: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #222729;
  }
`;

export const LoginButton = styled.button`
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  font-size: 1.6rem;
  margin-top: 40px;
  height: 50px;
  padding: 10px;
  border: none;
  border-radius: 15px;
  width: 100%;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${SECOND_COLOR};
  }
`;
