export function setDuplicateObjects(arr) {
  const uniqueObjects = new Set();
  const normalizeObject = (obj) => {
    const normalizedObj = {};
    const keys = Object.keys(obj).sort();
    for (const key of keys) {
      normalizedObj[key] = obj[key];
    }
    return JSON.stringify(normalizedObj);
  };

  const result = arr.filter((obj) => {
    const normalizedObj = normalizeObject(obj);
    if (!uniqueObjects.has(normalizedObj)) {
      uniqueObjects.add(normalizedObj);
      return true;
    }

    return false;
  });

  return result;
}
