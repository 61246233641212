import axios from "axios";
import { config } from "config.js";

const createPointTypeEvent = async ({ body, adminCompanyId }) => {
  const { libraryId, name, urlIconColor } = body;
  const token = localStorage.getItem("token");
  let url = `${
    config.URL_BACKEND_PG
  }api/v1/point-type-events?libraryId=${parseInt(
    body.libraryId
  )}&adminCompanyId=${adminCompanyId}`;
  const result = await axios.post(
    url,
    {
      pointLibraryEventId: libraryId,
      name,
      icon: urlIconColor,
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await result.data;
  return data;
};

export default createPointTypeEvent;
