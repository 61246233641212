// Styles
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import TollIcon from "@mui/icons-material/Toll";
import { BLACK_COLOR, DELETE_COLOR, VISIBILITY_OFF_ICON } from "utils/const";
import {
  Change,
  DateFilterContentStyle,
  getSelectStyles,
} from "../../../GenericTableStyle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// React
import Select from "react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import store from "redux/store";
// Helpers
import DebouncedInput from "./GenericInputFilter";
import { removePropertyExist } from "./removePropertyExist";
import { calculateOnlyOneOption } from "./calculateOnlyOneOption";
import { setDuplicateObjects } from "./setDuplicateObjects";
import { isDate } from "helpers/isDate";
import { isUrl, nameUrl } from "helpers/isUrl";
// Components
import DatePicker from "components/Accordions/DatePicker";

function FilterTableDigitalTwin({
  column,
  table,
  setValue,
  setFilterCheckBox,
  filterCheckBox,
  setDateFilters,
  dateFilters,
  rows,
  typeOfLibrary,
  dataChecked,
  setDataChecked,
  selectedFiltersByColumnOrder,
  currentNameviewColumnOrder,
}) {
  const [changeRange, setChangeRange] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [currentObject, setCurrentObject] = useState({});
  const [allOptions, setAllOptions] = useState([
    column.id === "relationId"
      ? {}
      : {
          label: "Toggle All",
          value: "Toggle All",
          uniqueValue: column.getFacetedUniqueValues().size,
        },
  ]);
  const [useCheck, setUseCheck] = useState(true);

  const { fullTableRows, filterTableRows } =
    store.getState().digitalTwinReducer;

  const selectStyles = getSelectStyles(
    table.getPrePaginationRowModel().rows.length
  );

  const firstValue = table
    ?.getPreFilteredRowModel()
    ?.flatRows[0]?.getValue(column.id);

  var columnFilterValue = column.getFilterValue();
  if (setValue) {
    columnFilterValue = "";
  }

  const handlerCurrentTableUnicValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues()).sort(),
    [rows, dataChecked, openSelect, useCheck]
  );

  const handlerTableUnicValues = useCallback(() => {
    const currentColumn = column.id;
    if (currentColumn !== "flyTo" && currentColumn !== "markPoint") {
      const uniqueValuesCount = fullTableRows?.reduce((acc, obj) => {
        const value = obj.original[currentColumn];
        if (typeof value === "string") {
          acc[value] = (acc[value] || 0) + 1;
          return acc;
        } else {
          return {};
        }
      }, {});

      const result = Object.entries(uniqueValuesCount).map(([key, count]) => [
        key,
        count,
      ]);
      if (result.length > 0) return result;
    }
  }, [column, fullTableRows, rows, typeOfLibrary, openSelect]);

  const handlerFilterTableUnicValues = useCallback(() => {
    const currentColumn = column.id;
    if (currentColumn !== "flyTo" && currentColumn !== "markPoint") {
      const uniqueValuesCount = filterTableRows?.reduce((acc, obj) => {
        const value = obj[currentColumn];
        if (typeof value === "string") {
          acc[value] = (acc[value] || 0) + 1;
          return acc;
        } else {
          return {};
        }
      }, {});

      const result = Object.entries(uniqueValuesCount)?.map(([key, count]) => [
        key,
        count,
      ]);
      if (result.length > 0) return result;
    }
  }, [column, filterTableRows, handlerTableUnicValues()?.length]);

  useEffect(() => {
    const optionsFilterTableUnicValues =
      handlerTableUnicValues()?.length > 0
        ? handlerTableUnicValues()
            .map((elm) => {
              return {
                value: elm[0],
                label: elm[0],
                uniqueValue: elm[1],
              };
            })
            .filter((it) => it !== undefined)
        : [];

    if (column.id === "relationId") {
      const relationComponentsLength = handlerFilterTableUnicValues()?.filter(
        (item) => /\/ C$/.test(item[0])
      );

      const relationEventsLength = handlerFilterTableUnicValues()?.filter(
        (item) => /\/ E$/.test(item[0])
      );
      const relationObjectsLength = handlerFilterTableUnicValues()?.filter(
        (item) => /\/ O$/.test(item[0])
      );
      const relations = [
        {
          value: "Objects",
          label: "Objects",
          uniqueValue: relationObjectsLength?.reduce(
            (totalPoints, currentValue) => totalPoints + currentValue[1],
            0
          ),
        },
        {
          value: "Components",
          label: "Components",
          uniqueValue: relationComponentsLength?.reduce(
            (totalPoints, currentValue) => totalPoints + currentValue[1],
            0
          ),
        },
        {
          value: "Events",
          label: "Events",
          uniqueValue: relationEventsLength?.reduce(
            (totalPoints, currentValue) => totalPoints + currentValue[1],
            0
          ),
        },
      ];
      const res = relations.concat(optionsFilterTableUnicValues);
      setAllOptions(res);
    } else {
      optionsFilterTableUnicValues.push({
        label: "Toggle All",
        value: "Toggle All",
        uniqueValue: column.getFacetedUniqueValues().size,
      });
      const sortedArray = optionsFilterTableUnicValues.sort((a, b) => {
        if (a.value === "Toggle All") {
          return -1;
        } else if (b.value === "Toggle All") {
          return 1;
        } else {
          return a.value.localeCompare(b.value);
        }
      });
      setAllOptions(sortedArray);
    }
  }, [column, table, handlerTableUnicValues()?.length]);

  useEffect(() => {
    const optionsFilterTableUnicValues =
      handlerFilterTableUnicValues()?.length > 0
        ? handlerFilterTableUnicValues()
            ?.map((elm) => {
              return {
                value: elm[0],
                label: elm[0],
                uniqueValue: elm[1],
              };
            })
            .filter((it) => it !== undefined)
        : [];

    const optionsTableUnicValues =
      handlerTableUnicValues()?.length > 0
        ? handlerTableUnicValues()
            ?.map((elm) => {
              return {
                value: elm[0],
                label: elm[0],
                uniqueValue: elm[1],
              };
            })
            .filter((it) => it !== undefined)
        : [];

    if (column.id === "relationId") {
      const relationComponentsLength = handlerFilterTableUnicValues()?.filter(
        (item) => /\/ C$/.test(item[0])
      );
      const relationEventsLength = handlerFilterTableUnicValues()?.filter(
        (item) => /\/ E$/.test(item[0])
      );
      const relationObjectsLength = handlerFilterTableUnicValues()?.filter(
        (item) => /\/ O$/.test(item[0])
      );
      const relations = [
        {
          value: "Objects",
          label: "Objects",
          uniqueValue: relationObjectsLength?.reduce(
            (totalPoints, currentValue) => totalPoints + currentValue[1],
            0
          ),
        },
        {
          value: "Components",
          label: "Components",
          uniqueValue: relationComponentsLength?.reduce(
            (totalPoints, currentValue) => totalPoints + currentValue[1],
            0
          ),
        },
        {
          value: "Events",
          label: "Events",
          uniqueValue: relationEventsLength?.reduce(
            (totalPoints, currentValue) => totalPoints + currentValue[1],
            0
          ),
        },
      ];
      relations.forEach((elm) => {
        optionsFilterTableUnicValues.push(elm);
        optionsTableUnicValues.push(elm);
      });
    }

    return setAllOptions((current) => {
      const newData = current
        .map((elm) => {
          if (elm.value !== "Toggle All") {
            let foundOption = optionsFilterTableUnicValues?.find(
              (elm2) => elm.value === elm2.value
            );
            if (foundOption) {
              return {
                ...elm,
                uniqueValue: foundOption.uniqueValue,
              };
            } else {
              const foundOptionDos = optionsTableUnicValues?.find(
                (elm2) => elm.value === elm2.value
              );

              const existOptionCurrentTable = handlerCurrentTableUnicValues
                .map((elm3) => {
                  if (elm3.includes(elm?.value)) return true;
                  return false;
                })
                .filter((it) => it !== undefined)[0];

              if (
                foundOptionDos &&
                existOptionCurrentTable?.[1] &&
                existOptionCurrentTable?.[0]
              ) {
                return {
                  ...elm,
                  uniqueValue: foundOptionDos.uniqueValue,
                };
              } else {
                const selectColumns = dataChecked.map(
                  (column) => Object.keys(column)[0]
                );
                if (!selectColumns.includes(column.id)) {
                  return {
                    ...elm,
                    uniqueValue: 0,
                  };
                } else {
                  return {
                    ...elm,
                  };
                }
              }
            }
          } else {
            return {
              ...elm,
              uniqueValue: column.getFacetedUniqueValues().size,
            };
          }
        })
        .filter((it) => it !== undefined);
      return newData;
    });
  }, [
    column,
    handlerFilterTableUnicValues()?.length,
    dataChecked,
    openSelect,
    handlerTableUnicValues()?.length,
  ]);

  useEffect(() => {
    if (Object.keys(currentObject).length > 0) {
      const newData = removePropertyExist(filterCheckBox, currentObject);
      setFilterCheckBox(newData.filter((it) => Object.keys(it).length !== 0));
    }
  }, [currentObject]);

  useEffect(() => {
    if (filterCheckBox.length === 0) {
      setDataChecked([]);
      setCurrentObject([]);
    }
  }, [filterCheckBox]);

  const isChecked = (column, value) => {
    if (dataChecked.length > 0) {
      const match = dataChecked.find(
        (it) => JSON.stringify(it) === JSON.stringify({ [column]: value })
      );
      if (
        match &&
        JSON.stringify(match) === JSON.stringify({ [column]: value })
      )
        return true;
      return false;
    }
    return false;
  };

  const check = (column, value) => {
    setUseCheck(!useCheck);
    if (value === "Toggle All") {
      if (!isChecked(column, value)) {
        setDataChecked((prev) => {
          const res = allOptions
            .map((elm) => {
              if (elm.value !== "Toggle All") {
                return { [column]: elm.value };
              }
            })
            .filter((it) => it !== undefined);
          return res.concat(prev);
        });
      } else {
        setFilterCheckBox((prev) => {
          const current = prev
            .map((elm) => {
              if (elm.hasOwnProperty(column)) {
                delete elm[column];
              }
              return elm;
            })
            .filter((it) => Object.keys(it).length !== 0);
          return current;
        });
        setDataChecked((prev) => {
          const res = prev.filter((elm) => {
            return Object.keys(elm)[0] !== column;
          });
          return res;
        });
      }
    }

    const exist = dataChecked.find(
      (it) => JSON.stringify(it) === JSON.stringify({ [column]: value })
    );

    const checkObjects = dataChecked.find(
      (it) => JSON.stringify(it) === JSON.stringify({ relationId: "Objects" })
    );
    const checkEvents = dataChecked.find(
      (it) => JSON.stringify(it) === JSON.stringify({ relationId: "Events" })
    );
    const checkComponents = dataChecked.find(
      (it) =>
        JSON.stringify(it) === JSON.stringify({ relationId: "Components" })
    );

    if (
      column === "relationId" &&
      (checkObjects || checkEvents || checkComponents)
    ) {
      if (checkObjects && value === "Objects") {
        const setRelationObjects = dataChecked.filter((elm) => {
          return (
            /\/ O$/.test(elm.relationId) === false &&
            elm.relationId !== "Objects"
          );
        });
        setCurrentObject({ relationId: "Objects" });
        return setDataChecked(setRelationObjects);
      }
      if (checkEvents && value === "Events") {
        const setRelationEvents = dataChecked.filter((elm) => {
          return (
            /\/ E$/.test(elm.relationId) === false &&
            elm.relationId !== "Events"
          );
        });
        setCurrentObject({ relationId: "Events" });
        return setDataChecked(setRelationEvents);
      }
      if (checkComponents && value === "Components") {
        const setRelationComponents = dataChecked.filter((elm) => {
          return (
            /\/ C$/.test(elm.relationId) === false &&
            elm.relationId !== "Components"
          );
        });
        setCurrentObject({ relationId: "Components" });
        return setDataChecked(setRelationComponents);
      }
    }

    if (exist) {
      if (value !== "Toggle All") {
        const filteredData = dataChecked.filter(
          (item) =>
            JSON.stringify(item) !== JSON.stringify({ [column]: value }) &&
            item[column] !== "Toggle All"
        );
        setDataChecked(filteredData);
        setCurrentObject({ [column]: value });
      }
    } else {
      if (column === "relationId") {
        if (value === "Objects") {
          const relationObjects = allOptions.filter((item) =>
            /\/ O$/.test(item.value)
          );
          relationObjects.forEach((elm) => {
            return setDataChecked((prev) => [
              ...prev,
              { relationId: elm.value },
            ]);
          });
        }
        if (value === "Components") {
          const relationComponents = allOptions.filter((item) =>
            /\/ C$/.test(item.value)
          );
          relationComponents.forEach((elm) => {
            return setDataChecked((prev) => [
              ...prev,
              { relationId: elm.value },
            ]);
          });
        }
        if (value === "Events") {
          const relationEvents = allOptions.filter((item) =>
            /\/ E$/.test(item.value)
          );
          relationEvents.forEach((elm) => {
            return setDataChecked((prev) => [
              ...prev,
              { relationId: elm.value },
            ]);
          });
        }
        return setDataChecked((prev) => [...prev, { [column]: value }]);
      }
      return setDataChecked((prev) => [...prev, { [column]: value }]);
    }
  };

  const addNewFilter = useCallback(
    (column, valueColumn) => {
      const newArray = [];
      if (filterCheckBox.length > 0) {
        filterCheckBox.forEach((elm) => {
          if (
            column === "relationId" &&
            (valueColumn === "Objects" ||
              valueColumn === "Components" ||
              valueColumn === "Events")
          ) {
            let newObject = {};
            if (valueColumn === "Objects") {
              const relationObjects = allOptions.filter((item) =>
                /\/ O$/.test(item.value)
              );
              relationObjects.forEach((obj) => {
                if (obj.value !== "Objects") {
                  if (elm.hasOwnProperty("relationId")) {
                    newArray.push(elm);
                  }
                  newObject = {
                    ...elm,
                    relationId: obj.value,
                  };
                  return newArray.push(newObject);
                }
              });
            }
            if (valueColumn === "Components") {
              const relationComponents = allOptions.filter((item) =>
                /\/ C$/.test(item.value)
              );
              relationComponents.forEach((component) => {
                if (component.value !== "Components") {
                  if (elm.hasOwnProperty("relationId")) {
                    newArray.push(elm);
                  }
                  newObject = {
                    ...elm,
                    relationId: component.value,
                  };
                  return newArray.push(newObject);
                }
              });
            }
            if (valueColumn === "Events") {
              const relationEvents = allOptions.filter((item) =>
                /\/ E$/.test(item.value)
              );
              relationEvents.forEach((event) => {
                if (event.value !== "Events") {
                  if (elm.hasOwnProperty("relationId")) {
                    newArray.push(elm);
                  }
                  newObject = {
                    ...elm,
                    relationId: event.value,
                  };
                  return newArray.push(newObject);
                }
              });
            }
          }

          if (
            valueColumn !== "Objects" &&
            valueColumn !== "Components" &&
            valueColumn !== "Events"
          ) {
            const entries = Object.entries(elm);
            for (const [key, value] of entries) {
              let newObject = {};
              if (key !== column) {
                newObject = {
                  ...elm,
                  [column]: valueColumn,
                };
              }
              if (key === column && value !== valueColumn) {
                newArray.push(elm);
                const res = {
                  ...elm,
                  [column]: valueColumn,
                };
                newObject = res;
                newArray.push(newObject);
                return null;
              }
              if (key === column && value === valueColumn) {
                newObject[key] = value;
              }
              newArray.push(newObject);
            }
          }
        });
      } else {
        if (
          column === "relationId" &&
          (valueColumn === "Objects" ||
            valueColumn === "Components" ||
            valueColumn === "Events")
        ) {
          if (valueColumn === "Objects") {
            const relationObjects = allOptions.filter((item) =>
              /\/ O$/.test(item.value)
            );

            relationObjects.forEach((obj) => {
              if (obj.value !== "Objects") {
                newArray.push({
                  relationId: obj.value,
                });
              }
            });
          }
          if (valueColumn === "Components") {
            const relationComponents = allOptions.filter((item) =>
              /\/ C$/.test(item.value)
            );
            relationComponents.forEach((component) => {
              if (component.value !== "Components") {
                newArray.push({
                  relationId: component.value,
                });
              }
            });
          }
          if (valueColumn === "Events") {
            const relationEvents = allOptions.filter((item) =>
              /\/ E$/.test(item.value)
            );
            relationEvents.forEach((event) => {
              if (event.value !== "Events") {
                newArray.push({
                  relationId: event.value,
                });
              }
            });
          }
        }
        newArray.push({ [column]: valueColumn });
        setFilterCheckBox(newArray);
      }
      if (valueColumn === "Toggle All") {
        const allCurrentOptions = [];
        if (filterCheckBox.length > 0) {
          allOptions.forEach((item) => {
            if (item.value !== "Toggle All" && item.uniqueValue > 0) {
              filterCheckBox
                .filter((it) => Object.values(it)[0] !== "")
                .forEach((elm) => {
                  const entries = Object.entries(elm);
                  for (const [key, value] of entries) {
                    let newObject = {};
                    if (key !== column) {
                      newObject = {
                        ...elm,
                        [column]: item.value,
                      };
                    }
                    if (key === column && value !== item.value) {
                      allCurrentOptions.push(elm);
                      const res = {
                        ...elm,
                        [column]: item.value,
                      };
                      newObject = res;
                      allCurrentOptions.push(newObject);
                      return null;
                    }
                    if (key === column && value === item.value) {
                      continue;
                    }
                    allCurrentOptions.push(newObject);
                  }
                });
            }
          });
          if (isChecked(column, valueColumn) === false) {
            return setFilterCheckBox(setDuplicateObjects(allCurrentOptions));
          }
        } else {
          const currentOptions = allOptions
            .map((elm) => {
              if (elm.uniqueValue > 0) {
                return { [column]: elm.value };
              } else {
                return undefined;
              }
            })
            .filter((it) => it !== undefined);

          const dataFilter = newArray
            .concat(currentOptions)
            .filter((it) => it[column] !== "Toggle All");
          return setFilterCheckBox(setDuplicateObjects(dataFilter));
        }
      }
      if (isChecked(column, valueColumn) === false) {
        return setFilterCheckBox(setDuplicateObjects(newArray));
      }
    },
    [column, filterCheckBox, setFilterCheckBox, allOptions]
  );

  useEffect(() => {
    if (selectedFiltersByColumnOrder.length > 0) {
      setFilterCheckBox(selectedFiltersByColumnOrder);
      selectedFiltersByColumnOrder.forEach((elm) => {
        for (let key in elm) {
          check(key, elm[key]);
        }
      });
    }
  }, [selectedFiltersByColumnOrder]);

  const [onlyOneOption, setOnlyOneOption] = useState(false);

  useEffect(() => {
    const newOnlyOneOption = calculateOnlyOneOption(allOptions);
    setOnlyOneOption(newOnlyOneOption);
  }, [allOptions]);

  const handleChangeMinDate = (date) => {
    if (dateFilters.columnId !== "" && dateFilters.columnId !== column.id) {
      setDateFilters({
        columnId: column.id,
        min: date,
        max: "",
      });
    } else {
      setDateFilters((prev) => ({
        ...prev,
        columnId: column.id,
        min: date,
      }));
    }
  };

  const handleChangeMaxDate = (date) => {
    if (dateFilters.columnId !== "" && dateFilters.columnId !== column.id) {
      setDateFilters({
        columnId: column.id,
        min: "",
        max: date,
      });
    } else {
      setDateFilters((prev) => ({
        ...prev,
        columnId: column.id,
        max: date,
      }));
    }
  };

  if (typeof firstValue === "number") {
    if (changeRange === false) {
      return (
        <div>
          <Change onClick={() => setChangeRange(!changeRange)}>
            <FiberSmartRecordIcon sx={{ mb: "-5px", mr: "5px" }} />
            <div className="container-tooltip">
              <span className="tooltip">Range Mode</span>
            </div>
          </Change>
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) => column.setFilterValue((old) => [value, value])}
            placeholder={`Num (${column.getFacetedUniqueValues().size})`}
            className="tableCell2"
          />
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <Change onClick={() => setChangeRange(!changeRange)}>
              <TollIcon sx={{ mb: "-5px", mr: "5px" }} />
              <div className="container-tooltip">
                <span className="tooltip">Unique Value</span>
              </div>
            </Change>
          </div>
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[0] ?? ""}
            onChange={(value) =>
              column.setFilterValue((old) => [value, old?.[1]])
            }
            placeholder={`Min ${
              column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ""
            }`}
            className="tableCell2"
          />
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) =>
              column.setFilterValue((old) => [old?.[0], value])
            }
            placeholder={`Max ${
              column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ""
            }`}
            className="tableCell2"
          />
        </div>
      );
    }
  }

  if (isDate(firstValue)) {
    return (
      <DateFilterContentStyle>
        <div className="contentButtons">
          <button
            className="resetButton"
            onClick={() => setDateFilters({ columnId: "", min: "", max: "" })}
          >
            <RestartAltIcon
              sx={{
                fontSize: "2rem",
                "&:hover": {
                  color: `${DELETE_COLOR}`,
                },
              }}
            />
          </button>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              startdate={
                dateFilters.columnId === column.id ? dateFilters.min : ""
              }
              enddate={
                dateFilters.columnId === column.id ? dateFilters.max : ""
              }
              value={dateFilters.columnId === column.id ? dateFilters.min : ""}
              changefunction={handleChangeMinDate}
              from={"genericTable"}
              active={
                dateFilters.columnId === column.id && dateFilters.min !== ""
                  ? true
                  : false
              }
            />
            <HorizontalRuleIcon sx={{ margin: 0 }} />
            <DatePicker
              startdate={
                dateFilters.columnId === column.id ? dateFilters.min : ""
              }
              enddate={
                dateFilters.columnId === column.id ? dateFilters.max : ""
              }
              value={dateFilters.columnId === column.id ? dateFilters.max : ""}
              changefunction={handleChangeMaxDate}
              from={"genericTable"}
              active={
                dateFilters.columnId === column.id && dateFilters.max !== ""
                  ? true
                  : false
              }
            />
          </LocalizationProvider>
        </div>
      </DateFilterContentStyle>
    );
  }

  if (typeof firstValue === "string" && !isNaN(firstValue) === false) {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <Select
          styles={selectStyles}
          menuPlacement="bottom"
          options={[...allOptions]}
          isMulti
          closeMenuOnSelect={false}
          menuIsOpen={openSelect}
          onMenuClose={() => setOpenSelect(false)}
          onMenuOpen={() => setOpenSelect(true)}
          placeholder={`Options (${column.getFacetedUniqueValues().size})`}
          components={{
            Option: ({ label, data }) => (
              <div>
                {" "}
                {data.uniqueValue !== 0 && (
                  <div
                    style={{
                      display: "flex",
                      margin: "2px",
                    }}
                  >
                    {onlyOneOption === false && (
                      <input
                        style={{
                          margin: "0px",
                          textAlign: "left",
                        }}
                        type="checkbox"
                        onChange={() => {
                          addNewFilter(column.id, data.value);
                          check(column.id, data.value);
                        }}
                        checked={isChecked(column.id, data.value)}
                        disabled={data.uniqueValue === 0 ? true : false}
                      />
                    )}
                    {data.value !== "Toggle All" && (
                      <div
                        style={{
                          marginRight: "1px",
                          marginLeft: "3px",
                          marginBottom: "2px",
                          color: `${
                            data.uniqueValue === 0
                              ? VISIBILITY_OFF_ICON
                              : BLACK_COLOR
                          }`,
                        }}
                      >
                        {" "}
                        {`(${data.uniqueValue})`}
                      </div>
                    )}
                    <p
                      style={{
                        color: `${
                          data.uniqueValue === 0
                            ? VISIBILITY_OFF_ICON
                            : BLACK_COLOR
                        }`,
                        marginTop: "1px",
                        marginLeft: "3px",
                      }}
                    >
                      {isUrl(label) ? nameUrl(label) : label}
                    </p>
                  </div>
                )}
              </div>
            ),
          }}
        />
      </div>
    );
  } else {
    return <></>;
  }
}

export default FilterTableDigitalTwin;
