import DynamicEvent from "components/Forms/Events/DynamicEvent";
import { useState } from "react";
import SelectInput from "components/Forms/SelectInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FeatureDetails } from "components/Forms/SaveObject/indexStyle";
import { WraperTitleCardMarker } from "components/ElementDetails/ImageDetailsElements";
import { useFetchEventLibraries } from "hooks/fetchLibraries";

/**
 * Creates an event with the given parameters.
 *
 * @param {Object} options - The options for creating the event.
 * @param {string} options.feature - The feature of the event.
 * @param {string} options.pointComponentId - The ID of the point component.
 * @param {function} options.closeModal - A function to close the modal.
 * @param {Array} options.typeEventsTypeComponents - The type events type components.
 * @return {void}
 */
export default function CreateEvent({
  feature,
  pointComponentId,
  closeModal,
  typeEventsTypeComponents,
  isGeographic = true,
}) {
  const [type, setType] = useState();
  const [currentEventType, setCurrentEventType] = useState("");
  const [currentLibraryType, setCurrentLibraryType] = useState("");

  const [libraryType, setLibraryType] = useState({
    name: "Library type",
    options: [],
    id: 0,
  });
  const [eventType, setEventType] = useState({
    name: "Type event",
    options: [],
    icons: [],
    id: 0,
  });

  const { data: typeEventLibraries, errorTypeEventLibraries } =

    useFetchEventLibraries({ id: null });


  useEffect(() => {
    const librarieIds = typeEventsTypeComponents.map(
      (event) => event.pointTypeEvent.pointLibraryEventId
    );

    var typeIcons = typeEventsTypeComponents.map((typeEvent) => {
      const library =
        !errorTypeEventLibraries &&
        typeEventLibraries?.find((library) => {
          return typeEvent.pointTypeEvent.pointLibraryEventId === library.id;
        });
      return {
        name: typeEvent.pointTypeEvent.name,
        icon: typeEvent.pointTypeEvent.icon,
        library: library?.name,
      };
    });

    const currentLibraryTypes = [];
    const currentEventTypes = [];

    !errorTypeEventLibraries &&
      typeEventLibraries?.forEach((library) => {
        if (librarieIds?.includes(library.id)) {
          currentLibraryTypes.push(library.name);
        }
      });

    typeEventsTypeComponents.forEach((event) => {
      !errorTypeEventLibraries &&
        typeEventLibraries?.forEach((library) => {
          if (
            !errorTypeEventLibraries &&
            typeEventLibraries &&
            event.pointTypeEvent.pointLibraryEventId === library.id
          ) {
            if (library.name === currentLibraryTypes[0]) {
              currentEventTypes.push(event.pointTypeEvent.name);
            }
          }
        });
    });
    setEventType((prev) => ({
      ...prev,
      options: currentEventTypes,
      icons: typeIcons,
    }));

    setLibraryType((prev) => ({
      ...prev,
      options: currentLibraryTypes,
    }));

    setCurrentEventType(currentEventTypes[0]);
    setCurrentLibraryType(currentLibraryTypes[0]);
    const currentType = typeEventsTypeComponents.find((event) => {
      return event.pointTypeEvent.name === currentEventTypes[0];
    });
    setType(currentType?.pointTypeEvent.id);
  }, [typeEventsTypeComponents, typeEventLibraries, errorTypeEventLibraries]);

  const { register, handleSubmit } = useForm();

  const handleSelectLibrary = (data) => {
    setCurrentLibraryType(data["Library type"]);
    const currentLibrary = typeEventLibraries.find(
      (event) => event.name === data["Library type"]
    );
    const currentLibraryOptions = [];
    const currentEventTypesId = [];
    typeEventsTypeComponents.forEach((event) => {
      if (event.pointTypeEvent.pointLibraryEventId === currentLibrary.id) {
        currentLibraryOptions.push(event.pointTypeEvent.name);
        currentEventTypesId.push(event.pointTypeEvent.id);
      }
    });
    setEventType({ ...eventType, options: currentLibraryOptions });
    setType(currentEventTypesId[0]);
    setCurrentEventType(currentLibraryOptions[0]);
  };

  const handleSelectTypeEvent = (data) => {
    typeEventsTypeComponents.forEach((event) => {
      if (event.pointTypeEvent.name === data["Type event"]) {
        setType(event.pointTypeEvent.id);
        setCurrentEventType(data["Type event"]);
      }
    });
  };
  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {eventType?.icons.map((event) => {
            return (
              event.name === currentEventType &&
              event.library === currentLibraryType && (
                <img className="dynamicImg" src={event.icon} alt="Decimetrix" />
              )
            );
          })}
          <h2>Events</h2>
        </div>
      </WraperTitleCardMarker>
      <div onChange={handleSubmit(handleSelectLibrary)}>
        <SelectInput field={libraryType} register={register} />
      </div>
      <div onChange={handleSubmit(handleSelectTypeEvent)}>
        <SelectInput field={eventType} register={register} />
      </div>
      <DynamicEvent
        closeModal={closeModal}
        pointComponentId={pointComponentId}
        feature={isGeographic ? feature : {}}
        pointTypeEventId={type}
      />
    </FeatureDetails>
  );
}
