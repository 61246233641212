import styled, { keyframes } from "styled-components";
import {
  WHITE_COLOR,
  BACKGROUND_SECOND_GREY,
  GRAY_COLOR,
  PRIMARY_COLOR,
  BACKGROUD_GREY_PREVIEW,
} from "utils/const";
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* position: ${({ position }) => position}; */
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  border: 1px solid ${(props) => props.borderColor};
  /* box-shadow: 1px 3px 5px ${(props) => props.borderColor}; */
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: ${(props) => props.maxHeight};
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 3px;
  right: ${(props) => `${props.right}px`};
  border: none;
  cursor: pointer;
  z-index: 2;
`;

export const RestoreButton = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  border: none;
  cursor: pointer;
  z-index: 2;
`;

export const ResizeRight = styled.div`
  .hover-area {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 0px;
    margin-top: 20px;
    padding-bottom: 20px;
    z-index: 2;
  }

  .hidden-div {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 100%;
    width: 25px;
    border-bottom-right-radius: 10px;
    background: #03030326;
    border-left: dashed 2px #0000002e;
    border-top: dashed 2px #0000002e;
    margin-top: 5px;
    padding-bottom: 24px;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50%;
      border-bottom: dashed 2px #0000002e;
    }

    span:hover {
      transform: scale(1);
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const ResizeBottom = styled.div`
  .hover-area {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 0px;
    z-index: 2;
  }

  .hidden-div {
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;
    position: absolute;
    align-items: center;
    text-align: center;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 25px;
    width: 100%;
    background: #03030326;
    border-top: dashed 2px #0000002e;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      border-right: dashed 2px #0000002e;
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const SectionContainer = styled.div`
  /* position: absolute; */

  width: ${(props) => props.width};
  height: ${(props) => props.height};
  text-align: center;
`;

export const Title = styled.div`
  display: ${(props) => props.display};
  font-size: ${(props) => (props.fontSize ? `${props?.fontSize}px` : "2.2rem")};
  color: ${(props) => props.color};
  font-weight: 700;
  background-color: ${(props) => props.backgroundColor};
  overflow-wrap: break-word;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
`;

export const Subtitle = styled.div`
  display: ${(props) => props.display};
  font-size: ${(props) => (props.fontSize ? `${props?.fontSize}px` : "2.2rem")};
  color: ${(props) => props.color};
  font-weight: 600;
  background-color: ${(props) => props.backgroundColor};
  overflow-wrap: break-word;
`;

export const BodyDescription = styled.div`
  display: ${(props) => props.display};
  font-size: ${(props) => `${props.fontSize}px` || "1.5rem"};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  padding: 10px 20px;
  font-weight: 400;
  overflow-wrap: break-word;
`;

export const Image = styled.img`
  display: ${(props) => props.display};
  width: ${(props) => props.width}%;
  aspect-ratio: auto;
  object-fit: contain;
  margin: 10px auto;
  padding: 10px 0 0;
  margin-bottom: 0;

  @media screen and (width < 768px) {
    width: 50%;
  }
`;

export const ImageDescription = styled.p`
  display: ${(props) => props.display};
  font-size: ${(props) => `${props.fontSize}px` || "1.5rem"};
  color: ${(props) => props.color};
  margin: 10px;
  font-weight: 400;
  overflow-wrap: break-word;
`;

// ---------------------- Preview Text Chart Styles -----------------------------

const rainbowAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const ContainerPreviewChart = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: ${WHITE_COLOR};
  border-radius: 10px;
  width: 100%;
  max-height: 75vh;

  .section-chart-button {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 68%;
    margin-right: 10px;
    max-height: 75vh;
  }

  .preview-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${BACKGROUD_GREY_PREVIEW};
    width: 100%;
    height: 90%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
  }

  .chart {
    width: ${({ widthChart }) => `${widthChart}px`};
    height: ${({ heightChart }) => `${heightChart}px`};
    max-width: 965px;
    border-radius: 8px;
  }

  .container-update {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${WHITE_COLOR};
    width: 100%;
    height: 10%;
    margin-right: 10px;
    border-radius: 10px;
  }

  .editing-tools {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    background: ${BACKGROUD_GREY_PREVIEW};
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
  }

  .groupe-styles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: ${WHITE_COLOR};
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .sections {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 5px;
      color: ${WHITE_COLOR};
      background: ${PRIMARY_COLOR};
      margin-bottom: 15px;
      height: 40px;
    }
  }

  .section-style {
    display: flex;
    flex-wrap: wrap;
    margin: 2px;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    .alias {
      width: 100%;
      align-self: center;
      padding: 10px;
    }
    p {
      font-size: 15px;
      font-weight: 900;
    }

    h4 {
      color: ${GRAY_COLOR};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: end;
    }

    .input-title {
      width: 100%;
      height: 43px;
      padding: 5px 5px 0px 5px;
      margin: 5px 5px 5px 5px;
      border: solid 3px #b7b7b7;
      border-radius: 5px;
      background: ${WHITE_COLOR};
      text-align: center;
      font-weight: 900;
    }

    .input-title:focus {
      outline: none;
    }

    input[type="file"] {
      width: 51%;
      height: 20px;
      margin: 10px;
      text-align: center;
      border: none;
      background-color: ${BACKGROUND_SECOND_GREY};
      font-weight: 600;
      padding: 5px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      color: #333; /* Puedes cambiar el color del texto si es necesario */
      cursor: pointer; /* Cambia el cursor al pasar por encima */
    }

    input[type="file"]::file-selector-button {
      background: none;
      border: none;
      font-weight: 600;
      cursor: pointer;
    }
    input[type="number"] {
      width: 38%;
      height: 18px;
      margin: 10px;
      text-align: center;
      border: none;
      background-color: ${BACKGROUND_SECOND_GREY};
      font-weight: 600;
    }
    input[type="checkbox"] {
      width: 45%;
      height: 20px;
    }

    input[type="range"] {
      margin: 10px;
      width: 59%;
    }

    input[type="color"] {
      border: none;
      width: 45%;
      height: 37px;
      background-color: ${WHITE_COLOR};
      cursor: pointer;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: 2px solid ${BACKGROUND_SECOND_GREY};
      border-radius: 5px;
    }

    select {
      border: none;
      width: 40%;
      margin: 7px;
      height: 25px;
      border-radius: 5px;
      text-align: center;
      color: ${WHITE_COLOR};
      background-color: ${PRIMARY_COLOR};
      cursor: pointer;
    }
  }
`;
