import { useDispatch } from "react-redux";

import { setOperationDetails, setShowOperationDetails } from "redux/actions";

import ObjectDetails from "components/ElementDetails/ObjectDetails/ObjectDetails";
import ComponentDetails from "components/ElementDetails/ComponentDetails/Index";
import EventDetails from "components/ElementDetails/EventDetails/EventDetails";

const useShowDetails = () => {
  const dispatch = useDispatch();
  const handlerClickObjectId = (id) => {
    const object = {
      content: <ObjectDetails objectId={id} />,
      title: "Object Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };
  const handlerClickComponentId = (id) => {
    const component = {
      content: <ComponentDetails componentId={id} />,
      title: "Component Details",
    };
    dispatch(setOperationDetails(component));
    dispatch(setShowOperationDetails(true));
  };

  const handlerClickEventId = (id) => {
    const event = {
      content: <EventDetails eventId={id} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(event));
    dispatch(setShowOperationDetails(true));
  };

  return { handlerClickObjectId, handlerClickComponentId, handlerClickEventId };
};

export default useShowDetails;
