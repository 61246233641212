export const orderAttributes = ({ arrayToOrder, fromArray }) => {
  const orderAttributes = arrayToOrder?.sort((a, b) => {
    const aIndex = fromArray?.[0]?.fields?.findIndex(
      (item) => item.name === a.name
    );
    const bIndex = fromArray?.[0]?.fields?.findIndex(
      (item) => item.name === b.name
    );
    return aIndex - bIndex;
  });
  return orderAttributes;
};
