import styled from "styled-components";
import {
  OBJECT_CLUSTER_COLOR,
  ICON_GREEN_COLOR,
  SENSOR_CLUSTER_COLOR,
  EVENT_CLUSTER_COLOR,
  CSV_CLUSTER_COLOR,
  COMPONENT_CLUSTER_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const MarkerElementContainer = styled.button`
  position: relative;
  border: none;
  background: none;
  cursor: ${(props) =>
    (props.drawInMap && props.actionType === "copyCoordinates") ||
    (props.drawInMap && props.actionType === "movePoints")
      ? "crosshair"
      : props.drawInMap && !props.actionType
      ? "none"
      : "pointer"};

  img {
    width: 40px;
    filter: ${(props) => (props.filter ? "none" : "grayscale(100%)")};
    z-index: 2;
  }

  img:hover {
    opacity: ${(props) => (props.drawInMap ? "0" : "1")};
  }

  .selected {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.flyTo ? "block" : "none")};
  }
  .selectedObject {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.objectRelation ? "block" : "none")};
  }

  .selectedObjectSon {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${ICON_GREEN_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.objectRelationSon ? "block" : "none")};
  }

  .selectedLookObjectSon {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${SENSOR_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.lookObjectSon ? "block" : "none")};
  }
  .qaqc {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${ICON_GREEN_COLOR};
    border-radius: 50%;
    z-index: -1;
    display: ${(props) => (props.qaqc ? "block" : "none")};
  }

  .color-cluster-component,
  .color-cluster-event,
  .color-cluster-object {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -7px;
    left: -5px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    background-color: ${OBJECT_CLUSTER_COLOR};
  }

  .color-cluster-event {
    background-color: ${EVENT_CLUSTER_COLOR};
  }

  .color-cluster-component {
    background-color: ${COMPONENT_CLUSTER_COLOR};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      opacity: 0.6;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }
`;

export const MarkerClusterContainer = styled("div")`
  .cluster-marker-component,
  .cluster-marker-object,
  .cluster-marker-csv,
  .cluster-marker-operation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    color: ${WHITE_COLOR};
    border-radius: 50%;
    cursor: pointer;
  }

  .cluster-marker-csv {
    background: ${CSV_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .cluster-marker-operation {
    background: ${EVENT_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .cluster-marker-component {
    background: ${COMPONENT_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .cluster-marker-object {
    background: ${OBJECT_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .object-marker {
    background: none;
    border: none;
  }

  .object-marker img {
    width: 40px;
  }
`;
