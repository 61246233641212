import styled from "styled-components";
import {
  COMPONENT_CLUSTER_COLOR,
  WHITE_COLOR,
  SECOND_COLOR,
  BORDER_GREY,
  WHITE_SECOND_COLOR,
  GRAY_COLOR,
  BLACK_COLOR,
  PRIMARY_COLOR,
  SELECTION_COLOR,
} from "utils/const";

export const selectStyle = {
  option: (provided) => ({
    ...provided,
    fontSize: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
  menu: (base) => ({
    ...base,
    width: "100%",
    maxWidth: "none",
    maxHeight: "auto",
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: "4px",
    backgroundColor: `${COMPONENT_CLUSTER_COLOR}`,
    padding: "3px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: `${WHITE_COLOR}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: `${WHITE_COLOR}`,
  }),
};

export const ContainerColumnSelect = styled.section`
  display: grid;
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  grid-template-rows: auto;
  gap: 10px;

  @media (width <= 700px) {
    grid-template-columns: 1fr;
  }
`;

export const SelectType = styled.section`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  .select {
    width: 100%;
  }
`;

export const InputTextSection = styled.section`
  position: relative;
  margin: 10px 0;

  h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 20px;
  }

  .button-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Divider = styled.hr`
  margin: 5px 0 30px;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    ${SECOND_COLOR},
    rgba(0, 0, 0, 0)
  );
`;
/* ----------------------------ToolBar ---------------------------- */
export const ContainerToolBar = styled.div`
  position: fixed;
  height: 100vh;
  width: 65px;
  padding: 70px 4px 0px 0px;
  z-index: 3;
`;
export const ToolBarDashBoard = styled.div`
  height: 100%;
  width: 100%;
  background: ${WHITE_COLOR};
  border: solid 1px ${GRAY_COLOR};

  /* ----------- POINT TYPES --------- */

  .container-typeChars {
    height: 15%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .type-charts {
    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .type {
    text-align: center;
    color: ${SECOND_COLOR};
  }

  /* ----------- TOOLS --------- */
  .tools {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 70%;
    padding: 2px;
  }

  .add-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.2%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: ${WHITE_COLOR};

    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .container-charts-templates {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.2%;
  }

  .charts-tampalates {
    color: ${({ currentTemplate }) =>
      currentTemplate ? SELECTION_COLOR : BLACK_COLOR};
    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .container-views-Tool {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.2%;
    border: solid 1px ${GRAY_COLOR};
    border-bottom-style: dashed;
  }

  .views-tool {
    color: ${({ selectViews, currentOrderName }) =>
      selectViews === true || currentOrderName !== "Design New View"
        ? SECOND_COLOR
        : BLACK_COLOR};
    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .add-new-view-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.2%;
    background: ${WHITE_COLOR};
    border: solid 1px ${GRAY_COLOR};
    border-top-style: none;
    border-bottom-style: ${({ useDragAndDroll, currentOrderName }) =>
      currentOrderName !== "Design New View" && useDragAndDroll
        ? "none"
        : useDragAndDroll
        ? "solid"
        : "none"};

    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .update-view-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.2%;
    border: solid 1px ${GRAY_COLOR};
    border-top-style: none;
    border-bottom-style: none;
    background: ${WHITE_COLOR};

    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .restore-chart-trash {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.2%;
    border: solid 1px ${GRAY_COLOR};
    border-top-style: none;
    background: ${WHITE_COLOR};

    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .container-calendar-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ useDragAndDroll, currentOrderName }) =>
      useDragAndDroll || currentOrderName ? "2px" : "0"};
    height: 14.2%;
    border: ${({ useFilterCalendar }) =>
      useFilterCalendar ? `solid 1px ${GRAY_COLOR}` : "none"};
    border-bottom-style: ${({ useFilterCalendar }) =>
      useFilterCalendar ? "dashed" : "none"};
  }

  .calendar-tool {
    color: ${({ useCalendar, useFilterCalendar }) =>
      useFilterCalendar || useCalendar ? SECOND_COLOR : BLACK_COLOR};
    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .reset-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14.2%;
    border: solid 1px ${GRAY_COLOR};
    border-top-style: none;
    background: ${WHITE_COLOR};

    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  .container-pdf-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ useDragAndDroll, currentOrderName }) =>
      useDragAndDroll || currentOrderName ? "2px" : "0"};
    height: 14.2%;
    border: ${({ openToolPrintPDF }) =>
      openToolPrintPDF ? `solid 1px ${GRAY_COLOR}` : "none"};
    border-bottom-style: ${({ openToolPrintPDF }) =>
      openToolPrintPDF ? "dashed" : "none"};
  }
  .pdf-tool {
    color: ${({ openToolPrintPDF, useFilterCalendar }) =>
      useFilterCalendar || openToolPrintPDF ? SECOND_COLOR : BLACK_COLOR};
    :hover {
      color: ${SECOND_COLOR};
      cursor: pointer;
    }
  }

  /* ----------- CONFIGURATION --------- */
  .configuration {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: end;
    height: 15%;
    padding: 2px;

    .all-configuration {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50%;
      border: solid 1px ${WHITE_COLOR};
      border-radius: 10px;
      background: ${WHITE_COLOR};

      :hover {
        color: ${SECOND_COLOR};
        cursor: pointer;
      }
    }
  }
`;

export const FloatingView = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinea los elementos horizontalmente al centro */
  justify-content: center; /* Alinea los elementos verticalmente al centro */
  gap: 8px; /* Espacio entre los elementos */
  padding: 10px;
  border: solid 1px ${GRAY_COLOR};
  border-radius: 10px;
  background-color: ${WHITE_COLOR};
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerTypeView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-left: -1px;
  max-width: 350px;
  min-width: 350px;

  p {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const ContainerSelectAndDeletView = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80px;
`;

export const ContainerMenu = styled.div`
  position: absolute;
  margin-top: 100px;
  margin-left: 200px;
  z-index: 3;
  width: 150px;
  height: 150px;
  border: solid 2px red;
  background: ${WHITE_COLOR};
`;

export const BackgroundDashboard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 120px;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 5px;
  border: solid 1px ${BORDER_GREY};
  cursor: pointer;
  background: ${({ color }) =>
    color === "default" ? WHITE_SECOND_COLOR : color};

  &:hover {
    border: solid 3px ${SECOND_COLOR};
  }
  .input-color-config {
    width: 70%;
    align-self: center;
    padding: 10px;
  }
`;

export const SelectFilterDate = styled.div`
  position: absolute;
  left: 100%;
  transform: translateX(8px);
  width: 450px;
`;

export const SelectRangeDateStyle = styled.div`
  gap: 8px;
  border: solid 1px ${GRAY_COLOR};
  padding: 10px;
  border-radius: 10px;
  background-color: ${WHITE_COLOR};

  h1 {
    text-align: center;
    margin-bottom: 10px;
  }

  .button {
    width: 60px;
  }
`;

export const ContainerDataPickers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const AddFilterDate = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  .button-add {
    display: grid;
    border-radius: 50%;
    background-color: var(--background-primary_color);
    border: none;
    cursor: pointer;

    :hover {
      background-color: var(--background-secondary_color);
    }
  }
`;

export const SelectViewStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "end",
    flexDirection: "row-reverse",
    width: "max-content",
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "start",
    fontSize: "16px",
    "&:hover": {
      color: PRIMARY_COLOR,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "fit-content",
    fontSize: "13px",
    left: 0,
    zIndex: 3,
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 20px)",
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    flexDirection: "row-reverse",
    overflow: "none",
  }),
  container: (provided) => ({
    ...provided,
    width: "200px",
  }),
  groupHeading: (provided) => ({
    ...provided,
    fontWeight: "bold",
    color: `${PRIMARY_COLOR}`,
    fontSize: "13px",
    // Agrega cualquier otro estilo que desees
  }),
};

export const SelectTypesStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "end",
    flexDirection: "row-reverse",
    width: "max-content",
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "start",
    fontSize: "16px",
    "&:hover": {
      color: PRIMARY_COLOR,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "130px",
  }),

  option: (provided) => ({
    ...provided,
    padding: "8px 4px",
  }),
  container: (provided) => ({
    ...provided,
    width: "200px",
  }),
};

export const SelectTemplatesStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "end",
    flexDirection: "row-reverse",
    width: "max-content",
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "start",
    fontSize: "16px",
    "&:hover": {
      color: PRIMARY_COLOR,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "150px",
  }),

  option: (provided) => ({
    ...provided,
    padding: "8px 4px",
  }),
  container: (provided) => ({
    ...provided,
    width: "200px",
  }),
};

// ----------- Img Form ---------

export const ContainerFormImg = styled.div`
  .image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .subtitle {
    margin-bottom: 10px;
  }

  .image-upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .image-upload-label:hover {
    border-color: #888;
  }

  .upload-icon {
    font-size: 48px;
    color: #888;
    margin-bottom: 10px;
  }

  .image-upload-input {
    display: none;
  }

  .image-preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
