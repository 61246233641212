import {
  CardKpi,
  CardKpiGauge,
  CountGoal,
  CountValue,
  Description,
  ContainerPreviewChart,
} from "./indexStyle";
import Gauge from "components/Charts/KpiChart/Gauge";
import { useEffect, useState } from "react";
import CustomButton from "components/Buttons/CustomButton";
import { updateChart } from "services/Dashboard/kpiChart";
import { useSWRConfig } from "swr";
import { config } from "config";
import { fixedNumber } from ".";
// import SelectLibrary from "pages/Dashboard/components/SelectLibrary";
// import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";
// import SelectColumnsLibrary from "pages/Dashboard/components/SelectColumnsLibrary";
// import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary2";
// import { useFetchEventLibraries } from "hooks/fetchLibraries";
// import useFindAllEvents from "hooks/events/useFindAllEvents";
// import HandlerClickColumn from "pages/Dashboard/helpers/handlerClickColumn";
// import useColumnsWithUniqueValues from "hooks/dashboard/events/useColumnsWithUniqueValues2";
// import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
// import { defaultFieldsEvents } from "utils/defaultColumns";
// import { RED_COLOR } from "utils/const";

const PreviewKpiChart = ({
  allDataKpiCharts = [],
  dataChart,
  setChartEditDialog,
  maxHeight,
  maxWidth,
}) => {
  const { chartStyle } = dataChart;
  const { mutate } = useSWRConfig();
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlKpi = `${
    config.URL_BACKEND_MG
  }kpi-chart?adminCompanyId=${adminCompanyId}&from=${
    dataChart.fromType ? dataChart.fromType : dataChart.from
  }`;
  const [currentGaugeColor, setCurrentGaugeColor] = useState("");
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState("");
  const [currentMainFontColor, setCurrentMaintFontColor] = useState("");
  const [currentSecondFontColor, setCurrentSecondFontColor] = useState("");
  const [currentMainFontSize, setCurrentMaintFontSize] = useState(0);
  const [currentSecondFontSize, setCurrentSecondtFontSize] = useState(0);
  const [currentNoDecimals, setCurrentNoDecimals] = useState(0);
  const [currentGaugeSize, setCurrentGaugeSize] = useState();
  const [selectDynamicGoalValue, setSelectDynamicGoalValue] = useState(null);
  const [currentGoal, setCurrentGoal] = useState(null);
  const [currentNameKpi, setCurrentNameKpi] = useState("");

  const [currentDynamicGoalValue, setCurrentDynamicGoalValue] = useState({
    object: {
      id: null,
      alias: null,
      goal: null,
      status: false,
    },
    components: {
      id: null,
      alias: null,
      goal: null,
      status: false,
    },
    events: {
      id: null,
      alias: null,
      goal: null,
      status: false,
    },
    currentKpi: {
      alias: "currentKpi",
      status: false,
      goal: null,
    },
  });

  const handleDynamicGoal = ({ checkbox, select }) => {
    // Checkbox Event
    if (checkbox) {
      if (checkbox.target.name === "objects") {
        if (checkbox.target.checked) {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: true,
            },
            components: {
              ...current.components,
              status: false,
            },
            events: {
              ...current.events,
              status: false,
            },
            currentKpi: {
              ...current.currentKpi,
              status: false,
            },
          }));
        } else {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: false,
            },
            components: {
              ...current.components,
              status: false,
            },
            events: {
              ...current.events,
              status: false,
            },
            currentKpi: {
              ...current.currentKpi,

              status: false,
            },
          }));
        }
      }
      if (checkbox.target.name === "components") {
        if (checkbox.target.checked) {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: false,
            },
            components: {
              ...current.components,
              status: true,
            },
            events: {
              ...current.events,
              status: false,
            },
            currentKpi: {
              ...current.currentKpi,

              status: false,
            },
          }));
        } else {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: false,
            },
            components: {
              ...current.components,
              status: false,
            },
            events: {
              ...current.events,
              status: false,
            },
            currentKpi: {
              ...current.currentKpi,

              status: false,
            },
          }));
        }
      }
      if (checkbox.target.name === "events") {
        if (checkbox.target.checked) {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: false,
            },
            components: {
              ...current.components,
              status: false,
            },
            events: {
              ...current.events,
              status: true,
            },
            currentKpi: {
              ...current.currentKpi,

              status: false,
            },
          }));
        } else {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: false,
            },
            components: {
              ...current.components,
              status: false,
            },
            events: {
              ...current.events,
              status: false,
            },
            currentKpi: {
              ...current.currentKpi,

              status: false,
            },
          }));
        }
      }
      if (checkbox.target.name === "currentKpi") {
        if (checkbox.target.checked) {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: false,
            },
            components: {
              ...current.components,
              status: false,
            },
            events: {
              ...current.events,
              status: false,
            },
            currentKpi: {
              ...current.currentKpi,

              status: true,
              goal: currentGoal,
            },
          }));
        } else {
          setCurrentDynamicGoalValue((current) => ({
            object: {
              ...current.object,
              status: false,
            },
            components: {
              ...current.components,
              status: false,
            },
            events: {
              ...current.events,
              status: false,
            },
            currentKpi: {
              ...current.currentKpi,

              status: false,
              goal: null,
            },
          }));
        }
      }
    }

    // Select Event
    if (select) {
      if (select.target.name === "objects") {
        if (currentDynamicGoalValue.object.status) {
          const selectValue = JSON.parse(select.target.value);
          setCurrentDynamicGoalValue((current) => ({
            ...current,
            object: {
              ...current.object,
              id: selectValue.id,
              alias: selectValue.name,
              goal: selectValue.goal,
            },
          }));
        }
      }
      if (select.target.name === "components") {
        if (currentDynamicGoalValue.components.status) {
          const selectValue = JSON.parse(select.target.value);
          setCurrentDynamicGoalValue((current) => ({
            ...current,
            components: {
              ...current.components,
              id: selectValue.id,
              alias: selectValue.name,
              goal: selectValue.goal,
            },
          }));
        }
      }
      if (select.target.name === "events") {
        if (currentDynamicGoalValue.events.status) {
          const selectValue = JSON.parse(select.target.value);
          setCurrentDynamicGoalValue((current) => ({
            ...current,
            events: {
              ...current.events,
              id: selectValue.id,
              alias: selectValue.name,
              goal: selectValue.goal,
            },
          }));
        }
      }
    }
  };

  useEffect(() => {
    const findActiveStatus = (data) => {
      for (const key in data) {
        if (data[key] && data[key].status) {
          return { key, value: data[key] };
        }
      }
      return null;
    };

    const res = findActiveStatus(currentDynamicGoalValue);
    setSelectDynamicGoalValue(res);
  }, [currentDynamicGoalValue]);

  // --------------------------------------------------------------------------------
  // const [columnsLibrary, setColumnsLibrary] = useState([]);
  // const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [columnsSelected, setColumnsSelected] = useState([]);
  // const [columnValues, setColumnValues] = useState({});
  // const [eventsFormat, setEventsFormat] = useState([]);
  // const [dataInputs, setDataInputs] = useState({
  //   librarySelected: null,
  //   metric: null,
  //   typeOperation: null,
  // });

  // const [showInputs, setShowInputs] = useState(false);

  // const updateDataInputs = (newState) => {
  //   setDataInputs((prevState) => ({ ...prevState, ...newState }));
  // };

  // const { data: libraries, error } = useFetchEventLibraries({});
  // const { events } = useFindAllEvents();

  // const handlerClickMetric = (column) => {
  //   updateDataInputs({ metric: column });
  // };

  // const handlerTypeOperation = (e) => {
  //   updateDataInputs({ typeOperation: e });
  // };

  // const handlerClickColumn = HandlerClickColumn({
  //   setColumnsSelected,
  //   columnValues,
  //   setColumnValues,
  // });

  // const { columnsWithValues, setColumnsWithValues } =
  //   useColumnsWithUniqueValues({
  //     events: eventsFormat,
  //     columnsSelected,
  //     handlerClickColumn,
  //   });

  // const handlerColumnValues = (columnName, selectedValues) => {
  //   setColumnValues({
  //     ...columnValues,
  //     [columnName]: selectedValues,
  //   });
  // };

  // const handlerLibrarySelected = (e) => {
  //   updateDataInputs({ librarySelected: e });
  //   const library = libraries.find((library) => library.id === e.value);
  //   const eventsByLibraryId = events.filter(
  //     (event) => event.pointTypeEvent.pointLibraryEventId === e.value
  //   );
  //   if (library) setShowInputs(true);
  //   const rows = createRowsEvents({
  //     events: eventsByLibraryId,
  //     library,
  //   });
  //   setEventsFormat(rows);
  //   updateDataInputs({ metric: null, alias: "" });
  //   setColumnsWithValues([]);
  // };

  // useEffect(() => {
  //   // find library by library select from libraries
  //   if (libraries && dataInputs.librarySelected) {
  //     const library = libraries.find(
  //       (library) => library.id === dataInputs.librarySelected.value
  //     );

  //     const columnsLibrary = library?.fields;

  //     const columnsFiltered = columnsLibrary?.filter(
  //       (field) =>
  //         field.type !== "captureMedia" && field.type !== "notification"
  //     );
  //     if (columnsFiltered && columnsFiltered.length > 0) {
  //       setColumnsLibrary([...defaultFieldsEvents, ...columnsFiltered] || []);
  //       setColumnsTypeNumber(
  //         columnsFiltered?.filter(
  //           (field) =>
  //             field.type === "number" ||
  //             field.type === "operation" ||
  //             field.type === "range"
  //         )
  //       );
  //     } else {
  //       setColumnsLibrary([...defaultFieldsEvents] || []);
  //       setColumnsTypeNumber([]);
  //     }
  //   } else {
  //     setColumnsLibrary([]);
  //     setColumnsTypeNumber([]);
  //   }
  // }, [libraries, dataInputs.librarySelected]);

  // ---------------------------------------------------------------------------------

  useEffect(() => {
    // const library = libraries?.find(
    //   (library) => library.id === dataChart.libraryId
    // );
    setCurrentNameKpi(dataChart.alias);
    setCurrentGoal(dataChart.goal);
    setCurrentGaugeColor(chartStyle.gaugeColor);
    setCurrentBackgroundColor(chartStyle.backgroundColor);
    setCurrentMaintFontColor(chartStyle.mainFontColor);
    setCurrentMaintFontSize(chartStyle.mainFontSize);
    setCurrentSecondFontColor(chartStyle.secondFontColor);
    setCurrentSecondtFontSize(chartStyle.secondFontSize);
    setCurrentNoDecimals(chartStyle.NoDecimals);
    setCurrentGaugeSize(chartStyle?.gaugeSize);

    const currentGoal = allDataKpiCharts.find((elm) => {
      return elm._id === dataChart.dynamicGoalValueId;
    });

    if (currentGoal) {
      if (currentGoal.fromType === "objects") {
        setCurrentDynamicGoalValue((current) => ({
          ...current,
          object: {
            id: currentGoal._id,
            alias: currentGoal.alias,
            goal: fixedNumber(
              currentGoal.count,
              currentGoal.chartStyle.NoDecimals
            ),
            status: true,
          },
        }));
      }

      if (currentGoal.fromType === "components") {
        setCurrentDynamicGoalValue((current) => ({
          ...current,
          components: {
            id: currentGoal._id,
            alias: currentGoal.alias,
            goal: fixedNumber(
              currentGoal.count,
              currentGoal.chartStyle.NoDecimals
            ),
            status: true,
          },
        }));
      }

      if (currentGoal.fromType === "events") {
        setCurrentDynamicGoalValue((current) => ({
          ...current,
          events: {
            id: currentGoal._id,
            alias: currentGoal.alias,
            goal: fixedNumber(
              currentGoal.count,
              currentGoal.chartStyle.NoDecimals
            ),
            status: true,
          },
        }));
      }
    } else {
      setCurrentDynamicGoalValue((current) => ({
        ...current,
        currentKpi: {
          alias: "currentKpi",
          goal: dataChart.goal,
          status: true,
        },
      }));
    }

    // if (library) {
    //   handlerLibrarySelected({
    //     label: library.name,
    //     value: dataChart.libraryId,
    //   });
    //   setDataInputs({
    //     librarySelected: {
    //       label: library.name,
    //       value: dataChart.libraryId,
    //     },
    //     metric: dataChart.metric,
    //     typeOperation: {
    //       label: dataChart.type,
    //       value: dataChart.type,
    //     },
    //   });
    //   const columnsLibrary = library?.fields;

    //   const columnsFiltered = columnsLibrary?.filter(
    //     (field) =>
    //       field.type !== "captureMedia" && field.type !== "notification"
    //   );
    //   if (columnsFiltered && columnsFiltered.length > 0) {
    //     setColumnsLibrary([...defaultFieldsEvents, ...columnsFiltered] || []);
    //     setColumnsTypeNumber(
    //       columnsFiltered?.filter(
    //         (field) =>
    //           field.type === "number" ||
    //           field.type === "operation" ||
    //           field.type === "range"
    //       )
    //     );
    //   }
    // }
    // if (dataChart && dataChart.columnsValuesFilter.length > 0) {
    //   dataChart.columnsValuesFilter.forEach((elm) => {
    //     handlerClickColumn(elm.name);
    //     elm.values.forEach((item) => {
    //       setColumnValues((current) => ({
    //         ...current,
    //         [elm.name]: [
    //           ...(current[elm.name] || []),
    //           {
    //             value: item,
    //             label: item,
    //           },
    //         ],
    //       }));
    //     });
    //   });
    // }
    // }, [dataChart, libraries]);
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...dataChart,
        alias: currentNameKpi,
        goal: currentGoal,
        dynamicGoalValueId: selectDynamicGoalValue.value.id,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        chartStyle: {
          ...chartStyle,
          gaugeColor: currentGaugeColor,
          gaugeSize: currentGaugeSize,
          backgroundColor: currentBackgroundColor,
          mainFontColor: currentMainFontColor,
          mainFontSize: currentMainFontSize,
          secondFontColor: currentSecondFontColor,
          secondFontSize: currentSecondFontSize,
          NoDecimals: currentNoDecimals,
        },
      },
    });
    if (data) {
      mutate(urlKpi);
      setChartEditDialog(false);
    }
  };

  return (
    <ContainerPreviewChart heightChart={maxHeight} widthChart={maxWidth}>
      <div className="section-chart-button">
        <div className="preview-chart">
          <div className="chart">
            {/* {currentGoal ? ( */}
            {selectDynamicGoalValue && selectDynamicGoalValue.value.goal ? (
              <CardKpiGauge
                borderColor={"none"}
                backgroundColor={currentBackgroundColor}
              >
                <Gauge
                  value={fixedNumber(
                    // dataChart.count / currentGoal,
                    dataChart.count / selectDynamicGoalValue.value.goal,

                    currentNoDecimals
                  )}
                  color={currentGaugeColor}
                  background={currentBackgroundColor}
                  textColor={currentSecondFontColor}
                  gaugeSize={currentGaugeSize}
                />
                <div>
                  <CountGoal
                    fontSize={currentMainFontSize}
                    color={currentMainFontColor}
                  >
                    {fixedNumber(dataChart.count, currentNoDecimals)}/
                    {/* {currentGoal} */}
                    {selectDynamicGoalValue.value.goal}
                  </CountGoal>
                  <Description
                    fontSize={currentSecondFontSize}
                    color={currentSecondFontColor}
                  >
                    {currentNameKpi}
                  </Description>
                </div>
              </CardKpiGauge>
            ) : (
              <CardKpi
                borderColor={"none"}
                backgroundColor={currentBackgroundColor}
              >
                <CountValue
                  fontSize={currentMainFontSize}
                  color={chartStyle?.mainFontColor}
                >
                  {fixedNumber(dataChart.count, currentNoDecimals)}
                </CountValue>
                <Description
                  fontSize={currentSecondFontSize}
                  color={currentSecondFontColor}
                >
                  {dataChart.alias}
                </Description>
              </CardKpi>
            )}
          </div>
        </div>
        <div className="container-update">
          <CustomButton
            text={"Update Chart"}
            margin={0}
            onClick={handleUpdateChart}
          />
        </div>
      </div>
      <div className="editing-tools">
        <div className="groupe-styles">
          <div className="sections">
            <h3>Title</h3>
            <div className="section-style">
              <input
                className="input-title"
                value={currentNameKpi}
                type="text"
                onChange={(e) => setCurrentNameKpi(e.target.value)}
              />
            </div>
            <div className="section-style">
              <p>Text Color</p>
              <input
                className="alias"
                type="color"
                value={currentSecondFontColor}
                onChange={(e) => {
                  setCurrentSecondFontColor(e.target.value);
                }}
              />
            </div>
            <div className="section-style">
              <p>Background Color</p>
              <input
                className="alias"
                type="color"
                value={currentBackgroundColor}
                onChange={(e) => {
                  setCurrentBackgroundColor(e.target.value);
                }}
              />
            </div>
            <div className="section-style">
              <p>Title Size</p>
              <div>
                <h4>{currentSecondFontSize} px</h4>
                <input
                  value={currentSecondFontSize}
                  type="range"
                  step={"1"}
                  min={"10"}
                  max={Math.round(maxHeight - 100 / 2)}
                  onChange={(e) => setCurrentSecondtFontSize(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Gauge</h3>
            <div className="section-style">
              <p>Gaugue Color</p>
              <input
                className="alias"
                type="color"
                value={currentGaugeColor}
                onChange={(e) => {
                  setCurrentGaugeColor(e.target.value);
                }}
              />
            </div>
            <div className="section-style">
              <p>Gauge Size</p>
              <div>
                <h4>{currentGaugeSize} px</h4>
                <input
                  value={currentGaugeSize}
                  type="range"
                  step={"1"}
                  min={"10"}
                  max={Math.round(maxHeight)}
                  onChange={(e) => setCurrentGaugeSize(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Goal</h3>

            <div className="section-style">
              <div className="dynamic_goal">
                <input
                  type="checkbox"
                  name="objects"
                  checked={currentDynamicGoalValue.object.status}
                  onClick={(e) => handleDynamicGoal({ checkbox: e })}
                />
                <p>Objects KPI's LIST</p>
              </div>
              <select
                name="objects"
                onChange={(e) => handleDynamicGoal({ select: e })}
                value={JSON.stringify({
                  id: currentDynamicGoalValue.object.id,
                  name: currentDynamicGoalValue.object.alias,
                  goal: currentDynamicGoalValue.object.goal,
                })}
              >
                {allDataKpiCharts
                  .filter(
                    (elm) =>
                      elm._id !== dataChart._id &&
                      // elm.goal &&
                      elm.fromType === "objects"
                  )
                  .map((elm, index) => {
                    const optionValue = JSON.stringify({
                      id: elm._id,
                      name: elm.alias,
                      goal: fixedNumber(elm.count, currentNoDecimals),
                    });

                    return (
                      <option
                        key={index}
                        value={optionValue}
                        // disabled={!currentDynamicGoalValue.object.status}
                      >
                        {elm.alias}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="section-style">
              <div className="dynamic_goal">
                <input
                  type="checkbox"
                  name="components"
                  checked={currentDynamicGoalValue.components.status}
                  onClick={(e) => handleDynamicGoal({ checkbox: e })}
                />
                <p>Components KPI's LIST</p>
              </div>
              <select
                name="components"
                onChange={(e) => handleDynamicGoal({ select: e })}
                value={JSON.stringify({
                  id: currentDynamicGoalValue.components.id,
                  name: currentDynamicGoalValue.components.alias,
                  goal: currentDynamicGoalValue.components.goal,
                })}
              >
                {allDataKpiCharts
                  .filter(
                    (elm) =>
                      elm._id !== dataChart._id &&
                      // elm.goal &&
                      elm.fromType === "components"
                  )
                  .map((elm, index) => {
                    const optionValue = JSON.stringify({
                      id: elm._id,
                      name: elm.alias,
                      goal: fixedNumber(elm.count, currentNoDecimals),
                    });
                    return (
                      <option
                        key={index}
                        value={optionValue}
                        // disabled={!currentDynamicGoalValue.components.status}
                      >
                        {elm.alias}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="section-style">
              <div className="dynamic_goal">
                <input
                  type="checkbox"
                  name="events"
                  checked={currentDynamicGoalValue.events.status}
                  onClick={(e) => handleDynamicGoal({ checkbox: e })}
                />
                <p>Events KPI's LIST</p>
              </div>
              <select
                name="events"
                onChange={(e) => handleDynamicGoal({ select: e })}
                value={JSON.stringify({
                  id: currentDynamicGoalValue.events.id,
                  name: currentDynamicGoalValue.events.alias,
                  goal: currentDynamicGoalValue.events.goal,
                })}
              >
                {allDataKpiCharts
                  .filter(
                    (elm) =>
                      elm._id !== dataChart._id &&
                      // elm.goal &&
                      elm.fromType === "events"
                  )
                  .map((elm, index) => {
                    const optionValue = JSON.stringify({
                      id: elm._id,
                      name: elm.alias,
                      goal: fixedNumber(elm.count, currentNoDecimals),
                    });

                    return (
                      <option
                        key={index}
                        value={optionValue}
                        // disabled={!currentDynamicGoalValue.events.status}
                      >
                        {elm.alias}
                      </option>
                    );
                  })}
              </select>
            </div>

            {selectDynamicGoalValue &&
              selectDynamicGoalValue.value.alias !== "currentKpi" && (
                <div className="section-style">
                  <p>Dynamic Goal Value</p>
                  <div>
                    <h3>{selectDynamicGoalValue.value.goal}</h3>
                  </div>
                </div>
              )}

            <div className="section-style">
              <div className="dynamic_goal">
                <input
                  type="checkbox"
                  name="currentKpi"
                  checked={currentDynamicGoalValue.currentKpi.status}
                  onClick={(e) => handleDynamicGoal({ checkbox: e })}
                />
                <p>Fixed Goal Value</p>
              </div>
              <input
                value={currentGoal}
                type="number"
                disabled={!currentDynamicGoalValue.currentKpi.status}
                onChange={(e) => {
                  setCurrentGoal(e.target.value);
                  setCurrentDynamicGoalValue((current) => ({
                    ...current,
                    currentKpi: {
                      ...current.currentKpi,
                      goal: e.target.value,
                    },
                  }));
                }}
                placeholder={`${currentGoal ? currentGoal : "None"}`}
              />
            </div>

            <div className="section-style">
              <p>Font Color</p>
              <input
                className="alias"
                type="color"
                value={currentMainFontColor}
                onChange={(e) => {
                  setCurrentMaintFontColor(e.target.value);
                }}
              />
            </div>

            <div className="section-style">
              <p>KPI Font Size</p>
              <div>
                <h4>{currentMainFontSize} px</h4>
                <input
                  value={currentMainFontSize}
                  type="range"
                  step={"1"}
                  min={"10"}
                  max={Math.round(maxHeight - 100 / 2)}
                  onChange={(e) => setCurrentMaintFontSize(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="groupe-styles">
          <div className="sections">
            <h3>Decimals</h3>
            <div className="section-style">
              <p>Num Decimals</p>
              <input
                value={currentNoDecimals}
                type="number"
                onChange={(e) => setCurrentNoDecimals(e.target.value)}
                placeholder={"Number"}
              />
            </div>
          </div>
        </div>

        {/* 
        {!error && libraries && (
          <SelectLibrary
            libraries={libraries}
            handlerLibrarySelected={handlerLibrarySelected}
            librarySelected={dataInputs.librarySelected}
          />
        )}
        {dataInputs.librarySelected && (
          <SelectTypeOperation
            handlerSelectTypeOperation={handlerTypeOperation}
            typeOperationSelect={dataInputs.typeOperation}
          />
        )}
        {dataInputs.librarySelected &&
          columnsTypeNumber.length === 0 &&
          dataInputs.typeOperation.value === "sum" && (
            <div>
              <p style={{ color: RED_COLOR }}>
                The library {dataInputs.librarySelected.label} does not have
                numeric fields.
              </p>
            </div>
          )}
        {dataInputs.librarySelected &&
          columnsTypeNumber.length > 0 &&
          dataInputs.typeOperation.value === "sum" && (
            <SelectColumnLibrary
              columnsLibrary={columnsTypeNumber}
              handlerClickColumn={handlerClickMetric}
              columnSelected={dataInputs.metric}
              title={"Select metric"}
            />
          )}
        {dataInputs.librarySelected && columnsLibrary && (
          <SelectColumnsLibrary
            columnsLibrary={columnsLibrary}
            handlerClickColumn={handlerClickColumn}
            columnsSelected={columnsSelected}
            columnsWithValues={columnsWithValues}
            handlerColumnValues={handlerColumnValues}
            columnValues={columnValues}
            title={"Set filters"}
          />
        )} */}
      </div>
    </ContainerPreviewChart>
  );
};

export default PreviewKpiChart;
