import { Marker } from "react-map-gl";

const MarkerLabel = ({ latitude, longitude, label }) => {
  return (
    <Marker
      style={{
        marginTop: "25px",
      }}
      latitude={Number(latitude)}
      longitude={Number(longitude)}
    >
      <p>{label}</p>
    </Marker>
  );
};

export default MarkerLabel;
