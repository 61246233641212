import axios from "axios";
import { config } from "../../config";

const filterLineForMG = (bodyline) => {
  return {
    userId: bodyline.userId,
    type: bodyline.type,
    feature: bodyline.feature,
    attributes: bodyline.attributes,
  };
};

const filterLineForPg = ({ mode, bodyline, objectsLineIds }) => {
  if (mode === "freeline")
    return {
      userId: parseInt(bodyline.userId),
      lineTypeId: parseInt(bodyline.lineTypeId),
      geojsonLineId: bodyline.geojsonLineId,
      adminCompanyId: parseInt(bodyline.adminCompanyId),
    };
  return {
    userId: bodyline.userId,
    lineTypeId: bodyline.lineTypeId,
    geojsonLineId: bodyline.geojsonLineId,
    adminCompanyId: bodyline.adminCompanyId,
    objectsIds: objectsLineIds,
  };
};

export const saveLineApiPg = async (body, objectsLineIds) => {
  const token = localStorage.getItem("token");
  try {
    const dataFilteredMg = filterLineForMG(body);
    const { data: mgResponse } = await saveLineApiMg(dataFilteredMg);
    if (mgResponse) {
      body.geojsonLineId = mgResponse._id;
      const dataFilteredPg = filterLineForPg({
        bodyline: body,
        objectsLineIds,
      });

      const res = await axios.post(
        `${config.URL_BACKEND_PG}api/v1/lines/objects`,
        dataFilteredPg,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const data = await res.data;
      return { data, res };
    }
    return { body };
  } catch (error) {
    console.error(error);
  }
};

export const saveLineApiMg = async (regionGeoJson) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(`${config.URL_BACKEND_MG}line`, regionGeoJson, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export const saveFreeLineApiPg = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const dataFilteredMg = filterLineForMG(body);
    const { data: mgResponse } = await saveLineApiMg(dataFilteredMg);
    if (mgResponse) {
      body.geojsonLineId = mgResponse._id;
      const dataFilteredPg = filterLineForPg({
        mode: "freeline",
        bodyline: body,
      });

      const res = await axios.post(
        `${config.URL_BACKEND_PG}api/v1/lines`,
        dataFilteredPg,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const data = await res.data;
      return { data, res };
    }
    return { body };
  } catch (error) {
    console.error(error);
  }
};
