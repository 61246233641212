export const config = {
  ACCESS_KEY_ID: process.env.REACT_APP_ACCESS_KEY_ID,
  SECRET_ACCESS_KEY: process.env.REACT_APP_SECRET_ACCESS_KEY,
  BUCKET_NAME: process.env.REACT_APP_BUCKET_NAME,
  URL_BACKEND_PG: process.env.REACT_APP_URL_BACKEND,
  URL_BACKEND_MG: process.env.REACT_APP_URL_MG_API,
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
  USER_TOKEN: localStorage.getItem("token"),
  ADMIN_COMPANY_ID: localStorage.getItem("adminCompanyId"),
  USER_ID: localStorage.getItem("userId"),
  ROLE: localStorage.getItem("role"),
};
