import ObjectsCharts from "./Charts/ObjectsCharts.jsx";
import ComponentsCharts from "./Charts/ComponentsCharts.jsx";
import EventsCharts from "./Charts/EventsCharts.jsx";
import AllTypesCharts from "./Charts/AllTypesCharts.jsx";

const AdminTabs = ({
  openDialog,
  objects,
  loading,
  components,
  loadingComponents,
  events,
  loadingEvents,
  typeChart,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  openDialogRestoreChart,
  setOpenDialogRestoreChart,
  orderId,
}) => {
  return (
    <div>
      {typeChart === "objects" && (
        <ObjectsCharts
          openDialog={openDialog}
          objects={objects}
          loading={loading}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          currentPositionId={currentPositionId}
          setCurrentTemplate={setCurrentTemplate}
          orderId={orderId}
        />
      )}

      {typeChart === "components" && (
        <ComponentsCharts
          openDialog={openDialog}
          components={components}
          loading={loadingComponents}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          currentPositionId={currentPositionId}
          setCurrentTemplate={setCurrentTemplate}
          orderId={orderId}
        />
      )}

      {typeChart === "events" && (
        <EventsCharts
          openDialog={openDialog}
          events={events}
          loading={loadingEvents}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          currentPositionId={currentPositionId}
          setCurrentTemplate={setCurrentTemplate}
          orderId={orderId}
        />
      )}
      {typeChart === "allTypes" && (
        <>
          <AllTypesCharts
            openDialog={openDialog}
            objects={objects}
            components={components}
            events={events}
            loading={loading}
            privateViews={privateViews}
            publicViews={publicViews}
            currentOrderName={currentOrderName}
            setCurrentOrderViews={setCurrentOrderViews}
            currentOrderViews={currentOrderViews}
            currentTemplate={currentTemplate}
            currentPositionId={currentPositionId}
            setCurrentTemplate={setCurrentTemplate}
            openDialogRestoreChart={openDialogRestoreChart}
            setOpenDialogRestoreChart={setOpenDialogRestoreChart}
            orderId={orderId}
          />
        </>
      )}
    </div>
  );
};

export default AdminTabs;
