import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { LibToolTip } from "./ObjectDetailsElements";
// Hooks
import useCountEventsToOneObject from "hooks/useCountEventsToOneObject";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  INFORMATION_COLOR,
  WHITE_COLOR,
} from "utils/const";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { useContext } from "react";
import { ThemeContext } from "App";

const getStyle = (theme) => ({
  color: theme === "light" ? document.body.style.color : WHITE_COLOR,
  background: theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
});

const QuantityEvents = ({ objectId, setShowDeleteButton }) => {
  const { amountEvents, totalAmountEvents, totalAmountEventsNoGeog } =
    useCountEventsToOneObject({
      objectId,
      setShowDeleteButton,
    });
  const { theme } = useContext(ThemeContext);
  const colorStyle = getStyle(theme);

  return (
    <>
      {amountEvents?.dynamics?.length > 0 && (
        <div className="main-currency-table">
          <p
            className="currency-table--title"
            style={{
              color:
                theme === "light"
                  ? "var(--background-primary_color)"
                  : WHITE_COLOR,
            }}
          >
            Events
          </p>
          <div className="currency-table--container">
            <table>
              {amountEvents.dynamics &&
                amountEvents.dynamics.map((it) => {
                  return (
                    <tr style={colorStyle}>
                      <td className="table__bottom-left">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {it.typeName}

                          <SimpleToolTip
                            title={`Library: ${it.libName}`}
                            placement="right"
                          >
                            <LocalLibraryIcon
                              sx={{
                                fontSize: "1.8rem",
                                color: INFORMATION_COLOR,
                              }}
                            />
                          </SimpleToolTip>
                        </div>
                      </td>
                      <td className="table__bottom-right table__right">
                        {it.quantity}
                      </td>
                    </tr>
                  );
                })}
              <tr style={colorStyle}>
                <td className="table__bottom-left">
                  <LibToolTip>
                    <b>Total events</b>
                  </LibToolTip>
                </td>
                <td className="table__bottom-right table__right">
                  <b>{totalAmountEvents}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
      {amountEvents?.dynamicsNoGeographic?.length > 0 && (
        <div className="main-currency-table">
          <p
            className="currency-table--title"
            style={{
              color:
                theme === "light"
                  ? "var(--background-primary_color)"
                  : WHITE_COLOR,
            }}
          >
            Non-Georeferenced Event
          </p>
          <div className="currency-table--container">
            <table>
              {amountEvents.dynamicsNoGeographic &&
                amountEvents.dynamicsNoGeographic.map((it) => {
                  return (
                    <tr style={colorStyle}>
                      <td className="table__bottom-left">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {it.typeName}

                          <SimpleToolTip
                            title={`Library: ${it.libName}`}
                            placement="right"
                          >
                            <LocalLibraryIcon
                              sx={{
                                fontSize: "1.8rem",
                                color: INFORMATION_COLOR,
                              }}
                            />
                          </SimpleToolTip>
                        </div>
                      </td>
                      <td className="table__bottom-right table__right">
                        {it.quantity}
                      </td>
                    </tr>
                  );
                })}
              <tr style={colorStyle}>
                <td className="table__bottom-left">
                  <LibToolTip>
                    <b>Total events</b>
                  </LibToolTip>
                </td>
                <td className="table__bottom-right table__right">
                  <b>{totalAmountEventsNoGeog}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default QuantityEvents;
