import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const ReportIcon = () => {
  return (
    <>
      <svg
        id="Capa_50"
        data-name="Capa 50"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`.cls-1rp {
        fill: #fff;
        stroke-width: 6px;
      }

      .cls-4rpx {
        fill: #f4f4f4;
      }

      .cls-1rp, .cls-2rp, .cls-3rp {
        stroke: ${PRIMARY_COLOR};
        stroke-miterlimit: 10;
      }

      .cls-2rp, .cls-3rp {
        stroke-width: 3px;
      }

      .cls-2rp, .cls-4rp {
        fill: ${SECOND_COLOR};
      }


      .cls-5rp {
        fill: ${PRIMARY_COLOR};
      }

      .cls-5rp, .cls-4rp {
        stroke-width: 0px;
      }

      .cls-3rp {
        fill: none;
      }`}
          </style>
        </defs>
        <rect
          class="cls-1rp"
          x="113.1"
          y="90.73"
          width="183.76"
          height="251"
          rx="15.39"
          ry="15.39"
        />
        <g>
          <rect
            class="cls-1rp"
            x="95.65"
            y="74.8"
            width="183.76"
            height="251"
            rx="15.39"
            ry="15.39"
          />
          <circle class="cls-2rp" cx="150.88" cy="128.72" r="36.61" />
          <path
            class="cls-2rp"
            d="M150.88,128.72h36.61c0,20.09-16.53,36.61-36.61,36.61s-36.61-16.53-36.61-36.61,16.53-36.61,36.61-36.61c.12,0,.23,0,.35,0l-.35,36.61Z"
          />
          <rect
            class="cls-2rp"
            x="129.1"
            y="250.34"
            width="13.39"
            height="54.47"
            rx="1.42"
            ry="1.42"
          />
          <rect
            class="cls-2rp"
            x="155.89"
            y="263.73"
            width="13.39"
            height="41.08"
            rx="1.42"
            ry="1.42"
          />
          <rect
            class="cls-2rp"
            x="181.79"
            y="278.47"
            width="13.39"
            height="26.34"
            rx="1.36"
            ry="1.36"
          />
          <rect
            class="cls-2rp"
            x="184.71"
            y="184.37"
            width="65.19"
            height="39.29"
            rx="6.85"
            ry="6.85"
          />
          <rect
            class="cls-4rp"
            x="125.35"
            y="188.38"
            width="51.05"
            height="6.25"
            rx="2.74"
            ry="2.74"
          />
          <rect
            class="cls-4rp"
            x="125.35"
            y="203.56"
            width="51.05"
            height="6.25"
            rx="2.74"
            ry="2.74"
          />
          <rect
            class="cls-4rp"
            x="125.35"
            y="217.68"
            width="51.05"
            height="6.25"
            rx="2.74"
            ry="2.74"
          />
          <rect
            class="cls-4rp"
            x="195.99"
            y="128.72"
            width="51.05"
            height="6.25"
            rx="2.74"
            ry="2.74"
          />
          <rect
            class="cls-4rp"
            x="195.99"
            y="143.9"
            width="51.05"
            height="6.25"
            rx="2.74"
            ry="2.74"
          />
          <rect
            class="cls-4rp"
            x="195.99"
            y="113.54"
            width="51.05"
            height="6.25"
            rx="2.74"
            ry="2.74"
          />
          <g>
            <polyline
              class="cls-3rp"
              points="196.2 278.47 176.4 285.62 160.02 264.14 141.36 272.43 127.11 244.34"
            />
            <polygon
              class="cls-5rp"
              points="133.08 243.22 123.31 236.85 122.68 248.5 133.08 243.22"
            />
          </g>
        </g>
        <g>
          <circle class="cls-5rp" cx="296.86" cy="304.37" r="83.74" />
          <g>
            <path
              class="cls-4rpx"
              d="M296.26,388.99c18.67,0,37.75-7.38,51.85-18.39-2.73-20.63-25.6-34.58-51.85-34.58s-49.02,13.09-51.75,33.72c16.65,13.16,33.08,19.25,51.75,19.25Z"
            />
            <ellipse
              class="cls-4rpx"
              cx="296.26"
              cy="296.71"
              rx="32.16"
              ry="32.79"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default ReportIcon;
