import styled from "styled-components";
import {
  BACKGROUND_DARK,
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  BLACK_COLOR,
  BORDER_DARK,
  BORDER_GREY,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_BACKGROUND,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "../../../utils/const";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  background: red;
  width: 100%;
`;

export const SummaryFeature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0px 0;
  margin-top: 50px;
  h3 {
    display: flex;
    padding: 1%;

    font-size: 1.6rem;
  }

  p {
    margin: 4px 0px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1.6rem;
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid var(--background-primary_color);
    border-radius: 5px;
    padding: 5px;
    background-color: ${WHITE_COLOR};
    font-size: 1.5rem;
  }
  textarea {
    height: 60px;
  }
`;

export const VideoPreview = styled.div`
  display: flex;
  justify-content: center;
`;

export const Label = styled("label")`
  background: var(--background-primary_color);
  width: 60%;
  margin: 0 -15px;
  border-radius: 10px;
  color: ${WHITE_SECOND_COLOR};
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: var(--background-secondary_color);
  }
`;

export const Wrapperbtn = styled("div")`
  position: relative;
  margin-top: 1.5rem;
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: var(--background-primary_color);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;
  border-radius: 10px 10px 0px 0px;

  .neurona {
    width: 34px;
    margin-right: 1px;
    margin-bottom: 6px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: ${WHITE_COLOR};
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentButton = styled.div`
  width: 94%;
  padding: 10px 10px 20px 10px;
`;

export const ContentButtonGallery = styled.div`
  width: 170%;
  padding: 10px 10px 10px 20px;
`;

export const Checkboxarea = styled.div`
  width: 100%;

  margin: 0rem;
  font-size: 1.4rem;
`;

export const CustomizedChevronLeftIcon = styled(ChevronLeftIcon)`
  background-color: var(--background-primary_color);
  border-radius: 23px;
  font-size: 30px;
`;

export const DeleteUserButton = styled.div`
  outline: none;
  border: none;
  padding-left: 15px;
`;

export const ContainerOperations = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 10px;
  /* background-color: ${WHITE_BACKGROUND}; */

  .current-object {
    display: flex;
    justify-content: ${(props) =>
      props.seeArrow ? "space-between" : "center"};
    align-items: center;
    /* background-color: ${BACKGROUND_GREY}; */
    padding: 0.5rem 1rem;
    border-radius: 10px;

    span {
      font-size: 1.6rem;
      background: var(--background-primary_color);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border-radius: 10px;
      color: ${WHITE_COLOR};
      cursor: pointer;
    }

    span.disabled {
      background: #ccc;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .map-container,
  .container-operation-galery,
  .c3,
  .c4,
  .c5,
  .c6 {
    margin: 0.3%;
    width: 100%;
    min-width: 150px;
    max-height: 500px;
    background-color: ${WHITE_COLOR};
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .table-container {
    margin: 10px 10px 10px 0px;
    width: 100%;
    min-width: 99.7%;
    max-height: 100%;
    background-color: ${BACKGROUND_GREY};
    border-radius: 5px;
  }

  .table-container .operation-title {
    display: flex;
    margin: 10px 0px 0px 0px;
  }
  .table-container .operation-title h1 {
    font-size: 1.5rem;
  }

  .table-container .operation-table {
    padding: 0px 24px 24px 24px;
  }

  .operation-type span {
    color: var(--background-primary_color);
  }

  .info-operation {
    display: flex;
    padding: 24px;
    flex-direction: column;
    background-color: ${BACKGROUND_GREY};
    align-items: center;

    height: 660px;
    width: 28.3vw;
    margin: 10px 16px 20px 0px;
    border-radius: 5px;
    overflow-y: scroll;
    .wrapper-buttons {
      justify-content: center;
    }
  }

  @media (max-width: 1279px) {
    .info-operation {
      width: 100%;
    }
  }

  @media (min-width: 1280px) and (max-width: 1620px) {
    .info-operation {
      width: 96.5%;
    }
  }

  @media (min-width: 1621px) {
    .info-operation {
      width: 97%;
    }
  }

  .photo-container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 58.1vw;
    height: 660px;
    /* background-color: ${BACKGROUND_GREY}; */
    margin: 10px 10px 10px 10px;
    border-radius: 5px;
  }

  @media (max-width: 1279px) {
    .photo-container-image {
      width: 100%;
      margin-left: 0px;
    }
  }

  @media (min-width: 1280px) and (max-width: 1620px) {
    .photo-container-image {
      width: 98.7%;
    }
  }

  @media (min-width: 1621px) {
    .photo-container-image {
      width: 99%;
    }
  }
  .bb {
    background-color: ${WHITE_BACKGROUND};
    border: solid 1px ${BORDER_GREY};
  }

  .bb2 {
    background-color: ${BACKGROUND_DARK};
    border: solid 1px ${BORDER_DARK};
  }

  .bb3 {
    background-color: ${BACKGROUND_SECOND_DARK};
    border: solid 1px ${BORDER_DARK};
  }
  .operation-title {
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
  }

  .operation-title span {
    /* color: var(--background-primary_color); */
    align-self: center;
    text-align: center;
    padding: 0 0 1rem 1rem;
  }

  .operation-details {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .details-item {
    padding: 0 10px;
    font-size: 1.4rem;
    padding: 5px 10px;
    border-radius: 0;
    color: ${BLACK_COLOR};
  }
  .details-item span {
    color: #595959;
  }

  .wrapper-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }

  .button-load {
    height: 50px;
    width: 50%;
    border: none;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    background-color: var(--background-primary_color);
    color: ${WHITE_COLOR};
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
  }

  .content-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 550px;

    /* background-color: ${BACKGROUND_GREY}; */
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
  }

  .map-container {
    height: 100%;
  }

  .photo-container-image .gallery {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    overflow: hidden;
  }

  .photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 550px;
    width: 28.3vw;
    border-radius: 5px;
  }

  @media (max-width: 1279px) {
    .photo-container {
      width: 100%;
      margin-left: 0px;
    }
  }

  @media (min-width: 1280px) and (max-width: 1620px) {
    .photo-container {
      width: 100%;
    }
  }

  @media (min-width: 1621px) {
    .photo-container {
      width: 100%;
    }
  }
  .photo-container img {
    width: 85%;
    height: 400px;
    object-fit: cover;
  }

  .leak-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 95.5%;
    margin: 10px 0px 20px 0px;
    border-radius: 5px;
    background-color: ${WHITE_COLOR};
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .leak-container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 650px;
    background-color: ${WHITE_COLOR};
    margin: 10px 0px 0px 0px;
    border-radius: 5px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .leak-container-image .gallery {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    overflow: hidden;
  }

  .leak-container .operation-title h1 {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    font-size: 1.5rem;
  }

  .photo-container .operation-title h1 {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    font-size: 1.5rem;
  }

  .c3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container-videos {
    display: flex;
    width: 100%;
  }

  .container-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0;
  }

  .container-video .video {
    position: relative;
    text-align: center;
    object-fit: contain;
    opacity: 0.9;
  }

  .container-video .video:hover {
    opacity: 1;
  }

  .videoProcessed {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    object-fit: contain;
    justify-content: center;
    align-items: center;
  }

  .videoProcessed .button {
    width: 100%;
    background: var(--background-primary_color);
    border-radius: 10px;
  }

  .container-video .videoProcessed .button span {
    display: block;
    position: relative;
    z-index: 3;
    border-left: 22px solid ${WHITE_COLOR};
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }

  .container-video .videoProcessed .button:hover {
    background: var(--background-secondary_color);
  }

  .container-video .video .button {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: var(--background-primary_color);
    border-radius: 50%;
    padding: 20px;
    cursor: pointer;
  }

  .article {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  @media (max-width: 1000px) {
    .article {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .container-video .video .button:hover {
    background: var(--background-secondary_color);
  }

  .container-video .video-text {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: var(--background-primary_color);
    font-weight: bold;
  }

  .container-video .video-text-pdf {
    font-size: 1.4rem;
    margin-bottom: 10px;
    margin-left: 65%;
    color: var(--background-primary_color);
    font-weight: bold;
  }

  .container-video .video-text-ppm {
    font-size: 1.4rem;
    margin-left: 10%;
    margin-bottom: 10px;
    color: var(--background-primary_color);
    font-weight: bold;
  }

  .container-video-360 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: ${WHITE_COLOR};
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .download-video {
    margin: 10px 0;
  }
  .download-video b {
    font-size: 1.4rem;
  }
  .download-video a {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 666px) {
    .photo-container-image .gallery {
      width: 80%;
    }
    .leak-container-image .gallery {
      width: 80%;
    }
  }
  @media screen and (min-width: 850px) {
    .container-operation-galery,
    .c3 {
      width: 49.4%;
    }
    .c4,
    .c5 {
      width: 49.4%;
    }
    .photo-container-image .gallery {
      width: 60%;
    }
    .leak-container-image .gallery {
      width: 60%;
    }
  }

  @media screen and (min-width: 1100px) {
    .map-container {
      width: 59.8%;
    }
    .table-container {
      width: 100%;
    }
    .container-operation-galery {
      width: 39%;
    }
    .c3,
    .c4 {
      width: 33%;
    }
    .c5 {
      width: 32.1%;
    }
    .c6 {
      width: 100%;
    }
    .photo-container-image .gallery {
      width: 50%;
    }
    .leak-container-image .gallery {
      width: 50%;
    }
  }
`;

export const WraperTitleCardMarker = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .wraper-title-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  span {
    color: var(--background-primary_color);
  }
  h1 {
    margin-top: 15px;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #636363;
  }
  img {
    width: 40px;
    height: 40px;
    margin: 5px 0;
  }
`;

export const VideoFlir = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: ${({ width }) => width};
  min-height: 200px;
`;

export const VideoFlirProcessed = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: ${({ width }) => width};
  min-height: 200px;
`;
