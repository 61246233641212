export function checkFilter(object, filter) {
  return filter.some((dataFilter) => {
    for (const key in dataFilter) {
      if (object[key] !== dataFilter[key]) {
        return false;
      }
    }
    return true;
  });
}
