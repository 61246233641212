import axios from "axios";
import { config } from "config.js";

const removeFieldVideoWithoutEvent = async (videoId) => {
  const token = localStorage.getItem("token");
  const result = await axios.delete(
    `${config.URL_BACKEND_PG}api/v1/field-video/${videoId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await result.data;
  return data;
};

export default removeFieldVideoWithoutEvent;
