import React from "react";
import { BLACK_COLOR } from "utils/const";

const NoImage = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        className="colorimg"
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={BLACK_COLOR}
        stroke="none"
      >
        <path
          d="M365 4956 c-62 -28 -115 -110 -115 -176 0 -61 30 -104 171 -246 l140
-141 -76 -74 c-129 -124 -239 -277 -305 -423 -80 -176 -143 -532 -170 -956
-39 -637 45 -1382 189 -1668 127 -251 376 -499 630 -627 151 -75 289 -110 571
-144 756 -93 1549 -93 2325 0 262 31 388 62 537 131 l69 31 242 -241 c155
-154 256 -247 280 -257 99 -41 202 0 248 100 23 49 24 93 3 145 -12 30 -521
539 -2272 2272 -1240 1228 -2271 2244 -2289 2257 -41 30 -132 39 -178 17z
m844 -1200 c36 -36 63 -67 60 -68 -2 -2 -22 -12 -43 -23 -58 -29 -148 -116
-179 -172 -99 -183 -65 -399 85 -538 175 -163 427 -164 604 -3 28 26 64 72 80
103 16 30 33 55 37 55 5 0 181 -172 393 -382 l385 -383 -121 -155 c-66 -85
-167 -219 -223 -297 -56 -78 -105 -142 -108 -143 -3 0 -40 44 -83 97 -43 54
-132 162 -199 241 -176 207 -247 249 -437 259 -159 9 -281 -35 -380 -136 -99
-102 -378 -445 -515 -634 -27 -37 -53 -63 -57 -59 -23 23 -85 382 -108 622
-48 524 6 1287 112 1564 42 109 131 237 233 335 l86 82 157 -150 c86 -82 186
-179 221 -215z"
        />
        <path
          d="M2165 4774 c-306 -16 -804 -61 -817 -73 -4 -4 68 -82 160 -174 l169
-168 224 18 c309 24 1012 24 1319 0 500 -40 712 -72 845 -127 225 -94 451
-319 540 -538 83 -203 144 -784 131 -1237 -6 -217 -27 -489 -38 -500 -2 -3
-44 48 -93 113 -246 325 -595 740 -668 794 -65 48 -175 97 -257 114 -94 20
-289 14 -370 -11 -68 -21 -160 -64 -160 -74 0 -5 387 -392 860 -860 688 -682
864 -850 875 -841 23 19 95 195 120 294 86 341 133 956 106 1411 -28 469 -96
837 -190 1023 -127 252 -374 498 -629 626 -204 102 -420 142 -1042 192 -184
14 -919 27 -1085 18z"
        />
      </g>
    </svg>
  );
};

export default NoImage;
