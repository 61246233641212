export const setLabelsToObjects = ({ objects, objectLibraries }) => {
  if (objects.length > 0 && objects[0].length > 0)
    objects[0]?.forEach((object) => {
      const { attributes, typeElement } = object;
      const pointLibraryId = typeElement?.pointLibraryId;

      const objectLibrary = objectLibraries?.filter(
        (library) => library.id === pointLibraryId
      )[0];
      const stringFields = objectLibrary?.fields?.filter(
        (field) => field?.type === "string" && field?.header === true
      )[0];

      const label = attributes?.find(
        (attribute) => attribute?.name === stringFields?.name
      );
      object.label = label?.value;
    });
};

export const setLabelsToComponents = ({ components, componentLibraries }) => {
  if (components.length > 0 && components[0].length > 0)
    components[0]?.forEach((component) => {
      const { attributes, pointTypeComponent } = component;
      const pointLibraryId = pointTypeComponent?.pointLibraryComponentId;

      const componentLibrary = componentLibraries?.filter(
        (library) => library.id === pointLibraryId
      )[0];
      const stringFields = componentLibrary?.fields?.filter(
        (field) => field.type === "string" && field.header === true
      )[0];

      const label = attributes.find(
        (attribute) => attribute.name === stringFields?.name
      );
      component.label = label?.value;
    });
};

export const setLabelsToEvents = ({ events, eventLibraries }) => {
  if (events.length > 0)
    events?.forEach((event) => {
      const { attributes, pointTypeEvent } = event;
      const pointLibraryId = pointTypeEvent?.pointLibraryEventId;

      const eventLibrary = eventLibraries?.filter(
        (library) => library.id === pointLibraryId
      )[0];
      const stringFields = eventLibrary?.fields?.filter(
        (field) => field.type === "string" && field.header === true
      )[0];

      const label = attributes.find(
        (attribute) => attribute.name === stringFields?.name
      );
      event.label = label?.value;
    });
};
