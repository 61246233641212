// Required modules external libraries
import { Layer, Popup, Source } from "react-map-gl";
import { useEffect, useState, useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useSWRConfig } from "swr";

// Required modules internal libraries
import useGet from "hooks/useFetch";
import { PopUpmap } from "./MapElements";
import DeletePolygonDialog from "components/Dialogs/DeletePolygon";
import {
  setDataRegion,
  showRegionDetailsDrawer,
  setGeometryPolygon,
  setDataObjectsPolygon,
} from "redux/actions/admin";
import { setUpdateRegion } from "redux/actions/index";
import deletePolygon from "services/deletePolygon";
import { config } from "config.js";

export default function Regions({
  layerIds,
  setLayerIds,
  dataRegions,
  popUpRegions,
}) {
  // Constants
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // Find data
  // Find all type regions
  const [typeRegions] = useGet(`api/v1/type-region`);

  // Redux
  const dispatch = useDispatch();
  const drawerControl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );
  const map = useSelector((state) => state.digitalTwinReducer.map);
  const updateRegion = useSelector(
    (state) => state.digitalTwinReducer.updateRegion
  );
  const { id: idUpdateRegion } = updateRegion;

  // Mutate to SWR auto update specific path
  const { mutate } = useSWRConfig();

  // Individual region render
  const Region = useCallback(
    ({ region, id }) => {
      if (idUpdateRegion && `update-${region._id}` === idUpdateRegion) {
        return false;
      }
      const features = region?.data?.features;
      const featuresFilter = features.filter(
        (feature) => feature.geometry.type === "Polygon"
      );

      return featuresFilter.map((feature, key) => {
        const { properties } = feature;
        const { regionTypeId } = properties;
        const typeRegion = typeRegions?.find(
          (type) => type.id === regionTypeId
        );
        if (!typeRegion) return null;
        const { background, opacity, border, width } = typeRegion;
        return (
          <>
            <Source type="geojson" data={feature} key={id}>
              <Layer
                key={key}
                id={region._id}
                type="fill"
                paint={{
                  "fill-color": background,
                  "fill-opacity": opacity,
                }}
              />
              <Layer
                id={key}
                type="line"
                paint={{
                  "line-color": border,
                  "line-width": width || 3,
                }}
              />
            </Source>
          </>
        );
      });
    },
    [typeRegions, idUpdateRegion]
  );

  // States
  const [renderRegions, setRenderRegions] = useState(false);
  const [renderPopUp, setRenderPopUp] = useState(false);
  const [popUp, setPopUp] = useState({});
  const [region, setRegion] = useState({});
  const [openDialogDeletePolygon, setOpenDialogDeletePolygon] = useState(false);
  const [lastLayerId, setLastLayerId] = useState("last-layer-id");

  // Evalue if exist thge layer id in the array
  const existLayerId = useCallback(
    (id) => {
      const exist = layerIds.find((layerId) => {
        return layerId === id;
      });
      return exist ? true : false;
    },
    [layerIds]
  );

  // Open details of polygon in drawer
  const openRegionDetails = (event) => {
    dispatch(setDataRegion(event));
    dispatch(showRegionDetailsDrawer(true));
  };

  // Find region by id
  const findRegion = (id) => {
    return dataRegions?.find((region) => region._id === id);
  };

  // Remove layer id of the array
  const removeLayerId = (id) => {
    const deleteLayerIndex = layerIds.findIndex((layerId) => layerId === id);
    const newLayerIds = layerIds
      .slice(0, deleteLayerIndex)
      .concat(layerIds.slice(deleteLayerIndex + 1, layerIds.length));
    setLayerIds(() => newLayerIds);
  };

  // Handle action to update region
  const handleUpdateRegion = (regionData) => {
    const { layer, properties } = regionData;
    const { id } = layer;
    removeLayerId(id);
    const newId = `update-${id}`;
    const { data } = findRegion(id);
    dispatch(
      setUpdateRegion({
        id: newId,
        isUpdate: true,
        data: { ...properties },
      })
    );
    data.features[0].id = newId;
    drawerControl.set({ ...data });
    const [feature] = drawerControl.getAll().features;
    dispatch(setGeometryPolygon(feature.geometry));
  };

  // Reset global data of polygon
  const resetDataPolygon = () => {
    dispatch(setGeometryPolygon(null));
    dispatch(setDataObjectsPolygon([]));
  };

  // Handle action to delete region
  const handleDeleteRegion = async (regionData) => {
    setOpenDialogDeletePolygon(false);
    const { layer } = regionData;
    const { id } = layer;
    await deletePolygon(id);
    resetDataPolygon();
    await mutate(
      `${config.URL_BACKEND_MG}region?admin_company_id=${adminCompanyId}`
    );
    removeLayerId(id);
  };

  // Action to move region to backward or forward
  const moveRegion = (regionData, move) => {
    const { id } = regionData.layer;
    if (move === "Backward") {
      map.moveLayer(id, lastLayerId);
      setLastLayerId(() => id);
    }
    if (move === "Forward") map.moveLayer(id, "first-layer-id");
  };

  // Set render popUp
  useEffect(() => {
    if (popUp?.latitude && popUp?.longitude) {
      setRenderPopUp(() => true);
      return;
    }
    setRenderPopUp(() => false);
  }, [popUp.latitude, popUp.longitude]);

  // Set popUp and region with prop popUpRegion
  useEffect(() => {
    setPopUp(() => ({ ...popUpRegions?.popUp }));
    setRegion(() => ({ ...popUpRegions?.region }));
  }, [popUpRegions.popUp, popUpRegions.region]);

  // Save layer ids of each region to interactio with the map
  useEffect(() => {
    if (!dataRegions) return null;
    dataRegions.map((region) => {
      const id = region?._id;
      const exist = existLayerId(id);
      if (exist || `update-${region._id}` === idUpdateRegion) return null;
      setLayerIds((prev) => [...prev, id]);
      return null;
    });
  }, [dataRegions, existLayerId, setLayerIds, idUpdateRegion]);

  // Evalue if data is ready to render
  useEffect(() => {
    if (!typeRegions || typeRegions?.error || !dataRegions) {
      setRenderRegions(() => false);
      return null;
    }
    setRenderRegions(() => true);
  }, [typeRegions, dataRegions]);

  return (
    <>
      <Source type="geojson">
        <Layer id="last-layer-id" type="fill" />
      </Source>
      {renderRegions &&
        dataRegions.map((region, key) => <Region region={region} id={key} />)}
      <Source type="geojson">
        <Layer id="first-layer-id" type="fill" />
      </Source>
      {renderPopUp && popUp.type === "click" && (
        <Popup
          latitude={popUp.latitude}
          longitude={popUp.longitude}
          anchor="bottom"
          onClose={() => setPopUp({})}
          closeButton={false}
        >
          <PopUpmap>
            <p className="specification">
              <span className="label">Name:</span>
              <span className="sub">{region?.properties?.name}</span>
            </p>
            <p className="specification">
              <span className="label">Type:</span>
              <span className="sub">{region?.properties?.regionType}</span>
            </p>
            <p className="specification">
              <span className="label">Operator:</span>
              <span className="sub">{region?.properties?.userName}</span>
            </p>
            <div className="actions">
              <button
                className="button"
                onClick={() => openRegionDetails(region)}
              >
                Details
              </button>
              <button
                className="button"
                onClick={() => handleUpdateRegion(region)}
              >
                Update
              </button>
              <button
                className="button"
                onClick={() => setOpenDialogDeletePolygon(true)}
              >
                <DeleteIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </button>
              <DeletePolygonDialog
                handle={() => handleDeleteRegion(region)}
                openDialog={openDialogDeletePolygon}
                setOpenDialog={setOpenDialogDeletePolygon}
              />
            </div>
          </PopUpmap>
        </Popup>
      )}
      {renderPopUp && popUp.type === "rightClick" && (
        <Popup
          latitude={popUp.latitude}
          longitude={popUp.longitude}
          anchor="bottom"
          onClose={() => setPopUp({})}
          closeButton={false}
        >
          <PopUpmap>
            <div className="actions row little">
              <button
                className="button"
                onClick={() => moveRegion(region, "Forward")}
              >
                Send Forward
              </button>
              <button
                className="button"
                onClick={() => moveRegion(region, "Backward")}
              >
                Send Backward
              </button>
            </div>
          </PopUpmap>
        </Popup>
      )}
    </>
  );
}

// // Required modules external libraries
// import { Layer, Popup, Source } from "react-map-gl";
// import { useEffect, useState, useCallback } from "react";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useDispatch, useSelector } from "react-redux";
// import { useSWRConfig } from "swr";

// // Required modules internal libraries
// import useGet from "hooks/useFetch";
// import { PopUpmap } from "./MapElements";
// import DeletePolygonDialog from "components/Dialogs/DeletePolygon";
// import {
//   setDataRegion,
//   showRegionDetailsDrawer,
//   setGeometryPolygon,
//   setDataObjectsPolygon,
// } from "redux/actions/admin";
// import { setUpdateRegion } from "redux/actions/index";
// import deletePolygon from "services/deletePolygon";
// import { config } from "config.js";

// export default function Regions({
//   layerIds,
//   setLayerIds,
//   dataRegions,
//   popUpregions,
// }) {
//   // Constants
//   const adminCompanyId = localStorage.getItem("adminCompanyId");

//   // Find data
//   // Find all type regions
//   const [typeRegions] = useGet(`api/v1/type-region`);

//   // Redux
//   const dispatch = useDispatch();
//   const drawerControl = useSelector(
//     (state) => state.digitalTwinReducer.drawerControl
//   );
//   const map = useSelector((state) => state.digitalTwinReducer.map);
//   const updateRegion = useSelector(
//     (state) => state.digitalTwinReducer.updateRegion
//   );
//   const { id: idUpdateRegion } = updateRegion;

//   // Mutate to SWR auto update specific path
//   const { mutate } = useSWRConfig();

//   // Individual region render
//   const Region = useCallback(
//     ({ region, id }) => {
//       if (idUpdateRegion && `update-${region._id}` === idUpdateRegion) {
//         return false;
//       }
//       const features = region?.data?.features;
//       const featuresFilter = features.filter(
//         (feature) => feature.geometry.type === "Polygon"
//       );

//       return featuresFilter.map((feature, key) => {
//         const { properties } = feature;
//         const { regionTypeId } = properties;
//         const typeRegion = typeRegions?.find(
//           (type) => type.id === regionTypeId
//         );
//         if (!typeRegion) return null;
//         const { background, opacity, border, width } = typeRegion;
//         return (
//           <>
//             <Source type="geojson" data={feature} key={id}>
//               <Layer
//                 key={key}
//                 id={region._id}
//                 type="fill"
//                 paint={{
//                   "fill-color": background,
//                   "fill-opacity": opacity,
//                 }}
//               />
//               <Layer
//                 id={key}
//                 type="line"
//                 paint={{
//                   "line-color": border,
//                   "line-width": width || 3,
//                 }}
//               />
//             </Source>
//           </>
//         );
//       });
//     },
//     [typeRegions, idUpdateRegion]
//   );

//   // States
//   const [renderRegions, setRenderRegions] = useState(false);
//   const [renderPopUp, setRenderPopUp] = useState(false);
//   const [popUp, setPopUp] = useState({});
//   const [region, setRegion] = useState({});
//   const [openDialogDeletePolygon, setOpenDialogDeletePolygon] = useState(false);
//   const [lastLayerId, setLastLayerId] = useState("last-layer-id");

//   // Evalue if exist thge layer id in the array
//   const existLayerId = useCallback(
//     (id) => {
//       const exist = layerIds.find((layerId) => {
//         return layerId === id;
//       });
//       return exist ? true : false;
//     },
//     [layerIds]
//   );

//   // Open details of polygon in drawer
//   const openRegionDetails = (event) => {
//     dispatch(setDataRegion(event));
//     dispatch(showRegionDetailsDrawer(true));
//   };

//   // Find region by id
//   const findRegion = (id) => {
//     return dataRegions?.find((region) => region._id === id);
//   };

//   // Remove layer id of the array
//   const removeLayerId = (id) => {
//     const deleteLayerIndex = layerIds.findIndex((layerId) => layerId === id);
//     const newLayerIds = layerIds
//       .slice(0, deleteLayerIndex)
//       .concat(layerIds.slice(deleteLayerIndex + 1, layerIds.length));
//     setLayerIds(() => newLayerIds);
//   };

//   // Handle action to update region
//   const handleUpdateRegion = (regionData) => {
//     const { layer, properties } = regionData;
//     const { id } = layer;
//     removeLayerId(id);
//     const newId = `update-${id}`;
//     const { data } = findRegion(id);
//     dispatch(
//       setUpdateRegion({
//         id: newId,
//         isUpdate: true,
//         data: { ...properties },
//       })
//     );
//     data.features[0].id = newId;
//     drawerControl.set({ ...data });
//     const [feature] = drawerControl.getAll().features;
//     dispatch(setGeometryPolygon(feature.geometry));
//   };

//   // Reset global data of polygon
//   const resetDataPolygon = () => {
//     dispatch(setGeometryPolygon(null));
//     dispatch(setDataObjectsPolygon([]));
//   };

//   // Handle action to delete region
//   const handleDeleteRegion = async (regionData) => {
//     setOpenDialogDeletePolygon(false);
//     const { layer } = regionData;
//     const { id } = layer;
//     await deletePolygon(id);
//     resetDataPolygon();
//     await mutate(
//       `${config.URL_BACKEND_MG}region?admin_company_id=${adminCompanyId}`
//     );
//     removeLayerId(id);
//   };

//   // Action to move region to backward or forward
//   const moveRegion = (regionData, move) => {
//     const { id } = regionData.layer;
//     if (move === "Backward") {
//       map.moveLayer(id, lastLayerId);
//       setLastLayerId(() => id);
//     }
//     if (move === "Forward") map.moveLayer(id, "first-layer-id");
//   };

//   // Set render popUp
//   useEffect(() => {
//     if (popUp?.latitude && popUp?.longitude) {
//       setRenderPopUp(() => true);
//       return;
//     }
//     setRenderPopUp(() => false);
//   }, [popUp.latitude, popUp.longitude]);

//   // Set popUp and region with prop popUpRegion
//   useEffect(() => {
//     setPopUp(() => ({ ...popUpregions?.popUp }));
//     setRegion(() => ({ ...popUpregions?.region }));
//   }, [popUpregions.popUp, popUpregions.region]);

//   // Save layer ids of each region to interactio with the map
//   useEffect(() => {
//     if (!dataRegions) return null;
//     dataRegions.map((region) => {
//       const id = region?._id;
//       const exist = existLayerId(id);
//       if (exist || `update-${region._id}` === idUpdateRegion) return null;
//       setLayerIds((prev) => [...prev, id]);
//       return null;
//     });
//   }, [dataRegions, existLayerId, setLayerIds, idUpdateRegion]);

//   // Evalue if data is ready to render
//   useEffect(() => {
//     if (!typeRegions || typeRegions?.error || !dataRegions) {
//       setRenderRegions(() => false);
//       return null;
//     }
//     setRenderRegions(() => true);
//   }, [typeRegions, dataRegions]);

//   return (
//     <>
//       <Source type="geojson">
//         <Layer id="last-layer-id" type="fill" />
//       </Source>
//       {renderRegions &&
//         dataRegions.map((region, key) => <Region region={region} id={key} />)}
//       <Source type="geojson">
//         <Layer id="first-layer-id" type="fill" />
//       </Source>
//       {renderPopUp && popUp.type === "click" && (
//         <Popup
//           latitude={popUp.latitude}
//           longitude={popUp.longitude}
//           anchor="bottom"
//           onClose={() => setPopUp({})}
//           closeButton={false}
//         >
//           <PopUpmap>
//             <p className="specification">
//               <span className="label">Name:</span>
//               <span className="sub">{region?.properties?.name}</span>
//             </p>
//             <p className="specification">
//               <span className="label">Type:</span>
//               <span className="sub">{region?.properties?.regionType}</span>
//             </p>
//             <p className="specification">
//               <span className="label">Operator:</span>
//               <span className="sub">{region?.properties?.userName}</span>
//             </p>
//             <div className="actions">
//               <button
//                 className="button"
//                 onClick={() => openRegionDetails(region)}
//               >
//                 Details
//               </button>
//               <button
//                 className="button"
//                 onClick={() => handleUpdateRegion(region)}
//               >
//                 Update
//               </button>
//               <button
//                 className="button"
//                 onClick={() => setOpenDialogDeletePolygon(true)}
//               >
//                 <DeleteIcon
//                   sx={{
//                     width: "16px",
//                     height: "16px",
//                   }}
//                 />
//               </button>
//               <DeletePolygonDialog
//                 handle={() => handleDeleteRegion(region)}
//                 openDialog={openDialogDeletePolygon}
//                 setOpenDialog={setOpenDialogDeletePolygon}
//               />
//             </div>
//           </PopUpmap>
//         </Popup>
//       )}
//       {renderPopUp && popUp.type === "rightClick" && (
//         <Popup
//           latitude={popUp.latitude}
//           longitude={popUp.longitude}
//           anchor="bottom"
//           onClose={() => setPopUp({})}
//           closeButton={false}
//         >
//           <PopUpmap>
//             <div className="actions row little">
//               <button
//                 className="button"
//                 onClick={() => moveRegion(region, "Forward")}
//               >
//                 Send Forward
//               </button>
//               <button
//                 className="button"
//                 onClick={() => moveRegion(region, "Backward")}
//               >
//                 Send Backward
//               </button>
//             </div>
//           </PopUpmap>
//         </Popup>
//       )}
//     </>
//   );
// }
