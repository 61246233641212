import styled from "styled-components";
import { PRIMARY_COLOR, OBJECT_CLUSTER_COLOR, WHITE_SECOND_COLOR } from "utils/const";

export const SelectEvent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  select {
    width: 100%;
    height: 40px;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    outline: none;
  }
`;

export const DrawerMapStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 15px;
`;

export const ButtonMapStyle = styled.button`
  width: 175px;
  height: 60px;
  border-radius: 4px;
  border: none;
  /* background-color: (${PRIMARY_COLOR}, 0.5); */
  background-color: rgba(0, 0, 0, 0.08);
  color: black;
  font-size: 2rem;
  /* font-weight: normal; */
  padding: 10px;
  margin: 3px;
  text-align: center;
  display: flex;
  margin-top: 10px;
  align-items: center;

  &:hover {
    background-color: rgba(14, 77, 69, 0.7);
    cursor: pointer;
  }
`;

export const ImgButton = styled.img`
  width: 50px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
`;

export const ImgIconOperation = styled.img`
  cursor: pointer;
`;

export const MapCardContainer = styled.div`
  height: 200hv;
  width: 200hv;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 50px;
  margin-top: 50px;
  align-items: center;
  background-color: ${WHITE_SECOND_COLOR};
  margin-bottom: 100px;
`;
export const MapCard = styled.div`
  position: relative;
  /* align-content: stretch; */
  overflow: hidden;
  /* object-fit: cover; */
  /* object-fit: fill; */

  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
`;

export const NamePlateMarker = styled.button`
  background: none;
  border: none;
  position: relative;

  img {
    width: 40px;
  }

  .newPosition {
    width: 40px;
    opacity: 0.5;
  }

  .selected {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.marked ? "block" : "none")};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      opacity: 0.6;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: white;
  }
`;
