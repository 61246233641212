import { useCallback, useEffect, useState } from "react";
// Zustand
import useStoreDashboard from "../Store";
// Constants
import {
  GRAY_COLOR,
  BLACK_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  SET_VISIBILITY_ICON,
} from "utils/const";
import { roles } from "utils/roles";
import { config } from "config";
// Styles
import {
  AddFilterDate,
  BackgroundDashboard,
  ContainerDataPickers,
  ContainerSelect,
  ContainerSelectAndDeletView,
  ContainerToolBar,
  ContainerTypeView,
  FloatingView,
  SelectFilterDate,
  SelectRangeDateStyle,
  SelectTypesStyles,
  SelectViewStyles,
  ToolBarDashBoard,
} from "./style";
import CancelIcon from "@mui/icons-material/Cancel";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import SyncIcon from "@mui/icons-material/Sync";
import PushPinIcon from "@mui/icons-material/PushPin";
import AddchartIcon from "@mui/icons-material/Addchart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import RoomIcon from "@mui/icons-material/Room";
import HexagonIcon from "@mui/icons-material/Hexagon";
import SettingsIcon from "@mui/icons-material/Settings";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import AddIcon from "@mui/icons-material/Add";
import { WHITE_COLOR } from "utils/const";
import { BookmarksFilterDate } from "./BookmarksFilterDate";
import { AccordionFilterDate } from "./AccordionFilterDate";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// Components
import DatePicker from "components/DatePicker";
import Select from "react-select";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import OpenDialog from "components/Dialogs/OpenDialog";
import ConfigDashboard from "../Config";
import FormAddView from "../DragAndDrop/FormAddView";
import { ColorGrid } from "pages/PlatformSetUp/Preferences/preferencesstyles";
// Dependencies
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { parseISO, startOfDay, endOfDay, isWithinInterval } from "date-fns";
import { useSWRConfig } from "swr";
// Services
import {
  deleteOrderView,
  updateOrderCard,
  updateTemplate,
} from "services/Dashboard/orderCards";
import { findFilterDate } from "services/Dashboard/filterDate";
import PDFPrintDashboardSettings from "./printPDF/pdfPrintDashboardSettings";
import HandlerShowPrintGuidelines from "./printPDF/showDashboardGrids";
import CustomButton from "components/Buttons/CustomButton";
import HandlerPrintOptions from "./printPDF/printPdfOptions";

dayjs.extend(utc);
dayjs.extend(timezone);
export const ToolBar = ({
  privateViews,
  publicViews,
  setResetDefaultView,
  setCurrentOrderViews,
  setCurrentPositionId,
  setCurrentOrderName,
  setUseSelectViews,
  setUseSelectTypes,
  setUseCalendar,
  setTypeChart,
  orderId,
  currentOrderName,
  currentOrderViews,
  currentPositionId,
  typeChart,
  setOpenAlert,
  useSelectViews,
  useSelectTypes,
  useCalendar,
  components,
  events,
  objects,
  setFilterObjects,
  setFilterComponents,
  setFilterEvents,
  openDialogSettings,
  setOpenDialogSettings,
  handleChangeDashboarColor,
  openDialog,
  setOpenDialog,
  setCurrentTemplate,
  currentTemplate,
  setOpenDialogRestoreChart,
  openToolPrintPDF,
  setOpenToolPrintPDF,
}) => {
  const { mutate } = useSWRConfig();
  const timeZone = "America/Bogota";
  const today = dayjs().tz(timeZone);
  const lastMonth = dayjs().subtract(1, "month").tz(timeZone);
  const role = localStorage.getItem("role");
  const userId = parseInt(localStorage.getItem("userId"));
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;

  const [optionsViewCards, setOptionsViewCards] = useState([]);
  const [useFilterCalendar, setUseFilterCalendar] = useState(false);
  const [currentTypeView, setCurrentTypeView] = useState("");
  const [dialogAddView, setDialogAddView] = useState(false);
  const [startDateSelected, setStartDateSelected] = useState(lastMonth);
  const [endDateSelected, setEndDateSelected] = useState(today);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPrintPDF, setOpenModalPrintPDF] = useState(false);
  const [pdfOptions, setPdfOptions] = useState({
    guidelines: false,
    landscape: true,
    portrait: false,
    orientationRows: 7,
  });

  const backgroundDashboardColor = localStorage.getItem("backgroundDashboar");
  const [colorBackgroundDashboard, setColorBackgroundDashboard] = useState(
    backgroundDashboardColor ? backgroundDashboardColor : "#ffffff"
  );

  const { setDragAndDroll, setDashboardViewName, setChartsIconsShowOnOff } =
    useStoreDashboard();
  const useDragAndDroll = useStoreDashboard((state) => state.useDragAndDroll);

  useEffect(() => {
    setOptionsViewCards([
      {
        name: "Design New View",
        position: [],
        id: 0,
        template: {
          name: "createTemplate",
          gridTemplateColumns: "repeat(var(--col-count, 8), 1fr)",
          chartsPosition: [],
          sizeChart: {
            width: 250,
            height: 180,
          },
        },
        // section: {
        //   name: "Others"
        // }
      },
      ...publicViews,
      ...privateViews,
    ]);
  }, [privateViews, publicViews]);

  useEffect(() => {
    const setViewName = () => {
      if (
        currentOrderName.length !== 0 &&
        document.URL.match("dashboard").length > 0
      ) {
        setDashboardViewName(currentOrderName);
      }
    };
    currentOrderName && setViewName();
    return () => {
      setDashboardViewName("");
    };
  }, [currentOrderName, setDashboardViewName]);

  const filterObjectsByDate = useCallback(() => {
    const endDate = endDateSelected.$d;
    const startDate = startDateSelected.$d;

    if (objects?.length > 0) {
      const newObjects = objects.filter((object) => {
        const { date: dateObjectString } = object;
        // Parseamos la fecha del objeto
        const dateObject = parseISO(dateObjectString);

        // Creamos el intervalo desde el inicio del día de inicio hasta el final del día de fin
        const interval = {
          start: startOfDay(startDate),
          end: endOfDay(endDate),
        };

        // Comprobamos si la fecha del objeto está dentro del intervalo
        return isWithinInterval(dateObject, interval);
      });

      setFilterObjects(newObjects);
    }
  }, [objects, endDateSelected, startDateSelected, setFilterObjects]);

  const filterComponentsByDate = useCallback(() => {
    const endDate = endDateSelected.$d;
    const startDate = startDateSelected.$d;

    if (components?.length > 0) {
      const newComponents = components.filter((component) => {
        const { date: dateObjectString } = component;
        const dateComponent = parseISO(dateObjectString);

        return isWithinInterval(dateComponent, {
          start: startOfDay(startDate),
          end: endOfDay(endDate),
        });
      });

      setFilterComponents(newComponents);
    }
  }, [components, endDateSelected, startDateSelected, setFilterComponents]);

  const filterEventsByDate = useCallback(() => {
    const endDate = endDateSelected.$d;
    const startDate = startDateSelected.$d;

    if (events?.length > 0) {
      const newEvents = events.filter((event) => {
        const { date: dateObjectString } = event;
        const dateEvent = parseISO(dateObjectString);

        return isWithinInterval(dateEvent, {
          start: startOfDay(startDate),
          end: endOfDay(endDate),
        });
      });

      setFilterEvents(newEvents);
    }
  }, [events, endDateSelected, startDateSelected, setFilterEvents]);

  const showAll = useCallback(() => {
    const firstDate = objects?.reduce((minDate, object) => {
      const dateObject = dayjs(object.date);
      return dateObject.isBefore(minDate) ? dateObject : minDate;
    }, dayjs());
    const firstDateObject = dayjs(firstDate).tz(timeZone);
    setStartDateSelected(firstDateObject);
    setEndDateSelected(today);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects]);

  useEffect(() => {
    showAll();
  }, [showAll]);

  useEffect(() => {
    filterObjectsByDate();
    filterComponentsByDate();
    filterEventsByDate();
  }, [filterObjectsByDate, filterComponentsByDate, filterEventsByDate]);

  const handleChangeView = (view) => {
    if (view.label.props.children[0].props.children[1] === "Design New View") {
      setResetDefaultView(false);
    }
    setDragAndDroll(false);
    setCurrentTypeView(view.typeView);
    setCurrentOrderViews(view.value);
    setCurrentPositionId(view.id);
    setCurrentOrderName(view.label.props.children[0].props.children[1]);
    if (view.template && view.template.name !== null) {
      setCurrentTemplate(view.template);
    } else {
      setCurrentTemplate(null);
    }
    setDashboardViewName(view.label.props.children[0].props.children[1]);
    mutate(urlOrders);
  };

  const handleChangeType = (view) => {
    setTypeChart(view.value);
    setCurrentOrderName("Design New View");
    setCurrentTemplate({
      name: "createTemplate",
      gridTemplateColumns: "repeat(var(--col-count, 8), 1fr)",
      chartsPosition: [],
      sizeChart: {
        width: 250,
        height: 180,
      },
    });
    setCurrentTypeView();
    setResetDefaultView(true);
    setUseSelectTypes(false);
  };

  const handleChageTemplate = () => {
    const template = {
      name: "createTemplate",
      gridTemplateColumns: "repeat(var(--col-count, 8), 1fr)",
      chartsPosition: [],
      sizeChart: {
        width: 250,
        height: 180,
      },
    };
    setCurrentOrderName("Design New View");
    setCurrentTemplate(template);
    setResetDefaultView(false);
    setUseSelectTypes(false);
    setUseSelectViews(false);
    setUseCalendar(false);
  };

  const handleUpdateView = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: currentOrderViews,
        _id: currentPositionId,
      },
      type: typeChart,
    });
    if (currentTemplate) {
      await updateTemplate({
        id: orderId,
        body: {
          template: currentTemplate,
          _id: currentPositionId,
        },
        type: typeChart,
      });
    }
    setOpenAlert({
      open: true,
      severity: "success",
      message: "It has been successfully updated",
    });
    setDragAndDroll(false);
    setResetDefaultView(false);
    mutate(urlOrders);
  };

  const handleUpdateDefaultView = async ({ viewId, data }) => {
    await updateOrderCard({
      id: orderId,
      body: {
        defaultView: {
          data,
        },
        _id: viewId,
      },
      type: typeChart,
    });
    setOpenAlert({
      open: true,
      severity: "success",
      message: "Default view updated success",
    });
    setDragAndDroll(false);
    mutate(urlOrders);
  };

  const handleDeleteView = async ({ viewId }) => {
    setResetDefaultView(true);
    setCurrentOrderViews([]);
    await deleteOrderView({
      id: orderId,
      type: typeChart,
      viewId: viewId,
    });
    setOpenAlert({
      open: true,
      severity: "success",
      message: "It has been successfully deleted",
    });
    mutate(urlOrders);
  };

  const handleColorBackgroundDashboard = (e) => {
    e.preventDefault();
    const color = e.target.value;
    handleChangeDashboarColor(color);
    setColorBackgroundDashboard(color);
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => {
    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateDates = async () => {
    try {
      const data = await findFilterDate({ userId: userId });
      return data;
    } catch (error) {
      console.error("Error fetching related objects:", error);
    }
  };

  const handlePrintPDF = () => {
    setOpenToolPrintPDF(!openToolPrintPDF);
  };

  const handleOpenPrintPDF = () => {
    setOpenModalPrintPDF(!openModalPrintPDF);
    //show off chart icons for generating
    setChartsIconsShowOnOff(false);
  };

  return (
    <ContainerToolBar>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        content={{
          title: "Dashboard Configuration",
          description: <ConfigDashboard setOpenDialog={setOpenDialog} />,
        }}
        minWidth="40%"
        maxWidth="100%"
        height="90%"
      />
      <OpenDialog
        openDialog={dialogAddView}
        setOpenDialog={setDialogAddView}
        content={{
          title: "Add New View",
          description: (
            <FormAddView
              from={typeChart}
              currentOrderViews={currentOrderViews}
              setDialogAddView={setDialogAddView}
              setCurrentPositionId={setCurrentPositionId}
              setCurrentOrderName={setCurrentOrderName}
              setCurrentOrderViews={setCurrentOrderViews}
              setOpenAlertCreated={setOpenAlert}
              setResetDefaultView={setResetDefaultView}
              currentTemplate={currentTemplate}
              allViews={[...publicViews, ...privateViews]}
            />
          ),
        }}
        minWidth="30%"
        maxWidth="40%"
      />
      <OpenDialog
        openDialog={openDialogSettings}
        setOpenDialog={setOpenDialogSettings}
        content={{
          title: "Dashboard Settings",
          description: (
            <div className="contentitle2">
              <h2>
                <FormatColorFillIcon style={{ marginRight: "1rem" }} />
                Select Color
              </h2>
              <p>BackGround Dashboard Color</p>
              <ColorGrid>
                <BackgroundDashboard
                  color={"default"}
                  onClick={() => {
                    handleChangeDashboarColor("default");
                  }}
                >
                  <p>Default</p>
                </BackgroundDashboard>
                <BackgroundDashboard color={colorBackgroundDashboard}>
                  <input
                    type="color"
                    className="input-color-config"
                    value={colorBackgroundDashboard}
                    onChange={(e) => handleColorBackgroundDashboard(e)}
                  />
                </BackgroundDashboard>
              </ColorGrid>
            </div>
          ),
        }}
        minWidth="fit-content"
        maxWidth="40%"
      />
      <ToolBarDashBoard
        typeChart={typeChart}
        selectViews={useSelectViews}
        useDragAndDroll={useDragAndDroll}
        currentOrderName={currentOrderName}
        useCalendar={useCalendar}
        useFilterCalendar={useFilterCalendar}
        currentTemplate={currentTemplate?.name}
        openToolPrintPDF={openToolPrintPDF}
      >
        <div className="container-typeChars">
          <div className="type-charts">
            {typeChart === "objects" ? (
              <div
                className="type"
                onClick={() => {
                  setUseSelectTypes(!useSelectTypes);
                  setUseSelectViews(false);
                  setUseCalendar(false);
                }}
              >
                <RoomIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
                <p>Obj</p>
              </div>
            ) : typeChart === "components" ? (
              <div
                className="type"
                onClick={() => {
                  setUseSelectTypes(!useSelectTypes);
                  setUseSelectViews(false);
                  setUseCalendar(false);
                }}
              >
                <HexagonIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
                <p>Cmp</p>
              </div>
            ) : typeChart === "events" ? (
              <div
                className="type"
                onClick={() => {
                  setUseSelectTypes(!useSelectTypes);
                  setUseSelectViews(false);
                  setUseCalendar(false);
                }}
              >
                <TripOriginIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
                <p>Evt</p>
              </div>
            ) : (
              <div
                className="type"
                onClick={() => {
                  setUseSelectTypes(!useSelectTypes);
                  setUseSelectViews(false);
                  setUseCalendar(false);
                }}
              >
                <AllInclusiveIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
                <p>All</p>
              </div>
            )}
          </div>

          {useSelectTypes && (
            <div
              style={{
                position: "absolute",
                marginLeft: "268px",
              }}
            >
              <Select
                onChange={handleChangeType}
                options={[
                  {
                    value: "objects",
                    label: "Obj",
                  },
                  {
                    value: "components",
                    label: "Cmp",
                  },
                  {
                    value: "events",
                    label: "Evt",
                  },
                  {
                    value: "allTypes",
                    label: "All",
                  },
                ].map((view) => {
                  return {
                    value: view.value,
                    label: (
                      <ContainerSelect>
                        {view.label === "Obj" ? (
                          <ContainerTypeView>
                            <RoomIcon />
                            <p>Objetos</p>
                          </ContainerTypeView>
                        ) : view.label === "Cmp" ? (
                          <ContainerTypeView>
                            <HexagonIcon />
                            <p>Components</p>
                          </ContainerTypeView>
                        ) : view.label === "Evt" ? (
                          <ContainerTypeView>
                            <TripOriginIcon />
                            <p>Events</p>
                          </ContainerTypeView>
                        ) : (
                          <ContainerTypeView>
                            <AllInclusiveIcon />
                            <p>All</p>
                          </ContainerTypeView>
                        )}
                      </ContainerSelect>
                    ),
                  };
                })}
                value={() => {}}
                isSearchable={false}
                placeholder={
                  typeChart === "objects"
                    ? "Objects"
                    : typeChart === "events"
                    ? "Events"
                    : typeChart === "components"
                    ? "Components"
                    : "All"
                }
                styles={SelectTypesStyles}
                menuPlacement="auto"
                menuIsOpen={true}
              />
            </div>
          )}
        </div>

        <div className="tools">
          <div className="add-chart">
            <SimpleToolTip title={"Add Chart"} placement={"right"}>
              <AddchartIcon
                sx={{
                  fontSize: "3rem",
                }}
                onClick={() => {
                  setOpenDialog(true);
                  setUseSelectTypes(false);
                  setUseSelectViews(false);
                  setUseCalendar(false);
                }}
              />
            </SimpleToolTip>
          </div>
          {/* <div className="container-charts-templates">
            <div className="charts-tampalates">
              <SimpleToolTip title={"Templates"} placement={"bottom"}>
                <ViewQuiltIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                  onClick={() => {
                    handleChageTemplate();
                  }}
                />
              </SimpleToolTip>
            </div>
          </div> */}

          <div className="container-views-Tool">
            <div
              className="views-tool"
              onClick={() => {
                setUseSelectViews(!useSelectViews);
                setUseSelectTypes(false);
                setUseCalendar(false);
              }}
            >
              <SimpleToolTip title={"Design New View"} placement={"bottom"}>
                <AppRegistrationIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
              </SimpleToolTip>
            </div>
            {useSelectViews && (
              <div
                style={{
                  position: "absolute",
                  marginLeft: "268px",
                }}
              >
                <Select
                  menuIsOpen={true}
                  onChange={handleChangeView}
                  options={optionsViewCards.reduce((acc, view) => {
                    if (view.section && view.section.name) {
                      // Encuentra o crea el grupo correspondiente
                      let group = acc.find(
                        (g) => g.label === view.section.name
                      );
                      if (!group) {
                        group = { label: view.section.name, options: [] };
                        acc.push(group);
                      }

                      // Añade la opción al grupo correspondiente
                      group.options.push({
                        id: view._id,
                        value: view.position,
                        typeView: view.typeView,
                        template: view.template,
                        label: (
                          <ContainerSelect>
                            <ContainerTypeView>
                              {view.name === "Design New View" ? (
                                <AppRegistrationIcon
                                  sx={{
                                    mr: "3px",
                                    color: `${PRIMARY_COLOR}`,
                                  }}
                                />
                              ) : view.typeView === "private" &&
                                view.name !== "Design New View" ? (
                                <LockIcon
                                  sx={{
                                    mr: "3px",
                                    color: `${PRIMARY_COLOR}`,
                                  }}
                                />
                              ) : view.typeView === "public" &&
                                view.name !== "Design New View" ? (
                                <LockOpenIcon
                                  sx={{
                                    mr: "3px",
                                    color:
                                      view.name === "Design New View"
                                        ? BLACK_COLOR
                                        : PRIMARY_COLOR,
                                  }}
                                />
                              ) : null}
                              {view.name}
                            </ContainerTypeView>
                            {view.name !== "Design New View" && (
                              <ContainerSelectAndDeletView>
                                {view.name !== "Design New View" &&
                                  (view.defaultView ? (
                                    <PushPinIcon
                                      sx={{
                                        color: SECOND_COLOR,
                                        width: "18px",
                                        height: "auto",
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                        transform: "rotate(45deg)",
                                        "&:hover": {
                                          color: GRAY_COLOR,
                                        },
                                      }}
                                      onClick={() => {
                                        handleUpdateDefaultView({
                                          viewId: view._id,
                                          data: false,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <PushPinIcon
                                      sx={{
                                        color: GRAY_COLOR,
                                        width: "18px",
                                        height: "auto",
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                        transform: "rotate(45deg)",
                                        "&:hover": {
                                          color: SECOND_COLOR,
                                        },
                                      }}
                                      onClick={() => {
                                        handleUpdateDefaultView({
                                          viewId: view._id,
                                          data: true,
                                        });
                                      }}
                                    />
                                  ))}
                                {(((role === roles.operario ||
                                  role === roles.teamField ||
                                  role === roles.teamOffice) &&
                                  view.typeView === "private") ||
                                  role === roles.companyAdmin) &&
                                  view.name !== "Design New View" && (
                                    <CancelIcon
                                      sx={{
                                        color: "red",
                                        width: "18px",
                                        height: "auto",
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                        "&:hover": {
                                          color: "darkred",
                                        },
                                      }}
                                      onClick={() => {
                                        handleDeleteView({
                                          viewId: view._id,
                                        });
                                      }}
                                    />
                                  )}
                              </ContainerSelectAndDeletView>
                            )}
                          </ContainerSelect>
                        ),
                      });
                    } else {
                      // Opción sin sección
                      acc.unshift({
                        id: view._id,
                        value: view.position,
                        typeView: view.typeView,
                        template: view.template,
                        label: (
                          <ContainerSelect>
                            <ContainerTypeView>
                              {view.name === "Design New View" ? (
                                <AppRegistrationIcon
                                  sx={{
                                    mr: "3px",
                                    color: `${PRIMARY_COLOR}`,
                                  }}
                                />
                              ) : view.typeView === "private" &&
                                view.name !== "Design New View" ? (
                                <LockIcon
                                  sx={{
                                    mr: "3px",
                                    color: `${PRIMARY_COLOR}`,
                                  }}
                                />
                              ) : view.typeView === "public" &&
                                view.name !== "Design New View" ? (
                                <LockOpenIcon
                                  sx={{
                                    mr: "3px",
                                    color:
                                      view.name === "Design New View"
                                        ? BLACK_COLOR
                                        : PRIMARY_COLOR,
                                  }}
                                />
                              ) : null}
                              {view.name}
                            </ContainerTypeView>
                            {view.name !== "Design New View" && (
                              <ContainerSelectAndDeletView>
                                {view.name !== "Design New View" &&
                                  (view.defaultView ? (
                                    <PushPinIcon
                                      sx={{
                                        color: SECOND_COLOR,
                                        width: "18px",
                                        height: "auto",
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                        transform: "rotate(45deg)",
                                        "&:hover": {
                                          color: GRAY_COLOR,
                                        },
                                      }}
                                      onClick={() => {
                                        handleUpdateDefaultView({
                                          viewId: view._id,
                                          data: false,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <PushPinIcon
                                      sx={{
                                        color: GRAY_COLOR,
                                        width: "18px",
                                        height: "auto",
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                        transform: "rotate(45deg)",
                                        "&:hover": {
                                          color: SECOND_COLOR,
                                        },
                                      }}
                                      onClick={() => {
                                        handleUpdateDefaultView({
                                          viewId: view._id,
                                          data: true,
                                        });
                                      }}
                                    />
                                  ))}
                                {(((role === roles.operario ||
                                  role === roles.teamField ||
                                  role === roles.teamOffice) &&
                                  view.typeView === "private") ||
                                  role === roles.companyAdmin) &&
                                  view.name !== "Design New View" && (
                                    <CancelIcon
                                      sx={{
                                        color: "red",
                                        width: "18px",
                                        height: "auto",
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                        "&:hover": {
                                          color: "darkred",
                                        },
                                      }}
                                      onClick={() => {
                                        handleDeleteView({
                                          viewId: view._id,
                                        });
                                      }}
                                    />
                                  )}
                              </ContainerSelectAndDeletView>
                            )}
                          </ContainerSelect>
                        ),
                      });
                    }

                    // const newArray = acc.find((elm) => elm.id === undefined);

                    // const copy = acc.filter((elm) => elm.id !== undefined);
                    // copy.unshift(newArray)
                    return acc;
                  }, [])}
                  value={() => {}}
                  isSearchable={false}
                  placeholder={currentOrderName}
                  styles={SelectViewStyles}
                  menuPlacement="auto"
                  formatGroupLabel={formatGroupLabel}
                />
              </div>
            )}
          </div>

          {(useDragAndDroll || currentOrderName === "Design New View") && (
            <div
              className="add-new-view-tool"
              onClick={() => setDialogAddView(true)}
            >
              <SimpleToolTip title={"Add View"} placement={"right"}>
                <AddToQueueIcon
                  sx={{
                    fontSize: "3rem",
                    color:
                      useDragAndDroll || currentOrderName === "Design New View"
                        ? `${SET_VISIBILITY_ICON}`
                        : "none",
                  }}
                />
              </SimpleToolTip>
            </div>
          )}

          {(((role === roles.operario ||
            role === roles.teamField ||
            role === roles.teamOffice) &&
            currentTypeView === "private") ||
            role === roles.companyAdmin) &&
            currentOrderName !== "Design New View" &&
            useDragAndDroll && (
              <div
                className="update-view-tool"
                onClick={() => handleUpdateView()}
              >
                <SimpleToolTip title={"Update View"} placement={"right"}>
                  <SyncIcon
                    sx={{
                      fontSize: "3rem",
                      color: `${SET_VISIBILITY_ICON}`,
                    }}
                  />
                </SimpleToolTip>
              </div>
            )}

          {(((role === roles.operario ||
            role === roles.teamField ||
            role === roles.teamOffice) &&
            currentTypeView === "private") ||
            role === roles.companyAdmin) &&
            currentOrderName !== "Design New View" && (
              <div
                className="restore-chart-trash "
                onClick={() => setOpenDialogRestoreChart(true)}
              >
                <SimpleToolTip title={"Restore Chart"} placement={"right"}>
                  <RestoreFromTrashIcon
                    sx={{
                      fontSize: "3rem",
                    }}
                  />
                </SimpleToolTip>
              </div>
            )}

          <div className="container-calendar-tool">
            <div
              className="calendar-tool"
              onClick={() => {
                setUseCalendar(!useCalendar);
                setUseSelectTypes(false);
                setUseSelectViews(false);
              }}
            >
              <SimpleToolTip title={"Calendar filter"} placement={"right"}>
                <CalendarMonthIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
              </SimpleToolTip>
            </div>
            {useCalendar && (
              <SelectFilterDate>
                <SelectRangeDateStyle>
                  <h1>Current Date Filter</h1>
                  <ContainerDataPickers>
                    <DatePicker
                      label={"start date"}
                      value={startDateSelected}
                      setValue={setStartDateSelected}
                      maxDate={endDateSelected}
                      setUseFilterCalendar={setUseFilterCalendar}
                    />
                    <DatePicker
                      label={"end date"}
                      value={endDateSelected}
                      setValue={setEndDateSelected}
                      minDate={startDateSelected}
                      setUseFilterCalendar={setUseFilterCalendar}
                    />
                  </ContainerDataPickers>

                  <AddFilterDate>
                    <button className="button-add" onClick={handleOpenModal}>
                      <SimpleToolTip title="Add this Date" placement="right">
                        <AddIcon
                          sx={{ fontSize: "3rem", color: WHITE_COLOR }}
                        />
                      </SimpleToolTip>
                    </button>
                  </AddFilterDate>
                  <BookmarksFilterDate
                    setStartDateSelected={setStartDateSelected}
                    setEndDateSelected={setEndDateSelected}
                    updateDates={updateDates}
                  />
                </SelectRangeDateStyle>
              </SelectFilterDate>
            )}
          </div>

          {/* download dashboard report */}
          <div className="container-pdf-tool">
            <div className="pdf-tool" onClick={handlePrintPDF}>
              <SimpleToolTip title={"Print dashboard"} placement={"right"}>
                <FileDownloadIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
              </SimpleToolTip>
            </div>
            {openToolPrintPDF && (
              <div
                style={{
                  position: "absolute",
                  left: "100%", // Posiciona el componente desplegable a la derecha del botón
                  transform: "translateX(8px)", // Espacio entre el botón y el componente desplegable
                }}
              >
                {/* Show options for printing pdf */}
                <FloatingView>
                  <HandlerPrintOptions
                    options={pdfOptions}
                    setOptions={setPdfOptions}
                  />
                  <CustomButton
                    text="Open report preview"
                    onClick={handleOpenPrintPDF}
                  />
                  {/* guidelines */}
                  {(pdfOptions.landscape || pdfOptions.portrait) && (
                    <HandlerShowPrintGuidelines
                      options={pdfOptions}
                      setOptions={setPdfOptions}
                    />
                  )}
                </FloatingView>

                {openModalPrintPDF && (
                  <OpenDialog
                    openDialog={openModalPrintPDF}
                    setOpenDialog={setOpenModalPrintPDF}
                    content={{
                      title: "Report download",
                      description: (
                        <PDFPrintDashboardSettings
                          setOpenModalPrintPDF={setOpenModalPrintPDF}
                          currentOrderViews={currentOrderViews}
                          currentTemplate={currentTemplate}
                          options={pdfOptions}
                        />
                      ),
                    }}
                    minWidth="80%"
                    maxWidth="90%"
                    toExecuteOnClose={() => {
                      setChartsIconsShowOnOff(true);
                    }}
                  />
                )}
              </div>
            )}
          </div>

          {openModal && (
            <AccordionFilterDate
              handleCloseModal={handleCloseModal}
              localStartDate={startDateSelected}
              localEndDate={endDateSelected}
              updateDates={updateDates}
            />
          )}
          {useFilterCalendar && (
            <div
              className="reset-calendar"
              onClick={() => {
                showAll();
                setUseFilterCalendar(false);
              }}
            >
              <SimpleToolTip title={"Reset Filter"} placement={"right"}>
                <RestartAltIcon
                  sx={{
                    fontSize: "3rem",
                  }}
                />
              </SimpleToolTip>
            </div>
          )}
        </div>
        <div className="configuration">
          <div
            className="all-configuration"
            onClick={() => {
              setOpenDialogSettings(true);
            }}
          >
            <SimpleToolTip title={"settings"} placement={"right"}>
              <SettingsIcon
                sx={{
                  fontSize: "3rem",
                }}
              />
            </SimpleToolTip>
          </div>
        </div>
      </ToolBarDashBoard>
    </ContainerToolBar>
  );
};
