import { useState, useEffect } from "react";
// Hooks
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import useFindObjects from "hooks/objects/useFindObjects";
import useColumnsWithUniqueValues from "hooks/dashboard/objects/useColumnsWithUniqueValues2";
// Helpers
import SelectColumnsLibrary from "pages/Dashboard/components/SelectColumnsLibrary";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
import HandlerClickColumn from "pages/Dashboard/helpers/handlerClickColumn";
// Components
import SelectLibrary from "./SelectLibrary";
import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary";
import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";
import CustomButton from "components/Buttons/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";

// Const
import { defaultFieldsObjects } from "utils/defaultColumns";
// Services
import { createChart } from "services/Dashboard/doughnutChart";
import { config } from "config";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";
import SelectColumnLibraryWithValues from "./SelectColumnLibraryWithValues";
import generateRandomHexadecimal from "helpers/generateRandomHexadecimal";
import ShowLinePreview from "helpers/showBarPreview";
import { DELETE_COLOR, HOVER_DELETE_COLOR, PRIMARY_COLOR } from "utils/const";

const ObjectFormDoughnut = ({ setOpenDialog }) => {
  const [columnsLibrary, setColumnsLibrary] = useState([]);
  const [columnsSelectedFilters, setColumnsSelectedFilters] = useState([]);
  const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columnValuesFilters, setColumnValuesFilters] = useState({});
  const [objectsFormat, setObjectsFormat] = useState([]);
  const [dataInputs, setDataInputs] = useState({
    librarySelected: null,
    columnX: null,
    columnY: null,
    alias: "",
    typeOperation: "count",
  });
  const [columnsLibrarySelected, setColumnsLibrarySelected] = useState([]);

  const { data: libraries, error } = useFetchObjectLibraries({});
  const { objects } = useFindObjects();
  const { mutate } = useSWRConfig();
  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);

  const filterColumnsTypeNumber = (columns) => {
    const numberFields = columns?.filter(
      (field) =>
        field.type === "number" ||
        field.type === "operation" ||
        field.type === "range"
    );
    setColumnsTypeNumber(numberFields || []);
  };

  useEffect(() => {
    // find library by library select from libraries
    if (libraries && dataInputs.librarySelected) {
      const library = libraries.find(
        (library) => library.id === dataInputs.librarySelected.value
      );

      const columnsLibrary = library?.fields;

      setColumnsLibrarySelected(columnsLibrary);

      const columnsFiltered = columnsLibrary?.filter(
        (field) =>
          field.type !== "captureMedia" &&
          field.type !== "notification" &&
          field.type !== "number" &&
          field.type !== "operation" &&
          field.type !== "range"
      );
      if (columnsFiltered && columnsFiltered.length > 0) {
        setColumnsLibrary([...defaultFieldsObjects, ...columnsFiltered] || []);
      } else {
        setColumnsLibrary([...defaultFieldsObjects] || []);
      }
      filterColumnsTypeNumber(columnsLibrary || []);
    } else {
      setColumnsLibrary([]);
    }
  }, [libraries, dataInputs.librarySelected]);

  const updateDataInputs = (newState) => {
    setDataInputs((prevState) => ({ ...prevState, ...newState }));
  };

  const handlerTypeOperation = (e) => {
    updateDataInputs({ typeOperation: e });
  };

  const handlerClickColumnY = (column) => {
    updateDataInputs({ columnY: column });
  };

  const handlerClickColumnX = (column) => {
    updateDataInputs({ columnX: column });
  };

  const handlerAlias = (e) => {
    updateDataInputs({ alias: e.target.value });
  };

  const handlerClickColumnFilters = HandlerClickColumn({
    setColumnsSelected: setColumnsSelectedFilters,
    columnValues: columnValuesFilters,
    setColumnValues: setColumnValuesFilters,
  });

  const {
    columnsWithValues: columsWithValuesFilters,
    setColumnsWithValues: setColumsWithValuesFilters,
  } = useColumnsWithUniqueValues({
    objects: objectsFormat,
    columnsSelected: columnsSelectedFilters,
    handlerClickColumn: handlerClickColumnFilters,
    columnsLibrary: columnsLibrarySelected,
  });

  const handlerColumnValuesFilters = (columnName, selectedValues) => {
    setColumnValuesFilters({
      ...columnValuesFilters,
      [columnName]: selectedValues,
    });
  };

  const handlerLibrarySelected = (e) => {
    updateDataInputs({ librarySelected: e });
    const library = libraries.find((library) => library.id === e.value);
    const objectsByLibraryId = objects.filter(
      (object) => object.typeElement.pointLibraryId === e.value
    );
    const rows = createRowsObjects({ objects: objectsByLibraryId, library });
    setObjectsFormat(rows);
    updateDataInputs({ columnY: null, columnX: null, alias: "" });
    setColumsWithValuesFilters([]);
  };

  const handlerAddChart = async () => {
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const userId = localStorage.getItem("userId");
    const urlDonutChart = `${config.URL_BACKEND_MG}doughnut-chart?adminCompanyId=${adminCompanyId}&from=objects`;
    const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;

    const columnsValuesFilter = Object.entries(columnValuesFilters).map(
      ([name, values]) => ({
        name,
        values: values.map((item) => item.value),
      })
    );

    const chart = {
      adminCompanyId: parseInt(localStorage.getItem("adminCompanyId")),
      type: dataInputs.typeOperation,
      from: "objects",
      libraryId: dataInputs.librarySelected.value,
      alias: dataInputs.alias,
      columnX: dataInputs.columnX,
      columnsY: dataInputs.columnY ? [dataInputs.columnY] : [],
      columnsValuesFilter,
      donutStyle: {
        backgroundColor: dataInputs.columnX.backgroundColor,
        backgroundColorHexadecimal:
          dataInputs.columnX.backgroundColorHexadecimal,
      },
    };

    setLoading(true);
    const { data } = await createChart(chart);
    if (data) {
      mutate(urlDonutChart);
      await addChartInAllViews({
        userId,
        type: "objects",
        body: {
          newChart: {
            id: data._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  const handlerAddMainCategory = () => {
    const colors = dataInputs.columnX.values.map(() => {
      const colorRandom = generateRandomHexadecimal();
      return colorRandom;
    });
    setDataInputs((current) => ({
      ...current,
      columnX: {
        ...current.columnX,
        backgroundColor: colors,
        backgroundColorHexadecimal: colors,
      },
    }));
  };

  const handlerRemoveMainCategory = () => {
    setDataInputs((current) => ({
      ...current,
      columnX: null,
    }));
  };

  return (
    <>
      {!error && libraries && (
        <SelectLibrary
          libraries={libraries}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={dataInputs.librarySelected}
        />
      )}

      {dataInputs.librarySelected &&
        !dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <SelectColumnLibraryWithValues
            columnsLibrary={columnsLibrary}
            handlerClickColumn={handlerClickColumnX}
            columnSelected={dataInputs.columnX}
            data={objectsFormat}
            title={"Main Category"}
          />
        )}
      {dataInputs.librarySelected &&
        !dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <>
            <br />
            <br />
            <CustomButton
              text={"Add Main Category"}
              onClick={handlerAddMainCategory}
              margin={0}
            />
          </>
        )}

      {dataInputs?.columnX?.backgroundColor?.length > 0 && (
        <>
          <h2 className="subtitle">Selected Main Category</h2>
          {dataInputs.columnX.values.map((elm, ind) => (
            <div key={ind} className="preview_data_time_series">
              <div className="item">
                <h3>{ind + 1}.</h3>
              </div>
              <div className="lineName">
                <h3>{elm}</h3>
              </div>
              <div className="linePreview">
                <ShowLinePreview
                  text={false}
                  width={1}
                  color={
                    dataInputs?.columnX?.backgroundColor[ind] || PRIMARY_COLOR
                  }
                />
              </div>
              <div className="delete">
                <DeleteIcon
                  sx={{
                    fontSize: "20px",
                    color: DELETE_COLOR,
                    "&:hover": {
                      cursor: "pointer",
                      color: HOVER_DELETE_COLOR,
                    },
                  }}
                  onClick={() =>
                    setDataInputs((current) => ({
                      ...current,
                      columnX: {
                        ...current.columnX,
                        values: current.columnX.values.filter(
                          (item) => item !== elm
                        ),
                        backgroundColor: current.columnX.backgroundColor.filter(
                          (item, index) => index !== ind
                        ),
                        backgroundColorHexadecimal:
                          current.columnX.backgroundColorHexadecimal.filter(
                            (item, index) => index !== ind
                          ),
                      },
                    }))
                  }
                />
              </div>
            </div>
          ))}

          {dataInputs.librarySelected &&
            dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
              <>
                <br />
                <br />
                <CustomButton
                  text={"Remove Main Category"}
                  onClick={handlerRemoveMainCategory}
                  primaryColor={DELETE_COLOR}
                  secondaryColor={HOVER_DELETE_COLOR}
                  margin={0}
                />
              </>
            )}
        </>
      )}

      {dataInputs.librarySelected &&
        dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <SelectTypeOperation
            handlerSelectTypeOperation={handlerTypeOperation}
          />
        )}

      {dataInputs.librarySelected &&
        columnsLibrary &&
        dataInputs.typeOperation === "sum" &&
        dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <SelectColumnLibrary
            title={"Select Variable for Operation"}
            columnsLibrary={columnsTypeNumber}
            handlerClickColumn={handlerClickColumnY}
            columnSelected={dataInputs.columnY}
          />
        )}

      {dataInputs.librarySelected &&
        columnsLibrary &&
        dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <SelectColumnsLibrary
            columnsLibrary={columnsLibrary}
            handlerClickColumn={handlerClickColumnFilters}
            columnsSelected={columnsSelectedFilters}
            columnsWithValues={columsWithValuesFilters}
            handlerColumnValues={handlerColumnValuesFilters}
            columnValues={columnValuesFilters}
            title={"Set filters"}
          />
        )}

      {dataInputs.librarySelected &&
        dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <section className="container-type-charts ">
            <div>
              <h2 className="subtitle">Title</h2>
              <input
                className="alias"
                type="text"
                value={dataInputs.alias}
                onChange={(e) => {
                  handlerAlias(e);
                }}
                required={true}
              />
            </div>
          </section>
        )}

      {dataInputs.alias !== "" && dataInputs.alias !== " " && (
        <CustomButton
          className="btn-add-chart"
          onClick={() => handlerAddChart()}
          isLoad={loading}
          text={"Add chart"}
          margin={"15px 0"}
        />
      )}
    </>
  );
};

export default ObjectFormDoughnut;
