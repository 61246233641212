import axios from "axios";
import { config } from "config.js";

const findEvents = async (objectId) => {
  try {
    const token = localStorage.getItem("token");
    let url = `${config.URL_BACKEND_PG}api/v1/point-events`;
    if (objectId) url = `${url}?id=${objectId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default findEvents;

/**
 * Retrieves events by component ID.
 *
 * @param {Object} pointComponentId - The ID of the point component.
 * @return {Promise<Object>} - The data containing the events.
 */
export const findEventsByComponentId = async ({ pointComponentId }) => {
  try {
    const token = localStorage.getItem("token");
    let url = `${config.URL_BACKEND_PG}api/v1/point-events/components?pointComponentId=${pointComponentId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const findPointEvents = async ({ pointTypeEventId, id }) => {
  try {
    const token = localStorage.getItem("token");
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    let url = `${config.URL_BACKEND_PG}api/v1/point-events/all?adminCompanyId=${adminCompanyId}`;
    if (pointTypeEventId) url = `${url}&pointTypeEventId=${pointTypeEventId}`;

    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};
