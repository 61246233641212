import InspectionSniffer from "components/Forms/Events/InspectionSniffer";
import InspectionFlir from "components/Forms/Events/InspectionFlir";
import Leak from "components/Forms/Events/Leak";
import Reparation from "components/Forms/Events/Reparation";
import {
  ContainerOperations,
  HeadModal,
  CustomizedChevronLeftIcon,
  ContentButtonGallery,
  Label,
} from "./OperationDetailsElements";
import { WraperTitleCardMarker } from "../ImageDetailsElements";
import OpenDialog from "../../Dialogs/OpenDialog";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MapView from "components/Maps/ObjectMap";
import PhotoGallery from "../PhotoGalley/PhotoGallery";

import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../../utils/const";
import { AiFillCloseCircle } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { setDataObjectDTImages } from "redux/actions";
import { useSWRConfig } from "swr";
import useSwr from "swr";

import ObjectDetails from "../ObjectDetails/ObjectDetails";
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";

import GenericDialog from "components/Dialogs/GenericDialog";
import ContentUploadFieldVIdeo from "../ContentUploadFieldVideo";
import findObjects from "services/objects/findObjects";

// Config
import { config } from "config.js";

import TableOperation from "./TableOperation";
import DialogUploadVideoProcessed from "components/Dialogs/ReportVideoProcessed/UploadVideoProcessed";
import LeakQuantification from "./LeakQuantification";

const OperationDetails = ({ operationId, fromObject }) => {
  const dispatch = useDispatch();
  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );
  const [equipmentPhoto, setEquipmentPhoto] = useState(null);
  const [namePlatePhoto, setNamePlatePhoto] = useState(null);
  const [equipmentPhotos, setEquipmentPhotos] = useState();
  const [namePlatePhotos, setNamePlatePhotos] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typePicture, setTypePicture] = useState("1");
  const [status, setStatus] = useState("normal");

  const [videos, setVideos] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [photosFile, setPhotosFile] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModalUploadVideo, setOpenModalUploadVideo] = useState(false);
  const [openModalUploadVideoProcessed, setOpenModalUploadVideoProcessed] =
    useState(false);
  const [nameFlirVideo, setNameFlirVideo] = useState();

  const [flirVideoId, setFlirVideoId] = useState();
  const [objectGHG, setObjectGHG] = useState(false);
  const [otherMarkers, setOtherMarkers] = useState([]);
  const [ppmValue, setPpmValue] = useState(null);
  const [grhValue, setGrhValue] = useState(null);
  const [ltMinValue, setLtMinValue] = useState(null);
  const [distance, setDistance] = useState(null);

  const { mutate } = useSWRConfig();

  const urlData = `${config.URL_BACKEND_PG}api/v1/operation/${operationId}`;
  const { data: operation, error: errorOperation } = useSwr(urlData);
  const operationsById = {
    1: (
      <InspectionSniffer
        callbackUpdate={handleNormal}
        isUpdate={true}
        id={operation?.inspectionSniffer?.id}
        operationId={operationId}
      />
    ),
    2: (
      <InspectionFlir
        callbackUpdate={handleNormal}
        isUpdate={true}
        id={operation?.inspectionFlir?.id}
        operationId={operationId}
      />
    ),
    3: (
      <Leak
        callbackUpdate={handleNormal}
        isUpdate={true}
        id={operation?.leak?.id}
        operationId={operationId}
      />
    ),
    4: (
      <Reparation
        callbackUpdate={handleNormal}
        isUpdate={true}
        id={operation?.Reparation?.id}
        operationId={operationId}
      />
    ),
  };

  // Get object the current event
  useEffect(() => {
    const getObject = async () => {
      const objectId = operation?.objectId;
      const data = await findObjects(objectId);
      setObjectGHG(data);

      const newMarker = {
        id: data.id,
        latitude: data?.location?.latitude,
        longitude: data?.location?.longitude,
        urlIcon: data?.typeElement?.urlIconColor,
      };
      setOtherMarkers((current) => {
        return current.concat(newMarker);
      });
    };
    operation && getObject();
  }, [operation]);

  useEffect(() => {
    const equipementPhotosArray = [];
    const nameplatePhotosArray = [];
    operation &&
      operation.photos.map((photo) => {
        if (photo.operationPhotoTypeId === 2) {
          setEquipmentPhoto(photo);
          equipementPhotosArray.push(photo);
        }
        if (photo.operationPhotoTypeId === 1) {
          setNamePlatePhoto(photo);
          nameplatePhotosArray.push(photo);
        }
        return false;
      });
    setEquipmentPhotos(equipementPhotosArray);

    setNamePlatePhotos(nameplatePhotosArray);
  }, [operation]);

  useEffect(() => {
    const getVideos = async () => {
      const { data } = await axios.get(
        `${config.URL_BACKEND_PG}api/v1/flir-video/inspection-flir/${operation?.inspectionFlir?.id}`
      );

      data[0]?.flirVideoProcessed?.forEach((item) => {
        if (item.typeId === 1) setPpmValue(item.value ? item.value : "-");
        if (item.typeId === 2) setGrhValue(item.value ? item.value : "-");
        if (item.typeId === 3) setLtMinValue(item.value ? item.value : "-");
      });

      setDistance(data[0]?.distance);
      setVideos(data);
      setNameFlirVideo(data[0]?.name);
      setFlirVideoId(data[0]?.id);
    };
    operation?.inspectionFlir && getVideos();
  }, [operation?.inspectionFlir]);

  //open modal upload video inspection
  const handleOpenUploadVideo = () => {
    setOpenModalUploadVideo(!openModalUploadVideo);
  };

  function handleNormal() {
    setStatus("normal");
  }

  //open modal upload video processed
  const handleOpenUploadVideoProcessed = () =>
    setOpenModalUploadVideoProcessed(!openModalUploadVideoProcessed);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    textAlign: "center",
    borderRadius: "5px",
    width: "50%",
    maxHeight: "80%",
  };

  const returnToObject = () => {
    const object = {
      content: <ObjectDetails objectId={objectGHG.id} />,
      title: "Object Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };

  const handleClosePhotoModal = () => setOpenModal(false);

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setDataObjectDTImages([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setOpenModal(true);
  };

  const uploadImages = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/operation/${operationId}`);
  };

  // define functions to handle save button
  const savePhotos = async () => {
    setLoading(true);
    Promise.all(
      photosFile.map(async (file) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("file", file);
        if (typePicture === "1") {
          formData.append("bucketId", "1");
        }
        if (typePicture === "2") {
          formData.append("bucketId", "2");
        }
        if (typePicture === "3") {
          formData.append("bucketId", "3");
        }
        const urlPhoto = await fetch(
          `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
          {
            method: "POST",
            body: formData,
          }
        );
        const photo = await urlPhoto.json();
        await axios.post(
          `${config.URL_BACKEND_PG}api/v1/operation-photo`,
          {
            operationId: operationId,
            operationPhotoTypeId: typePicture,
            urlPhoto: photo.urlFile,
          },
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      })
    ).then(() => {
      setLoading(false);
      handleClosePhotoModal();
      setPhotosFile([]);
      mutate(urlData);
    });
  };

  const setObjectHandler = (e) => {
    setTypePicture(e.target.value);
  };

  return (
    <>
      {operation && !errorOperation && (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            position: "relative",
          }}
        >
          {fromObject && (
            <IconButton
              onClick={returnToObject}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          <OpenDialog
            openDialog={errorMessage}
            setOpenDialog={setErrorMessage}
            content={{
              title: "Conexion lost",
              description: "Please check your internet conexion and try again.",
              disagree: "Continue",
            }}
          />
          <ContainerOperations>
            {/* --EVENT TYPE-- */}
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="info-operation">
                <WraperTitleCardMarker>
                  <h1>
                    EVENT TYPE
                    <br />
                    <span>
                      {" "}
                      {operation.typeOperation?.name === "INSPECTION FLIR" ? (
                        <span>FLIR INSPECTION</span>
                      ) : (
                        operation.typeOperation?.name
                      )}{" "}
                    </span>
                  </h1>
                  <img alt="ico" src={operation.typeOperation?.urlIcon}></img>
                </WraperTitleCardMarker>
                {status === "normal" && (
                  <TableOperation
                    ppmValue={ppmValue}
                    grhValue={grhValue}
                    ltMinValue={ltMinValue}
                    operation={operation}
                    distance={distance}
                    videos={videos}
                    setStatus={setStatus}
                  />
                )}
                {status === "edit" &&
                  operationsById[operation?.typeOperationId]}
              </div>
            </Grid>

            {/* --OPERATION PHOTOS-- */}

            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div className="photo-container-image">
                <h1 className="operation-title">
                  <span>GALLERY</span>
                </h1>
                <div
                  className="gallery"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 24px 0px 20px",
                  }}
                >
                  {operation.photos.length > 0 && (
                    <>
                      <p style={{ fontSize: "1.6rem" }}>
                        <b>Total Pictures: </b> {operation.photos.length}
                      </p>
                      <br />
                      <PhotoGallery
                        photos={operation.photos}
                        ownId={operation.id}
                        uploadImages={uploadImages}
                      />
                    </>
                  )}
                  <ContentButtonGallery>
                    {/* <Wrapperbtn> */}
                    <Label htmlFor="files">
                      <AddAPhotoIcon />
                      <p>Add Picture</p>
                    </Label>
                    <input
                      id="files"
                      style={{ visibility: "hidden" }}
                      accept="image/*"
                      type="file"
                      name="file"
                      onChange={uploadFile}
                    />
                    {/* </Wrapperbtn> */}
                  </ContentButtonGallery>
                  <Modal
                    open={openModal}
                    onClose={handleClosePhotoModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                      }}
                    >
                      <HeadModal>
                        <div className="title">
                          <img
                            src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
                            alt=""
                            className="neurona"
                          />
                          <span className="titleHeadModal">Add Picture</span>
                        </div>
                        <Button onClick={handleClosePhotoModal}>
                          <AiFillCloseCircle
                            style={{
                              color: WHITE_COLOR,
                              fontSize: 21,
                              marginBottom: -4,
                            }}
                          />
                        </Button>
                      </HeadModal>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                          marginTop: "50px",
                        }}
                      >
                        Please select the type of picture to upload:
                        <br />
                        <div>
                          <select
                            defaultValue={typePicture}
                            onChange={setObjectHandler}
                            style={{
                              backgroundColor: PRIMARY_COLOR,
                              borderColor: PRIMARY_COLOR,
                              color: WHITE_COLOR,
                              padding: "5px 15px",
                              margin: "11px 11px 11px 15px",
                            }}
                          >
                            <option selected value="1">
                              Screen Sensor
                            </option>
                            <option value="2">Equipment</option>
                            <option value="3">General</option>
                          </select>
                        </div>
                      </Typography>
                      <LoadingButton
                        sx={{
                          margin: "11px",
                        }}
                        loadingPosition="start"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        style={{
                          backgroundColor: PRIMARY_COLOR,
                          borderColor: PRIMARY_COLOR,
                          color: WHITE_COLOR,
                        }}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setLoading(true);
                          savePhotos();
                        }}
                        style={{
                          backgroundColor: PRIMARY_COLOR,
                          borderColor: PRIMARY_COLOR,
                          color: WHITE_COLOR,
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Modal>
                </div>
              </div>
            </Grid>

            {/* --EQUIPMENT PHOTO-- */}
            {equipmentPhoto && (
              <Grid item xs={12} md={12} lg={4} xl={4}>
                <div className="photo-container">
                  <h1 className="operation-title">
                    <span>EQUIPMENT</span>
                  </h1>

                  {
                    <div
                      style={{
                        width: "85%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "0px 0px 20px 0px",
                      }}
                    >
                      <PhotoGallery
                        photos={equipmentPhotos}
                        ownId={operation.id}
                        state={false}
                        uploadImages={uploadImages}
                      />{" "}
                    </div>
                  }
                </div>
              </Grid>
            )}

            {/* --SCREEN SENSOR PHOTO-- */}
            {namePlatePhoto && (
              <Grid item xs={12} md={12} lg={4} xl={4}>
                <div className="photo-container">
                  <h1 className="operation-title">
                    <span>SCREEN SENSOR</span>
                  </h1>

                  {
                    <div
                      style={{
                        width: "85%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "0px 0px 20px 0px",
                      }}
                    >
                      <PhotoGallery
                        photos={namePlatePhotos}
                        ownId={operation.id}
                        state={false}
                        uploadImages={uploadImages}
                      />{" "}
                    </div>
                  }
                </div>
              </Grid>
            )}

            {/* --MAP- */}

            {namePlatePhoto && equipmentPhoto ? (
              <Grid item xs={12} md={12} lg={4} xl={4}>
                <div className="content-map">
                  <h1 className="operation-title">
                    <span>EVENT LOCATION</span>
                  </h1>
                  <MapView
                    otherMarkers={otherMarkers}
                    id={operation?.id}
                    eventId={
                      operation.Reparation !== null
                        ? operation.Reparation?.id
                        : operation.inspectionFlir !== null
                        ? operation.inspectionFlir?.id
                        : operation.inspectionSniffer !== null
                        ? operation.inspectionSniffer?.id
                        : operation.leak?.id
                    }
                    typeOperation={operation.typeOperation}
                    location={operation.locationOperation}
                    urlIcon={operation.typeOperation.urlIcon}
                    objectId={operation.objectId}
                  />
                </div>
              </Grid>
            ) : namePlatePhoto || equipmentPhoto ? (
              <Grid item xs={12} md={12} lg={8} xl={8}>
                <div className="content-map">
                  <h1 className="operation-title">
                    <span>LOCATION</span>
                  </h1>
                  <MapView
                    otherMarkers={otherMarkers}
                    id={operation?.id}
                    eventId={
                      operation.Reparation !== null
                        ? operation.Reparation?.id
                        : operation.inspectionFlir !== null
                        ? operation.inspectionFlir?.id
                        : operation.inspectionSniffer !== null
                        ? operation.inspectionSniffer?.id
                        : operation.leak?.id
                    }
                    typeOperation={operation.typeOperation}
                    location={operation.locationOperation}
                    urlIcon={operation.typeOperation.urlIcon}
                    objectId={operation.objectId}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <div className="content-map">
                  <h1 className="operation-title">
                    <span>LOCATION</span>
                  </h1>
                  <MapView
                    otherMarkers={otherMarkers}
                    id={operation?.id}
                    eventId={
                      operation.Reparation !== null
                        ? operation.Reparation?.id
                        : operation.inspectionFlir !== null
                        ? operation.inspectionFlir?.id
                        : operation.inspectionSniffer !== null
                        ? operation.inspectionSniffer?.id
                        : operation.leak?.id
                    }
                    typeOperation={operation.typeOperation}
                    location={operation.locationOperation}
                    urlIcon={operation.typeOperation.urlIcon}
                    objectId={operation.objectId}
                  />
                </div>
              </Grid>
            )}

            {/* --LEAK CUATIFICATION-- */}
            <LeakQuantification
              videos={videos}
              setVideos={setVideos}
              handleOpenUploadVideo={handleOpenUploadVideo}
              handleOpenUploadVideoProcessed={handleOpenUploadVideoProcessed}
            />

            {/* modal Upload video Flir */}
            {operation && (
              <GenericDialog
                openDialog={openModalUploadVideo}
                setOpenDialog={handleOpenUploadVideo}
                content={{
                  title: "Sync Video",
                  description: null,
                  disagree: null,
                  agree: null,
                  content: (
                    <ContentUploadFieldVIdeo
                      namePlatePhoto={namePlatePhoto}
                      nameFlirVideo={nameFlirVideo}
                      videos={videos}
                      setNameFlirVideo={setNameFlirVideo}
                      flirVideoId={flirVideoId}
                      userId={operation.userId}
                      operationId={operationId}
                      setOpenDialog={handleOpenUploadVideo}
                    />
                  ),
                }}
                style={{
                  margin: 0,
                  padding: 0,
                  width: "70%",
                  height: "100%",
                  maxWidth: "none",
                }}
              />
            )}

            {/* modal Upload video Processed */}
            <DialogUploadVideoProcessed
              openModalUploadVideoProcessed={openModalUploadVideoProcessed}
              handleOpenUploadVideoProcessed={handleOpenUploadVideoProcessed}
              setOpenModalUploadVideoProcessed={
                setOpenModalUploadVideoProcessed
              }
              videos={videos}
              flirVideoId={flirVideoId}
            />
          </ContainerOperations>
        </Box>
      )}
    </>
  );
};

export default OperationDetails;
