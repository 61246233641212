import React from "react";
import image from "images/gifTooltip.webp";

const AnimatedGif = () => {
  return (
    <img
      src={image}
      alt="GIFimage"
      style={{ width: "90%", height: "auto", borderRadius: "5px" }}
    />
  );
};

export default AnimatedGif;
