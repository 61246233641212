import styled from "styled-components";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const ContainerStyle = styled.div`
  display: contents;

  .types-base-map {
    margin-top: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    row-gap: 5rem;

    .type-base-map {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .select-base-map {
      margin: 0;
      box-sizing: border-box;
      outline: none;
      border: none;
      background-color: ${PRIMARY_COLOR};
      color: white;
      padding: 5px 8px;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 5px;
      width: 150px;
    }

    img {
      height: 20rem;
      width: 20rem;
      object-fit: cover;
      :hover {
        border: solid 5px ${PRIMARY_COLOR};
        padding: 1rem;
        cursor: pointer;
      }
    }

    .current-type-base-map {
      border: solid 2px ${SECOND_COLOR};
      box-shadow: 1px 1px 15px ${SECOND_COLOR};
    }

    button:hover {
      background: ${SECOND_COLOR};
    }
  }
`;

export const ButtonBaseMap = styled("button")`
  outline: none;
  position: absolute;
  top: ${(props) => props.top}px;
  right: 8px;
  width: 33px;
  height: 32px;
  z-index: 8;
  background: ${WHITE_COLOR};
  background-clip: padding-box;
  color: #b9b9b9;
  display: inline-block;
  cursor: pointer;
  padding: 5px 6px 2px 7px;
  border-radius: 5px;
  border: solid 2px;
  border-color: #00000021;

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;
