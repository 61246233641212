import axios from "axios";
import { config } from "config.js";

/**
 * Fetches all filters based on the given query parameters.
 *
 * @param {Object} query - Query parameters to filter dates
 * @returns {Promise<Object[]>}
 *
 */

export const findFilterDate = async (query) => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_MG}date-filter?userId=${query.userId}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return result.data;
};

export const deleteFilterDate = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${config.URL_BACKEND_MG}date-filter/${id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error("🚩🚩🚩Error deleting date service", error);
  }
};

/**
 * Creates a new date filter.
 *
 * @param {Object} body - The request body.
 *  @property {string} date - The date to filter.
 * @returns {Promise<Object>} The response data from the API.
 */

export const saveFilterDate = async (body) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_MG}date-filter`;
  const result = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};
