import { useState } from "react";
import DialogInformation from "components/Dialogs/DialogInformation.jsx";
import { ContentDatePicker } from "components/ContentDialogs/ContentDatePicker";
import { ButtonStyle, ButtonTableStyle } from "./DatePickerStyle";
import { FilterTabStyles } from "./AccordionStyles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import dayjs from "dayjs";

function DatePicker(props) {
  const { startdate, enddate, value, changefunction, from, active } = props;
  const [information, setInfoObjects] = useState(false);

  const handleInformationObjects = () => {
    setInfoObjects(true);
  };

  return (
    <>
      <DialogInformation
        openDialog={information}
        setOpenDialog={setInfoObjects}
        content={ContentDatePicker(
          startdate,
          enddate,
          changefunction,
          setInfoObjects
        )}
      />
      {from === undefined && (
        <FilterTabStyles>
          <div className="date" style={{ width: "100%" }}>
            <p className="date" style={{ textAlign: "center", width: "200px" }}>
              {" "}
              {value}{" "}
            </p>
          </div>
        </FilterTabStyles>
      )}
      {from === "genericTable" ? (
        <ButtonTableStyle onClick={handleInformationObjects}>
          <CalendarMonthIcon
            sx={{
              margin: "0px",
              color: `${active ? SECOND_COLOR : PRIMARY_COLOR}`,
              "&:hover": {
                color: `${active ? PRIMARY_COLOR : SECOND_COLOR}`,
              },
            }}
          />
          <div className="container-tooltip">
            <span
              className="tooltip"
              style={{
                width: `${
                  startdate === "" && enddate === "" ? "80px" : "165px"
                }`,
              }}
            >
              {`Min: ${
                startdate === ""
                  ? " - "
                  : dayjs(startdate, { format: "YYYY-MM-DD" }).format(
                      "ddd DD MMM YYYY"
                    )
              }`}
              <br />
              {`Max: ${
                enddate === ""
                  ? " - "
                  : dayjs(enddate, { format: "YYYY-MM-DD" }).format(
                      "ddd DD MMM YYYY"
                    )
              }`}
            </span>
          </div>
        </ButtonTableStyle>
      ) : (
        <ButtonStyle>
          <button onClick={handleInformationObjects} style={{ width: "100%" }}>
            Select date
          </button>
        </ButtonStyle>
      )}
    </>
  );
}

export default DatePicker;
