import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import CropLandscapeIcon from "@mui/icons-material/CropLandscape";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { PdfOptionsContainer } from "./printPdfOptionsStyles";
import NoteIcon from "@mui/icons-material/Note";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

/**
 * Handler for print pdf dashboard module
 *
 * @param {{options:Object}} options Object with configurations
 * @param {{setOptions: Function}} setOptions Setter useState function
 * @returns ReactJS Component
 */
export default function HandlerPrintOptions({ options, setOptions }) {
  const handleChangeOption = (opcn) => {
    if (!opcn || !opcn.length) return;
    if (opcn === "landscape") {
      setOptions((current) => {
        return {
          ...current,
          portrait: options.portrait && !options.landscape ? false : true,
          orientationRows: 7,
          [opcn]: !current[opcn],
        };
      });
      return;
    }
    if (opcn === "portrait") {
      setOptions((current) => {
        return {
          ...current,
          landscape: options.landscape && !options.portrait ? false : true,
          orientationRows: 10,
          [opcn]: !current[opcn],
        };
      });
      return;
    }
    if (opcn === "guidelines") {
      setOptions((current) => {
        return {
          ...current,
          [opcn]: !current[opcn],
        };
      });
      return;
    }
    setOptions((current) => {
      return {
        ...current,
        [opcn]: !current[opcn],
      };
    });
  };

  return (
    <PdfOptionsContainer>
      <div className="options-title">
        <h3>Options</h3>
      </div>
      <div className="options-container">
        <span>
          <SimpleToolTip title={"Print guidelines"} placement={"right"}>
            <div className="option-div">
              <h3>
                <NoteIcon
                  sx={{
                    fontSize: "1.25",
                  }}
                />
                Landscape
              </h3>
              <div>
                <input
                  type="checkbox"
                  name="landscapecheckbox"
                  id="landscapecheckbox"
                  value={options.landscape}
                  checked={options.landscape}
                  onChange={() => handleChangeOption("landscape")}
                />
              </div>
            </div>
          </SimpleToolTip>
        </span>

        <span>
          <SimpleToolTip title={"Print guidelines"} placement={"right"}>
            <div className="option-div">
              <h3>
                <InsertDriveFileIcon
                  sx={{
                    fontSize: "1.25",
                  }}
                />
                Portrait
              </h3>
              <div>
                <input
                  type="checkbox"
                  name="portraitcheckbox"
                  id="portraitcheckbox"
                  value={options.portrait}
                  checked={options.portrait}
                  onChange={() => handleChangeOption("portrait")}
                />
              </div>
            </div>
          </SimpleToolTip>
        </span>

        <span>
          <SimpleToolTip title={"Print guidelines"} placement={"right"}>
            <div className="option-div">
              <h3>
                <Grid3x3Icon
                  sx={{
                    fontSize: "1.25",
                  }}
                />
                Guidelines
              </h3>
              <div>
                <input
                  type="checkbox"
                  name="portraitcheckbox"
                  id="portraitcheckbox"
                  value={options.guidelines}
                  checked={options.guidelines}
                  onChange={() => handleChangeOption("guidelines")}
                />
              </div>
            </div>
          </SimpleToolTip>
        </span>
      </div>
    </PdfOptionsContainer>
  );
}
