import styled from "styled-components";
import { BACKGROUND_GREY, PRIMARY_COLOR, WHITE_COLOR } from "utils/const";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 0 auto;

  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;

  h1 {
    font-size: 2rem;
    margin: 20px 0;
    color: ${PRIMARY_COLOR};
  }

  img {
    width: 100%;
  }
`;

export const PrincipalConteiner = styled.div`
  background: ${WHITE_COLOR};
  height: 100vh;
`;
