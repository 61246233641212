import axios from "axios";
import { config } from "config.js";

const saveFlirVideo = async (body) => {
  const res = await axios.post(
    `${config.URL_BACKEND_PG}api/v1/flir-video`,
    body
  );
  const data = await res.data;
  return { data, res };
};

export default saveFlirVideo;
