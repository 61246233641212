// React
import { useCallback } from "react";
// Redux
import { useDispatch, useStore } from "react-redux";
import { setDrawAnyThing } from "redux/actions";
import {
  setCancelDrawing,
  setDrawLineMode,
  setNeuronSelectTool,
  setTmpDistanceFeatures,
} from "redux/actions/digitalTwin";
import {
  setGeometryPolygon,
  setFeaturesDetailsDT,
  setDataObjectsPolygon,
  setGeometryLine,
  setDataEventPolygon,
  setOriginalDataObjectsPolygon,
  setUpdateGeometryLine,
} from "redux/actions/admin";
// Service
import { getPointsPolygon } from "services/sendPolygonGeometry";
import calculareLineLength from "helpers/calculateLineLength";
import uniqueObjsArr from "helpers/uniqueObjsArr";

const HandlerDrawerControl = () => {
  const dispatch = useDispatch();
  const store = useStore();

  // function to send polygon geometry to backend
  const sendPolygonGeometry = useCallback(
    (body) => {
      let { coordinates } = body;
      coordinates = coordinates[0];
      const { filteredObjects, filteredOperations } =
        store.getState().digitalTwinReducer;

      const { dataObjectsPolygon } = store.getState().adminReducer;

      const { features } = getPointsPolygon(coordinates, filteredObjects);

      const objectsIntoPolygon = features.map(
        (feature) => feature.properties.object
      );

      const idsSet = new Set(objectsIntoPolygon.map((item) => item.id));
      const data = dataObjectsPolygon.filter((elm) => idsSet.has(elm.id));

      dispatch(setOriginalDataObjectsPolygon(data));
      dispatch(setDataObjectsPolygon(data));

      const { features: featuresOperations } = getPointsPolygon(
        coordinates,
        filteredOperations
      );
      const operationsIntoPolygon = featuresOperations.map(
        (feature) => feature.properties.operation
      );
      dispatch(setDataEventPolygon(operationsIntoPolygon));
    },
    [dispatch, store]
  );

  const onCreate = useCallback(
    (e) => {
      if (e.features[0].geometry.type === "Polygon") {
        sendPolygonGeometry(e.features[0].geometry);
        dispatch(setGeometryPolygon(e.features[0].geometry));
      }
      if (e.features[0].geometry.type === "Point") {
        ((currFeatures) => {
          const newFeatures = { ...currFeatures };
          for (const f of e.features) {
            newFeatures[f.id] = f;
            dispatch(setFeaturesDetailsDT(f));
          }
          return newFeatures;
        })();
      }
      if (e.features[0].geometry.type === "LineString") {
        const drawerControl = store.getState().digitalTwinReducer.drawerControl;
        const { drawLineMode } = store.getState().digitalTwinReducer;
        //prevent from closing line on line relations mode
        if (
          drawLineMode.mode === "linerelations" &&
          drawLineMode.features.length < 2
        ) {
          dispatch(setGeometryLine(null));
          dispatch(
            setDrawLineMode({
              mode: "",
              drawStatus: false,
              show: false,
              features: [],
            })
          );
          dispatch(
            setNeuronSelectTool({
              show: false,
              top: null,
              rigth: null,
            })
          );
          drawerControl.trash();
          return;
        }
        dispatch(setGeometryLine(e.features[0]));
      }
    },
    [dispatch, sendPolygonGeometry, store]
  );

  const onSelect = useCallback(
    (e) => {
      for (const f of e.features) {
        dispatch(setFeaturesDetailsDT(f));
      }
    },
    [dispatch]
  );

  const onMovePoints = useCallback(
    (e) => {
      if (e.movePoint || Object.keys(e.currentMoveLocation).length > 0) {
        dispatch(
          e.setNeuronSelectTool({
            show: false,
            top: null,
            right: null,
          })
        );
        e.setMovePoint(false);
        e.setMoveNewLocation({});
        dispatch(
          setDrawAnyThing({
            isDraw: false,
            type: "",
          })
        );
        localStorage.removeItem("newLocationMove");
        return;
      }
      e.setMovePoint(!e.movePoint);
      dispatch(
        e.setNeuronSelectTool({
          show: true,
          top: 45,
          right: 65,
        })
      );
      dispatch(
        setDrawAnyThing({
          isDraw: true,
          type: "movePoints",
        })
      );
    },
    [dispatch]
  );

  const onCopyCoordinates = useCallback(
    (e) => {
      if (e.copyCoordinates || Object.keys(e.copyLocation).length > 0) {
        dispatch(
          e.setNeuronSelectTool({
            show: false,
            top: null,
            right: null,
          })
        );
        e.setCopyCoordinates(false);
        e.setCopyLocation({});
        dispatch(
          setDrawAnyThing({
            isDraw: false,
            type: "",
          })
        );
        return;
      }
      e.setCopyCoordinates(!e.copyCoordinates);
      dispatch(
        e.setNeuronSelectTool({
          show: true,
          top: 75,
          right: 65,
        })
      );
      dispatch(
        setDrawAnyThing({
          isDraw: true,
          type: "copyCoordinates",
        })
      );
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    (e) => {
      if (e?.features[0]?.geometry?.type === "Polygon") {
        sendPolygonGeometry(e.features[0].geometry);
        dispatch(setGeometryPolygon(e.features[0].geometry));
      }
      if (e?.features[0]?.geometry?.type === "LineString") {
        const { dataLines, currentUpdateLineId, tmpDistanceFeatures } =
          store.getState().digitalTwinReducer;

        //update temporary line for measurement
        const lineExist = tmpDistanceFeatures.features.find(
          (ft) => ft.id === e.features[0].id
        );
        if (lineExist) {
          const ft = e.features[0];
          const distance = calculareLineLength({
            coordinates: ft.geometry.coordinates,
            units: "kilometers",
          });
          lineExist.properties = { ...lineExist.properties, distance };
          lineExist.geometry.coordinates = ft.geometry.coordinates;
          const uniques = uniqueObjsArr([
            ...tmpDistanceFeatures.features,
            lineExist,
          ]);
          dispatch(
            setTmpDistanceFeatures({
              ...tmpDistanceFeatures,
              features: uniques,
            })
          );
          return;
        }

        const finalIndexUpdate = e.features[0].geometry.coordinates.length - 1;

        const currentLine = dataLines.find((line) => {
          return line.feature[0].id === e.features[0].id;
        });

        const finalIndexCurrent =
          currentLine?.feature?.[0]?.geometry?.coordinates?.length - 1;

        if (
          e.features[0].geometry.coordinates[0][0] !==
            currentLine.feature[0].geometry.coordinates[0][0] &&
          e.features[0].geometry.coordinates[0][1] !==
            currentLine.feature[0].geometry.coordinates[0][1] &&
          e.features[0].geometry.coordinates[finalIndexUpdate][0] !==
            currentLine.feature[0].geometry.coordinates[finalIndexCurrent][0] &&
          e.features[0].geometry.coordinates[finalIndexUpdate][1] !==
            currentLine.feature[0].geometry.coordinates[finalIndexCurrent][1]
        ) {
          dispatch(setCancelDrawing(true));
          dispatch(setUpdateGeometryLine({}));
          dispatch(
            setNeuronSelectTool({
              show: false,
              top: null,
              right: null,
            })
          );
          return;
        } else {
          e.features[0].properties["lineIdPg"] = currentUpdateLineId;
          dispatch(setUpdateGeometryLine(e.features[0]));
          dispatch(
            setNeuronSelectTool({
              show: true,
              top: 221,
              right: 67,
            })
          );
        }
      }
      ((currFeatures) => {
        const newFeatures = { ...currFeatures };
        for (const f of e.features) {
          newFeatures[f.id] = f;
        }
        return newFeatures;
      })();
    },
    [dispatch, sendPolygonGeometry]
  );

  const onDelete = useCallback(
    (e) => {
      dispatch(
        setNeuronSelectTool({
          show: false,
          top: null,
        })
      );
      dispatch(setGeometryLine({}));
      dispatch(setGeometryPolygon(null));
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
      dispatch(
        setDrawLineMode({
          mode: "",
          show: false,
          drawStatus: false,
          features: [],
        })
      );
    },
    [dispatch]
  );

  return {
    onCreate,
    onSelect,
    onUpdate,
    onDelete,
    onCopyCoordinates,
    onMovePoints,
  };
};

export default HandlerDrawerControl;
