import styled from "styled-components";

export const PdfOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .options-title {
    min-width: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .options-container {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;
    gap: 10px;
  }
  .option-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Algo = styled.div`
  .option-text {
    font-size: 14px;
  }
  .button {
    width: 60px;
  }
`;
