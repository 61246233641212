import { useEffect, useState } from "react";

import { useFetchEventLibraries } from "hooks/fetchLibraries";
import CustomSelect from "components/Buttons/CustomSelect";
import useFetchPointTypeEvents from "hooks/useFetchPointTypeEvents";
import { BLACK_COLOR } from "utils/const";

/**
 * Renders a component that allows the user to select fields.
 *
 * @param {Object} props - The props object.
 * @param {string} props.fieldName - The currently selected field name.
 * @param {function} props.setFieldName - A function to set the selected field name.
 * @param {string} props.fieldFile - The currently selected field file.
 * @param {function} props.setFieldFile - A function to set the selected field file.
 * @param {string} props.typeEventId - The ID of the selected type event.
 * @param {function} props.setTypeEventId - A function to set the selected type event ID.
 * @return {JSX.Element} The rendered component.
 */
const SelectFields = ({
  fieldName = null,
  setFieldName = null,
  fieldFile,
  setFieldFile,
  typeEventId,
  setTypeEventId,
}) => {
  const [libraryId, setLibraryId] = useState("");
  const [fieldTypeEvent, setFieldsTypeEvent] = useState([]);

  const { data: dataLibrary, error: errorLibrary } = useFetchEventLibraries({
    id: null,
  });

  useEffect(() => {
    dataLibrary && setLibraryId(dataLibrary?.[0]?.id);
  }, [dataLibrary]);

  const { data: dataTypeEvent, error: errorTypeEvent } =
    useFetchPointTypeEvents({ libraryId: libraryId });

  useEffect(() => {
    dataTypeEvent && setTypeEventId(dataTypeEvent?.[0]?.id);
  }, [dataTypeEvent, setTypeEventId]);

  useEffect(() => {
    const getFields = () => {
      const typeEvent = dataTypeEvent?.find(
        (item) => item.id === parseInt(typeEventId)
      );
      const fields = typeEvent?.fields;
      setFieldsTypeEvent(fields);
    };

    dataTypeEvent && getFields();
  }, [dataTypeEvent, typeEventId]);

  const handleNameField = (_id) => {
    const fieldSelected = fieldTypeEvent.find((field) => field._id === _id);
    setFieldName(fieldSelected);
  };

  const handleFileField = (_id) => {
    const fieldSelected = fieldTypeEvent.find((field) => field._id === _id);
    setFieldFile(fieldSelected);
  };

  return (
    <div>
      <section>
        <div className="select">
          <p>Select event library:</p>
          {dataLibrary && !errorLibrary && (
            <div className="select-container">
              <CustomSelect
                options={dataLibrary?.map((library) => {
                  return { id: library.id, name: library.name };
                })}
                select={libraryId}
                setSelect={setLibraryId}
              />
            </div>
          )}
        </div>
        <div className="select">
          <p>Select point type event:</p>
          {dataTypeEvent && !errorTypeEvent && (
            <div className="select-container">
              <CustomSelect
                options={dataTypeEvent?.map((typeEvent) => {
                  return {
                    id: typeEvent.id,
                    name: typeEvent.name,
                  };
                })}
                select={typeEventId}
                setSelect={setTypeEventId}
              />
            </div>
          )}
        </div>
      </section>
      <section className="select-fields">
        {fieldName !== null && (
          <div className="select-name-field">
            <div>
              <h2 className="select-title">Select name field</h2>
              <p className="select-description">
                The selected name is used to match the video names.
              </p>
            </div>
            <table className="table">
              <thead>
                <tr className="table-header">
                  <th>Name</th>
                  <th>Display name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {fieldTypeEvent &&
                  fieldTypeEvent
                    ?.filter((item) => item.type === "string")
                    .map((field) => (
                      <tr
                        style={{ color: document.body.style.color }}
                        key={field?._id}
                        className={
                          field?._id === fieldName?._id
                            ? "table-row selected"
                            : "table-row"
                        }
                        onClick={() => handleNameField(field?._id)}
                      >
                        <td className="table-column">{field?.name}</td>
                        <td className="table-column">{field?.alias}</td>
                        <td className="table-column">{field?.type}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="select-file-field">
          <div>
            <h2 className="select-title">Select file field</h2>
            <p className="select-description">
              The selected field is used to upload the videos.
            </p>
          </div>
          <table className="table">
            <thead>
              <tr className="table-header">
                <th>Name</th>
                <th>Display name</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {fieldTypeEvent &&
                fieldTypeEvent
                  ?.filter((item) => item.type === "file")
                  .map((field) => (
                    <tr
                      style={{ color: document.body.style.color }}
                      key={field?._id}
                      className={
                        field?._id === fieldFile?._id
                          ? "table-row selected"
                          : "table-row"
                      }
                      onClick={() => handleFileField(field?._id)}
                    >
                      <td className="table-column">{field?.name}</td>
                      <td className="table-column">{field?.alias}</td>
                      <td className="table-column">{field?.type}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default SelectFields;
