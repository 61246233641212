import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "components/Buttons/CustomButton";
import findTypeObjectsTypeObjects from "services/typeObjectsTypeObjects/find.js";
import {
  FeatureDetails,
  LineContainerForm,
  WraperTitleCardMarker,
  LineConnectionsContainer,
} from "../SaveLines/LineRelationsStyles.jsx";
import useViewFilterClusterMapObjects from "hooks/useViewFilterClusterMapObjects.jsx";
import {
  setInfoObjectRelation,
  setObjectRelationObject,
} from "redux/actions/digitalTwin.jsx";

import { setModalRelationObject } from "redux/actions/digitalTwin.jsx";

const ObjectRelations = ({
  objectId,
  setOpenDialogParent,
  setOpenDialog,
  object,
}) => {
  const [relatedObjects, setRelatedObjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasConnections, setHasConnections] = useState(true); //state to enable or disable the button
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const dispatch = useDispatch();

  const { handleFilterPointsObjectRelations } = useViewFilterClusterMapObjects({
    objectId,
  });

  useEffect(() => {
    const fetchRelatedObjects = async () => {
      setLoading(true);
      try {
        const data = await findTypeObjectsTypeObjects({
          pointTypeObjectId: objectId,
        });
        setRelatedObjects(data);
        setHasConnections(data.length > 0); // Update the state to enable or disable the button
      } catch (error) {
        console.error("Error fetching related objects:", error);
      } finally {
        setLoading(false);
      }
    };

    if (objectId) {
      fetchRelatedObjects();
    }
  }, [objectId]);

  const handleSetObjectRelations = () => {
    if (!hasConnections) {
      // If there are no connections, set the error message
      setErrorMessage("No objects available for connection.");
    } else {
      // Reset the error message
      setErrorMessage("");
      // Execute the actions to show the modal and the info of the object
      dispatch(setModalRelationObject({ show: true }));
      dispatch(setInfoObjectRelation({ showInfoObject: true }));
      dispatch(setObjectRelationObject({ objectId: object }));
      handleFilterPointsObjectRelations();
      setOpenDialogParent();
      setOpenDialog(false);
    }
  };

  return (
    <FeatureDetails>
      <LineContainerForm>
        <WraperTitleCardMarker>
          <h2>Related Objects:</h2>
        </WraperTitleCardMarker>
      </LineContainerForm>

      <ShowPossibleObjectConnections id={objectId} />
      {errorMessage && ( // Show the error message if there is one
        <div style={{ color: "red" }}>{errorMessage}</div>
      )}
      <CustomButton
        type={"button"}
        onClick={handleSetObjectRelations}
        isLoad={loading}
        text={"Save"}
        margin={0}
      />
    </FeatureDetails>
  );
};

const ShowPossibleObjectConnections = ({ id }) => {
  const [types, setTypes] = useState([]);
  useLayoutEffect(() => {
    const getTypesLinesTypesObjects = async () => {
      const typeElementAllow = await findTypeObjectsTypeObjects({
        pointTypeObjectId: id,
      });
      setTypes(typeElementAllow);
    };
    id && getTypesLinesTypesObjects();
  }, [id]);

  return (
    <LineConnectionsContainer>
      <div className="container-list-header">
        <h4>Possible object types for connection:</h4>
      </div>
      <div className="container-list">
        {types?.length &&
          types.map((tp) => {
            return (
              <div className="container-label">
                <label className="location-details">
                  {tp.PointTypeObjectRelation.name}
                </label>
                <div
                  className="container-img"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    alt=""
                    src={tp.PointTypeObjectRelation.urlIconColor}
                    style={{ marginRight: "20px" }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </LineConnectionsContainer>
  );
};

export default ObjectRelations;
