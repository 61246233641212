import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AiFillCloseCircle } from "react-icons/ai";
import { HeadModal } from "../Forms/FormStyles";
import SignUp from "components/Forms/OperatorRegister";
import { WHITE_BACKGROUND, WHITE_COLOR } from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor, StyleColor2 } from "Styles";

export default function FormDialog({ open, handleClose }) {
  const { theme } = useContext(ThemeContext);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            padding: "0px",
          }}
        >
          <HeadModal>
            <div className="title">
              <img
                src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
                alt=""
                className="neurona"
              />
              <span className="titleHeadModal">Operator Registration</span>
            </div>
            <Button onClick={handleClose}>
              <AiFillCloseCircle style={{ color: WHITE_COLOR, fontSize: 21 }} />
            </Button>
          </HeadModal>
        </DialogTitle>
        <DialogContent
          style={{
            background: StyleColor(theme),
            color: StyleColor2(theme),
          }}
        >
          <SignUp handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
