import axios from "axios";
import { config } from "config.js";

const deleteView = async (viewid) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/views/${parseInt(viewid)}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error("🚩🚩🚩Error deleting view service", error);
  }
};
export default deleteView;
