import { useDispatch, useSelector } from "react-redux";
import {
  setNeuronSelectTool,
  setShowDataComponents,
  setShowDataComponentsByType,
  setShowDataComponentsLibrary,
  setShowEvents,
  setShowEventsByLibrary,
  setStateObjectFilterTable,
  showEventsByInspectionType,
  showEventsByType,
  showOperationsByType,
} from "redux/actions/digitalTwin";
import {
  setFiltersEventsToFalse,
  setFiltersEventsToTrue,
  setFiltersFalse,
  setFiltersTrue,
} from "../components/Tables/GenericTable/helpers/setGlobalFilterPoints";
import { useEffect, useState } from "react";
import {
  setObjectsFilterTable,
  setShowDataObjects,
  showMarkerseByTypeElement,
  showMarkerseByTypeObject,
} from "redux/actions/admin";
import { PRIMARY_COLOR, VISIBILITY_OFF_ICON } from "utils/const";
import findTypeObjectsTypeObjects from "services/typeObjectsTypeObjects/find";

export default function useViewFilterClusterMap({ objectId }) {
  const dispatch = useDispatch();

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );
  const showOperationByType = useSelector(
    (state) => state.digitalTwinReducer.showOperationsByType
  );
  const showAllEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );
  const showEventsByLibrary = useSelector(
    (state) => state.digitalTwinReducer.showEventsByLibrary
  );

  const showMarkersByLibrary = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );

  const showDataComponentsByType = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsByType
  );
  const showDataComponentsLibrary = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsLibrary
  );

  const allObjects = useSelector((state) => state.adminReducer.dataObjects);

  const [eventByInspectionType, setEventByInspectionType] = useState([]);
  const [operationByType, setOperationByType] = useState([]);
  const [allEventByType, setAllEventsByType] = useState([]);
  const [eventsByLibrary, setAllEventsByLibrary] = useState([]);

  const [markersByLibrary, setMarkersByLibrary] = useState([]);
  const [markersByTypeElement, setMarkersByTypeElement] = useState([]);

  const [dataComponentsByType, setDataComponentsByType] = useState([]);
  const [dataComponentsLibrary, setDataComponentsLibrary] = useState([]);
  const [currentDataTable, setCurrentDataTable] = useState([]);

  useEffect(() => {
    setEventByInspectionType(showEventByInspectionType);
    setOperationByType(showOperationByType);
    setAllEventsByType(showAllEventsByType);
    setAllEventsByLibrary(showEventsByLibrary);

    setMarkersByLibrary(showMarkersByLibrary);
    setMarkersByTypeElement(showMarkersByTypeElement);

    setDataComponentsByType(showDataComponentsByType);
    setDataComponentsLibrary(showDataComponentsLibrary);
  }, [
    showEventByInspectionType,
    showOperationByType,
    showAllEventsByType,
    showEventsByLibrary,
    showMarkersByLibrary,
    showMarkersByTypeElement,
    showDataComponentsByType,
    showDataComponentsLibrary,
  ]);

  const handleFilterPointsObjectRelations = async () => {
    const typeElementAllow = await findTypeObjectsTypeObjects({
      pointTypeObjectId: objectId,
    });

    const dataFilter = typeElementAllow.filter((elm) => {
      const result = allObjects.some((item) => {
        return elm.pointTypeObjectRelationId === item.typeElementId;
      });

      return result;
    });

    const env1 = setFiltersEventsToFalse(eventByInspectionType);
    const env2 = setFiltersEventsToFalse(operationByType);
    const env3 = setFiltersFalse(allEventByType);
    const env4 = setFiltersFalse(eventsByLibrary);
    const componentsByType = setFiltersFalse(dataComponentsByType);
    const componentsByLibrary = setFiltersFalse(dataComponentsLibrary);
    const updateDataTable = [];
    allObjects.forEach((elm) => {
      dataFilter.forEach((elm2) => {
        if (elm.typeElementId === elm2.pointTypeObjectRelationId) {
          updateDataTable.push(elm);
        }
      });

      setCurrentDataTable(updateDataTable);
    });
    dispatch(setShowEvents({ color: `${VISIBILITY_OFF_ICON}`, state: false }));
    dispatch(showEventsByInspectionType(env1));
    dispatch(showOperationsByType(env2));
    dispatch(showEventsByType(env3));
    dispatch(setShowEventsByLibrary(env4));
    dispatch(setShowDataComponentsByType(componentsByType));
    dispatch(setShowDataComponentsLibrary(componentsByLibrary));
    dispatch(
      setShowDataComponents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
    );
    dispatch(setObjectsFilterTable(updateDataTable));
    dispatch(setStateObjectFilterTable(true));
  };

  const handleSetFilterPoints = () => {
    dispatch(setObjectsFilterTable([]));
    const env1 = setFiltersEventsToTrue(eventByInspectionType);
    const env2 = setFiltersEventsToTrue(operationByType);
    const env3 = setFiltersTrue(allEventByType);
    const env4 = setFiltersTrue(eventsByLibrary);
    const objects = setFiltersTrue(markersByLibrary);
    const obj = setFiltersTrue(markersByTypeElement);
    const componentsByType = setFiltersTrue(dataComponentsByType);
    const componentsByLibrary = setFiltersTrue(dataComponentsLibrary);
    dispatch(showMarkerseByTypeObject(objects));
    dispatch(showMarkerseByTypeElement(obj));
    dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
    dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
    dispatch(showEventsByInspectionType(env1));
    dispatch(showOperationsByType(env2));
    dispatch(showEventsByType(env3));
    dispatch(setShowEventsByLibrary(env4));
    dispatch(setShowDataComponentsByType(componentsByType));
    dispatch(setShowDataComponentsLibrary(componentsByLibrary));
    dispatch(setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true }));
    dispatch(setStateObjectFilterTable(false));
    dispatch(
      setNeuronSelectTool({
        show: false,
        top: null,
        right: null,
      })
    );
  };

  return {
    handleSetFilterPoints,
    handleFilterPointsObjectRelations,
    currentDataTable,
  };
}
