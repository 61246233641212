import axios from "axios";

import { config } from "config.js";

export const findCharts = async ({ adminCompanyId, from, chartType }) => {
  const params = new URLSearchParams();

  if (adminCompanyId) params.append("adminCompanyId", adminCompanyId);
  if (from) params.append("from", from);
  if (chartType) params.append("chartType", chartType);

  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_MG}charts-dashboard/${
    params ? `?${params.toString()}` : ""
  }`;
  const res = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export const findChartsText = async ({ adminCompanyId, from }) => {
  const token = localStorage.getItem("token");

  const params = new URLSearchParams();

  if (adminCompanyId) params.append("adminCompanyId", adminCompanyId);
  if (from) params.append("from", from);

  const url = `${config.URL_BACKEND_MG}charts-text/${
    params ? `?${params.toString()}` : ""
  }`;

  const res = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};
