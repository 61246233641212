import { parse, parseISO, isBefore, isAfter } from "date-fns";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useFilterObjectsByDateAndUser = ({ objects, users, objectsMap }) => {
  const [filterObjects, setFilterObjects] = useState([]);
  const [filterObjectsMap, setFilterObjectsMap] = useState([]);

  const dateFilterObject = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );

  const { endDate: endDateString, startDate: startDateString } =
    dateFilterObject;

  const endDate = useMemo(
    () => parse(endDateString, "yyyy-MM-dd", new Date()),
    [endDateString]
  );
  const startDate = useMemo(
    () => parse(startDateString, "yyyy-MM-dd", new Date()),
    [startDateString]
  );

  const filterObjectsByDateAndUser = useCallback(() => {
    if (objects.length > 0) {
      const newObjects = objects.filter((object) => {
        const userId = object.user.id;
        const dateObject = parseISO(object.date, "yyyy-MM-dd", new Date());
        const isInRange =
          !isBefore(dateObject, startDate) && !isAfter(dateObject, endDate);
        const isShowUser = users && users[userId]?.isShow;
        return isInRange && isShowUser;
      });
      setFilterObjects(newObjects);
    }
  }, [objects, startDate, endDate, users]);

  const filterObjectsMapByDateAndUser = useCallback(() => {
    if (objectsMap.length > 0) {
      const newObjectsMap = objectsMap.filter((object) => {
        const userId = object.properties.object.userId;
        const dateObject = parseISO(
          object.properties.object.date,
          "yyyy-MM-dd",
          new Date()
        );
        const isInRange =
          !isBefore(dateObject, startDate) && !isAfter(dateObject, endDate);
        const isShowUser = users && users[userId]?.isShow;
        return isInRange && isShowUser;
      });
      setFilterObjectsMap(newObjectsMap);
    }
  }, [objectsMap, startDate, endDate, users]);

  useEffect(() => {
    filterObjectsByDateAndUser();
    filterObjectsMapByDateAndUser();
  }, [filterObjectsByDateAndUser, filterObjectsMapByDateAndUser]);

  return {
    filterObjects,
    setFilterObjects,
    filterObjectsMap,
    setFilterObjectsMap,
  };
};

export default useFilterObjectsByDateAndUser;
