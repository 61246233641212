import { useState, useEffect, useCallback } from "react";
import { findCharts } from "services/Dashboard/barChart";
// Helpers
import { count, sum } from "pages/Dashboard/helpers/objectsBarChart";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { config } from "config";

const useFindBarCharts = ({ openDialog, objects, loading }) => {
  const [charts, setCharts] = useState(null);
  const [barCharts, setBarCharts] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlBarChart = `${config.URL_BACKEND_MG}bar-chart?adminCompanyId=${adminCompanyId}&from=objects`;
  const { data: dataBarChart, error: errorDataBarChart } = useSwr(urlBarChart);

  useEffect(() => {
    if (!errorDataBarChart && dataBarChart) setCharts(dataBarChart);
  }, [dataBarChart, errorDataBarChart, openDialog, reloadCharts]);

  useEffect(() => {
    const objectsFormat = createRowsObjects({ objects });
    setObjectsFormat(objectsFormat);
  }, [objects]);

  const getDataBarChart = useCallback(async () => {
    if (!loading && objectsFormat) {
      const chartsFilterCount = charts.filter(
        (chart) => chart.type === "count"
      );
      const countCharts = count({
        barCharts: chartsFilterCount,
        objects: objectsFormat,
      });
      const chartsFilterSum = charts.filter((chart) => chart.type === "sum");
      const sumCharts = sum({
        barCharts: chartsFilterSum,
        objects: objectsFormat,
      });
      setBarCharts([...countCharts, ...sumCharts]);
    }
  }, [loading, objectsFormat, charts]);

  useEffect(() => {
    if (charts && objects) {
      getDataBarChart();
    }
  }, [charts, objects, getDataBarChart]);

  return { barCharts };
};

export default useFindBarCharts;
