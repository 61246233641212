import axios from "axios";
import { config } from "config.js";
/**
 * Saves an object file to the backend API.
 *
 * @param {Object} object - The object containing the necessary data.
 * @param {string} object.objectId - The ID of the object.
 * @param {string} object.typeObjectFileId - The ID of the type object file.
 * @param {string} object.urlFile - The URL of the file to be saved.
 * @return {Object} The response object containing the saved data and the HTTP response.
 */
const saveObjectFile = async ({ objectId, typeObjectFileId, urlFile }) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${config.URL_BACKEND_PG}api/v1/object-files`,
    {
      objectId: objectId,
      typeObjectFileId: typeObjectFileId,
      url: urlFile,
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};

export default saveObjectFile;

/**
 * Saves captured media for a given point object.
 *
 * @param {object} options - The options object.
 * @param {string} options.pointObjectMgId - The ID of the point object.
 * @param {array} options.media - The media to be saved.
 * @param {string} options.media.type - The type of media.
 * @param {string} options.media.alias - The alias of the media.
 * @param {string} options.media.url - The URL of the media.
 * @return {object} - An object containing the saved data and the response.
 */
export const saveCaptureMedia = async ({ pointObjectMgId, media }) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.patch(
    `${config.URL_BACKEND_MG}point-object/${pointObjectMgId}`,
    {
      $push: {
        captureMedia: {
          $each: media,
        },
      },
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};
