import React from "react";
import { SECOND_COLOR } from "utils/const";

const ReportImg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="500"
        height="500"
        viewBox="0 0 866 612"
      >
        <path
          id="a435875b-2c1c-4448-a2d6-7c6fccbb74a3-271"
          data-name="Path 461"
          d="M192.86952,718.80333a22.728,22.728,0,0,0,21.947-3.866c7.687-6.452,10.1-17.081,12.058-26.924l5.8-29.112-12.143,8.362c-8.733,6.013-17.662,12.219-23.709,20.929s-8.686,20.6-3.828,30.024"
          transform="translate(-167 -144)"
          fill="#e4e4e4"
        />
        <path
          id="a4b268fa-75d0-4ac1-9d87-14bdca948464-272"
          data-name="Path 462"
          d="M193.90753,754.04931c-1.229-8.953-2.493-18.02-1.631-27.069.766-8.036,3.217-15.885,8.209-22.321a37.13178,37.13178,0,0,1,9.527-8.633c.953-.6,1.829.909.881,1.507a35.29989,35.29989,0,0,0-13.963,16.847c-3.04,7.732-3.528,16.161-3,24.374.317,4.967.988,9.9,1.665,14.83a.9.9,0,0,1-.61,1.074.878.878,0,0,1-1.074-.61Z"
          transform="translate(-167 -144)"
          fill="#f2f2f2"
        />
        <path
          d="M710.95671,754.81485H335.999a33.55881,33.55881,0,0,1-33.5209-33.5209V177.5209A33.55881,33.55881,0,0,1,335.999,144H710.95671a33.55881,33.55881,0,0,1,33.5209,33.5209V721.294A33.55881,33.55881,0,0,1,710.95671,754.81485Z"
          transform="translate(-167 -144)"
          fill="#3f3d56"
        />
        <path
          d="M711.6167,733.42778H335.339a10.345,10.345,0,0,1-10.33319-10.33319V175.72026A10.345,10.345,0,0,1,335.339,165.38707H711.6167a10.345,10.345,0,0,1,10.33319,10.33319V723.09459A10.345,10.345,0,0,1,711.6167,733.42778Z"
          transform="translate(-167 -144)"
          fill="#fff"
        />
        <circle cx="355.05203" cy="10.2658" r="2.85161" fill="#fff" />
        <path
          d="M349.91573,416.95811H670.62917V361.98893a43.525,43.525,0,0,0-13.35-31.00278l-.08718-.08515c-5.72815-5.74742-24.87142-20.33416-44.67817-19.09739-10.46284.65133-19.42052,5.69368-26.62367,14.98717-22.42145,28.9388-51.87422,19.17038-67.43517,10.772-13.71366-7.40286-27.71877-10.87746-41.62909-10.32446-19.4816.76182-46.90207,7.93861-66.89966,37.804-7.63779,11.39751-24.24822,26.86874-60.01052,35.961Z"
          transform="translate(-167 -144)"
          fill="#e4e4e4"
        />
        <path
          d="M688.775,418.23648H350.533a1.05859,1.05859,0,0,1-1.05854-1.05854L349.98819,416l338.78677.1194a1.05854,1.05854,0,1,1,0,2.11708Z"
          transform="translate(-167 -144)"
          fill="#3f3d56"
        />
        <rect
          x="245.79616"
          y="211.8933"
          width="2.07614"
          height="60.85345"
          fill="#3f3d56"
        />
        <rect
          x="307.95252"
          y="183.47252"
          width="2.07614"
          height="89.27423"
          fill="#3f3d56"
        />
        <rect
          x="370.23686"
          y="202.15782"
          width="2.07614"
          height="70.58892"
          fill="#3f3d56"
        />
        <rect
          x="432.52121"
          y="167.90143"
          width="2.07614"
          height="104.84531"
          fill="#3f3d56"
        />
        <rect
          x="494.80555"
          y="191.7771"
          width="2.07614"
          height="80.96965"
          fill="#3f3d56"
        />
        <circle cx="309.11861" cy="184.90351" r="6.35126" fill={SECOND_COLOR} />
        <circle cx="433.6873" cy="168.29435" r="6.35126" fill={SECOND_COLOR} />
        <circle cx="371.40296" cy="202.55074" r="6.35126" fill={SECOND_COLOR} />
        <circle cx="246.83427" cy="211.89339" r="6.35126" fill={SECOND_COLOR} />
        <circle cx="495.97165" cy="191.13194" r="6.35126" fill={SECOND_COLOR} />
        <path
          id="ba0cc859-1ca5-421a-9acf-7fb9aa3b4e23-273"
          data-name="Path 1495"
          d="M474.33247,638.42514V530.90788a2.49679,2.49679,0,0,1,2.49679-2.49678h11.1421a2.49678,2.49678,0,0,1,2.49678,2.49678h0V638.70539Z"
          transform="translate(-167 -144)"
          fill="#3f3d56"
        />
        <path
          id="a524792d-c043-478e-a7da-ec48af772355-274"
          data-name="Path 1496"
          d="M511.1331,638.762V553.27134a2.49679,2.49679,0,0,1,2.49678-2.49679H524.772a2.49679,2.49679,0,0,1,2.49678,2.49679h0V638.578Z"
          transform="translate(-167 -144)"
          fill="#3f3d56"
        />
        <path
          id="fd6a765d-e5c4-40cb-8615-8d176b643a05-275"
          data-name="Path 1513"
          d="M584.73434,638.381V514.46084a2.70781,2.70781,0,0,1,2.49678-2.87781h11.1421a2.70763,2.70763,0,0,1,2.49678,2.87781V638.70538Z"
          transform="translate(-167 -144)"
          fill="#3f3d56"
        />
        <path
          id="bd64d803-c830-42f7-b93c-47122685f128-276"
          data-name="Path 1514"
          d="M437.53157,638.54942V578.72265c0-.76715,1.11789-1.38936,2.49679-1.38936h11.1421c1.37888,0,2.49678.62278,2.49678,1.38936v59.98273Z"
          transform="translate(-167 -144)"
          fill="#3f3d56"
        />
        <path
          id="f4fc54bd-288c-4bcd-8f44-6b7e88ac2463-277"
          data-name="Path 1515"
          d="M547.93343,638.33881V497.71231c0-1.80351,1.11789-3.26563,2.49678-3.26563h11.1421c1.37889,0,2.49679,1.46212,2.49679,3.26563V638.70539Z"
          transform="translate(-167 -144)"
          fill={SECOND_COLOR}
        />
        <path
          d="M648.88976,640h-252a1,1,0,0,1,0-2h252a1,1,0,0,1,0,2Z"
          transform="translate(-167 -144)"
          fill="#3f3d56"
        />
        <polygon
          points="782.156 599.448 770.355 599.447 764.741 553.928 782.159 553.928 782.156 599.448"
          fill="#ffb6b6"
        />
        <path
          d="M952.16614,754.8871l-38.05237-.00141v-.4813a14.81185,14.81185,0,0,1,14.811-14.81081h.00094l23.24109.00094Z"
          transform="translate(-167 -144)"
          fill="#2f2e41"
        />
        <polygon
          points="692.503 599.058 681.102 596.01 687.436 550.585 704.262 555.084 692.503 599.058"
          fill="#ffb6b6"
        />
        <path
          d="M859.45544,754.8871,822.69469,745.057l.12432-.465a14.81184,14.81184,0,0,1,18.134-10.48262l.00091.00024,22.45219,6.004Z"
          transform="translate(-167 -144)"
          fill="#2f2e41"
        />
        <polygon
          points="788.946 344.346 798.831 355.83 786.047 589.206 758.739 589.206 744.971 409.632 702.284 594.023 673.895 587.06 699.708 352.86 788.946 344.346"
          fill="#2f2e41"
        />
        <path
          d="M873.30743,337.67552,900.49685,324.038l41.81255.735,35.98171,18.412L957.81928,445.42625l8.84328,53.30859-.00006,0a218.06032,218.06032,0,0,1-104.28352.85835l-.27378-.065s20.3242-72.1147,11.67216-94.12189Z"
          transform="translate(-167 -144)"
          fill="#e4e4e4"
        />
        <path
          d="M951.96688,283.18457a30.80555,30.80555,0,1,0,0,.228Q951.9673,283.29858,951.96688,283.18457Z"
          transform="translate(-167 -144)"
          fill="#ffb6b6"
        />
        <path
          d="M907.36691,265.58226A8.73966,8.73966,0,0,1,913.255,263.207c3.2912-.10693,8.22259,1.06183,10.32126,3.7374,1.68674,2.15037,1.76742,5.10891,1.77387,7.8419l.0178,7.54274c.00528,2.23151.03242,4.55825,1.04626,6.54621s3.3411,3.49789,5.46724,2.8202c2.52214-.80391,3.6312-4.15606,6.20878-4.75883,1.93581-.45269,3.92424.96189,4.77042,2.76084a11.97387,11.97387,0,0,1,.689,5.85839c-.244,3.94952-5.90853,5.51631-6.71052,9.39124-.464,2.24141-2.0883,6.71269,0,5.77561,9.626-.9626,13.85115-6.58508,18.25683-11.8778l9.93651-11.9371a11.66815,11.66815,0,0,0,2.5592-4.14155,11.50366,11.50366,0,0,0,.26262-3.3024q-.08127-4.4505-.24162-8.89857c-.0934-2.591-.74551-5.85994-3.28293-6.39242-1.3197-.27693-3.06631.206-3.76389-.948a2.619,2.619,0,0,1-.11936-1.83954c.56-2.96127,1.50541-6.05859.53079-8.91041-1.46989-4.301-6.53128-6.06587-11.0035-6.87733s-9.49275-1.50959-12.29987-5.08439a38.9856,38.9856,0,0,0-2.4414-3.43168,9.58347,9.58347,0,0,0-4.57678-2.30838,25.29606,25.29606,0,0,0-16.63388,1.47627c-2.16336.98719-4.33635,2.31658-6.70954,2.16618-2.46508-.15625-4.57068-1.8911-7.00436-2.31315-3.931-.68173-7.689,2.26333-9.62622,5.75111-2.40036,4.32169-2.62066,10.24.77033,13.83725,1.69172,1.79465,4.21573,3.0274,4.9183,5.39154.286.96231.21958,1.99876.47251,2.97024a5.36179,5.36179,0,0,0,4.32081,3.73839C903.56735,268.18253,905.60107,267.0813,907.36691,265.58226Z"
          transform="translate(-167 -144)"
          fill="#2f2e41"
        />
        <path
          d="M869.55473,412.22a11.03332,11.03332,0,0,0,16.02773,5.41656l55.20826,29.18424,1.78777-13.44892-53.64533-31.63363A11.0931,11.0931,0,0,0,869.55473,412.22Z"
          transform="translate(-167 -144)"
          fill="#ffb6b6"
        />
        <path
          d="M943.12529,414.54533a11.0333,11.0333,0,0,1-16.82451,1.77844l-60.25607,16.39751.52451-17.075,59.9406-15.56869a11.0931,11.0931,0,0,1,16.61547,14.46778Z"
          transform="translate(-167 -144)"
          fill="#ffb6b6"
        />
        <path
          d="M966.54242,343.92106l11.74869-.73606s13.75527,18.15005,6.12562,37.84548c0,0,1.32178,70.74989-29.14417,67.84379s-40.092-2.90609-40.092-2.90609l9.14471-25.50894,20.45795-6.31638s-6.30516-27.81364,5.62982-39.386Z"
          transform="translate(-167 -144)"
          fill="#e4e4e4"
        />
        <path
          d="M881.70621,343.29954l-1.66057-8.51182s-24.48825-.57529-29.3303,36.53186c0,0-22.02136,55.53472-.43659,62.68516s45.32768,0,45.32768,0L893.818,409.51439l-23.75-4.847s12.26884-15.8726,5.58829-29.64036Z"
          transform="translate(-167 -144)"
          fill="#e4e4e4"
        />
        <path
          d="M1032,756H168a1,1,0,0,1,0-2h864a1,1,0,0,1,0,2Z"
          transform="translate(-167 -144)"
          fill="#cacaca"
        />
      </svg>
    </>
  );
};

export default ReportImg;
