import { getNumericValue } from "helpers/getNumericValue";

export const count = ({ objects, kpiChart }) => {
  const charts = kpiChart.map((chart) => {
    const filteredObjects = objects.filter((obj) => {
      // Filtrar por libraryId y pointLibraryId
      if (obj.typeElement.pointLibraryId !== chart.libraryId) {
        return false;
      }

      // Filtrar por los valores de columnsValuesFilter
      for (const filter of chart.columnsValuesFilter) {
        const attribute = obj.attributes.find(
          (attr) => attr.name === filter.name
        );
        // Filtrar por valor del attributo
        if (!attribute || !filter.values.includes(attribute.value)) {
          return false;
        }
      }

      return true;
    });

    return {
      ...chart,
      count: filteredObjects.length,
    };
  });

  return charts;
};

export const sum = ({ objects, kpiChart }) => {
  const charts = kpiChart.map((chart) => {
    // Inicializar el total de la suma
    let totalSum = 0;

    // Iterar sobre cada objeto en la matriz objects
    objects.forEach((obj) => {
      // Filtrar por libraryId y pointLibraryId
      if (obj.typeElement.pointLibraryId === chart.libraryId) {
        // Verificar si el objeto cumple con los criterios de filtrado
        let filtered = true;
        for (const filter of chart.columnsValuesFilter) {
          const attribute = obj.attributes.find(
            (attr) => attr.name === filter.name
          );
          if (!attribute || !filter.values.includes(attribute.value)) {
            filtered = false;
            break;
          }
        }

        // Si el objeto pasa los criterios de filtrado, sumar su valor number01
        if (filtered) {
          const value = obj.attributes.find(
            (attr) => attr.name === chart.metric
          )?.value;

          const numValue = getNumericValue(value);

          totalSum += numValue;
        }
      }
    });

    return {
      ...chart,
      count: totalSum,
    };
  });

  return charts;
};
