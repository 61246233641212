import useSwr from "swr";
import { config } from "config.js";

const FetchUsersAdminCompany = (adminCompanyId) => {
  const token = localStorage.getItem("token"); // Obtener el token del almacenamiento local

  const { data, error } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`,
    async (url) => {
      const response = await fetch(url, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    }
  );

  return { data, error };
};

export default FetchUsersAdminCompany;
