import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_SECOND_COLOR } from "utils/const";

export const FeatureDetails = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 15px 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 4px;

  .title {
    font-size: 2rem;
    align-self: center;
  }

  .location-details {
    margin: 5px 0;
    font-weight: 500;
    font-size: 1.6rem;
  }

  .custom-select {
    margin: 5px 0;
    padding: 7px;
    width: 100%;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: #fff;
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    font-size: 1.6rem;
  }
  textarea {
    height: 60px;
  }

  .main-currency-table {
    width: 100%;
    min-width: 235px;
    margin: 10px auto;
  }
  .currency-table--container {
    width: 100%;
    min-width: 230px;
    margin: 0 auto;
  }
  .currency-table--container table {
    width: 100%;
    height: 100%;
  }

  .currency-table--container table td {
    width: 50%;
    height: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f9f9f9;
  }
  .currency-table--container .table__top-left {
    border-radius: 5px 0 0 0;
  }
  .currency-table--container .table__top-right {
    border-radius: 0 5px 0 0;
  }
  .currency-table--container .table__bottom-right {
    border-radius: 0 0 5px 0;
  }
  .currency-table--container .table__bottom-left {
    border-radius: 0 0 0 5px;
  }
  .currency-table--container .table__right {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
    background-color: #f9f9f9;
    color: #525252;
  }

  .input-location {
    border: 0px;
    padding: 0 5px;
    width: 100%;
    background-color: ${WHITE_SECOND_COLOR};
  }

  .button-copy {
    border: none;
    cursor: pointer;
  }
`;

export const ButtonCard = styled.button`
  width: 40%;
  height: 40px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
  background-color: ${PRIMARY_COLOR};
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  align-self: end;

  &:hover {
    background-color: ${SECOND_COLOR};
    cursor: pointer;
  }
`;

export const WraperTitleCardMarker = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;

  .wraper-title-element {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 5vw;
    margin: 10px 10vw;
    min-width: 300px;
    min-height: 150px;

    h2 {
      font-size: 2rem;
    }
  }
  span {
    color: ${PRIMARY_COLOR};
  }
  h1 {
    margin-top: 15px;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #636363;
  }
  img {
    width: 45px;
    height: 38px;
    margin: 5px 0;
  }
  .dynamicImg {
    width: 60px;
    height: auto;
    padding: 0;
  }
`;

export const LineContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  .container {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .text-line-preview {
    display: block;
    font-weight: 600;
    cursor: pointer;
    color: #5c5757;
    font-size: 1.6rem;
  }

  .line {
    width: 50px;
    height: ${(props) => props.width * 2}px;
    background: ${(props) => props.color};
  }

  .separator {
    width: ${(props) =>
      props.separator <= 0 ? 0.1 * 2 : props.separator * 2}px;
  }

  .circle {
    width: ${(props) => props.width * 8}px;
    height: ${(props) => props.width * 8}px;
    background: ${(props) => props.color};
    border-radius: 50%;
  }
`;
