import styled from "styled-components";

export const Container = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    margin: 20px 0;
  }

  .title span {
    font-weight: normal;
    margin-right: 10px;
  }

  .icon {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
  }

  .icon img {
    width: 300px;
  }
`;
