import Container from "@mui/material/Container";
import { ItemList } from "./UserRoutesElements";
import FetchEvents from "hooks/fetchEvents";
import { useStyles, CustomizedAccordion } from "./MapDrawerStyle";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor2 } from "Styles";
const ListEvents = () => {
  const { data: typeElements, error: errorTypeElements } = FetchEvents();
  const { theme } = useContext(ThemeContext);
  const classes = useStyles();

  const listTypeElements = typeElements?.map(
    (typeElement) => typeElement.pointLibraryEventId
  );
  const pointLibrariesIdsUnic = [...new Set(listTypeElements)];

  const listNameElements = typeElements?.map(
    (typeElement) => typeElement?.pointLibraryEvent?.name
  );
  const pointLibrariesNamesUnic = [...new Set(listNameElements)];
  return (
    <Container>
      {!errorTypeElements && typeElements !== undefined && (
        <div>
          <>
            {pointLibrariesIdsUnic.map((libraryId, index) => (
              <CustomizedAccordion
                classes={{
                  content: classes.content,
                  expanded: classes.expanded,
                }}
                defaultExpanded={false}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      fontFamily: document.body.style.fontFamily,
                      color:
                        theme === "light"
                          ? document.body.style.color
                          : WHITE_COLOR,
                    }}
                  >
                    {pointLibrariesNamesUnic[index]}
                  </Typography>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                        }}
                      />
                    }
                  ></AccordionSummary>
                </div>

                {typeElements
                  .filter(
                    (typeElement) =>
                      typeElement.pointLibraryEventId === libraryId
                  )
                  .map((typeElementId) => (
                    <ItemList style={{ color: StyleColor2(theme) }}>
                      <img src={typeElementId.icon} width="40px" alt="ico" />{" "}
                      <p>{typeElementId.name}</p>
                    </ItemList>
                  ))}
              </CustomizedAccordion>
            ))}
          </>
        </div>
      )}
    </Container>
  );
};

export default ListEvents;
