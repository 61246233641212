import { useState } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import { ButtonInfo } from "./ObjectDetails/ObjectDetailsElements";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// Dependencies
import { Grid } from "@mui/material";
import { CnstInfoTable } from "./OperationInfoStyles";
import { INFORMATION_COLOR } from "utils/const";

const OperationInfo = ({ content }) => {
  const usedConstants = ((data) => {
    return data?.options?.length === 0
      ? []
      : data?.options?.map((it) => {
          if (typeof it === "object") {
            return it;
          } else {
            return JSON.parse(it);
          }
        });
  })(content);

  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      agree: "Close",
    },
  });

  const handleShow = () => {
    setDialog((current) => ({
      ...current,
      open: true,
    }));
  };
  const handleShowOff = () => {
    setDialog((current) => ({
      ...current,
      open: false,
    }));
  };
  return (
    <ButtonInfo>
      {
        <ContactSupportIcon
          sx={{
            fontSize: "1.8rem",
            color: INFORMATION_COLOR,
          }}
          onClick={handleShow}
        />
      }
      <OpenDialog
        openDialog={dialog?.open}
        setOpenDialog={setDialog}
        execute={handleShowOff}
        content={{
          title: "Expression details",
          description: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <div>
                <span>
                  The result of this field is obtained from the following
                  expression:
                </span>
              </div>
              <div>
                <math-field
                  read-only
                  className="math-field"
                  id={content?.name}
                  style={{
                    display: "inline-block",
                    minWidth: "200px",
                    width: "100%",
                    minHeight: "60px",
                    fontSize: "2.2rem",
                    border: "none",
                  }}
                  value={content?.operation}
                />
              </div>
              {usedConstants?.length > 0 && (
                <ShowConstantsUsed array={usedConstants} />
              )}
            </div>
          ),
          ...dialog.actions,
        }}
      />
    </ButtonInfo>
  );
};

const ShowConstantsUsed = ({ array }) => {
  return (
    <Grid container width={"100%"} justifyContent={"center"}>
      <CnstInfoTable>
        <tr>
          <th>Alias</th>
          <th>Name</th>
          <th>Value</th>
        </tr>
        {array.map((item) => (
          <tr>
            <td>{item.alias}</td>
            <td>{item.name}</td>
            <td>{item.value}</td>
          </tr>
        ))}
      </CnstInfoTable>
    </Grid>
  );
};

export default OperationInfo;
