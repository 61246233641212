import styled from "styled-components";

export const ProfileStyle = styled.div`
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;

  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;

  .title-profile {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
  }

  @media screen and (max-width: 250px) {
    margin-top: 400px;
    height: 160vh;
  }
`;
