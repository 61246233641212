
/**
 * Checks if a given string is a valid JSON or not.
 * 
 * @param {string} str - The input string to be checked for JSON validity.
 * @returns {boolean} - True if the input string is a valid JSON, false otherwise.
 * 
 * @example
 * const jsonString = '{"name": "John", "age": 30}';
 * const isValid = isValidJSON(jsonString);
 * console.log(isValid); // true
 * 
 * const invalidString = 'This is not a valid JSON';
 * const isInvalid = isValidJSON(invalidString);
 * console.log(isInvalid); // false
 * 
 * const invalidString = '30'; // a string number
 * const isValid = isValidJSON(invalidString);
 * console.log(isValid); // false
 */
function isValidJSON(str) {
  if (str === null || str === undefined || typeof str !== "string") return false;
  if (!isNaN(parseInt(str))) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Retrieves a constant library object from an array based on the given ID.
 * If the ID is not provided or is null, it returns the first object in the array.
 * If the ID is provided but not found in the array, it also returns the first object in the array.
 * If the array is empty or undefined, it returns an empty object.
 *
 * @param {Array} lib - An array of constant library objects.
 * @param {number} [id] - The ID of the constant library object to retrieve.
 * @returns {Object} - The constant library object that matches the provided ID, or the first object in the array if the ID is not found or not provided.
 *                    - An empty object if the lib array is empty or undefined.
 *
 * @example
 * const library = [
 *   { id: 1, name: "Library 1" },
 *   { id: 2, name: "Library 2" },
 *   { id: 3, name: "Library 3" }
 * ];
 *
 * const constant = getConstantLibrary(library, 2);
 * console.log(constant); // { id: 2, name: "Library 2" }
 *
 * const defaultConstant = getConstantLibrary(library);
 * console.log(defaultConstant); // { id: 1, name: "Library 1" }
 */
const getConstantLibrary = (lib, id) => {
  if (lib !== undefined && lib?.length > 0) {
    if (id === undefined || id === null) return lib[0];
    const libChoosed = lib.find((it) => it.id === id);
    if (libChoosed === undefined) return lib[0];
    return libChoosed;
  }
  return {};
};

/**
 * Retrieves the library ID from the `options` property of an object Field.
 * If the `options` array is empty or does not contain a valid JSON string with a `constantLibraryId` property, it returns 0.
 *
 * @param {Object} obj - The object containing the `options` property.
 * @returns {number} - The library ID of the Constants extracted from the `options` array.
 */
function getLibraryIdFromOldOptions({ options }) {
  try {
    if (options === undefined || options.length === 0) return 0;
    const parsedConstants = options?.map((cnst) => {
      if (typeof cnst === "string" && isValidJSON(cnst)) return JSON.parse(cnst);
      return cnst;
    });
    const id = parsedConstants.at(0).constantLibraryId
    return id;
  } catch (error) {
    console.error("error getting id from old options:", error.stack);
  }
}

export {
  isValidJSON,
  getConstantLibrary,
  getLibraryIdFromOldOptions
}