// Constants
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";

const SelectObjectTypes = ({
  objectTypes,
  librarySelected,
  handlerClickObjectType,
  typeIdsSelected,
  typeBar,
}) => {
  return (
    <>
      <h2 className="subtitle">Select type</h2>
      <section className="container-type-charts ">
        {objectTypes
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((objectType) =>
            typeBar === "countByType"
              ? true
              : objectType.pointLibraryId === librarySelected
          )
          .map((objectType) => (
            <div
              key={objectType.id}
              className="card-type-chart"
              onClick={() =>
                handlerClickObjectType(
                  objectType.id,
                  objectType.pointLibraryId,
                  objectType
                )
              }
              style={{
                backgroundColor: typeIdsSelected.includes(objectType.id)
                  ? ICON_GREEN_COLOR
                  : WHITE_COLOR,
              }}
            >
              <p>{objectType.name} </p>
              <span>
                <img
                  width="30px"
                  src={
                    objectType.urlIconColor
                      ? objectType.urlIconColor
                      : "https://static.thenounproject.com/png/79163-200.png"
                  }
                  alt="icon object library"
                />
              </span>
            </div>
          ))}
      </section>
    </>
  );
};

export default SelectObjectTypes;
