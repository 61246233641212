import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const DragButton = styled.button`
  position: absolute;
  top: 3px;
  left: ${({ ml }) => ml}px;
  border: none;
  background-color: transparent;
  z-index: 2;

  &:hover {
    cursor: ${({ cursor }) => cursor};
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


export const SelectViewStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "start",
    fontSize: "16px",
    "&:hover": {
      color: PRIMARY_COLOR,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    fontSize: "13px",
    left: 0,
    zIndex: 3,
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 20px)",
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    overflow: "none",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),  
};


