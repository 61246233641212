import Grid from "@material-ui/core/Grid";
import PhotoGallery from "components/ElementDetails/PhotoGalley/PhotoGallery";
import { useSWRConfig } from "swr";
import { config } from "config.js";
import { useContext } from "react";
import { ThemeContext } from "App";
import { PRIMARY_COLOR, WHITE_COLOR } from "utils/const";
const GalleryByType = ({ line, mediaLine }) => {
  const { mutate } = useSWRConfig();
  const { theme } = useContext(ThemeContext);
  const uploadImages = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/lines?id=${line?.id}`);
  };
  return (
    <>
      {mediaLine?.map((mediaGroup) => {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            key={mediaGroup[0].alias}
          >
            <div
              className={`photo-container ${theme === "light" ? "bb" : "bb2"}`}
            >
              <h1
                className="event-title"
                style={{
                  color: theme === "light" ? PRIMARY_COLOR : WHITE_COLOR,
                }}
              >
                <span>{mediaGroup[0].alias}</span>
              </h1>
              {
                <div
                  style={{
                    width: "85%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 0px 20px 0px",
                  }}
                >
                  <PhotoGallery
                    photos={mediaGroup}
                    ownId={line?.id}
                    state={false}
                    mongoId={line?.geojsonLineId}
                    type={"lineObject"}
                    uploadImages={uploadImages}
                  />{" "}
                </div>
              }
            </div>
          </Grid>
        );
      })}
    </>
  );
};

export default GalleryByType;
