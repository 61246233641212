import findTypeLinesTypeObjects from "services/typeLinesTypeObjects/find.js";
import createTypeLinesTypeObjects from "services/typeLinesTypeObjects/create";
import deleteTypeLinesTypeObjects from "services/typeLinesTypeObjects/delete";

const UpdateTypeObjectsTypeObjects = ({
  typeLine,
  typeLinesTypeObjects,
  setTypeLinesTypeObjects,
}) => {
  const update = async () => {
    const { id } = typeLine;
    const lastTypeLinesTypeObjects = await findTypeLinesTypeObjects({
      typeLineId: id,
    });
    const toDelete = [];
    const toCreate = [];

    lastTypeLinesTypeObjects.forEach(({ typeElementId }) => {
      if (!typeLinesTypeObjects[typeElementId]) {
        const ids = lastTypeLinesTypeObjects
          .filter((last) => last.typeElementId === typeElementId)
          .map((last) => last.id);
        ids.filter((item, index) => ids.indexOf(item) === index);
        toDelete.push(...ids);
      }
    });

    Object.keys(typeLinesTypeObjects).forEach((typeElementId) => {
      if (
        !lastTypeLinesTypeObjects.find(
          (e) => parseInt(e.typeElementId) === parseInt(typeElementId)
        )
      )
        toCreate.push(typeElementId);
    });

    await Promise.all(
      toCreate.map(async (typeElementId) => {
        await createTypeLinesTypeObjects({
          typeLineId: typeLine.id,
          typeElementId: parseInt(typeElementId),
        });
      })
    );

    await Promise.all(
      toDelete.map(async (id) => {
        await deleteTypeLinesTypeObjects(id);
      })
    );

    setTypeLinesTypeObjects({});
  };

  return update;
};

export default UpdateTypeObjectsTypeObjects;
