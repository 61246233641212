import CustomTabs from "pages/AdminCompany/Hub/CustomTabs";
import React from "react";
import ModulesOper from "./ModulesOper";

const TabsOper = ({ userRole }) => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <CustomTabs value={value} onChange={handleChange}/>

      {value === 0 && <ModulesOper userRole={userRole} />} {/* Pasando userRole */}
      {value === 1 && <ModulesOper userRole={userRole} hideCards={true} />} {/* Pasando userRole y hideCards */}
      {value === 2 && <ModulesOper userRole={userRole} hideCards={true} />} {/* Pasando userRole y hideCards */}
    </div>
  );
};

export default TabsOper;
