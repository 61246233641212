import React from "react";
import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import { PRIMARY_COLOR, WHITE_SECOND_COLOR } from "utils/const";
import { CircularProgress } from "@mui/material";

const getDocument = (pages) => {
  /* <Document> <Page>CONTENT</Page> </Document>*/
  const document = !pages ? (
    <Document>
      <Page>
        <Text>No Content</Text>
      </Page>
    </Document>
  ) : (
    <Document>{pages?.map((page) => page)}</Document>
  );
  return document;
};

/**
 * PDFCreator component receives an array of pdf templates
 * @param {Array<Object>} pages - Array of pdf pages templates to render
 * @param {Boolean} download - Boolean for selecting the button to download pdf
 * @param {Boolean} buttom - If buttom is false then PDFCreator will return the url of the pdf
 * @returns - <PDFViewer/> | <PDFDownloadLink/> | url
 */
const PDFCreator = ({ pages = [], downloadButton = false }) => {
  const document = getDocument(pages);
  return downloadButton ? (
    <div className={styles.downloadButtom}>
      <PDFDownloadLink
        document={document}
        fileName={`report_${new Date().toDateString().toString()}`}
        style={styles.downloadAnchor}
      >
        {({ blob, url, loading, error }) => {
          return loading ? (
            <CircularProgress style={styles.circularProgressStyle} />
          ) : (
            "Download now"
          );
        }}
      </PDFDownloadLink>
    </div>
  ) : (
    <PDFViewer style={styles.viewer}>{document}</PDFViewer>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  downloadButtom: {
    minWidth: "200px",
    width: "max-content",
    height: "auto",
    padding: "10px 20px",
  },
  downloadAnchor: {
    backgroundColor: PRIMARY_COLOR,
    minWidth: "200px",
    width: "100%",
    height: "auto",
    padding: "10px 20px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    textDecoration: "none",
    color: WHITE_SECOND_COLOR,
  },
  viewer: {
    width: "100%",
    height: "auto",
    minHeight: "600px",
  },
  circularProgressStyle: {
    width: "20px",
    height: "20px",
    color: WHITE_SECOND_COLOR,
    marginRight: "5px",
  },
});

export default PDFCreator;
