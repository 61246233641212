import { createGlobalStyle } from "styled-components";
import { WHITE_BACKGROUND } from "utils/const";

export const Styles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Montserrat', sans-serif; */
    }
.hhh{
  background-color: purple;
}
html {
  font-size: 62.5%;
}

p {
    font-size:1.4rem;
}

main {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    padding: 40px;
    font-family: 'Montserrat', sans-serif;
    }

body {
    background: ${WHITE_BACKGROUND};
    font-family: 'Montserrat', sans-serif;
    min-width: 500px;
    width: auto;
}
.ML__keyboard{
    --keyboard-zindex: 3000;
    --keycap-max-width: 200px;
    --keyboard-toolbar-font-size: 2.5rem;
    --keycap-gap: 5px;
    --keycap-border: 1px solid;
}

@media screen and (max-width: 768px) {
  body {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  body {
    flex-direction: column;
  }
}
`;
