import useSwr from "swr";
import { config } from "config.js";

/**
 * Fetches components by object ID.
 *
 * @param {string} objectId - The ID of the object to fetch components for.
 * @return {object} - An object {data, error} containing the fetched data and any errors that occurred.
 */
const FetchComponentsByObjectId = (objectId) => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { data, error } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-components?objectId=${objectId}&adminCompanyId=${adminCompanyId}`
  );
  return { data, error };
};

export default FetchComponentsByObjectId;
