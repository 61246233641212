import axios from "axios";
import { config } from "config.js";

/**
 * Updates an object in the database.
 *
 * @param {Object} body - The updated object data.
 * @param {string} id - The ID of the object to update.
 * @return {Promise<Object>} An object containing the updated data and the response.
 */
const createViewTableColumns = async ({
  body,
  nameView,
  typeOfLibrary,
  typeOfTable,
}) => {
  try {
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${config.URL_BACKEND_MG}order-columns/${userId}?adminCompanyId=${adminCompanyId}&name=${nameView}&typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const response = await res.data;
    return { response, res };
  } catch (error) {
    console.error(error);
  }
};

export default createViewTableColumns;
