import styled from "styled-components";
import {
  WHITE_COLOR,
  BACKGROUND_SECOND_GREY,
  GRAY_COLOR,
  PRIMARY_COLOR,
  BACKGROUD_GREY_PREVIEW,
} from "utils/const";

export const CardContainer = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: ${WHITE_COLOR};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  .delete-card-button {
    position: absolute;
    top: 3px;
    right: 3px;
    border: none;
    background-color: ${WHITE_COLOR};
    cursor: pointer;
    z-index: 2;
  }
  .delete-card-in-view-button {
    position: absolute;
    top: 3px;
    right: 25px;
    border: none;
    background-color: ${WHITE_COLOR};
    cursor: pointer;
    z-index: 2;
  }
  .restore-card-button {
    position: absolute;
    top: 3px;
    right: 3px;
    border: none;
    background-color: ${WHITE_COLOR};
    cursor: pointer;
    z-index: 2;
  }
`;

export const ResizeRight = styled.div`
  .hover-area {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 0px;
    margin-top: 20px;
    padding-bottom: 20px;
    z-index: 1;
  }

  .hidden-div {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 100%;
    width: 25px;
    border-bottom-right-radius: 10px;
    background: #03030326;
    border-left: dashed 2px #0000002e;
    border-top: dashed 2px #0000002e;
    margin-top: 5px;
    padding-bottom: 24px;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50%;
      border-bottom: dashed 2px #0000002e;
    }

    span:hover {
      transform: scale(1);
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const ResizeBottom = styled.div`
  .hover-area {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
  }

  .hidden-div {
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;
    position: absolute;
    align-items: center;
    text-align: center;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 25px;
    width: 100%;
    background: #03030326;
    border-top: dashed 2px #0000002e;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      border-right: dashed 2px #0000002e;
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const CardBar = styled.div`
  position: ${({ position }) => position};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 100%;
  width: 100%;
  background: ${WHITE_COLOR};
  padding: 35px 5px;
  text-align: center;
  border-radius: 10px;
`;

// ---------------------- Preview Bar Chart Styles -----------------------------

export const ContainerPreviewChart = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: ${WHITE_COLOR};
  border-radius: 10px;
  width: 100%;
  max-height: 75vh;

  .section-chart-button {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 68%;
    margin-right: 10px;
    max-height: 75vh;
  }

  .preview-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${BACKGROUD_GREY_PREVIEW};
    width: 100%;
    height: 90%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
  }

  .chart {
    width: 100%;
  }
  .container-update {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${WHITE_COLOR};
    width: 100%;
    height: 10%;
    margin-right: 10px;
    border-radius: 10px;
  }

  .editing-tools {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 32%;
    background: ${BACKGROUD_GREY_PREVIEW};
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
  }

  .groupe-styles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: ${WHITE_COLOR};
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .sections {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 5px;
      color: ${WHITE_COLOR};
      background: ${PRIMARY_COLOR};
      margin-bottom: 15px;
      height: 40px;
    }
  }

  .section-style {
    display: flex;
    flex-wrap: wrap;
    margin: 2px;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    .alias {
      width: 100%;
      align-self: center;
      padding: 10px;
    }
    p {
      font-size: 15px;
    }

    h4 {
      color: ${GRAY_COLOR};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: end;
    }

    .input-title {
      width: 100%;
      height: 43px;
      padding: 5px 5px 0px 5px;
      margin: 0px 5px 0px 5px;
      border: solid 3px #b7b7b7;
      border-radius: 5px;
      background: ${WHITE_COLOR};
      text-align: center;
      font-weight: 900;
    }

    .input-title:focus {
      outline: none;
    }

    input[type="number"] {
      width: 38%;
      height: 18px;
      margin: 10px;
      text-align: center;
      border: none;
      background-color: ${BACKGROUND_SECOND_GREY};
      font-weight: 600;
    }

    input[type="checkbox"] {
      width: 45%;
      height: 20px;
    }

    input[type="range"] {
      margin: 10px;
      width: 59%;
    }

    input[type="color"] {
      border: none;
      width: 45%;
      height: 37px;
      background-color: ${WHITE_COLOR};
      cursor: pointer;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: 2px solid ${BACKGROUND_SECOND_GREY};
      border-radius: 5px;
    }

    select {
      border: none;
      width: 40%;
      margin: 7px;
      height: 25px;
      border-radius: 5px;
      text-align: center;
      color: ${WHITE_COLOR};
      background-color: ${PRIMARY_COLOR};
      cursor: pointer;
    }
  }

  .section-color-conditional {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    .alias {
      width: 100%;
      align-self: center;
      padding: 10px;
    }
    p {
      font-size: 15px;
    }
    div {
      display: flex;
      align-items: center;
      width: 40%;
    }

    input[type="checkbox"] {
      width: 10%;
    }

    input[type="color"] {
      border: none;
      width: 90%;
      height: 37px;
      background-color: ${WHITE_COLOR};
      cursor: pointer;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: 2px solid ${BACKGROUND_SECOND_GREY};
      border-radius: 5px;
    }
  }
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 5px 0;
  }

  .divider::before,
  .divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
  }

  .divider-title {
    padding: 0 10px;
    color: #666;
    font-size: 16px;
  }
`;
