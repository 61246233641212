// Styles
import { Container } from "@mui/material";
//Components
import ListUser from "components/Tables/BasicTable/ListOperators";
import Layout from "components/Layout/AdminDecimetrix";
import { ContainerUser } from "./operatorStyles";

const Operators = () => {
  return (
    <Layout>
      <ContainerUser className="background-1">
        <Container
          style={{
            paddingTop: 60,
          }}
          maxWidth="xl"
        >
          <ListUser />
        </Container>
      </ContainerUser>
    </Layout>
  );
};

export default Operators;
