import axios from "axios";
import { config } from "config.js";

const uploadFileEvent = async (body) => {
  try {
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/event-files`,
      body
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default uploadFileEvent;

/**
 * Saves the captured media for a given point event.
 *
 * @param {Object} options - The options object.
 * @param {string} options.pointEventMgId - The ID of the point event.
 * @param {Object[]} options.media - The media to be saved.
 * @param {string} options.media.type - The type of the media.
 * @param {string} options.media.alias - The alias of the media.
 * @param {string} options.media.url - The URL of the media.
 * @return {Promise<{data: any, res: any}>} The response object containing the data and response from the server.
 */
export const saveCaptureMedia = async ({ pointEventMgId, media }) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.patch(
    `${config.URL_BACKEND_MG}point-event/${pointEventMgId}`,
    {
      $push: {
        captureMedia: {
          $each: media,
        },
      },
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};

export const updateCaptureMedia = async ({
  endPoint,
  pointTypeMgId,
  mediaId,
  media,
}) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png",
      "rotation": "tipo numero - posicion imagen"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.patch(
    `${config.URL_BACKEND_MG}${endPoint}${pointTypeMgId}?mediaId=${mediaId}`,
    media,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};

export const deleteCaptureMedia = async ({
  endPoint,
  pointTypeMgId,
  mediaId,
}) => {
  /*Example media:
   [
    {
      "type": "photos_namplate", 
      "alias": "fotos de namplate",
      "url": "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png",
      "rotation": "tipo numero - posicion imagen"
    },
  ]*/
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${config.URL_BACKEND_MG}${endPoint}${pointTypeMgId}?mediaId=${mediaId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = res.data;
  return { data, res };
};

export const deleteCaptureMediaPg = async (endPoint) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/${endPoint}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};
