import styled from "styled-components";
import {
  WHITE_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_BACKGROUND,
  BLACK_COLOR,
} from "utils/const";

export const Title = styled("div")`
  font-size: 1.4rem;
  background-color: ${WHITE_BACKGROUND};
  margin: 20px 0;
  color: ${BLACK_COLOR};
`;

export const ContainerUser = styled.div`
  min-height: calc(100vh - 7rem);

  /* min-height: 100vh; */
`;

export const ButtonStyle = styled("div")`
  background-color: ${WHITE_BACKGROUND};
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  button {
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: ${SECOND_COLOR};
    }
  }
`;
