import styled from "styled-components";
import {
  BACKGROUND_GREY,
  BLACK_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_BACKGROUND,
} from "utils/const";
import SendIcon from "@mui/icons-material/Send";

export const ChatContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: ${BLACK_COLOR};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export const ContainerChat = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const MessagesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  color: ${document.body.style.color};

  .examples {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: ${document.body.style.color};
  }

  .examples ul li {
    list-style: none;
    margin: 10px 0;
    padding: 10px;
    font-size: 1.4rem;
    background-color: ${BACKGROUND_GREY};
    text-align: center;
    border-radius: 5px;
  }

  .examples li:hover {
    background-color: ${SECOND_COLOR};
    cursor: pointer;
  }
`;

export const Messages = styled.ul`
  display: flex;
  flex-direction: column;
  width: 90%;
  list-style-type: none;
  margin: 0 auto;
  padding: 10px;
`;

export const Message = styled.li`
  display: inline-block;
  background-color: ${({ isResponse }) =>
    isResponse ? PRIMARY_COLOR : SECOND_COLOR};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 1.4rem;
  color: ${({ isResponse }) => (isResponse ? "white" : BLACK_COLOR)};
`;

export const InputContainer = styled.form`
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 30px;
  max-width: 1200px;
  border-radius: 100px;
  border: 1px solid #5f6368;
  justify-content: center;
  background: ${WHITE_BACKGROUND};

  :hover {
    border: 1px solid #303134;
    box-shadow: 0px 0px 2px #303134;
  }

  .search-icon {
    background-image: url("https://i0.wp.com/www.thinkafrica.fi/wp-content/uploads/2019/04/search-icon.png?fit=1200%2C1200&ssl=1");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  margin: 0 10px;
  background-color: ${WHITE_BACKGROUND};
  font-size: 1.6rem;
`;

export const SendIconButton = styled(SendIcon)`
  color: var(--background-primary_color);
  margin: 0 10px;

  :hover {
    cursor: pointer;
    color: var(--background-secondary_color);
  }
`;
