import { useEffect, useState, useCallback } from "react";
// Constants
// Hooks
import useFindObjects from "hooks/objects/useFindObjects";
// Helpers
import { sum, count } from "pages/Dashboard/helpers/componentsKpis";
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";
// Services
import useSwr from "swr";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { config } from "config";

const useFindKpiCharts = ({ openDialog, components, loading }) => {
  const [charts, setCharts] = useState(null);
  const [kpiCharts, setKpiCharts] = useState(null);
  const [componentsFormat, setComponentsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { objects } = useFindObjects();
  const urlKpi = `${config.URL_BACKEND_MG}kpi-chart?adminCompanyId=${adminCompanyId}&from=components`;
  const { data: dataKpi, error: errorDataKpi } = useSwr(urlKpi);

  useEffect(() => {
    if (!errorDataKpi && dataKpi) setCharts(dataKpi);
  }, [dataKpi, errorDataKpi, openDialog, reloadCharts]);

  useEffect(() => {
    const componentsFormat = createRowsComponents({ components, objects });
    setComponentsFormat(componentsFormat);
  }, [components, objects]);

  const getDataKpiChartCount = useCallback(async () => {
    if (componentsFormat && !loading) {
      const kpiChart = charts.filter((item) => item.type === "count");
      // Charts total components
      const chartsCount = count({
        kpiChart,
        components: componentsFormat,
      });

      setKpiCharts([...chartsCount]);
    }
  }, [charts, componentsFormat, loading]);

  const getDataKpiChartSum = useCallback(async () => {
    if (componentsFormat && !loading) {
      const kpiChart = charts.filter((item) => item.type === "sum");
      // Sum components by library
      const chartSum = sum({
        kpiChart,
        components: componentsFormat,
      });

      // Set state once with all the calculated values
      setKpiCharts((prev) => [...prev, ...chartSum]);
    }
  }, [charts, componentsFormat, loading]);

  useEffect(() => {
    if (charts && components) {
      getDataKpiChartCount();
      getDataKpiChartSum();
    }
  }, [charts, components, getDataKpiChartCount, getDataKpiChartSum]);

  return { kpiCharts };
};

export default useFindKpiCharts;
