import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 30px auto;
  align-items: center;
  justify-items: center;
  height: 100vh;
  text-align: left;
  padding: 0 20px;

  img {
    width: 40%;
  }

  h1 {
    font-size: 4rem;
    margin: 30px 0;
  }

  h2 {
    align-self: flex-start;
    font-size: 2rem;
  }

  p {
    margin: 20px 0;
    font-size: 2rem;
  }
`;
