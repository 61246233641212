const isoDateToString = (isoDate) => {
  const date = new Date(isoDate);
  return date.toString().substring(0, 23);
};

export function createRows({ pointEvents, fieldFile }) {
  const fileVideoExistsArray = pointEvents.map((event) => {
    const fieldVideo = event.attributes.some(
      (attribute) => attribute.name === fieldFile?.name
    );
    const urlVideo = event.attributes.find(
      (attribute) => attribute.name === fieldFile?.name
    )?.value;
    const videoName = urlVideo?.split("/").pop();
    const { firstName, firstLastName } =
      event?.user?.operator || event?.user?.adminCompany;
    return {
      id: event.id,
      download: (
        <a key={urlVideo} href={urlVideo} target="_blank" rel="noreferrer">
          {videoName}
        </a>
      ),
      fieldVideo: fieldVideo ? "✅" : "❌",
      userCollected: firstName + " " + firstLastName,
      date: isoDateToString(event.date),
    };
  });
  return fileVideoExistsArray;
}

export function createRowsVideos(videos) {
  return videos.map((video) => {
    return {
      id: null,
      download: (
        <a key={video.url} href={video.url} target="_blank" rel="noreferrer">
          {video.name}
        </a>
      ),
      fieldVideo: "⚠️",
      userCollected: "",
      date: `${isoDateToString(video.date)}`,
    };
  });
}
