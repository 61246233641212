import axios from "axios";
import { config } from "config.js";

const findTypeLinesTypeObjects = async ({ typeLineId, typeElementId }) => {
  const token = localStorage.getItem("token");
  const urlParams = new URLSearchParams();
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  if (adminCompanyId) urlParams.append("adminCompanyId", adminCompanyId);
  if (typeLineId) urlParams.append("typeLineId", typeLineId);
  if (typeElementId) urlParams.append("typeElementId", typeElementId);

  const result = await axios.get(
    `${
      config.URL_BACKEND_PG
    }api/v1/type-lines-type-objects?${urlParams.toString()}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );

  const data = await result.data;
  return data;
};

export default findTypeLinesTypeObjects;
