import axios from "axios";
import { config } from "config.js";

const findLibraryComponents = async (libraryId) => {
  try {
    const token = localStorage.getItem("token");
    let url = `${config.URL_BACKEND_PG}api/v1/point-library-components`;
    if (libraryId) url = `${url}?id=${libraryId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default findLibraryComponents;
