// Style
import {
  CardContainer,
  CardDonut,
  ResizeBottom,
  ResizeRight,
} from "./indexStyle";
import CloseIcon from "@mui/icons-material/Close";
import { GREY_TRANS } from "utils/const";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useEffect, useState } from "react";
import randomColorRGBA from "helpers/generateRandomRGBA";
import { updateOrderCard } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { config } from "config";
import handleAddGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridColumn";
import handleRemoveGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridColumn";
import handleRemoveGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridRow";
import handleAddGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridRow";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import { useSetCurrentStyle } from "./Hooks/useSetCurrentStyle";
import { fixedDecimalNumber } from "pages/Dashboard/helpers/fixedDecimalNumber";
ChartJS.register(ArcElement, Title, Tooltip, Legend);
Chart.register(ChartDataLabels);

const GroupDonutChart = ({
  dataChart,
  currentStyle,
  template,
  index,
  setCurrentTemplate,
  responsiveWidth = null,
  responsiveHeight = null,
  restore = false,
  orderId,
  currentOrderName,
  currentOrderViews,
  setOpenDialogRestoreChart,
  setCurrentOrderViews,
  currentPositionId,
  handlerDeleteChartOnView,
  setDeleteChartDialog,
}) => {
  const role = localStorage.getItem("role");
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
  const { mutate } = useSWRConfig();
  const { setDragAndDroll } = useStoreDashboard();
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  const handleRestoreChart = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: [
          ...currentOrderViews,
          {
            id: dataChart._id,
            sizeChart: template.sizeChart,
          },
        ],
        _id: currentPositionId,
      },
      type: dataChart.from,
    });
    setCurrentOrderViews((current) => [
      ...current,
      {
        id: dataChart._id,
        sizeChart: template.sizeChart,
      },
    ]);
    mutate(urlOrders);
    setOpenDialogRestoreChart(false);
  };

  const { currentStyleLineDataChart } = useSetCurrentStyle({
    dataChart,
  });

  return (
    <CardContainer
      key={dataChart._id}
      width={
        template?.index
          ? currentStyle.width
          : responsiveWidth
          ? `${currentStyle.width - responsiveWidth}px`
          : `${currentStyle.width}px`
      }
      height={
        template?.index
          ? currentStyle.height
          : responsiveHeight
          ? `${currentStyle.height - responsiveHeight}px`
          : `${currentStyle.height}px`
      }
    >
      {role === "Administrador de compañía" &&
        !restore &&
        chartsIconsShowOnOff && (
          <button
            className="delete-card-button"
            onClick={() =>
              currentOrderName === "Design New View"
                ? setDeleteChartDialog(true)
                : handlerDeleteChartOnView()
            }
          >
            <span>
              <CloseIcon
                sx={{
                  color: GREY_TRANS,
                }}
              />
            </span>
          </button>
        )}

      {role === "Administrador de compañía" &&
        restore &&
        chartsIconsShowOnOff && (
          <button
            className="restore-card-button"
            onClick={() => handleRestoreChart()}
          >
            <RestartAltIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </button>
        )}

      {template && (
        <>
          <ResizeRight>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleAddGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleRemoveGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeRight>
          <ResizeBottom>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleRemoveGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleAddGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeBottom>
        </>
      )}
      <CardDonut>
        <Doughnut
          data={{
            labels: currentStyleLineDataChart?.labels,
            datasets: currentStyleLineDataChart?.datasets,
          }}
          options={{
            responsive: true,
            // aspectRatio: 2,
            layout: {
              padding: {
                left: 10,
                right: 20,
                top: 10,
                bottom: 10,
              },
            },
            plugins: {
              datalabels: {
                formatter: (value, ctx) => {
                  const datasets = ctx.chart.data.datasets;
                  const total = datasets.reduce((total, dataset) => {
                    return (
                      total +
                      dataset.data.reduce(
                        (datasetTotal, datapoint) => datasetTotal + datapoint,
                        0
                      )
                    );
                  }, 0);

                  const percentage = ((value / total) * 100).toFixed(1);

                  if (
                    currentStyleLineDataChart.chartStyle.datalabels
                      .showPercentage
                  ) {
                    return [
                      `${fixedDecimalNumber({
                        number: value,
                        numDecimals: ctx.dataset?.datalabels?.numDecimals,
                      })}; ${percentage}%`,
                    ];
                  } else {
                    return fixedDecimalNumber({
                      number: value,
                      numDecimals: ctx.dataset?.datalabels?.numDecimals,
                    });
                  }
                },

                color: function (context) {
                  return context.dataset?.datalabels?.colorTotal;
                },
                anchor: function (context) {
                  return context.dataset?.datalabels?.positionTotal;
                },
                align: function (context) {
                  return context.dataset?.datalabels?.positionTotal;
                },
                offset: function (context) {
                  return context.dataset?.datalabels?.offsetTotal;
                },
                font: function (context) {
                  return {
                    size: context.dataset?.datalabels?.fontSizeTotal,
                  };
                },
                backgroundColor: function (context) {
                  if (
                    context.dataset?.datalabels?.displayTotal &&
                    context.dataset?.datalabels?.displayBackgroundTotal
                  ) {
                    return context.dataset?.datalabels?.backgroundColorTotal;
                  }
                },
                borderWidth: 1,
                borderColor: function (context) {
                  if (
                    context.dataset?.datalabels?.displayTotal &&
                    context.dataset?.datalabels?.displayBackgroundTotal
                  ) {
                    return context.dataset?.datalabels?.backgroundColorTotal;
                  }
                },
                borderRadius: 3,
                display: (context) => {
                  if (context.dataset?.datalabels?.displayTotal) {
                    const dataset = context.dataset;
                    const value = dataset.data[context.dataIndex];
                    const total = dataset.data.reduce(
                      (acc, data) => acc + data,
                      0
                    );
                    const percentage = (value / total) * 100;
                    return percentage > 1;
                  } else {
                    return false;
                  }
                },
              },
              title: {
                display: true,
                color: currentStyleLineDataChart.chartStyle.colorTitle,
                text: currentStyleLineDataChart.alias,
                font: {
                  size: currentStyleLineDataChart.chartStyle.fontSizeTitle,
                  family: "Montserrat",
                },
                padding: {
                  top: 20,
                },
              },
              subtitle: {
                display:
                  currentStyleLineDataChart.type === "sum" ? true : false,
                text: `Sum ${currentStyleLineDataChart?.columnY}`,
                font: {
                  size: 15,
                  family: "Montserrat",
                },
              },
              legend: {
                display: true,
                onClick: function (e, legendItem, legend) {
                  const ci = legend.chart;

                  if (legendItem.isFromStyleLineDataChart) {
                    const meta = ci.getDatasetMeta(legendItem.datasetIndex);
                    meta.hidden =
                      meta.hidden === null
                        ? !ci.data.datasets[legendItem.datasetIndex].hidden
                        : null;
                  } else {
                    const dataIndex = legendItem.index;
                    ci.data.datasets.forEach((dataset, i) => {
                      const dataMeta = ci.getDatasetMeta(i);
                      dataMeta.data[dataIndex].hidden =
                        !dataMeta.data[dataIndex].hidden;
                    });
                  }

                  ci.update();
                },

                labels: {
                  generateLabels: function (chart) {
                    let data = chart.data.datasets;
                    const labelsFromChartData = chart.data.labels.map(
                      (label, index) => {
                        let dataset = data.find(
                          (ds) => ds.data[index] !== undefined
                        );

                        return {
                          text: label,
                          fillStyle: dataset.backgroundColor[index],
                          hidden: chart.getDatasetMeta(0).data[index].hidden,
                          index: index,
                          isFromStyleLineDataChart: false,
                        };
                      }
                    );

                    // const labelsFromStyleLineDataChart =
                    //   currentStyleLineDataChart?.datasets.map(
                    //     (dataset, i) => {
                    //       const meta = chart.getDatasetMeta(i);
                    //       return {
                    //         text: dataset.alias,
                    //         // fillStyle: dataset.backgroundColor,
                    //         fillStyle: dataset.borderColor,
                    //         hidden: meta.hidden,
                    //         lineCap: dataset.borderCapStyle,
                    //         lineDash: dataset.borderDash,
                    //         lineDashOffset: dataset.borderDashOffset,
                    //         lineJoin: dataset.borderJoinStyle,
                    //         lineWidth: dataset.borderWidth,
                    //         strokeStyle: dataset.borderColor,
                    //         pointStyle: dataset.pointStyle,
                    //         datasetIndex: i,
                    //         fontStyle: meta.hidden
                    //           ? "italic"
                    //           : "normal",
                    //         fontColor: meta.hidden ? "#999" : "#666",
                    //         isFromStyleLineDataChart: true,
                    //       };
                    //     }
                    //   );

                    return [
                      ...labelsFromChartData,
                      // ...labelsFromStyleLineDataChart,
                    ];
                  },
                },
              },
              afterDraw: (chart) => {
                const ctx = chart.ctx;
                const centerX =
                  (chart.chartArea.left + chart.chartArea.right) / 2;
                const centerY =
                  (chart.chartArea.top + chart.chartArea.bottom) / 2;

                // Sumar todos los valores de todos los datasets
                const total = chart.data.datasets.reduce(
                  (totalSum, dataset) => {
                    return (
                      totalSum +
                      dataset.data.reduce((sum, value) => sum + value, 0)
                    );
                  },
                  0
                );

                ctx.save();
                ctx.font = "bold 16px Arial";
                ctx.fillStyle = "black";
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillText(`Total: ${total}`, centerX, centerY);
                ctx.restore();
              },

              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (context) {
                    const label2 = `Total ${currentStyleLineDataChart.columnY}`; // Ejemplo de un segundo label con información adicional
                    const label1 = `${context.dataset.alias} : ${context.raw}`;

                    if (currentStyleLineDataChart.type === "sum") {
                      return [label2, label1];
                    } else {
                      return label1;
                    }
                  },
                },
                titleFont: {
                  size: currentStyleLineDataChart.chartStyle.tooltip
                    .fontSizeTitle,
                },
                bodyFont: {
                  size: currentStyleLineDataChart.chartStyle.tooltip
                    .fontSizeBody,
                },
                mode: "nearest",
                intersect: true,
                titleAlign: "center",
                displayColors: false,
              },
            },
            animation: {
              duration: 1500,
              easing: "easeInOutQuart",
            },
          }}
        />
      </CardDonut>
    </CardContainer>
  );
};

export default GroupDonutChart;
