import axios from "axios";
import { config } from "config.js";

const findTypeObjectsTypeObjects = async ({
  pointTypeObjectRelationId,
  pointTypeObjectId,
}) => {
  const token = localStorage.getItem("token");
  const urlParams = new URLSearchParams();
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  if (adminCompanyId) urlParams.append("adminCompanyId", adminCompanyId);
  if (pointTypeObjectRelationId)
    urlParams.append("pointTypeObjectRelationId", pointTypeObjectRelationId);
  if (pointTypeObjectId)
    urlParams.append("pointTypeObjectId", pointTypeObjectId);

  const result = await axios.get(
    `${
      config.URL_BACKEND_PG
    }api/v1/type-objects-type-objects?${urlParams.toString()}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );

  const data = await result.data;
  return data;
};

export default findTypeObjectsTypeObjects;
