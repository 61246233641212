import { createContext, useContext, useEffect } from "react";
import useSwr from "swr";
import { useSWRConfig } from "swr";
import { config } from "config";
import { updateBackendPreferences } from "services/updateBackendPreferences";
import { BLACK_COLOR } from "utils/const";

const FontContext = createContext();

export const useFont = () => useContext(FontContext);

export const FontData = ({ children }) => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");

  const { mutate } = useSWRConfig();

  const url = `${config.URL_BACKEND_MG}config-preferences?adminCompanyId=${adminCompanyId}`;

  const { data: configData, error: errorConfigData } = useSwr(url);

  useEffect(() => {
    if (adminCompanyId && userId) {
      const initializeFont = async () => {
        if (!errorConfigData && configData && configData.length > 0) {
          document.body.style.color =
            configData[0].font.fontColor || BLACK_COLOR;
          document.body.style.fontFamily =
            configData[0].font.fontType || "Montserrat";
        } else {
          document.body.style.color = BLACK_COLOR;
          document.body.style.fontFamily = "Montserrat";
        }
      };
      initializeFont();
    } else {
      document.body.style.color = BLACK_COLOR;
      document.body.style.fontFamily = "Montserrat";
    }
  }, [errorConfigData, configData, userId, adminCompanyId]);

  const handleFontFamily = async (fontFamily) => {
    try {
      await updateBackendPreferences({
        id: configData[0]._id,
        body: {
          ...configData[0],
          font: { ...configData[0].font, fontType: fontFamily },
        },
      });

      mutate(url);
    } catch (error) {
      console.error("Failed to update font family:", error);
    }
  };

  const handleFontColor = async (fontColor) => {
    try {
      await updateBackendPreferences({
        id: configData[0]._id,
        body: {
          ...configData[0],
          font: { ...configData[0].font, fontColor: fontColor },
        },
      });
      mutate(url);
    } catch (error) {
      console.error("Failed to update font color:", error);
    }
  };
  return (
    <FontContext.Provider
      value={{
        handleFontFamily,
        handleFontColor,
      }}
    >
      {children}
    </FontContext.Provider>
  );
};
