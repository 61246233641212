import { useEffect, useState } from "react";

/**
 * Generates a media object and width map based on the provided element.
 *
 * @param {Object} element - The element containing media data.
 * @return {Object} An object containing the mediaObject and widthMap.
 */
const useMediaElement = ({ element }) => {
  const [widthMap, setWidthMap] = useState();
  const [mediaObject, setMediaObject] = useState([]);
  
  useEffect(() => {
    const groupPhotos = () => {
      // Objeto para almacenar los arrays agrupados por tipo
      const mediaByType = {};
      let mediaElement;

      if (element?.media) mediaElement = element?.media;
      if (element?.captureMedia) mediaElement = element?.captureMedia;

      // Iterar sobre el array original y agrupar por tipo
      mediaElement?.forEach((item) => {
        const type = item.type;

        if (!mediaByType[type]) {
          mediaByType[type] = [];
        }

        mediaByType[type].push(item);
      });

      // Convertir el objeto de resultados en un array de arrays
      const groupedMediaArrays = Object.values(mediaByType);
      setMediaObject(groupedMediaArrays);

      // Calcular el ancho del mapa
      const nTypes = groupedMediaArrays.length;
      const widthValue = 12 - (nTypes % 3) * 4;
      setWidthMap(widthValue);
    };
    element && groupPhotos();
  }, [element]);

  return {
    mediaObject,
    widthMap,
  };
};

export default useMediaElement;
