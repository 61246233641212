import { Container } from "@mui/material";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import ListUser from "components/Tables/BasicTable/ListAdminCompany";
import FormRegister from "components/Dialogs/AdminCompanyRegister";

import { Title, ButtonStyle, CustomContainer } from "./adminCompaniesStyles";
import Layout from "components/Layout/AdminDecimetrix";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";

const AdminCompanies = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Layout>
      <CustomContainer className="background-1">
        <Container
          style={{
            paddingTop: 80,
          }}
          maxWidth="xl"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <Title className="title" style={{ color: `${BLACK_COLOR}` }}>
                  <h1>Administrators by Company </h1>
                </Title>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <ButtonStyle>
                  <button onClick={handleClickOpen}>Add Administrator</button>
                </ButtonStyle>
              </Grid>
            </Grid>
          </Box>
          <div>
            <FormRegister open={open} handleClose={handleClose} />
            <ListUser handleClose={handleClose} />
          </div>
        </Container>
      </CustomContainer>
    </Layout>
  );
};

export default AdminCompanies;
