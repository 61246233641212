import useSwr from "swr";
import { config } from "config.js";

export const useFetchObjectLibraries = ({ id }) => {
  /**
   * It is not necessary to send the adminCompanyId because it is implicit in the token and is handled in the backend
   */
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);

  const url = `${
    config.URL_BACKEND_PG
  }api/v1/point-libraries?${urlParams.toString()}`;

  const { data, error } = useSwr(url);

  return { data, error };
};

export const useFetchEventLibraries = ({ id }) => {
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);

  let url = `${
    config.URL_BACKEND_PG
  }api/v1/point-library-events?${urlParams.toString()}`;

  const { data, error } = useSwr(url);

  return { data, error };
};

export const useFetchComponentsLibraries = ({ id }) => {
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);

  let url = `${
    config.URL_BACKEND_PG
  }api/v1/point-library-components?${urlParams.toString()}`;

  const { data, error } = useSwr(url);

  return { data, error };
};
