// React
import { useState } from "react";
import { useEffect } from "react";
// Components
import DeleteLineButton from "./DeleteLine";
import { InfoTitle } from "../ImageDetailsElements";
import DynamicFields from "components/Forms/SaveLines/DynamicFields";
import OpenDialog from "components/Dialogs/OpenDialog";
import EditIcon from "@mui/icons-material/Edit";

// Helpers
import { isUrl, nameUrl } from "helpers/isUrl";
import isoDateToString from "helpers/isoDateToString";
import { formatFloat } from "helpers/formatFloat";
// Dependencies
import { Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import useSwr from "swr";
import useGet from "hooks/useFetch";
import { findLinesObjects } from "services/lines/findLinesObjects";
import { Wrapperbtn } from "./LineDetailsStyles";
import CustomButton from "components/Buttons/CustomButton";
import findUser from "services/findUser";
import { fixDecimals } from "helpers/fixDecimals";
import { useContext } from "react";
import { ThemeContext } from "App";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  WHITE_COLOR,
} from "utils/const";

import { config } from "config.js";
import { roles } from "utils/roles";
import ConvertButton from "./convertLines";

const rowOmit = [
  "mongoId",
  "geojsonLineId",
  "_id",
  "userId",
  "adminCompanyId",
  "user",
  "id",
  "Latitude",
  "Longitude",
];

const getStyle = (theme) => ({
  color: theme === "light" ? document.body.style.color : WHITE_COLOR,
  background: theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
});

export default function TableLine({
  line,
  deleteButton,
  deletedLine,
  handleUpdateData,
  setShowLineDetails = () => {},
}) {
  delete line?.object;
  const { theme } = useContext(ThemeContext);
  const colorStyle = getStyle(theme);

  const [openDialog, setOpenDialog] = useState(false);
  const [markersObjects, setMarkersObjects] = useState([]);
  const [infoOperation, setInfoOperation] = useState({});
  const [lineLibraries] = useGet(`api/v1/line-libraries`);
  const shouldShowTooltip = true;
  const [userUpdatedBy, setUserUpdatedBy] = useState({
    firstName: "",
    firstLastName: "",
  });

  const token = localStorage.getItem("token");
  const decodeToken = jwt_decode(token);
  const urlUser = `${config.URL_BACKEND_PG}api/v1/users/${decodeToken.sub}`;
  const { data: user } = useSwr(urlUser);

  useEffect(() => {
    const getUserInfo = async () => {
      const { data } = await findUser(
        line.userUpdatedBy !== 0 ? line.userUpdatedBy : line.userId
      );
      const { adminDecimetrix, adminCompany, operator } = data;
      const { firstName, firstLastName } =
        adminDecimetrix || adminCompany || operator;
      setUserUpdatedBy({ firstName, firstLastName });
    };
    line !== undefined && getUserInfo();
  }, [line]);

  useEffect(() => {
    findLinesObjects({ lineId: line?.id }).then((linesObjects) => {
      const markers = linesObjects.map((item) => {
        const { id } = item?.object;
        const { longitude, latitude } = item?.object?.location;
        const { name } = item?.object?.typeElement;

        return {
          id: id,
          name: name,
          latitude: latitude,
          longitude: longitude,
        };
      });

      setMarkersObjects(markers);
    });
  }, [line]);

  useEffect(() => {
    const omit = [
      "mongoId",
      "userId",
      "locationComponentId",
      "pointTypeComponentId",
      "adminCompanyId",
      "locationComponent",
      "point_library_component_id",
      "pointTypeComponent",
      "attributes",
      "date",
      "componentFiles",
      "user",
      "fieldsCaptureMedia",
      "media",
      "timeZoneOffset",
      "timeZone",
      "createdAt",
      "updatedAt",
      "id",
      "comments",
      "type",
      "lineTypeId",
      "userUpdatedBy",
    ];

    Object.keys(line).map((key) => {
      if (omit.includes(key)) return null;
      let value = line[key];
      if (!value) {
        return null;
      }
      setInfoOperation((prev) => ({ ...prev, [key]: value }));
      return null;
    });

    // Find user name
    const { adminCompany, operator } = line?.user;
    const { firstName, firstLastName } = adminCompany || operator;

    setInfoOperation((prev) => ({
      ...prev,
      ID: line?.id,
      "Created By": firstName + " " + firstLastName,
      "Updated By": userUpdatedBy.firstName + " " + userUpdatedBy.firstLastName,
      Source: line?.feature[0]?.properties?.source,
      "Created At": isoDateToString(line?.createdAt),
      "Updated At": isoDateToString(line?.updatedAt),
      "Collection Date": isoDateToString(
        line?.feature[0]?.properties?.collectionDate
      ),
      "Initial Point Name":
        markersObjects.length === 2 && markersObjects[0]?.name,
      "Initial Point Id": markersObjects.length === 2 && markersObjects[0]?.id,
      "Initial Longitude": line?.feature[0].geometry.coordinates[0][0],
      "Initial Latitude": line?.feature[0].geometry.coordinates[0][1],
      "Initial Point Status": line?.statusInitialPoint ? "ON" : "OFF",
      "Final Point Name":
        markersObjects.length === 2 && markersObjects[1]?.name,
      "Final Point Id": markersObjects.length === 2 && markersObjects[1]?.id,
      "Final Longitude":
        line?.feature[0].geometry.coordinates[
          line.feature[0].geometry.coordinates.length - 1
        ][0],
      "Final Latitude":
        line?.feature[0].geometry.coordinates[
          line.feature[0].geometry.coordinates.length - 1
        ][1],
      "Final Point Status": line?.statusFinalPoint ? "ON" : "OFF",
      "Distance (m)": `${fixDecimals(
        line?.feature[0]?.properties?.distance * 1000,
        4
      )}`,
    }));
  }, [line, userUpdatedBy, markersObjects]);

  const reduceAttributes = () => {
    lineLibraries &&
      line?.attributes?.forEach((attribute) => {
        let matchAlias = "";
        lineLibraries?.forEach((lib) => {
          if (lib.fields) {
            matchAlias = lib.fields.find(
              (field) => field.name === attribute.name
            );
            if (matchAlias) {
              attribute.alias = matchAlias.alias;
            }
          }
        });
      });

    const filteredAttributes = line?.attributes?.map((item) => {
      let newItem = { ...item };
      try {
        if (typeof JSON.parse(item.value) === "object")
          newItem.value = JSON.parse(item.value);
      } catch (error) {
        return item;
      }
      return newItem;
    });
    return filteredAttributes;
  };

  return (
    <InfoTitle>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        content={{
          title: "Update Line",
          description: (
            <DynamicFields
              dataLine={line}
              update={true}
              setOpenDialog={setOpenDialog}
              handleUpdateData={handleUpdateData}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <section className="main-tables-container">
          <div className="main-currency-table">
            <div className="currency-table--container">
              <table>
                {Object.keys(infoOperation).map((key) => {
                  const row = (key, value) => {
                    return (
                      <tr style={colorStyle}>
                        <td>{key}</td>
                        <td className="table__right">{value}</td>
                      </tr>
                    );
                  };

                  if (typeof infoOperation[key] !== "object") {
                    if (!rowOmit.includes(key)) {
                      return row(key, infoOperation[key]);
                    }
                  }
                  return null;
                })}
                {line &&
                  reduceAttributes()?.map((item) => {
                    return (
                      !(item?.value === "" || item?.value === " ") && (
                        <tr style={colorStyle}>
                          <td>{item?.alias || item?.name}</td>
                          <td className="table__right">
                            {isUrl(item?.value) ? (
                              <a
                                href={item?.value}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {nameUrl(item?.value)}
                              </a>
                            ) : typeof item?.value !== "object" ? (
                              item?.value
                            ) : (
                              formatFloat(item?.value?.value, 4)
                            )}
                          </td>
                        </tr>
                      )
                    );
                  })}
              </table>
              <br />
              {(user?.operator?.role?.role === roles.teamOffice ||
                user?.operator?.role?.role === roles.teamField ||
                decodeToken?.role === roles.companyAdmin) && (
                <Wrapperbtn>
                  {line && (
                    <ConvertButton
                      line={line}
                      setShowLineDetails={setShowLineDetails}
                    />
                  )}

                  <CustomButton
                    text={<EditIcon />}
                    onClick={() => setOpenDialog(true)}
                    margin={"10px 0"}
                    primaryColor={"#ff600a"}
                    secondaryColor={"#f0712c"}
                    showTooltip={shouldShowTooltip}
                    tooltipTitle="Edit"
                  />

                  {deleteButton && (
                    <DeleteLineButton
                      lineId={line.id}
                      deletedLine={deletedLine}
                    />
                  )}
                </Wrapperbtn>
              )}
            </div>
          </div>
        </section>
      </Grid>
    </InfoTitle>
  );
}
