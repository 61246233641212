import styled from "styled-components";
import { BORDER_GREY, WHITE_BACKGROUND } from "utils/const";

export const ProfileStyle = styled.div`
  .profile-title {
    font-size: 3rem;
  }

  .profile-details-item {
    font-size: 1.5rem;
  }

  .card-profile {
    background-color: ${WHITE_BACKGROUND};
    border: solid 1px ${BORDER_GREY};
    border-radius: 12px;
    height: 100%;
    box-shadow: none;
  }
`;
