import { CircularProgress } from "@mui/material";
import { ContentButton } from "./CustomButtonStyle";
import PropTypes from "prop-types";
import { WHITE_SECOND_COLOR } from "utils/const";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";

/**
 * Renders a custom button component.
 *
 * @param {Object} props - An object containing the button's properties.
 * @param {string} props.primaryColor - The primary color of the button.
 * @param {string} props.secondaryColor - The secondary color of the button.
 * @param {string} props.text - The text to display on the button.
 * @param {function} props.onClick - The function to be executed when the button is clicked.
 * @param {boolean} props.isLoad - A flag indicating whether the button should display a loading indicator.
 * @param {string} props.type - The type of the button.
 * @param {number} [props.margin=10] - The margin around the button.
 * @param {boolean} [props.disabled=false] - A flag indicating whether the button is disabled.
 * @return {JSX.Element} The custom button component.
 */
const CustomButton = ({
  primaryColor,
  secondaryColor,
  text,
  onClick,
  isLoad,
  type,
  margin = 10,
  disabled = false,
  tooltipTitle,
  showTooltip,
}) => {
  return (
    <>
      {showTooltip ? (
        <SimpleToolTip title={tooltipTitle} placement="bottom">
          <ContentButton
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            margin={margin}
          >
            <button onClick={onClick} type={type} disabled={disabled}>
              {isLoad && <CircularProgress style={circularProgressStyle} />}
              {text}
            </button>
          </ContentButton>
        </SimpleToolTip>
      ) : (
        <ContentButton
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          margin={margin}
        >
          <button onClick={onClick} type={type} disabled={disabled}>
            {isLoad && <CircularProgress style={circularProgressStyle} />}
            {text}
          </button>
        </ContentButton>
      )}
    </>
  );
};

CustomButton.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

CustomButton.defaultProps = {
  isLoading: false,
  type: "button",
};

const circularProgressStyle = {
  width: "20px",
  height: "20px",
  color: WHITE_SECOND_COLOR,
  marginRight: "5px",
};

export default CustomButton;
