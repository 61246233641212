import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Sensoricon = () => {
  return (
    <>
      <svg
        id="Layer_18"
        data-name="Layer 18"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`
          .cls-1sens {
            fill: none;
            stroke: var(--background-secondary_color);
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-width: 16px;
          }
    
          .cls-2sens {
        fill: var(--background-primary_color);
            
            stroke-width: 0px;
          }

      `}
          </style>
        </defs>
        <path
          class="cls-1sens"
          d="m111.15,195c-3.94,41.7,22.14,67.73,22.14,67.73"
        />
        <path
          class="cls-1sens"
          d="m84.13,178.65c-8.48,67.2,37.37,114.29,37.37,114.29"
        />
        <path
          class="cls-1sens"
          d="m55.52,164.58c-14.37,95.48,51.56,157.67,51.56,157.67"
        />
        <g id="Layer_26" data-name="Layer 26">
          <g>
            <rect
              class="cls-2sens"
              x="217.55"
              y="112.58"
              width="149.77"
              height="126.25"
              rx="18.66"
              ry="18.66"
              transform="translate(-40.13 99.58) rotate(-18.11)"
            />
            <path
              class="cls-2sens"
              d="m210.97,187.38l9.08,27.77c3.1,9.49,1.38,19.29-4.22,24.02l-27.9,23.59c-8.36,7.06-21.3,0-25.93-14.16l-17.62-53.9c-4.63-14.16,1.64-27.51,12.55-26.75l36.45,2.54c7.32.51,14.49,7.4,17.6,16.89Z"
            />
            <g>
              <path
                class="cls-2sens"
                d="m347.47,224.12l-8.95-8.95c-5.05-5.05-12.98-5.75-18.84-1.68l-13.2,9.17c-5.69,3.96-7.48,10.83-5.59,16.68l46.57-15.23Z"
              />
              <path
                class="cls-2sens"
                d="m369.77,246.43l-17.17-17.17-48.34,15.71c.98,1.01,2.11,1.92,3.43,2.66l44.44,24.92c9.83,5.51,21.95-1.59,21.95-12.86v-2.85c0-3.91-1.55-7.66-4.32-10.42Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default Sensoricon;
