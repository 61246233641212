import React from "react";
import { PRIMARY_COLOR } from "utils/const";

const FactoryImg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="200"
        height="200"
        viewBox="0 0 888 460.52563"
      >
        <title>factory</title>
        <path
          d="M369.70122,672.44322l-21.87443-34.71387,21.88457,38.49557.01173,3.95547q2.34084-.02736,4.63442-.17946l-1.78417-50.81218.02346-.39275-.0402-.075-.16988-4.80142,22.87164-38.04637-22.89523,34.4507-.06851,1.017-1.34778-38.39059,19.66232-39.36387-19.816,32.61808-.92176-79.573-.00422-.26483-.00317.2606-.44881,62.73791-18.78843-25.10244,18.80506,30.50606.08719,34.362-17.4329-33.18692,17.44253,38.23878.04824,19.107L344.254,577.08157l25.34285,52.87363Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M557.41283,672.44322,535.5384,637.72935,557.423,676.22492l.01173,3.95547q2.34084-.02736,4.63442-.17946L560.285,629.18875l.02346-.39275-.0402-.075-.16988-4.80142L582.97,585.87323l-22.89524,34.4507-.0685,1.017-1.34778-38.39059,19.66232-39.36387-19.816,32.61808-.92176-79.573-.00423-.26483-.00317.2606-.4488,62.73791-18.78843-25.10244,18.80506,30.50606.08719,34.362-17.43291-33.18692,17.44254,38.23878.04823,19.107-25.32288-46.20808,25.34285,52.87363Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M181.81131,674.67584l-15.79541-25.0667,15.80273,27.79745.00847,2.85622q1.6903-.01975,3.34649-.12959l-1.28834-36.6912.01694-.2836-.029-.05415-.12267-3.46707L200.266,612.16411l-16.53253,24.87667-.04946.73435-.97323-27.72164,14.19806-28.42444-14.309,23.55334-.6656-57.45927-.00305-.19123-.00229.18817-.32408,45.30272-13.567-18.12634,13.579,22.02826.063,24.81262-12.58821-23.9641,12.59516,27.612.03484,13.79707L163.436,605.8157l18.29994,38.17977Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M1013.94505,672.44322l-21.87443-34.71387,21.88456,38.49557.01173,3.95547q2.34084-.02736,4.63443-.17946l-1.78418-50.81218.02346-.39275-.04019-.075-.16989-4.80142,22.87164-38.04637-22.89523,34.4507-.0685,1.017-1.34778-38.39059,19.66231-39.36387-19.816,32.61808-.92176-79.573-.00422-.26483-.00317.2606-.44881,62.73791-18.78843-25.10244,18.80506,30.50606.0872,34.362-17.43291-33.18692,17.44253,38.23878.04824,19.107-25.32289-46.20808,25.34285,52.87363Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <rect
          x="420.71394"
          y="615.03256"
          width="118"
          height="5"
          transform="translate(803.42788 1015.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="414.71394"
          y="610.03256"
          width="118"
          height="5"
          transform="translate(791.42788 1005.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="421.71394"
          y="605.03256"
          width="118"
          height="5"
          transform="translate(805.42788 995.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="433.71394"
          y="600.03256"
          width="118"
          height="5"
          transform="translate(829.42788 985.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="333.31299"
          y="577.93732"
          width="4.09697"
          height="29.08849"
          transform="translate(514.72295 965.22594) rotate(-180)"
          fill="#3f3d56"
        />
        <rect
          x="407.83085"
          y="466.88793"
          width="4.09697"
          height="163.62126"
          transform="translate(-100.38591 915.10754) rotate(-112.09151)"
          fill="#3f3d56"
        />
        <rect
          x="257.51902"
          y="636.524"
          width="35.64365"
          height="13.9297"
          transform="translate(394.6817 1067.24052) rotate(-180)"
          fill="#3f3d56"
        />
        <rect
          x="260.7966"
          y="648.81492"
          width="175.76006"
          height="15.56849"
          transform="translate(541.35326 1093.46114) rotate(-180)"
          fill="#3f3d56"
        />
        <polygon
          points="133.68 436.862 175.879 436.862 175.879 383.806 144.332 383.806 133.68 410.565 133.68 436.862"
          fill="#e2e2e2"
        />
        <rect
          x="307.29722"
          y="606.82096"
          width="18.02667"
          height="18.02667"
          transform="translate(476.62112 1011.93141) rotate(-180)"
          fill="#fff"
        />
        <polygon
          points="137.777 405.315 149.249 405.315 149.249 387.289 144.332 387.289 137.777 405.315"
          fill="#fff"
        />
        <rect
          x="320.40753"
          y="635.09006"
          width="4.09697"
          height="7.78425"
          transform="translate(488.91203 1058.22719) rotate(-180)"
          fill="#3f3d56"
        />
        <path
          d="M297.66934,618.49733h.20485a3.89212,3.89212,0,0,1,3.89212,3.89212v0a3.89212,3.89212,0,0,1-3.89212,3.89212h-.20485a0,0,0,0,1,0,0v-7.78425A0,0,0,0,1,297.66934,618.49733Z"
          transform="translate(443.43566 1025.04172) rotate(180)"
          fill="#3f3d56"
        />
        <path
          d="M450.07667,648.81492h.20485a3.89212,3.89212,0,0,1,3.89212,3.89212v0a3.89212,3.89212,0,0,1-3.89212,3.89212h-.20485a0,0,0,0,1,0,0v-7.78425A0,0,0,0,1,450.07667,648.81492Z"
          transform="matrix(-1, 0, 0, -1, 748.25029, 1085.6769)"
          fill="#3f3d56"
        />
        <path
          d="M273.29236,648.75613a20.2704,20.2704,0,0,0-15.75558,7.843h-8.00685l7.78425-21.68366,36.25819-7.151-1.02424,28.83466H289.048A20.27042,20.27042,0,0,0,273.29236,648.75613Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <circle cx="116.88267" cy="441.98319" r="17.82182" fill="#3f3d56" />
        <circle cx="116.88267" cy="441.98319" r="9.37991" fill="#e2e2e2" />
        <circle cx="277.89363" cy="444.03168" r="15.56849" fill="#3f3d56" />
        <circle cx="277.89363" cy="444.03168" r="8.19394" fill="#e2e2e2" />
        <rect
          x="330.64996"
          y="596.16884"
          width="8.60364"
          height="62.06911"
          transform="translate(513.90356 1034.6696) rotate(-180)"
          fill="#3f3d56"
        />
        <rect
          x="330.64996"
          y="646.97128"
          width="123.72853"
          height="3.68727"
          transform="translate(629.02844 1077.89265) rotate(-180)"
          fill="#3f3d56"
        />
        <rect
          x="299.30813"
          y="625.87188"
          width="4.09697"
          height="1.63879"
          transform="translate(446.71323 1033.64536) rotate(-180)"
          fill="#3f3d56"
        />
        <path
          d="M480.39425,620.00189a.40971.40971,0,0,1-.10422-.31848l5.65034-53.43206a.40987.40987,0,0,1,.40569-.36669h.0018a.40985.40985,0,0,1,.4071.36309L492.866,619.569a.40975.40975,0,0,1-.4033.4563l-11.76139.11083h-.0038A.40928.40928,0,0,1,480.39425,620.00189Zm5.97-49.96645-5.211,49.27688L492,619.2103Z"
          transform="translate(-156 -219.73719)"
          fill="#3f3d56"
        />
        <path
          d="M255.88024,635.70461h.20485a3.89212,3.89212,0,0,1,3.89212,3.89212v0a3.89212,3.89212,0,0,1-3.89212,3.89212h-.20485a0,0,0,0,1,0,0v-7.78425A0,0,0,0,1,255.88024,635.70461Z"
          transform="translate(86.94131 -269.62329) rotate(20.71738)"
          fill="#3f3d56"
        />
        <rect
          x="484.49122"
          y="516.07305"
          width="4.09697"
          height="59.81578"
          transform="translate(817.07942 872.2247) rotate(-180)"
          fill="#3f3d56"
        />
        <circle cx="179.36148" cy="359.01953" r="5.73576" fill="#3f3d56" />
        <rect
          x="343.71394"
          y="642.03256"
          width="118"
          height="5"
          transform="translate(649.42788 1069.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="350.71394"
          y="637.03256"
          width="118"
          height="5"
          transform="translate(663.42788 1059.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="345.71394"
          y="633.03256"
          width="118"
          height="5"
          transform="translate(653.42788 1051.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="352.71394"
          y="628.03256"
          width="118"
          height="5"
          transform="translate(667.42788 1041.32794) rotate(-180)"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M538.33725,341.97939a15.59567,15.59567,0,0,1,10.23657,3.81414,15.60631,15.60631,0,0,1,6.88715-.30262,15.65993,15.65993,0,0,1,28.55484,8.887H523.01887A15.66484,15.66484,0,0,1,538.33725,341.97939Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M446.57156,391.32748a14.74417,14.74417,0,0,1,9.67768,3.6059,14.75453,14.75453,0,0,1,6.51113-.2861,14.80494,14.80494,0,0,1,26.99582,8.40174H432.08953A14.80958,14.80958,0,0,1,446.57156,391.32748Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M354.82419,309.93539a15.34075,15.34075,0,0,1,10.06927,3.75181,15.35133,15.35133,0,0,1,6.77459-.29768,15.404,15.404,0,0,1,28.08814,8.7417h-60A15.40882,15.40882,0,0,1,354.82419,309.93539Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M750.99875,464.92273V589.82849H799.573V463.18787l-11.27588.40271V438.03321h-8.17871l-1.34766-56.60376c3.30029-2.15052,5.62305-7.25818,5.62305-13.222a21.66875,21.66875,0,0,0-.59375-5.084,10.4004,10.4004,0,0,0,1.12012-12.66773,10.40734,10.40734,0,1,0,13.4873-15.85144,10.40972,10.40972,0,1,0-14.01367-9.767c0,.15247.00488.30371.01123.45435a10.41549,10.41549,0,0,0-13.33789,8.38257,10.409,10.409,0,1,0-7.48535,19.1272c-.002.07556-.00586.15051-.00586.22656a50.59934,50.59934,0,0,0,3.67627,8.43457,21.02593,21.02593,0,0,0-1.07373,6.74487c0,5.96387,2.32226,11.07153,5.62255,13.222l-1.34765,56.60376h-8.17822V464.5199Zm30.332-119.26843.02686.00061c.15039,0,.29931-.00488.44775-.01123q.14063.46143.32178.90356A10.45284,10.45284,0,0,0,781.33078,345.6543Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <rect
          x="724.25381"
          y="508.14821"
          width="147.45827"
          height="170.58898"
          transform="translate(1439.96588 967.14821) rotate(-180)"
          fill="#3f3d56"
        />
        <rect
          x="778.10499"
          y="644.04112"
          width="39.75591"
          height="34.69606"
          transform="translate(1439.96588 1103.04112) rotate(-180)"
          fill="#e2e2e2"
        />
        <path
          d="M675.11058,283.692c-.00282.126-.00953.2509-.00953.37748a17.2926,17.2926,0,0,0,5.26,12.43748,17.32924,17.32924,0,0,0,.86719,1.62029,35.02934,35.02934,0,0,0-1.79014,11.24144c0,13.17395,6.796,23.85355,15.17953,23.85355s15.17952-10.6796,15.17952-23.85355a36.10523,36.10523,0,0,0-.98931-8.47345,17.33406,17.33406,0,0,0,1.86674-21.11261,17.34558,17.34558,0,1,0,22.47882-26.41915,17.34962,17.34962,0,1,0-23.35625-16.27825c0,.254.00847.506.01906.75729a17.3592,17.3592,0,0,0-22.23,13.97086,17.34835,17.34835,0,1,0-12.4756,31.87862Zm29.62669-11.91071c.25024,0,.49871-.00838.74648-.01888q.23348.76911.53578,1.506a17.38709,17.38709,0,0,0-1.32708-1.48825Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M685.94113,451.76722h17.3501l-.61963-26.02v-.01l-2.27051-95.41a11.09469,11.09469,0,0,1-11.56982,0l-2.27,95.41v.01Z"
          transform="translate(-156 -219.73719)"
          fill={PRIMARY_COLOR}
        />
        <polygon
          points="498.141 250.82 498.141 459 579.092 459 579.092 247.93 560.301 248.6 560.301 206.01 516.931 206.01 516.931 250.15 498.141 250.82"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M881.84129,283.692c-.00282.126-.00953.2509-.00953.37748a17.2926,17.2926,0,0,0,5.26,12.43748,17.32924,17.32924,0,0,0,.86719,1.62029,35.02934,35.02934,0,0,0-1.79014,11.24144c0,13.17395,6.796,23.85355,15.17952,23.85355s15.17953-10.6796,15.17953-23.85355a36.10576,36.10576,0,0,0-.98931-8.47345,17.33406,17.33406,0,0,0,1.86674-21.11261,17.34558,17.34558,0,1,0,22.47881-26.41915,17.34962,17.34962,0,1,0-23.35624-16.27825c0,.254.00847.506.01906.75729a17.3592,17.3592,0,0,0-22.23,13.97086,17.34835,17.34835,0,1,0-12.4756,31.87862ZM911.468,271.78128c.25024,0,.49871-.00838.74648-.01888q.23347.76911.53577,1.506a17.38528,17.38528,0,0,0-1.32707-1.48825Z"
          transform="translate(-156 -219.73719)"
          fill="#e2e2e2"
        />
        <path
          d="M892.67428,451.76711h17.348L907.131,330.33089a17.10079,17.10079,0,0,1-11.56535,0Z"
          transform="translate(-156 -219.73719)"
          fill={PRIMARY_COLOR}
        />
        <polygon
          points="704.871 250.82 704.871 459 785.831 459 785.831 247.93 779.181 248.17 767.031 248.6 767.031 206.01 723.661 206.01 723.661 250.15 704.871 250.82"
          fill={PRIMARY_COLOR}
        />
        <rect
          x="819.98294"
          y="528.73719"
          width="20"
          height="20"
          transform="translate(1503.96588 857.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="787.98294"
          y="528.73719"
          width="20"
          height="20"
          transform="translate(1439.96588 857.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="755.98294"
          y="528.73719"
          width="20"
          height="20"
          transform="translate(1375.96588 857.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="819.98294"
          y="560.73719"
          width="20"
          height="20"
          transform="translate(1503.96588 921.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="787.98294"
          y="560.73719"
          width="20"
          height="20"
          transform="translate(1439.96588 921.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="755.98294"
          y="560.73719"
          width="20"
          height="20"
          transform="translate(1375.96588 921.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="819.98294"
          y="592.73719"
          width="20"
          height="20"
          transform="translate(1503.96588 985.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="787.98294"
          y="592.73719"
          width="20"
          height="20"
          transform="translate(1439.96588 985.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="755.98294"
          y="592.73719"
          width="20"
          height="20"
          transform="translate(1375.96588 985.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="684.61759"
          y="520.73719"
          width="20"
          height="20"
          transform="translate(1233.23517 841.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="684.61759"
          y="552.73719"
          width="20"
          height="20"
          transform="translate(1233.23517 905.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="684.61759"
          y="584.73719"
          width="20"
          height="20"
          transform="translate(1233.23517 969.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="684.61759"
          y="616.73719"
          width="20"
          height="20"
          transform="translate(1233.23517 1033.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="891.18137"
          y="520.73719"
          width="20"
          height="20"
          transform="translate(1646.36273 841.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="891.18137"
          y="552.73719"
          width="20"
          height="20"
          transform="translate(1646.36273 905.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="891.18137"
          y="584.73719"
          width="20"
          height="20"
          transform="translate(1646.36273 969.73719) rotate(-180)"
          fill="#fff"
        />
        <rect
          x="891.18137"
          y="616.73719"
          width="20"
          height="20"
          transform="translate(1646.36273 1033.73719) rotate(-180)"
          fill="#fff"
        />
        <rect y="458.29537" width="888" height="2" fill="#3f3d56" />
      </svg>
    </>
  );
};

export default FactoryImg;
