import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { INFORMATION_COLOR } from "utils/const";
import CloseIcon from "@mui/icons-material/Close";
import { ClickAwayListener } from "@mui/material";

const ClickToolTip = ({ title, children, placement }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: INFORMATION_COLOR,
      color: "#fff",
      boxShadow: `0 1px 5px  ${INFORMATION_COLOR}`,
      fontSize: 16,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0.5rem 1rem",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: INFORMATION_COLOR,
    },
  }));

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <StyledTooltip
            title={
              <>
                <div
                  style={{
                    margin: "0.5rem 2rem 0.5rem 0.5rem",
                  }}
                >
                  {title}
                </div>

                <CloseIcon
                  onClick={handleTooltipClose}
                  sx={{
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  style={{ margin: "2px", fontSize: "20px" }}
                />
              </>
            }
            arrow
            placement={placement}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClick={handleTooltipOpen}
          >
            {children}
          </StyledTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ClickToolTip;
