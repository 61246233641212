import axios from "axios";
import { config } from "config.js";

const updateLeak = async (body, id) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(
      `${config.URL_BACKEND_PG}api/v1/leak/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default updateLeak;
