import AllEventsCharts from "./EventsCharts/index";
import Loading from "components/Lodings/Loading";

const EventsCharts = ({
  openDialog,
  events,
  loading,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  orderId,
}) => {
  return (
    <div className="dashboard">
      {events?.length > 0 && (
        <AllEventsCharts
          openDialog={openDialog}
          events={events}
          loading={loading}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          currentPositionId={currentPositionId}
          orderId={orderId}
        />
      )}
      {loading && <Loading height={"auto"} text={"Loading Dashboard..."} />}
    </div>
  );
};

export default EventsCharts;
