import { useState } from "react";
//Style
import { Container } from "./indexStyle";
// Components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import ConfigObjects from "./ConfigObjects";
import ConfigComponents from "./ConfigComponents";
import ConfigEvents from "./ConfigEvents";
// Constants
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";
import CarbonSummaryKpi from "icons/CarbonSummaryKpi";
import PhChartBar from "icons/PhChartBar";
import MaterialSymbolsTextSnippetOutline from "icons/MaterialSymbolsTextSnippetOutline";
import BxsDoughnutChart from "icons/BxsDoughnutChart";
import CarbonChartLineSmooth from "icons/CarbonChartLineSmooth";
import CarbonChartLineStacked from "icons/CarbonChartLineStacked";
import CarbonChartBarStacked from "icons/CarbonChartBarStacked";
import CarbonChartTimeSeriesBar from "icons/CarbonChartTimeSeriesBar";
import ImgChartIcon from "icons/ImgChart";
import BxsGroupDoughnutChart from "icons/BxsGroupDoughnutChart";

const typeCharts = [
  {
    name: "KPI Chart",
    value: "kpiChart",
    icon: <CarbonSummaryKpi color={"#494949"} />,
  },
  {
    name: "Bar Chart",
    value: "barChart",
    icon: <PhChartBar color={"#494949"} />,
  },
  {
    name: "Group Bar Chart",
    value: "groupBarChart",
    icon: <CarbonChartBarStacked color={"#494949"} />,
  },
  {
    name: "Time Series Bar Chart",
    value: "timeSeriesBarChart",
    icon: <CarbonChartTimeSeriesBar color={"#494949"} />,
  },
  {
    name: "Line Chart",
    value: "lineChart",
    icon: <CarbonChartLineSmooth color={"#494949"} />,
  },
  {
    name: "Time Series Line Chart",
    value: "timeChart",
    icon: <CarbonChartLineStacked color={"#494949"} />,
  },
  {
    name: "Doughnut Chart",
    value: "doughnutChart",
    icon: <BxsDoughnutChart color={"#494949"} />,
  },
  {
    name: "Group Doughnut Chart",
    value: "groupDoughnutChart",
    icon: <BxsGroupDoughnutChart color={"#494949"} />,
  },
  {
    name: "Text Chart",
    value: "textChart",
    icon: <MaterialSymbolsTextSnippetOutline color={"#494949"} />,
  },
  {
    name: "Image Chart",
    value: "imgChart",
    icon: <ImgChartIcon color={"#494949"} />,
  },
];

const ConfigDashboard = ({ setOpenDialog }) => {
  const [chartType, setChartType] = useState("kpiChart");

  const [value, setValue] = useState("objects");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <h1 className="title">Dashboard Configuration</h1>
      <h2 className="subtitle">Select chart type</h2>
      <section className="container-type-charts">
        {typeCharts.map((item) => (
          <div
            className="card-type-chart"
            key={item.value}
            onClick={() => setChartType(item.value)}
            style={{
              backgroundColor:
                item.value === chartType ? ICON_GREEN_COLOR : WHITE_COLOR,
            }}
          >
            <div className="icon">{item?.icon}</div>
            <div className="name">{item.name}</div>
          </div>
        ))}
      </section>
      <section
        style={{
          marginTop: "20px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="objects" label="Objects" />
              <Tab value="components" label="Components" />
              <Tab value="events" label="Events" />
            </Tabs>
          </Box>
          <TabPanel sx={{ padding: "0" }} value="objects">
            <ConfigObjects
              chartType={chartType}
              setOpenDialog={setOpenDialog}
            />
          </TabPanel>
          <TabPanel sx={{ padding: "0" }} value="components">
            <ConfigComponents
              chartType={chartType}
              setOpenDialog={setOpenDialog}
            />
          </TabPanel>
          <TabPanel sx={{ padding: "0" }} value="events">
            <ConfigEvents chartType={chartType} setOpenDialog={setOpenDialog} />
          </TabPanel>
        </TabContext>
      </section>
    </Container>
  );
};

export default ConfigDashboard;
