import axios from "axios";
import timeZoneOffset from "services/timeZoneOffset";
import { config } from "config.js";

export const createPointEventNoLocation = (
  info,
  objectId,
  pointComponentId,
  pointTypeEventId
) => {
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  return {
    attributes: info,
    isGeographic: false,
    pointTypeEventId,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneOffset: timeZoneOffset(),
    date: new Date(),
    objectId,
    pointComponentId,
    userId: parseInt(userId),
    adminCompanyId,
  };
};

export const savePointEvenRelation = async (existEventId, newEventId) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/point-events/relation`,
      {
        existEventId,
        newEventId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export const setUpdateAtEvent = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.patch(
      `${config.URL_BACKEND_PG}api/v1/point-events/set-updateAt/${id}`,
      {},
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

const savePointEventApiPg = async (pointEvent) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/point-events`,
      pointEvent,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default savePointEventApiPg;
