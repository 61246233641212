export const TYPE_FIELDS = {
  typeToLibrary: "type to library",
  typeToPoints: "type to points",
};

export const MAPBOX_STYLE = {
  streets: {
    id: "streets-v12",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3tZaAJFEXlkxY3W3cMkbEfU95DFoJTipPww&usqp=CAU",
    alt: "image for streets style basemap",
    name: "Streets",
    value: "mapbox://styles/mapbox/streets-v12",
  },
  outdoors: {
    id: "outdoors-v12",
    img: "https://greendragon-production.s3.us-east-2.amazonaws.com/default/outdoors.jpg",
    alt: "image for streets style basemap",
    name: "Outdoors",
    value: "mapbox://styles/mapbox/outdoors-v12",
  },
  satelliteStreets: {
    id: "satellite-streets-v12",
    img: "https://greendragon-production.s3.us-east-2.amazonaws.com/default/satellite-streets.jpg",
    alt: "image for satellite streets basemap",
    name: "Satellite Streets",
    value: "mapbox://styles/mapbox/satellite-streets-v12",
  },
  satellite: {
    id: "satellite-v9",
    img: "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_SATELLITE.png",
    alt: "image for satellite basemap",
    name: "Satellite",
    value: "mapbox://styles/mapbox/satellite-v9",
  },
  light: {
    id: "light-v11",
    img: "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_LIGHT_V2.png",
    alt: "image for ligth basemap",
    name: "Light",
    value: "mapbox://styles/mapbox/light-v11",
  },
  dark: {
    id: "dark-v11",
    img: "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_DARK_V2.png",
    alt: "image for streets style basemap",
    name: "Dark",
    value: "mapbox://styles/mapbox/dark-v11",
  },
};

export const FROM_TABLE = {
  digitalTwin: "digitalTwin",
  config: "config",
  users: "users",
  videoManager: "videoManager",
  uploadVideos: "uploadVideos",
  details: "details",
  dashBoard: "dashBoard",
};

export const CREATED_FROM = {
  desktop: "Desktop",
  mobile: "Mobile",
};

export const TYPE_POINTS = {
  object: "Object",
  component: "Component",
  event: "Event",
};

export const TYPE_OBJECTS = {
  line: "Line",
  polygon: "Polygon",
};

export const CSV_OBJECTS_TEMPLATE = {
  typeElementId: "",
  longitude: "",
  latitude: "",
};

export const CSV_EVENTS_TEMPLATE = {
  typeEventId: "",
  longitude: "",
  latitude: "",
};

export const CSV_COMPONENTS_TEMPLATE = {
  typeComponentId: "",
  longitude: "",
  latitude: "",
};

export const CSV_LINES_TEMPLATE = {
  typeLineId: "",
  longitude: "",
  latitude: "",
  initialPointName: "",
  initialPointType: "",
  statusInitialPoint: "",
  finalPointName: "",
  finalPointType: "",
  statusFinalPoint: "",
};

export const HOSTNAME = {
  greenDragon: "greendragonpro.decimetrix.com",
  redDragon: "reddragonpro.decimetrix.com",
};
