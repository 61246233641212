import { PRIMARY_COLOR, VISIBILITY_OFF_ICON } from "utils/const";

export function setFiltersEventsToFalse(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      obj[prop] = false;
    }
  }
  return obj;
}
export function setFiltersEventsToTrue(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      obj[prop] = true;
    }
  }
  return obj;
}

export function setFiltersTrue(component) {
  const components = component.map((elm) => {
    return { ...elm, color: PRIMARY_COLOR, state: true };
  });
  return components;
}

export function setFiltersFalse(component) {
  const components = component.map((elm) => {
    return { ...elm, color: VISIBILITY_OFF_ICON, state: false };
  });
  return components;
}
