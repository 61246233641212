import styled from "styled-components";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const LoadingContainer = styled.div`
  display: grid;
  place-content: center;
  height: 100vh;
  text-align: center;

  p {
    margin: 20px 0;
  }
`;

// width: 100%;
// margin-top: 100px;
// text-align: center;
export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 7rem);
  height: auto;
  text-align: center;

  .title {
    margin: 20px 0;
    color: ${BLACK_COLOR};
  }
  .title h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.6rem;
  }

  .table-container {
    /* margin: 5px 0; */
  }

  .kpis-section {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .kpis-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px 20px;
    box-shadow: 0 0 5px #c9c9c9;
    border-radius: 7px;
    margin: 15px 0;
  }

  .kpis-container h3 {
    font-size: 2rem;
    font-weight: 500;
    margin: 0 5px;
  }

  .kpis-container h3 span {
    font-weight: 700;
  }

  .button-upload-videos {
    background-color: ${PRIMARY_COLOR};
    padding: 10px 10px;
    color: ${WHITE_COLOR};
    border-radius: 10px;
    font-size: 1.8rem;
    border: none;
    width: 200px;
    cursor: pointer;
  }
  .button-upload-videos:hover {
    background-color: ${SECOND_COLOR};
  }
`;
