import { styled } from "@mui/material/styles";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../utils/const";

export const HeadModal = styled("modal")`
  background: var(--background-primary_color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .neurona {
    width: 34px;
    margin-right: 1px;
    margin-bottom: 6px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: ${WHITE_COLOR};
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 1920px) {
    //styles for desktop
    height: auto;
    position: relative;
    top: 0;
    left: 0;
  }

  @media only screen and (max-width: 600px) {
    //styles for mobile
    height: auto;
    position: relative;
    top: 0;
    left: 0;
  }
`;
