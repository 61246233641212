// Styles
import { ConfigAdminContainer } from "../configAdminStyles";

import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import { useState } from "react";
import ObjectElements from "./pointTypeObjects/ObjectElements";

export default function TypeElementsGhg() {
  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  return (
    <div>
      <ConfigAdminContainer className="background-2 border-top-1">
        <OpenDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          execute={executeDialog}
          content={contentDialog}
        />
        <ObjectElements
          setOpenDialog={setOpenDialog}
          setContentDialog={setContentDialog}
        />
      </ConfigAdminContainer>
    </div>
  );
}
