import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Custom hooks
import useGet from "hooks/useFetch";
import FetchTypeElements from "hooks/fetchTypeElements";
// Components
import DynamicFields from "./DynamicFields";
// Styles
import { FeatureDetails } from "./indexStyle";
import { WraperTitleCardMarker } from "components/ElementDetails/ImageDetailsElements";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { setFeaturesDetailsDT } from "redux/actions/admin";
import { useContext } from "react";
import { ThemeContext } from "App";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";
import { LineTag } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const GenericFields = () => {
  const { theme } = useContext(ThemeContext);
  // States
  const [libraryId, setLibraryId] = useState("");
  const [elementId, setElementId] = useState("");
  const [genericData, setGenericData] = useState();
  const [lngText, setLngText] = useState(0);
  const [latText, setLatText] = useState(0);
  // Local storage
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  // Selectors
  const featureDetails = useSelector(
    (state) => state.adminReducer.setFeaturesDetailsDT
  );
  const objectsUnderDrawnPoint = useSelector(
    (state) => state.digitalTwinReducer.objectsUnderDrawnPoint
  );
  const dispatch = useDispatch();
  // Custom hooks
  const [pointLibraries] = useGet("api/v1/point-libraries");
  const { data: typeElements, errorTypeElemets } = FetchTypeElements(libraryId);
  const [typeElementImageUrl, setTypeElementImageUrl] = useState("");
  // Efects
  useEffect(() => {
    pointLibraries &&
      !pointLibraries.error &&
      setLibraryId(pointLibraries[0].id);
  }, [pointLibraries]);

  useEffect(() => {
    typeElements && !typeElements.error && setElementId(typeElements[0]?.id);
    typeElements && setTypeElementImageUrl(typeElements[0]?.urlIconColor);
  }, [typeElements]);
  // Methods
  const handlerLibraryId = (e) => {
    setLibraryId(e.target.value);
  };
  const setElementHandler = useCallback(
    (e) => {
      const urlImage = typeElements.filter(
        (item) => item.id === parseInt(e.target.value)
      )[0]?.urlIconColor;
      setElementId(e.target.value);
      setTypeElementImageUrl(urlImage);
    },
    [typeElements]
  );

  const handleCopy = (lnglat) => {
    navigator.clipboard.writeText(lnglat);
  };

  const handleUpdateCoordinates = useCallback(
    (value, num) => {
      const updatedFeatureDetails = {
        ...featureDetails,
      };
      if (num === 1) {
        updatedFeatureDetails.geometry.coordinates[1] = Number(value);
        dispatch(setFeaturesDetailsDT(updatedFeatureDetails));
        setLatText(Number(value));
      }
      if (num === 0) {
        updatedFeatureDetails.geometry.coordinates[0] = Number(value);
        dispatch(setFeaturesDetailsDT(updatedFeatureDetails));
        setLngText(Number(value));
      }
    },
    [featureDetails, dispatch]
  );

  useEffect(() => {
    const setPoint = () => {
      setLngText(Number(featureDetails?.geometry?.coordinates[0]));
      setLatText(Number(featureDetails?.geometry?.coordinates[1]));
      const point = {
        userId: Number(userId),
        location: {
          latitude: Number(featureDetails?.geometry?.coordinates[1]),
          longitude: Number(featureDetails?.geometry?.coordinates[0]),
        },
        adminCompanyId: adminCompanyId,
        typeElementId: elementId,
      };
      const bodyObject = {
        point: point,
        regionIds: objectsUnderDrawnPoint.map((object) => object?.layer?.id),
      };
      setGenericData(bodyObject);
    };

    featureDetails?.geometry?.coordinates && setPoint();
  }, [
    adminCompanyId,
    objectsUnderDrawnPoint,
    featureDetails,
    elementId,
    userId,
  ]);

  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          <img
            width="30"
            src={typeElementImageUrl}
            alt="object-agile-icon"
          ></img>
        </div>
        <h2
          className="title"
          style={{ color: theme === "light" ? BLACK_COLOR : WHITE_COLOR }}
        >
          Object Location
        </h2>
      </WraperTitleCardMarker>

      {featureDetails?.geometry?.coordinates && (
        <div className="main-currency-table">
          <div className="currency-table--container">
            <table>
              <tr>
                <td className="table__top-left">Longitude</td>
                <td className="table__top-right table__right">
                  <input
                    type="text"
                    className="input-location"
                    value={lngText}
                    onChange={(evt) => {
                      handleUpdateCoordinates(evt.target.value, 0);
                    }}
                  />
                </td>
                <td>
                  <button
                    id="btn-copy"
                    className="button-copy"
                    value={"longitude"}
                    onClick={() => handleCopy(lngText)}
                  >
                    <ContentCopyIcon
                      style={{
                        color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                      }}
                    />
                  </button>
                </td>
              </tr>
              {true && (
                <div className="container-tooltip">
                  <span className="tooltip">Information</span>
                </div>
              )}

              <tr>
                <td className="table__bottom-left">Latitude</td>
                <td className="table__bottom-right table__right">
                  <input
                    type="text"
                    className="input-location"
                    value={latText}
                    onChange={(evt) => {
                      handleUpdateCoordinates(evt.target.value, 1);
                    }}
                  />
                </td>
                <td>
                  <button
                    id="btn-copy"
                    className="button-copy"
                    value={"latitude"}
                    onClick={() => handleCopy(latText)}
                  >
                    <ContentCopyIcon
                      style={{
                        color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                      }}
                    />
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}

      <div>
        <label htmlFor="select-library" className="location-details">
          Library
        </label>
        <select
          id="select-library"
          defaultValue={libraryId}
          onChange={handlerLibraryId}
          className="custom-select"
        >
          {pointLibraries &&
            !pointLibraries.error &&
            pointLibraries.map((library) => (
              <option
                key={library.id}
                value={library.id}
                disabled={library.available ? false : true}
              >
                {library.name}
              </option>
            ))}
        </select>
        <label htmlFor="select-element" className="location-details">
          Object type
        </label>

        <div>
          <select
            id="select-element"
            defaultValue={elementId}
            onChange={setElementHandler}
            className="custom-select"
          >
            {!errorTypeElemets &&
              !typeElements?.error &&
              typeElements?.map((element) => {
                return (
                  <option key={element.id} value={element.id}>
                    {element.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <KeyboardArrowDownIcon style={{ margin: "2rem 0" }} />
      </div>

      {pointLibraries?.length > 0 && typeElements?.length > 0 && (
        <DynamicFields genericData={genericData} />
      )}
    </FeatureDetails>
  );
};

export default GenericFields;
