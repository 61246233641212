import { getNumericValue } from "helpers/getNumericValue";

const filterObjectsByColumns = ({ objects, chart }) => {
  return objects.filter((object) => {
    return chart.columnsValuesFilter.every((column) => {
      const attributeName = column.name;
      const attributeValue = object.attributes.find(
        (attr) => attr.name === attributeName
      )?.value;
      return column.values.includes(attributeValue);
    });
  });
};

export const countByValues = ({ charts, objects }) => {
  const chartsFiltered = charts.filter((chart) => chart.type === "count");

  return chartsFiltered.map((chart) => {
    const name = chart.columnX;
    const nameCounts = {};

    const filteredObjectsByLibraryId = objects.filter(
      (item) => item.typeElement.pointLibraryId === chart.libraryId
    );

    const filteredObjects = filterObjectsByColumns({
      objects: filteredObjectsByLibraryId,
      chart,
    });

    for (const obj of filteredObjects) {
      const attributes = obj.attributes.filter((attr) => attr.name === name);

      for (const attr of attributes) {
        const attributeValue = attr.value;
        const isDate = new Date(attributeValue).toString() !== "Invalid Date";

        if (isDate && typeof attributeValue !== "number") {
          const dateKey = attributeValue.substring(0, 10);
          nameCounts[dateKey] = (nameCounts[dateKey] || 0) + 1;
        } else {
          nameCounts[attributeValue] = (nameCounts[attributeValue] || 0) + 1;
        }
      }
    }

    let formattedData = Object.entries(nameCounts).map(([label, data]) => ({
      label,
      data,
    }));

    // Ordenar por fecha si los labels son fechas
    const isDateLabels = formattedData.every(
      (item) => !isNaN(Date.parse(item.label))
    );
    if (isDateLabels) {
      formattedData.sort((a, b) => new Date(a.label) - new Date(b.label));
    }

    const dataSeriesConstant = chart.dataSeriesConstant.map((elm) => {
      return {
        ...elm,
        data: new Array(formattedData.length).fill(elm.constantValue),
      };
    });

    const chartLines = {
      ...chart,
      labels: formattedData.map((item) => item.label),
      datasets: [
        {
          label: "",
          data: formattedData.map((item) => item.data),
          backgroundColor: chart.colors[0],
        },
      ],
      dataSeriesConstant,
    };

    return chartLines;
  });
};

export function sumValues({ objects, charts }) {
  const chartsFiltered = charts.filter((chart) => chart.type === "sum");

  return chartsFiltered.map((chart) => {
    const { columnX, columnsY } = chart;
    let formattedData = [];

    const valueMap = {};

    const filteredObjectsByLibraryId = objects.filter(
      (item) => item.typeElement.pointLibraryId === chart.libraryId
    );

    const filteredObjects = filterObjectsByColumns({
      objects: filteredObjectsByLibraryId,
      chart,
    });

    for (const obj of filteredObjects) {
      let xValue = obj.attributes.find((attr) => attr.name === columnX)?.value;
      // Check if the value is a date
      const isDate = new Date(xValue).toString() !== "Invalid Date";
      // Extract the date part only (without time)
      if (isDate && typeof xValue !== "number") {
        xValue = xValue.substring(0, 10);
      }

      // Iterate over all columns in the chart
      for (const colY of columnsY) {
        let yValue = 0;
        // Find the attribute in the object
        const yData = obj.attributes.find((attr) => attr.name === colY);
        if (yData) {
          yValue = getNumericValue(yData.value);
        }
        // Combine the two values to make a unique key
        const key = `${xValue}|${colY}`;

        // If the key doesn't exist, set the value to 0
        if (!valueMap[key]) {
          valueMap[key] = 0;
        }

        // Add the yValue to the current value for that key
        valueMap[key] += yValue;
      }
    }

    for (const [key, sum] of Object.entries(valueMap)) {
      const [xValue, colY] = key.split("|"); // Split the combined key
      formattedData.push({ label: xValue, column: colY, sum });
    }

    // if formattedData[0].label is date, sort by date and create new array with all dates
    const isDate =
      new Date(formattedData[0]?.label).toString() !== "Invalid Date";
    if (isDate) {
      // Sort the array by date
      formattedData.sort((a, b) => new Date(a.label) - new Date(b.label));
    }

    const dataSeriesConstant = chart.dataSeriesConstant.map((elm) => {
      return {
        ...elm,
        data: new Array(formattedData.length).fill(elm.constantValue),
      };
    });

    return {
      ...chart,
      labels: formattedData.map((item) => item.label),
      datasets: [
        {
          label: "",
          data: formattedData.map((item) => item.sum),
          backgroundColor: chart.colors[0],
        },
      ],
      dataSeriesConstant,
    };
  });
}
