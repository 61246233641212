import styled from "styled-components";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";

export const ButtonSaveCoordinates = styled("button")`
  position: absolute;
  top: 211px;
  right: 8px;
  width: 33px;
  height: 33px;
  z-index: 8;
  background: ${WHITE_COLOR};
  background-clip: padding-box;
  display: inline-block;
  cursor: pointer;
  padding: 7px 5px 6px 6px;
  border-radius: 5px;
  border: solid 2px;
  border-color: #00000021;

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: ${BLACK_COLOR};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }

  &:hover {
    background-color: #fcf7f7;
  }
`;

export const ContentDialogFunction = styled("div")`
  h1{
    color: ${BLACK_COLOR};
  }

  h2 {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  div {
    margin-top: 2rem;
  }
`;
