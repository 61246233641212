import React, { useState } from "react";
import Layout from "components/Layout/AdminDecimetrix";
import { InformationSection } from "../GlobalConstants/styles";
import {
  ConfigContent,
  ContentPref,
  DescriptionBack,
  ShowAllButton,
  ShowAllButton2,
} from "./preferencesstyles";
import TranslateIcon from "@mui/icons-material/Translate";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AssistantIcon from "@mui/icons-material/Assistant";
import CardP from "./CardP";
import DetilsCard from "./DetilsCard";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import TooltipConfig from "./TooltipConfig";
import ThemeConfig from "./ThemeConfig";
import FontConfig from "./FontConfig";

const Preferences = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const navigate = useNavigate();

  const handleCardClick = (title) => {
    if (title === "Theme") {
      setSelectedCard(<ThemeConfig title={title} />);
    } else if (title === "Tooltip") {
      setSelectedCard(<TooltipConfig title={title} />);
    } else if (title === "Language") {
      setSelectedCard(<TooltipConfig title={title} />);
    } else if (title === "Font") {
      setSelectedCard(<FontConfig title={title} />);
    } else {
      setSelectedCard(<DetilsCard title={title} />);
    }
  };

  const handleShowAll = () => {
    setSelectedCard(null);
  };
  const navigation = (path) => {
    navigate(path);
  };
  return (
    <>
      <Layout>
        <InformationSection className="background-1" style={{ border: "none" }}>
          <DescriptionBack>
            {selectedCard && (
              <>
                <ShowAllButton2 onClick={handleShowAll}>
                  <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
                </ShowAllButton2>
                <ShowAllButton
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  style={{ bottom: "20px", position: "fixed" }}
                >
                  <KeyboardArrowUpIcon style={{ fontSize: "2rem" }} />
                </ShowAllButton>
              </>
            )}
            {!selectedCard && (
              <ShowAllButton2 onClick={() => navigation("/setUp-admin")}>
                <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
              </ShowAllButton2>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <h1
                style={{
                  display: "flex",
                  alignSelf: "center",
                  margin: "2rem",
                }}
              >
                Preferences
              </h1>
              <p
                style={{
                  display: "flex",
                  alignSelf: "center",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                Customize your application settings to enhance your user
                experience. <br /> Select an option below to modify tooltips,
                themes, fonts, and language preferences.
              </p>
            </div>
          </DescriptionBack>
        </InformationSection>
        <ConfigContent className="background-2 border-top-1">
          <ContentPref
            isSingleColumn={selectedCard}
            className="background-row-1 border-color-1"
          >
            <CardP
              title="Tooltip"
              description="Enable tooltips to provide helpful hints and explanations, <br /> enhancing user understanding and interaction <br /> within the application."
              icon={AssistantIcon}
              onClick={() => handleCardClick("Tooltip")}
              isVisible={!selectedCard}
            />
            {/* <div style={{ opacity: "10%" }}> */}
            <CardP
              title="Theme"
              description="Customize your application's interface with a variety <br /> of colors  to enhance visual appeal and user experience."
              icon={ColorLensIcon}
              onClick={() => handleCardClick("Theme")}
              isVisible={!selectedCard}
            />
            {/* </div>{" "} */}
            <CardP
              title="Font"
              description="Choose from a variety of font styles to customize the appearance <br /> and readability of your text throughout the application."
              icon={SortByAlphaIcon}
              onClick={() => handleCardClick("Font")}
              isVisible={!selectedCard}
            />
            <div style={{ opacity: "10%" }}>
              {" "}
              <CardP
                title="Language"
                description="Select your preferred language to tailor the application's  <br /> interface and content according to your linguistic preferences."
                icon={TranslateIcon}
                // onClick={() => handleCardClick("Language")}
                isVisible={!selectedCard}
              />
            </div>
            {/* </div> */} {selectedCard}
          </ContentPref>
        </ConfigContent>
      </Layout>
    </>
  );
};

export default Preferences;
