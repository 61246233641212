import React from "react";
import { ContentCardP } from "./preferencesstyles";

function CardP({ title, description, icon: Icon, onClick, isVisible }) {
  return (
    <>
      <ContentCardP
        className="button-hover-1 border-color-1"
        style={{ display: isVisible ? "block" : "none" }}
        onClick={onClick}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {Icon && <Icon className="icon color1" />}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2>{title}</h2>
            {/* <p>{description}</p> */}
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
        </div>
      </ContentCardP>
    </>
  );
}

export default CardP;
