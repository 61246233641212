import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { HeadModal } from "./DialogsStyles";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "utils/const";
import { AiFillCloseCircle } from "react-icons/ai";
import { useContext } from "react";
import { ThemeContext } from "App";
import { useSelector } from "react-redux";
import { setObjectsFilterTable } from "redux/actions/admin";
import {
  setModalRelationObject,
  setStateObjectFilterTable,
} from "redux/actions/digitalTwin.jsx";
import { useDispatch } from "react-redux";

const OpenDialog = ({ openDialog, setOpenDialog, execute, content }) => {
  const open = openDialog;
  const theme2 = useTheme();
  const fullScreen = useMediaQuery(theme2.breakpoints.down("md"));
  const { theme } = useContext(ThemeContext);

  //
  const dispatch = useDispatch();
  const relationObjectModal = useSelector(
    (state) => state.digitalTwinReducer.modalRelationObject
  );
  //All objects in the map
  const allObjects = useSelector((state) => state.adminReducer.dataObjects);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "600px",
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              color:
                theme === "light" ? document.body.style.color : WHITE_COLOR,
            },
          },
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <HeadModal>
          <div className="title">
            <img
              src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
              alt=""
              className="neurona"
            />
            <span className="titleHeadModal">{content.title}</span>
          </div>
          <Button onClick={handleClose}>
            <AiFillCloseCircle style={{ color: WHITE_COLOR, fontSize: 21 }} />
          </Button>
        </HeadModal>
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
            fontFamily: document.body.style.fontFamily,
            color: theme === "light" ? document.body.style.color : WHITE_COLOR,
          }}
          id="responsive-dialog-title"
        >
          {content.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: "1.4rem",
              fontFamily: document.body.style.fontFamily,
            }}
          >
            {content.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {content.disagree && (
            <Button
              sx={{ fontSize: "1.4rem" }}
              onClick={() => {
                //handleClose();
                if (relationObjectModal.show) {
                  setOpenDialog(false);
                  dispatch(setModalRelationObject({ show: false }));
                  dispatch(setObjectsFilterTable(allObjects));

                  dispatch(setStateObjectFilterTable(false));
                }
                setOpenDialog(false);
              }}
              style={{
                background: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: WHITE_COLOR,
              }}
            >
              {content.disagree[0].toUpperCase() + content.disagree.slice(1)}
            </Button>
          )}
          {content.agree && (
            <Button
              sx={{ fontSize: "1.4rem" }}
              onClick={execute}
              autoFocus
              style={{
                background: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: WHITE_COLOR,
              }}
            >
              {content.agree[0].toUpperCase() + content.agree.slice(1)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OpenDialog;
