import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR, WHITE_SECOND_COLOR } from "../utils/const";

export const Button = styled(LinkS)`
  width: ${({ width }) => (width ? width : "auto")};
  border-radius: 50px;
  background: ${({ primary }) => (primary ? SECOND_COLOR : "#222729")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#222729" : WHITE_COLOR)};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? WHITE_COLOR : SECOND_COLOR)};
  }
`;

export const ButtonR = styled(LinkR)`
  width: ${({ width }) => (width ? width : "auto")};
  border-radius: 50px;
  background: ${({ primary }) => (primary ? SECOND_COLOR : "#222729")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#222729" : WHITE_COLOR)};
  outline: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? WHITE_COLOR : SECOND_COLOR)};
  }
`;

export const ButtonRoute = styled.button`
  width: ${({ width }) => (width ? width : "auto")};
  border-radius: 50px;
  background: ${({ primary }) => (primary ? WHITE_COLOR : PRIMARY_COLOR)};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? PRIMARY_COLOR : WHITE_COLOR)};
  outline: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  display: flex; 
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? WHITE_COLOR : SECOND_COLOR)};
  }
`;

export const NamePlateMarker = styled.button`
  background: none;
  border: none;

  img {
    width: 40px;
  }
`;

export const Btn = styled.button`
  position: absolute;
  top: 0;
  right: 5px;
  width: 40%;
  height: 70%;
  background: ${PRIMARY_COLOR};
  color: ${WHITE_SECOND_COLOR};
  border: 2px solid ${PRIMARY_COLOR};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 25px;

  &:hover {
    background: ${WHITE_SECOND_COLOR};
    color: ${PRIMARY_COLOR};
  }
`;

