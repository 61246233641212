const Grid = ({ children, minWidth = 280, gap = "5px 0" }) => {
  return (
    <div
      style={{
        display: "grid",
        width: "100%",
        gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, 1fr))`,
        gridGap: gap,
        margin: "5px auto",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {children}
    </div>
  );
};

export default Grid;
