import Layout from "components/Layout/AdminDecimetrix";
import { Container } from "./style";
import DecimetrixNetZeroImage from "images/decimetrix_net_zero.png";

export const DeleteAccount = () => {
  return (
    <Layout>
      <Container>
        <img src={DecimetrixNetZeroImage} alt="Decimetrix net zero logo" />
        <h1>Canceling Your Decimetrix® Green Dragon Account ⚠️</h1>
        <p>
          Are you sure you want to cancel your Decimetrix® Green Dragon account?
          Deleting your account means you will no longer be able to access your
          organization's sustainability data, artificial intelligence (AI) and
          augmented reality (AR) features.
        </p>
        <p>
          To cancel your account, please contact our support team at{" "}
          <b>support.greendragon@decimetrix.com</b> . We will send you a
          confirmation email to finalize the account deletion process. We're
          sorry to see you go, but we support you on your sustainability
          journey. If you decide to continue your environmental monitoring in
          the future, <b>Decimetrix® Green Dragon</b> welcomes you back with
          open arms!
        </p>
      </Container>
    </Layout>
  );
};
