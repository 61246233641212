export const isDate = (string) => {
  if (typeof string === "string") {
    const dateParts = string.slice(0, 10).split("-");
    if (dateParts.length !== 3) {
      return false;
    }
    const [year, month, day] = dateParts.map(Number);
    return (
      !isNaN(year) &&
      !isNaN(month) &&
      !isNaN(day) &&
      month >= 0 &&
      month <= 12 &&
      day >= 0 &&
      day <= 31
    );
  }
};
