import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Scope2icon = () => {
  return (
    <svg
      id="Layer_49"
      data-name="Layer 49"
      width="200"
      height="200"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 433.72 433.72"
    >
      <defs>
        <style>
          {`.cls-1scope2 {
        fill: var(--background-secondary_color);
      }
    
          .cls-1scope2, .cls-2scope2, .cls-3scope2 {
            stroke-width: 0px;
          }
    
          .cls-2scope2 {
            fill: #fff;
          }
    
          .cls-3scope2 {
        fill: var(--background-primary_color);
            
          }`}
        </style>
      </defs>
      <circle class="cls-3scope2" cx="216.86" cy="216.86" r="165.05" />
      <path
        id="dos"
        class="cls-2scope2"
        d="M219.48,273.54c16.27-16.85,37.72-34.46,49.17-55.11,9.42-16.99,11.75-44.22-9.12-53.4-21.75-9.57-43.32,3.99-60.65,15.91-5.02-6.18-9.98-12.27-15.13-18.61,30.27-34.18,110.71-37.7,120.42,17.17,4.33,24.47-7.15,46.99-23.08,64.6l-63.61,64.18,94.45-.09v25.23h-129.84l-.14-25.14,37.52-34.74Z"
      />
      <path
        class="cls-1scope2"
        d="M181.12,135.22l-60.41-86.55c-1.86-2.66-5.79-2.66-7.65,0l-60.41,86.55c-2.16,3.09.05,7.33,3.82,7.33h21.93c2.58,0,4.66,2.09,4.66,4.66v55.69c0,2.58,2.09,4.66,4.66,4.66h58.29c2.58,0,4.66-2.09,4.66-4.66v-55.69c0-2.58,2.09-4.66,4.66-4.66h21.93c3.77,0,5.98-4.24,3.82-7.33Z"
      />
    </svg>
  );
};

export default Scope2icon;
