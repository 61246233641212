import { useNavigate } from "react-router-dom";
import { LinksContainer } from "./LinksPrivacyStyle";

const LinksTerms = () => {
  const navigate = useNavigate();

  const navigation = (url) => navigate(url);

  return (
    <LinksContainer>
      <ul>
        <li
          className="item-links background-row-1 border-color-1 color1"
          onClick={() => navigation("/privacy-policy-cloud-web")}
        >
          Decimetrix® Green Dragon cloud enterprise-level web platform Privacy
          Policy
        </li>
        <li
          className="item-links background-row-1 border-color-1 color1"
          onClick={() => navigation("/privacy-policy-mobile")}
        >
          Decimetrix® Reality Capture Mobile Solution Privacy Policy
        </li>
        <li
          className="item-links background-row-1 border-color-1 color1"
          onClick={() => navigation("/privacy-policy-socrates")}
        >
          Decimetrix® AI Socrates Privacy Policy
        </li>
      </ul>
    </LinksContainer>
  );
};

export default LinksTerms;
