import useGet from "hooks/useFetch.js";
import AccordionRender from "./AccordionRender";

/**
 * Generates the function comment for the given function body.
 *
 * @param {Object} typeObjectsTypeObjects - The typeObjectsTypeObjects parameter.
 * @param {Function} handleChangeCheck - The handleChangeCheck parameter.
 * @returns {JSX.Element} The JSX element representing the generated output.
 */
const CheckListsObjectsElements = ({
  typeObjectsTypeObjects,
  handleChangeCheck,
}) => {
  const [libraries] = useGet("api/v1/point-libraries");
  const [allTypes] = useGet("api/v1/type-elements");

  const res =
    allTypes &&
    !allTypes.error &&
    allTypes.map((elm) => {
      return elm.pointLibraryId;
    });

  const idTypes =
    res &&
    !res.error &&
    res.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
      }}
    >
      {libraries &&
        !libraries.error &&
        libraries
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((library, key) =>
            idTypes && !idTypes.error && idTypes.includes(library.id) ? (
              <AccordionRender
                handleChangeCheck={handleChangeCheck}
                typeObjectsTypeObjects={typeObjectsTypeObjects}
                key={key}
                library={library}
              />
            ) : (
              <></>
            )
          )}
    </div>
  );
};

export default CheckListsObjectsElements;
