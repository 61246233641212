import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Calculatoricon = () => {
  return (
    <>
      <svg
        id="Layer_15"
        data-name="Layer 15"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`
            .cls-1calc {
              stroke: #6666d2;
              stroke-width: 8px;
            }
      
            .cls-1calc, .cls-2calc, .cls-3calc {
              fill: none;
              stroke-linecap: round;
              stroke-miterlimit: 10;
            }
      
            .cls-2calc {
              stroke: #fff;
            }
      
            .cls-2calc, .cls-3calc {
              stroke-width: 10px;
            }
      
            .cls-3calc {
              stroke: var(--background-secondary_color);
            }
      
            .cls-4calc {
        fill: var(--background-primary_color);
              
            }
      
            .cls-4calc, .cls-5calc, .cls-6calc {
              stroke-width: 0px;
            }
      
            .cls-5calc {
              fill: var(--background-secondary_color);
            }
      
            .cls-6calc {
              fill: #fff;
            }
      `}
          </style>
        </defs>
        <line
          class="cls-1calc"
          x1="292.99"
          y1="101.52"
          x2="292.99"
          y2="136.78"
        />
        <g id="Layer_27" data-name="Layer 27">
          <path
            class="cls-5calc"
            d="m120.89,94.43s3.41-13.22,20.06-13.22,18.03,20.32,18.03,20.32c0,0-2.11,17.68,10.84,14.94s119.92-17.1,119.92-17.1v183.13l-168.84,25.81V94.43Z"
          />
          <path
            class="cls-3calc"
            d="m165.92,77.59l127.07-18.58s27.38-4.83,22.15,32.72c-.33,2.35-2.14,4.22-4.49,4.59-3.88.62-9.94,1.5-17.44,2.56-36.24,5.11-106.2,14.3-127.66,17.11-3.24.42-6.11-2.11-6.08-5.39.11-10.41-1.87-30.15-18.53-29.39-22.58,1.03-21.03,20.32-21.03,20.32l2.05,194.05c0,.82-.17,1.62-.51,2.36-1.62,3.43-7.15,12.95-18.57,12.95-1.33,0-2.57-.08-3.72-.23-8.55-1.08-14.87-8.5-14.87-17.12,0-4.74,0-10.66,0-14.69,0-2.66,1.94-4.91,4.57-5.32l28.98-4.46"
          />
          <line
            class="cls-3calc"
            x1="293.21"
            y1="98.87"
            x2="293.21"
            y2="140.69"
          />
          <line
            class="cls-2calc"
            x1="193.05"
            y1="161.59"
            x2="151.91"
            y2="167.4"
          />
          <line
            class="cls-2calc"
            x1="193.05"
            y1="179.66"
            x2="151.91"
            y2="185.47"
          />
          <line
            class="cls-2calc"
            x1="193.05"
            y1="196.55"
            x2="151.91"
            y2="202.36"
          />
          <line
            class="cls-2calc"
            x1="193.05"
            y1="232.92"
            x2="151.91"
            y2="238.73"
          />
          <line
            class="cls-2calc"
            x1="193.05"
            y1="256.45"
            x2="151.91"
            y2="262.27"
          />
          <line
            class="cls-3calc"
            x1="193.05"
            y1="297.25"
            x2="104.42"
            y2="310.82"
          />
        </g>
        <g>
          <rect
            class="cls-4calc"
            x="175.97"
            y="128.47"
            width="182.16"
            height="233.97"
            rx="34.05"
            ry="34.05"
            transform="translate(42.15 -39.12) rotate(9.09)"
          />
          <path
            class="cls-6calc"
            d="m339.6,196.69l-124.06-19.86c-12.07-1.59-4.62-21.68-3.95-30.13.88-5.5,5.42-9.34,10.15-8.58l124.06,19.86c12.07,1.59,4.62,21.68,3.95,30.13-.88,5.5-5.42,9.34-10.15,8.58Z"
          />
          <rect
            class="cls-6calc"
            x="202.47"
            y="189.17"
            width="38.27"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(-19.46 394.49) rotate(-80.91)"
          />
          <rect
            class="cls-6calc"
            x="194.45"
            y="239.23"
            width="38.27"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(-75.64 428.73) rotate(-80.91)"
          />
          <rect
            class="cls-6calc"
            x="186.44"
            y="289.29"
            width="38.27"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(-131.82 462.97) rotate(-80.91)"
          />
          <rect
            class="cls-6calc"
            x="253.54"
            y="197.34"
            width="38.27"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(15.48 451.81) rotate(-80.91)"
          />
          <rect
            class="cls-6calc"
            x="245.53"
            y="247.41"
            width="38.27"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(-40.7 486.05) rotate(-80.91)"
          />
          <rect
            class="cls-6calc"
            x="237.52"
            y="297.47"
            width="38.27"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(-96.88 520.29) rotate(-80.91)"
          />
          <rect
            class="cls-6calc"
            x="304.97"
            y="205.57"
            width="38.27"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(50.65 509.52) rotate(-80.91)"
          />
          <rect
            class="cls-6calc"
            x="267.6"
            y="280.67"
            width="88.97"
            height="38.97"
            rx="11.91"
            ry="11.91"
            transform="translate(-33.62 560.88) rotate(-80.91)"
          />
        </g>
      </svg>
    </>
  );
};

export default Calculatoricon;
