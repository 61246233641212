import Container from "@mui/material/Container";
import { ItemList, Pentagono, StyledP } from "./UserRoutesElements";
import FetchTypeRegions from "hooks/fetchTypeRegions";
import { useStyles, CustomizedAccordion } from "./MapDrawerStyle";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor2 } from "Styles";
const ListRegions = () => {
  const { data: typeElements, error: errorTypeElements } = FetchTypeRegions();
  const classes = useStyles();
  const [elements, setElements] = useState();
  const [names, setNames] = useState();
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    typeElements &&
      !typeElements.error &&
      setElements(
        typeElements.map((typeElement) => typeElement.regionLibraryId)
      );
    typeElements &&
      !typeElements.error &&
      setNames(
        typeElements.map((typeElement) => typeElement.regionLibrary.name)
      );
  }, [typeElements]);

  const pointLibrariesIdsUnique = [...new Set(elements)];
  const namesLibraryUnique = [...new Set(names)];

  return (
    <Container>
      {!errorTypeElements &&
        typeElements &&
        elements &&
        names !== undefined && (
          <div>
            <>
              {pointLibrariesIdsUnique.map((libraryId, index) => (
                <CustomizedAccordion
                  classes={{
                    content: classes.content,
                    expanded: classes.expanded,
                  }}
                  defaultExpanded={false}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: document.body.style.fontFamily,
                        color:
                          theme === "light"
                            ? document.body.style.color
                            : WHITE_COLOR,
                      }}
                    >
                      Librería {namesLibraryUnique[index]}
                    </Typography>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color:
                              theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                          }}
                        />
                      }
                    ></AccordionSummary>
                  </div>

                  {typeElements
                    .filter(
                      (typeElement) => typeElement.regionLibraryId === libraryId
                    )
                    .map((typeElementId) => (
                      <ItemList
                        style={{ color: StyleColor2(theme) }}
                        width={50}
                        color={typeElementId.border}
                        background={typeElementId.background}
                      >
                        <Pentagono background={typeElementId.border} width={30}>
                          <Pentagono small={true} />
                          <Pentagono
                            small={true}
                            into={true}
                            background={typeElementId.background}
                            opacity={1}
                          />
                        </Pentagono>
                        <StyledP>{typeElementId.type}</StyledP>
                      </ItemList>
                    ))}
                </CustomizedAccordion>
              ))}
            </>
          </div>
        )}
    </Container>
  );
};

export default ListRegions;
