import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// Custom hooks
import CustomButton from "components/Buttons/CustomButton";
import GetLinesArrays from "./hooks/getLibraryArrays.jsx";
// styles
import {
  FeatureDetails,
  LineContainerForm,
  WraperTitleCardMarker,
  Form,
} from "./LineRelationsStyles.jsx";
import BlockIcon from "@mui/icons-material/Block";
import { useSelector } from "react-redux";

import DynamicFields from "./DynamicFields";

const FreeLine = ({ setOpendDialog }) => {
  // Local storage
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  // states
  const [libraryId, setLibraryId] = useState("");
  const [lineTypeId, setLineTypeId] = useState(0);
  const [lineConfig, setLineConfig] = useState({});
  const [linePreview, setLinePreview] = useState({
    color: "#000",
    width: 1,
    separator: 1,
  });
  const [libraryLineImageUrl, setLibraryLineImageUrl] = useState("");
  const [genericData, setGenericData] = useState();
  // Custom hooks
  const {
    linesLibrariesArray: lineLibraries,
    errorLineLibraries,
    linesTypeArray: lineTypes,
    errorLineType,
  } = GetLinesArrays({ adminCompanyId, libraryId });

  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );
  //set library id and get the first image to display
  useEffect(() => {
    lineLibraries !== undefined &&
      !errorLineLibraries &&
      setLibraryId(parseInt(lineLibraries[0]?.id));
    lineLibraries !== undefined &&
      !errorLineLibraries &&
      setLibraryLineImageUrl(lineLibraries[0]?.icon);
  }, [lineLibraries, errorLineLibraries]);

  // set line type id and properties of the line preview
  useEffect(() => {
    lineTypes && !errorLineType && setLineTypeId(lineTypes[0]?.id);
    lineTypes &&
      !errorLineType &&
      setLinePreview({
        color: lineTypes[0]?.color,
        width: lineTypes[0]?.dasharrayWidth,
        separator: lineTypes[0]?.dasharrayPixels,
      });
  }, [lineTypes, errorLineType]);

  // listen to library id changes to chose library image
  useEffect(() => {
    lineLibraries &&
      !errorLineLibraries &&
      setLibraryLineImageUrl(
        lineLibraries?.find((it) => it.id === libraryId)?.icon
      );
  }, [libraryId, lineLibraries, errorLineLibraries]);

  // Methods
  //select line library
  const handleLibraryId = (e) => {
    const id = parseInt(e.target.value);
    setLibraryId(id);
    setLineTypeId(lineTypes?.[0]?.id);
    const lib = lineLibraries?.find((it) => it.id === id);
    if (lib) {
      setLinePreview({
        color: lib?.color,
        width: lib?.dasharrayWidth,
        separator: lib?.dasharrayPixels,
      });
    }
  };
  //select line type by line library choosed
  const handlerLineType = useCallback(
    (e) => {
      const lineTypePreview = lineTypes.find(
        (item) => item.id === parseInt(e.target.value)
      );
      setLineTypeId(e.target.value);
      setLinePreview({
        color: lineTypePreview?.color,
        width: lineTypePreview?.dasharrayWidth,
        separator: lineTypePreview?.dasharrayPixels,
      });
    },
    [lineTypes]
  );

  //set the generic data for the form in the dynamicFields form
  useEffect(() => {
    const setLineLibraryAndType = () => {
      const line = {
        libraryId: parseInt(libraryId),
        lineTypeId: parseInt(lineTypeId),
      };
      setLineConfig({ ...line });
    };
    lineTypeId && libraryId && setLineLibraryAndType();
  }, [lineTypeId, userId, libraryId]);

  useEffect(() => {
    const setLine = () => {
      const line = {
        userId: userId,
        name: "freeline",
        description: "no description",
        type: "geojson",
        feature: [
          {
            id: geometryLine.id,
            type: geometryLine.type,
            properties: {
              ...geometryLine.properties,
              userId,
              lineTypeId,
              adminCompanyId,
            },
            geometry: { ...geometryLine.geometry },
          },
        ],
        adminCompanyId: adminCompanyId,
        libraryId,
        lineTypeId,
      };
      setGenericData({
        ...line,
      });
    };
    geometryLine?.geometry?.coordinates && setLine();
  }, [adminCompanyId, geometryLine, lineTypeId, userId, libraryId]);

  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {libraryLineImageUrl ? (
            <img
              width="50"
              src={libraryLineImageUrl}
              alt="object-agile-icon"
            ></img>
          ) : (
            <BlockIcon />
          )}
          <div>
            <Line
              color={linePreview.color}
              width={linePreview.width}
              separator={linePreview.separator}
            />
          </div>
        </div>
      </WraperTitleCardMarker>
      <div>
        <label htmlFor="select-library" className="location-details">
          Line Library
        </label>
        <select
          id="select-library"
          defaultValue={libraryId}
          onChange={handleLibraryId}
          className="custom-select"
        >
          {lineLibraries &&
            !errorLineLibraries &&
            lineLibraries.map((library) => (
              <option key={library.id} value={library.id}>
                {library.name}
              </option>
            ))}
        </select>
        <label htmlFor="select-element" className="location-details">
          Line Type
        </label>
        <div>
          <select
            id="select-element"
            defaultValue={lineTypeId}
            onChange={handlerLineType}
            className="custom-select"
          >
            {lineTypes &&
              !errorLineType &&
              lineTypes?.map((element) => {
                return (
                  <option key={element.id} value={element.id}>
                    {element.type}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      {lineLibraries !== undefined && lineLibraries?.length && (
        <DynamicFields genericData={genericData} />
      )}
    </FeatureDetails>
  );
};

// To show current line aparence
const Line = (props) => {
  const { color, width, separator } = props;

  return (
    <LineContainerForm separator={separator} width={width} color={color}>
      <span className="text-line-preview">Line preview:</span>
      <div className="container">
        <span className="circle"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="circle"></span>
      </div>
    </LineContainerForm>
  );
};

export default FreeLine;
