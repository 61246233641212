import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import NavBar from "components/NavBar";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { MessageError, MessageSuccess } from "components/Forms/FormStyles";
import { config } from "config.js";
import registerLogin from "./registerLogin";
import {
  LoginContainer,
  LoginTitle,
  LoginForm,
  ArrowForward,
  ArrowRight,
  LoginButton,
} from "./RecoveryElements";
import InputComponent from "components/Forms/Input";
import { createLoginRegister } from "services/users/loginHistory";

const PasswordConfirmation = () => {
  const [message, setMessage] = useState(null);
  const [password2, setPassword2] = useState({ field: "", valid: null });
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({ field: "", valid: null });
  const [formValidated, setFormValidated] = useState(null);
  const [hover, setHover] = useState(false);
  const { token } = useParams();
  const userId = localStorage.getItem("userId");
  const [createLogin, setCreateLogin] = useState(null);

  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };

  const expressions = {
    lastName: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // letters y spaces, can carry accents.
    name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // letters y spaces, can carry accents.
    password: /^.{8,12}$/, // 8 to 12 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const navigate = useNavigate();

  const saveUser = async (body) => {
    try {
      const res = await fetch(
        `${config.URL_BACKEND_PG}api/v1/auth/resetPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!res.ok) {
        const result = await res.json();
        throw new Error(result.message);
      }
      const data = await res.json();
      return { res, data };
    } catch (error) {
      throw error;
    }
  };

  const handleSaveLogin = useCallback(async () => {
    const body = { userId: userId };
    try {
      await registerLogin(body);
    } catch (error) {
      console.error("Error saving login", error);
    }
  }, [userId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null); // Limpiar el mensaje antes de hacer la petición

    if (password.valid === "true" && password2.valid === "true") {
      const body = {
        password: password.field,
        passResetToken: token,
      };

      try {
        const { data } = await saveUser(body);
        setFormValidated(true);
        setCreateLogin(true);
        setMessage(null);
        await createLoginRegister({
          id: data.id,
        });
        navigate("/signin");
      } catch (error) {
        if (error.message === "password has been used before") {
          setMessage("This password has been used before");
          setFormValidated(false);
          setCreateLogin(false);
        } else {
          setMessage("Incorrect user or password");
          setFormValidated(false);
          setCreateLogin(false);
        }
      } finally {
        setLoading(false);
        setPassword({ field: "", valid: null });
      }
    } else {
      setFormValidated(false);
      setCreateLogin(false);
      setLoading(false);
      setMessage("Passwords do not match");
    }
  };

  const onHover = () => {
    setHover(!hover);
  };

  const validatePassword2 = () => {
    if (password.field.length > 0) {
      if (password.field !== password2.field) {
        setPassword2((prevState) => ({ ...prevState, valid: "false" }));
      } else {
        setPassword2((prevState) => ({ ...prevState, valid: "true" }));
      }
    }
  };

  const navigateSignin = () => {
    navigate("/signin");
  };

  useEffect(() => {
    if (createLogin === true) {
      handleSaveLogin();
    }
  }, [createLogin, message, handleSaveLogin]);

  return (
    <div>
      <NavBar configNavbar={configNavbar} />
      <LoginContainer>
        <LoginForm onSubmit={onSubmit}>
          <LoginTitle>Type the new password</LoginTitle>

          <InputComponent
            state={password}
            changeState={setPassword}
            type="password"
            name="password"
            label="Password"
            placeholder="********"
            legendError="The password must be 8 to 12 characters long."
            regularExpression={expressions.password}
          />

          <InputComponent
            state={password2}
            changeState={setPassword2}
            type="password"
            label="Repeat password"
            name="password2"
            legendError="Both passwords must be the same."
            func={validatePassword2}
          />

          <LoginButton
            type="submit"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Send {hover ? <ArrowForward /> : <ArrowRight />}
          </LoginButton>

          {loading && (
            <Box sx={{ marginTop: "20px" }}>
              <CircularProgress />
            </Box>
          )}

          <div>
            {message && (
              <MessageError>
                <p>
                  <b>{message}</b>
                </p>
              </MessageError>
            )}
            {formValidated === true && createLogin === true && (
              <MessageSuccess>Password has been changed</MessageSuccess>
            )}
            {formValidated === true && (
              <LoginButton onClick={navigateSignin}>Go to log in</LoginButton>
            )}
          </div>
        </LoginForm>
      </LoginContainer>
    </div>
  );
};

export default PasswordConfirmation;
