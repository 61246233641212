import { useEffect, useState } from "react";
// Hooks
import useFindKpiCharts from "pages/Dashboard/Hooks/events/useFindKpiCharts";
import useFindTextCharts from "pages/Dashboard/Hooks/events/useFindTextCharts";
import useFindLineCharts from "pages/Dashboard/Hooks/events/useFindLineCharts";
import useFindBarCharts from "pages/Dashboard/Hooks/events/useFindBarCharts";
import useFindDoughnutCharts from "pages/Dashboard/Hooks/events/useFindDoughnutCharts";
import { useFindTimeSeriesChartEvents } from "pages/Dashboard/Hooks/events/useFindTimeSeriesCharts";
// Components
import DropDragGrid from "pages/Dashboard/DragAndDrop/DragAndDrollGrid";
import { useFindGroupBarChartEvent } from "pages/Dashboard/Hooks/events/useFindGroupBarCharts";
import { useFindTimeSeriesBarChartEvents } from "pages/Dashboard/Hooks/events/useFindTimeSeriesBarCharts";
import { useFindImgChartsEvents } from "pages/Dashboard/Hooks/events/useFindImgCharts";
import { useFindGroupDoughnutChartsEvents } from "pages/Dashboard/Hooks/events/useFindGroupDoughnutCharts";
const EventsCharts = ({
  openDialog,
  events,
  loading,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  orderId,
}) => {
  const [dataChart, setDataChart] = useState([]);

  const { kpiCharts } = useFindKpiCharts({ openDialog, events, loading });
  const { textCharts } = useFindTextCharts({ openDialog, events, loading });

  const { lineCharts } = useFindLineCharts({ openDialog, events, loading });
  const { barCharts } = useFindBarCharts({ openDialog, events, loading });

  const { doughnutCharts } = useFindDoughnutCharts({
    openDialog,
    events,
    loading,
  });

  const { groupDoughnutCharts } = useFindGroupDoughnutChartsEvents({
    openDialog,
    events,
    loading,
  });

  const { timeSeriesCharts } = useFindTimeSeriesChartEvents({
    openDialog,
    events,
    loading,
  });

  const { groupBarCharts } = useFindGroupBarChartEvent({
    openDialog,
    events,
    loading,
  });

  const { timeSeriesBarCharts } = useFindTimeSeriesBarChartEvents({
    openDialog,
    events,
    loading,
  });

  const { imgCharts } = useFindImgChartsEvents({
    openDialog,
  });

  useEffect(() => {
    if (
      kpiCharts &&
      textCharts &&
      lineCharts &&
      barCharts &&
      doughnutCharts &&
      groupDoughnutCharts &&
      timeSeriesCharts &&
      groupBarCharts &&
      timeSeriesBarCharts &&
      imgCharts
    ) {
      setDataChart([
        ...kpiCharts,
        ...textCharts,
        ...lineCharts,
        ...barCharts,
        ...doughnutCharts,
        ...groupDoughnutCharts,
        ...timeSeriesCharts,
        ...groupBarCharts,
        ...timeSeriesBarCharts,
        ...imgCharts,
      ]);
    }
  }, [
    kpiCharts,
    textCharts,
    lineCharts,
    barCharts,
    doughnutCharts,
    groupDoughnutCharts,
    timeSeriesCharts,
    currentOrderName,
    currentTemplate,
    groupBarCharts,
    timeSeriesBarCharts,
    imgCharts,
  ]);

  return (
    <>
      {dataChart && events && (
        <DropDragGrid
          dataChart={dataChart}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          currentPositionId={currentPositionId}
          orderId={orderId}
        />
      )}
    </>
  );
};

export default EventsCharts;
