import axios from 'axios';
import { config } from 'config.js';

/**
 * 
 * @param {Object} body - The request body.
 */

const registerLogin = async (body) => {
    const token = localStorage.getItem('token');
    let url = `${config.URL_BACKEND_PG}api/v1/login-history`;

    try {
        const result = await axios.post(url, body, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        });
        const data = await result.data;
        return data;
    }
    catch (error) {
        console.error(error);
    }
};

export default registerLogin;
