import { config } from "config.js";
const backendPg = config.URL_BACKEND_PG;
const adminCompanyId = config.ADMIN_COMPANY_ID;

export const urlKeys = {
  objects: `${backendPg}api/v1/admin-company/objects-web/${adminCompanyId}`,
  eventsObjects: `${backendPg}api/v1/point-events?adminCompanyId=${adminCompanyId}`,
  eventsComponents: `${backendPg}api/v1/point-events/components?adminCompanyId=${adminCompanyId}`,
  eventsEvents: `${backendPg}api/v1/point-events/relation?adminCompanyId=${adminCompanyId}`,
  components: `${backendPg}api/v1/point-components?adminCompanyId=${adminCompanyId}`,
  lines: `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`,
};
