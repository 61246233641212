import styled from "styled-components";
import {
  BACKGROUND_GREY,
  BORDER_GREY,
  WHITE_BACKGROUND,
  WHITE_COLOR,
  BLACK_COLOR,
  INFORMATION_COLOR,
} from "utils/const";
import "animate.css";

export const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const HelpButton = styled.div`
  cursor: pointer;
  color: ${INFORMATION_COLOR};
  font-size: 4.5rem;
`;

export const ModalContent = styled.div`
  flex: grid;
  grid-template-rows: auto 1fr;
  width: 50%;
  background: ${WHITE_COLOR};
  border-radius: 8px;
  border: solid 1px ${BORDER_GREY};
  z-index: 999;

  &.animate__fadeIn {
    --animate-duration: 1.5s;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(180, 180, 180, 0.5);
  backdrop-filter: blur(1px);
  z-index: 998;
`;

//Buttons
export const DecimButton = styled.button`
  display: flex;
  justify-content: center;
  align-self: center;
  height: 45px;
  line-height: 45px;
  width: 150px;
  background: var(--background-primary_color);
  color: ${WHITE_COLOR};
  font-weight: bold;

  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-top: 1rem;
  transition: 0.1s ease all;

  &:hover {
    background: var(--background-secondary_color);
  }
`;

//Containers

export const ContainerBanner = styled.div`
  display: flex;
  border-radius: 8px 8px 0px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${WHITE_COLOR};
  padding: 15px 10px;
  background-color: var(--background-primary_color);

  h2 {
    color: ${WHITE_COLOR};
  }
`;

export const ContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 3rem 1rem 3rem;
  background: ${WHITE_BACKGROUND};

  h1 {
    font-size: 32px;
  }

  p {
    text-align: justify;
  }
`;

export const ContainerBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  align-items: flex-start;
  border: solid 1px ${BORDER_GREY};
  background: ${BACKGROUND_GREY};
  border-radius: 5px;
  padding: 21px;
  background: ${WHITE_COLOR};
`;
