import { config } from "config.js";
import { useState, useEffect } from "react";

async function fetchLineLibraries(id, adminCompanyId) {
  const baseUrl = new URL(`${config.URL_BACKEND_PG}api/v1/line-libraries`);

  if (id && !isNaN(parseInt(id))) {
    baseUrl.searchParams.append("id", parseInt(id));
  }

  if (adminCompanyId && !isNaN(parseInt(adminCompanyId))) {
    baseUrl.searchParams.append("adminCompanyId", parseInt(adminCompanyId));
  }

  const token = localStorage.getItem("token");

  const response = await fetch(baseUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  if (!response.ok) {
    throw new Error(`🚩Error fetching data: ${response.statusText}`);
  }

  return response.json();
}

function useGetLineLibraries({ id, adminCompanyId }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchLineLibraries(id, adminCompanyId);
        setData(result);
      } catch (err) {
        setError(err);
      }
    };

    getData();
  }, [id, adminCompanyId]);

  return { data, error };
}

export default useGetLineLibraries;
