import React from "react";
import { PRIMARY_COLOR } from "utils/const";

const FootPrintImg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="200"
        height="200"
        viewBox="0 0 686.30979 507.37542"
      >
        <path
          d="M839.55171,703.68771h-29.02c-.43018-.78-.83008-1.58-1.2002-2.39-3.33984-7.15-4.75-15.13-6.1001-22.95l-2.18994-12.7q10.5,7.635,20.99024,15.26c2.27978,1.66,4.60986,3.39,6.7998,5.26,4.61035,3.91,8.59033,8.43,10.1499,14.17.08008.32.16016.64.22022.96A19.46641,19.46641,0,0,1,839.55171,703.68771Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#f0f0f0"
        />
        <path
          d="M843.62153,701.29769a.77484.77484,0,0,1-.00976.15,10.48655,10.48655,0,0,1-.54,2.24H830.04145a16.31218,16.31218,0,0,1-1.03955-2.39,17.49851,17.49851,0,0,1-.8501-3.39,35.21814,35.21814,0,0,1,.67969-11.74c.25-1.25.52-2.51.79-3.75l1.54-7.08,7.71,12.39C841.45161,691.87771,844.13179,696.44766,843.62153,701.29769Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#f0f0f0"
        />
        <path
          d="M942.16168,232.86478H257.83832a1.0156,1.0156,0,0,1,0-2.0307H942.16168a1.0156,1.0156,0,0,1,0,2.0307Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#cacaca"
        />
        <ellipse
          cx="23.34831"
          cy="11.16881"
          rx="10.92534"
          ry="11.16881"
          fill="#3f3d56"
        />
        <ellipse
          cx="61.09038"
          cy="11.16881"
          rx="10.92534"
          ry="11.16881"
          fill="#3f3d56"
        />
        <ellipse
          cx="98.83246"
          cy="11.16881"
          rx="10.92534"
          ry="11.16881"
          fill="#3f3d56"
        />
        <path
          d="M919.5485,203.1439H892.73177a2.03119,2.03119,0,0,1,0-4.06139H919.5485a2.03119,2.03119,0,0,1,0,4.06139Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#3f3d56"
        />
        <path
          d="M919.5485,210.759H892.73177a2.03119,2.03119,0,0,1,0-4.06139H919.5485a2.03119,2.03119,0,0,1,0,4.06139Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#3f3d56"
        />
        <path
          d="M919.5485,218.3741H892.73177a2.03119,2.03119,0,0,1,0-4.06139H919.5485a2.03119,2.03119,0,0,1,0,4.06139Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#3f3d56"
        />
        <path
          d="M841.26582,578.28769H321.67744a20.7293,20.7293,0,0,1-20.70581-20.70606V298.99325a20.72918,20.72918,0,0,1,20.70581-20.70556H841.26582a20.72919,20.72919,0,0,1,20.70581,20.70556V557.58163A20.7293,20.7293,0,0,1,841.26582,578.28769Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#f0f0f0"
        />
        <path
          d="M898.30024,648.84188H378.71186a20.7293,20.7293,0,0,1-20.70581-20.706V369.54745a20.7292,20.7292,0,0,1,20.70581-20.70557H898.30024a20.72919,20.72919,0,0,1,20.70581,20.70557V628.13583A20.72929,20.72929,0,0,1,898.30024,648.84188Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#fff"
        />
        <path
          d="M898.30024,648.84188H378.71186a20.7293,20.7293,0,0,1-20.70581-20.706V369.54745a20.7292,20.7292,0,0,1,20.70581-20.70557H898.30024a20.72919,20.72919,0,0,1,20.70581,20.70557V628.13583A20.72929,20.72929,0,0,1,898.30024,648.84188Zm-519.58838-297a17.72584,17.72584,0,0,0-17.70581,17.70557V628.13583a17.72594,17.72594,0,0,0,17.70581,17.706H898.30024a17.726,17.726,0,0,0,17.70581-17.706V369.54745a17.72592,17.72592,0,0,0-17.70581-17.70557Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#e4e4e4"
        />
        <path
          d="M418.61225,603.56884H834.09264V532.35693a56.38609,56.38609,0,0,0-17.29473-40.16373l-.11295-.11031c-7.42075-7.44571-32.22061-26.34266-57.88-24.74044-13.55448.84379-25.159,7.3761-34.49064,19.4157-29.04672,37.48987-67.20243,24.835-87.36145,13.955-17.76588-9.59031-35.90932-14.09161-53.93-13.37521-25.23817.98693-60.76106,10.28438-86.6677,48.9746-9.89467,14.76533-31.41327,34.80813-77.74291,46.587Z"
          transform="translate(-256.84511 -196.31229)"
          fill={PRIMARY_COLOR}
        />
        <path
          d="M857.6003,605.225H419.41186a1.3714,1.3714,0,0,1-1.37133-1.37133V393.8299a1.37133,1.37133,0,0,1,2.74266,0V602.4823H857.6003a1.37133,1.37133,0,1,1,0,2.74266Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#3f3d56"
        />
        <circle cx="325.26146" cy="293.18288" r="8.22798" fill="#3f3d56" />
        <circle cx="486.63869" cy="271.66591" r="8.22798" fill="#3f3d56" />
        <circle cx="405.95007" cy="316.04465" r="8.22798" fill="#3f3d56" />
        <circle cx="244.57284" cy="328.14794" r="8.22798" fill="#3f3d56" />
        <circle cx="567.32731" cy="301.25174" r="8.22798" fill="#3f3d56" />
        <path
          d="M919.49205,523.44529a8.62866,8.62866,0,0,1,.92064-13.199L911.5418,480.8942l15.27123,4.54873,6.0827,27.17593a8.67542,8.67542,0,0,1-13.40368,10.82643Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#9e616a"
        />
        <path
          d="M775.49791,348.90049a8.62865,8.62865,0,0,0,8.96991,9.72628l11.42578,28.4551,9.0756-13.09714-12.24151-25.01351a8.67542,8.67542,0,0,0-17.22978-.07073Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#9e616a"
        />
        <polygon
          points="647.472 494.812 636.952 494.812 631.948 454.235 647.474 454.236 647.472 494.812"
          fill="#9e616a"
        />
        <path
          d="M907.00008,701.32191l-33.92056-.00126v-.429A13.20353,13.20353,0,0,1,886.28235,687.689h.00084l20.71751.00084Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#2f2e41"
        />
        <polygon
          points="613.047 494.478 603.018 491.303 610.493 451.107 625.295 455.794 613.047 494.478"
          fill="#9e616a"
        />
        <path
          d="M869.37275,701.32191l-32.33854-10.23828.12948-.409a13.20355,13.20355,0,0,1,16.57171-8.60245l.0008.00025,19.75126,6.25326Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#2f2e41"
        />
        <path
          d="M857.43293,492.80844s-15.60875,24.50868-10.09315,46.383,11.52216,63.92019,11.52216,63.92019l29.90593,71.17205,22.2929-.90945L888.92232,589.46l.87536-56.10627s15.38706-26.81444,12.39054-35.16157S857.43293,492.80844,857.43293,492.80844Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#2f2e41"
        />
        <path
          d="M908.42646,501.571s1.92027,24.31339-4.08629,34.61035-12.406,49.48841-12.406,49.48841l-10.20948,78.38239-21.14661-6.88153,18.03212-87.81806,14.1729-69.07881Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#2f2e41"
        />
        <path
          d="M915.92425,383.87218l-16.499-11.34081-25.10769-.72927-8.74352,8.57208-13.34314,6.81363-1.23569,111.00437s39.95671,19.15179,61.45273,9.90725l10.341-86.47174C927.07929,404.4661,915.92425,383.87218,915.92425,383.87218Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#3f3d56"
        />
        <path
          d="M855.58211,416.85183H811.57262l-28.9141-49.77031,18.80108-7.447,15.92224,28.32334,23.04561-2.08571,14.38938-.69593a15.84711,15.84711,0,0,1,16.49437,17.75949h0A15.86568,15.86568,0,0,1,855.58211,416.85183Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#3f3d56"
        />
        <circle cx="632.07342" cy="144.5792" r="21.76948" fill="#9e616a" />
        <path
          d="M915.1489,335.93858c-.66849-4.77931-3.90535-9.31727-8.50873-10.76518-1.82866-5.31031-6.67233-9.21985-12.0238-10.92407a23.48728,23.48728,0,0,0-27.00124,9.84786c-.40956.64393-1.66882,2.22585-1.63362,2.99427.04462.97592,1.53767,1.98358,2.28765,2.59886a33.53927,33.53927,0,0,0,5.98121,3.69167c7.8281,4.05838,5.22223,10.43637,5.116,17.66385-.05153,3.51224,1.121,6.51112,4.1159,8.51727,4.32224,2.89534,10.50791,1.74345,15.2108.58145,5.3364-1.31865,9.773-6.00656,12.81631-10.52662C914.20472,345.61491,915.8176,340.71788,915.1489,335.93858Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#2f2e41"
        />
        <polygon
          points="658.087 215.476 676.547 306.933 649.333 308.422 658.087 215.476"
          fill="#3f3d56"
        />
        <path
          d="M933.99165,702.4877a1.19488,1.19488,0,0,1-1.18994,1.2H791.5019a1.195,1.195,0,0,1,0-2.39H932.80171A1.19269,1.19269,0,0,1,933.99165,702.4877Z"
          transform="translate(-256.84511 -196.31229)"
          fill="#cacaca"
        />
      </svg>
    </>
  );
};

export default FootPrintImg;
