export const getWrapperTemplate = ({ template, index, size }) => {
  if (template) {
    const chartStyle = template?.chartsPosition?.find((elm) => {
      return elm.index === index;
    });

    if (size) {
      if (chartStyle) {
        return {
          width: "100%",
          height: "100%",
          index: true,
        };
      } else {
        return {
          width: template.sizeChart.width,
          height: template.sizeChart.height,
          index: false,
        };
      }
    } else {
      if (chartStyle) {
        return {
          gridRowStart: chartStyle.gridRowStart,
          gridColumnStart: chartStyle.gridColumnStart,
        };
      }
    }
  } else {
    return {};
  }
};
