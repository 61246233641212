import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Grid from "@material-ui/core/Grid";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useGet from "hooks/useFetch";

import saveRegionApiMg from "services/saveRegionApiMg";
import updateRegionApiMg from "services/updateRegion";
import updateRegionApiPg from "services/updateRegionPg";
import saveRegionApiPg from "services/saveRegionApiPg";
import uploadFileToS3 from "services/uploadFileToS3";
// //Dialogs
import OpenDialog from "../Dialogs/OpenDialog";
// import OpenDialog from "./../Dialogs/OpenDialog";
import GenericDialog from "components/Dialogs/GenericDialog";
import { config } from "config.js";

// custom style
import {
  WrapperTypeObjectTitle,
  WrapperTypeElementTitle,
  FormInput,
  WrapperButtonsIcons,
  SummaryFeature,
  LabelForm,
  InputForm,
  SelectRegion,
  useStyles,
  CustomizedAccordion,
} from "./AccordionStyles";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  showMarkerseByTypeObjectPolygon,
  showMarkerseByTypeElementPolygon,
  setDataObjectsPolygon,
  setGeometryPolygon,
} from "redux/actions/admin";

import { useEffect } from "react";
import { useSWRConfig } from "swr";
import axios from "axios";
import { setUpdateRegion } from "redux/actions/index";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const deleteDuplicateObjects = (objects) => {
  return objects.filter(
    (object, index, self) =>
      index === self.findIndex((t) => t.name === object.name)
  );
};

const getTypeElementsIds = (dataObjects) => {
  const typeElementsIds = dataObjects.map((object) => object.typeElement.id);
  return [...new Set(typeElementsIds)];
};

const AccordionObjectsPolygon = ({ dataObjects }) => {
  // Redux
  const dispatch = useDispatch();

  // Know if is new polygon or update polygon
  const updateRegion = useSelector(
    (state) => state.digitalTwinReducer.updateRegion
  );

  const { data: updateRegionData, isUpdate, id: newIdUpdate } = updateRegion;

  // Dialog of aceept or cancel last action
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to save this region?",
    disagree: "Cancel",
    agree: "Accept",
  });

  // Set content of dialog if is update
  useEffect(() => {
    if (isUpdate) {
      setContentDialog((current) => ({
        ...current,
        description: "Are you sure you want to update this region?",
      }));
    }
  }, [isUpdate]);

  const { mutate } = useSWRConfig();
  const [openModal, setOpenModal] = useState(false);

  // Foorm data
  const [regionName, setRegionName] = useState(
    updateRegionData?.name || "Region name"
  );
  const [contract, setContract] = useState(updateRegionData?.contract || "");
  const [campo, setCampo] = useState(updateRegionData?.field || "");
  const [period, setPeriod] = useState(updateRegionData?.baselinePeriod || "");
  const stateOfFacility = ["Nueva", "Existente", "N/A"];
  const [stateFacility, setStateFacility] = useState(
    stateOfFacility.find(
      (state) => state === updateRegionData?.stateFacility
    ) || stateOfFacility[0]
  );
  const [pdf, setPdf] = useState(null);
  const [namePdf, setNamePdf] = useState();
  const userId = localStorage.getItem("userId");
  const [comments, setComments] = useState(updateRegionData?.comments || "");
  const [fechaInicioOperacion, setFechaInicioOperacion] = useState(
    updateRegionData?.startDateOperation || dayjs()
  );
  const [fechaPresentacionAnh, setFechaPresentacionAnh] = useState(
    updateRegionData?.datePresentationAnh || dayjs()
  );

  // Handle dates
  const handleChange = (newValue) => {
    setFechaInicioOperacion(newValue);
  };
  const handleChangeFechaPresentacionAnh = (newValue) => {
    setFechaPresentacionAnh(newValue);
  };

  //Get Libraries
  const [librariesRegion, errorLibrariesRegion] = useGet(
    "api/v1/region-libraries"
  );
  const [regionLibrary, setRegionLibrary] = useState();

  useEffect(() => {
    const findCurrentRegionLibrary = async () => {
      let regionLibraryExists;
      if (isUpdate && !regionLibrary) {
        const token = localStorage.getItem("token");
        const { data } = await axios.get(
          `${config.URL_BACKEND_PG}api/v1/type-region?id=${updateRegionData?.regionTypeId}`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
        const typeRegion = data[0];
        regionLibraryExists = librariesRegion?.find(
          (library) => library.id === typeRegion.regionLibraryId
        );
      }
      !errorLibrariesRegion &&
        librariesRegion &&
        !regionLibrary &&
        setRegionLibrary(regionLibraryExists || librariesRegion[0]);
    };
    findCurrentRegionLibrary();
  }, [
    librariesRegion,
    errorLibrariesRegion,
    isUpdate,
    regionLibrary,
    updateRegionData?.regionTypeId,
  ]);

  //Get type Regions
  const [typesRegion, errorTypeRegion] = useGet(
    `api/v1/type-region/library/${regionLibrary?.id}`
  );
  const [typeRegion, setTypeRegion] = useState();
  const [userName, setUserName] = useState();

  useEffect(() => {
    let typeRegionExists;
    if (isUpdate && !typeRegion && !typesRegion?.error) {
      typeRegionExists = typesRegion?.find(
        (type) => type.id === updateRegionData?.regionTypeId
      );
    }
    !typesRegion?.error &&
      typesRegion?.at(0) &&
      !typeRegion &&
      !typeRegion &&
      setTypeRegion(typeRegionExists || typesRegion[0]);
  }, [
    errorTypeRegion,
    typesRegion,
    isUpdate,
    typeRegion,
    updateRegionData?.regionTypeId,
  ]);

  const classes = useStyles();

  // Info points into region
  const typeLibraryId = dataObjects.map(
    (object) => object.typeElement.libraryId
  );
  const objectIds = dataObjects.map((object) => object.id);

  //Data Admin
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [user, errorUser] = useGet(`api/v1/users/${userId}`);

  // filter duplicate typeLibraryId
  const uniqueTypeObjectsIds = [...new Set(typeLibraryId)];

  //Geometry Polygon
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  //Features Polygon
  const featuresPolygon = useSelector(
    (state) => state.adminReducer.setFeaturesPolygon
  );

  // I don't know what this useEffect does
  useEffect(() => {
    const showTypeObject = {};
    uniqueTypeObjectsIds.map(
      (typeLibraryId) => (showTypeObject[typeLibraryId] = true)
    );
    dispatch(showMarkerseByTypeObjectPolygon({ ...showTypeObject }));

    const showTypeElement = {};
    const typeElementsIds = getTypeElementsIds(dataObjects);
    typeElementsIds.map(
      (typeElementId) => (showTypeElement[typeElementId] = true)
    );
    dispatch(showMarkerseByTypeElementPolygon({ ...showTypeElement }));

    const findUserName = () => {
      let userName = null;
      if (user.operator !== null) {
        const { operator } = user;
        userName = operator.firstName + " " + operator.firstLastName;
      }
      if (user.adminCompany !== null) {
        const { adminCompany } = user;
        userName = adminCompany.firstName + " " + adminCompany.firstLastName;
      }
      if (user.adminDecimetrix !== null) {
        const { adminDecimetrix } = user;
        userName =
          adminDecimetrix.firstName + " " + adminDecimetrix.firstLastName;
      }
      setUserName(userName);
    };

    user && findUserName();
  }, [dataObjects, user, dispatch]);

  // Handle modal
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [handleOpenModal, setHandleOpenModal] = useState(false);

  // Define functions to handle save button
  const handlerSave = async () => {
    let url = null;
    if (pdf) url = await savePdf();
    await sendRegionGeoJson(url);
    setHandleOpenModal(false);
    dispatch(setGeometryPolygon([]));
  };

  const handleUpdate = async () => {
    let url = null;
    if (pdf) url = await savePdf();
    await updateRegionGeoJson(url);
    setHandleOpenModal(false);
    dispatch(setGeometryPolygon([]));
    dispatch(setUpdateRegion({ id: null, isUpdate: false, data: {} }));
  };

  // Change in library select
  const handleLibrary = (e) => {
    setRegionLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: librariesRegion.find(
        (library) => library.id === parseInt(e.target.value)
      )?.name,
    }));
  };

  // Change in type region select
  const handleTypeRegion = (e) => {
    setTypeRegion((currentTypeRegion) => ({
      ...currentTypeRegion,
      id: e.target.value,
      type: typesRegion.find((type) => type.id === parseInt(e.target.value))
        ?.type,
    }));
  };

  // Change in state Facility select
  const handleStateFacility = (e) => {
    setStateFacility(() => e.target.value);
  };

  // Create GeoJson structure
  const buildGeoJson = (urlPdf) => {
    //geoJson format for region
    const regionGeoJson = {
      userId: userId,
      type: "geojson",
      name: regionName,
      createdAt: new Date(),
      updatedAt: new Date(),
      regionTypeId: typeRegion?.id,
      data: {
        type: "FeatureCollection",
        features: [
          ...featuresPolygon,
          {
            type: "Feature",
            properties: {
              name: regionName,
              regionTypeId: parseInt(typeRegion?.id),
              regionlibrary: regionLibrary?.name,
              regionType: typeRegion?.type,
              contract,
              field: campo,
              baselinePeriod: period,
              stateFacility,
              startDateOperation: fechaInicioOperacion.$d,
              datePresentationAnh: fechaPresentacionAnh.$d,
              userName,
              comments,
              adminCompanyId,
              urlPdf,
            },
            geometry: geometryPolygon,
          },
        ],
      },
    };

    return regionGeoJson;
  };

  // Create region structure
  const buildRegion = (data) => {
    const region = {
      name: regionName,
      regionTypeId: typeRegion?.id,
      contract: contract,
      field: campo,
      baselinePeriod: period,
      stateFacility: stateFacility,
      startDateOperation: fechaInicioOperacion.$d,
      datePresentationAnh: fechaPresentacionAnh.$d,
      userId: user?.id,
      comments: comments,
      geojsonRegionId: data._id,
    };
    return region;
  };

  // Function to save Region
  const sendRegionGeoJson = async (urlPdf) => {
    const regionGeoJson = buildGeoJson(urlPdf);

    // save region to api-green-dragon-mg
    const { data, res } = await saveRegionApiMg(regionGeoJson);

    const region = buildRegion(data);

    if (res.status === 200) {
      const body = {
        region,
        objectIds,
      };
      try {
        const { res: resPg } = await saveRegionApiPg(body);
        if (resPg.status === 200) {
          mutate(
            `${config.URL_BACKEND_MG}region?admin_company_id=${adminCompanyId}`
          );
          dispatch(setDataObjectsPolygon([]));
        }
      } catch (e) {
        alert("Error al guardar la región");
        handleClose();
      }
    }
  };

  // Function to update Region
  const updateRegionGeoJson = async (urlPdf) => {
    const regionGeoJson = buildGeoJson(urlPdf);
    const id = newIdUpdate.split("-")[1];
    delete regionGeoJson.createdAt;

    // update region to api-green-dragon-mg
    const { data, res } = await updateRegionApiMg(regionGeoJson, id);
    const region = buildRegion(data);

    if (res.status === 200) {
      const body = {
        region,
        objectIds,
      };
      try {
        const { res: resPg } = await updateRegionApiPg(body, id);
        if (resPg.status === 200) {
          mutate(
            `${config.URL_BACKEND_MG}region?admin_company_id=${adminCompanyId}`
          );
          dispatch(setDataObjectsPolygon([]));
        }
      } catch (e) {
        alert("Error al guardar la región");
        handleClose();
      }
    }
  };

  // Function to upload PDF
  const uploadPdf = async (e) => {
    const file = e.target.files[0];
    setPdf(file);
    setNamePdf(file.name);
  };

  // Function to save PDF
  const savePdf = async () => {
    const urlPdf = await uploadFileToS3(pdf, "8");
    return urlPdf;
  };

  const showTypeObject = useSelector(
    (state) => state.adminReducer.markerByTypeObjectPolygon
  );

  const handlerClickFilterTypeLibrary = (typeLibraryId) => {
    showTypeObject[typeLibraryId] = !showTypeObject[typeLibraryId];
    dispatch(showMarkerseByTypeObjectPolygon({ ...showTypeObject }));
  };

  const showTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElementPolygon
  );

  const handlerClickFilterTypeElementId = (typeElementId) => {
    showTypeElement[typeElementId] = !showTypeElement[typeElementId];
    dispatch(showMarkerseByTypeElementPolygon({ ...showTypeElement }));
  };

  const accordionDetails = uniqueTypeObjectsIds.map((typeLibraryId, index) => {
    // filter typeElement by typeLibraryId
    const objects = dataObjects.filter(
      (object) => object.typeElement.libraryId === typeLibraryId
    );

    const typesElementNames = objects.map((object) => {
      return {
        id: object.typeElement.id,
        name: object.typeElement.name,
        icon: object.typeElement.urlIconColor,
      };
    });
    const uniqueTypesElementNames = deleteDuplicateObjects(typesElementNames);

    return (
      <div key={index}>
        <Accordion
          sx={{
            paddingLeft: "5px",
            border: "none",
            boxShadow: "none",
          }}
          defaultExpanded={false}
        >
          <WrapperTypeObjectTitle>
            <div className="icon-title">
              {/* {showTypeObject[typeLibraryId] ? (
                <VisibilityIcon
                  sx={{
                    color: "#272727",
                    margin: "0px 10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlerClickFilterTypeLibrary(typeLibraryId)}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{
                    color: "#575757",
                    margin: "0px 10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlerClickFilterTypeLibrary(typeLibraryId)}
                />
              )} */}

              <p>Objects {typeLibraryId}</p>
            </div>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            ></AccordionSummary>
          </WrapperTypeObjectTitle>
          <AccordionDetails>
            {uniqueTypesElementNames.map((element, index) => {
              return (
                <FormGroup key={index}>
                  <WrapperTypeElementTitle>
                    <div className="icon-title">
                      {/* {showTypeElement[element.id] ? (
                        <VisibilityIcon
                          sx={{
                            color: "#575757",
                            margin: "0px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handlerClickFilterTypeElementId(element.id)
                          }
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{
                            color: "#575757",
                            margin: "0px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handlerClickFilterTypeElementId(element.id)
                          }
                        />
                      )} */}
                      <p>{element.name}</p>
                    </div>
                    <img
                      src={element.icon}
                      width="30px"
                      height="30px"
                      alt="ico"
                    />
                  </WrapperTypeElementTitle>
                </FormGroup>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });

  return (
    <CustomizedAccordion
      classes={{
        content: classes.content,
        expanded: classes.expanded,
      }}
      defaultExpanded={true}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormInput
          type="text"
          value={regionName}
          onChange={(e) => setRegionName(e.target.value)}
        ></FormInput>

        <WrapperButtonsIcons>
          <SaveTwoToneIcon
            onClick={handleOpen}
            sx={{
              cursor: "pointer",
              color: "#006ad4",
              "&:hover": {
                color: `${SECOND_COLOR}`,
              },
            }}
          />
        </WrapperButtonsIcons>

        {/* dialog */}
        <>
          <OpenDialog
            openDialog={handleOpenModal}
            setOpenDialog={setHandleOpenModal}
            execute={isUpdate ? handleUpdate : handlerSave}
            content={contentDialog}
          />
          <GenericDialog
            openDialog={openModal}
            setOpenDialog={setOpenModal}
            execute={() => setHandleOpenModal(true)}
            content={{
              title: "Region Details",
              agree: isUpdate ? "Update" : "Guardar",
              content: (
                <SummaryFeature>
                  <div>
                    <p className="geometry-type">
                      <b>Nombre de la región: *</b>
                    </p>
                    <input
                      type="text"
                      value={regionName}
                      onChange={(e) => setRegionName(e.target.value)}
                    />
                  </div>
                  <div>
                    <p className="geometry-type">
                      <b>Librería: *</b>
                    </p>
                    <SelectRegion
                      name="endPoint"
                      id="endPoint"
                      onChange={handleLibrary}
                    >
                      {librariesRegion?.map((item) => (
                        <option
                          selected={
                            regionLibrary?.name === item.name ? true : false
                          }
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </SelectRegion>
                  </div>
                  <div>
                    <p className="geometry-type">
                      <b>Tipo de región: *</b>
                    </p>
                    {typesRegion && (
                      <SelectRegion
                        name="regions"
                        id="region-select"
                        onChange={handleTypeRegion}
                      >
                        {!typesRegion?.error &&
                          typesRegion?.map((item) => (
                            <option
                              selected={
                                typeRegion?.type === item.type ? true : false
                              }
                              key={item.id}
                              value={item.id}
                            >
                              {item.type}
                            </option>
                          ))}
                      </SelectRegion>
                    )}
                  </div>
                  <div>
                    <p>
                      <b>Contrato:</b>
                    </p>
                    <input
                      readonly
                      type="text"
                      value={contract}
                      onChange={(e) => setContract(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>
                      <b>Campo:</b>
                    </p>
                    <input
                      readonly
                      type="text"
                      value={campo}
                      onChange={(e) => setCampo(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>
                      <b>Período de línea base:</b>
                    </p>
                    <input
                      readonly
                      type="text"
                      onChange={(e) => setPeriod(e.target.value)}
                      value={period}
                    />
                  </div>
                  <div>
                    <p className="geometry-type">
                      <b>Estado de la región:</b>
                    </p>
                    <SelectRegion
                      name="endPoint"
                      id="endPoint"
                      onChange={handleStateFacility}
                    >
                      {stateOfFacility?.map((item) => (
                        <option
                          selected={stateFacility === item ? true : false}
                          key={item.id}
                          value={item.id}
                        >
                          {item}
                        </option>
                      ))}
                    </SelectRegion>
                  </div>

                  <div>
                    <p>
                      <b>Fecha de inicio de operación: *</b>
                    </p>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      // label="Fecha de inicio de operación"
                      inputFormat="MM/DD/YYYY"
                      value={fechaInicioOperacion}
                      onChange={handleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <div>
                    <p>
                      <b>Fecha de presentación de la línea base a la ANH: *</b>
                    </p>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      // label="Fecha de inicio de operación"
                      inputFormat="MM/DD/YYYY"
                      value={fechaPresentacionAnh}
                      onChange={handleChangeFechaPresentacionAnh}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <div>
                    <p>
                      <b>Creado por:</b>
                    </p>
                    {!errorUser && user && (
                      <input
                        readonly
                        type="text"
                        value={updateRegionData?.userName || userName}
                      />
                    )}
                  </div>

                  <div>
                    <label htmlFor="uploadPdf">
                      <LabelForm htmlFor="uploadPdf">Adjuntar PDF:</LabelForm>
                    </label>
                    <Grid container spacing={2}>
                      <Grid item xs={10} md={10} lg={10} xl={10}>
                        <InputForm>{namePdf}</InputForm>
                        <input
                          id="uploadPdf"
                          type="file"
                          name="file"
                          style={{ display: "none" }}
                          onChange={uploadPdf}
                          accept=".pdf"
                        ></input>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} xl={2}>
                        <label htmlFor="uploadPdf">
                          <PictureAsPdfIcon
                            sx={{
                              cursor: "pointer",
                              mt: 1,
                            }}
                          />
                        </label>
                      </Grid>
                    </Grid>
                  </div>

                  <div>
                    <p>
                      <b>Comentarios:</b>{" "}
                      <textarea
                        type="text"
                        onChange={(e) => setComments(e.target.value)}
                        value={comments}
                      />
                    </p>
                  </div>
                </SummaryFeature>
              ),
            }}
            style={{
              margin: 0,
              padding: 0,
              width: "50%",
              maxWidth: "none",
            }}
          />
        </>
      </div>
      {accordionDetails}
    </CustomizedAccordion>
  );
};

export default AccordionObjectsPolygon;
