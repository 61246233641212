import { useState } from "react";
// Components
import Input from "./Input";
import { MessageError } from "components/Forms/FormStyles";
import CustomButton from "components/Buttons/CustomButton";
// Style
import { FormContainer } from "./FlareEffiencyStyle";
//
import { CalculateEfficiency } from "helpers/calculateFlareEfficiency";
import { BLACK_COLOR, PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

function Formulario() {
  const [metano, setMetano] = useState({ field: "", valid: null });
  const [masaGas, setMasaGas] = useState({ field: "", valid: null });
  const [viento, setViento] = useState({ field: "", valid: null });
  const [caudalGas, setCaudalGas] = useState({ field: "", valid: null });
  const [diametro, setDiametro] = useState({ field: "", valid: null });
  const [FormValidated, setFormValidated] = useState(false);
  const [flareEfficiency, setFlareEfficiency] = useState("");

  const expressions = {
    number: /^\d+(\.\d+)?$/, // permite enteros y flotantes
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      metano.valid === "true" &&
      masaGas.valid === "true" &&
      viento.valid === "true" &&
      caudalGas.valid === "true" &&
      diametro.valid === "true"
    ) {
      setFormValidated(false);
      const efficiency = CalculateEfficiency(
        parseFloat(metano.field),
        parseFloat(masaGas.field),
        parseFloat(viento.field),
        parseFloat(caudalGas.field),
        parseFloat(diametro.field)
      );
      setFlareEfficiency(efficiency);
      return;
    }
    setFormValidated(true);
  };

  return (
    <FormContainer
      className="background-row-1 border-color-1 color1"
      onSubmit={handleSubmit}
    >
      <Input
        state={metano}
        changeState={setMetano}
        type="number"
        name="metano"
        label="Porcentaje de Metano (%):"
        placeholder="44.23"
        legendError="el valor debe ser un número"
        regularExpression={expressions.number}
      />
      <Input
        state={masaGas}
        changeState={setMasaGas}
        type="number"
        name="masaGas"
        label="Masa Molar del Gas (g/mol):"
        placeholder="34.91"
        legendError="el valor debe ser un número"
        regularExpression={expressions.number}
      />
      <Input
        state={viento}
        changeState={setViento}
        type="number"
        name="viento"
        label="Velocidad del Viento (mph):"
        placeholder="6"
        legendError="el valor debe ser un número"
        regularExpression={expressions.number}
      />
      <Input
        state={caudalGas}
        changeState={setCaudalGas}
        type="number"
        name="caudalGas"
        label="Caudal del Gas (MMSCFD):"
        placeholder="1.1"
        legendError="el valor debe ser un número"
        regularExpression={expressions.number}
      />
      <Input
        state={diametro}
        changeState={setDiametro}
        type="number"
        name="diametro"
        label="Diámetro de la TEA (in):"
        placeholder="12"
        legendError="el valor debe ser un número"
        regularExpression={expressions.number}
      />
      <div>
        {FormValidated && (
          <MessageError>
            Por favor llene el formulario correctamente.
          </MessageError>
        )}
      </div>
      <CustomButton
        type="submit"
        primaryColor={"var(--background-primary_color)"}
        secondaryColor={"var(--background-secondary_color)"}
        text={"Calcular"}
      />
      <p className="color1">
        {" Eficiencia de la tea (%): "} {flareEfficiency}
      </p>
    </FormContainer>
  );
}

export default Formulario;
