import axios from "axios";
import { config } from "config.js";

const deleteNewEvent = async (pointEventId) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${config.URL_BACKEND_PG}api/v1/point-events/${pointEventId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteNewEvent;
