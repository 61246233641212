const Grid = ({ children, minWidth = 280, gap = "15px", template }) => {
  const templateStyle = {
    maxWidth: "100%",
    display: "grid",
    gridTemplateColumns: template?.gridTemplateColumns,
    gridGap: "10px",
    padding: "20px",
    marginTop: "65px",
  };

  const defaultStyle = {
    maxWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gridGap: gap,
    marginTop: "90px",
    marginBottom: "31px",
  };

  return (
    <ul
      id="chart-dashboard-element-array"
      style={template ? templateStyle : defaultStyle}
    >
      {children}
    </ul>
  );
};

export default Grid;
