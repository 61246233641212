import { updateChart } from "services/Dashboard/doughnutChart";
import { useSWRConfig } from "swr";
import { config } from "config";
import { useEffect, useState } from "react";
import generateRandomHexadecimal from "helpers/generateRandomHexadecimal";

export const useSetCurrentStyle = ({ dataChart, setChartEditDialog }) => {
  const { mutate } = useSWRConfig();
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlKpi = `${
    config.URL_BACKEND_MG
  }doughnut-chart?adminCompanyId=${adminCompanyId}&from=${
    dataChart.fromType ? dataChart.fromType : dataChart.from
  }`;
  const [currentStyleLineDataChart, setCurrentStyleLineDataChart] =
    useState(dataChart);

  useEffect(() => {
    const colors = dataChart.columnX.values.map(() => {
      const colorRandom = generateRandomHexadecimal();
      return colorRandom;
    });
    setCurrentStyleLineDataChart((current) => {
      if (
        dataChart.columnX.values.length > 0 &&
        dataChart.columnX.backgroundColor.length === 0
      ) {
        return {
          ...dataChart,
          columnX: {
            ...dataChart.columnX,
            backgroundColor: colors,
            backgroundColorHexadecimal: colors,
          },
          datasets: [
            {
              ...dataChart.datasets[0],
              backgroundColor: colors,
              backgroundColorHexadecimal: colors,
            },
          ],
        };
      }
      return dataChart;
    });
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...currentStyleLineDataChart,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        donutStyle: currentStyleLineDataChart.datasets[0],
      },
    });
    if (data) {
      mutate(urlKpi);
      setChartEditDialog(false);
    }
  };

  return {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
  };
};
