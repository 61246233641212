import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Scope3icon = () => {
  return (
    <svg
      id="Layer_49"
      data-name="Layer 49"
      width="200"
      height="200"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 433.72 433.72"
    >
      <defs>
        <style>
          {` .cls-1scope3 {
        fill: var(--background-secondary_color);
      }

      .cls-1scope3, .cls-2scope3, .cls-3scope3 {
        stroke-width: 0px;
      }

      .cls-2scope3 {
        fill: #fff;
      }

      .cls-3scope3 {
        fill: var(--background-primary_color);
        
      }`}
        </style>
      </defs>
      <circle class="cls-3scope3" cx="216.86" cy="216.86" r="165.05" />
      <path
        id="tres"
        class="cls-2scope3"
        d="M184.03,321.81v-25.04c25.84,10.5,83.76,26.57,94.82-12.15,11.91-41.72-40.24-39.25-66.23-41.43v-22.22c24.32-1.75,69.34-.36,62.23-38.18-6.81-36.24-57.09-17.86-76.71-5.87-4.59-6.42-8.96-12.52-13.82-19.32,28.82-19.03,84.65-33.68,110.34-.76,9.41,12.06,11.86,29.69,6.82,44.07-6.05,17.27-20.74,23.89-36.67,29.91,17.7,3.86,35.13,9.24,42.16,27.91,5.89,15.66,2.53,35.73-7.21,49.12-23.33,32.08-84,30.64-115.72,13.95Z"
      />
      <path
        class="cls-1scope3"
        d="M181.12,135.22l-60.41-86.55c-1.86-2.66-5.79-2.66-7.65,0l-60.41,86.55c-2.16,3.09.05,7.33,3.82,7.33h21.93c2.58,0,4.66,2.09,4.66,4.66v55.69c0,2.58,2.09,4.66,4.66,4.66h58.29c2.58,0,4.66-2.09,4.66-4.66v-55.69c0-2.58,2.09-4.66,4.66-4.66h21.93c3.77,0,5.98-4.24,3.82-7.33Z"
      />
    </svg>
  );
};

export default Scope3icon;
