import axios from "axios";
import { config } from "config.js";

const updateGlobalConstant = async ({ id, body }) => {
  try {
    const token = localStorage.getItem("token");
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    body = { ...body, adminCompanyId };
    const res = await axios.patch(
      `${config.URL_BACKEND_PG}api/v1/global-constants/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    return { error };
  }
};

export default updateGlobalConstant;
