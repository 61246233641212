import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Socratesicon = () => {
  return (
    <>
      <svg
        id="Layer_15"
        data-name="Layer 15"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
        style={{ padding: "2rem" }}
      >
        <defs>
          <style>
            {`.cls-1soc {
        fill: var(--background-primary_color);
        
      }

      .cls-1soc, .cls-2soc, .cls-3soc {
        stroke-width: 0px;
      }

      .cls-2soc {
        fill: var(--background-secondary_color);
      }

      .cls-3soc {
        fill: #fff;
      }`}
          </style>
        </defs>
        <g>
          <circle class="cls-2soc" cx="180.2" cy="183.48" r="134.03" />
          <path
            class="cls-2soc"
            d="m55.87,315.66s20.88-25.12,5.41-79.05c32.21-35.91,73.53,44.61,73.53,44.61l-4.95,12.87c-1.75,4.54-5.87,7.75-10.71,8.29-3.83.43-8.82,2.02-14.13,6.42-3.62,3.01-7.68,5.43-12.13,6.98-9.87,3.45-20.97,5.02-37.03-.13Z"
          />
          <circle class="cls-3soc" cx="120.64" cy="186.85" r="22.25" />
          <circle class="cls-3soc" cx="180.15" cy="186.85" r="22.25" />
          <circle class="cls-3soc" cx="239.76" cy="186.85" r="22.25" />
        </g>
        <g>
          <path
            class="cls-1soc"
            d="m379.15,381.41s-18.07-21.74-4.68-68.41c-27.87-31.08-63.64,38.61-63.64,38.61l4.28,11.13c1.51,3.93,5.08,6.7,9.27,7.18,3.31.37,7.63,1.74,12.23,5.56,3.13,2.6,6.65,4.7,10.5,6.05,8.54,2.98,18.15,4.35,32.05-.11Z"
          />
          <path
            class="cls-1soc"
            d="m334.62,169.67c1.33,7.89,2.03,15.99,2.03,24.26,0,80.02-64.87,144.89-144.89,144.89-3.93,0-7.82-.16-11.67-.47,21.23,27.18,54.29,44.66,91.45,44.66,64.07,0,116-51.94,116-116,0-40.8-21.08-76.66-52.93-97.34Z"
          />
        </g>
      </svg>
    </>
  );
};

export default Socratesicon;
