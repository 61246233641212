export const removeAccents = (str) => {
  const accentsMap = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    ñ: "n",
  };
  return str.replace(/[áéíóúñ]/gi, (match) => accentsMap[match]);
};

export const replaceDotsWithUnderscores = (string) => {
  if (string) {
    const str = string.replace(/\./g, "");
    return str;
  }
};
