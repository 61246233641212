import Layout from "components/Layout/AdminDecimetrix";
import { Container } from "./style";
import DecimetrixNetZeroImage from "images/decimetrix_net_zero.png";

export const CreateAccount = () => {
  return (
    <Layout>
      <Container>
        <img src={DecimetrixNetZeroImage} alt="Decimetrix net zero logo" />
        <h1>Create Account</h1>
        <p>
          <b>Decimetrix® Green Dragon</b> is a comprehensive sustainability
          solution that empowers your organization to track, control, and reduce
          its carbon footprint. Our platform seamlessly collects and integrates
          data from multiple sources, platforms, and clouds, providing you with
          online and offline access from any device, with multi-user
          capabilities. Additionally, leverage AI (artificial intelligence) and
          AR (augmented reality) features to enhance data interpretation and
          presentation.
        </p>
        <h2>Join the Decimetrix® Community</h2>
        <p>
          Ready to take the first step towards a sustainable future? Contact us
          at <b>support.greendragon@decimetrix.com</b> to learn more about how{" "}
          <b>Decimetrix® Green Dragon</b> can empower your organization's
          sustainability journey.
        </p>
      </Container>
    </Layout>
  );
};
