import styled from "styled-components";
import { ICON_GREEN_COLOR, OBJECT_CLUSTER_COLOR } from "utils/const";

export const NamePlateMarker = styled.button`
  background: none;
  border: none;
  position: relative;

  img {
    width: 40px;
  }

  .selected {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.marked ? "block" : "none")};
  }

  .qaqc {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${ICON_GREEN_COLOR};
    border-radius: 50%;
    z-index: -1;
    display: ${(props) => (props.qaqc ? "block" : "none")};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      opacity: 0.6;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }
`;

export const ImgIconOperation = styled.img`
  cursor: pointer;
`;
