import Select from "react-select";
// Styles
import { selectStyle, SelectType } from "./style";

const SelectTypeOperation = ({ handlerSelectTypeOperation,typeOperationSelect }) => {
  return (
    <SelectType>
      <h2 className="subtitle">Select operation</h2>
      <div className="select">
        <Select
          key={"select type chart"}
          onChange={(e) => handlerSelectTypeOperation(e.value)}
          options={[
            { value: "count", label: "Count" },
            { value: "sum", label: "Sum Σ" },
          ]}
          defaultValue={{ value: "count", label: "Count" }}
          value={typeOperationSelect}
          placeholder={"Select type chart"}
          styles={selectStyle}
        />
      </div>
    </SelectType>
  );
};

export default SelectTypeOperation;
