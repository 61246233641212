import deleteObjectFile from "services/deleteObjectFile";
import {
  deleteCaptureMedia,
  deleteCaptureMediaPg,
} from "services/uploadFilePointEvent";
import { config } from "config.js";
import deleteEventPhoto from "services/deleteEventPhoto";
import { setUpdateAtComponent } from "services/savePointComponent";
import { setUpdateAtEvent } from "services/savePointEvent";
import updateObjectPg from "services/updateObjectPg";
import { updateLinePg } from "services/lines/updateLine";
import moment from "moment";
import { deleteCaptureMediaLines } from "services/lines/uploadFileLines";

export default async function deleteImages(
  currentPhoto,
  uploadImages,
  mongoId,
  ownId,
  mutate
) {
  const userId = localStorage.getItem("userId");
  if (currentPhoto.type === "object") {
    await deleteObjectFile(currentPhoto?.id);
    let body = {
      lastUpdate: new Date(),
      userUpdateId: userId,
    };
    await updateObjectPg(body, ownId);
    const urlData = `${config.URL_BACKEND_PG}api/v1/objects/${ownId}`;
    mutate(urlData);
  }
  if (currentPhoto.type === "operation") {
    await deleteEventPhoto(currentPhoto?.id);
    const urlData = `${config.URL_BACKEND_PG}api/v1/operation/${ownId}`;
    mutate(urlData);
  }
  if (currentPhoto.type === "pointEventPg") {
    await deleteCaptureMediaPg(`event-files/${currentPhoto?.id}`);
    await setUpdateAtEvent(ownId);
    uploadImages();
  }
  if (currentPhoto.type === "pointEventMg") {
    await deleteCaptureMedia({
      endPoint: "point-event/event-file/",
      pointTypeMgId: mongoId,
      mediaId: currentPhoto?.id,
    });
    await setUpdateAtEvent(ownId);
    uploadImages();
  }
  if (currentPhoto.type === "pointComponentPg") {
    await deleteCaptureMediaPg(`component-files/${currentPhoto?.id}`);
    await setUpdateAtComponent(ownId);
    uploadImages();
  }
  if (currentPhoto.type === "pointComponentMg") {
    await deleteCaptureMedia({
      endPoint: "point-components/component-file/",
      pointTypeMgId: mongoId,
      mediaId: currentPhoto?.id,
    });
    await setUpdateAtComponent(ownId);
    uploadImages();
  }
  if (currentPhoto.type === "pointObjectMg") {
    let body = {
      lastUpdate: new Date(),
      userUpdateId: userId,
    };
    await updateObjectPg(body, ownId);
    await deleteCaptureMedia({
      endPoint: "point-object/object-file/",
      pointTypeMgId: mongoId,
      mediaId: currentPhoto?.id,
    });
    uploadImages();
  }
  if (currentPhoto.type === "line") {
    const dateUpdate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS Z')
    const indx = dateUpdate.lastIndexOf(':');
    const dateUpdateParsed = dateUpdate.substring(0, indx).concat(dateUpdate.substring(indx + 1))
    let body = {
      updatedAt: dateUpdateParsed,
      userUpdatedBy: userId,
    };
    await updateLinePg(body, ownId);
    await deleteCaptureMediaLines({
      endPoint: "line",
      mongoId,
      mediaId: currentPhoto?.id,
    });
    uploadImages();
  }
}
