import axios from "axios";
import { config } from "config.js";

async function createNewView(newView) {
  try {
    const token = localStorage.getItem("token");
    const url = `${config.URL_BACKEND_PG}api/v1/views`;
    const result = await axios.post(url, newView, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.error("🚩🚩Error on Create new View: ", error);
    return { error };
  }
}

export default createNewView;
