import styled from "styled-components";
import { BLACK_COLOR } from "utils/const";

export const GalleryContainerNoThumbnails = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 440px;
    background-color: ${BLACK_COLOR};
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-content {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 0px 0px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const GalleryContainer = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 525px;
    background-color: ${BLACK_COLOR};
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-image:nth-child(${(props) => props.items}) {
      transform: rotate(90deg);
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const CustomImageGalleyItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-items: center;

  .button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .wrapper-buttons {
    display: flex;
    position: absolute;
    bottom: 40px;
  }
`;
