import React from "react";
import { PRIMARY_COLOR_TRANS_LOGO } from "utils/const";

const NeuronaIconD = ({ height, width }) => {
  return (
    <>
      <svg
        id="Capa_78"
        data-name="Capa 78"
        xmlns="http://www.w3.org/2000/svg"
        width={height}
        height={width}
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`.logoIcon-color{
        fill: var(--background-trans_color);
        stroke-width: 0px;
      }
      `}
          </style>
        </defs>
        <path
          class="logoIcon-color"
          d="M336.14,271.63c-.65,0-1.28.08-1.93.1-8.4.07-42.52-1.04-65.8-22.8-35.22-32.92-27.23-72.46-25.4-79.68,2.63-9.29,4.07-19.07,4.07-29.2,0-59.11-47.92-107.03-107.03-107.03s-107.03,47.92-107.03,107.03,47.92,107.03,107.03,107.03c11.08,0,21.77-1.68,31.83-4.81,9.01-2.26,45.34-8.7,78.71,23.27,19.83,19.01,21.76,49.36,21.5,63.25-.28,2.43-.45,4.88-.45,7.38,0,35.64,28.87,64.51,64.51,64.51s64.55-28.87,64.55-64.51-28.91-64.55-64.55-64.55Z"
        />
      </svg>
    </>
  );
};

export default NeuronaIconD;
