import styled from "styled-components";
import { WHITE_COLOR } from "utils/const";

export const CollectionWrapper = styled.div`
  width: 100%;
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: center;
  gap: 3rem;
  margin-bottom: 3rem;
`;

export const CollectionCard = styled.div`
  background-color: ${WHITE_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const CollectionIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const CollectionH1 = styled.h1`
  font-size: 2.5rem;
  color: #010606;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const CollectionH2 = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CollectionP = styled.p`
  font-size: 1.4rem;
  text-align: center;
`;

export const ViewModeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
