// Required modules
import NavBar from "components/Admin/NavBar/NavBar";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  ConfigAdminContainer,
  ConfigObjectsContainer,
  ReturnSettingsPage,
  TitleAdminConfig,
} from "./configAdminStyles";
import { useState } from "react";
import EventsElements from "./typeElemts/pointTypeEvents/EventsElements";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import Layout from "components/Layout/AdminDecimetrix";
import { ShowAllButton2 } from "pages/PlatformSetUp/Preferences/preferencesstyles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export default function ActionsEvents() {
  const navigate = useNavigate();

  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  // Action to execute dialog
  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  // To navigate any page
  const navigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <NavBar linkLogo={"admin"} />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={executeDialog}
        content={contentDialog}
      />

      <Layout>
        <div className="background-1">
          {" "}
          <ConfigObjectsContainer>
            <ShowAllButton2
              style={{ position: "absolute" }}
              onClick={() => navigation("/config-admin")}
            >
              <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
            </ShowAllButton2>

            <TitleAdminConfig>
              <h1>Digital Twin Events Configuration - Points</h1>
              {/* <p>
              This module allows one to design, create, configure, deploy and
              edit the different libraries to be used as the digital Twin Data
              Architecture for Web & Mobile. Begin by selecting the category and
              geometry on the right.
            </p> */}
            </TitleAdminConfig>
          </ConfigObjectsContainer>
          <ConfigAdminContainer className="background-2 border-top-1">
            <EventsElements
              setOpenDialog={setOpenDialog}
              setContentDialog={setContentDialog}
            />
          </ConfigAdminContainer>
        </div>
      </Layout>
    </div>
  );
}
