import Container from "@mui/material/Container";
import NavBar from "components/Admin/NavBar/NavBar";
import { CustomContainer } from "./videoManagerStyles";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import FetchUsersAdminCompany from "hooks/fetchUsersAdminCompany";
import { useNavigate } from "react-router-dom";
import Loading from "components/Lodings/LoadingV2";
import { FROM_TABLE } from "utils/constStrings";
import { ContainerContentTable } from "components/Tables/GenericTable/GenericTableStyle";
import Layout from "components/Layout/AdminDecimetrix";

function createRows(operators) {
  return operators.map((operator) => ({
    id: operator.userId,
    firstName: operator.firstName,
    secondName: operator.secondName,
    firstLastName: operator.firstLastName,
    email: operator.user.email,
    company: operator.company.name,
    jobTitle: operator.jobTitle,
    role: operator.role.role,
    area: operator.area,
  }));
}

const headers = [
  "First Name",
  "Second Name",
  "Last Name",
  "E-mail",
  "Company",
  "Role",
  "Area",
];

const keyValues = [
  "firstName",
  "secondName",
  "firstLastName",
  "email",
  "company",
  "role",
  "area",
];

const VideoManager = () => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { data: adminData, error } = FetchUsersAdminCompany(adminCompanyId);
  const rows = adminData ? createRows(adminData.operators) : null;
  const navigate = useNavigate();

  const handlerClickUser = (userId) => navigate(`/upload-videos/${userId}`);

  return (
    <>
      <Layout>
        <CustomContainer className="background-1">
          <Container maxWidth="xl">
            {adminData ? (
              <div>
                <section className="title">
                  <h1>VIDEO MANAGER</h1>
                  <p className="color1">Videos upload by user</p>
                </section>
                <section className="table-container">
                  {!error && rows && (
                    <ContainerContentTable
                      className="background-2 border-color-1"
                      style={{ minHeight: "280px" }}
                    >
                      <GenericTable
                        headers={headers}
                        rows={rows}
                        keyValues={keyValues}
                        handlerClick={handlerClickUser}
                        state={true}
                        customTableWidth={"100%"}
                        customHeaderWidth={"5%"}
                        from={FROM_TABLE.videoManager}
                      />
                    </ContainerContentTable>
                  )}
                </section>
              </div>
            ) : (
              <Loading />
            )}
          </Container>
        </CustomContainer>
      </Layout>
    </>
  );
};

export default VideoManager;
