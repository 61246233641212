import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { useTooltip } from "./TooltipContext";

const SimpleToolTip = ({ title, children, placement, open }) => {
  const { globalTooltipActive } = useTooltip();
  const { tooltipColor, tooltipFont, tooltipSize } = useTooltip();
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: tooltipColor,
      color: tooltipFont,
      boxShadow: `0 1px 5px ${tooltipColor}`,
      fontSize: tooltipSize,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: tooltipColor,
    },
  }));

  return (
    <>
      {globalTooltipActive && (
        <StyledTooltip title={title} arrow placement={placement} open={open}>
          {children}
        </StyledTooltip>
      )}
      {!globalTooltipActive && children}{" "}
    </>
  );
};

export default SimpleToolTip;
