import { config } from "config";

export const executeChangePassword = async (email) => {
  const body = {
    email: email,
  };
  try {
    const res = await fetch(
      `${config.URL_BACKEND_PG}api/v1/auth/changePassword`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      },
    );
    const response = await res.json();
    return response;
  } catch (error) {
    console.error("Error executinh password change", error);
  }
};
