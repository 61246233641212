import { useState } from "react";
import { Input, Label, WrapperInput, LegendError } from "./FormStyles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const InputComponent = ({
  state,
  changeState,
  type,
  label,
  placeholder,
  name,
  legendError,
  regularExpression,
  func,
}) => {
  const [visibility, setVisibility] = useState(false);
  const [newType, setNewType] = useState(
    type === "password" ? "password" : type
  );

  const onChange = (e) => {
    changeState({ ...state, field: e.target.value });
  };

  const validation = () => {
    if (regularExpression) {
      if (regularExpression.test(state.field)) {
        changeState({ ...state, valid: "true" });
      } else {
        changeState({ ...state, valid: "false" });
      }
    }

    if (func) {
      func();
    }
  };

  if (state.field !== "" && !state.valid) validation();

  const handleShowPassword = () => {
    setVisibility(!visibility);
    if (newType === "password") {
      setNewType("text");
    } else {
      setNewType("password");
    }
  };

  const showEye = (state) => {
    return state ? (
      <VisibilityOffIcon
        className="login-visibility"
        onClick={handleShowPassword}
      />
    ) : (
      <VisibilityIcon
        className="login-visibility"
        onClick={handleShowPassword}
      />
    );
  };

  return (
    <>
      <WrapperInput>
        <Label htmlFor={name} valid={state.valid}>
          {label}
        </Label>
        <div className="input-container">
          <Input
            className="background-1 color1 border-color-1"
            type={newType}
            placeholder={placeholder}
            id={name}
            onChange={onChange}
            onKeyUp={validation}
            onBlur={validation}
            valid={state.valid}
            value={state.field}
          />
          {type === "password" && showEye(visibility)}
        </div>
        <LegendError valid={state.valid}>{legendError}</LegendError>
      </WrapperInput>
    </>
  );
};

export default InputComponent;
