import styled from "styled-components";
import { BACKGROUND_GREY, BORDER_GREY, WHITE_COLOR } from "utils/const";

export const FormContainer = styled.form`
  min-width: 350px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  border: 1px solid ${BORDER_GREY};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  position: relative;
  background-color: ${BACKGROUND_GREY};

  p {
    margin-top: 10px;
    font-size: 1.7rem;
  }
`;
