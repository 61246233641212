import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "utils/const.jsx";

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;
`;

export const Title = styled("div")`
  font-size: 1.4rem;
  margin: 20px 0;
`;

export const ButtonStyle = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  button {
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: ${SECOND_COLOR};
    }
  }
`;
