import React from "react";
import { ContentCardDetail, Fosad } from "./preferencesstyles";

function DetilsCard({ title }) {
  return (
    <>
      <ContentCardDetail className="background-2 border-color-1">
        <div>
          <h1>{title}</h1>
          <div>
            <p>Comming soon...</p>
          </div>
        </div>
      </ContentCardDetail>
    </>
  );
}

export default DetilsCard;
