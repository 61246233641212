import deleteView from "services/deleteBookmarksView.js";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSWR, { useSWRConfig } from "swr";
// custom style
import { WrapperTypeElementTitle } from "./AccordionStyles";
import OpenDialog from "components/Dialogs/OpenDialog";
import { config } from "config.js";
import {
  ButtonsContainer,
  ButtonsMaksCont,
  ViewsTitle,
} from "./AccordionBookmarksViewsStyle";
// components
import DraggableList from "components/DragDrop/DraggableList";
// Services
import { createOrderMapView } from "services/orderMapViews/create";
import { updateMapView } from "services/orderMapViews/update";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { WHITE_COLOR } from "utils/const";
import { ThemeContext } from "App";
/**
 * Renders a list of public and private views and handles their deletion. The function
 * uses the useSWR hook to fetch the data from the backend. When a view is deleted,
 * the function calls the deleteView function to remove the view from the database and
 * calls the mutate function to update the views list. When a view is clicked, the function
 * uses the handleToFly function to move the map to the view's location.
 *
 * @param {Object} map - The map object.
 * @return {JSX.Element} Returns the JSX element that displays the views list.
 */
const BookmarksViews = () => {
  const { mutate } = useSWRConfig();
  const map = useSelector((state) => state.digitalTwinReducer.map);
  const { theme } = useContext(ThemeContext);

  const [confirmationPublic, setConfirmationPublic] = useState(false);
  const [confirmationPrivate, setConfirmationPrivate] = useState(false);

  const [publicViews, setPublicViews] = useState([]);
  const [privateViews, setPrivateViews] = useState([]);

  const [toDelete, setToDelete] = useState(0);

  const userId = parseInt(localStorage.getItem("userId"));

  const { data: userViews, error: errorUserViews } = useSWR(
    `${config.URL_BACKEND_PG}api/v1/views`
  );

  const { data: orderViews, error: errorOrderViews } = useSWR(
    `${config.URL_BACKEND_MG}order-map-views?userId=${userId}`
  );

  useEffect(() => {
    const setOrderViews = () => {
      if (orderViews[0]?.orderPrivate?.length > 0) {
        const currentOrderPrivateViews = userViews?.private?.sort((a, b) => {
          const aIndex = orderViews?.[0]?.orderPrivate.findIndex(
            (item) => item.id === a.id
          );
          const bIndex = orderViews?.[0]?.orderPrivate.findIndex(
            (item) => item.id === b.id
          );
          return aIndex - bIndex;
        });
        setPrivateViews(currentOrderPrivateViews);
      } else {
        setPrivateViews(userViews?.private);
      }

      if (orderViews[0]?.orderPublic?.length > 0) {
        const currentOrderPublicViews = userViews?.public?.sort((a, b) => {
          const aIndex = orderViews?.[0]?.orderPublic.findIndex(
            (item) => item.id === a.id
          );
          const bIndex = orderViews?.[0]?.orderPublic.findIndex(
            (item) => item.id === b.id
          );
          return aIndex - bIndex;
        });
        setPublicViews(currentOrderPublicViews);
      } else {
        setPublicViews(userViews?.public);
      }
    };
    !errorOrderViews && orderViews && setOrderViews();
  }, [userViews, orderViews, errorOrderViews]);

  const saveOrder = async ({ listItems, isPrivate }) => {
    if (orderViews?.length === 0) {
      const adminCompanyId = localStorage.getItem("adminCompanyId");
      await createOrderMapView({
        body: {
          userId: userId,
          adminCompanyId: adminCompanyId,
          orderPrivate: privateViews,
          orderPublic: publicViews,
        },
      });
      mutate(`${config.URL_BACKEND_MG}order-map-views?userId=${userId}`);
    }

    if (orderViews?.[0]?.orderPrivate.length > 0 && isPrivate) {
      await updateMapView({
        id: orderViews[0]._id,
        body: {
          newOrderPrivate: listItems,
        },
      });
    }

    if (orderViews?.[0]?.orderPublic.length > 0 && !isPrivate) {
      await updateMapView({
        id: orderViews[0]._id,
        body: {
          newOrderPublic: listItems,
        },
      });
    }
  };

  function handleOnDragEndPrivateView(result) {
    if (!result.destination) return;

    const listItems = [...privateViews];
    const [reorderedItem] = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, reorderedItem);
    setPrivateViews(listItems);
    saveOrder({ listItems, isPrivate: true });
  }

  function handleOnDragEndPublicView(result) {
    if (!result.destination) return;

    const listItems = [...publicViews];
    const [reorderedItem] = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, reorderedItem);
    setPublicViews(listItems);
    saveOrder({ listItems, isPrivate: false });
  }

  const handleToDeletePublic = async (id) => {
    setToDelete(parseInt(id));
    setConfirmationPublic(true);
  };
  const toDeletePublic = async (id) => {
    await deleteView(id);
    mutate(`${config.URL_BACKEND_PG}api/v1/views`);
    setConfirmationPublic(false);
  };

  const handleToDeletePrivate = async (id) => {
    setToDelete(parseInt(id));
    setConfirmationPrivate(true);
  };
  const toDeletePrivate = async (id) => {
    await deleteView(id);
    mutate(`${config.URL_BACKEND_PG}api/v1/views`);
    setConfirmationPrivate(false);
  };

  const handleToFly = (id, visibility) => {
    const view = visibility
      ? publicViews?.filter((item) => item.id === id)[0]
      : privateViews?.filter((item) => item.id === id)[0];
    const SW = {
      lng: view?.coordinates.bounds[0][0],
      lat: view?.coordinates.bounds[0][1],
    };
    const NE = {
      lng: view?.coordinates.bounds[1][0],
      lat: view?.coordinates.bounds[1][1],
    };
    const bbox = [SW, NE];
    map.getMap().fitBounds(bbox, {
      padding: 10,
      duration: 500,
    });
  };

  return (
    <>
      {!errorUserViews && (
        <List
          sx={{
            height: "100%",
            position: "bottom",
          }}
          subheader={<li />}
        >
          <AccordionDetails>
            <ViewsTitle style={{ marginBottom: "15px" }}>
              Public views ({publicViews && publicViews?.length})
            </ViewsTitle>
            {publicViews && (
              <DraggableList
                items={publicViews}
                // setItems={setPublicViews}
                handleOnDragEnd={handleOnDragEndPublicView}
                itemContent={(item) => (
                  <WrapperTypeElementTitle>
                    <ButtonsContainer className="background-2 border-color-1 align">
                      <DragIndicatorIcon className="color2 drag-icon" />
                      <ButtonsMaksCont
                        style={{
                          color:
                            theme === "light"
                              ? document.body.style.color
                              : WHITE_COLOR,
                        }}
                      >
                        <button
                          onClick={() => handleToFly(item.id, true)}
                          className="buttons-views color1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <OpenInNewIcon />
                        </button>
                        <div className="icon-title">
                          <p>{item?.description}</p>
                        </div>
                        {userId === parseInt(item?.userId) && (
                          <button
                            onClick={() => handleToDeletePublic(item.id)}
                            className="buttons-views button-delete color1"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <DeleteIcon />
                          </button>
                        )}
                      </ButtonsMaksCont>
                    </ButtonsContainer>
                  </WrapperTypeElementTitle>
                )}
              />
            )}
            <OpenDialog
              openDialog={confirmationPublic}
              setOpenDialog={setConfirmationPublic}
              execute={() => toDeletePublic(toDelete)}
              content={{
                title: "Delete view",
                description: "Do you want to delete view?",
                agree: "delete",
                disagree: "cancel",
              }}
              minWidth={"300px"}
            />
          </AccordionDetails>

          <AccordionDetails>
            <ViewsTitle className="color1" style={{ marginBottom: "15px" }}>
              Private views ({privateViews && privateViews?.length})
            </ViewsTitle>
            {privateViews && orderViews && (
              <DraggableList
                items={privateViews}
                // setItems={setPrivateViews}
                handleOnDragEnd={handleOnDragEndPrivateView}
                itemContent={(item) => (
                  <WrapperTypeElementTitle>
                    <ButtonsContainer className="background-2 border-color-1 align">
                      <DragIndicatorIcon className="color2 drag-icon" />
                      <ButtonsMaksCont
                        style={{
                          color:
                            theme === "light"
                              ? document.body.style.color
                              : WHITE_COLOR,
                        }}
                      >
                        <button
                          onClick={() => handleToFly(item?.id, false)}
                          className="buttons-views color1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <OpenInNewIcon />
                        </button>
                        <div className="icon-title">
                          <p>{item?.description}</p>
                        </div>
                        <button
                          onClick={() => handleToDeletePrivate(item?.id)}
                          className="buttons-views button-delete color1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <DeleteIcon />
                        </button>
                      </ButtonsMaksCont>
                    </ButtonsContainer>
                  </WrapperTypeElementTitle>
                )}
              />
            )}
            <OpenDialog
              openDialog={confirmationPrivate}
              setOpenDialog={setConfirmationPrivate}
              execute={() => toDeletePrivate(toDelete)}
              content={{
                title: "Delete view",
                description: "Do you want to delete view?",
                agree: "delete",
                disagree: "cancel",
              }}
              minWidth={"300px"}
            />
          </AccordionDetails>
        </List>
      )}
    </>
  );
};

export default BookmarksViews;
