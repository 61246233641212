import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const Cicleicon = () => {
  return (
    <>
      <svg
        id="Layer_3"
        data-name="Layer 3"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
        style={{ padding: "2rem" }}
      >
        <defs>
          <style>
            {`
      .cls-1c {
        fill: var(--background-primary_color);
      }

      .cls-1c, .cls-2c {
        stroke-width: 0px;
      }

      .cls-2c {
        fill: var(--background-secondary_color);
      }`}
          </style>
        </defs>
        <path
          class="cls-2c"
          d="m216.86,346.54c-69.79,0-126.36-56.57-126.36-126.36,0-25.01,7.3-48.3,19.83-67.92l-53.36-48.09c-23.68,32.57-37.66,72.65-37.66,116.01,0,109.1,88.45,197.55,197.55,197.55,34.54,0,67-8.87,95.24-24.45l-.1.06,14.69,24.39v-33.33l-.07.04s.05-.03.07-.05v-101.71c-21.75,38.14-62.79,63.86-109.84,63.86Z"
        />
        <path
          class="cls-1c"
          d="m220.89,87.18c69.79-.19,126.51,56.24,126.69,126.02.07,25.01-7.17,48.32-19.65,67.97l53.49,47.95c23.59-32.64,37.46-72.75,37.35-116.11-.29-109.1-88.97-197.31-198.07-197.02-34.54.09-66.97,9.05-95.18,24.7l.1-.06-14.76-24.35.09,33.33.07-.04s-.05.03-.07.05l.27,101.71c21.65-38.2,62.62-64.02,109.67-64.15Z"
        />
      </svg>
    </>
  );
};

export default Cicleicon;
