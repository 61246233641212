import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR, BLACK_COLOR } from "../../utils/const";

export const Nav = styled.nav`
  background-color: ${PRIMARY_COLOR};
  height: 80px;
  /* margin-top: -80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000066;
  font-size: 1.4rem;
  position: sticky;
  top: 0;
  z-index: 10;
  width: ${(props) => (props.tipo ? "90%" : "100%")};
  margin-left: ${(props) => (props.tipo ? "10%" : "0%")};
  
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 1;
  width: 100%;
  
  /* padding: 0 24px; */
`;

export const BoxNav = styled.div`
  align-items: left;
  
  width: 50%;
  /* padding: 0 24px; */
  
`;

export const NavbarLogoS = styled(LinkS)`
  color: ${WHITE_COLOR};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const NavbarLogoR = styled(LinkR)`
  color: ${WHITE_COLOR};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const Img = styled.img`
  width: 200px;
  height: auto;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0.6rem;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.4rem;
    cursor: pointer;
    color: ${WHITE_COLOR};
  }
`;

export const NavInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: ${(props) => (props.tipo ? "0" : "0%")};
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: ${({ sidebar }) => (sidebar ? "none" : null)};
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinkS = styled(LinkS)`
  color: ${WHITE_COLOR};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 1.4rem;

  &.active {
    border-bottom: 3px solid ${SECOND_COLOR};
  }

  &:hover {
    color: ${SECOND_COLOR};
    border-bottom: 3px solid ${SECOND_COLOR};
  }
`;

export const NavLinkR = styled(LinkR)`
  color: ${WHITE_COLOR};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #222729;
  }

  &:hover {
    color: #222729;
    border-bottom: 3px solid #222729;
  }
`;

export const NavBtn = styled.nav`
  display: flex;

  align-items: center;

  @media screen and (max-width: 768px) {
    display: ${({ sidebar }) => (sidebar ? "none" : null)};
  }
`;

export const NavBtnLink = styled.button`
  border-radius: 50px;
  background: ${BLACK_COLOR};
  padding: 10px 22px;
  color: ${WHITE_COLOR};
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${BLACK_COLOR};
    background: ${WHITE_COLOR};
  }
`;
