import axios from "axios";
import { config } from "config.js";

export const deleteObjectsByIds = async (data) => {
  // ids = [23, 345, 456]
  // relations = true (only objects relations)
  const { ids, relations } = data;
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_PG}api/v1/objects/bulk-delete`;

  const response = await axios.delete(url, {
    data: {
      ids: ids,
      relations,
    },
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  return response.data;
};
