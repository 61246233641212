// React
import { useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
// Components
import { customStyles } from "components/Drawers/DrawerPolygonTable/styles";
import OpenDialog from "components/Dialogs/OpenDialog";
// Services
import deleteViewTableColumns from "services/deleteViewTableColumns";
// Dependencies
import Select from "react-select";
import useSwr from "swr";
import { useSWRConfig } from "swr";
// Styles
import CancelIcon from "@mui/icons-material/Cancel";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
// Config-utils
import { BLACK_COLOR, PRIMARY_COLOR } from "utils/const";
import { config } from "config.js";
import { roles } from "utils/roles";

const SelectViewTable = ({
  setCurrentNameViewColumnOrder,
  setCurrentViewColumnOrder,
  typeOfLibrary,
  typeOfTable,
  currentNameviewColumnOrder,
  setUseOrderColumns,
  setSelectedFiltersByColumnOrder,
  setUseFilter,
  handlerSetValue,
}) => {
  const { mutate } = useSWRConfig();
  const originalOrderColumns = useSelector(
    (state) => state.digitalTwinReducer.originalOrderViewColumns
  );
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");

  const [allViewColumns, setAllViewColumns] = useState([]);

  const [openDialogDeleteView, setOpenDialogDeleteView] = useState(false);
  const [nameViewDelete, setNameViewDelete] = useState("");

  const { data: orderColumnsMg, error: errorOrderColumns } = useSwr(
    `${config.URL_BACKEND_MG}order-columns/${userId}?adminCompanyId=${adminCompanyId}&typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`
  );

  const [view, setView] = useState(null);
  const [currentNameView, setCurrentNameView] = useState("Default Table View");

  useEffect(() => {
    setCurrentNameView(currentNameviewColumnOrder);
  }, [currentNameviewColumnOrder]);

  useEffect(() => {
    if (
      !errorOrderColumns &&
      orderColumnsMg &&
      orderColumnsMg.length !== originalOrderColumns.length
    ) {
      originalOrderColumns.forEach((column) => {
        orderColumnsMg.forEach((view) => {
          if (!view.orderColumns.includes(column)) {
            view.orderColumns.push(column);
          }
        });
      });
    }
  }, [orderColumnsMg, originalOrderColumns]);

  useEffect(() => {
    if (!errorOrderColumns && orderColumnsMg && orderColumnsMg.length > 0) {
      setAllViewColumns([
        { name: "Default Table View", orderColumnsMg: originalOrderColumns },
        ...orderColumnsMg,
      ]);
    } else {
      setAllViewColumns([
        { name: "Default Table View", orderColumnsMg: originalOrderColumns },
      ]);
    }
  }, [
    orderColumnsMg,
    errorOrderColumns,
    originalOrderColumns,
    typeOfLibrary,
    typeOfTable,
    setAllViewColumns,
    currentNameviewColumnOrder,
  ]);

  const handleChangeView = async (view) => {
    await handlerSetValue();
    const url = `${config.URL_BACKEND_MG}order-columns/${userId}?adminCompanyId=${adminCompanyId}&typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`;
    mutate(url);
    if (view.selectedFilters?.length > 0) {
      setUseFilter(true);
      setSelectedFiltersByColumnOrder(view.selectedFilters);
    } else {
      setSelectedFiltersByColumnOrder([]);
      setUseFilter(false);
    }
    await setCurrentViewColumnOrder(view.value);
    // Para renderizar el valor de "label" sin mostrar laS etiquetas de iconos.
    await setCurrentNameView(view.label.props.children[0].props.children[1]);
    await setCurrentNameViewColumnOrder(
      view.label.props.children[0].props.children[1]
    );

    await setUseOrderColumns(false);
  };

  const handleDeleteView = async () => {
    setCurrentViewColumnOrder(originalOrderColumns);
    setCurrentNameView("Default Table View");
    setCurrentNameViewColumnOrder("Default Table View");
    await deleteViewTableColumns({
      nameView: nameViewDelete,
      typeOfLibrary,
      typeOfTable,
    });
    setAllViewColumns([]);
    const url = `${config.URL_BACKEND_MG}order-columns/${userId}?adminCompanyId=${adminCompanyId}&typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`;
    mutate(url);
    setOpenDialogDeleteView(false);
  };

  const handleOpenDialog = ({ nameView }) => {
    setNameViewDelete(nameView);
    setOpenDialogDeleteView(true);
  };

  return (
    <div>
      <OpenDialog
        openDialog={openDialogDeleteView}
        setOpenDialog={setOpenDialogDeleteView}
        execute={() => handleDeleteView()}
        content={{
          title: "Delete view",
          description: `Do you want to delete table view ${nameViewDelete}?`,
          agree: "delete",
          disagree: "cancel",
        }}
        maxWidth={"200px"}
        minWidth={"500px"}
      />
      <div
        style={{
          fontSize: "15px",
          height: "auto",
          width: "220px",
          display: "flex",
        }}
      >
        <Select
          onChange={handleChangeView}
          options={
            allViewColumns?.length > 0 &&
            allViewColumns?.map((view) => {
              return {
                value: view.orderColumns,
                selectedFilters: view.selectedFilters,
                label: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "-2px",
                      }}
                    >
                      {view?.userId !== null &&
                      view.name !== "Default Table View" ? (
                        <LockIcon
                          sx={{ mr: "3px", color: `${PRIMARY_COLOR}` }}
                        />
                      ) : view.adminCompanyId !== null ||
                        view.name === "Default Table View" ? (
                        <LockOpenIcon
                          sx={{
                            mr: "3px",
                            color:
                              view.name === "Default Table View"
                                ? BLACK_COLOR
                                : PRIMARY_COLOR,
                          }}
                        />
                      ) : null}

                      {view.name}
                    </div>
                    {view.name !== "Default Table View" &&
                      (view?.userId === userId ||
                        role === roles.companyAdmin) && (
                        <CancelIcon
                          sx={{
                            color: "red",
                            width: "15px",
                            height: "auto",
                            cursor: "pointer",
                            transition: "color 0.3s",
                            "&:hover": {
                              color: "darkred",
                            },
                          }}
                          onClick={() =>
                            handleOpenDialog({ nameView: view.name })
                          }
                        />
                      )}
                  </div>
                ),
              };
            })
          }
          value={view}
          placeholder={
            currentNameView.split(" ").length > 4 ||
            currentNameView.split(" ").some((word) => word.length > 10)
              ? currentNameView
                  .split(" ")
                  .slice(0, 4)
                  .map((word) =>
                    word.length > 10 ? word.slice(0, 10) + "..." : word
                  )
                  .join(" ") + "..."
              : currentNameView
          }
          styles={customStyles}
          menuPlacement="auto"
        />
      </div>
    </div>
  );
};

export default SelectViewTable;
