import { useState } from "react";
// Components
import NavBar from "components/NavBar";
import Terms from "../ShowData";
import LinksPrivacy from "./LinksPrivacy";
// Styles
import { Container } from "../indexStyles";
// Data
import { dataSocratesPol } from "../PrivacyPolicyData/policySocratesData";

const Index = () => {
  const configNavbar = {
    sidebar: true,
    typeLinks: "scroll",
    linkLogo: "/",
    menuElements: {},
    button: {
      link: "signIn",
      text: "Log In",
    },
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavBar toggle={toggle} configNavbar={configNavbar} />
      <Container className="background-1">
        <LinksPrivacy />
        <Terms data={dataSocratesPol} />
      </Container>
    </>
  );
};

export default Index;
