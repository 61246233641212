import { config } from "config";
import { createContext, useContext, useEffect, useState } from "react";
import {
  createBackendPreferences,
  updateBackendPreferences,
} from "services/updateBackendPreferences";

import useSwr from "swr";
import { useSWRConfig } from "swr";
import { PRIMARY_COLOR, SECOND_COLOR, SECOND_COLOR_TRANS } from "utils/const";

const ThemeContext = createContext();

const getColorName = (color) => {
  switch (color) {
    case PRIMARY_COLOR:
      return "Green Dragon";
    case "#f7a21b":
      return "Orange Dragon";
    case "#cf1616":
      return "Red Dragon";
    case "#575757":
      return "White Dragon";
    default:
      return "Decimetrix";
  }
};

export const useTheme = () => useContext(ThemeContext);

export const ThemeData = ({ children }) => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");

  const [primaryColor, setPrimaryColor] = useState(PRIMARY_COLOR);
  const [secondaryColor, setSecondaryColor] = useState(SECOND_COLOR);
  const [transColor, setTransColor] = useState(SECOND_COLOR_TRANS);

  const [colorName, setColorName] = useState(getColorName(primaryColor));

  const { mutate } = useSWRConfig();

  const url = `${config.URL_BACKEND_MG}config-preferences?adminCompanyId=${adminCompanyId}`;
  const { data: configData, error: errorConfigData } = useSwr(url);
  useEffect(() => {
    if (adminCompanyId && userId) {
      const initializeTheme = async () => {
        if (!errorConfigData && configData && configData.length > 0) {
          const { theme, secondaryTheme, transTheme } = configData[0].theme;
          document.body.style.setProperty("--background-primary_color", theme);
          document.body.style.setProperty(
            "--background-secondary_color",
            secondaryTheme
          );
          document.body.style.setProperty(
            "--background-trans_color",
            transTheme
          );
          // setPrimaryColor(theme);
          // setSecondaryColor(secondaryTheme);
          // setTransColor(transTheme);
          setColorName(getColorName(theme));
        } else {
          const { data, response } = await createBackendPreferences({
            body: {
              adminCompanyId,
              userId,
              theme: {
                theme: PRIMARY_COLOR,
                secondaryTheme: SECOND_COLOR,
                transTheme: SECOND_COLOR_TRANS,
              },
            },
          });

          if (data.theme) {
            setPrimaryColor(data.theme.theme);
            setSecondaryColor(data.theme.secondaryTheme);
            setTransColor(data.theme.transTheme);
            setColorName(getColorName(data.theme.theme));
          }
        }
      };
      initializeTheme();
    } else {
      document.body.style.setProperty(
        "--background-primary_color",
        PRIMARY_COLOR
      );
      document.body.style.setProperty(
        "--background-secondary_color",
        SECOND_COLOR
      );
      document.body.style.setProperty(
        "--background-trans_color",
        SECOND_COLOR_TRANS
      );

      setPrimaryColor({
        theme: PRIMARY_COLOR,
        secondaryTheme: SECOND_COLOR,
        transTheme: SECOND_COLOR_TRANS,
      });
      setSecondaryColor(SECOND_COLOR);
      setTransColor(SECOND_COLOR_TRANS);
      setColorName(
        getColorName({
          theme: PRIMARY_COLOR,
          secondaryTheme: SECOND_COLOR,
          transTheme: SECOND_COLOR_TRANS,
        })
      );
    }
  }, [errorConfigData, configData, userId, adminCompanyId]);

  const handleThemeColor = async (primary, secondary, trans) => {
    try {
      document.body.style.setProperty("--background-primary_color", primary);
      document.body.style.setProperty(
        "--background-secondary_color",
        secondary
      );
      document.body.style.setProperty("--background-trans_color", trans);

      setPrimaryColor(primary);
      setSecondaryColor(secondary);
      setTransColor(trans);
      setColorName(getColorName(primary));

      await updateBackendPreferences({
        id: configData[0]._id,
        body: {
          ...configData[0],
          theme: {
            theme: primary,
            secondaryTheme: secondary,
            transTheme: trans,
          },
        },
      });
      mutate(url);
    } catch (error) {
      console.error("Failed to update theme color:", error);
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        handleThemeColor,
        primaryColor,
        secondaryColor,
        transColor,
        colorName,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
