// Style
import { ConfigObjectsContainer } from "./indexStyle";
// Hooks
import useFindObjects from "hooks/objects/useFindObjects";
// Components
import Loading from "components/Lodings/Loading";
import FormText from "pages/Dashboard/components/FormText";
import FormLineChart from "pages/Dashboard/components/FormLineChart";
import ObjectFormKpi from "pages/Dashboard/components/ObjectFormKpi";
import ObjectFormBar from "pages/Dashboard/components/ObjectFormBar";
import ObjectFormDoughnut from "pages/Dashboard/components/ObjectFormDoughnut";
import FormTimeChart from "pages/Dashboard/components/FormTimeChart";
import FormGroupBarChart from "pages/Dashboard/components/FormGroupBarChart";
import FormTimeSeriesBarChart from "pages/Dashboard/components/FormTimeSeriesBarChart";
import FormImgChart from "pages/Dashboard/components/FormImgChart";
import ObjectFormGroupDoughnut from "pages/Dashboard/components/ObjectFormGroupDoughnut";

const ConfigObjects = ({ chartType, setOpenDialog }) => {
  // Fetch objects data;
  const { objects, loading } = useFindObjects();

  return (
    <ConfigObjectsContainer>
      {objects?.length > 0 && (
        <section>
          <div className="conatiner-add-chart">
            <h1 className="title">Chart settings</h1>
            {/* Kpi chart */}
            {chartType === "kpiChart" && (
              <ObjectFormKpi setOpenDialog={setOpenDialog} />
            )}
            {/* Bar chart */}
            {chartType === "barChart" && (
              <ObjectFormBar setOpenDialog={setOpenDialog} />
            )}
            {/* Doughnut chart */}
            {chartType === "doughnutChart" && (
              <ObjectFormDoughnut setOpenDialog={setOpenDialog} />
            )}
            {chartType === "groupDoughnutChart" && (
              <ObjectFormGroupDoughnut setOpenDialog={setOpenDialog} />
            )}
            {/* Line Chart */}
            {chartType === "lineChart" && (
              <FormLineChart setOpenDialog={setOpenDialog} />
            )}
            {/* Time Series Chart */}
            {chartType === "timeChart" && (
              <FormTimeChart setOpenDialog={setOpenDialog} />
            )}
            {/* Group Bar Chart */}
            {chartType === "groupBarChart" && (
              <FormGroupBarChart setOpenDialog={setOpenDialog} />
            )}
            {/* Text chart */}
            {chartType === "textChart" && (
              <FormText from={"objects"} setOpenDialog={setOpenDialog} />
            )}
            {/* Time Series Bar Chart */}
            {chartType === "timeSeriesBarChart" && (
              <FormTimeSeriesBarChart setOpenDialog={setOpenDialog} />
            )}
            {chartType === "imgChart" && (
              <FormImgChart from={"objects"} setOpenDialog={setOpenDialog} />
            )}
          </div>
        </section>
      )}
      {loading && <Loading height={"auto"} text={"Loading..."} />}
    </ConfigObjectsContainer>
  );
};

export default ConfigObjects;
