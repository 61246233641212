import styled from "styled-components";
// Constants
import {
  ICON_GREEN_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  BORDER_GREY,
  GRAY_COLOR,
} from "utils/const";

export const ConfigObjectsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    margin: 20px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  .subtitle {
    margin: 10px 0;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "25px")};
    font-weight: 500;
    text-align: left;
  }

  .section_title {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
  }

  .collapsible-section {
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
  }

  .section-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .section-content.expanded {
    max-height: 1000px; /* Ajusta este valor según sea necesario */
    transition: max-height 0.5s ease-in;
  }

  .container-type-charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto;
    gap: 10px;

    input:required:invalid {
      border: 1px solid red;
    }
  }

  .card-type-chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: auto;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    text-align: center;

    &:hover {
      background-color: ${ICON_GREEN_COLOR};
    }
  }

  .conatiner-add-chart {
    height: auto;
    margin: 20px 0;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
  }

  input[type="color"] {
    border: 1px solid ${BLACK_COLOR};
    width: 100%;
    height: 37px;
    background-color: ${WHITE_COLOR};
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
`;
