import axios from "axios";
import { config } from "config.js";

const deletePolygonRelations = async (polygonId) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/region/${polygonId}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error in deletePolygonRelations:", error);
    throw error;
  }
};

const deletePolygon = async (polygonId) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.delete(
      `${config.URL_BACKEND_MG}region?id=${polygonId}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = res.data;
    const response = await deletePolygonRelations(data._id);
    return response;
  } catch (error) {
    console.error("Error in deletePolygon:", error);
    throw error;
  }
};

export default deletePolygon;
