import BarChart from "components/Charts/BarChart";
import DonutChart from "components/Charts/DonutChart";
import KpiChart from "components/Charts/KpiChart";
import { LineChart } from "components/Charts/Line";
import TextChart from "components/Charts/TextChart";
import { useEffect, useState } from "react";
import { GRAY_SECOND } from "utils/const";
import { TimeSeriesChart } from "components/Charts/TimeSeriesChart";

const RestoreCharts = ({
  dataChart,
  currentOrderViews,
  setCurrentOrderViews,
  currentPositionId,
  orderId,
  currentOrderName,
  template,
  setOpenDialogRestoreChart,
}) => {
  const [itemsDeleted, setItemsDeleted] = useState([]);

  useEffect(() => {
    const deleteData = dataChart
      .filter((elm) => !currentOrderViews.some((item) => elm._id === item.id))
      .map((elm) => {
        return {
          ...elm,
          id: elm._id,
          sizeChart: {
            width: 300,
            height: 200,
          },
        };
      });
    setItemsDeleted(deleteData);
  }, [currentOrderViews, dataChart]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        maxWidth: "100%",
      }}
    >
      {itemsDeleted.map((item) => {
        return (
          <div
            style={{
              position: "relative",
              maxWidth: `${item.sizeChart.width}px`,
              maxHeight: `${item.sizeChart.height}px`,
              overflow: "hidden",
              margin: "10px",
            }}
          >
            {item.chartType === "kpiChart" && (
              <KpiChart
                dataKpi={item}
                borderColor={GRAY_SECOND}
                currentStyle={{
                  width: item.sizeChart.width,
                  height: item.sizeChart.height,
                }}
                restore={true}
                currentPositionId={currentPositionId}
                orderId={orderId}
                currentOrderName={currentOrderName}
                template={template}
                currentOrderViews={currentOrderViews}
                setCurrentOrderViews={setCurrentOrderViews}
                setOpenDialogRestoreChart={setOpenDialogRestoreChart}
              />
            )}
            {item.chartType === "textChart" && (
              <TextChart
                dataChart={item}
                borderColor={GRAY_SECOND}
                currentStyle={{
                  width: item.sizeChart.width,
                  height: item.sizeChart.height,
                }}
                restore={true}
                currentPositionId={currentPositionId}
                orderId={orderId}
                currentOrderName={currentOrderName}
                template={template}
                currentOrderViews={currentOrderViews}
                setCurrentOrderViews={setCurrentOrderViews}
                setOpenDialogRestoreChart={setOpenDialogRestoreChart}
              />
            )}
            {item.chartType === "barChart" && (
              <BarChart
                dataChart={item}
                borderColor={GRAY_SECOND}
                currentStyle={{
                  width: item.sizeChart.width,
                  height: item.sizeChart.height,
                }}
                restore={true}
                currentPositionId={currentPositionId}
                orderId={orderId}
                currentOrderName={currentOrderName}
                template={template}
                currentOrderViews={currentOrderViews}
                setCurrentOrderViews={setCurrentOrderViews}
                setOpenDialogRestoreChart={setOpenDialogRestoreChart}
              />
            )}
            {item.chartType === "lineChart" && (
              <LineChart
                dataChart={item}
                borderColor={GRAY_SECOND}
                currentStyle={{
                  width: item.sizeChart.width,
                  height: item.sizeChart.height,
                }}
                restore={true}
                currentPositionId={currentPositionId}
                orderId={orderId}
                currentOrderName={currentOrderName}
                template={template}
                currentOrderViews={currentOrderViews}
                setCurrentOrderViews={setCurrentOrderViews}
                setOpenDialogRestoreChart={setOpenDialogRestoreChart}
              />
            )}
            {item.chartType === "doughnutChart" && (
              <DonutChart
                dataChart={item}
                borderColor={GRAY_SECOND}
                currentStyle={{
                  width: item.sizeChart.width,
                  height: item.sizeChart.height,
                }}
                restore={true}
                currentPositionId={currentPositionId}
                orderId={orderId}
                currentOrderName={currentOrderName}
                template={template}
                currentOrderViews={currentOrderViews}
                setCurrentOrderViews={setCurrentOrderViews}
                setOpenDialogRestoreChart={setOpenDialogRestoreChart}
              />
            )}
            {item.chartType === "timeSeriesChart" && (
              <TimeSeriesChart
                dataChart={item}
                borderColor={GRAY_SECOND}
                currentStyle={{
                  width: item.sizeChart.width,
                  height: item.sizeChart.height,
                }}
                restore={true}
                currentPositionId={currentPositionId}
                orderId={orderId}
                currentOrderName={currentOrderName}
                template={template}
                currentOrderViews={currentOrderViews}
                setCurrentOrderViews={setCurrentOrderViews}
                setOpenDialogRestoreChart={setOpenDialogRestoreChart}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RestoreCharts;
