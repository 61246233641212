function hexToRGB(hex) {
  // Remover el símbolo '#' si está presente
  hex = hex.replace(/^#/, "");

  // Convertir valores de 3 dígitos a 6 dígitos (ejemplo: 'abc' -> 'aabbcc')
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // El valor hexadecimal es válido y tiene 6 caracteres
  if (hex.length !== 6) {
    throw new Error("El valor hexadecimal debe tener 6 caracteres");
  }

  // Convertir a valores RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export default hexToRGB;
