import { getNumericValue } from "helpers/getNumericValue";

export const count = ({ components, kpiChart }) => {
  const charts = kpiChart.map((chart) => {
    const filteredComponents = components.filter((cmp) => {
      // Filtrar por libraryId y pointLibraryId
      if (cmp.pointTypeComponent.pointLibraryComponentId !== chart.libraryId) {
        return false;
      }

      // Filtrar por los valores de columnsValuesFilter
      for (const filter of chart.columnsValuesFilter) {
        const attribute = cmp.attributes.find(
          (attr) => attr.name === filter.name
        );
        // Comprobar que la metrica existe en los atributos
        // if (!cmp.attributes.find((attr) => attr.name === chart.metric)) {
        //   return false;
        // }
        // Filtrar por valor del attributo
        if (!attribute || !filter.values.includes(attribute.value)) {
          return false;
        }
      }

      return true;
    });

    return {
      ...chart,
      count: filteredComponents.length,
    };
  });

  return charts;
};

export const sum = ({ components, kpiChart }) => {
  const charts = kpiChart.map((chart) => {
    // Inicializar el total de la suma
    let totalSum = 0;

    // Iterar sobre cada objeto en la matriz components
    components.forEach((cmp) => {
      // Filtrar por libraryId y pointLibraryId
      if (cmp.pointTypeComponent.pointLibraryComponentId === chart.libraryId) {
        // Verificar si el objeto cumple con los criterios de filtrado
        let filtered = true;
        for (const filter of chart.columnsValuesFilter) {
          const attribute = cmp.attributes.find(
            (attr) => attr.name === filter.name
          );
          if (!attribute || !filter.values.includes(attribute.value)) {
            filtered = false;
            break;
          }
        }

        // Si el objeto pasa los criterios de filtrado, sumar su valor number01
        if (filtered) {
          const value = cmp.attributes.find(
            (attr) => attr.name === chart.metric
          )?.value;

          const numValue = getNumericValue(value);

          totalSum += numValue;
        }
      }
    });

    return {
      ...chart,
      count: totalSum,
    };
  });

  return charts;
};
