const handleRemoveGridColumn = ({
  index,
  setDragAndDroll,
  setCurrentTemplate,
}) => {
  setDragAndDroll(true);
  setCurrentTemplate((current) => ({
    ...current,
    chartsPosition: current.chartsPosition.map((elm) => {
      if (elm.index === index) {
        return {
          ...elm,
          gridColumnStart:
            elm.gridColumnStart[5] > 1
              ? `span ${parseInt(elm.gridColumnStart.split(" ")[1]) - 1}`
              : elm.gridColumnStart,
        };
      }
      return elm;
    }),
  }));
};

export default handleRemoveGridColumn;
