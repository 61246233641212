import axios from "axios";
import { config } from "config.js";

const findOperations = async (operationId) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_PG}api/v1/operation`;
  if (operationId) url = `${url}/${operationId}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default findOperations;
