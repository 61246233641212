import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AiFillCloseCircle } from "react-icons/ai";
import { HeadModal } from "./DialogsStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SignUp from "../Forms/AdminCompanyRegister";
import { WHITE_COLOR } from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor, StyleColor2 } from "Styles";

//form for admin decimetrix aqui esta el propio formulario
export default function FormDialog({ open, handleClose }) {
  const theme2 = useTheme();
  const fullScreen = useMediaQuery(theme2.breakpoints.down("md"));
  const { theme } = useContext(ThemeContext);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "1200px",
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            padding: "0px",
          }}
        >
          <HeadModal>
            <div className="title">
              <img
                src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
                alt=""
                className="neurona"
              />
              <span className="titleHeadModal">Administrator Registration</span>
            </div>
            <Button onClick={handleClose}>
              <AiFillCloseCircle style={{ color: WHITE_COLOR, fontSize: 21 }} />
            </Button>
          </HeadModal>
        </DialogTitle>
        <DialogContent
          style={{
            background: StyleColor(theme),
            color: StyleColor2(theme),
          }}
        >
          <SignUp handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
