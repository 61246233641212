import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import {
  BLACK_COLOR,
  WHITE_BACKGROUND,
  BORDER_GREY,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "utils/const";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export const HubContainer = styled.div`
  min-height: calc(100vh - 45rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 0;
  justify-content: flex-start;
  @media (max-width: 768px) {
    padding-top: 10rem;
    min-height: unset;
  }
`;

export const ContainerIntro = styled.div`
  padding: 4rem 1rem 5.2rem 1rem;
  display: grid;
  grid-template-columns: 0.25fr 3fr 1fr;
  margin-right: 70px;
  width: 100%;
  background: ${PRIMARY_COLOR};
`;

export const LogoCont = styled.div`
  display: flex;

  &.miniLogo {
    margin-left: 20px;
    margin-right: 50px;
  }

  &.maxLogo {
    justify-content: center;
  }
  @media (max-width: 768px) {
    &.maxLogo {
      display: none;
      width: 90px;
      height: 90px;
    }
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const LogoSmall = styled.img`
  width: 50px;
  height: 50px;
`;

export const LogoBig = styled.div`
  background-image: url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png");
  background-size: cover;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  justify-self: center;

  grid-column: 3;

  @media (max-width: 768px) {
    width: 90px;
    height: 90px;
    display: none;
  }
`;

export const LastConteiner = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 3fr 1fr;

  @media (max-width: 1190px) {
    display: flex;
    padding: 1rem 0;
    justify-content: flex-end;
  }
`;

export const UserH2Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem;

  font-size: 17px;
  background: ${WHITE_BACKGROUND};
  /* color: ${BLACK_COLOR}; */

  b {
    color: var(--background-primary_color);
  }

  grid-column: 3;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const NavInfo = styled.div`
  margin-top: 13%;
  margin-left: 15%;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const Nav = styled.nav`
  background-color: ${PRIMARY_COLOR};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000066;
  font-size: 1.4rem;
  position: sticky;
  top: 0;
  z-index: 10;
  width: ${(props) => (props.tipo ? "90%" : "100%")};
  margin-left: ${(props) => (props.tipo ? "10%" : "0%")};

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 89%;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  cursor: pointer;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const LeftArrow = styled(ArrowLeftIcon)`
  color: ${PRIMARY_COLOR};
  margin: 10px;

  &:hover {
    color: ${SECOND_COLOR};
  }
`;

export const RightArrow = styled(ArrowRightIcon)`
  color: ${PRIMARY_COLOR};
  margin: 10px;

  &:hover {
    color: ${SECOND_COLOR};
  }
`;

export const HubWrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  align-items: center;
  width: 100%;

  &.scroll-style {
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x proximity;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &.wrap-style {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const HubCard = styled.div`
  background-color: ${WHITE_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 350px;
  width: 320px;
  margin: 20px 20px;
  padding: 30px;
  transition: all 0.2s ease-in-out;
  border: solid 1px ${BORDER_GREY};

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin-right: 15px;
    margin-left: 7px;
  }

  user-select: none;
`;

export const HubIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const HubH1 = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  color: ${PRIMARY_COLOR};
  margin-bottom: 20px;
  text-align: justify;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const HubH2 = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${BLACK_COLOR};
`;

export const HubD = styled.p`
  font-size: 18px;
  text-align: justify;
  /* color: ${BLACK_COLOR}; */
`;

export const HubP = styled.p`
  font-size: 18px;
  text-align: center;
  /* color: ${BLACK_COLOR}; */
`;

export const UserInfoReport = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-auto-rows: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  border: 2px solid #ececec;
  border-radius: 15px;
  padding: 1.2rem 0;
  margin-top: 3rem;
`;

export const UserContainer = styled.div`
  border: 2px solid pink;
  height: 800px;
  padding-left: 20px;
  padding-right: 70px;
  margin-top: 70px;
`;

export const UserH1 = styled.h1`
  margin-bottom: 2px;
  font-size: 3rem;
  font-weight: 500;
  color: ${BLACK_COLOR};
`;

export const UserH2 = styled.text`
  font-size: 2.2rem;
  color: ${BLACK_COLOR};
  margin-bottom: 15px;
`;

export const ReportItem = styled.div`
  font-size: 2rem;
  padding: 3px;
  background-color: ${WHITE_SECOND_COLOR};
  border-radius: 0;
  align-self: center;
  justify-self: center;
`;

export const NavbarLogoR = styled(LinkR)`
  color: ${WHITE_COLOR};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;
