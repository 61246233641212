import { PRIMARY_COLOR } from "utils/const";

const CarbonChartBarStacked = ({ color = PRIMARY_COLOR, size = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        d="M4 28v-3h22v-8H4v-4h14V5H4V2H2v26a2 2 0 0 0 2 2h26v-2Zm20-5H14v-4h10Zm-8-12h-6V7h6Z"
      />
    </svg>
  );
};

export default CarbonChartBarStacked;
