import moment from "moment-timezone";

const removeLineBreak = (text) => {
  if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
  if (text && isNaN(text) && typeof text !== "object") {
    return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
  }
  return text;
};

export const createRowsEvents = ({ events, library = null }) => {
  let newDataArray = events;
  if (library) {
    newDataArray = events?.map((dataItem) => {
      let attributesCopy = [...dataItem.attributes];

      const res = attributesCopy.map((newData) => {
        const elm = library?.fields?.find((item) => {
          return (
            item?.name === newData?.name ||
            item?.alias === newData?.name ||
            item?.name === newData?.alias ||
            item?.alias === newData?.alias
          );
        });
        if (elm) {
          if (elm.type !== "captureMedia" && elm.type !== "notification") {
            if (elm.type === "number" || elm.type === "operation") {
              if (newData.value && newData.value[0] === "{") {
                const valueParse = JSON.parse(newData.value);
                if (valueParse.value === "") {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: NaN,
                    _id: newData._id,
                  };
                } else {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: valueParse.value,
                    _id: newData._id,
                  };
                }
              } else {
                if (newData.value && !isNaN(newData.value)) {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: parseFloat(newData.value),
                    _id: newData._id,
                  };
                }
              }
            } else {
              if (elm.type === "date" && !newData.value === !NaN) {
                return {
                  name: elm.name,
                  alias: elm.alias,
                  value: "0000-00-00",
                  _id: newData._id,
                };
              } else {
                if (!newData.value === !NaN || newData.value === " ") {
                  if (elm.type === "date") {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: "0000-00-00",
                      _id: newData._id,
                    };
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: "Undefined data",
                      _id: newData._id,
                    };
                  }
                } else {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: newData.value,
                    _id: newData._id,
                  };
                }
              }
            }
          }
        }
        return {
          ...newData,
          alias: newData.name,
        };
      });

      const allFields = library?.fields
        ?.map((elm) => {
          if (elm.type !== "captureMedia" && elm.type !== "notification") {
            return {
              name: elm.name,
              alias: elm.alias,
              type: elm.type,
            };
          }
          return null;
        })
        .filter((it) => it !== undefined && it !== null);

      const matchMissingAttributes = allFields?.filter((elm) => {
        return res.every((item) => {
          return item.name !== elm.name;
        });
      });

      if (matchMissingAttributes?.length > 0) {
        matchMissingAttributes.forEach((elm) => {
          if (elm.type === "date") {
            res.push({
              name: elm.name,
              alias: elm.alias,
              value: "0000-00-00",
            });
          } else if (elm.type === "number" || elm.type === "operation") {
            res.push({
              name: elm.name,
              alias: elm.alias,
              value: NaN,
            });
          } else {
            res.push({
              name: elm.name,
              alias: elm.alias,
              value: "Undefined data",
            });
          }
        });
      }

      return { ...dataItem, attributes: res };
    });
  }

  const rows = newDataArray.map((item) => {
    const convertedTime = moment
      .tz(item.date, "UTC")
      .utcOffset(item.timeZoneOffset || "-05:00")
      .tz(item.timeZone || "America/Bogota");

    const IdRelation = (elm) => {
      if (elm?.objectId) return `${elm.objectId} / O`;
      if (elm?.pointComponentId) return `${elm?.pointComponentId} / C`;
      if (elm?.PointEventRelationWith)
        return `${item?.PointEventRelationWith[0]?.id} / E`;
    };
    const typeOfNameRelation = (elm) => {
      if (elm?.object) return elm.object?.typeElement?.name;
      if (elm?.component) return elm.component?.typeComponent?.name;
      if (elm?.event) return elm.event?.typeEvent?.name;
    };

    const { id, locationOperation } = item;
    const { latitude, longitude } = locationOperation;
    // Find user name
    const { operator, adminCompany } = item?.user;
    const { firstName, firstLastName } = operator || adminCompany;
    const totalEvents = item?.PointEventRelation?.length;

    const row = [
      {
        name: "id",
        value: removeLineBreak(id),
        alias: "ID",
      },
      {
        name: "typeOfEvent",
        value: removeLineBreak(item?.pointTypeEvent?.name),
        alias: "Type of Event",
      },
      {
        name: "relationId",
        value: removeLineBreak(IdRelation(item)),
        alias: "Relation ID",
      },
      {
        name: "relationTypeName",
        value: removeLineBreak(typeOfNameRelation(item)),
        alias: "Relation Type Name",
      },
      {
        name: "totalEvents",
        alias: "Total Events",
        value: removeLineBreak(totalEvents),
      },
      {
        name: "localDate",
        value: removeLineBreak(
          convertedTime.format("YYYY-MM-DD HH:mm:ss z").substring(0, 19)
        ),
        alias: "Local Date",
      },
      {
        name: "userName",
        alias: "User",
        value: removeLineBreak(firstName + " " + firstLastName),
      },
      {
        name: "typeElementId",
        alias: "Element ID",
        value: removeLineBreak(item.typeElementId),
      },
      {
        name: "latitude",
        alias: "Latitude",
        value: removeLineBreak(latitude),
      },
      {
        name: "longitude",
        alias: "Longitude",
        value: removeLineBreak(longitude),
      },
      ...item.attributes,
    ];

    return {
      ...item,
      attributes: row,
    };
  });
  const rowsFilter = rows.filter((item) => item !== null);

  return rowsFilter;
};
