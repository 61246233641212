// Styles
import { ContainerColumnSelect } from "./style";
// Constants
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";

const SelectColumnLibrary = ({
  columnsLibrary,
  handlerClickColumn,
  columnSelected,
  title = "",
}) => {
  return (
    <>
      <h2 className="subtitle">{title}</h2>
      <ContainerColumnSelect>
        {columnsLibrary.map((columnLibrary) => (
          <div
            key={columnLibrary._id}
            className="card-type-chart"
            onClick={() => handlerClickColumn(columnLibrary)}
            style={{
              backgroundColor:
                columnSelected === columnLibrary.name
                  ? ICON_GREEN_COLOR
                  : WHITE_COLOR,
            }}
          >
            <p>{columnLibrary.alias || columnLibrary.name} </p>
          </div>
        ))}
      </ContainerColumnSelect>
    </>
  );
};

export default SelectColumnLibrary;
