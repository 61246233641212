import axios from "axios";
import { config } from "config.js";

const updateUrlFlirVideo = async (flirVideoId, url) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.patch(
      `${config.URL_BACKEND_PG}api/v1/flir-video/${flirVideoId}`,
      {
        urlVideo: url,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return data;
  } catch (error) {
    return error;
  }
};

export default updateUrlFlirVideo;
