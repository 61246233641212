import Container from "@mui/material/Container";
import Loading from "components/Lodings/LoadingV2";

import Layout from "components/Layout/AdminDecimetrix";
import DescriptionHub from "pages/AdminCompany/Hub/DescriptionHub";
import useGet from "hooks/useFetch";
import TabsOper from "./TabsOper";

const Hub = ({ userRole }) => {
  const operatorId = localStorage.getItem("operatorId");
  const [operatorDecimetrix, errorOperatorDecimetrix] = useGet(
    `api/v1/operators/${operatorId}`
  );
  return (
    <>
      <Layout>
        <Container
          className="background-1"
          maxWidth="false"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          {!errorOperatorDecimetrix && operatorDecimetrix ? (
            <>
              <DescriptionHub operatorDecimetrix={operatorDecimetrix} />
              <TabsOper userRole={userRole} />
            </>
          ) : (
            <Loading />
          )}
        </Container>
      </Layout>
    </>
  );
};
export default Hub;
