import axios from "axios";
import { config } from "config.js";

export const updateMapView = async ({ id, body }) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.patch(
      `${config.URL_BACKEND_MG}order-map-views/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const mapViewUpdated = await res.data;
    return mapViewUpdated;
  } catch (error) {
    console.error(error);
    throw new Error("Error updating map view");
  }
};
