import axios from "axios";
import timeZoneOffset from "services/timeZoneOffset";
import { config } from "config.js";
/**
 * Creates a point component.
 *
 * @param {Object} feature - The feature object.
 * @param {Object} info - The info object.
 * @param {string} objectId - The object ID.
 * @param {string} pointTypeComponentId - The point type component ID.
 * @return {Object} The created point component.
 */
export const createPointComponent = (
  feature,
  info,
  objectId,
  pointTypeComponentId
) => {
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { coordinates } = feature.geometry;
  const [lng, lat] = coordinates;

  return {
    attributes: info,
    locationComponent: {
      longitude: lng,
      latitude: lat,
    },
    pointTypeComponentId,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneOffset: timeZoneOffset(),
    date: new Date(),
    objectId,
    userId: parseInt(userId),
    adminCompanyId,
  };
};

/**
 * Saves a point component API to the database.
 *
 * @param {Object} pointEvent - The point event to be saved.
 * @return {Object} An object containing the saved data and the response.
 */
const savePointComponentApiPg = async (pointComponent) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/point-components`,
      pointComponent,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default savePointComponentApiPg;

export const setUpdateAtComponent = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.patch(
      `${config.URL_BACKEND_PG}api/v1/point-components/set-updateAt/${id}`,
      {},
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};
