import Grid from "@material-ui/core/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ImageFieldVideo from "images/oil-pump-jack.jpeg";
import TableInfo from "components/Tables/BasicTable/Table";
import { Label, Wrapperbtn } from "./OperationDetailsElements";
import UseGet from "hooks/useFetch";
import deleteFlirVideoProcessed from "services/deleteFlirVideoProcessed";
import CustomButton from "components/Buttons/CustomButton";
import { useEffect, useState } from "react";
import { WHITE_COLOR } from "utils/const";

const LeakQuantification = ({
  videos,
  setVideos,
  handleOpenUploadVideo,
  handleOpenUploadVideoProcessed,
}) => {
  const getNameFileUrl = (url) => {
    if (url) return url.split("/").at(-1);
    return null;
  };

  const [videosProcessed, setVideosProcessed] = useState([]);

  useEffect(() => {
    if (videos[0]?.flirVideoProcessed.length > 0) {
      setVideosProcessed(videos[0]?.flirVideoProcessed);
    }
  }, [videos]);

  const [typesVideosProcessed] = UseGet("api/v1/type-video-processed");

  const handlerDelete = async (id) => {
    setVideosProcessed((currentVideos) =>
      currentVideos.filter((videoProcessed) => videoProcessed.id !== id)
    );
    await deleteFlirVideoProcessed(id);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {videos && videos.length > 0 && (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <div className="leak-container">
            <div className="operation-title">
              <h1>
                LEAK <span>QUANTIFICATION</span>
              </h1>
            </div>
            {/* <div className="container-videos"> */}
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <item>
                  <div className="operation-title">
                    <h5>
                      <span>FILE .SEQ</span>
                    </h5>
                  </div>
                  <div className="container-video">
                    {videos[0].urlVideo !== null ? (
                      videos.map((video) => (
                        <div key={video.id}>
                          <div className="video">
                            <img
                              src={ImageFieldVideo}
                              alt="video"
                              width="100%"
                              height="460px"
                              style={{
                                objectFit: "cover",
                              }}
                            />
                            <a href={video.urlVideo}>
                              <button class="button">
                                <DownloadIcon
                                  style={{
                                    color: WHITE_COLOR,
                                    fontSize: "4rem",
                                  }}
                                />
                              </button>
                            </a>
                          </div>
                          {video?.urlVideo?.length > 0 && (
                            <TableInfo
                              title={"Details"}
                              data={{
                                "Name file event": video.name,
                                "Name file assigned": getNameFileUrl(
                                  video?.urlVideo
                                ),
                                Size: video.size,
                              }}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <Wrapperbtn
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        <Label onClick={handleOpenUploadVideo}>
                          <FileUploadIcon />
                          <p
                            style={{
                              width: "360px",
                              textAlign: "center",
                            }}
                          >
                            Upload Field Video
                          </p>
                        </Label>
                      </Wrapperbtn>
                    )}
                  </div>
                </item>
              </Grid>
            </Grid>
            {/* Field video processed section */}
            <Grid
              item
              xs={12}
              md={12}
              lg={videos[0].urlVideo === null ? 0 : 6}
              xl={videos[0].urlVideo === null ? 0 : 6}
            >
              <item>
                {videos[0].urlVideo !== null && (
                  <>
                    <div className="operation-title">
                      <h5>
                        <span>FIELD VIDEO PROCESSED</span>
                      </h5>
                    </div>
                    <div className="container-video">
                      <Wrapperbtn
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        <Label onClick={handleOpenUploadVideoProcessed}>
                          <FileUploadIcon />
                          <p
                            style={{
                              width: "400px",
                              textAlign: "center",
                            }}
                          >
                            Add New Processed Video
                          </p>
                        </Label>
                      </Wrapperbtn>
                    </div>
                  </>
                )}
              </item>
            </Grid>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {videosProcessed.length > 0 &&
                videosProcessed?.map((videoProcessed) => (
                  <article className="article">
                    <div className="videoProcessed">
                      <video
                        id="field-video-processed"
                        width="320"
                        controls
                        style={{
                          margin: "0",
                        }}
                      >
                        <source
                          src={videoProcessed.urlVideo}
                          type="video/mp4"
                        />
                      </video>
                      <CustomButton
                        text={"Delete"}
                        onClick={() => handlerDelete(videoProcessed.id)}
                        primaryColor={"#9a1414"}
                        secondaryColor={"#d74747d3"}
                      />
                    </div>
                    <TableInfo
                      title=""
                      data={{
                        value: videoProcessed?.value,
                        type: typesVideosProcessed?.filter(
                          (item) => item.id === videoProcessed?.typeId
                        )[0]?.type,
                        "video raw": (
                          <a
                            className="video-text-pdf"
                            href={videoProcessed?.urlVideo}
                          >
                            {getNameFileUrl(videoProcessed?.urlVideo)}
                          </a>
                        ),
                        "video plume": (
                          <a
                            className="video-text-pdf"
                            href={videoProcessed?.urlVideoPlume}
                          >
                            {getNameFileUrl(videoProcessed?.urlVideoPlume)}
                          </a>
                        ),
                        "output file": (
                          <a
                            className="video-text-pdf"
                            href={videoProcessed?.dataOutputFile}
                          >
                            {getNameFileUrl(videoProcessed?.dataOutputFile)}
                          </a>
                        ),
                        report: (
                          <a
                            className="video-text-pdf"
                            href={videoProcessed.urlReport}
                          >
                            {getNameFileUrl(videoProcessed.urlReport)}
                          </a>
                        ),
                      }}
                    />
                  </article>
                ))}
            </div>
            {/* </div> */}
          </div>
        </Grid>
      )}
    </div>
  );
};

export default LeakQuantification;
