// Create attributes object from key fields and formFields
export const createAttributes = (formFields, keyFields) => {
  return keyFields.map((key) => {
    let value = formFields[key];
    if (typeof value === "boolean") value = value.toString();
    return {
      name: key,
      value: value,
    };
  });
};

export const waitSeconds = (seconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(`Await ${seconds} seconds`);
    }, seconds * 1000);
  });
};
