import axios from "axios";
import { config } from "config.js";

const token = localStorage.getItem("token");

const axiosMg = axios.create({
  baseURL: `${config.URL_BACKEND_MG}doughnut-chart`,
  headers: {
    Authorization: `Bearer ${token} : ""`,
  },
});

export const createChart = async (body) => {
  const res = await axiosMg.post("/", body);
  const data = await res.data;
  return { data, res };
};
export const findCharts = async ({ adminCompanyId, from }) => {
  const params = new URLSearchParams();

  if (adminCompanyId) params.append("adminCompanyId", adminCompanyId);
  if (from) params.append("from", from);

  let url = `/${params ? `?${params.toString()}` : ""}`;
  const res = await axiosMg.get(url);
  const data = await res.data;
  return { data, res };
};

export const updateChart = async ({ id, body }) => {
  const res = await axiosMg.patch(`/${id}`, body);
  const data = await res.data;
  return { data, res };
};

// Delete kpi chart

export const deleteChartDoughnut = async ({ id }) => {
  const res = await axiosMg.delete(`/${id}`);
  const data = await res.data;
  return { data, res };
};
