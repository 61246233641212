// Required modules
import styled from "styled-components";
import {
  BORDER_GREY,
  ICON_GREEN_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  SELECTION_COLOR,
  SENSOR_CLUSTER_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 21px;
  /* box-shadow: rgba(149, 157, 165, 0.5) 0px 1px 5px 1px; */
  border-radius: 5px;
  margin: 13px 0;
  border: solid 1px ${BORDER_GREY};
  background: ${WHITE_COLOR};

  .handling {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 5px 0;
    border-radius: 5px;
    padding: 2rem;
    margin-top: 2rem;

    button {
      display: flex;
      background: ${PRIMARY_COLOR};
      color: ${WHITE_COLOR};
      padding: 8px 13px;
      outline: none;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      margin: 13px 0;
      transition: 0.3s all ease-in-out;
    }

    button:hover {
      background: ${SECOND_COLOR};
    }

    .label {
      font-size: 1.6rem;
      font-weight: 600;
      color: #5c5757;
    }
  }
  .hr-line {
    border: solid 1px #b1b1b1dc;
    width: 70%;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

export const FieldColumns = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column;

  /* justify-content: center; */
  /* row-gap: 1rem; */
  /* column-gap: 2rem; */
  /* align-items: start; */
  /* padding-bottom: 5rem; */
  margin-bottom: 5rem;
  border-radius: 5px;
  padding: 2rem;
  width: 100%;

  .column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  /* .left {
    align-items: flex-start;
  }

  .right {
    align-items: flex-start;
  } */
`;

export const FiledRelation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  align-items: start;
  margin-bottom: 5rem;
  border-radius: 5px;
  padding: 2rem;
`;

export const FiledSelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  align-items: start;
  margin-bottom: 5rem;
  border: dashed 1px grey;
  border-radius: 5px;
  padding: 2rem;
  width: 100%;
  height: 100%;
  min-height: 170px;

  .empty {
    align-self: center;
    color: grey;
  }
`;

export const FiledOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  align-items: start;
  margin-bottom: 1rem;
  border: solid 1px #bebebe;
  border-radius: 5px;
  padding: 2rem;
  width: 100%;
  height: 100%;
  min-height: 170px;
  background-color: #eeeeee;

  .empty {
    align-self: center;
    color: grey;
  }
`;

export const Totalcircle = styled.h2`
  background-color: "#5579D6";
  color: "white";
  border-radius: "50%";
  width: "23px"; // Ajusta el tamaño según sea necesario
  height: "23px"; // Asegúrate de que sea un cuadrado para mantener la forma circular
  display: "flex";
  align-items: "center";
  justify-content: "center";
  padding: "2px";
  margin-left: "0.5rem"; // Espaciado entre los elementos
`;

export const IconOption = styled.div`
  display: "flex";
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 2px;

  &.edit {
    background-color: var(--background-primary_color);
  }
  &.select {
    background-color: var(--background-primary_color);
  }
  &.relationship {
    background-color: var(--background-primary_color);
  }
  &.line {
    background-color: var(--background-primary_color);
  }
  &.close {
    /* -webkit-filter: invert(100%);
    filter: invert(100%); */
    background-color: #ffffff;
  }
`;

export const ConteinerLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 70px;
  border: solid 1px grey;
  border-radius: 5px;
  background: white;
  padding: 1.5rem;
`;

export const LineTag = styled.hr`
  border: solid 1px #b1b1b1dc;
  width: 100%;
  margin-bottom: 1rem;
`;

export const LineConteiner2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.6rem 0;

  hr {
    border: 1px solid #b1b1b1dc;
    flex-grow: 1;
  }

  h3 {
    margin: 0 1.5rem 0 1.5rem;
  }
`;
export const LineConteiner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #b1b1b1dc;
  }

  .titlecontent {
    background-color: white;
  }
  .title {
    margin: 0 10px;
    font-weight: bold;
    white-space: nowrap;
    background-color: white;
    margin: 0 1rem 0 1rem;
  }
`;

export const IconOption2 = styled.div`
  display: "flex";
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  width: 35px;
  height: 20px;
  padding: 2px;
  margin: 0 3px 0 3px;
  cursor: "pointer";

  &.select {
    background-color: var(--background-primary_color);
    /* box-shadow: #ffee009b 0px 1px 2px 1px; */
  }

  &.item {
    text-align: left;

    color: #44598d;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    flex-direction: row;
    width: auto;
    height: 25px;
    padding: 2px 10px 2px 10px;
    font-weight: bolder;
    font-size: medium;
  }
`;

export const Field = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 0.2rem;
  box-shadow: rgba(91, 93, 95, 0.5) 0px 1px 5px 1px;
  border-radius: 5px;
  padding: 1rem;
  padding-left: 1rem;
  margin-top: 20px;
  color: white;
  width: 100%;
  /* background: ${(props) => (props.isRightColumn ? "#5579D6" : "#415381")}; */
  background: var(--background-secondary_color);

  position: relative;
  transition: background-color 0.3s;

  &.header {
    border: 2px solid ${SENSOR_CLUSTER_COLOR};
  }

  &.qaqc {
    border: 2px solid ${ICON_GREEN_COLOR};
  }

  &.content {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 35px; /* Adjust the width to the desired size of the red fraction */
      background-color: var(--background-primary_color);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &.fields:hover {
    /* background-color: #738141; */
    /* border: solid 5px #e08002;
    border-radius: 10px;
    box-shadow: 0 0 10px #e08002; */
  }
  &.coloricon {
    color: white;
  }
`;

export const FieldOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  box-shadow: rgba(91, 93, 95, 0.5) 0px 1px 5px 1px;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  margin-top: 10px;
  color: white;
  width: 100%;
  background: ${(props) => (props.isRightColumn ? "#5579D6" : "#3c5696")};
`;

export const FieldDetatils = styled.div`
  display: flex;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 2rem;
  align-items: center;
  padding-bottom: 2rem;
  flex-wrap: wrap;

  .field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 1px 5px 1px;
    border-radius: 5px;
    padding: 1rem;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;

    .delete {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }

    .update {
      position: absolute;
      top: -5px;
      right: 12px;
      cursor: pointer;
    }

    span {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }

  .header {
    border: 2px solid ${SENSOR_CLUSTER_COLOR};
  }

  .qaqc {
    border: 2px solid ${ICON_GREEN_COLOR};
  }
`;

export const MoveTag = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  min-width: 900px;
  margin: 10px;
  padding: 2px;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 10px;
  background-color: ${({ currentLibraryId, item }) =>
    currentLibraryId === item.id ? SELECTION_COLOR : "#f3f1f1"};
  text-align: ${(props) => props.textAling};
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentDataTag = styled.p`
  width: ${(props) => props.width}%;
  background-color: #fff;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.8rem;
  font-weight: 500;
`;

export const Columns = styled.div`
  display: flex;
  width: 100%;
  height: 20%;
  border-radius: 5px;
  margin-top: 14px;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: 20px;
  align-items: center;
  background-color: ${PRIMARY_COLOR};
`;

export const Column = styled.p`
  width: ${(props) => props.width}%;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: ${(props) => props.textAling};
  align-items: center;
  color: #fff;
`;

export const SearchPointType = styled.input`
  border-radius: 5px;
  font-size: 15px;
  padding: 0.8rem;
  outline: none;
  border: 1px solid #c2c9ca;
  width: 100%;

  :focus {
    border: 2px solid #2d69ec;
  }
`;
