import axios from "axios";
import { config } from "config.js";

const token = localStorage.getItem("token");

export const deleteEventsByIds = async (ids) => {
  // ids = [23, 345, 456]
  const url = `${config.URL_BACKEND_PG}api/v1/point-events/bulk-delete`;

  const response = await axios.delete(url, {
    data: { ids: ids },
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return response.data;
};
