// Mapbox
import { Marker } from "react-map-gl";
// Style
import { NamePlateMarker, ImgIconOperation } from "./showEventsStyle";

/**
 * Generates a list of markers based on the provided marker objects.
 *
 * @param {object} markers - The markers object containing marker information.
 * @param {number} markers.id - The ID of the marker.
 * @param {string} markers.icon - The URL of the marker icon.
 * @param {number} markers.latitude - The latitude coordinate of the marker.
 * @param {number} markers.longitude - The longitude coordinate of the marker.
 * @param {function} markers.handlerClick - The click handler function for the marker.
 * @param {number} [markers.marked] - The ID of the marker if it is marked.
 * @return {Array} - An array of React components representing the markers.
 */
const showMarkers = ({ markers }) => {
  /*
    markers body: [{
      id: number,
      icon: url,
      latitude: number,
      longitude: number,
      handlerClick: function
      marked: number 
    }]
  */
  return markers?.map((marker, index) => {
    const icon = marker?.icon;
    const handlerClick = marker?.handlerClick;
    const marked = marker?.marked;
    return (
      <div key={index}>
        {markers && (
          <Marker
            latitude={Number(marker?.latitude)}
            longitude={Number(marker?.longitude)}
            style={{
              cursor: "pointer",
            }}
          >
            <NamePlateMarker
              id={marker.id}
              onClick={() => handlerClick(marker?.id)}
              marked={marked === marker?.id}
              qaqc={marker.QAQC}
            >
              <ImgIconOperation
                id={marker.id}
                src={icon}
                alt="marker of element"
                style={{
                  cursor: "pointer",
                }}
              ></ImgIconOperation>
              <span className="selected"></span>
              <span className="qaqc"></span>
            </NamePlateMarker>
            <p>{marker?.id}</p>
          </Marker>
        )}
      </div>
    );
  });
};

export default showMarkers;
