import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
const AddUsers = () => {
  return (
    <>
      <svg
        id="Capa_55"
        data-name="Capa 55"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`.cls-1addu {
        fill: var(--background-primary_color);
      }

      .cls-1adduplus{
        fill: var(--background-secondary_color);

      }


      .cls-1addu, .cls-2addu {
        stroke-width: 0px;
      }

      .cls-3addu {
        fill: #f4f4f4;
        stroke: #a7a7a7;
        stroke-miterlimit: 10;
        stroke-width: 8px;
      }

      .cls-2addu {
        fill: #d8d8d8;
      }`}
          </style>
        </defs>
        <g>
          <circle class="cls-1addu" cx="216.86" cy="215.98" r="167.22" />
          <g>
            <ellipse
              class="cls-2addu"
              cx="215.67"
              cy="200.69"
              rx="64.22"
              ry="65.48"
            />
            <path
              class="cls-2addu"
              d="M216.86,383.2c37.79,0,72.65-12.54,100.66-33.69-16.58-36.07-56.27-67-101.52-67s-83.21,30.94-99.79,67c28,21.14,62.86,33.69,100.66,33.69Z"
            />
          </g>
        </g>
        <g>
          <circle class="cls-3addu" cx="333.36" cy="340.37" r="66.27" />
          <rect
            class="cls-1adduplus"
            x="319.29"
            y="299.92"
            width="28.14"
            height="80.9"
            rx="6.01"
            ry="6.01"
          />
          <rect
            class="cls-1adduplus"
            x="319.93"
            y="299.92"
            width="28.14"
            height="80.9"
            rx="6.01"
            ry="6.01"
            transform="translate(-7.01 673.73) rotate(-90)"
          />
        </g>
      </svg>
    </>
  );
};

export default AddUsers;
