import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const VideoManagerIcon = () => {
  return (
    <>
      <svg
        id="Capa_49"
        data-name="Capa 49"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 433.72 433.72"
      >
        <defs>
          <style>
            {`.cls-1vm {
        fill: var(--background-primary_color);
      }

      .cls-1vm, .cls-2vm, .cls-3vm {
        stroke-width: 0px;
      }

      .cls-2vm {
        fill: #fff;
      }

      .cls-3vm {
        fill: var(--background-secondary_color);
      }`}
          </style>
        </defs>
        <g>
          <rect
            class="cls-1vm"
            x="98.79"
            y="66.82"
            width="229.6"
            height="229.6"
            rx="21.13"
            ry="21.13"
          />
          <rect
            class="cls-2vm"
            x="105.01"
            y="73.04"
            width="217.16"
            height="217.16"
            rx="15.39"
            ry="15.39"
          />
        </g>
        <g>
          <g>
            <rect
              class="cls-1vm"
              x="119.84"
              y="84.64"
              width="229.6"
              height="229.6"
              rx="21.13"
              ry="21.13"
            />
            <rect
              class="cls-2vm"
              x="126.05"
              y="90.86"
              width="217.16"
              height="217.16"
              rx="15.39"
              ry="15.39"
            />
          </g>
          <g>
            <rect
              class="cls-3vm"
              x="170.91"
              y="151.57"
              width="157.48"
              height="8.61"
              rx="2.8"
              ry="2.8"
            />
            <rect
              class="cls-3vm"
              x="170.91"
              y="162.93"
              width="157.48"
              height="8.61"
              rx="2.8"
              ry="2.8"
            />
            <rect
              class="cls-3vm"
              x="170.91"
              y="192.71"
              width="157.48"
              height="8.61"
              rx="2.8"
              ry="2.8"
            />
            <rect
              class="cls-3vm"
              x="170.91"
              y="204.07"
              width="157.48"
              height="8.61"
              rx="2.8"
              ry="2.8"
            />
            <rect
              class="cls-3vm"
              x="170.91"
              y="233.84"
              width="157.48"
              height="8.61"
              rx="2.8"
              ry="2.8"
            />
            <rect
              class="cls-3vm"
              x="170.91"
              y="245.2"
              width="157.48"
              height="8.61"
              rx="2.8"
              ry="2.8"
            />
            <rect
              class="cls-3vm"
              x="148.32"
              y="154.2"
              width="11.96"
              height="11.96"
              rx="5.98"
              ry="5.98"
            />
            <rect
              class="cls-3vm"
              x="148.32"
              y="195.34"
              width="11.96"
              height="11.96"
              rx="5.98"
              ry="5.98"
            />
            <rect
              class="cls-3vm"
              x="148.32"
              y="236.47"
              width="11.96"
              height="11.96"
              rx="5.98"
              ry="5.98"
            />
            <rect
              class="cls-3vm"
              x="140.88"
              y="107.84"
              width="187.5"
              height="19.13"
              rx="7.57"
              ry="7.57"
            />
          </g>
        </g>
        <g>
          <path
            class="cls-1vm"
            d="M174.45,314.13v26.81c0,9.16,4.3,17.22,10.53,19.75l31.07,12.62c9.3,3.78,18.58-6.08,18.58-19.75v-52.05c0-13.67-9.28-23.53-18.58-19.75l-31.07,12.62c-6.24,2.53-10.53,10.59-10.53,19.75Z"
          />
          <rect
            class="cls-1vm"
            x="44.8"
            y="268.25"
            width="137.48"
            height="112.81"
            rx="16.12"
            ry="16.12"
          />
          <path
            class="cls-2vm"
            d="M130.68,333.3c-.45,0-.89.04-1.32.1-3.37.05-11.89-1.28-9.82-16.08.34-1.33.54-2.72.54-4.16,0-9.25-7.5-16.74-16.74-16.74s-16.74,7.5-16.74,16.74,7.5,16.74,16.74,16.74c1.6,0,3.14-.24,4.6-.66,3.95-.63,13.73-.78,12.94,13.4,0,0,0,0,.01,0,0,.16-.02.31-.02.46,0,5.41,4.39,9.8,9.8,9.8s9.8-4.39,9.8-9.8-4.39-9.8-9.8-9.8Z"
          />
        </g>
      </svg>
    </>
  );
};

export default VideoManagerIcon;
