// React
import React, { useEffect, useState } from "react";
// Redux
import { setNeuronSelectTool } from "redux/actions/digitalTwin";
import { useSelector } from "react-redux";
// Components
import { ButtonMovePoint } from "./userRoutes";
// Style
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { Typography } from "@mui/material";

function MovePoint({
  from,
  setMovePoint,
  movePoint,
  moveNewLocation,
  setMoveNewLocation,
  onMovePoints,
  onPasteCoordinates,
}) {
  const [currentMoveLocation, setCurrentMoveLocation] = useState({});

  useEffect(() => {
    if (from === "details") {
      const newLocationMove = localStorage.getItem("newLocationMove");
      const parseNewLocationMove = JSON.parse(newLocationMove);
      if (parseNewLocationMove) setCurrentMoveLocation(parseNewLocationMove);
    }
    if (from === "digitalTwin") {
      setCurrentMoveLocation(moveNewLocation);
    }
  }, [from, moveNewLocation]);

  const drawerControl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );
  const handleCopyCoordinates = () => {
    if (from === "digitalTwin")
      onMovePoints({
        setMovePoint,
        movePoint,
        currentMoveLocation,
        setMoveNewLocation,
        drawerControl,
        setNeuronSelectTool,
      });
    if (from === "details") onPasteCoordinates();
  };

  return (
      <SimpleToolTip
        title={
          Object.keys(currentMoveLocation).length === 0 ? (
            "Move Point"
          ) : (
            <>
              <Typography style={{fontSize: 12}}>
                Longitude: {currentMoveLocation.lng}
              </Typography>
              <Typography style={{fontSize: 12}}>
                Latitude: {currentMoveLocation.lat}
              </Typography>
            </>
          )
        }
        placement="left"
      >
        <ButtonMovePoint
          top={from === "digitalTwin" ? "54" : "211"}
          right={from === "digitalTwin" ? "44" : "8"}
          onClick={() => handleCopyCoordinates()}
        >
          {currentMoveLocation.hasOwnProperty("lng") &&
            currentMoveLocation.hasOwnProperty("lat") &&
            currentMoveLocation.lng !== "" &&
            currentMoveLocation.lat !== "" && <p>✓</p>}
          <EditLocationAltIcon
            style={{
              color: "#282828",
              fontSize: "1.6rem",
            }}
          />
        </ButtonMovePoint>
      </SimpleToolTip>
    );
}

export default MovePoint;
