import Layout from "components/Layout/AdminDecimetrix";
import {
  InformationSection,
  Content,
  ConfigurationSection,
  ConfigLibrary,
  ConfigConstant,
  ContentButton,
  ContentData,
  InformationShare,
  Form,
  Title,
  ContentMoveTags,
  MoveTag,
  ContentDataTag,
  Columns,
  Column,
  Space,
  Ded,
  GlobalContent,
} from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "components/Buttons/CustomButton";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/Forms/GenericInput";
import saveConstantLibrary from "services/GlobalConstant/saveConstantLibrary";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useSWR from "swr";
import { useSWRConfig } from "swr";
import { config } from "config.js";
import saveGlobalConstant from "services/GlobalConstant/saveGlobalConstant";
import { BLACK_COLOR, DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";
import OpenDialog from "components/Dialogs/OpenDialog";
import deleteConstantLibrary from "services/GlobalConstant/deleteConstantLibrary";
import deleteGlobalConstant from "services/GlobalConstant/deleteGlobalConstant";
import updateConstantLibrary from "services/GlobalConstant/updateConstantLibrary";
import updateGlobalConstant from "services/GlobalConstant/updateGlobalConstant";

import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor, StyleColor2, StyleColor3 } from "Styles";

const GlobalConstants = () => {
  const { mutate } = useSWRConfig();

  const [openDialog, setOpenDialog] = useState(false);
  const [constantLibraries, setConstantLibraries] = useState([]);
  const [globalConstants, setGlobalConstants] = useState([]);
  const [currentLibraryId, setCurrentLibraryId] = useState(0);
  const [isCreateLibrary, setIsCreateLibray] = useState(false);
  const [isCreateGlobalConstant, setIsCreateGlobalConstant] = useState(false);
  const [deleteConstat, setDeleteConstant] = useState(false);
  const [updateConstant, setUpdateConstant] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "",
    disagree: "Cancel",
    agree: "",
  });
  const { theme } = useContext(ThemeContext);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidCharacters, setInvalidCharacters] = useState(false);

  const [changeWarningState, setChangeWarningState] = useState(false);
  const [dataForm, setDataForm] = useState({});

  const hadleChangeWarningState = (data) => {
    setDataForm(data);
    setChangeWarningState(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const url = `${config.URL_BACKEND_PG}api/v1/constant-library?adminCompanyId=${adminCompanyId}`;

  const { data: constantLibrary, error: errorConstantLibrary } = useSWR(url);

  const { data: globalConstant, error: errorGlobalConstant } = useSWR(
    `${config.URL_BACKEND_PG}api/v1/global-constants?constantLibraryId=${currentLibraryId}`
  );

  //sample → {adminCompanyId: 1m, fieldNames: ["field1","field2",...]}
  const { data: dataNames, error: errorDataNames } = useSWR(
    `${config.URL_BACKEND_PG}api/v1/global-constants?adminCompanyId=${adminCompanyId}&getFieldsNames=true`
  );

  useEffect(() => {
    constantLibrary &&
      !errorConstantLibrary &&
      setConstantLibraries(constantLibrary);
  }, [constantLibrary, errorConstantLibrary, currentLibraryId]);

  useEffect(() => {
    globalConstant &&
      !errorGlobalConstant &&
      setGlobalConstants(globalConstant);
  }, [globalConstant, errorGlobalConstant, currentLibraryId]);

  const handelSubmitConstantLibrary = async (data) => {
    await saveConstantLibrary({ name: data["Library name"] });
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setOpenDialog(false);
    setIsCreateLibray(false);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/constant-library?adminCompanyId=${adminCompanyId}`
    );
  };

  const handelSubmitGlobalConstant = async (data) => {
    if (!invalidName && !invalidCharacters) {
      await saveGlobalConstant({
        name: data["Name"],
        alias: data["Alias"],
        value: data["Value"],
        constantLibraryId: currentLibraryId,
      });
      setContentDialog({
        title: "",
        description: "",
        disagree: "",
      });
      setOpenDialog(false);
      setIsCreateGlobalConstant(false);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/global-constants?constantLibraryId=${currentLibraryId}`
      );
    }
  };

  const handelDeleteConstants = async () => {
    if (dataDelete.globalConstants) await deleteConstantLibrary(dataDelete.id);
    if (dataDelete.constantLibrary) await deleteGlobalConstant(dataDelete.id);
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataDelete({ name: "" });
    setOpenDialog(false);
    setDeleteConstant(false);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/constant-library?adminCompanyId=${adminCompanyId}`
    );
    mutate(
      `${config.URL_BACKEND_PG}api/v1/global-constants?constantLibraryId=${currentLibraryId}`
    );
  };

  const handleUpdateConstants = async (data) => {
    data = data === undefined ? dataForm : data;
    if (data.globalConstants)
      await updateConstantLibrary({
        id: data.id,
        body: { name: data["Library name"] },
      });
    if (data.constantLibrary)
      await updateGlobalConstant({
        id: data.id,
        body: {
          name: data["Name"],
          alias: data["Alias"],
          value: data["Value"],
        },
      });
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataForm({});
    setValue("Library name", "");
    setValue("Name", "");
    setValue("Alias", "");
    setValue("Value", 0);
    setOpenDialog(false);
    setChangeWarningState(false);

    setUpdateConstant(false);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/constant-library?adminCompanyId=${adminCompanyId}`
    );
    mutate(
      `${config.URL_BACKEND_PG}api/v1/global-constants?constantLibraryId=${currentLibraryId}`
    );
  };

  const CreateLibrary = (
    <Form
      style={{ background: StyleColor3(theme) }}
      onSubmit={handleSubmit(handelSubmitConstantLibrary)}
    >
      <Title
        style={{
          color: StyleColor2(theme),
        }}
      >
        Create Constant Library
      </Title>
      <TextInput
        field={{
          name: "Library name",
          type: "string",
          required: true,
        }}
        register={register}
        errors={errors}
      />
      <CustomButton type={"submit"} text={"Save"} margin={0} />
    </Form>
  );

  let updateLibrary = (
    <Form onSubmit={handleSubmit(handleUpdateConstants)}>
      <Title
        style={{
          color: StyleColor2(theme),
        }}
      >
        Update Constant Library
      </Title>
      <TextInput
        field={{
          name: "Library name",
          type: "string",
          required: true,
        }}
        register={register}
        errors={errors}
      />
      <CustomButton type={"submit"} text={"Update"} margin={0} />
    </Form>
  );

  const WarningSpan = ({ content }) => (
    <span
      style={{
        maginBottom: "10px",
        width: "100%",
        textAlign: "center",
        color: "red",
      }}
    >
      {content}
    </span>
  );

  const CreateGlobalConstant = (
    <Form onSubmit={handleSubmit(handelSubmitGlobalConstant)}>
      <Title
        style={{
          color: StyleColor2(theme),
        }}
      >
        Create Global Constant
      </Title>
      <TextInput
        field={{
          name: "Name",
          type: "string",
          required: true,
          validatename: invalidName || invalidCharacters,
        }}
        register={register}
        errors={errors}
      />
      {invalidName && (
        <WarningSpan content="Invalid constant name, it is already used." />
      )}
      {invalidCharacters && (
        <WarningSpan content="Invalid characters for constant name" />
      )}
      <TextInput
        field={{
          name: "Alias",
          type: "string",
          required: true,
        }}
        register={register}
        errors={errors}
      />
      <TextInput
        field={{
          name: "Value",
          type: "number",
          required: true,
        }}
        register={register}
        errors={errors}
      />
      <CustomButton
        type={"submit"}
        text={"Create"}
        margin={0}
        disabled={!invalidName && !invalidCharacters ? false : true}
      />
    </Form>
  );

  const constantName = watch("Name") || undefined;

  useEffect(() => {
    if (constantName !== undefined) {
      const toValidate = checkInvalidCharacters(constantName);
      const isNameFound =
        !errorDataNames &&
        dataNames?.fieldNames?.find((it) => it === constantName);

      if (toValidate) {
        setInvalidCharacters(true);
        setInvalidName(false);
        setContentDialog((prev) => ({
          ...prev,
          description: isCreateGlobalConstant ? CreateGlobalConstant : "",
        }));
      } else if (isNameFound !== undefined) {
        setInvalidName(true);
        setInvalidCharacters(false);

        setContentDialog((prev) => ({
          ...prev,
          description: isCreateGlobalConstant ? CreateGlobalConstant : "",
        }));
      } else {
        setInvalidName(false);
        setInvalidCharacters(false);
        setContentDialog((prev) => ({
          ...prev,
          description: isCreateGlobalConstant
            ? CreateGlobalConstant
            : updateConstant
            ? UpdateGlobalConstant
            : "",
        }));
      }
    }
  }, [constantName, dataNames, errorDataNames, invalidName, invalidCharacters]);

  const UpdateGlobalConstant = (
    <Form onSubmit={handleSubmit(hadleChangeWarningState)}>
      <Title
        style={{
          color: StyleColor2(theme),
        }}
      >
        Update Global Constant
      </Title>
      <TextInput
        field={{
          name: "Name",
          type: "string",
          required: true,
          noTouch: true,
        }}
        register={register}
        errors={errors}
      />
      <TextInput
        field={{
          name: "Alias",
          type: "string",
          required: true,
        }}
        register={register}
        errors={errors}
      />
      <TextInput
        field={{
          name: "Value",
          type: "number",
          required: true,
        }}
        register={register}
        errors={errors}
      />
      <CustomButton type={"submit"} text={"Update"} margin={0} />
    </Form>
  );

  const onDragConstantLibrary = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = [...constantLibraries];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);
    setConstantLibraries(updatedItems);
  };

  const onDragGlobalConstant = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = [...globalConstants];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);
    setGlobalConstants(updatedItems);
  };

  const handleOpenDialogLibrary = (e) => {
    e.preventDefault();
    setValue("Library name", "");
    setValue("Name", "");
    setValue("Alias", "");
    setValue("Value", 0);
    setIsCreateLibray(true);
    setContentDialog({
      title: "Create Library",
      description: CreateLibrary,
    });
    setOpenDialog(true);
  };

  const handleOpenDialogGlobalConstants = (e) => {
    e.preventDefault();
    setValue("Library name", "");
    setValue("Name", "");
    setValue("Alias", "");
    setValue("Value", 0);
    setIsCreateGlobalConstant(true);
    setContentDialog({
      title: "Create Global Constant",
      description: CreateGlobalConstant,
    });
    setOpenDialog(true);
  };

  const handleOpenDialogDelete = ({
    e,
    id,
    name,
    globalConstants,
    constantLibrary,
  }) => {
    e.preventDefault();
    setDeleteConstant(true);
    setDataDelete({ id, globalConstants, constantLibrary });
    setContentDialog({
      title: "Delete Constant Library",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          Do you want delete{" "}
          {globalConstants ? `${name} library` : `the global constant ${name}`}
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogUpdate = ({
    e,
    id,
    name,
    alias,
    value,
    globalConstants,
    constantLibrary,
  }) => {
    e.preventDefault();
    setUpdateConstant(true);
    if (globalConstants) {
      setValue("Library name", name);
      setValue("id", id);
      setValue("globalConstants", globalConstants);
      setValue("constantLibrary", constantLibrary);
      setContentDialog({
        title: "Update Constant Library",
        description: updateLibrary,
      });
    }
    if (constantLibrary) {
      setValue("Name", name);
      setValue("Alias", alias);
      setValue("Value", value);
      setValue("id", id);
      setValue("globalConstants", globalConstants);
      setValue("constantLibrary", constantLibrary);
      setContentDialog({
        title: "Update Global Constant",
        description: UpdateGlobalConstant,
      });
    }

    setOpenDialog(true);
  };

  return (
    <Layout>
      {/* Dialog for initial form */}
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={
          isCreateLibrary
            ? handelSubmitConstantLibrary
            : isCreateGlobalConstant
            ? handelSubmitGlobalConstant
            : deleteConstat
            ? handelDeleteConstants
            : updateConstant
            ? hadleChangeWarningState
            : ""
        }
        content={contentDialog}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      {/* Dialog for warning form */}
      <OpenDialog
        openDialog={changeWarningState}
        setOpenDialog={setChangeWarningState}
        execute={
          isCreateLibrary
            ? handelSubmitConstantLibrary
            : isCreateGlobalConstant
            ? handelSubmitGlobalConstant
            : deleteConstat
            ? handelDeleteConstants
            : updateConstant
            ? handleUpdateConstants
            : ""
        }
        content={{
          title: "Warning",
          description:
            "Several registers will be affected and expressions will be recalculated.\nAre you sure you want to update?",
          disagree: "Cancel",
          agree: "Yes",
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <Content>
        <InformationSection className="background-1" style={{ border: "none" }}>
          <h1>Digital Twin Global Constants Configuration</h1>
        </InformationSection>
        <GlobalContent className="background-2 border-top-1">
          <ConfigurationSection>
            <ConfigLibrary className="background-2">
              <ContentButton className="background-row-2 border-color-1">
                <h1>Create a new global constant libray</h1>
                <CustomButton
                  text={"+ New Constant Libray"}
                  margin={0}
                  type={"onClick"}
                  onClick={handleOpenDialogLibrary}
                />
              </ContentButton>
              <ContentData className="background-row-2 border-color-1">
                {constantLibraries.length !== 0 ? (
                  <Columns paddingLeft={30}>
                    <Column width={10} textAling={"start"}>
                      Id
                    </Column>
                    <Column width={90} textAling={"start"}>
                      Library Name
                    </Column>
                  </Columns>
                ) : (
                  <Space />
                )}
                {constantLibraries.length === 0 ? (
                  <InformationShare>
                    <div className="head">
                      Edit Existing Globlal Library Constant
                    </div>
                    <div className="body">
                      To Edit Click on + New Constant Libray
                    </div>
                  </InformationShare>
                ) : (
                  <DragDropContext onDragEnd={onDragConstantLibrary}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {(provided) => (
                        <ContentMoveTags
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {constantLibraries.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <MoveTag
                                  className="background-row-1 border-color-1"
                                  ref={provided.innerRef}
                                  currentLibraryId={currentLibraryId}
                                  item={item}
                                  textAling={"start"}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={item.id}
                                      onClick={() =>
                                        setCurrentLibraryId(item.id)
                                      }
                                      margin={0}
                                    />
                                  </div>
                                  <ContentDataTag
                                    className="background-2"
                                    width={90}
                                  >
                                    {item.name}
                                  </ContentDataTag>
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={<DeleteIcon />}
                                      onClick={(e) =>
                                        handleOpenDialogDelete({
                                          e: e,
                                          id: item.id,
                                          name: item.name,
                                          globalConstants: true,
                                          constantLibrary: false,
                                        })
                                      }
                                      margin={0}
                                      primaryColor={DELETE_COLOR}
                                      secondaryColor={HOVER_DELETE_COLOR}
                                    />
                                  </div>
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={<EditIcon />}
                                      onClick={(e) =>
                                        handleOpenDialogUpdate({
                                          e: e,
                                          id: item.id,
                                          name: item.name,
                                          globalConstants: true,
                                          constantLibrary: false,
                                        })
                                      }
                                      margin={0}
                                    />
                                  </div>
                                </MoveTag>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ContentMoveTags>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </ContentData>
            </ConfigLibrary>
            <ConfigConstant className="background-2">
              <ContentButton className="background-row-2 border-color-1">
                <h1>Create global constant</h1>
                <CustomButton
                  text={"+ New Global Constant"}
                  margin={0}
                  type={"onClick"}
                  onClick={handleOpenDialogGlobalConstants}
                  disabled={currentLibraryId !== 0 ? false : true}
                />
              </ContentButton>
              <ContentData
                className="background-row-2 border-color-1"
                style={{ borderRadius: "5px" }}
              >
                {globalConstants.length !== 0 ? (
                  <Columns paddingLeft={10}>
                    <Column width={8} textAling={"center"}>
                      Id
                    </Column>
                    <Column width={33} textAling={"center"}>
                      Alias
                    </Column>
                    <Column width={22} textAling={"center"}>
                      Name
                    </Column>
                    <Column width={20} textAling={"center"}>
                      Value
                    </Column>
                  </Columns>
                ) : (
                  <Space />
                )}
                {globalConstants.length === 0 ? (
                  <InformationShare>
                    <div className="head color1">
                      Edit Existing Global Constant
                    </div>
                    <div className="body color1">
                      To Edit Click on + New Global Constant
                    </div>
                  </InformationShare>
                ) : (
                  <DragDropContext onDragEnd={onDragGlobalConstant}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {(provided) => (
                        <ContentMoveTags
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {globalConstants.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <MoveTag
                                  className="background-row-1 border-color-1"
                                  ref={provided.innerRef}
                                  item={item}
                                  textAling={"center"}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ContentDataTag
                                    className="background-2"
                                    width={7}
                                  >
                                    {item.id}
                                  </ContentDataTag>
                                  <ContentDataTag
                                    className="background-2"
                                    width={44}
                                  >
                                    {item.alias}
                                  </ContentDataTag>
                                  <ContentDataTag
                                    className="background-2"
                                    width={25}
                                  >
                                    {item.name}
                                  </ContentDataTag>
                                  <ContentDataTag
                                    className="background-2"
                                    width={24}
                                  >
                                    {item.value}
                                  </ContentDataTag>
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={<DeleteIcon />}
                                      onClick={(e) =>
                                        handleOpenDialogDelete({
                                          e: e,
                                          id: item.id,
                                          name: item.alias,
                                          globalConstants: false,
                                          constantLibrary: true,
                                        })
                                      }
                                      margin={0}
                                      primaryColor={DELETE_COLOR}
                                      secondaryColor={HOVER_DELETE_COLOR}
                                    />
                                  </div>
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={<EditIcon />}
                                      onClick={(e) =>
                                        handleOpenDialogUpdate({
                                          e: e,
                                          id: item.id,
                                          name: item.name,
                                          alias: item.alias,
                                          value: item.value,
                                          globalConstants: false,
                                          constantLibrary: true,
                                        })
                                      }
                                      margin={0}
                                    />
                                  </div>
                                </MoveTag>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ContentMoveTags>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </ContentData>
            </ConfigConstant>
          </ConfigurationSection>
        </GlobalContent>
      </Content>
    </Layout>
  );
};

const checkInvalidCharacters = (inputStr) => {
  const expression = /^[a-zA-Z0-9]+$/;
  if (inputStr !== "" && !expression.test(inputStr)) return true;
  return false;
};

export default GlobalConstants;
