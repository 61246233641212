import { useCallback } from "react";
import Container from "@mui/material/Container";
import NavBar from "components/Admin/NavBar/NavBar";
import { CustomContainer, ContainerSelectFields } from "./uploadStyles";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import OperationDialog from "components/Dialogs/OperationDialog";
import { useParams } from "react-router-dom";
import GenericDialog from "components/Dialogs/GenericDialog";
import DragAndDropContent from "components/DragAndDrop";
import { useState } from "react";
import FetchFieldVideosByUserId from "hooks/fetchFieldVideosWithoutEvent";
import Loading from "components/Lodings/LoadingV2";
import axios from "axios";
import { useSWRConfig } from "swr";
import OpenDialog from "components/Dialogs/OpenDialog";
import SelectFields from "components/DragAndDrop/SelectFields";
import CustomButton from "components/Buttons/CustomButton";
import findEvents from "components/DragAndDrop/findEvents";
import EventDetails from "components/ElementDetails/EventDetails/EventDetails";
import { createRows, createRowsVideos } from "./createRows";
import { WHITE_BACKGROUND } from "utils/const";
import { FROM_TABLE } from "utils/constStrings";
import { config } from "config.js";
import Layout from "components/Layout/AdminDecimetrix";

// info table
const headers = [
  "Event ID",
  "Download Video",
  "Have Field Video ?",
  "Event Collected by",
  "Date Collection",
];

const keyValues = ["id", "download", "fieldVideo", "userCollected", "date"];

const UploadVideos = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const { mutate } = useSWRConfig();
  const [idToDelete, setIdToDelete] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [typeEventId, setTypeEventId] = useState("");
  const [fieldFile, setFieldFile] = useState("");
  const [pointEvents, setPointEvents] = useState([]);
  const [eventsWithoutVideo, setEventsWithoutVideo] = useState([]);
  const [isLoadSync, setIsLoadSync] = useState(false);
  const [rows, setRows] = useState([]);

  const { data: fieldVideos, error: erroFieldVideos } =
    FetchFieldVideosByUserId(userId);

  const rowsVideos =
    fieldVideos && !erroFieldVideos ? createRowsVideos(fieldVideos) : [];

  const handlerClickUpladVideos = () => handleOpenModal();

  const dialogDeleteVideo = async () => {
    const result = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/field-video/${idToDelete}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result) {
      mutate(`${config.URL_BACKEND_PG}api/v1/field-video/userId/${userId}`);
    }
    setOpenDialog(false);
  };

  const handleDeleteEvent = async (id) => {
    setIdToDelete(id.props.sx.value);
    setOpenDialog(true);
  };

  const handleClickEvent = useCallback(
    (eventId) => {
      const event = {
        content: <EventDetails eventId={eventId} />,
        title: "Event Details",
      };
      dispatch(setOperationDetails(event));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );

  const handlerClickSync = async () => {
    setIsLoadSync(true);
    const { pointEvents, eventsWithoutVideo } = await findEvents({
      fieldFile,
      typeEventId,
    });
    const rows = createRows({ pointEvents, fieldFile });
    setRows(rows);
    setIsLoadSync(false);
    setPointEvents(pointEvents);
    setEventsWithoutVideo(eventsWithoutVideo);
  };

  return (
    <>
      <Layout>
        {" "}
        <OpenDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          execute={dialogDeleteVideo}
          content={{
            title: "Borrar video",
            description: "Estas seguro de borrar este video?",
            disagree: "Cancelar",
            agree: "Aceptar",
          }}
        />
        <CustomContainer className="background-1">
          <Container
            className="background-2 border-radius-1 border-color-1"
            style={{ padding: "2rem" }}
          >
            <div className="title">
              <h1>UPLOAD VIDEOS</h1>
            </div>
            {pointEvents ? (
              <div>
                <ContainerSelectFields>
                  <SelectFields
                    fieldFile={fieldFile}
                    setFieldFile={setFieldFile}
                    typeEventId={typeEventId}
                    setTypeEventId={setTypeEventId}
                  />
                </ContainerSelectFields>
                <CustomButton
                  text={"Sync data"}
                  onClick={handlerClickSync}
                  isLoad={isLoadSync}
                  margin={0}
                />
                <section className="kpis-section">
                  <button
                    className="button-upload-videos"
                    onClick={() => handlerClickUpladVideos()}
                  >
                    Upload Videos
                  </button>

                  <div className="kpis-container background-row-1 border-radius-1 border-color-1">
                    <h3 className="color1">
                      <span>{pointEvents.length} FLIR</span> Events
                    </h3>
                    <h3 className="color1">
                      <span>
                        {pointEvents.length -
                          eventsWithoutVideo.length +
                          rowsVideos.length}{" "}
                        Videos
                      </span>{" "}
                      Uploaded
                    </h3>
                    <h3 className="color1">
                      <span>
                        {pointEvents.length - eventsWithoutVideo.length} Events
                      </span>{" "}
                      with video
                    </h3>
                    <h3 className="color1">
                      <span>{eventsWithoutVideo.length} Events</span> without
                      video
                    </h3>
                    <h3 className="color1">
                      <span>{rowsVideos.length} Videos</span> without event
                    </h3>
                  </div>
                </section>
                <section className="table-container background-row-1 border-radius-1 border-color-1">
                  <GenericTable
                    headers={headers}
                    rows={rows.concat(rowsVideos)}
                    keyValues={keyValues}
                    handlerClick={handleClickEvent}
                    handlerDelete={handleDeleteEvent}
                    state={true}
                    customTableWidth={"100%"}
                    customHeaderWidth={"5%"}
                    from={FROM_TABLE.uploadVideos}
                  />
                </section>
              </div>
            ) : (
              <Loading />
            )}
            <OperationDialog
              style={{
                margin: 0,
                padding: 0,
                width: "90%",
                height: "100%",
                maxWidth: "none",
              }}
            />
            <GenericDialog
              openDialog={openModal}
              setOpenDialog={setOpenModal}
              content={{
                title: "Upload Videos",
                description: null,
                disagree: null,
                agree: null,
                content: <DragAndDropContent userId={userId} />,
              }}
              style={{
                margin: 0,
                padding: 0,
                width: "80%",
                height: "80%",
                maxWidth: "none",
                background: `${WHITE_BACKGROUND}`,
              }}
            />
          </Container>
        </CustomContainer>
      </Layout>
    </>
  );
};

export default UploadVideos;
