import { useEffect, useState, useCallback } from "react";
// Helpers
import { sum, count } from "pages/Dashboard/helpers/eventsKpis";
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Services
import useSwr from "swr";
import { config } from "config";

const useFindKpiCharts = ({ openDialog, events, loading }) => {
  const [charts, setCharts] = useState(null);
  const [kpiCharts, setKpiCharts] = useState(null);
  const [eventsFormat, setEventsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlKpi = `${config.URL_BACKEND_MG}kpi-chart?adminCompanyId=${adminCompanyId}&from=events`;
  const { data: dataKpi, error: errorDataKpi } = useSwr(urlKpi);

  useEffect(() => {
    if (!errorDataKpi && dataKpi) setCharts(dataKpi);
  }, [dataKpi, errorDataKpi, openDialog, reloadCharts]);

  useEffect(() => {
    const eventsFormat = createRowsEvents({ events });
    setEventsFormat(eventsFormat);
  }, [events]);

  const getDataKpiChartCount = useCallback(async () => {
    if (eventsFormat && !loading) {
      const kpiChart = charts.filter((item) => item.type === "count");
      // Charts total events
      const chartsCount = count({
        kpiChart,
        events: eventsFormat,
      });

      // Set state once with all the calculated values
      setKpiCharts([...chartsCount]);
    }
  }, [charts, eventsFormat, loading]);

  const getDataKpiChartSum = useCallback(async () => {
    if (eventsFormat && !loading) {
      // Kpis type sum
      const kpisTypeSum = charts.filter((item) => item.type === "sum");

      const chartsSum = sum({
        kpiChart: kpisTypeSum,
        events: eventsFormat,
      });
      // Set state once with all the calculated values
      setKpiCharts((prev) => [...prev, ...chartsSum]);
    }
  }, [charts, eventsFormat, loading]);

  useEffect(() => {
    if (charts && events) {
      getDataKpiChartCount();
      getDataKpiChartSum();
    }
  }, [charts, events, getDataKpiChartCount, getDataKpiChartSum]);

  return { kpiCharts };
};

export default useFindKpiCharts;
